import { useState } from 'react';
import { BodyLarge, Checkbox, colors, DownIcon, Flex, Icon, UpIcon } from '@beauty/beauty-market-ui';
import { SubCategoryCardProps } from '../types';
import { ServiceCard } from './ServiceCard';

export const SubCategoryCard = ({ title, services, selectedServices, serviceIds, onChange }: SubCategoryCardProps) => {
  const [isOpen, setIsOpen] = useState(false);

  const subCategoryTitle = `${title} (${services.length})`;
  const subCategoryChecked = serviceIds.every(id => selectedServices.includes(id));

  const handleSelectAll = () => onChange(serviceIds);
  const handleChangeOpen = () => setIsOpen(!isOpen);

  return (
    <Flex flexDirection="column" p="16px" backgroundColor={colors.grey.light} borderRadius="10px">
      <Flex
        alignItems="center"
        mb={isOpen ? '22px' : '0'}
        justifyContent="space-between"
        onClick={handleChangeOpen}
        cursor="pointer"
      >
        <Flex alignItems="center" gap="16px">
          <Icon width="20px" height="20px">
            {isOpen ? <UpIcon /> : <DownIcon />}
          </Icon>
          <BodyLarge>{subCategoryTitle}</BodyLarge>
        </Flex>
        <Checkbox checked={subCategoryChecked} onCheckboxClick={handleSelectAll} shape="round" />
      </Flex>
      {isOpen &&
        services.map(item => (
          <ServiceCard
            id={item.id}
            key={item.id}
            title={item.title}
            description={item.description}
            selectedServices={selectedServices}
            onChange={onChange}
          />
        ))}
    </Flex>
  );
};
