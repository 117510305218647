import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { capitalize } from 'lodash';
import moment from 'moment';
import { Flex, H5, H6, Scroll, Separator } from '@beauty/beauty-market-ui';
import { EditButton } from 'components';
import { formatPhoneNumber } from 'helpers';
import { Adaptive } from 'hoc';
import { useMediaScreen } from 'hooks';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { selectClient, ThunkClient } from 'store/redux-slices/clientSlice';
import { ClientActions } from 'types';
import { ClientForm, ClientSidebar, ProfileItem } from '../../components';
import { getParams } from '../../helper';
import { Wrapper } from '../../style';

export const PageData = () => {
  const { t } = useTranslation();
  const { isDesktop } = useMediaScreen('md');
  const client = useAppSelector(selectClient);
  const dispatch = useAppDispatch();
  const { number, email, code, dateOfBirth, gender, idNumber, info } = client;

  const [action, setAction] = useState(ClientActions.None);

  const onSubmit = useCallback(
    (data: ClientForm) => {
      const params = getParams(data, client, true);
      return dispatch(ThunkClient.editClient(params)).unwrap();
    },
    [dispatch, getParams, client],
  );

  const handleEditClient = () => setAction(ClientActions.Edit);
  const handleClose = () => setAction(ClientActions.None);

  return (
    <Wrapper minHeight={isDesktop ? 'auto' : 'calc(100vh - 70px)'}>
      <Flex justifyContent="space-between" alignItems="center" mb="16px">
        <Adaptive mobile={H6} desktop={H5}>
          {t('profile.personalInformation')}
        </Adaptive>
        <EditButton onClick={handleEditClient} edit />
      </Flex>
      {isDesktop && <Separator />}
      <Scroll>
        {number && code && (
          <ProfileItem
            label={t('profile.phone')}
            placeholder={t('profile.notFilledYet')}
            large
            title={formatPhoneNumber(code, number)}
            separator
            dir="ltr"
          />
        )}
        <ProfileItem label={t('profile.email')} placeholder={t('profile.notFilledYet')} large title={email} separator />
        <ProfileItem
          label={t('profile.dateOfBirth')}
          placeholder={t('profile.notFilledYet')}
          large
          title={dateOfBirth && moment(dateOfBirth).format('DD.MM.YYYY')}
          separator
        />
        <ProfileItem
          label={t('profile.gender')}
          placeholder={t('profile.notFilledYet')}
          large
          title={capitalize(gender)}
          separator
        />
        <ProfileItem
          label={t('profile.idNumber')}
          placeholder={t('profile.notFilledYet')}
          large
          title={idNumber ?? undefined}
          separator
        />
        <ProfileItem label={t('profile.additionalInfo')} placeholder={t('profile.notFilledYet')} title={info} />
      </Scroll>
      {action !== ClientActions.None && <ClientSidebar onSubmit={onSubmit} onClose={handleClose} action={action} />}
    </Wrapper>
  );
};
