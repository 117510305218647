import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { Flex, Separator, BodyLarge } from '@beauty/beauty-market-ui';
import PopUp from 'components/PopUp';

import { useRequest } from 'hooks';
import { useAppSelector } from 'store/hooks';
import { selectAppointments } from 'store/redux-slices/appointmentsSlice';
import { ThunkSpecialist } from 'store/redux-slices/specialistSlice';
import { OrganizationAction } from 'types';

type CloseDayPopupProps = {
  date: Date;
  onClose: () => void;
  onSubmit: () => void;
  orgId: string | null;
  isEmptyDay: boolean;
};

export const CloseDayPopup = ({ date, onClose, onSubmit, orgId, isEmptyDay }: CloseDayPopupProps) => {
  const { t } = useTranslation();
  const { selectedAddress } = useAppSelector(selectAppointments);

  const [isLoading, setLoading] = useState(false);

  const closeDay = useRequest(ThunkSpecialist.addBreakAll, OrganizationAction.CloseDay);
  const formattedDate = moment(date, 'DD.MM.yyyy').format('YYYY-MM-DD');
  const start = moment(`${formattedDate} 00:00`).utc();
  const end = moment(`${formattedDate} 23:59`).utc();

  const handleSubmit = () => {
    setLoading(true);
    const params = { start, end, orgId: selectedAddress ?? (orgId as string) };

    closeDay(params)
      .then(value => onSubmit())
      .finally(() => setLoading(false));
  };

  const content = (
    <Flex flexDirection="column" width="100%" gap="16px">
      <BodyLarge>{`${isEmptyDay ? '' : `${t('calendar.menu.closeDayWarning')} `} ${t(
        'calendar.menu.closeDayInfo',
      )}`}</BodyLarge>
      <Separator mt="40px" />
    </Flex>
  );

  return (
    <PopUp
      title={t('calendar.menu.closeDayTitle')}
      description={t('calendar.menu.closeDayDescription')}
      onSubmit={handleSubmit}
      onClose={onClose}
      confirm={t('calendar.menu.closeDay')}
      isLoading={isLoading}
    >
      {content}
    </PopUp>
  );
};
