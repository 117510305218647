import { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { BellIcon, Button, Flex, useClickOutside } from '@beauty/beauty-market-ui';
import { GoogleCalendarIcon } from 'assets';
import { getCalendarAccessUrl } from 'helpers/generalAPI';
import { useAppSelector } from '../../../../store/hooks';
import { selectUser } from '../../../../store/redux-slices/userSlice';
import { BellWrapper, StyledFlex } from '../../style';
import { ChangePlan } from '../ChangePlan';
import { NotificationsPopup } from '../NotificationsPopup/NotificationsPopup';
import { AvatarMenu } from './AvatarMenu';

export const HeaderTools = () => {
  const { t } = useTranslation();
  const { user } = useAppSelector(selectUser);
  const location = useLocation();

  const [isNotificationsPopup, setNotificationsPopup] = useState(false);

  const bellRef = useRef<Element | null>(null);
  const notificationsRef = useRef<Element | null>(null);

  const { id = '', googleTokens = 'false', active = false } = user.headOrgSpecialist ?? {};

  const handleGoogleCalendar = async () => {
    const response = id ? await getCalendarAccessUrl(id) : null;
    response && window.open(response, '_blank');
  };

  const notificationsPopupProps = {
    isOpen: isNotificationsPopup,
    title: t('notifications.title'),
    items: [] /* notifications */,
    setRead: undefined /* async (index: number) => {
      if (!notifications[index].isRead) {
        const response = await changeNotificationStatus(notifications[index].id);
        response?.status === NotificationStatus.VIEWED && dispatch(updateNotificationStatus(index));
      }
    }, */,
    setNotificationsPopup,
    onClose: () => setNotificationsPopup(false),
  };

  useClickOutside(notificationsRef, () => {
    setTimeout(() => {
      setNotificationsPopup(false);
    }, 150);
  });

  return (
    <Flex gap="14px" width="310px" height="48px" alignItems="center">
      {location.pathname.includes('calendar') && googleTokens === 'false' ? (
        // google button
        <StyledFlex>
          <button className="gsi-material-button" type="button" onClick={handleGoogleCalendar} disabled={!active}>
            <div className="gsi-material-button-state" />
            <div className="gsi-material-button-content-wrapper">
              <div className="gsi-material-button-icon">
                <GoogleCalendarIcon width="20" height="20" />
              </div>
              <span className="gsi-material-button-contents">{t('header.sync')}</span>
              <span style={{ display: 'none' }}>{t('header.sync')}</span>
            </div>
          </button>
        </StyledFlex>
      ) : (
        <ChangePlan />
      )}
      <BellWrapper
        ref={bellRef}
        onClick={() => {
          setNotificationsPopup(pr => !pr);
        }}
      >
        <Button design="quaternary">
          <BellIcon />
        </Button>
        <NotificationsPopup {...notificationsPopupProps} ref={notificationsRef} />
      </BellWrapper>
      <AvatarMenu />
    </Flex>
  );
};
