import { BreakType } from './types';

export enum ActionType {
  SetAllDays = 'SET_ALL_DAYS',
  SetOrgWeek = 'SET_ORG_WEEK',
  SetDirty = 'SET_DIRTY',
  SetOpen = 'SET_OPEN',
  SetLoading = 'SET_LOADING',

  ChangeDate = 'CHANGE_DATE',
  ChangeDay = 'CHANGE_DAY',
  ChangeWeek = 'CHANGE_WEEK',
  ChangeRepeat = 'CHANGE_REPEAT',

  ChangeIsWorkDay = 'CHANGE_IS_WORK_DAY',
  ChangeStartTime = 'CHANGE_START_TIME',
  ChangeEndTime = 'CHANGE_END_TIME',

  ChangeStartBreak = 'CHANGE_START_BREAK',
  ChangeEndBreak = 'CHANGE_END_BREAK',
  CreateBreak = 'CREATE_BREAK',
  DeleteBreak = 'DELETE_BREAK',

  DeleteOrganisation = 'DELETE_ORGANISATION',
  AddOrganisation = 'ADD_ORGANISATION',
  ChangeOrganisation = 'CHANGE_ORGANISATION',
}

export const setAllDays = (value: boolean) => ({ type: ActionType.SetAllDays, payload: value }) as const;
export const setDirty = (value: boolean) => ({ type: ActionType.SetDirty, payload: value }) as const;
export const setOpen = (value: boolean) => ({ type: ActionType.SetOpen, payload: value }) as const;
export const setLoading = (value: boolean) => ({ type: ActionType.SetLoading, payload: value }) as const;

export const changeRepeat = (index: number) => ({ type: ActionType.ChangeRepeat, payload: index }) as const;
export const changeWeek = (index: number) => ({ type: ActionType.ChangeWeek, payload: index }) as const;
export const changeDay = (index: number) => ({ type: ActionType.ChangeDay, payload: index }) as const;
export const changeDate = (value: string) => ({ type: ActionType.ChangeDate, payload: value }) as const;
export const changeIsWorkDay = (value: boolean) => ({ type: ActionType.ChangeIsWorkDay, payload: value }) as const;

export const changeStartTime = (orgId: string, index: number) =>
  ({
    type: ActionType.ChangeStartTime,
    payload: { orgId, startIndex: index },
  }) as const;

export const changeEndTime = (orgId: string, index: number) =>
  ({
    type: ActionType.ChangeEndTime,
    payload: { orgId, endIndex: index },
  }) as const;

export const changeOrganisation = (orgId: string, newOrgId: string, newOrgName: string) =>
  ({
    type: ActionType.ChangeOrganisation,
    payload: { orgId, newOrgId, newOrgName },
  }) as const;

export const deleteOrganisation = (orgId: string) =>
  ({
    type: ActionType.DeleteOrganisation,
    payload: orgId,
  }) as const;

export const addOrganisation = (orgId: string, orgName: string) =>
  ({
    type: ActionType.AddOrganisation,
    payload: { orgId, orgName },
  }) as const;

export const createBreak = (orgId: string, value: BreakType) =>
  ({
    type: ActionType.CreateBreak,
    payload: { orgId, value },
  }) as const;

export const deleteBreak = (orgId: string, breakId: string) =>
  ({
    type: ActionType.DeleteBreak,
    payload: { orgId, breakId },
  }) as const;

export const changeStartBreak = (orgId: string, breakId: string, index: number) =>
  ({
    type: ActionType.ChangeStartBreak,
    payload: { orgId, breakId, startIndex: index },
  }) as const;

export const changeEndBreak = (orgId: string, breakId: string, index: number) =>
  ({
    type: ActionType.ChangeEndBreak,
    payload: { orgId, breakId, endIndex: index },
  }) as const;

export const setWeekOfOrganisation = (orgId: string, value: boolean) =>
  ({
    type: ActionType.SetOrgWeek,
    payload: { orgId, checked: value },
  }) as const;

export type ScheduleActionsType =
  | ReturnType<typeof setAllDays>
  | ReturnType<typeof setDirty>
  | ReturnType<typeof setOpen>
  | ReturnType<typeof setLoading>
  | ReturnType<typeof changeRepeat>
  | ReturnType<typeof changeDate>
  | ReturnType<typeof changeWeek>
  | ReturnType<typeof changeDay>
  | ReturnType<typeof changeIsWorkDay>
  | ReturnType<typeof changeStartTime>
  | ReturnType<typeof changeEndTime>
  | ReturnType<typeof deleteOrganisation>
  | ReturnType<typeof addOrganisation>
  | ReturnType<typeof changeOrganisation>
  | ReturnType<typeof createBreak>
  | ReturnType<typeof deleteBreak>
  | ReturnType<typeof changeStartBreak>
  | ReturnType<typeof changeEndBreak>
  | ReturnType<typeof setWeekOfOrganisation>;
