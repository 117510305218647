import { BodySmall, colors, Flex, BodyLarge, Separator } from '@beauty/beauty-market-ui';

export const AddressWorkDay = ({ title, subtitle, label, description, enableSeparator }) => (
  <>
    <Flex justifyContent="space-between" alignItems="center" minHeight="42px" width="100%">
      <Flex flexDirection="column">
        <BodyLarge large>{title}</BodyLarge>
        <BodySmall color={colors.grey.dark}>{subtitle}</BodySmall>
      </Flex>
      <Flex flexDirection="column">
        <BodyLarge large>{label}</BodyLarge>
        <BodySmall color={colors.grey.dark}>{description}</BodySmall>
      </Flex>
    </Flex>
    {enableSeparator && <Separator my="16px" />}
  </>
);
