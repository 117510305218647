import { Moment } from 'moment';
import { Document, Education, Language, SpokenLanguage } from './general';
import { AddressBaseType, DayOrgType, MainPhotoType } from './organisation';
import { OrganisationServiceResponse } from './service';

export type SpecialistType = {
  id: string;
  name: string;
  photo?: string;
  rating?: string;
  description?: string;
};

export type AccessLevel = 'ADMIN' | 'SPECIALIST' | 'OWNER' | 'SUPERADMIN';

export type Role = 'MANAGER' | 'SPECIALIST' | null;

export enum EmployeeRole {
  MANAGER = 'MANAGER',
  SPECIALIST = 'SPECIALIST',
}

export type SpecialistBreakType = {
  start: string;
  end: string;
  id?: string;
  orgSpecDayScheduleId?: string;
};

export type SpecialistScheduleDayType = {
  id: string;
  dayOfWeek: string;
  start: string;
  end: string;
  orgSpecDayBreak: SpecialistBreakType[];
  orgSpecWeekScheduleId: string;
};

export type SpecialistDayScheduleType = {
  [orgSpecId: string]: SpecialistScheduleDayType | undefined;
};

export type SpecialistScheduleType = {
  id: string;
  orgSpecId: string;
  weekNumber: number;
  orgSpecDaySchedule: SpecialistScheduleDayType[];
};

export type SpecialistOrganizationType = {
  orgId: string;
  orgSpecId: string;
  orgSpecSchedule: SpecialistScheduleType[];
  orgService: OrganisationServiceResponse[];
  orgSchedule: DayOrgType[];
  scheduleStart?: string;
  id?: string;
  address?: AddressBaseType;
  mainPhoto?: MainPhotoType;
  name?: string;
};

export type CreateSpecialistParams = {
  id: string;
  role: Role;
  accessLevel: AccessLevel;
  accessStart: string;
  accessEnd: string | undefined;
  specialization: string | null;
  language: Language;
  account: {
    name: string;
    surname: string;
    code: string;
    number: string;
    email: string;
    avatarUrl: File | null | string;
  };
  active?: boolean;
  published?: boolean;
  organization?: SpecialistOrganizationType[];
};

export type SpecialistResponse = {
  id: string;
  headOrgId: string;
  accessLevel: AccessLevel;
  accessStart: string;
  accessEnd: string | undefined;
  role: Role;
  published: boolean;
  specialization: string;
  account: {
    name: string;
    surname: string;
    avatarUrl: string;
    code: string;
    number: string;
    email: string;
    verified: boolean;
  };
  organization: SpecialistOrganizationType[];
  active: boolean;
};

export type OrganisationSpecialistResponse = {
  orgSpecId: string;
  headOrgSpecialistId: string;
  name: string;
  surname: string;
  email: string;
  code: string;
  number: string;
  avatarUrl: string | null;
  serviceIds: string[];
  role?: string;
};

export type SpecParams = {
  id: string;
  name: string;
  avatarUrl;
  phone: string;
  serviceIds: string[];
}[];

export enum SpecialistAction {
  Add = 'ADD_SPECIALIST',
  Edit = 'EDIT_SPECIALIST',
  PublishOn = 'PUBLISH_ON',
  PublishOff = 'PUBLISH_OFF',
  EditWorkplace = 'EDIT_WORKPLACE',
  EditServices = 'EDIT_SERVICES',
  EditSchedule = 'EDIT_SCHEDULE',
  DeleteWorkplace = 'DELETE_WORKPLACE',
  DeleteService = 'DELETE_SERVICE',
  AddBreak = 'ADD_BREAK',
  EditBreak = 'EDIT_BREAK',
  DeleteBreak = 'DELETE_BREAK',
  None = 'NONE',
  EditAbout = 'EDIT_SPECIALIST_ABOUT',
  AddEducation = 'ADD_SPECIALIST_EDUCATION',
  EditEducation = 'EDIT_SPECIALIST_EDUCATION',
  DeleteEducation = 'DELETE_SPECIALIST_EDUCATION',
  EditPhotos = 'EDIT_SPECIALIST_PHOTOS',
  AddLanguage = 'ADD_SPECIALIST_LANGUAGE',
  EditLanguage = 'EDIT_SPECIALIST_LANGUAGE',
  DeleteLanguage = 'DELETE_SPECIALIST_LANGUAGE',
}

export type SpecialistEducation = Education & {
  documentUrl?: null | string;
  documentId?: null | string;
  headOrgSpecialistId?: string;
};

export type SpecialistLanguage = SpokenLanguage & {
  id: string;
  headOrgSpecId: string;
};

export type SpecialistInformation = {
  photo: Document[];
  about: string | null;
  education: SpecialistEducation[];
  spokenLanguage: SpecialistLanguage[];
};

export type SpecialistStateType = {
  allSpecialists: SpecialistResponse[];
  currentSpecialist: CreateSpecialistParams | SpecialistResponse;
  information: SpecialistInformation;
  type: SpecialistAction;
  isLoading: boolean;
};

export type DayParamsType = {
  dayOfWeek: string;
  start: string;
  end: string;
  orgSpecDayBreak: SpecialistBreakType[];
};

type WeekParamsType = {
  weekNumber: number;
  daySchedule: DayParamsType[];
};

export type EditWorkingTimeParamsType = {
  orgSpecId: string;
  scheduleStart: string;
  weekSchedule: WeekParamsType[];
};

export type AddBreakParams = {
  start: Moment;
  end: Moment;
  id?: string;
  orgSpecialistId?: string;
  orgId?: string;
};
