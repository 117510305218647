import { useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import { Formik, Form } from 'formik';
import { Button } from '@beauty/beauty-market-ui';
import { setAccessToken } from 'api/api.helpers';
import { FormikInput } from 'components/functional/formik/formik-input/FormikInput';
import { FormikPasswordInput } from 'components/functional/formik/formik-password-input/FormikPasswordInput';
import { loginByEmail } from 'helpers/loginByEmail';
import useRedirectAfterLogin from 'hooks/useRedirectAfterLogin';
import { RouterUrl } from 'routes/routes';
import { clearAccountState } from 'store/actions/common';
import { useAppDispatch } from 'store/hooks';
import { setLogin } from 'store/redux-slices/authSlice';
import { updateProfile, updateUser } from 'store/redux-slices/userSlice';
import { InvalidVariants } from '../../../constants';
import {
  LoginByEmailForm,
  initialByEmailValues,
  loginEmailFormValidationSchema,
  LoginByEmailFields,
} from '../Login.definitions';

const Login = () => {
  const navigate = useNavigate();
  const { state } = useLocation();
  const { t } = useTranslation();
  const [invalidType, setInvalidType] = useState<InvalidVariants | null>(null);
  const [isWrongPassword, setWrongPassword] = useState(false);
  const [isLoading, setLoading] = useState(false);

  const dispatch = useAppDispatch();
  const formRef = useRef<HTMLFormElement>(null);

  const onFormSubmit = useCallback(
    async (data: LoginByEmailForm) => {
      setLoading(true);
      dispatch(clearAccountState());
      const response = await loginByEmail(data);
      if (response.success) {
        setInvalidType(null);
        setWrongPassword(false);
        const { name, surname, email, id, avatarUrl, headOrgSpecialist, ...rest } = response.data;
        setAccessToken(response.accessToken); // TODO Review after BE response
        dispatch(setLogin(true));
        dispatch(updateUser({ name: `${name} ${surname}`, email, userId: id, avatarUrl, headOrgSpecialist }));
        dispatch(updateProfile({ id, name, surname, email, avatarUrl, ...rest }));
        useRedirectAfterLogin(navigate, !!headOrgSpecialist);
      } else
        switch (response.statusCode) {
          case 401:
            setWrongPassword(true);
            break;
          case 404:
            setInvalidType(InvalidVariants.NotFound);
            break;
          default:
            setInvalidType(InvalidVariants.Unknown);
        }
      setLoading(false);
    },
    [navigate, dispatch, state, invalidType],
  );

  const formikContextValue = {
    initialValues: initialByEmailValues,
    validationSchema: loginEmailFormValidationSchema(t),
    onSubmit: onFormSubmit,
    validateOnMount: false,
  };

  useEffect(() => {
    const onLoad = () => formRef.current?.focus();
    document.addEventListener('load', onLoad);
    return () => document.removeEventListener('load', onLoad);
  }, []);

  return (
    <Formik {...formikContextValue}>
      {({ isValid }) => (
        <Form ref={formRef}>
          <FormikInput
            autoFocus
            id={LoginByEmailFields.Email}
            name={LoginByEmailFields.Email}
            placeholder="Email"
            invalidType={invalidType}
            onInput={() => setInvalidType(null)}
          />
          <FormikPasswordInput
            mt="8px"
            id={LoginByEmailFields.Password}
            name={LoginByEmailFields.Password}
            placeholder={t('registration.password')}
            secondaryCaption={t('login.resetPassword')}
            onSecondaryCaptionClick={() => navigate(RouterUrl.ResetPassword)}
            onInput={() => setWrongPassword(false)}
            isWrong={isWrongPassword}
          />
          <Button
            disabled={!isValid || invalidType || isWrongPassword || isLoading}
            design="primary"
            mt="24px"
            width="100%"
            size="large"
            type="submit"
          >
            {t(`login.continue`)}
          </Button>
        </Form>
      )}
    </Formik>
  );
};

export default Login;
