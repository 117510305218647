import React, { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FormikProps } from 'formik';
import { Flex } from '@beauty/beauty-market-ui';
import { SidebarFooter } from '../../SidebarFooter';
import { SidebarSheet } from '../../SidebarSheet';
import { MainServiceFormType } from './MainService.definitions.';
import { MainServiceForm } from './MainServiceForm';

export const MainService = ({ handleNextStep, handleBackStep, handleClose, stepper }) => {
  const { t } = useTranslation();
  const formRef = useRef<FormikProps<MainServiceFormType>>();

  const [isValid, setIsValid] = useState(false);

  const handleSubmit = async () => {
    await formRef.current?.submitForm();
    handleNextStep();
  };

  const footer = <SidebarFooter cancel disabled={!isValid} onSubmit={handleSubmit} onBack={handleBackStep} />;
  const content = <MainServiceForm ref={formRef} setIsValid={setIsValid} showCaption />;

  return (
    <SidebarSheet
      onClose={handleClose}
      FooterBody={footer}
      label={t('settings.services.sidebar.addNewService')}
      descriptor={t('settings.services.sidebar.chooseCategories')}
    >
      <Flex flexDirection="column" gap="16px" mb="80px">
        {stepper}
        {content}
      </Flex>
    </SidebarSheet>
  );
};
