import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { isArray } from 'lodash';
import moment from 'moment/moment';
import Popup from 'components/PopUp';
import { useGetAppointmentById, useGetClients, useRequest } from 'hooks';
import { useGetAddressById } from 'hooks/address/useGetAddressById';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { ThunkAppointments } from 'store/redux-slices/appointmentsSlice';
import { selectUserHeadOrgId } from 'store/redux-slices/userSlice';
import { AppointmentsAction, PaymentType } from 'types';
import { CloseAppointmentParams } from 'types/appointment';
import { EventStatus } from '../../../constants';
import { ActionClient, selectClientInfo } from '../../../store/redux-slices/clientSlice';
import { AppointmentInfo } from './AppointmentInfo/AppointmentInfo';
import { EditAppointment } from './EditAppointment/EditAppointment';
import { AppointmentForm } from './EditAppointment/EditAppointment.definitions';

type Props = {
  id?: string | null;
  onClose: () => void;
  onRefresh: () => void;
};

export const AppointmentDetails = ({ id, onClose, onRefresh }: Props) => {
  const { t } = useTranslation();
  const clientInfo = useAppSelector(selectClientInfo);
  const headOrgId = useAppSelector(selectUserHeadOrgId);
  const dispatch = useAppDispatch();
  const query = ['orgBusinessHours', 'address', 'contact', 'orgSpecialist', 'orgService'].join(',');

  const [data, isLoadingAppointment, , setData] = useGetAppointmentById(id);
  const [address, isLoadingAddress] = useGetAddressById(data?.orgService?.orgId, query);
  const [clients] = useGetClients(headOrgId);

  const [isPopup, setPopup] = useState(false);
  const [isSubmitting, setSubmitting] = useState(false);

  const cancelAppointment = useRequest(ThunkAppointments.updateAppointment, AppointmentsAction.Cancel);
  const updateAppointment = useRequest(ThunkAppointments.updateAppointment, AppointmentsAction.Update);
  const closeAppointment = useRequest(ThunkAppointments.closeAppointment, AppointmentsAction.Close);

  const [updatedPrice, setUpdatedPrice] = useState<number | string>('');

  const handleOpenPopup = () => setPopup(true);
  const handleClosePopup = () => setPopup(false);

  const makeRequest = async (request: Promise<unknown>) => {
    setSubmitting(true);
    return request.finally(() => {
      setSubmitting(false);
      onClose();
      onRefresh();
    });
  };

  const handleAppointmentCancel = async () => {
    if (data) {
      const prepareParams = { status: EventStatus.CANCELLED };
      await makeRequest(cancelAppointment({ id: data?.id, params: prepareParams }));
      dispatch(ActionClient.updateClientInfo({ cancelledApp: clientInfo.cancelledApp + 1 }));
    }
  };

  const handleUpdateAppointment = async (params: Partial<AppointmentForm>) => {
    if (data) {
      const formattedDate = moment(params.date, 'DD.MM.yyyy').format('YYYY-MM-DD');
      const prepareParams = {
        orgSpecId: params.orgSpecId,
        orgServId: params.orgServId,
        notes: params.notes,
        status: params.status,
        start: moment(`${formattedDate} ${params.start}`).utc(),
        end: moment(`${formattedDate} ${params.end}`).utc(),
      };
      await makeRequest(updateAppointment({ id: data?.id, params: prepareParams }));
      if (params.status === EventStatus.NOSHOW) {
        dispatch(ActionClient.updateClientInfo({ noShowApp: clientInfo.noShowApp + 1 }));
      }
      if (params.status === EventStatus.COMPLETED || params.status === EventStatus.PAST) {
        const price = data?.orgService.price ?? 0;
        dispatch(
          ActionClient.updateClientInfo({
            completedApp: clientInfo.completedApp + 1,
            totalSales: clientInfo.totalSales + price,
          }),
        );
      }
    }
  };

  const handleCloseAppointment = async (params: Partial<AppointmentForm>) => {
    setSubmitting(true);
    if (data) {
      const { price } = params;
      const prepareParams: CloseAppointmentParams = {
        id: data.id,
        paymentMethod: params?.paymentMethod ?? PaymentType.CASH,
      };
      if (price) prepareParams.price = Number(price);
      await closeAppointment(prepareParams).then(() => setUpdatedPrice(prepareParams.price ?? ''));

      setData({ ...data, status: EventStatus.COMPLETED });
      const servicePrice = data?.orgService.price ?? prepareParams.price ?? 0;
      dispatch(
        ActionClient.updateClientInfo({
          completedApp: clientInfo.completedApp + 1,
          totalSales: clientInfo.totalSales + servicePrice,
        }),
      );
    }
    setSubmitting(false);
  };

  if (!data || !id || !address || isLoadingAppointment || isLoadingAddress) return null;

  switch (data.status) {
    case EventStatus.UNCLOSED: {
      return (
        <EditAppointment
          readOnly={!!data.price}
          isSubmitting={isSubmitting}
          appointment={data}
          company={address}
          clients={clients}
          onClose={onClose}
          onSubmit={handleCloseAppointment}
        />
      );
    }
    case EventStatus.PENDING:
    case EventStatus.CONFIRMED:
    case EventStatus.WAITING:
    case EventStatus.INPROGRESS:
      return (
        <>
          <EditAppointment
            isSubmitting={isSubmitting}
            appointment={data}
            company={address}
            clients={clients}
            onClose={onClose}
            onSubmit={handleUpdateAppointment}
            onCancel={handleOpenPopup}
          />
          {isPopup && (
            <Popup
              title={t('calendar.confirmation.cancellingAppointment')}
              description={t('calendar.confirmation.doYouWantToCancelAppointment')}
              onClose={handleClosePopup}
              onSubmit={handleAppointmentCancel}
              isLoading={isSubmitting}
              confirm={t('button.confirm')}
              cancel={t('button.close')}
            />
          )}
        </>
      );
    case EventStatus.COMPLETED:
    case EventStatus.NOSHOW:
    case EventStatus.PAST: {
      const company = {
        name: address?.name ?? '',
        email: address?.contact?.email?.email ?? '',
        address: isArray(address?.address?.address) ? address?.address?.address[0].fullAddress || '' : '',
      };
      return (
        <AppointmentInfo
          onClose={onClose}
          company={company}
          appointment={data}
          isLoading={isLoadingAppointment || isLoadingAddress}
          price={updatedPrice}
        />
      );
    }
    case EventStatus.CANCELLED:
    default: {
      return null;
    }
  }
};
