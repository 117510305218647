import { AddressActivities, AddressServices, DeleteAddress, Employees, WorkingTime } from '../components';

export const GeneralData = () => (
    <>
      <AddressActivities />
      <AddressServices />
      <Employees />
      <WorkingTime />
      <DeleteAddress />
    </>
  );
