import { useTranslation } from 'react-i18next';
import { capitalize } from 'lodash';
import { Badge, BodyLarge, BodySmall, Caption, colors, Flex, H5, H6 } from '@beauty/beauty-market-ui';
import { AdditionalTool, PaymentBar } from 'components';
import { usePaymentForSubscription, usePluralForm } from 'hooks';
import { SubscriptionDataType } from 'types';
import { BorderedPaper, ColoredPaper } from '../style';

type Props = {
  period: string;
  planData: SubscriptionDataType;
  onClose?: () => void;
};

export const PaymentForSubscription = ({ period, planData, onClose }: Props) => {
  const { t } = useTranslation();
  const { onSubmit, isSubmitting, card, setCard, isAnnually, whatsApp, telegram, employee, mainPrice, totalPrice } =
    usePaymentForSubscription({ period, planData, onClose });

  return (
    <PaymentBar
      label={t('subscriptions.upgradePlan')}
      descriptor={t('subscriptions.chooseThePlanSubscribe')}
      confirmationLabel={t('subscriptions.pay')}
      cancelLabel={t('button.cancel')}
      onSubmit={onSubmit}
      onClose={onClose}
      isLoading={isSubmitting}
      card={card}
      setCard={setCard}
    >
      <Flex flexDirection="column" gap="40px">
        <ColoredPaper>
          <Badge
            text={isAnnually ? t('subscriptions.payAnnually') : t('subscriptions.payMonthly')}
            design="lightGrey"
            p="14px 16px"
            mb="86px"
          />
          <BodySmall color={colors.white.standard} lowline mb="8px">{`${capitalize(planData.planType)} ${t(
            'subscriptions.plan',
          )}`}</BodySmall>
          <Flex alignItems="flex-end" gap="4px">
            <H6 color={colors.white.standard}>{mainPrice}</H6>
            <Caption color={colors.white.standard}>
              / {isAnnually ? t('subscriptions.year') : t('subscriptions.month')}
            </Caption>
          </Flex>
        </ColoredPaper>
        <Flex flexDirection="column" gap="16px" width="100%">
          <Flex flexDirection="column" gap="8px">
            <H5>{t('subscriptions.additionalTools')}</H5>
            <BodySmall>{t('subscriptions.inAdditionToChosenPlan')}</BodySmall>
          </Flex>
          <AdditionalTool
            title={t('subscriptions.whatsAppNotificationBot')}
            subtitle={t('subscriptions.smartBotWhichNotify')}
            price={
              whatsApp.isIncluded
                ? ''
                : `${whatsApp.price} ${isAnnually ? t('subscriptions.perYear') : t('subscriptions.perMonth')}`
            }
            checked={whatsApp.checked}
            onToggle={whatsApp.isIncluded ? undefined : () => whatsApp.setWhatsApp(prevState => !prevState)}
          />
          <AdditionalTool
            title={t('subscriptions.telegramNotificationBot')}
            subtitle={t('subscriptions.smartBotWhichNotify')}
            price={
              telegram.isIncluded
                ? ''
                : `${telegram.price} ${isAnnually ? t('subscriptions.perYear') : t('subscriptions.perMonth')}`
            }
            checked={telegram.checked}
            onToggle={telegram.isIncluded ? undefined : () => telegram.setTelegram(prevState => !prevState)}
          />
        </Flex>
        <BorderedPaper>
          <BodyLarge>{t('subscriptions.paymentInformation')}</BodyLarge>
          <Flex flexDirection="column" gap="8px">
            <Flex justifyContent="space-between">
              <BodySmall color={colors.grey.dark}>{t('subscriptions.tariffPayment')}</BodySmall>
              <BodySmall color={colors.grey.dark}>{mainPrice}</BodySmall>
            </Flex>
            {employee.num !== 0 && (
              <Flex justifyContent="space-between">
                <BodySmall color={colors.grey.dark}>{usePluralForm('subscriptions.employees', employee.num)}</BodySmall>
                <BodySmall color={colors.grey.dark}>{employee.price}</BodySmall>
              </Flex>
            )}
            {whatsApp.amount !== 0 && whatsApp.checked && (
              <Flex justifyContent="space-between">
                <BodySmall color={colors.grey.dark}>{t('subscriptions.whatsPayment')}</BodySmall>
                <BodySmall color={colors.grey.dark}>{whatsApp.price}</BodySmall>
              </Flex>
            )}
            {telegram.amount !== 0 && telegram.checked && (
              <Flex justifyContent="space-between">
                <BodySmall color={colors.grey.dark}>{t('subscriptions.telegramPayment')}</BodySmall>
                <BodySmall color={colors.grey.dark}>{telegram.price}</BodySmall>
              </Flex>
            )}
          </Flex>
          <Flex justifyContent="space-between">
            <BodySmall>{t('subscriptions.total')}</BodySmall>
            <BodySmall>{totalPrice}</BodySmall>
          </Flex>
        </BorderedPaper>
      </Flex>
    </PaymentBar>
  );
};
