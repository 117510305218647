import { Flex, Avatar, H5, BodySmall, colors, getMinWidthMediaQuery, useMediaQuery } from '@beauty/beauty-market-ui';
import { ImageType } from '../../types';

type RatingCardProps = {
  avatarUrl: ImageType;
  title: string;
  info: string;
};

export const RatingCard = ({ avatarUrl, title, info }: RatingCardProps) => {
  const mediaQuery = getMinWidthMediaQuery('md');
  const isMobile = !useMediaQuery(mediaQuery);

  const avatarSize = isMobile ? 'l' : 'xl';

  return (
    <Flex flexDirection="column" alignItems="center">
      <Avatar size={avatarSize} url={avatarUrl} />
      <Flex flexDirection="column" justifyContent="end" mt="16px" mb="104px" maxWidth="240px">
        <H5 mb="5px" textAlign="center">
          {title}
        </H5>
        <BodySmall lowline textAlign="center" color={colors.grey.dark}>
          {info}
        </BodySmall>
      </Flex>
    </Flex>
  );
};
