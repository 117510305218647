import { ReactElement } from 'react';
import { TFunction } from 'react-i18next';
import Resizer from 'react-image-file-resizer';
import { CountryCode } from 'libphonenumber-js/max';
import { capitalize, get, head, isNil, omitBy, pickBy, values } from 'lodash';
import moment, { Moment } from 'moment';
import { Nullable } from 'tsdef';

import { AppointmentStatus, calendarIcons, colors, DataList, Div, Icon, Separator } from '@beauty/beauty-market-ui';
import { DollarIcon, EuroIcon, ShekelIcon } from 'assets';
import { MINUTES_PER_SLOT } from 'components/Calendar/constant';
import { EventType, SpecialistEvents, TimeslotType } from 'types/event';
import {
  AccessRange,
  Currency,
  DAYS_PER_WEEK,
  generalTelegramReg,
  getSelectedLanguage,
  instagramDomain,
  InvalidVariants,
  MARKET_PRED_URL,
  MARKET_TEST_URL,
  MARKET_URL,
  regionListCode,
  VALID_IMAGES,
} from '../constants';
import { useDynamicTranslation } from '../hooks/useDynamicTranslation';
import { RouterUrl } from '../routes/routes';
import { useAppSelector } from '../store/hooks';
import { ThunkAddress } from '../store/redux-slices/addressSlice';
import { selectUserHead } from '../store/redux-slices/userSlice';
import { AppDispatch } from '../store/store';
import { packPhotoStoreToFormData } from '../store/utils';
import {
  AccessLevel,
  ImageType,
  Language,
  MenuItemType,
  OrganisationSubscriptionType,
  PhotoStoreType,
  Role,
  SpecialistResponse,
  SpecialistScheduleType,
  SubscriptionDataType,
  SubscriptionPricePlanType,
  SubscriptionPriceType,
  WorkDayType,
  WorkTimeslot,
} from '../types';
import { ApiAction } from '../types/api';
import { Text, TextWithTranslations } from '../types/general';
import { HeadOrganizationSpecialistType } from '../types/user';

export const range = (start: number, end: number): number[] => {
  const length = end - start + 1;
  return Array.from({ length }, (__, idx) => idx + start);
};

export const getRandomInt = (min: number, max: number) => {
  const minValue = Math.ceil(min);
  const maxValue = Math.floor(max);
  return Math.floor(Math.random() * (maxValue - minValue) + minValue);
};

export const getHost = () => window.location.origin;

export const getMarketplaceUrl = (page: string) => {
  const host = getHost();
  const url = (() => {
    if (host.includes('localhost')) return `http://localhost:3000`;
    if (host.includes('crm0')) return `${MARKET_TEST_URL}`;
    if (host.includes('dev')) return `${MARKET_PRED_URL}`;
    return `${MARKET_URL}`;
  })();
  return `${url}/${page}`;
};

export const getFullOrganisationLink = (id: string) => {
  const url = getHost();

  return `${url}/organisation/${id}`;
};

export const openLink = (id: string, query?: string) => {
  const link = getFullOrganisationLink(id);
  window.open(link + (query || ''), '_blank');
};

const pickTranslationByLocale = (translations: Text[], language: string) => {
  const translation = head(values(pickBy(translations, { langId: language })));
  return translation?.text || null;
};

export const getTranslation = (title: TextWithTranslations, language: string) => {
  const defaultText = title.text;
  const isTranslationExists = get(title, 'translation', []).length > 0;
  const translation = isTranslationExists && pickTranslationByLocale(title.translation!, language);
  return translation || defaultText;
};

export const getInvalidType = (errorCode: number, type: ApiAction) => {
  switch (errorCode) {
    case 400: {
      switch (type) {
        case 'changeEmail':
        case 'signUp':
          return InvalidVariants.Email;
        case 'changePhone':
        case 'registration':
          return InvalidVariants.InvalidPhone;
        case 'codeVerification':
        case 'login':
          return InvalidVariants.ExpiredOTP;
        default:
          return InvalidVariants.Unknown;
      }
    }
    case 401:
      return InvalidVariants.InvalidOTP;
    case 404:
      return InvalidVariants.NotFound;
    case 409:
      return InvalidVariants.Exists;
    case 429:
      return InvalidVariants.TooMany;
    case 500:
      return InvalidVariants.MaxAttempts;
    default:
      return InvalidVariants.Unknown;
  }
};

export const getWeekStartDay = (date: moment.Moment) => (date.day() ? moment(date).day(1) : moment(date).day(-6));

export const toFormData: (objectData: object, withNull?: boolean) => FormData = (objectData, withNull = false) => {
  const formData = new FormData();
  Object.entries(withNull ? objectData : omitBy(objectData, isNil)).map(el => formData.append(el[0], el[1]));
  return formData;
};

export const getQueryParams = params => {
  const filterdParams = omitBy(params, isNil); // Remove all undefined values
  return `?${new URLSearchParams(filterdParams).toString()}`;
};

export const hasArrayChanged = (arr1, arr2) => {
  if (arr1.length !== arr2.length) {
    return true;
  }
  return !arr1.every(item => arr2.includes(item));
};

export const checkPhotosValid = (photos, t: TFunction<'translation', undefined>, maxCount = 5): string | null => {
  let count = 0;
  for (const photo of photos) {
    count += 1;
    if (photo instanceof File) {
      if (!VALID_IMAGES.includes(photo.type)) {
        return t('validation.incorrectImageFormat');
      }

      const size = photo.size / (1024 * 1024);
      if (size > 6) {
        return t('validation.sizeLess6');
      }
    }
  }

  if (count > maxCount) {
    return useDynamicTranslation('validation.photosLessMax', { max: maxCount });
  }
  if (count === maxCount) {
    return null;
  }
  return '';
};

export const hasScheduleChanges = (schedule1: WorkDayType[], schedule2: WorkDayType[]): boolean => {
  if (schedule1.length !== schedule2.length) {
    return true;
  }

  for (let i = 0; i < schedule1.length; i += 1) {
    const day1 = schedule1[i];
    const day2 = schedule2[i];

    if (day1.start !== day2.start || day1.end !== day2.end || day1.isWorkDay !== day2.isWorkDay) {
      return true;
    }
  }

  return false;
};

export const isScheduleValid = (schedule: WorkDayType[]): boolean => {
  let countWorkdays = 0;
  for (let i = 0; i < schedule.length; i += 1) {
    const day = schedule[i];
    if (day.isWorkDay) {
      countWorkdays += 1;
      if (day.start >= day.end) return false;
    }
  }
  return countWorkdays !== 0;
};

export const formatPhoneNumber = (code?: string, phoneNumber?: string) => {
  if (!phoneNumber || !code) return '';

  let formattedNumber = '';
  const countryCode = code[0] === '+' ? code : `+${code}`;

  switch (countryCode) {
    case '+1':
      // USA & Canada (+1 (555) 123-4567)
      formattedNumber = `${countryCode} (${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(3, 6)}-${phoneNumber.slice(
        6,
      )}`;
      break;
    case '+44':
      // United Kingdom (+44 20 1234 5678)
      formattedNumber = `${countryCode} ${phoneNumber.slice(0, 2)} ${phoneNumber.slice(2, 6)} ${phoneNumber.slice(6)}`;
      break;
    case '+972':
      // Israel (+972 50-123-4567)
      formattedNumber = `${countryCode} ${phoneNumber.slice(0, 2)}-${phoneNumber.slice(2, 5)}-${phoneNumber.slice(5)}`;
      break;
    case '+48':
      // Poland (+48 123 456 789)
      formattedNumber = `${countryCode} ${phoneNumber.slice(0, 3)} ${phoneNumber.slice(3, 6)} ${phoneNumber.slice(6)}`;
      break;
    case '+375':
      // Belarus (+375 29 123-45-67)
      formattedNumber = `${countryCode} ${phoneNumber.slice(0, 2)} ${phoneNumber.slice(2, 5)}-${phoneNumber.slice(
        5,
        7,
      )}-${phoneNumber.slice(7)}`;
      break;
    case '+7':
      // Russia, Kazakhstan (+7 (999) 123-45-67 or +7 (7172) 123-456)
      if (phoneNumber.length === 10) {
        formattedNumber = `${countryCode} (${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(3, 6)}-${phoneNumber.slice(
          6,
          8,
        )}-${phoneNumber.slice(8)}`;
      } else if (phoneNumber.length === 11) {
        formattedNumber = `${countryCode} (${phoneNumber.slice(0, 4)}) ${phoneNumber.slice(4, 7)}-${phoneNumber.slice(
          7,
        )}`;
      } else {
        formattedNumber = `${countryCode}${phoneNumber}`;
      }
      break;
    case '+90':
      // Turkey (+90 555 123 4567)
      formattedNumber = `${countryCode} ${phoneNumber.slice(0, 3)} ${phoneNumber.slice(3, 6)} ${phoneNumber.slice(6)}`;
      break;
    default:
      formattedNumber = `${countryCode}${phoneNumber}`;
      break;
  }

  return formattedNumber[formattedNumber.length - 1] === '-'
    ? formattedNumber.slice(0, formattedNumber.length - 1)
    : formattedNumber;
};

export const getHoursAndMinutesForUserLocale = (durationInMinutes: number, language: string) => {
  const hours = parseInt(`${durationInMinutes / 60}`, 10);
  const minutes = durationInMinutes % 60;

  const enFormatter = new Intl.RelativeTimeFormat('EN', { numeric: 'always' });
  const formatter = new Intl.RelativeTimeFormat(language, { numeric: 'always' });

  const hoursParts =
    language !== 'HE'
      ? formatter.formatToParts(hours, 'hours')
      : [
          { value: '' },
          { value: enFormatter.formatToParts(hours, 'hours')[1].value },
          { value: formatter.formatToParts(0, 'hours')[2].value },
        ];
  const minsParts = formatter.formatToParts(minutes, 'minutes');

  const minutesDuration = `${minsParts[1].value}${minsParts[2].value}`;
  const hoursDuration = `${hoursParts[1].value}${hoursParts[2].value}`;

  const hoursAndMinsDuration = `${hoursDuration} ${Number(minsParts[1].value) ? minutesDuration : ''}`;

  return hours && hoursDuration ? hoursAndMinsDuration : minutesDuration;
};

export const getFormattedCurrency = (amount: number | string, currency: Nullable<string>, language: string) => {
  if (!currency) return '';
  const formatter = Intl.NumberFormat(language, {
    currency,
    style: 'currency',
    minimumFractionDigits: 0,
    maximumFractionDigits: 1,
    useGrouping: false,
  });
  return formatter.format(Number(amount));
};

// still not in use (currencySymbol object from constants except)
export const getCurrencySymbol = (currency: Nullable<string>, language: string) => {
  if (!currency) return '';

  const formatter = Intl.NumberFormat(language, {
    style: 'currency',
    currency,
  });

  const parts = formatter.formatToParts(0);
  const symbol = parts.find(part => part.type === 'currency')?.value;
  return symbol || '';
};

export const resizeFile = (file: File): Promise<File> =>
  new Promise(resolve => {
    Resizer.imageFileResizer(
      file,
      300,
      300,
      'JPEG',
      100,
      0,
      uri => {
        resolve(uri as File);
      },
      'file',
    );
  });

export const getListRegionCode = () => regionListCode;

export const checkAccess = (accessRange: AccessLevel[]) => {
  const userHead = useAppSelector(selectUserHead);
  if (userHead) {
    return accessRange.includes(userHead.accessLevel);
  }
  return false;
};

export const getMenuClient = (
  t,
  headOrg: HeadOrganizationSpecialistType | null,
  isDesktop: boolean,
): MenuItemType[] => {
  const menu = [
    // {
    //   id: 0,
    //   item: t('menu.organisationAccount'),
    //   disabled: !headOrg,
    //   link: RouterUrl.ProfileSettings,
    //   accessRange: AccessRange.ADMIN,
    // },
    {
      id: 1,
      item: t('menu.personalProfile'),
      disabled: !headOrg,
      link: RouterUrl.PersonalProfile,
      accessRange: AccessRange.SPECIALIST,
      isMobile: true,
    },
    {
      id: 2,
      item: t('menu.billing'),
      disabled: !headOrg,
      link: RouterUrl.CurrentPlanPage,
      accessRange: AccessRange.OWNER,
      isMobile: false,
    },
  ];

  return menu.filter(link => checkAccess(link.accessRange) && (isDesktop || link.isMobile));
};

export const timeToSlotNumber: (time: Moment) => number = time => {
  const startOfDay = time.clone().startOf('day');
  const diff = time.diff(startOfDay, 'minute');
  return Math.trunc(diff / MINUTES_PER_SLOT);
};

export const getMarkedTimeslots: (events: EventType[], timeslots: TimeslotType[]) => TimeslotType[] = (
  events,
  timeslots,
) => {
  const tempTimeslots = [...timeslots];
  events.forEach((event: { start: Date; end: Date }) => {
    const startSlot = timeToSlotNumber(moment(event.start));
    const endSlot = timeToSlotNumber(moment(event.end));
    range(startSlot, endSlot - 1).forEach(slot => {
      if (tempTimeslots[slot] !== null) tempTimeslots[slot] = true;
    });
  });

  return tempTimeslots;
};

export const getProgress: (timeslots: TimeslotType[][]) => number = timeslots => {
  let totalBooked = 0;
  let totalSum = 0;

  timeslots
    .filter(slot => slot)
    .map(slots =>
      slots.reduce(
        (sum: { booked: number; total: number }, current: TimeslotType) => {
          if (current === true) {
            sum.booked += 1;
            sum.total += 1;
          } else if (current === false) sum.total += 1;
          return sum;
        },
        { booked: 0, total: 0 },
      ),
    )
    .forEach(summary => {
      totalBooked += summary.booked;
      totalSum += summary.total;
    });
  return totalBooked / totalSum;
};

export const getSeparator = (dateOfBirth: string) => {
  if (dateOfBirth.includes('.')) return '.';
  if (dateOfBirth.includes('-')) return '-';
  return '/';
};

export const getSpecialistDataList = (
  specialists: SpecialistResponse[],
  onClick: (id: string) => void,
  filterByRole?: Role,
): ReactElement[] => {
  if (!Array.isArray(specialists)) return [];

  const filteredSpecialists = filterByRole ? specialists.filter(spec => spec.role === filterByRole) : specialists;

  return filteredSpecialists.map((spec, index, arr) => {
    const phone = formatPhoneNumber(spec.account.code, spec.account.number);
    return (
      <Div key={spec.id} onClick={() => onClick(spec.id)}>
        <DataList
          key={spec.id}
          title={spec.specialization}
          label={`${spec.account.name} ${spec.account.surname}`}
          avatarUrl={spec.account.avatarUrl}
          defaultAvatar={!spec.account.avatarUrl}
          description={`${phone} | ${spec.account.email}`}
        />
        {index !== arr.length - 1 && <Separator />}
      </Div>
    );
  });
};

export const extractFullAddress = (address: any) => {
  const { fullAddress, country, city, street, building, office: orgOffice } = address;
  const office = orgOffice ? `, ${orgOffice}` : '';
  return fullAddress || `${country}, ${city}, ${street} st., ${building}${office}`;
};

type StatusType = 'completed' | 'pending';

export const getIconByStatus = (id: string, status: StatusType, size = '16px', alignItems = 'center') => {
  switch (status) {
    case 'pending':
      return (
        <Icon fill={colors.orange.standard} data-tooltip-id={id} alignItems={alignItems} width={size} height={size}>
          <calendarIcons.Pending />
        </Icon>
      );
    case 'completed':
      return (
        <Icon fill={colors.green.standard} data-tooltip-id={id} alignItems={alignItems} width={size} height={size}>
          <calendarIcons.Completed />
        </Icon>
      );
    default:
      return null;
  }
};

export const getPhotoImage = (image: ImageType | null) =>
  image instanceof File ? URL.createObjectURL(image as File) : image;

const getSplitImageFormData = (store: PhotoStoreType): FormData[] => {
  const chunks: FormData[] = [];
  const photoImages: ImageType[] = store.photos.filter(photo => photo instanceof File);
  const mainImage: ImageType = store.mainPhoto instanceof File ? store.mainPhoto : '';
  let i = 0;

  do {
    const data = {
      ...store,
      photos: photoImages.slice(i, i + 5),
      deletePhotos: i === 0 ? store.deletePhotos : [],
      mainPhoto: i === 0 ? mainImage : '',
    };
    chunks.push(packPhotoStoreToFormData(data));
    i += 5;
  } while (i < photoImages.length);

  return chunks;
};

export const updatePhotosByChunk = (store: PhotoStoreType, dispatch: AppDispatch) => {
  const chunks = getSplitImageFormData(store);
  return (
    chunks[0] &&
    dispatch(ThunkAddress.editPhotosChunk(chunks[0]))
      .unwrap()
      .then(() => chunks[1] && dispatch(ThunkAddress.editPhotosChunk(chunks[1])).unwrap())
      .then(() => chunks[2] && dispatch(ThunkAddress.editPhotosChunk(chunks[2])).unwrap())
  );
};

export const prepareInstagramUserName = (link: string | null) => {
  if (!link) return '';
  return link.replace(instagramDomain, '').replace(/^@/, '').replace(/\/$/, '');
};

export const prepareTelegramUserName = (link: string | null) => {
  if (!link) return '';
  const match = link.match(generalTelegramReg);
  if (match && match[4]) {
    return match[4];
  }
  return link;
};

export const preparePhoneNumber = (code?: string, number?: string) =>
  code && number ? `${code.trim()} ${number.trim()}`.replace('+', '') : '';

export const getShortCountryByCode = (code?: string): CountryCode =>
  (regionListCode.find(item => item.code === code)?.short as CountryCode) ?? '';

export const groupPricesByCurrency = (
  prices: SubscriptionPriceType[],
): Record<string, Record<SubscriptionPricePlanType, SubscriptionPriceType>> =>
  prices.reduce((acc, arg) => {
    if (!acc[arg.currency]) acc[arg.currency] = {};
    acc[arg.currency][arg.planType] = arg;
    return acc;
  }, {});

export const preparePlanData = (subscription: OrganisationSubscriptionType | null): SubscriptionDataType | null => {
  if (!subscription) return null;
  return {
    id: subscription.plan.id,
    limit: subscription.plan.orgLimit,
    planType: subscription.plan.planType,
    price: groupPricesByCurrency(subscription.plan.price),
    public: subscription.plan.public,
  };
};

export const getCurrencyIcon = (currency: string) => {
  switch (currency) {
    case Currency.USD:
      return <DollarIcon />;
    case Currency.EUR:
      return <EuroIcon />;
    case Currency.ILS:
      return <ShekelIcon />;
    default:
      return getCurrencySymbol(currency, 'en-EN');
  }
};

export const getFullName = (person: { name?: string; surname?: string }) => [person?.name, person?.surname].join(' ');

export const getStatusList = (t: TFunction<'translation', undefined>, except: AppointmentStatus[] = []) =>
  [
    {
      item: t('status.pending'),
      disabled: false,
      status: AppointmentStatus.PENDING,
    },
    {
      item: t('status.confirmed'),
      disabled: false,
      status: AppointmentStatus.CONFIRMED,
    },
    {
      item: t('status.waiting'),
      disabled: false,
      status: AppointmentStatus.WAITING,
    },
    {
      item: t('status.inprogress'),
      disabled: false,
      status: AppointmentStatus.INPROGRESS,
    },
    {
      item: t('status.completed'),
      disabled: false,
      status: AppointmentStatus.COMPLETED,
    },
    {
      item: t('status.no_show'),
      disabled: false,
      status: AppointmentStatus.NOSHOW,
    },
  ].filter(item => !except.includes(item.status));

export const getMinAndMaxTime = (times: Date[]) => {
  const sortedTimes = [...times].sort((time1, time2) => (moment(time1).isAfter(moment(time2)) ? 1 : -1));
  return { minTime: sortedTimes[0], maxTime: sortedTimes[sortedTimes.length - 1] };
};

export const getFormattedDay = (day: string, language: string) => {
  const dayFormatter = Intl.DateTimeFormat(language, { day: 'numeric' });
  const weekdayFormatter = Intl.DateTimeFormat(language, { weekday: 'long' });
  const monthFormatter = Intl.DateTimeFormat(language, { month: 'short' });

  const date = new Date(day);
  return `${capitalize(weekdayFormatter.format(date))}, ${dayFormatter.format(date)} ${monthFormatter.format(date)}`;
};

export const getCurrencyTemplate: (currencySign: string) => RegExp = currencySign =>
  currencySign === '$ ' ? /^\$ ?\d+$/ : new RegExp(`^${currencySign}\\d+$`);

export const isRtl = () => getSelectedLanguage() === Language.Hebrew;

export const getPluralForm = (count: number) => {
  switch (true) {
    // 5-20
    case count >= 5 && count <= 20:
      return 'many';

    // end 1
    case count % 10 === 1:
      return 'one';

    // end 2, 3, 4
    case count % 10 >= 2 && count % 10 <= 4:
      return 'few';

    default:
      return 'many';
  }
};

export const hasScroll = () => document.body.scrollHeight > window.innerHeight;

export const isHebrew = (text: string) => {
  const hebrewRegex = /[\u0590-\u05FF]/;
  return hebrewRegex.test(text);
};

export const getDir = title => (isHebrew(title) ? 'rtl' : 'ltr');

export const getWorkWeekNumberForDate = (
  baseDate: Moment,
  currentDate: Moment,
  weeksNumber: number,
  startOfWeek = 1,
) => {
  const baseDayOfWeek = baseDate.isoWeekday();
  const startDate = baseDayOfWeek === startOfWeek ? baseDate : baseDate.isoWeekday(startOfWeek);
  const differenceInDays = moment(currentDate).diff(startDate.hour(0).minute(0), 'days');

  return (
    (Math.floor(
      (differenceInDays >= 0 ? differenceInDays : differenceInDays + DAYS_PER_WEEK * weeksNumber) / DAYS_PER_WEEK,
    ) %
      weeksNumber) +
    1
  );
};

export const getScheduleWeekIndex = (schedule: SpecialistScheduleType[], startSchedule: Moment, currentDate: Moment) =>
  schedule.findIndex(week => week.weekNumber === getWorkWeekNumberForDate(startSchedule, currentDate, schedule.length));

export const getNumberOfEvents: (events: { [orgSpecId: string]: SpecialistEvents } | undefined) => {
  total: number;
  totalWithoutBreaks: number;
} = events => {
  let total = 0;
  let totalWithoutBreaks = 0;
  events &&
    Object.keys(events).forEach(spec => {
      total += events[spec].events.length;
      totalWithoutBreaks += events[spec].events.filter(event => !event.resource.isBreak).length;
    });
  return { total, totalWithoutBreaks };
};

export const isWorkingSlotNumber: (index: number, workTimeslot?: WorkTimeslot) => boolean = (index, workTimeslot) =>
  !!workTimeslot &&
  workTimeslot.startNumber !== null &&
  workTimeslot.endNumber !== null &&
  workTimeslot.startNumber <= index &&
  workTimeslot.endNumber >= index &&
  !workTimeslot.breaks?.find(item => index >= item.startNumber && index <= item.endNumber);

export const isFile = (obj: any) => obj instanceof File;
