import { PlusIcon } from '@beauty/beauty-market-ui';
import { StyledPrimaryButton } from './style';

interface PlusButtonProps {
  disabled: boolean;
  handleClick?: () => void;
}

export const PlusButton = ({ disabled, handleClick }: PlusButtonProps) => (
  <StyledPrimaryButton disabled={disabled} onClick={handleClick}>
    <PlusIcon />
  </StyledPrimaryButton>
);
