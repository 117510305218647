import { Views } from 'react-big-calendar';
import { Nullable } from 'tsdef';
import { AppointmentStatus } from '@beauty/beauty-market-ui';
import { EventStatus } from '../constants';
import { Contact, Person, TextWithTranslations } from './general';
import { AddressSpecialistType, TimeType } from './organisation';
import { OrgService } from './service';
import { OrganisationSpecialistResponse, Role } from './specialist';
import { HeadOrganizationType } from './user';

export type TextType = {
  id: string;
  langId: string;
  text: string;
  translation: string[];
};

export type TranslationType = {
  id: string;
  tid: string;
  langId: string;
  text: string;
};

export type TitleType = {
  id: string;
  langId: string;
  text: string;
  translation: TranslationType[];
};

export type HeadOrganizationServiceType = {
  id: string;
  headOrgId: string;
  categoryId: string;
  titleTid: string;
  descrTid: string;
  duration: number;
  listPrice: number;
  title: TitleType;
  descr: TitleType;
  availableFor?: string;
  deleted?: boolean;
  category?: {
    id: string;
    title: TitleType;
    parentId: string;
    parent: {
      id: string;
      title: TitleType;
    };
  };
};
export type AddressType = {
  id: string;
  address: string[];
};

export type OrganisationType = {
  id: string;
  headOrgId: string;
  label: null;
  contactId: string;
  addressId: string;
  primary: true;
  timezone: string;
  address: AddressType;
  headOrganization: HeadOrganizationType;
};

export type OrganisationServiceType = {
  title: string;
  price: null | number;
  duration: number;
};

export type ClientType = Person & Contact;

export type BaseAppointment = {
  id: string;
  clientId: string;
  createdAt: string;
  updatedAt: string;
  start: string;
  end: string;
  googleEventId: null;
  orgSpecId: string;
  orgServId: string;
  status: EventStatus;
};

export type AppointmentType = BaseAppointment & {
  orgService: OrganisationServiceType;
  client: ClientType;
  category: TitleType;
  eventId?: string;
};

export type GoogleEventType = {
  id: string;
  orgSpecId: string;
  start: string;
  end: string;
  title: string;
  status: EventStatus;
  isGoogle: boolean;
  description?: string;
};

export type BreakType = {
  id: string;
  orgSpecialistId: string;
  start: string;
  end: string;
  isGoogle: boolean;
  orgSpecId?: undefined;
};

export type CreateAppointmentParams = {
  orgSpecId: string;
  orgServId: string;
  clientId: string;
  start: string;
  end?: string;
};

export type UpdateAppointmentParams = {
  status?: string;
};

export type FetchAppointmentProps = {
  start?: string;
  end?: string;
  orgId?: string;
  headOrgId?: string;
  orgSpecId?: string;
};

export type CreateClientParamsType = {
  name: string;
  surname: string;
  region?: string | null;
  phone?: string | null;
  email?: string | null;
};

export type ClientsSearchResponse = {
  id: string;
  name: string;
  phone: string;
  avatar: string | null;
};

export type AppointmentByIdResponse = {
  id: string;
  orgSpecId: string;
  orgServId: string;
  start: string;
  end: string;
  clientId: string;
  status: AppointmentStatus;
  createdAt: string;
  updatedAt: string;
  price: string | null;
  client: ClientType & {
    id: string;
    idClientNumber?: string;
  };
  orgService: {
    id: string;
    orgId: string;
    headOrgServId: string;
    price: number;
    headOrgService: {
      id: string;
      headOrgId: string;
      categoryId: string;
      titleTid: string;
      descrTid: string;
      duration: number;
      listPrice: number;
      availableFor: string;
      title: TitleType;
      descr: TitleType;
    };
  };
  orgSpecialist: Omit<OrganisationSpecialistResponse, 'orgSpecId'> & {
    id: string;
  };
  isGoogle: false;
  notes: string;
  clientReviewed: boolean;
  cardNumber?: string;
};

export type AppointmentDataType = {
  name: string;
  surname: string;
  specialization: string;
  avatarUrl: string | null;
  role: Role;
  orgSpecIds: string[];
  appointment: AppointmentType[];
  break: BreakType[];
  googleEvents?: GoogleEventType[];
};

export type AppointmentData = {
  id?: string;
  appointment: AppointmentType[];
  break: BreakType[];
  googleEvents?: GoogleEventType[] | null;
};

export enum AppointmentsAction {
  Create = 'CREATE_APPOINTMENT',
  Update = 'UPDATE_APPOINTMENT',
  Cancel = 'CANCEL_APPOINTMENT',
  Convert = 'CONVERT_GOOGLE',
  Close = 'CLOSE_APPOINTMENT',
  CancelAll = 'CANCEL_ALL',
}

export type SpecialistSummaryType = AddressSpecialistType & {
  serviceIds: string[];
};

export type SpecialistDataType = Record<string, SpecialistSummaryType & AppointmentDataType>;
export type CategoryDataType = Record<
  string,
  { title: TextWithTranslations; orgServiceIds: string[]; serviceIds: string[] }
>;

export type AppointmentsState = {
  view: Views;
  range: TimeType;
  date: string;
  unclosed: AppointmentType[];
  inProgress: AppointmentType[];
  specialistIds: string[];
  specialistData: SpecialistDataType;
  categoryData: CategoryDataType;
  categoryIds: string[];
  orgServices: {
    data: Record<string, OrgService>;
    ids: string[];
  };
  selectedSpecialist: string | null;
  selectedCategory: string | null;
  selectedAddress: string | null;
  selectedClient: string | null;
  isLoading: boolean;
};

export type ServiceOption = {
  id: string;
  title: string;
  description: string;
  price: string | number;
  duration: Nullable<string | number>;
};

export type SpecialistOption = {
  id: string;
  name: string;
  avatarUrl: string | null;
  phone: string;
};

export type AppointmentDateType = {
  item: string;
  disabled: boolean;
};

export type AppointmentPrefilledData = {
  start: string;
  orgSpecialist?: string;
};

export type ConvertGoogleParams = {
  eventId: string;
  orgSpecId: string;
  orgServId: string;
  clientId: string;
  notes: string;
};

export type CloseAppointmentParams = {
  id: string;
  paymentMethod: string;
  price?: number;
};

export type CancelAllAppointmentParams = {
  date: string;
  notification: boolean;
  orgSpecId?: string;
  orgId?: string;
  reason?: string;
};
