import { useTranslation } from 'react-i18next';
import { Caption, colors } from '@beauty/beauty-market-ui';
import { getMarketplaceUrl } from 'helpers/utils';
import { PARTNER_TERMS_URL } from '../../../constants';
import { LinkStyled } from '../style';

export const Rules = () => {
  const { t } = useTranslation();

  return (
    <Caption p="8px 40px" textAlign="center" color={colors.grey.standard}>
      {t('login.rules.description')}
      <LinkStyled design="blue" size="xs" href={getMarketplaceUrl('privacy-policy')} target="_blank">
        {t('login.rules.policy')}
      </LinkStyled>
      ,
      <LinkStyled design="blue" size="xs" href={getMarketplaceUrl('terms-of-use')} target="_blank">
        {t('login.rules.use')}
      </LinkStyled>
      ,
      <LinkStyled design="blue" size="xs" href={getMarketplaceUrl('terms-of-service')} target="_blank">
        {t('login.rules.service')}
      </LinkStyled>
      {t('login.rules.and')}
      <LinkStyled design="blue" size="xs" href={PARTNER_TERMS_URL} target="_blank">
        {t('login.rules.partner')}
      </LinkStyled>
    </Caption>
  );
};
