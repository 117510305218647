import { apiRequest } from 'api/api';
import { ClientEndpoints, HeadOrganisationEndpoints } from 'api/endpoints/endpoints';
import { ClientReviewPayload } from '../types/client';
import { toFormData } from './utils';

const createClient = async ({ data, thunkAPI }) => {
  const { getState, rejectWithValue } = thunkAPI;
  const { id } = getState().organisation.head;
  const formData = toFormData(data);
  const [error, response] = await apiRequest.post({
    endpoint: HeadOrganisationEndpoints.Client,
    endpointParams: id,
    data: formData,
  });
  return response ? response.data : rejectWithValue(error?.data);
};

const updateClient = async ({ data, thunkAPI }) => {
  const { getState, rejectWithValue } = thunkAPI;
  const { headOrgClientId: id } = getState().client.currentClient;
  const formData = toFormData(data, true);
  const [error, response] = await apiRequest.patch({
    endpoint: HeadOrganisationEndpoints.Client,
    endpointParams: id,
    data: formData,
  });
  return response ? response.data : rejectWithValue(error?.data);
};

const getClient = async ({ id, thunkAPI }) => {
  const { rejectWithValue } = thunkAPI;
  const [error, response] = await apiRequest.get({
    endpoint: ClientEndpoints.Root,
    endpointParams: {
      id,
      queryParams: '?include=headOrganization,account,headOrgSpecService,orgSpecialist',
    },
  });
  return response ? response.data : rejectWithValue(error?.data);
};

const fetchClients = async ({ id, thunkAPI }) => {
  const { rejectWithValue } = thunkAPI;
  const [error, response] = await apiRequest.get({
    endpoint: HeadOrganisationEndpoints.AllClients,
    endpointParams: id,
  });
  return response ? response.data : rejectWithValue(error?.data);
};

const fetchClient = async ({ id, thunkAPI }) => {
  const { rejectWithValue } = thunkAPI;
  const [error, response] = await apiRequest.get({
    endpoint: HeadOrganisationEndpoints.Client,
    endpointParams: id,
  });
  return response ? response.data : rejectWithValue(error?.data);
};

export const sendReview = async (id: string, data: ClientReviewPayload) => {
  const [error, response] = await apiRequest.post({
    endpoint: ClientEndpoints.Review,
    endpointParams: id,
    data,
  });
  return response ? response.data : error?.data;
};

const fetchClientInfo = async ({ id, thunkAPI }) => {
  const { rejectWithValue } = thunkAPI;
  const [error, response] = await apiRequest.get({
    endpoint: HeadOrganisationEndpoints.ClientInfo,
    endpointParams: id,
  });
  return response ? response.data : rejectWithValue(error?.data);
};

export const clientAPI = {
  createClient,
  updateClient,
  getClient,
  fetchClients,
  fetchClient,
  sendReview,
  fetchClientInfo,
};
