import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Flex, Title } from '@beauty/beauty-market-ui';
import { extractFullAddress, hasArrayChanged } from 'helpers';
import { useMediaScreen, useRequest } from 'hooks';
import { ServiceIcons } from '../../../constants';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import { selectAllAddresses } from '../../../store/redux-slices/addressSlice';
import { selectSpecialist, ThunkSpecialist } from '../../../store/redux-slices/specialistSlice';
import { SpecialistAction } from '../../../types';
import { SidebarFooter } from '../../SidebarFooter';
import { SidebarSheet } from '../../SidebarSheet';
import { StyledIcon } from '../style';
import { WorkPlace } from './WorkPlace';

const EditWorkplaces = ({ initial, onClose }) => {
  const { t } = useTranslation();
  const { isDesktop } = useMediaScreen('md');
  const dispatch = useAppDispatch();
  const specialist = useAppSelector(selectSpecialist);
  const allAddresses = useAppSelector(selectAllAddresses);

  const editWorkplaces = useRequest(ThunkSpecialist.editWorkplaces, SpecialistAction.EditWorkplace);

  const [dirty, setDirty] = useState<boolean>(false);
  const [selected, setSelected] = useState<string[]>(initial);
  const [isSubmitting, setSubmitting] = useState(false);

  const handleAddressClick = (id: string) => {
    if (selected.includes(id)) {
      const filtered = selected.filter(org => org !== id);
      setSelected(filtered);
    } else {
      setSelected([...selected, id]);
    }
  };

  const handleSelectAllClick = () => {
    selected.length !== allAddresses.length ? setSelected(allAddresses.map(org => org.id)) : setSelected([]);
  };

  const handleSubmit = () => {
    setSubmitting(true);

    const params = {
      headOrgSpecId: specialist.id,
      organizationIds: selected.join(','),
    };
    editWorkplaces(params)
      .then(() => dispatch(ThunkSpecialist.fetchSpecialist(specialist.id)))
      .finally(() => {
        setSubmitting(false);
        onClose();
      });
  };

  useEffect(() => {
    const isDirty = hasArrayChanged(initial, selected);
    setDirty(isDirty);
  }, [selected, allAddresses]);

  return (
    <SidebarSheet
      onClose={onClose}
      onBack={onClose}
      label={t('specialists.chooseOrganisations')}
      descriptor={t('specialists.whereEmployeeWorks')}
      FooterBody={
        <SidebarFooter
          disabled={!dirty}
          onBack={onClose}
          onSubmit={handleSubmit}
          isLoading={isSubmitting}
          save
          cancel
        />
      }
    >
      <>
        {isDesktop ? (
          <Title
            text={t('specialists.addresses')}
            label={t('common.selectAll')}
            checked={selected.length === allAddresses.length}
            onChange={handleSelectAllClick}
          />
        ) : (
          <WorkPlace
            image={<StyledIcon>{ServiceIcons.All}</StyledIcon>}
            service={t('common.selectAll')}
            address={t('specialists.chooseAllAddresses')}
            onChange={handleSelectAllClick}
            checked={selected.length === allAddresses.length}
            isDesktop={false}
            separator
          />
        )}
        <Flex flexDirection="column" mt={isDesktop ? '16px' : '0px'} mb="80px">
          {allAddresses.map((item, index, arr) => (
            <WorkPlace
              key={item.id}
              image={item.mainPhoto?.url}
              service={item.name}
              address={extractFullAddress(item.address)}
              onChange={() => handleAddressClick(item.id)}
              checked={selected.includes(item.id)}
              separator={index !== arr.length - 1}
              isDesktop={isDesktop}
            />
          ))}
        </Flex>
      </>
    </SidebarSheet>
  );
};

export default EditWorkplaces;
