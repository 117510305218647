import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useFormikContext } from 'formik';
import moment from 'moment/moment';
import { Flex } from '@beauty/beauty-market-ui';
import { getEndTimeIndex, getScrollTo } from 'components/Calendar/Sidebars/helpers';
import { FormikDropdown } from 'components/functional/formik/formik-dropdown/FormikDropdown';
import { useTimeList } from 'hooks';
import { AddressPayloadType } from 'types';
import { EventStatus } from '../../../../constants';
import { extractWeek } from '../../../../store/utils';
import { AppointmentFormFields } from './EditAppointment.definitions';
import { SmallCalendar } from './SmallCalendar';

type Props = {
  company: AddressPayloadType;
};

export const TimeSection = ({ company }: Props) => {
  const { t } = useTranslation();
  const { values, setFieldValue } = useFormikContext<AppointmentFormFields>();
  const { Start, End, AppointmentDate, Service, Status } = AppointmentFormFields;

  const [calendarOpen, setCalendarOpen] = useState(false);
  const [startScroll, setStartScroll] = useState(0);
  const [endScroll, setEndScroll] = useState(1);

  const timeList = useTimeList();
  const orgWorkTime = extractWeek(company.orgBusinessHours);

  const handleChangeDate = async (date: Date) => {
    const selectedDate = moment(date).format('DD.MM.YYYY');
    await setFieldValue(AppointmentDate, selectedDate);
    setCalendarOpen(false);
  };

  const handleChangeEndTime = async (index: number) => {
    await setFieldValue(End, timeList[index].item);
  };

  const handleChangeStartTime = async (index: number) => {
    await setFieldValue(Start, timeList[index].item);
    const serviceData = company.orgService?.find(item => item.id === values[Service]);
    const serviceDuration = serviceData ? serviceData.duration : undefined;
    handleChangeEndTime(getEndTimeIndex(index, timeList, serviceDuration));
  };

  useEffect(() => {
    const date = moment(values[AppointmentDate], 'DD.MM.YYYY').toDate();
    const serviceData = company.orgService?.find(item => item.id === values[Service]);
    const serviceDuration = serviceData ? serviceData.duration : undefined;
    const scrollTo = getScrollTo(date, timeList, orgWorkTime, serviceDuration);

    setStartScroll(scrollTo.preScrolledToStart);
    setEndScroll(scrollTo.preScrolledToEnd);
  }, [values[Service], values[AppointmentDate], orgWorkTime]);

  return (
    <Flex gap="8px" position="relative">
      <FormikDropdown
        id={Start}
        name={Start}
        placeholder={t('calendar.newAppointmentSidebar.start')}
        scrollTo={startScroll}
        currentOption={timeList.findIndex(item => item.item === values[Start])}
        options={timeList}
        onChange={handleChangeStartTime}
        isSeparator
        autoScroll
        disabled={values[Status] === EventStatus.INPROGRESS}
      />
      <FormikDropdown
        id={End}
        name={End}
        placeholder={t('calendar.newAppointmentSidebar.end')}
        scrollTo={endScroll}
        currentOption={timeList.findIndex(item => item.item === values[End])}
        options={timeList}
        onChange={handleChangeEndTime}
        isSeparator
        autoScroll
      />
      <FormikDropdown
        id={AppointmentDate}
        name={AppointmentDate}
        placeholder={t('calendar.newAppointmentSidebar.date')}
        currentOption={0}
        options={[{ item: values[AppointmentDate], disabled: false }]}
        onClick={() => setCalendarOpen(prev => !prev)}
        isSeparator
      />
      {calendarOpen && <SmallCalendar date={values[AppointmentDate]} onChange={handleChangeDate} />}
    </Flex>
  );
};
