import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  BodySmall,
  Button,
  Flex,
  getMinWidthMediaQuery,
  H5,
  UpdateIcon,
  useMediaQuery,
} from '@beauty/beauty-market-ui';
import { Paper } from '../../../components';
import { ChangePasswordSidebar } from '../../../components/Sidebar/ChangePasswordSidebar/ChangePasswordSidebar';

export const ChangePassword = () => {
  const { t } = useTranslation();

  const [isOpen, setOpen] = useState(false);

  const mediaQuery = getMinWidthMediaQuery('md');
  const isDesktop = useMediaQuery(mediaQuery);

  return (
    <>
      <Paper
        justifyContent="space-between"
        alignItems="start"
        gap="8px"
        borderRadius={isDesktop ? '0' : '10px'}
        p={isDesktop ? '24px' : '16px 8px'}
      >
        <Flex flexDirection="column" gap="4px" width="100%">
          <H5>{t('profile.changePassword')}</H5>
          <BodySmall>{t('profile.makeSureThatYourAccount')}</BodySmall>
        </Flex>
        <Button
          prefix={isDesktop ? <UpdateIcon width="16px" height="16px" /> : null}
          onClick={() => setOpen(true)}
          design="secondary"
          size="extraSmall"
        >
          {t('profile.change')}
        </Button>
      </Paper>
      {isOpen && <ChangePasswordSidebar onClose={() => setOpen(false)} />}
    </>
  );
};
