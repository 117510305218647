import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { isArray } from 'lodash';
import { Separator, Badge } from '@beauty/beauty-market-ui';
import { EditWorkplaces, PopUp } from 'components';
import { extractFullAddress } from 'helpers';
import { useMediaScreen, useRequest } from 'hooks';
import { useAppSelector } from 'store/hooks';
import { selectAllAddresses } from 'store/redux-slices/addressSlice';
import { selectSpecialist, ThunkSpecialist } from 'store/redux-slices/specialistSlice';
import { SpecialistAction } from 'types';
import { EmployeeSection } from '../EmployeeSection/EmployeeSection';
import { WorkPlace } from './WorkPlace';

export const EmployeeWorkplaces = () => {
  const { t } = useTranslation();
  const { isDesktop } = useMediaScreen('md');
  const { id, organization } = useAppSelector(selectSpecialist);
  const allAddresses = useAppSelector(selectAllAddresses);
  const editWorkplaces = useRequest(ThunkSpecialist.editWorkplaces, SpecialistAction.EditWorkplace);

  const orgIds = isArray(organization) ? organization.map(org => org.orgId) : [];

  const [address, setAddress] = useState('');
  const [action, setAction] = useState<SpecialistAction>(SpecialistAction.None);
  const [isSubmitting, setSubmitting] = useState(false);

  const handleClose = () => {
    setAction(SpecialistAction.None);
  };

  const handleEditWorkplace = () => {
    setAction(SpecialistAction.EditWorkplace);
  };

  const handleDeleteClick = (orgId: string) => {
    setAddress(orgId);
    setAction(SpecialistAction.DeleteWorkplace);
  };

  const handleDeleteWorkplace = () => {
    setSubmitting(true);

    const filteredIds = orgIds.filter(orgId => orgId !== address);
    const params = {
      headOrgSpecId: id,
      organizationIds: filteredIds.join(','),
    };
    editWorkplaces(params).finally(() => {
      setSubmitting(false);
      handleClose();
    });
  };

  const specialistAddressItems = allAddresses
    .filter(item => orgIds.includes(item.id))
    .map((item, index, arr) => (
      <WorkPlace
        key={item.id}
        name={item.name}
        url={item?.mainPhoto?.url}
        address={extractFullAddress(item.address)}
        onDelete={() => handleDeleteClick(item.id)}
        separator={index !== arr.length - 1}
        isDesktop={isDesktop}
      />
    ));

  return (
    <>
      <EmployeeSection
        onClick={handleEditWorkplace}
        count={specialistAddressItems.length}
        title={t('specialists.placesOfWork')}
        description={t('specialists.whereSpecialistWorks')}
        placeholder={t('specialists.haveNotWorkingPlaces')}
        buttonLabel={t('specialists.choosePlaces')}
        separator={isDesktop ? <Separator mb="16px" /> : null}
        badge={specialistAddressItems.length ? <Badge text={specialistAddressItems.length} design="lightGrey" /> : null}
      >
        {specialistAddressItems}
      </EmployeeSection>
      {action === SpecialistAction.EditWorkplace && <EditWorkplaces initial={orgIds} onClose={handleClose} />}
      {action === SpecialistAction.DeleteWorkplace && (
        <PopUp
          title={t('specialists.deleteWorkplace')}
          description={t('specialists.areYouSureDeleteWorkplace')}
          confirm={t('button.delete')}
          onClose={handleClose}
          onSubmit={handleDeleteWorkplace}
          isLoading={isSubmitting}
        />
      )}
    </>
  );
};
