export const editFields = {
  photos: {
    mainPhoto: true,
    photos: true,
  },
  mainInformation: {
    name: true,
    property: false,
    number: true,
    email: true,
    description: false,
    instagram: true,
    telegram: true,
    whatsapp: true,
  },
  detailedInformation: {
    country: true,
    city: true,
    street: true,
    building: true,
    office: true,
    postal: true,
    description: true,
  },
  schedule: true,
};
