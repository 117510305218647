import { Nullable } from 'tsdef';
import { TitleType } from './appointment';
import { OptionType, PhotoType, TextWithTranslations } from './general';
import { ServiceShortType } from './organisation';

export type ReviewType = {
  id: string;
  username: string;
  date: string;
  rating: string;
  text: string;
  isSidebar?: boolean;
  avatarUrl?: string;
};

export type ServiceType = {
  id: string;
  title: string;
  label: string;
  description: string;
  oldPrice: string | null;
  price: string;
  categoryId: string;
  about?: string;
  photos?: string[];
  reviews?: ReviewType[];
};

export type SpecialRequirementType = {
  title: string;
  text: string;
};

export type CategoryDataType = {
  id: string;
  parentId: string | null;
  title: TextWithTranslations;
};

export type TopCategoryType = CategoryDataType & {
  childs: CategoryDataType[];
};

export type CategoryOptionType = CategoryDataType & OptionType;

export type CategoryResponseType = {
  id: string;
  headOrgId: string;
  serviceCategoryId: string;
  serviceCategory: TopCategoryType;
};

export type TopCategoryStateType = {
  [key: string]: TopCategoryType;
};

export type OrgService = {
  id: string;
  price: Nullable<number>;
  duration: Nullable<string>;
  title: TitleType;
  deleted?: boolean;
};

export type ServiceStateType = {
  isLoading: boolean;
  allServices: ServiceShortType[];
  categories: TopCategoryStateType;
  topIds: string[];
  newService: {
    id?: string;
    category: string | null;
    service: string | null;
    language: string;
    name: string;
    photos: Array<File | string>;
    description: string;
    availableFor: string;
    duration: string;
    price: string | null;
    photoStore?: {
      save: PhotoType[];
      del: PhotoType[];
    };
  };
};

export type OrganisationServiceResponse = {
  id: string;
  price: Nullable<number>;
  duration: Nullable<string>;
  title: TitleType;
  availableFor: string;
  category: {
    id: string;
    title: TitleType;
  };
  headOrgServiceId?: string;
  published?: boolean;
  deleted?: boolean;
};

export enum ServiceAction {
  Create = 'CREATE_SERVICE',
  Update = 'UPDATE_SERVICE',
  Delete = 'DELETE_SERVICE',
}

export type RelatedCategoryType = {
  id: string;
  parentId: string;
  parent: CategoryDataType;
};
