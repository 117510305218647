import { TFunction } from 'react-i18next';
import { isValidPhoneNumber } from 'libphonenumber-js/max';
import * as Yup from 'yup';
import { emailReg } from '../../../constants';
import { getShortCountryByCode } from '../../../helpers';
import { useAppSelector } from '../../../store/hooks';
import { selectUserHead } from '../../../store/redux-slices/userSlice';
import { ProfileType } from '../../../types/user';

export enum EditProfileFormFields {
  Name = 'name',
  Surname = 'surname',
  Email = 'email',
  Code = 'code',
  Number = 'number',
  Role = 'role',
  Photo = 'photo',
}

export type EditProfileFormType = {
  [EditProfileFormFields.Name]: string | null;
  [EditProfileFormFields.Surname]: string | null;
  [EditProfileFormFields.Email]: string | null;
  [EditProfileFormFields.Number]: string | null;
  [EditProfileFormFields.Code]: string | null;
  [EditProfileFormFields.Role]: string | number;
  [EditProfileFormFields.Photo]?: string | File | null;
};

export const initialValues = (profile: ProfileType, positionOptions) => {
  const userHead = useAppSelector(selectUserHead);
  const positionIndex = userHead?.role ? positionOptions.findIndex(item => item.id === userHead.role) : -1;

  return {
    [EditProfileFormFields.Name]: profile.name || '',
    [EditProfileFormFields.Surname]: profile.surname || '',
    [EditProfileFormFields.Email]: profile.email || '',
    [EditProfileFormFields.Number]: profile.number || '',
    [EditProfileFormFields.Code]: profile.code || '',
    [EditProfileFormFields.Role]: positionIndex,
    [EditProfileFormFields.Photo]: profile.avatarUrl,
  };
};

export const validationSchema = (t: TFunction<'translation', undefined>) =>
  Yup.object({
    [EditProfileFormFields.Name]: Yup.string()
      .trim()
      .min(2, t('validation.minName'))
      .max(50, t('validation.maxName'))
      .required(t('validation.notEmpty')),
    [EditProfileFormFields.Surname]: Yup.string()
      .trim()
      .min(1, t('validation.minName1'))
      .max(50, t('validation.maxName'))
      .required(t('validation.notEmpty')),
    [EditProfileFormFields.Code]: Yup.string(),
    [EditProfileFormFields.Number]: Yup.string()
      .trim()
      .required(t('validation.phoneRequired'))
      .test('validate-code', t('validation.codeRequired'), function validateCode() {
        return !!this.parent[EditProfileFormFields.Code];
      })
      .test('validate-phone', t('validation.enterValidPhone'), function validatePhone(phone) {
        const code = this.parent[EditProfileFormFields.Code];
        if (code) {
          return isValidPhoneNumber(`${code} ${phone}`, getShortCountryByCode(code));
        }
        return false;
      }),
    [EditProfileFormFields.Email]: Yup.string()
      .trim()
      .email(t('validation.emailNotValid'))
      .matches(emailReg, t('validation.emailNotValid'))
      .required(t('validation.notEmpty')),
    [EditProfileFormFields.Role]: Yup.number().min(0, t('validation.notEmpty')),
  });
