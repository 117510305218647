import { memo, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import isArray from 'lodash/isArray';
import isEmpty from 'lodash/isEmpty';
import {
  Avatar,
  Badge,
  BodySmall,
  Button,
  colors,
  getMinWidthMediaQuery,
  H6,
  PlusIcon,
  Separator,
  useMediaQuery,
} from '@beauty/beauty-market-ui';
import {
  DetailedInformation,
  EditAddress,
  InfoBlock,
  MainInformation,
  Photos,
  Schedule,
  Tooltip,
  Widget,
} from 'components';
import { useStepper } from 'hooks';
import { clearCurrentData } from '../../../store/actions/common';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import { selectAllAddresses, ThunkAddress } from '../../../store/redux-slices/addressSlice';
import { selectSubscriptionPlan } from '../../../store/redux-slices/organisationSlice';
import { editFields } from './helpers';

const Address = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const planSubscription = useAppSelector(selectSubscriptionPlan);
  const allAddress = useAppSelector(selectAllAddresses) || [];

  const [openEditBar, setIsOpenEditBar] = useState(false);

  const isLimitExceeded = isArray(allAddress) && allAddress.length >= (planSubscription?.orgLimit ?? 0);

  const mediaQuery = getMinWidthMediaQuery('md');
  const isDesktop = useMediaQuery(mediaQuery);

  const title = useMemo(
    () => [
      '',
      t('settings.addresses.stepper.main'),
      t('settings.addresses.stepper.detailed'),
      t('settings.addresses.stepper.schedule'),
      t('settings.addresses.stepper.about'),
    ],
    [t],
  );

  const { currentStep, StepperComponent, handleBackStep, handleNextStep, handleReset, handleFirstStep } = useStepper({
    title,
  });

  const handleOpenAddBar = () => {
    dispatch(clearCurrentData());
    handleFirstStep();
  };

  const handleClose = () => {
    if (openEditBar) setIsOpenEditBar(false);
    dispatch(clearCurrentData());
    handleReset();
  };

  const addressItems = useMemo(() => {
    if (!isArray(allAddress)) return [];
    return allAddress.map((item, index, arr) => {
      const handleAddressClick = async () => {
        await dispatch(ThunkAddress.fetchAddressById(item.id));
        setIsOpenEditBar(true);
      };
      const { fullAddress, country, city, street, building, office: orgOffice } = item.address;
      const office = orgOffice ? `, ${orgOffice}` : '';
      const description = fullAddress || `${country}, ${city}, ${street} st., ${building}${office}`;
      return (
        <Widget
          key={item.id}
          title={<H6>{item.name}</H6>}
          subtitle={<BodySmall color={colors.grey.dark}>{description}</BodySmall>}
          avatar={isDesktop ? null : <Avatar url={item?.mainPhoto?.url} size="s" />}
          onClick={handleAddressClick}
          separator={index !== arr.length - 1}
          p="16px 0"
        />
      );
    });
  }, [allAddress, isDesktop]);

  const empty = isEmpty(addressItems);
  const button = (
    <Tooltip id="add-new-address-button" content={t('validation.haveReachedOrgLimit')} show={isLimitExceeded}>
      <Button
        data-tooltip-id="add-new-address-button"
        size={empty && !isDesktop ? 'small' : 'extraSmall'}
        onClick={handleOpenAddBar}
        disabled={isLimitExceeded}
        prefix={<PlusIcon width="16px" height="16px" />}
        my={empty && !isDesktop ? '16px' : '0'}
      >
        {(empty || isDesktop) && t('button.add')}
      </Button>
    </Tooltip>
  );

  return (
    <>
      <InfoBlock
        title={t('settings.addresses.title')}
        description={t('settings.addresses.description')}
        placeholder={t('settings.addresses.empty')}
        count={addressItems.length}
        badge={!empty && <Badge text={addressItems.length} design="lightGrey" />}
        separator={isDesktop ? <Separator mb={empty ? '16px' : '0'} /> : null}
        visibleCount={1}
        position="default"
        button={button}
        emptyButton={button}
      >
        {addressItems}
      </InfoBlock>
      {currentStep === 1 && (
        <MainInformation
          stepper={StepperComponent}
          handleClose={handleClose}
          handleBackStep={handleClose}
          handleNextStep={handleNextStep}
        />
      )}
      {currentStep === 2 && (
        <DetailedInformation
          stepper={StepperComponent}
          handleClose={handleClose}
          handleBackStep={handleBackStep}
          handleNextStep={handleNextStep}
        />
      )}
      {currentStep === 3 && (
        <Schedule
          stepper={StepperComponent}
          handleClose={handleClose}
          handleBackStep={handleBackStep}
          handleNextStep={handleNextStep}
        />
      )}
      {currentStep === 4 && (
        <Photos stepper={StepperComponent} handleClose={handleReset} handleBackStep={handleBackStep} />
      )}
      {openEditBar && <EditAddress fields={editFields} onClose={handleClose} />}
    </>
  );
};

export const AddressSection = memo(Address);
