import { Avatar, BodyLarge, BodySmall, Caption, Checkbox, colors, Flex, Separator } from '@beauty/beauty-market-ui';
import { Adaptive } from 'hoc';

export const WorkPlace = ({ image, separator, service, address, onChange, checked, isDesktop }) => (
  <>
    <Flex alignItems="center" justifyContent="space-between" p="8px 0">
      <Flex gap="8px">
        {typeof image === 'string' || typeof image === 'undefined' ? <Avatar url={image} rating="" size="s" /> : image}
        <Flex flexDirection="column" width={isDesktop ? '330px' : '65vw'} justifyContent="center" gap="4px">
          <Adaptive mobile={BodyLarge} desktop={BodySmall} truncated large={!isDesktop} title={service}>
            {service}
          </Adaptive>
          <Adaptive mobile={Caption} desktop={BodySmall} truncated color={colors.grey.dark} title={address}>
            {address}
          </Adaptive>
        </Flex>
      </Flex>
      <Checkbox shape="round" onCheckboxClick={onChange} checked={checked} />
    </Flex>
    {separator && <Separator />}
  </>
);
