import { useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Button, Flex, getMinWidthMediaQuery, H5, useMediaQuery } from '@beauty/beauty-market-ui';
import { Paper, Tooltip } from 'components';
import { checkAccess } from 'helpers';
import { useRequest } from 'hooks';
import { ProfileActions } from 'types';
import { AccessRange, transComponents } from '../../../constants';
import { useAppSelector } from '../../../store/hooks';
import { selectHeadOrgTelegram } from '../../../store/redux-slices/organisationSlice';
import { selectProfile, selectUser, ThunkUser } from '../../../store/redux-slices/userSlice';
import { BlueSpanInclude } from '../style';
import { TelegramPopup } from './TelegramPopup';

export const Telegram = () => {
  const { t } = useTranslation();
  const { user } = useAppSelector(selectUser);
  const profile = useAppSelector(selectProfile);
  const hasHeadOrgTelegram = useAppSelector(selectHeadOrgTelegram);
  const isSuperAdmin = checkAccess(AccessRange.SUPERADMIN);

  const disconnectTelegram = useRequest(ThunkUser.disconnectTelegram, ProfileActions.DisconnectTelegram);

  const [isLoading, setLoading] = useState(false);
  const [isOpen, setOpen] = useState(false);

  const isTelegram = !!profile.telegram;
  const mediaQuery = getMinWidthMediaQuery('md');
  const isDesktop = useMediaQuery(mediaQuery);

  const handleTelegramConnect = () => {
    setLoading(true);
    window.open(`${process.env.REACT_APP_TELEGRAM_URL}?start=${user.userId}`, '_blank');
    setOpen(false);
  };

  const handleTelegramDisconnect = () => {
    setLoading(true);
    disconnectTelegram().finally(() => {
      setOpen(false);
      setLoading(false);
    });
  };

  const userMessage = profile?.telegram?.userName
    ? t('profile.yourPlatformSyncWithUserName', { value: 'Telegram', name: profile.telegram.userName })
    : t('profile.yourPlatformSyncWithOutUserName', { value: 'Telegram' });

  return (
    <>
      <Paper
        justifyContent="space-between"
        alignItems="start"
        gap="8px"
        borderRadius={isDesktop ? '0' : '10px'}
        p={isDesktop ? '24px' : '16px 8px'}
      >
        <Flex flexDirection="column" gap="4px" width="100%">
          <H5>{t('profile.connectTelegramTitle')}</H5>
          <BlueSpanInclude maxWidth="90%">
            <Trans components={transComponents}>{isTelegram ? userMessage : t('profile.connectTelegramInfo')}</Trans>
          </BlueSpanInclude>
        </Flex>
        <Tooltip
          id="telgram-connect"
          content={
            isSuperAdmin
              ? t('profile.telegramIsNotAvailableForSuperAdmin')
              : t('profile.telegramIsNotAvailableForSpecialist')
          }
          show={!isTelegram && !hasHeadOrgTelegram}
        >
          <Button
            data-tooltip-id="telgram-connect"
            onClick={() => setOpen(true)}
            disabled={isLoading || !profile.id || (!isTelegram && !hasHeadOrgTelegram)}
            design={isTelegram ? 'secondary' : 'primary'}
            size="extraSmall"
          >
            {isTelegram ? t('profile.disconnect') : t('profile.connect')}
          </Button>
        </Tooltip>
      </Paper>
      {isOpen && (
        <TelegramPopup
          isTelegram={isTelegram}
          onClose={() => setOpen(false)}
          connect={handleTelegramConnect}
          disconnect={handleTelegramDisconnect}
        />
      )}
    </>
  );
};
