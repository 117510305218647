import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import {
  BodyLarge,
  BodySmall,
  Button,
  Caption,
  Flex,
  H5,
  H6,
  Link,
  NotifyStatus,
  TrashIcon,
  useNotify,
} from '@beauty/beauty-market-ui';
import { PopUp } from 'components';
import { checkAccess, getAddressNotifyContent } from 'helpers';
import { Adaptive } from 'hoc';
import { useMediaScreen } from 'hooks';
import { RouterUrl } from 'routes/routes';
import { ThunkCommon } from 'store';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { selectNewAddress } from 'store/redux-slices/addressSlice';
import { AddressAction } from 'types';
import { AccessRange } from '../../../../../constants';
import { Wrapper } from '../../style';

export const DeleteAddress = () => {
  const { t } = useTranslation();
  const { isDesktop } = useMediaScreen('md');
  const { id, name } = useAppSelector(selectNewAddress);
  const isOwner = checkAccess(AccessRange.OWNER);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const notify = useNotify();

  const [action, setAction] = useState<AddressAction>(AddressAction.None);
  const [isSubmitting, setSubmitting] = useState(false);

  const handleClose = () => setAction(AddressAction.None);

  const handleDeleteClick = () => setAction(AddressAction.DeleteAddress);

  const handleDeleteAddress = () => {
    setSubmitting(true);
    dispatch(ThunkCommon.deleteAddress(id))
      .unwrap()
      .then(res => {
        if (res?.success) {
          navigate(RouterUrl.AddressList);
          notify(getAddressNotifyContent(NotifyStatus.SUCCESS, AddressAction.DeleteAddress, t));
        } else {
          throw new Error('Error deleting address');
        }
      })
      .catch(() => notify(getAddressNotifyContent(NotifyStatus.ERROR, AddressAction.DeleteAddress, t)))
      .finally(() => {
        setSubmitting(false);
        setAction(AddressAction.None);
      });
  };

  if (!isOwner) return null;

  return (
    <>
      <Wrapper
        position="last"
        flexDirection={isDesktop ? 'row' : 'column'}
        alignItems="start"
        justifyContent="space-between"
      >
        <Flex flexDirection="column">
          <Adaptive mobile={H6} desktop={H5}>
            {t('address.deleteAddress')}
          </Adaptive>
          <Adaptive mobile={Caption} desktop={BodySmall}>
            {t('address.removeAllInformation')}
          </Adaptive>
        </Flex>
        <Button
          prefix={<TrashIcon />}
          size={isDesktop ? 'extraSmall' : 'small'}
          onClick={handleDeleteClick}
          width={isDesktop ? 'max-content' : '100%'}
        >
          {t('button.delete')}
        </Button>
      </Wrapper>
      {action === AddressAction.DeleteAddress && (
        <PopUp
          title={t('address.deleteByName', { name })}
          description=""
          onSubmit={handleDeleteAddress}
          onClose={handleClose}
          confirm={t('button.delete')}
          cancel={t('button.cancel')}
          isLoading={isSubmitting}
        >
          <Flex flexDirection="column" gap="16px">
            <BodyLarge>{t('address.doYouWantToDeleteByName', { name })}</BodyLarge>
            <BodyLarge>
              {t('address.youHaveOptionToRestore', { name })}
              <Link href="mailto:support@beautymarket.co.il" display="inline">
                support@beautymarket.co.il
              </Link>
            </BodyLarge>
          </Flex>
        </PopUp>
      )}
    </>
  );
};
