import { useCallback, useEffect } from 'react';
import isEmpty from 'lodash/isEmpty';
import { EditEmployee } from 'components';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import {
  emptySpecialist,
  selectAllSpecialists,
  selectSpecialistState,
  updateSpecialist,
  updateType,
} from 'store/redux-slices/specialistSlice';
import { SpecialistAction } from 'types';
import { EmployeeListSection, EmployeeStub } from './components';

export const EmployeeList = () => {
  const { type } = useAppSelector(selectSpecialistState);

  const specialists = useAppSelector(selectAllSpecialists);
  const dispatch = useAppDispatch();

  const handleAddButtonClick = useCallback(() => {
    const owner = specialists.find(item => item.accessLevel === 'OWNER');
    if (owner) {
      if (owner.role) {
        dispatch(updateType(SpecialistAction.Add));
        dispatch(updateSpecialist(emptySpecialist));
      } else {
        dispatch(updateType(SpecialistAction.Edit));
        dispatch(updateSpecialist(owner));
      }
    }
  }, [dispatch, specialists]);

  useEffect(() => {
    if (type !== SpecialistAction.None) {
      dispatch(updateType(SpecialistAction.None));
    }
  }, []);

  return (
    <>
      {isEmpty(specialists) ? (
        <EmployeeStub onAddClick={handleAddButtonClick} />
      ) : (
        <EmployeeListSection onAddClick={handleAddButtonClick} />
      )}
      {type === SpecialistAction.Add && <EditEmployee />}
    </>
  );
};
