import { Fragment, memo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Avatar,
  BodyLarge,
  BodySmall,
  Button,
  Caption,
  CoverStack,
  CoverStackCustomer,
  EditIcon,
  Flex,
  H4,
  Separator,
  TagButton,
} from '@beauty/beauty-market-ui';
import { EditProfileSidebar, Paper } from 'components';
import { getFullName } from 'helpers';
import { Adaptive } from 'hoc';
import { useMediaScreen } from 'hooks';
import { useAppSelector } from 'store/hooks';
import { selectProfile, selectUser } from 'store/redux-slices/userSlice';
import { getPersonalInfo } from '../helpers';

const ProfileCardTemplate = () => {
  const { t } = useTranslation();
  const { isDesktop } = useMediaScreen('md');
  const { user } = useAppSelector(selectUser);
  const profile = useAppSelector(selectProfile);

  const [isOpen, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const tagText = t(`profile.access.${user.headOrgSpecialist?.accessLevel.toLowerCase()}`);
  const fullName = getFullName(profile);

  const content = (
    <Flex
      flexDirection="column"
      alignItems={isDesktop ? 'center' : 'start'}
      width="100%"
      pt={isDesktop ? '16px' : '24px'}
    >
      {getPersonalInfo(profile, t).map((item, index, arr) => (
        <Fragment key={item.id}>
          <Flex flexDirection="column" width="100%" alignItems="start">
            <Adaptive mobile={Caption} desktop={BodySmall}>
              {item.label}
            </Adaptive>
            <BodyLarge large dir={item.dir}>
              {item.value}
            </BodyLarge>
          </Flex>
          {index !== arr.length - 1 && <Separator my={isDesktop ? '12px' : '8px'} />}
        </Fragment>
      ))}
    </Flex>
  );

  const header = (
    <CoverStackCustomer
      firstName={profile?.name}
      lastName={profile?.surname}
      buttonSize="extraSmall"
      buttonDesign="tertiary"
      editButtonText={t('profile.editProfile')}
      onButtonClick={handleOpen}
      imgUrl={profile?.avatarUrl}
      tagText={tagText}
      width="310px"
    />
  );

  return (
    <>
      {isDesktop ? (
        <CoverStack width="310px" header={header} content={content} mr="10px" />
      ) : (
        <Paper flexDirection="column" alignItems="center" p="16px 8px" gap="8px" borderRadius="10px">
          <Flex alignItems="center" justifyContent="space-between" width="100%">
            <TagButton text={tagText} backgroundColor="black" design="hover" />
            <Button design="secondary" prefix={<EditIcon />} size="small" onClick={handleOpen} width="42px" />
          </Flex>
          <Flex flexDirection="column" alignItems="center">
            <Avatar url={profile?.avatarUrl} name={fullName} rating="" size="m" />
            <H4 mt="8px">{fullName}</H4>
          </Flex>
          {content}
        </Paper>
      )}
      {isOpen && <EditProfileSidebar onClose={handleClose} />}
    </>
  );
};

export const ProfileCard = memo(ProfileCardTemplate);
