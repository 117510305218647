import { NavLink } from 'react-router-dom';
import { CalendarIcon, HomeIcon, MenuIcon } from '@beauty/beauty-market-ui';
import { useMediaScreen } from 'hooks';
import { RouterUrl } from 'routes';
import { MobileMenuWrapper, NavIcon } from '../style';

export const MobileMenu = ({ isOpen, onOpen }) => {
  const { isMobile } = useMediaScreen('md');
  return isMobile ? (
    <MobileMenuWrapper>
      <NavLink to={RouterUrl.AccountSetting} style={{ textDecoration: 'none' }}>
        {({ isActive }) => (
          <NavIcon isActive={isActive}>
            <HomeIcon />
          </NavIcon>
        )}
      </NavLink>
      <NavLink to={RouterUrl.Calendar} style={{ textDecoration: 'none' }}>
        {({ isActive }) => (
          <NavIcon isActive={isActive}>
            <CalendarIcon />
          </NavIcon>
        )}
      </NavLink>
      <NavIcon isActive={isOpen} onClick={onOpen}>
        <MenuIcon />
      </NavIcon>
    </MobileMenuWrapper>
  ) : null;
};
