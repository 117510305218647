import { BodySmall, Caption, colors } from '@beauty/beauty-market-ui';
import { Widget } from '../../../_common';

type Props = {
  title?: string;
  subtitle?: string;
  description?: string;
  padding?: string;
};

export const InfoItem = ({ title = '', subtitle = '', description = '', padding = '8px 0' }: Props) =>
  subtitle ? (
    <Widget
      title={
        <Caption lowline color={colors.grey.standard}>
          {title}
        </Caption>
      }
      subtitle={<BodySmall>{subtitle}</BodySmall>}
      description={<BodySmall>{description}</BodySmall>}
      p={padding}
    />
  ) : null;
