import { TFunction } from 'react-i18next';
import { t as defaultTF } from 'i18next';
import { NotifyStatus } from '@beauty/beauty-market-ui';
import {
  AddressAction,
  AppointmentsAction,
  OrganizationAction,
  ProfileActions,
  ServiceAction,
  SpecialistAction,
} from '../../types';
import { ClientActions } from '../../types/client';
import { getAddressNotifyContent } from './address';
import { getAppointmentsNotifyContent } from './appointments';
import { getClientNotifyContent } from './client';
import { getOrganizationNotifyContent } from './organisation';
import { getServiceNotifyContent } from './service';
import { getSpecialistNotifyContent } from './specialist';
import { getUserNotifyContent } from './user';

export type NotifyActionType =
  | AddressAction
  | ServiceAction
  | SpecialistAction
  | AppointmentsAction
  | OrganizationAction
  | ProfileActions
  | ClientActions;

export const getNotifyContent = (
  status: NotifyStatus,
  action: NotifyActionType,
  t: TFunction<'translation', undefined> = defaultTF,
  message = '',
) => {
  switch (action) {
    case AddressAction.AddAddress:
    case AddressAction.AddPhotos:
    case AddressAction.EditPhotos:
    case AddressAction.EditDescription:
    case AddressAction.EditMainPhoto:
    case AddressAction.EditAddress:
    case AddressAction.EditWorkingTime:
    case AddressAction.EditEmployees:
    case AddressAction.EditCurrentEmployee:
    case AddressAction.DeleteEmployee:
    case AddressAction.DeleteAddress:
    case AddressAction.Publish:
    case AddressAction.Unpublish:
      return getAddressNotifyContent(status, action, t, message);
    case ServiceAction.Create:
    case ServiceAction.Update:
    case ServiceAction.Delete:
      return getServiceNotifyContent(status, action, t, message);
    case SpecialistAction.Add:
    case SpecialistAction.Edit:
    case SpecialistAction.PublishOn:
    case SpecialistAction.PublishOff:
    case SpecialistAction.EditWorkplace:
    case SpecialistAction.EditServices:
    case SpecialistAction.EditSchedule:
    case SpecialistAction.DeleteWorkplace:
    case SpecialistAction.DeleteService:
    case SpecialistAction.DeleteBreak:
    case SpecialistAction.EditAbout:
    case SpecialistAction.AddEducation:
    case SpecialistAction.EditEducation:
    case SpecialistAction.DeleteEducation:
    case SpecialistAction.AddLanguage:
    case SpecialistAction.EditLanguage:
    case SpecialistAction.DeleteLanguage:
    case SpecialistAction.EditPhotos:
      return getSpecialistNotifyContent(status, action, t, message);
    case AppointmentsAction.Create:
    case AppointmentsAction.Update:
    case AppointmentsAction.Cancel:
    case AppointmentsAction.Convert:
    case AppointmentsAction.Close:
    case AppointmentsAction.CancelAll:
      return getAppointmentsNotifyContent(status, action, t, message);
    case OrganizationAction.CancelSubscription:
    case OrganizationAction.PaySubscription:
    case OrganizationAction.UpdateCard:
    case OrganizationAction.ActivateSpecialist:
    case OrganizationAction.PayForTelegram:
    case OrganizationAction.ActivateTelegram:
    case OrganizationAction.DeactivateTelegram:
    case OrganizationAction.PayForWhatsApp:
    case OrganizationAction.ActivateWhatsApp:
    case OrganizationAction.DeactivateWhatsApp:
    case OrganizationAction.PayForSpecialist:
    case OrganizationAction.EditOrganisation:
    case OrganizationAction.CreateOrganisation:
    case OrganizationAction.CloseDay:
      return getOrganizationNotifyContent(status, action, t, message);
    case ProfileActions.ChangePassword:
    case ProfileActions.EditProfile:
    case ProfileActions.DisconnectTelegram:
    case ProfileActions.DeleteAccount:
    case ProfileActions.RevokeGoogleCalendar:
    case ProfileActions.ChangeNotificationLanguage:
      return getUserNotifyContent(status, action, t, message);
    case ClientActions.Add:
    case ClientActions.Edit:
      return getClientNotifyContent(status, action, t, message);
    case AddressAction.None:
    case SpecialistAction.None:
    case ProfileActions.None:
    case ClientActions.None:
    default:
      return '';
  }
};
