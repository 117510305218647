import { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FormikProps } from 'formik';
import { Flex } from '@beauty/beauty-market-ui';
import { useRequest } from 'hooks';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { selectHeadState, setHeadInitialize } from 'store/redux-slices/organisationSlice';
import { selectService, setServiceLoading, ThunkService } from 'store/redux-slices/serviceSlice';
import { ServiceAction } from 'types';
import { SidebarFooter } from '../../SidebarFooter';
import { SidebarSheet } from '../../SidebarSheet';
import { TariffServiceFormType } from './TariffService.definitions.';
import { TariffServiceForm } from './TariffServiceForm';

export const TariffService = ({ handleClose, handleBackStep, handleNextStep, stepper }) => {
  const { t } = useTranslation();
  const { initialized } = useAppSelector(selectHeadState);
  const { isLoading } = useAppSelector(selectService);
  const dispatch = useAppDispatch();

  const createService = useRequest(ThunkService.createService, ServiceAction.Create);

  const formRef = useRef<FormikProps<TariffServiceFormType>>(null);

  const [isValid, setIsValid] = useState(false);

  const handleSubmit = () => {
    dispatch(setServiceLoading(true));
    formRef.current
      ?.submitForm()
      .then(createService)
      .then(() => !initialized && dispatch(setHeadInitialize(true)))
      .finally(handleNextStep);
  };

  const footer = (
    <SidebarFooter disabled={!isValid} onSubmit={handleSubmit} onBack={handleBackStep} save isLoading={isLoading} />
  );
  const content = <TariffServiceForm ref={formRef} setIsValid={setIsValid} showCaption />;

  return (
    <SidebarSheet
      onClose={handleClose}
      FooterBody={footer}
      label={t('settings.services.sidebar.addNewService')}
      descriptor={t('settings.services.sidebar.chooseCategories')}
    >
      <Flex flexDirection="column" gap="16px" mb="80px">
        {stepper}
        {content}
      </Flex>
    </SidebarSheet>
  );
};
