import { TFunction } from 'react-i18next';
import * as Yup from 'yup';

export enum EditDescriptionFormFields {
  Description = 'description',
}

export type EditDescriptionFormType = {
  [EditDescriptionFormFields.Description]: string;
};

export const schema = (t: TFunction<'translation', undefined>) =>
  Yup.object({
    [EditDescriptionFormFields.Description]: Yup.string().max(1000, t('validation.maxLength1000')),
  });
