import { useTranslation } from 'react-i18next';
import { Flex, H6 } from '@beauty/beauty-market-ui';
import { EventType } from 'types';
import { ActiveEvent } from '../../Event/ActiveEvent';

type EventsSectionProps = {
  status: string;
  events: EventType[];
  handleEventClick: (id: string) => void;
};

export const EventsSection = ({ status, events, handleEventClick }: EventsSectionProps) => {
  const { t } = useTranslation();

  return (
    <Flex flexDirection="column" gap="16px">
      <H6>{t(`status.${status}`)}</H6>
      {events.map(event => (
        <ActiveEvent key={event.resource.appointmentId} event={event} handleClick={handleEventClick} />
      ))}
    </Flex>
  );
};
