import { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { ThunkAddress } from '../../store/redux-slices/addressSlice';
import { ThunkOrganisation } from '../../store/redux-slices/organisationSlice';
import { ThunkService } from '../../store/redux-slices/serviceSlice';
import { ThunkSpecialist } from '../../store/redux-slices/specialistSlice';
import { selectUserHead } from '../../store/redux-slices/userSlice';

export const useInitializeApp = () => {
  const [isInitializing, setInitializing] = useState(true);
  const userHead = useAppSelector(selectUserHead);
  const dispatch = useAppDispatch();

  useEffect(() => {
    const initializeApp = async () => {
      if (userHead) {
        const orgId = userHead.headOrgId;
        await dispatch(ThunkOrganisation.fetchHead(orgId));
        await dispatch(ThunkAddress.fetchAllAddresses(orgId));
        await dispatch(ThunkSpecialist.fetchAllSpecialists(orgId));
        await dispatch(ThunkService.fetchAllServices(orgId));
      }
    };

    initializeApp().finally(() => setInitializing(false));
  }, []);

  return { isInitializing };
};
