import { useEffect, useRef, useState } from 'react';
import { Views } from 'react-big-calendar';
import { useTranslation } from 'react-i18next';
import { range } from 'lodash';
import moment, { Moment } from 'moment';
import {
  Flex,
  MailIcon,
  PlusCircleIcon,
  CrossIcon,
  ContextMenu,
  BodySmall,
  useClickOutside,
  getMinWidthMediaQuery,
  useMediaQuery,
  BottomSheet,
  Button,
} from '@beauty/beauty-market-ui';
import { getFormattedDay } from 'helpers/utils';
import { useAppSelector } from 'store/hooks';
import { selectAppointments } from 'store/redux-slices/appointmentsSlice';
import { BodyButtonProps } from 'types/calendar';
import { getSelectedLanguage, maxCalendarDays } from '../../../constants';
import { ContextMenuWrapper, Dots, MenuWrapper } from './style';

type OptionsMenuProps = {
  onAddBreak: () => void;
  onCancelAll: () => void;
  onClose: () => void;
  onSelectDate?: (date: string) => void;
  isOpen?: boolean;
  dateFromMenu?: string;
  onNavigate?: (date: Moment) => void;
  onChangeView?: (currentView: Views) => void;
  onCreateAppointment?: () => void;
  setDateToClose?: (date: Date) => void;
};

const BodyButton = ({ label, onClick, disabled = false, design = 'quaternary', ...other }: BodyButtonProps) => (
  <Button disabled={disabled} width="100%" size="large" design={design} onClick={onClick} {...other}>
    {label}
  </Button>
);

export const OptionsMenu = ({
  onAddBreak,
  onCancelAll,
  isOpen,
  onClose,
  onSelectDate,
  dateFromMenu,
  onNavigate,
  onChangeView,
  onCreateAppointment,
  setDateToClose,
}: OptionsMenuProps) => {
  const { t } = useTranslation();
  const [isMenu, setIsMenu] = useState(false);
  const menuRef = useRef(null);
  const mediaQuery = getMinWidthMediaQuery('md');
  const isMobile = !useMediaQuery(mediaQuery);

  const selectedLanguage = getSelectedLanguage();

  const { date, view, selectedSpecialist } = useAppSelector(selectAppointments);

  const sliderDates = range(-maxCalendarDays, maxCalendarDays).map(day => {
    const currentDay = moment().add(day, 'days');
    const timestamp = currentDay.format('YYYY-MM-DD');
    return {
      timestamp,
      disabled: false,
    };
  });

  const options = [
    /* {
      body: (
        <Flex gap="8px" key="mailAll">
          <MailIcon />
          <BodySmall lowline>{t('calendar.menu.mailAll')}</BodySmall>
        </Flex>
      ),
      onClick: () => alert(1),
    }, */
    {
      body: (
        <Flex gap="8px" key="addBreak">
          <PlusCircleIcon />
          <BodySmall lowline>{t('calendar.menu.addBreak')}</BodySmall>
        </Flex>
      ),
      onClick: onAddBreak,
    },
    {
      body: (
        <Flex gap="8px" key="cancelAll">
          <CrossIcon />
          <BodySmall lowline>{t('calendar.menu.cancelAll')}</BodySmall>
        </Flex>
      ),
      onClick: onCancelAll,
    },
  ];

  const content = (
    <Flex flexDirection="column" alignItems="center" width="100%" gap="16px">
      {dateFromMenu && (
        <>
          <BodyButton
            onClick={() => {
              onClose();
              onNavigate &&
                onNavigate(
                  moment(dateFromMenu).isBefore(moment(sliderDates[0].timestamp))
                    ? moment(sliderDates[0].timestamp)
                    : (moment(dateFromMenu).isAfter(moment(sliderDates[sliderDates.length - 1].timestamp)) &&
                        moment(sliderDates[sliderDates.length - 1].timestamp)) ||
                        moment(dateFromMenu),
                );
              view !== Views.DAY && onChangeView && onChangeView(selectedSpecialist ? Views.DAY : Views.WEEK);
            }}
            label={t(`calendar.menu.openDay`)}
            design="primary"
          />
          <BodyButton
            onClick={() => {
              onClose();
              onCreateAppointment && onCreateAppointment();
            }}
            label={t(`calendar.menu.createAppointment`)}
            disabled={
              moment(dateFromMenu).format('DD-MM-YYYY') !== moment().format('DD-MM-YYYY') &&
              moment(dateFromMenu).isBefore(moment())
            }
          />
          <BodyButton
            onClick={() => {
              onClose();
              setDateToClose && setDateToClose(moment(dateFromMenu).toDate());
            }}
            label={t(`calendar.menu.closeDay`)}
          />
        </>
      )}

      {!dateFromMenu && (
        <BodyButton
          onClick={() => {
            onClose();
            onAddBreak();
          }}
          label={t(`calendar.menu.addBreak`)}
        />
      )}
      <BodyButton
        onClick={() => {
          onClose();
          onCancelAll();
          onSelectDate && onSelectDate(date);
        }}
        label={t(`calendar.menu.cancelAll`)}
      />
    </Flex>
  );
  useClickOutside(menuRef, () => setIsMenu(false));

  useEffect(() => {
    moment.locale(selectedLanguage.toLowerCase());
  }, [selectedLanguage]);

  return isMobile ? (
    <BottomSheet
      label={getFormattedDay(dateFromMenu ?? date, selectedLanguage)}
      descriptor={t('calendar.menu.options')}
      isOpen={isOpen}
      handleClose={onClose}
      onClose={onClose}
      detent="content-height"
      content={content}
    />
  ) : (
    <MenuWrapper ref={menuRef} onClick={() => setIsMenu(prev => !prev)}>
      <Dots />
      {isMenu && (
        <ContextMenuWrapper>
          <ContextMenu items={options} />
        </ContextMenuWrapper>
      )}
    </MenuWrapper>
  );
};
