import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  BodySmall,
  Button,
  colors,
  EditIcon,
  getMinWidthMediaQuery,
  H6,
  Slider,
  useMediaQuery,
} from '@beauty/beauty-market-ui';
import { InfoBlock } from 'components';
import { isRtl } from 'helpers/utils';
import { useAppSelector } from 'store/hooks';
import { selectAddressPhotos } from 'store/redux-slices/addressSlice';
import { AddressAction } from 'types';
import { GalleryImage, GalleryStub } from '../../../style';
import { EditPhotoGallery } from './EditPhotoGallery';

export const PhotoGallery = () => {
  const { t } = useTranslation();
  const { photos } = useAppSelector(selectAddressPhotos);

  const [action, setAction] = useState<AddressAction>(AddressAction.None);

  const mediaQuery = getMinWidthMediaQuery('md');
  const isDesktop = useMediaQuery(mediaQuery);
  const rtl = isRtl();

  const handleEditPhotosClick = () => setAction(AddressAction.EditPhotos);

  const handleClose = () => setAction(AddressAction.None);

  const slides = photos.map(slide => <GalleryImage key={String(slide)} src={String(slide)} alt="slide" />);
  const stub = (
    <GalleryStub key="stub" onClick={handleEditPhotosClick} cursor="pointer">
      <BodySmall lowline color={colors.grey.dark}>
        {t('address.addPhoto')}
      </BodySmall>
    </GalleryStub>
  );

  return (
    <InfoBlock
      title={t('address.photoGallery')}
      button={
        <Button
          size="extraSmall"
          design="secondary"
          prefix={<EditIcon height="16px" width="16px" />}
          onClick={handleEditPhotosClick}
        >
          {isDesktop && t('button.edit')}
        </Button>
      }
      count={1}
      visibleCount={7}
    >
      <Slider
        title={<H6 />}
        titleBottomMargin="0"
        slides={slides.length ? [...slides] : [stub]}
        isPaginationEnabled
        rtl={rtl}
      />
      {action === AddressAction.EditPhotos && <EditPhotoGallery onClose={handleClose} />}
    </InfoBlock>
  );
};
