import { useTranslation } from 'react-i18next';
import { useFormikContext } from 'formik';
import { BodyLarge, Flex } from '@beauty/beauty-market-ui';
import { FormikInput, FormikPhone, FormikTextfield } from 'components';
import { ClientForm, ClientFormFields } from '../ClientForm.definitions';

type ContactInformationFormProps = {
  verified: boolean;
  editMode: boolean;
};

const ContactInformationForm = ({ editMode, verified }: ContactInformationFormProps) => {
  const { t } = useTranslation();
  const { values, handleChange } = useFormikContext<ClientForm>();

  return (
    <Flex flexDirection="column" gap="16px">
      <BodyLarge large>{t('calendar.newClientSidebar.contactInformation')}</BodyLarge>
      <FormikPhone
        codeId={ClientFormFields.Code}
        phoneId={ClientFormFields.Number}
        disabled={editMode}
        placeholder={t('profile.phone')}
        required
      />
      <FormikPhone
        codeId={ClientFormFields.AdditionalCode}
        phoneId={ClientFormFields.AdditionalNumber}
        placeholder={t('registration.additionalNumber')}
      />
      <FormikInput
        design="white"
        id={ClientFormFields.Email}
        name={ClientFormFields.Email}
        placeholder={t('profile.email')}
        disabled={editMode && verified}
      />
      <FormikTextfield
        id={ClientFormFields.AdditionalInfo}
        name={ClientFormFields.AdditionalInfo}
        width="100%"
        rows={4}
        design="white"
        onChange={handleChange}
        value={values[ClientFormFields.AdditionalInfo]}
        defaultValue={values[ClientFormFields.AdditionalInfo]}
        placeholder={t('registration.additionalInfo')}
      />
    </Flex>
  );
};

export { ContactInformationForm };
