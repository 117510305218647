import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { Form, Formik } from 'formik';
import { parsePhoneNumber } from 'libphonenumber-js/max';
import { Flex, H6, NotifyStatus, useNotify } from '@beauty/beauty-market-ui';
import { CustomInput } from 'components/_common/CustomInput';
import { FormikPhone } from 'components/functional';
import { SidebarSheet } from 'components/SidebarSheet';
import { getNotifyContent, getShortCountryByCode } from 'helpers';
import { useMediaScreen } from 'hooks';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { ThunkClient } from 'store/redux-slices/clientSlice';
import { ClientActions } from 'types/client';
import { selectCurrentOrgId } from '../../../../store/redux-slices/appointmentsSlice';
import { ErrorDataType } from '../../../../types';
import { SidebarFooter } from '../../../SidebarFooter';
import { FormWrapper } from '../style';
import {
  initialValues,
  NewClientForm,
  NewClientFormFields,
  newClientValidationSchema,
} from './NewClientSidebar.definitions';

interface Props {
  onClose: () => void;
}

export const NewClientSidebar = ({ onClose }: Props) => {
  const { t } = useTranslation();
  const { isDesktop } = useMediaScreen('md');

  const dispatch = useAppDispatch();
  const notify = useNotify();

  const organisationId = useAppSelector(selectCurrentOrgId);

  const { Name, Surname, Number, Region } = NewClientFormFields;
  /*  const onFormSubmit = useCallback(
    async (data: NewClientForm, { setFieldError }) => {
      const { name, surname, phone, region } = data;
      if (organisationId) {
        const params = { name, surname, number: phone, code: region };
        const response = await appointmentsAPI.createClient(organisationId, params);
        if (response.data) {
          handleSubmit(response.data);
          handleClose();
        } else if (response.error?.statusCode === 409) setFieldError(Number, t('validation.phoneExists'));
        else if (response.error?.statusCode === 400) setFieldError(Number, t('validation.enterValidPhone'));
      }
    },
    [organisationId],
  ); */

  const onFormSubmit = useCallback(
    async (data: NewClientForm, { setFieldError }) => {
      const { code, number } = data;
      const phone = code && number ? parsePhoneNumber(`${code}${number}`, getShortCountryByCode(code)) : null;
      const payload = { ...data, code, number: phone?.nationalNumber ?? '', orgId: organisationId ?? undefined };
      try {
        await dispatch(ThunkClient.createClient(payload)).unwrap();
        notify(getNotifyContent(NotifyStatus.SUCCESS, ClientActions.Add, t));
        onClose();
      } catch (error) {
        const { statusCode, message } = error as ErrorDataType;
        if (statusCode === 400) {
          if (message.includes('created')) setFieldError(Number, t('validation.phoneExists'));
        }
        const errorMessage = message.includes('created') ? t('clients.alreadyCreated') : undefined;
        notify(getNotifyContent(NotifyStatus.ERROR, ClientActions.Add, t, errorMessage));
      }
    },
    [organisationId],
  );

  const formikContextValue = {
    initialValues,
    validationSchema: newClientValidationSchema(t),
    onSubmit: onFormSubmit,
    validateOnMount: false,
  };

  return (
    <Formik {...formikContextValue}>
      {({ isValid, handleSubmit, isSubmitting }) => {
        const footerBody = (
          <SidebarFooter
            disabled={!isValid || isSubmitting}
            onSubmit={handleSubmit}
            onBack={onClose}
            confirmationLabel={t('calendar.newClientSidebar.sendInvite')}
            cancel
            isLoading={isSubmitting}
          />
        );

        const sidebarProps = {
          isOpen: true,
          onClose,
          FooterBody: footerBody,
          label: t('calendar.newClientSidebar.newClient'),
        };

        const content = (
          <Form>
            <FormWrapper>
              <H6 marginTop="8px">{t('calendar.newClientSidebar.generalInformation')}</H6>
              <Flex gap={isDesktop ? '8px' : '16px'} flexDirection={isDesktop ? 'row' : 'column'}>
                <CustomInput
                  fieldName={Name}
                  placeholder={t('calendar.newClientSidebar.name')}
                  autoComplete="off"
                  required
                />
                <CustomInput
                  fieldName={Surname}
                  placeholder={t('calendar.newClientSidebar.surname')}
                  autoComplete="off"
                  required
                />
              </Flex>
              <H6 marginTop="8px">{t('calendar.newClientSidebar.contactInformation')}</H6>
              <FormikPhone
                codeId={Region}
                phoneId={Number}
                placeholder={t('calendar.newClientSidebar.phone')}
                required
              />
            </FormWrapper>
          </Form>
        );

        return <SidebarSheet {...sidebarProps}>{content}</SidebarSheet>;
      }}
    </Formik>
  );
};
