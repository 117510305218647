import { useEffect, useRef, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { Nullable } from 'tsdef';
import { NotifyStatus, useNotify } from '@beauty/beauty-market-ui';
import { getNotifyContent } from '../helpers';
import { useAppDispatch, useAppSelector } from '../store/hooks';
import { ThunkOrganisation } from '../store/redux-slices/organisationSlice';
import { selectUserHead } from '../store/redux-slices/userSlice';
import { OrganizationAction } from '../types';

export const useGetPaymentFor = (condition: boolean, action: OrganizationAction, option = '') => {
  const userHead = useAppSelector(selectUserHead);

  const dispatch = useAppDispatch();
  const notify = useNotify();

  const timerRef = useRef<Nullable<NodeJS.Timeout | number>>(null);

  const [params, setParams] = useSearchParams();
  const [counter, setCounter] = useState(0);
  const [isLoading, setLoading] = useState(false);

  const clearTimer = () => {
    if (timerRef.current) clearTimeout(timerRef.current);
  };

  const handleResult = (status: NotifyStatus) => {
    notify(getNotifyContent(status, action));
    clearTimer();
    setLoading(false);
    setParams({});
  };

  const tryGetSubscription = () => {
    setTimeout(() => {
      if (userHead?.headOrgId) {
        dispatch(ThunkOrganisation.fetchHead(userHead.headOrgId));
      }
      setCounter(prev => prev + 1);
    }, 1000);
  };

  useEffect(() => {
    const success = params.get('success');
    const optionType = params.get('optionType');
    if (success && option === optionType) {
      setLoading(true);
      if (success === 'true' && counter < 5) {
        condition ? handleResult(NotifyStatus.SUCCESS) : tryGetSubscription();
      } else if (success === 'false') {
        handleResult(NotifyStatus.ERROR);
      } else {
        window.location.reload();
      }
    }
    return () => clearTimer();
  }, [counter, condition]);

  return { isLoading };
};
