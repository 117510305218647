import { organisationAPI } from '../../helpers/organisationAPI';
import { SubscriptionPricePlanType } from '../../types';
import { createAppAsyncThunk } from '../create-app-async-thunk';

export const deleteAddress = createAppAsyncThunk<{ success: boolean }, string>('common/deleteAddress', (id, thunkAPI) =>
  organisationAPI.deleteAddress({ id, thunkAPI }),
);

export const activateSpecialist = createAppAsyncThunk<
  { id: string; active: boolean; payment_url?: string; status?: number },
  { specId: string; cardId?: string }
>('common/activateSpecialist', (data, thunkAPI) => {
  const { id, currency, todaysPrice } = thunkAPI.getState().organisation.head.subscription[0];
  return organisationAPI.activateOption({
    id,
    data: {
      currency,
      specId: data.specId,
      amount: todaysPrice.employee,
      optionType: SubscriptionPricePlanType.EMPLOYEE,
      cardId: data?.cardId,
    },
    thunkAPI,
  });
});
