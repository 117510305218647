import { useFormikContext } from 'formik';
import _ from 'lodash';
import { InfoIcon } from '@beauty/beauty-market-ui';
import {
  AppointmentForm,
  AppointmentFormFields,
} from 'components/Calendar/Sidebars/NewAppointmentSidebar/AppointmentSidebar.definitions';
import {
  NewClientForm,
  NewClientFormFields,
} from 'components/Calendar/Sidebars/NewClientSidebar/NewClientSidebar.definitions';
import { FormikInput } from 'components/functional/formik/formik-input/FormikInput';

interface CustomInputProps {
  fieldName: NewClientFormFields | AppointmentFormFields;
  caption?: string;
  placeholder?: string;
  iconLeft?: JSX.Element;
  disabled?: boolean;
  autoComplete?: string;
  nonValid?: boolean;
  required?: boolean;
  onInput?: () => void;
}

export const CustomInput = ({
  fieldName,
  caption,
  placeholder,
  iconLeft,
  disabled,
  autoComplete,
  onInput,
  nonValid = false,
  required = false,
}: CustomInputProps) => {
  const { touched, errors, values } = useFormikContext<NewClientForm | AppointmentForm>();
  const isShowError = touched[fieldName] && !!errors[fieldName];
  const captionMessage = isShowError ? errors[fieldName] : caption || '';
  const placeholderMessage = placeholder || _.capitalize(fieldName);
  return (
    <FormikInput
      id={fieldName}
      name={fieldName}
      width="100%"
      design="white"
      caption={captionMessage}
      value={values[fieldName]}
      placeholder={placeholderMessage}
      captionIcon={!!captionMessage && !nonValid && <InfoIcon />}
      iconLeft={iconLeft}
      disabled={disabled}
      autoComplete={autoComplete}
      nonValid={isShowError}
      onInput={onInput}
      required={required}
    />
  );
};
