import { TFunction } from 'react-i18next';
import { InvalidVariants } from '../../constants';

export const getErrorMessage = (t: TFunction<'translation', undefined>, message?: string) => {
  switch (message) {
    case InvalidVariants.FileLarge:
      return t('validation.File too large');
    default:
      return message;
  }
};
