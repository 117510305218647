import styled from 'styled-components';
import { Flex, mediaQueries } from '@beauty/beauty-market-ui';
import { croppedMultiLineText } from '../../style';

export const Wrapper = styled(Flex)`
  p,
  h4 {
    ${croppedMultiLineText}
  }

  & > div:first-of-type {
    width: 310px;
  }
`;

export const ContentWrapper = styled(Flex)`
  flex-direction: column;
  align-items: start;
  padding: 0 8px 16px;

  ${mediaQueries.md} {
    padding: 0;
  }
`;
