import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { EmptyProfile } from '../../constants';
import { BookingType, SpecialistResponse } from '../../types';
import { ProfileType, UserType } from '../../types/user';
import { logout } from '../actions/common';
import {
  changePassword,
  deleteAccount,
  deleteUserAvatar,
  disconnectTelegram,
  editProfile,
  getProfile,
  revokeGoogleCalendar,
  updateNotificationLanguage,
} from '../asyncActions/user';
import { RootState } from '../store';
import { updateUserProfile } from '../utils';
import { ThunkOrganisation } from './organisationSlice';
import { ThunkSpecialist } from './specialistSlice';

export interface UserState {
  user: UserType;
  booking: BookingType;
  profile: ProfileType;
  selectedAppointmentId?: string;
  smsCodeTime: number;
}

const initialState: UserState = {
  user: { name: '', email: '', userId: '', avatarUrl: '', headOrgSpecialist: null },
  booking: {
    service: null,
    timeslot: null,
    specialist: null,
  },
  profile: EmptyProfile,
  selectedAppointmentId: undefined,
  smsCodeTime: 0,
};

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    updateUser: (state, action: PayloadAction<UserType>) => {
      state.user = action.payload;
    },
    updateBooking: (state, action: PayloadAction<BookingType>) => {
      state.booking = { ...state.booking, ...action.payload };
    },
    updateProfile: (state, action: PayloadAction<ProfileType>) => {
      state.profile = { ...state.profile, ...action.payload };
    },
    updateSmsCodeTime: (state, action: PayloadAction<number>) => {
      state.smsCodeTime = action.payload;
    },
    updateAppointment: (state, action: PayloadAction<string>) => {
      state.selectedAppointmentId = action.payload;
    },
    updateGoogleTokens: (state, action: PayloadAction<string>) => {
      state.user.headOrgSpecialist!.googleTokens = action.payload;
    },
  },
  extraReducers: builder => {
    builder
      .addCase(ThunkOrganisation.createHead.fulfilled, (state, action) => {
        state.user.headOrgSpecialist = {
          headOrganization: action.payload,
          id: '',
          googleTokens: 'false',
          headOrgId: action.payload.id,
          accessLevel: 'OWNER',
          orgSpecialist: [],
          googleCalendarEmail: null,
        };
      })
      .addCase(ThunkOrganisation.createHead.rejected, state => {
        state.user.headOrgSpecialist = initialState.user.headOrgSpecialist;
      })
      .addCase(ThunkSpecialist.editSpecialist.fulfilled, (state, action: PayloadAction<SpecialistResponse>) => {
        if (action.payload.id === state.user.userId) {
          updateUserProfile(state, action.payload);
        }
      })
      .addCase(deleteUserAvatar.fulfilled, state => {
        state.user.avatarUrl = undefined;
        state.profile.avatarUrl = null;
      })
      .addCase(disconnectTelegram.fulfilled, state => {
        state.profile.telegram = null;
      })
      .addCase(revokeGoogleCalendar.fulfilled, (state, action) => {
        if (action.payload.success && state.user.headOrgSpecialist) {
          state.user.headOrgSpecialist.googleTokens = 'false';
          state.user.headOrgSpecialist.googleCalendarEmail = null;
        }
      })
      .addCase(updateNotificationLanguage.fulfilled, (state, action) => {
        state.profile.language = action.meta.arg.accountData.language;
      })
      .addCase(getProfile.fulfilled, (state, action) => {
        if (action.payload.headOrgSpecialist) {
          state.user.headOrgSpecialist = action.payload.headOrgSpecialist;
        }
        state.profile = action.payload;
      })
      .addCase(getProfile.rejected, state => {
        state.profile = EmptyProfile;
      })
      .addCase(editProfile.fulfilled, (state, action: PayloadAction<SpecialistResponse>) => {
        updateUserProfile(state, action.payload);
      })
      .addCase(logout, () => initialState);
  },
});

export const { updateUser, updateBooking, updateProfile, updateSmsCodeTime, updateAppointment, updateGoogleTokens } =
  userSlice.actions;

export const selectUser = (state: RootState) => state.user;
export const selectProfile = (state: RootState) => state.user.profile;
export const selectUserHead = (state: RootState) => state.user.user.headOrgSpecialist;
export const selectUserHeadOrgId = (state: RootState) => state.user.user.headOrgSpecialist?.headOrgId;
export const selectUserOrgSpecialist = (state: RootState) =>
  state.user.user.headOrgSpecialist?.orgSpecialist?.[0] ?? null;

export const ThunkUser = {
  getProfile,
  changePassword,
  editProfile,
  deleteAccount,
  deleteUserAvatar,
  disconnectTelegram,
  revokeGoogleCalendar,
  updateNotificationLanguage,
};

export default userSlice.reducer;
