import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { NotifyStatus, useNotify } from '@beauty/beauty-market-ui';
import { TelegramBar, TelegramSection } from 'components';
import { getNotifyContent } from 'helpers';
import { useRequest } from 'hooks';
import { OrganizationAction, SubscriptionPlanType } from 'types';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import {
  selectActiveSubscription,
  selectHeadOrgTelegram,
  ThunkOrganisation,
} from '../../../store/redux-slices/organisationSlice';

export const Telegram = () => {
  const { t } = useTranslation();
  const isHeadOrgTelegramConnect = useAppSelector(selectHeadOrgTelegram);
  const subscription = useAppSelector(selectActiveSubscription);
  const dispatch = useAppDispatch();
  const notify = useNotify();

  const [isSidebarOpen, setSidebarOpen] = useState(false);

  const handleClose = useCallback(() => {
    setSidebarOpen(false);
  }, [setSidebarOpen]);

  const deactivateTelegram = useRequest(ThunkOrganisation.deactivateTelegram, OrganizationAction.DeactivateTelegram);

  const activateTelegram = () => {
    dispatch(ThunkOrganisation.payForTelegram({}))
      .unwrap()
      .then(res => {
        if (res?.status === 0) {
          throw new Error(t('validation.unknown'));
        } else if (res.telegram) {
          notify(getNotifyContent(NotifyStatus.SUCCESS, OrganizationAction.ActivateTelegram, t));
        } else if (res.payment_url) {
          window.location.href = res.payment_url;
        } else {
          throw new Error(t('validation.unknown'));
        }
      })
      .catch(() => notify(getNotifyContent(NotifyStatus.ERROR, OrganizationAction.ActivateTelegram, t)))
      .then(() => handleClose());
  };

  const handleClick = useCallback(() => {
    if (subscription?.telegram || subscription?.plan.planType === SubscriptionPlanType.FREE) {
      isHeadOrgTelegramConnect ? deactivateTelegram() : activateTelegram();
    } else {
      setSidebarOpen(true);
    }
  }, [setSidebarOpen, deactivateTelegram, activateTelegram]);

  return (
    <>
      <TelegramSection onClick={handleClick} />
      {isSidebarOpen && <TelegramBar onClose={handleClose} />}
    </>
  );
};
