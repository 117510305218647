import { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useClickOutside, Flex, BodySmall, PlusCircleIcon, CrossIcon } from '@beauty/beauty-market-ui';
import { checkAccess } from 'helpers';
import { AccessRange } from '../../../constants';
import { KebabBody } from './KebabBody';

type WeekKebabMenuProps = {
  onAddBreak: () => void;
  onSelectDate: (date: string) => void;
  onCancelAll: () => void;
  date: string;
};

export const WeekKebabMenu = ({ onAddBreak, onSelectDate, onCancelAll, date }: WeekKebabMenuProps) => {
  const { t } = useTranslation();
  const [isMenu, setIsMenu] = useState(false);
  const menuRef = useRef(null);

  const isUserAdmin = checkAccess(AccessRange.ADMIN);

  const options = [
    /* {
      body: (
        <Flex gap="8px" key="mailAll">
          <MailIcon />
          <BodySmall lowline>{t('calendar.menu.mailAll')}</BodySmall>
        </Flex>
      ),
      onClick: () => alert(1),
    }, */
    {
      body: (
        <Flex gap="8px" key="addBreak">
          <PlusCircleIcon />
          <BodySmall lowline>{t('calendar.menu.addBreak')}</BodySmall>
        </Flex>
      ),
      onClick: e => {
        e.stopPropagation();
        onSelectDate(date);
        onAddBreak();
        setIsMenu(false);
      },
    },
    {
      body: (
        <Flex gap="8px" key="cancelAll">
          <CrossIcon />
          <BodySmall lowline>{t('calendar.menu.cancelAll')}</BodySmall>
        </Flex>
      ),
      onClick: e => {
        e.stopPropagation();
        onSelectDate(date);
        onCancelAll();
        setIsMenu(false);
      },
    },
  ];

  useClickOutside(menuRef, () => setIsMenu(false));

  return (
    <KebabBody
      isMenu={isMenu}
      onClick={e => {
        e.stopPropagation();
        e.preventDefault();
        setIsMenu(prev => !prev);
      }}
      menuRef={menuRef}
      options={isUserAdmin ? options : options.slice(0, 1)}
    />
  );
};
