import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Form, useFormikContext } from 'formik';
import moment from 'moment';
import { Nullable } from 'tsdef';
import { BottomSheet, Button, DateSelector, H6, SearchIcon } from '@beauty/beauty-market-ui';
import { FormikDropdown } from 'components/functional/formik/formik-dropdown/FormikDropdown';
import { FormikInput } from 'components/functional/formik/formik-input/FormikInput';
import { useMediaScreen } from 'hooks';
import { TimeItemType } from 'hooks/useTimeList';
import { useAppSelector } from 'store/hooks';
import { selectOrgServices } from 'store/redux-slices/appointmentsSlice';
import { Client, WorkDayType } from 'types';
import {
  AppointmentByIdResponse,
  AppointmentDateType,
  GoogleEventType,
  ServiceOption,
  SpecialistOption,
  SpecialistSummaryType,
} from 'types/appointment';
import { getSelectedLanguage } from '../../../../../constants';
import { getDate, getEndTimeIndex, getPreselectedValues, getScrollTo } from '../../helpers';
import { ButtonsWrapper, FormWrapper, SelectorWrapper, TimeWrapper } from '../../style';
import { AppointmentFormFields } from '../AppointmentSidebar.definitions';
import { SpecialistForm } from './SpecialistForm';

type ServiceFormProps = {
  serviceOptionsList: ServiceOption[];
  specialistOptionsList: SpecialistOption[];
  infoMode: boolean;
  timeList: TimeItemType[];
  organisationWorkTime: Nullable<WorkDayType[]>;
  selectedWeekday: string;
  selectedAppointmentData: Nullable<AppointmentByIdResponse | GoogleEventType>;
  extendedOrganisationClients: Nullable<Client[]>;
  organisationSpecialists: Nullable<SpecialistSummaryType[]>;
  setIsService: Dispatch<SetStateAction<boolean>>;
  isEmptyDate: boolean;
  setEmptyDate: Dispatch<SetStateAction<boolean>>;
  startTime: number;
  setStartTime: Dispatch<SetStateAction<number>>;
  endTime: number;
  setEndTime: Dispatch<SetStateAction<number>>;
  setTextfield: Dispatch<SetStateAction<boolean>>;
  isEditService: boolean;
  setEditService: Dispatch<SetStateAction<boolean>>;
  dateFromMenu: string | null;
  isValid: boolean;
};

export const ServiceForm = ({
  serviceOptionsList,
  specialistOptionsList,
  infoMode,
  timeList,
  organisationWorkTime,
  selectedWeekday,
  selectedAppointmentData,
  extendedOrganisationClients,
  organisationSpecialists,
  setIsService,
  isEmptyDate,
  setEmptyDate,
  startTime,
  setStartTime,
  endTime,
  setEndTime,
  setTextfield,
  isEditService,
  setEditService,
  dateFromMenu,
  isValid,
}: ServiceFormProps) => {
  const { isMobile } = useMediaScreen('md');
  const { Service, Specialist, Start, End, AppointmentDate, Status } = AppointmentFormFields;
  const { setFieldValue, setFieldTouched, values } = useFormikContext<AppointmentFormFields>();
  const isGoogle = selectedAppointmentData?.isGoogle;
  const { t } = useTranslation();
  const { data: orgServicesMap, ids: orgServiceIds } = useAppSelector(selectOrgServices);
  const userLocale = getSelectedLanguage().toLowerCase();

  const { preselectedDate, preScrolledToStart, preScrolledToEnd } = getPreselectedValues(
    selectedWeekday,
    selectedAppointmentData,
    null,
    extendedOrganisationClients,
    organisationSpecialists,
    organisationWorkTime,
    timeList,
    dateFromMenu,
  );

  const [startScroll, setStartScroll] = useState(preScrolledToStart);
  const [endScroll, setEndScroll] = useState(preScrolledToEnd);
  const [duration, setDuration] = useState<Nullable<number | string>>(null);

  const [appointmentDate, setAppointmentDate] = useState<AppointmentDateType>({
    item: (dateFromMenu && getDate(dateFromMenu)) || preselectedDate,
    disabled: false,
  });

  const [isCalendarVisible, setIsCalendarVisible] = useState(false);

  const [isEditSpecialist, setEditSpecialist] = useState(false);

  const isFieldsFilled =
    !!values[Service] && !!values[Specialist] && !!values[Start] && !!values[End] && !!values[AppointmentDate];

  const isClear =
    (!values[Service] &&
      (!values[Specialist] || (organisationSpecialists?.length && organisationSpecialists?.length === 1)) &&
      !values[Start] &&
      !values[End] &&
      (!values[AppointmentDate] || isEmptyDate)) ||
    (isGoogle && !values[Service]);

  const specialistForm = (
    <SpecialistForm
      specialists={specialistOptionsList}
      onSelect={(index: number) => {
        setFieldValue(Specialist, specialistOptionsList[index].id);
        setEditSpecialist(false);
      }}
      onDelete={() => {
        setFieldValue(Specialist, '');
        setEditSpecialist(true);
      }}
      disabled={infoMode || isGoogle || organisationSpecialists?.length === 1}
    />
  );

  const handleChangeEndTime = (index: number) => {
    setEndTime(index);
    setFieldTouched(End, true);
    setFieldValue(End, timeList[index].item);
  };

  const handleChangeStartTime = async (index: number) => {
    setStartTime(index);
    await setFieldTouched(Start, true);
    await setFieldValue(Start, timeList[index].item);
    const serviceData = orgServiceIds.includes(values[Service]) ? orgServicesMap[values[Service]] : undefined;
    const serviceDuration = serviceData?.duration ?? null;
    handleChangeEndTime(getEndTimeIndex(index, timeList, serviceDuration));
  };

  const handleChangeDate = async (selectedDate: Date) => {
    setEmptyDate(false);
    const selectedAppointmentDate = moment(selectedDate).format('DD.MM.yyyy');
    const scrollTo = getScrollTo(selectedDate, timeList, organisationWorkTime);
    setFieldValue(AppointmentDate, selectedAppointmentDate);
    setAppointmentDate({
      item: selectedAppointmentDate,
      disabled: false,
    });
    setStartScroll(scrollTo.preScrolledToStart);
    setEndScroll(scrollTo.preScrolledToEnd);
  };

  const handleCalendarClick = () => {
    setIsCalendarVisible(prevValue => !prevValue);
  };

  const handleClear = async () => {
    if (!isGoogle) {
      setStartTime(-1);
      setEndTime(-1);
      setFieldTouched(End, true);
      setFieldValue(End, '');
      setFieldTouched(Start, true);
      setFieldValue(Start, '');
      setFieldValue(AppointmentDate, '');
      setAppointmentDate({
        item: '',
        disabled: false,
      });
      organisationSpecialists?.length && organisationSpecialists?.length > 1 && setFieldValue(Specialist, '');
      setEmptyDate(true);
    }
    setFieldValue(Service, '');
  };

  const handleCloseDateSelector = () => setIsCalendarVisible(false);

  useEffect(() => {
    !dateFromMenu &&
      setAppointmentDate({
        item: values[AppointmentDate],
        disabled: false,
      });
  }, [values[AppointmentDate], dateFromMenu]);

  useEffect(() => {
    const serviceData = orgServiceIds.includes(values[Service]) ? orgServicesMap[values[Service]] : undefined;
    !isGoogle && setDuration(serviceData?.duration ?? null);
  }, [values[Service], isGoogle]);

  useEffect(() => {
    isEditService && handleChangeStartTime(timeList.findIndex(timeItem => timeItem.item === values[Start]));
  }, [duration]);

  return (
    <Form>
      <FormWrapper>
        <FormikInput
          id={Service}
          name={Service}
          placeholder={
            serviceOptionsList.length === 0
              ? t('calendar.newAppointmentSidebar.addNewService')
              : t('calendar.newAppointmentSidebar.serviceSearch')
          }
          iconLeft={<SearchIcon />}
          design="white"
          searchInput
          currentOption={serviceOptionsList.findIndex(item => item.id === values[Service])}
          options={serviceOptionsList}
          onSelect={(index: number) => {
            setFieldValue(Service, serviceOptionsList[index].id);
            setEditService(true);
          }}
          onDeleteIconClick={() => {
            setFieldValue(Service, '');
            setEditService(true);
          }}
          onEditClick={() => {
            setEditService(true);
          }}
          type="service"
          disabled={infoMode}
        />
        <H6 marginTop="8px">{t('calendar.newAppointmentSidebar.appointment')}</H6>
        <TimeWrapper>
          <FormikDropdown
            id={Start}
            name={Start}
            placeholder={t('calendar.newAppointmentSidebar.start')}
            // currentOption={timeList.length >= startTime ? startTime : 0}
            scrollTo={startScroll}
            currentOption={startTime}
            options={timeList}
            onChange={handleChangeStartTime}
            isSeparator
            autoScroll
            disabled={infoMode || isGoogle || values[Status] === 3}
          />
          <FormikDropdown
            id={End}
            name={End}
            placeholder={t('calendar.newAppointmentSidebar.end')}
            // currentOption={timeList.length >= endTime ? endTime : 0}
            scrollTo={endScroll}
            currentOption={endTime}
            options={timeList}
            onChange={handleChangeEndTime}
            isSeparator
            autoScroll
            disabled={infoMode || isGoogle}
          />
          <FormikDropdown
            id={AppointmentDate}
            name={AppointmentDate}
            placeholder={t('calendar.newAppointmentSidebar.date')}
            currentOption={isEmptyDate ? -1 : 0}
            options={[appointmentDate]}
            onClick={handleCalendarClick}
            isSeparator
            disabled={infoMode || isGoogle}
          />
          {isCalendarVisible && (
            <SelectorWrapper>
              <DateSelector
                locale={userLocale}
                onClose={handleCloseDateSelector}
                selectedDate={
                  appointmentDate.item ? moment(appointmentDate.item, 'DD.MM.yyyy').toDate() : moment().toDate()
                }
                buttonLabel={t('calendar.newAppointmentSidebar.selectDate')}
                onSelectDateClick={handleCloseDateSelector}
                onChange={handleChangeDate}
                minDate={new Date()}
                size={isMobile ? 'md' : 'lg'}
              />
            </SelectorWrapper>
          )}
        </TimeWrapper>

        {!values[Specialist] && isMobile ? (
          <Button
            design="secondary"
            onClick={() => setEditSpecialist(true)}
            disabled={!values[Service] || !values[Start] || !values[End] || !values[AppointmentDate]}
          >
            {t('calendar.newAppointmentSidebar.chooseSpecialist')}
          </Button>
        ) : (
          specialistForm
        )}

        <ButtonsWrapper>
          <Button width="100%" design="secondary" type="button" onClick={handleClear} disabled={isClear}>
            {t('button.clear')}
          </Button>
          <Button
            width="100%"
            type="button"
            disabled={!isFieldsFilled || !isValid}
            onClick={() => {
              setIsService(true);
              setTextfield(false);
              setEditService(false);
            }}
          >
            {t('button.apply')}
          </Button>
        </ButtonsWrapper>

        {isEditSpecialist && (
          <BottomSheet
            isOpen
            content={specialistForm}
            onClose={() => setEditSpecialist(false)}
            label={t('calendar.newAppointmentSidebar.chooseSpecialistForAppointment')}
            descriptor={t('calendar.newAppointmentSidebar.mainInformation')}
            // detent="content-height"
          />
        )}
      </FormWrapper>
    </Form>
  );
};
