import { TFunction } from 'react-i18next';
import moment, { Moment } from 'moment';
import { colors } from '@beauty/beauty-market-ui';
import { todayColorWorking } from 'components/Calendar/constant';
import { Client, WorkDayType } from 'types/organisation';

const defaultStartWorkingHour = moment().set({ h: 8, m: 0 });
const defaultEndWorkingHour = moment().set({ h: 17, m: 0 });

type WorkTime = { startWorking: Moment | null; endWorking: Moment | null };
type WorkSchedule = Record<string, WorkTime>;

export const getCurrentWorktime = (organisationWorkTime: WorkDayType[] | null): WorkSchedule | undefined => {
  let startWorking = defaultStartWorkingHour;
  let endWorking = defaultEndWorkingHour;

  // const selectedDay = moment(selectedWeekday).isoWeekday();

  const workingTime = organisationWorkTime?.reduce((schedule, daySchedule) => {
    const { start, end, isWorkDay } = daySchedule;
    if (!isWorkDay) schedule[daySchedule.dayOfWeek] = { startWorking: null, endWorking: null };
    else {
      if (start) {
        const startTime = moment(start, 'HH:mm');
        startWorking = moment().set({
          hour: startTime.get('hour'),
          minute: startTime.get('minute'),
        });
      }

      if (end) {
        const endTime = moment(end, 'HH:mm:ss');
        if (endTime.get('hour') === 0 && endTime.get('minute') === 0)
          endWorking = moment().set({
            hour: 23,
            minute: 59,
            second: 59,
          });
        else
          endWorking = moment().set({
            hour: endTime.get('hour'),
            minute: endTime.get('minute'),
          });
      }
      schedule[daySchedule.dayOfWeek] = {
        startWorking,
        endWorking,
      };
    }

    return schedule;
  }, {});

  return workingTime;
};

export const getWorktimelimits = (organisationWorkTime: WorkDayType[] | null) => {
  let minStartWorkingHour = defaultStartWorkingHour;
  let maxEndWorkingHour = defaultEndWorkingHour;

  if (organisationWorkTime) {
    const weeklyStartWorkTime = organisationWorkTime.map(dayworkTime => dayworkTime.start).filter(start => start);
    const weeklyEndWorkTime = organisationWorkTime.map(dayworkTime => dayworkTime.end).filter(end => end);

    const start = weeklyStartWorkTime.reduce((minValue, item) => (minValue < item ? minValue : item));
    const end = weeklyEndWorkTime.reduce((maxValue, item) => (maxValue > item ? maxValue : item));

    const minStartTime = moment(start, 'HH:mm');
    const maxEndTime = moment(end, 'HH:mm');

    minStartWorkingHour = moment().set({
      hour: minStartTime.get('hour'),
      minute: minStartTime.get('minute'),
    });
    maxEndWorkingHour = moment().set({
      hour: maxEndTime.get('hour'),
      minute: maxEndTime.get('minute'),
    });
  }

  return {
    minStartWorkingHour,
    maxEndWorkingHour,
  };
};

export const isToday = date => moment(date).isSame(moment(), 'day');

export const getCellBackground = (
  isDayToday: boolean,
  isWorkTime: boolean,
  isWeekView: boolean,
  isPastSlot: boolean,
) => ({
  className:
    isWeekView && isDayToday ? `reset${isPastSlot ? ' past-date-cell' : ''}` : (isPastSlot && 'past-date-cell') || '',
  style: isWorkTime
    ? {
        background: isWeekView && isDayToday ? todayColorWorking : colors.white.standard,
      }
    : {},
});

export const getISOStartDay = (day: string | Moment): string => {
  if (moment.isMoment(day)) {
    return day.startOf('day').toISOString();
  }
  return moment(day).startOf('day').toISOString();
};

export const getISOEndDay = (day: string | Moment) => {
  if (moment.isMoment(day)) {
    return day.endOf('day').toISOString();
  }
  return moment(day).endOf('day').toISOString();
};

export const setLocalesData = () => {
  moment.locale('RU', {
    // months: 'Январь_Февраль_Март_Апрель_Май_Июнь_Июль_Август_Сентябрь_Октябрь_Ноябрь_Декабрь'.split('_'),
    monthsShort: 'Янв_Фев_Март_Апр_Май_Июнь_Июль_Авг_Сен_Окт_Ноя_Дек'.split('_'),
    weekdaysShort: 'Вс_Пн_Вт_Ср_Чт_Пт_Сб'.split('_'),
  });
  moment.locale('HE', {
    // months: 'Январь_Февраль_Март_Апрель_Май_Июнь_Июль_Август_Сентябрь_Октябрь_Ноябрь_Декабрь'.split('_'),
    monthsShort: 'דצמבר_נובמבר_אוקטובר_ספטמבר_אוגוסט_יולי_יוני_מאי_אפריל_מרץ_פברואר_ינואר'.split('_').reverse(),
    weekdaysShort: 'יום שבת_יום ו׳_יום ה׳_יום ד׳_יום ג׳_יום ב׳_יום א׳'.split('_').reverse(),
  });
};

export const getDeletedClientTemplate = (t: TFunction<'translation', undefined>): Client => ({
  id: 'Deleted Client',
  code: '',
  name: t('Deleted'),
  number: '',
  headOrgClientId: 'Deleted Client',
  surname: t('Client'),
  avatar: null,
  email: '',
  createdBy: '',
  clientId: '',
  accountId: '',
  verified: false,
  orgClientIds: [],
});

export const extractDayOfWeek = (date: Date) => moment(date).format('dddd').toUpperCase();
export const extractWorkTimeMoment = (date: Date, time: Moment) =>
  moment(date).set({
    hour: time?.get('hour'),
    minute: time?.get('minute'),
  });

export const isWorkingSlot = (date: Date, schedule?: WorkSchedule) => {
  const slotDate = moment(date);
  const dayOfWeek = extractDayOfWeek(date);

  const startWorking = schedule ? schedule[dayOfWeek]?.startWorking : null;
  const endWorking = schedule ? schedule[dayOfWeek]?.endWorking : null;

  if (startWorking && endWorking) {
    const start = extractWorkTimeMoment(date, startWorking);
    const end = extractWorkTimeMoment(date, endWorking);

    return slotDate.isBetween(start, end, undefined, '[)');
  }
  return false;
};

export const getStartWorkingTime = (date, schedule?: WorkSchedule) => {
  const dayOfWeek = extractDayOfWeek(date);
  const startWorking = schedule ? schedule[dayOfWeek].startWorking : null;
  return startWorking
    ? {
        hour: startWorking.get('hour'),
        minute: startWorking.get('minute'),
      }
    : defaultStartWorkingHour;
};
