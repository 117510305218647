import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ArrowLeftIcon, Caption, ImageCropper, getMinWidthMediaQuery, useMediaQuery } from '@beauty/beauty-market-ui';
import { SidebarFooter } from '../SidebarFooter/SidebarFooter';
import { SidebarSheet } from '../SidebarSheet';
import { BackWrapper } from './style';
import { EditPhotoType } from './types';

export const EditPhoto = ({ onSubmit, onClose }: EditPhotoType) => {
  const { t } = useTranslation();

  const [isCrop, setIsCrop] = useState(false);
  const [isLastStep, setLastStep] = useState(false);

  const mediaQuery = getMinWidthMediaQuery('md');
  const isDesktop = useMediaQuery(mediaQuery);

  const handleSomething = () => {
    setIsCrop(true);
    setLastStep(false);
    setTimeout(onClose, 0);
  };

  const footerBody = <SidebarFooter disabled={!isLastStep} onSubmit={handleSomething} onBack={onClose} cancel />;

  const sidebarDescriptor = isDesktop ? (
    <BackWrapper onClick={onClose}>
      <ArrowLeftIcon width="16px" height="16px" />
      <Caption large>{t('button.back')}</Caption>
    </BackWrapper>
  ) : null;

  const generalProps = {
    isOpen: true,
    label: t(`uploadPhoto.newPhoto`),
    descriptor: sidebarDescriptor,
    onClose,
    onBack: isDesktop ? undefined : onClose,
    FooterBody: footerBody,
  };

  const content = (
    <ImageCropper
      dragText={t('uploadPhoto.dragText')}
      browseText={t('uploadPhoto.browseText')}
      restrictionsText={t('uploadPhoto.restrictionsText')}
      errorText={t('uploadPhoto.errorText')}
      maxZoom={5}
      isCrop={isCrop}
      setIsCrop={setIsCrop}
      onCrop={onSubmit}
      isLastStep={isLastStep}
      setLastStep={setLastStep}
    />
  );

  return <SidebarSheet {...generalProps}>{content}</SidebarSheet>;
};

export default EditPhoto;
