import React from 'react';
import { DropdownOption } from 'types/calendar';
import { DropdownWrapper, ItemWrapper } from './style';

export type DropdownMenuProps = {
  items: DropdownOption[];
};

export const DropdownMenu = ({ items }: DropdownMenuProps) => (
  <DropdownWrapper>
    {items.map((item, index) => (
      <React.Fragment key={index}>
        <ItemWrapper
          onClick={event => {
            event.stopPropagation();
            !item.disabled && item.onClick();
          }}
        >
          {item.body}
        </ItemWrapper>
      </React.Fragment>
    ))}
  </DropdownWrapper>
);
