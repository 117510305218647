import { ChangeEvent, useMemo, MouseEvent } from 'react';
import { Views } from 'react-big-calendar';
import { t } from 'i18next';
import moment, { Moment } from 'moment';
import { BodySmall, colors, H6, Caption, Flex } from '@beauty/beauty-market-ui';
import { getNumberOfEvents, getProgress, getScheduleWeekIndex } from 'helpers/utils';
import { useMediaScreen } from 'hooks/useMediaScreen';
import { useAppSelector } from 'store/hooks';
import { selectAppointments } from 'store/redux-slices/appointmentsSlice';
import { EventType, SpecialistSummaryType } from 'types';
import { TimeslotType } from 'types/event';
import { usePluralForm } from '../../../hooks/useDynamicTranslation';
import { isToday } from '../../../page/Calendar/helpers';
import { CircleProgress } from '../CircleProgress/CircleProgress';
import { MonthKebabMenu } from '../KebabMenu/MonthKebabMenu';
import { getSeparateBySpecialistTimeslots } from '../Sidebars/helpers';
import { BodyWrapper, DateAppointment, DateContainer, DateHeader } from './style';

type MonthDateProps = {
  date: Moment;
  label: string;
  isOffRange: boolean;
  onNavigate: (date: Moment) => void;
  view: Views;
  onView: (view: Views) => void;
  events: EventType[];
  organisationSpecialists: SpecialistSummaryType[] | null;
  setAddFromMenu: (isOpen: boolean) => void;
  setDateFromMenu: (date: string) => void;
  onCancelAll: () => void;
  isAdmin: boolean;
  isSubscription: boolean;
  onCloseDay: () => void;
  setEmptyDay: (IsEmptyDay: boolean) => void;
  setOptionsMenu: (isMenu: boolean) => void;
};

export const MonthDate = ({
  date,
  label,
  isOffRange,
  onNavigate,
  view,
  onView,
  events,
  organisationSpecialists,
  setAddFromMenu,
  setDateFromMenu,
  onCancelAll,
  isAdmin,
  isSubscription,
  onCloseDay,
  setEmptyDay,
  setOptionsMenu,
}: MonthDateProps) => {
  const { isMobile, isDesktop } = useMediaScreen('md');
  const { selectedSpecialist } = useAppSelector(selectAppointments);
  const typeOfDate =
    moment(date).format('DD-MM-YYYY') === moment().format('DD-MM-YYYY')
      ? 'today'
      : (moment(date).isBefore(moment()) && 'past') || 'future';

  const handleClick = () => {
    if (isMobile) {
      setDateFromMenu(moment(date).toISOString());
      setOptionsMenu(true);
    } else {
      onNavigate(date);
      onView(Views.DAY);
    }
  };

  const specialistsDaySchedule = (organisationSpecialists as SpecialistSummaryType[]).reduce((res, spec) => {
    const scheduleIndex = getScheduleWeekIndex(spec.orgSpecSchedule, moment(spec.scheduleStart), date);
    res[spec.orgSpecId] = spec.orgSpecSchedule[scheduleIndex]?.orgSpecDaySchedule.find(
      day => day.dayOfWeek === moment(date).format('dddd').toUpperCase(),
    );
    return res;
  }, {});

  const specialistTimeslots = getSeparateBySpecialistTimeslots(events, specialistsDaySchedule);

  const allDayBreaks = events.filter(
    event =>
      event.resource.isBreak &&
      moment(event.start).format('HH:mm') === '00:00' &&
      moment(event.end).format('HH:mm') === '23:59',
  );
  const isBreaksDayOff =
    (selectedSpecialist && allDayBreaks.length) ||
    (!selectedSpecialist &&
      organisationSpecialists?.every(spec => allDayBreaks.find(item => item.resourceId === spec.orgSpecId)));

  const design = isToday(date) ? 'white' : 'grey';

  const { totalEventsCount, progress, isDayOff } = useMemo(() => {
    const slots: TimeslotType[][] = [];
    specialistTimeslots &&
      Object.values(specialistTimeslots).forEach(specialist => {
        slots.push(specialist.timeslots);
      });

    return {
      totalEventsCount: getNumberOfEvents(specialistTimeslots),
      progress: getProgress(slots),
      isDayOff:
        (specialistTimeslots && !Object.values(specialistTimeslots).find(item => item.timeslots)) ||
        !specialistTimeslots ||
        isBreaksDayOff,
    };
  }, [specialistTimeslots]);

  const phrase = isDayOff
    ? t('calendar.dayOff')
    : usePluralForm('calendar.appointment', totalEventsCount.totalWithoutBreaks);
  const isShowKebabMenu = isAdmin && isDesktop && !moment(date).isBefore(moment(), 'day');

  const handleCloseDay = (e: ChangeEvent<HTMLInputElement>) => {
    e.stopPropagation();
    onCloseDay();
    setEmptyDay(!totalEventsCount.totalWithoutBreaks);
  };

  const handleCreateAppointment = (e: MouseEvent<HTMLDivElement>) => {
    e.stopPropagation();
    setDateFromMenu(date.toISOString());
    setAddFromMenu(true);
  };

  const handleCancelAll = (e: MouseEvent<HTMLDivElement>) => {
    e.stopPropagation();
    setDateFromMenu(date.toISOString());
    onCancelAll();
  };

  return (
    <DateContainer isShowDots={isSubscription && !isMobile}>
      {isMobile && (
        <Flex width="56px" height="48px" alignItems="start">
          <Caption lowline>{moment(date).format('ddd')}</Caption>
        </Flex>
      )}
      <BodyWrapper onClick={handleClick} type={typeOfDate}>
        <DateHeader>
          <CircleProgress
            progress={typeOfDate === 'past' ? 0 : progress}
            design={design}
            showCircle={typeOfDate !== 'past' && !isDayOff}
          >
            <H6 color={isOffRange ? colors.grey.standard : colors.black.standard}>{label}</H6>
          </CircleProgress>
          {isShowKebabMenu && (
            <MonthKebabMenu
              onCloseDay={handleCloseDay}
              onCancelAll={handleCancelAll}
              onCreateAppointment={handleCreateAppointment}
            />
          )}
        </DateHeader>
        {(!!totalEventsCount.total || isDayOff) && (
          <DateAppointment height={view === Views.MONTH && isMobile ? '20px' : 'auto'} dayOff={isDayOff}>
            <BodySmall large color={colors.white.standard}>
              {phrase}
            </BodySmall>
          </DateAppointment>
        )}
      </BodyWrapper>
    </DateContainer>
  );
};
