import { useEffect, useMemo, useState } from 'react';
import { momentLocalizer, Views } from 'react-big-calendar';
import { useTranslation } from 'react-i18next';
import size from 'lodash/size';
import moment, { Moment } from 'moment';
import { CalendarHeader, CalendarHeaderProps } from 'components/Calendar/CalendarHeader/CalendarHeader';
import { MobileCalendarHeader } from 'components/Calendar/CalendarHeader/MobileCalendarHeader';
import { OptionsMenu } from 'components/Calendar/CalendarHeader/OptionsMenu';
import { DayEvent } from 'components/Calendar/Event/DayEvent';
import { MenuButton } from 'components/Calendar/FloatingButtons/MenuButton';
import { PlusButton } from 'components/Calendar/FloatingButtons/PlusButton';
import { MonthHeader } from 'components/Calendar/MonthHeader/MonthHeader';
import { CancelAllPopup } from 'components/Calendar/Popups/CancelAllPopup/CancelAllPopup';
import { CloseDayPopup } from 'components/Calendar/Popups/CloseDayPopup/CloseDayPopup';
import { ActiveEventsSidebar } from 'components/Calendar/Sidebars/ActiveEventsSidebar/ActiveEventsSidebar';
import { BreakSidebar } from 'components/Calendar/Sidebars/BreakSidebar/BreakSidebar';
import { AppointmentMobile } from 'components/Calendar/Sidebars/NewAppointmentSidebar/AppointmentMobile';
import { AppointmentSidebar } from 'components/Calendar/Sidebars/NewAppointmentSidebar/AppointmentSidebar';
import { NewClientSidebar } from 'components/Calendar/Sidebars/NewClientSidebar/NewClientSidebar';
import { WidgetHeader } from 'components/Calendar/WidgetHeader/WidgetHeader';
import { NoData } from 'components/NoData';
import { isRtl } from 'helpers';
import { appointmentsAPI } from 'helpers/appointmentsAPI';
import { getAccount } from 'helpers/generalAPI';
import { organisationAPI } from 'helpers/organisationAPI';
import { useMediaScreen } from 'hooks';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { selectSpecialist } from 'store/redux-slices/specialistSlice';
import { updateGoogleTokens } from 'store/redux-slices/userSlice';
import { extractWeek } from 'store/utils';
import { AppointmentByIdResponse, EmployeeRole, SpecialistResponse, SubscriptionStatusType, WorkDayType } from 'types';
import { AppointmentPrefilledData, BreakType, GoogleEventType, SpecialistSummaryType } from 'types/appointment';
import { Company } from 'types/calendar';
import { MonthDate } from '../../components/Calendar/MonthDate/MonthDate';
import {
  EventStatus,
  getSelectedLanguage,
  listViewDaysPeriod,
  numberOfTimeslotsPerTimeStep,
  SpecialistsRoles,
  timeStepInDayWeekView,
} from '../../constants';
import { AsyncComponent } from '../../hoc';
import {
  selectAppointments,
  setCategoryData,
  setSelectedClient,
  setServiceData,
  setSpecialistData,
  setView,
} from '../../store/redux-slices/appointmentsSlice';
import { ThunkClient } from '../../store/redux-slices/clientSlice';
import { selectActiveSubscription } from '../../store/redux-slices/organisationSlice';
import {
  getCellBackground,
  getCurrentWorktime,
  getStartWorkingTime,
  isToday,
  isWorkingSlot,
  setLocalesData,
} from './helpers';
import { CustomTimeGutterWrapper, FloatingButton, StyledCalendar } from './style';
import { useCustomCalendar } from './useCustomCalendar';

import 'react-big-calendar/lib/addons/dragAndDrop/styles.css';
import 'react-big-calendar/lib/css/react-big-calendar.css';

setLocalesData();

export const CustomCalendar = () => {
  const { t } = useTranslation();
  const currentDate = moment().toDate();

  const currentSpecialist = useAppSelector(selectSpecialist);
  const subscription = useAppSelector(selectActiveSubscription);
  const { selectedSpecialist, selectedClient, specialistData, specialistIds, isLoading } =
    useAppSelector(selectAppointments);

  // TODO: need to hide to small components all useState behavior for optimization calendar
  const [isInitializing, setInitializing] = useState(true);
  const [company, setCompany] = useState<Company | null>(null);
  const [organisationWorkTime, setOrganisationWorkTime] = useState<WorkDayType[] | null>(null);
  const [organisationSpecialists, setOrganisationSpecialists] = useState<SpecialistSummaryType[] | null>(null);
  const [selectedAppointmentData, setSelectedAppointmentData] = useState<
    AppointmentByIdResponse | GoogleEventType | null
  >(null);
  const [selectedBreakData, setSelectedBreakData] = useState<BreakType | null>(null);

  const [isEditAppointmentSidebar, setIsEditAppointmentSidebar] = useState(false);
  const [isAppointmentSidebarVisible, setIsAppointmentSidebarVisible] = useState(false);
  const [isNewClientSidebarVisible, setIsNewClientSidebarVisible] = useState(false);
  const [isAddBreak, setAddBreak] = useState(false);
  const [isCancelAll, setCancelAll] = useState(false);

  const [isEditBreakSidebar, setEditBreakSidebar] = useState(false);
  const [breakSpecialist, setBreakSpecialist] = useState<string | null>(null);
  const [isActiveEvents, setActiveEvents] = useState(false);

  const [selectedSlot, setSelectedSlot] = useState<{ start: string; resourceId: string | null } | null>(null);
  const [newAppointmentData, setNewAppointmentData] = useState<AppointmentPrefilledData | null>(null);
  const [isAddFromMenu, setAddFromMenu] = useState(false);
  const [dateFromMenu, setDateFromMenu] = useState<string | null>(null);
  const [cancelAllSpecialist, setCancelAllSpecialist] = useState<string | null>(null);
  const [isAppointmentDetails, setAppointmentDetails] = useState(false);
  const [dateToClose, setDateToClose] = useState<Date | null>(null);
  const [isEmptyDay, setEmptyDay] = useState(false);
  const [optionsMenu, setOptionsMenu] = useState(false);

  const [eventMouseMove, setEventMouseMove] = useState(false);

  const dispatch = useAppDispatch();
  const locale = getSelectedLanguage();

  const rtl = isRtl();

  moment.locale(locale, {
    week: {
      dow: locale === 'HE' ? 0 : 1,
      doy: 1,
    },
  });

  // TODO: Uncomment to set calendar time range to align with organisation working hours
  // const { startWorkingHour, endWorkingHour } = getCurrentWorktime(organisationWorkTime, selectedWeekday);
  // const { minStartWorkingHour, maxEndWorkingHour } = getWorktimelimits(organisationWorkTime);

  const {
    selectedView,
    selectedWeekday,
    events,
    unclosedEvents,
    inProgressEvents,
    resources,
    user,
    isUserAdmin,
    isSuperAdmin,
    userHeadOrgId,
    organisationId,
    orgSpecialist,
    handleChangeView,
    handleNavigate,
    handleRangeChange,
    DayHeader,
    specialistCount,
  } = useCustomCalendar({
    onAddBreak: () => setAddBreak(true),
    setBreakSpecialist,
    setCancelAllSpecialist,
    onCancelAll: () => setCancelAll(true),
    setDateFromMenu,
  });

  const schedule = getCurrentWorktime(organisationWorkTime);
  // const { minStartWorkingHour, maxEndWorkingHour } = getWorktimelimits(organisationWorkTime);

  // Should be used if we have issues with clustering custom component
  // const eventStyleGetter = event => ({
  //   style: {
  //     border: 'none',
  //     backgroundColor: event.resource.backgroundColor,
  //     opacity: moment(event.end).isAfter(currentDate) ? 1 : 0.5,
  //   },
  // });

  const localizer = momentLocalizer(moment);
  const isSubscription = subscription?.status === SubscriptionStatusType.ACTIVE;

  const { isDesktop, isMobile } = useMediaScreen('md');

  const dayOfWeek =
    (isMobile &&
      !selectedSpecialist &&
      (locale === 'HE' ? moment(selectedWeekday).day() + 1 : moment(selectedWeekday).isoWeekday())) ||
    undefined;

  const agendaViewAvatarUrl = selectedSpecialist
    ? specialistData[selectedSpecialist]?.avatarUrl
    : (!isUserAdmin && Object.values(specialistData)[0]?.avatarUrl) || null;

  const agendaViewSpecialistName = selectedSpecialist
    ? `${specialistData[selectedSpecialist]?.name} ${specialistData[selectedSpecialist]?.surname}`
    : (!isUserAdmin && `${Object.values(specialistData)[0]?.name} ${Object.values(specialistData)[0]?.surname}`) ||
      undefined;

  const agendaViewSpecialization = selectedSpecialist
    ? specialistData[selectedSpecialist]?.specialization
    : (isUserAdmin && '') || `${Object.values(specialistData)[0]?.specialization}`;

  const weekViewHeaderAvatarUrl =
    isMobile && specialistIds.length === 1 ? Object.values(specialistData)[0].avatarUrl : undefined;

  const weekViewHeaderTitle = date =>
    isMobile && specialistIds.length === 1
      ? Object.values(specialistData)[0].specialization
      : moment(date).format('ddd');

  const weekViewHeaderText = date =>
    isMobile && specialistIds.length === 1
      ? `${Object.values(specialistData)[0].name} ${Object.values(specialistData)[0].surname}`
      : moment(date).format('DD');

  const weekViewHeaderUrls =
    isMobile && !selectedSpecialist && specialistIds.length > 1
      ? Object.values(specialistData).map(spec => spec.avatarUrl)
      : undefined;

  const filteredSpecialists = useMemo(
    () =>
      organisationSpecialists?.filter(
        spec =>
          spec.role === SpecialistsRoles.SPECIALIST &&
          (!selectedSpecialist || (selectedSpecialist && spec.orgSpecId === selectedSpecialist)),
      ) ?? null,
    [organisationSpecialists, selectedSpecialist],
  );

  const handleSlotClick = slot => {
    isSubscription && !eventMouseMove && setSelectedSlot(slot);
  };

  useEffect(() => {
    selectedSlot &&
      moment(selectedSlot.start).isAfter(moment()) &&
      setNewAppointmentData({
        start: new Date(selectedSlot.start).toISOString(),
        orgSpecialist: selectedSlot.resourceId || undefined,
      });
  }, [selectedSlot]);

  useEffect(() => {
    userHeadOrgId && dispatch(ThunkClient.fetchClients(userHeadOrgId));
  }, [userHeadOrgId]);

  useEffect(() => {
    const abortController = new AbortController();

    const fetchOrganisationData = async (id: string) => {
      const orgData = await organisationAPI.fetchAddressById(id);
      if (!abortController.signal.aborted) {
        const specialists = orgData.orgSpecialist
          .filter(
            spec =>
              spec.role !== EmployeeRole.MANAGER &&
              spec.active &&
              (isUserAdmin || spec.orgSpecId === orgSpecialist?.id || spec.headOrgSpecId === user.userId),
          )
          .map(spec => ({ ...spec, serviceIds: spec.orgSpecService.map(item => item.orgService.id) }));

        setCompany({
          name: orgData.name,
          address: orgData.address.address[0].fullAddress,
          email: orgData.contact.email.email,
        });
        setOrganisationSpecialists(specialists);
        setOrganisationWorkTime(extractWeek(orgData.orgBusinessHours));
        dispatch(setSpecialistData(specialists));
        dispatch(setCategoryData(orgData.category));
        dispatch(setServiceData(orgData.orgService));
        setIsAppointmentSidebarVisible(!!selectedClient);
      }
    };

    if (organisationId) {
      fetchOrganisationData(organisationId).finally(() => setTimeout(() => setInitializing(false), 1000));
    }
  }, [organisationId]);

  useEffect(() => {
    const fetchAccount = async () => {
      const response = await getAccount(user.userId);
      if (response) {
        const { headOrgSpecialist } = response;
        headOrgSpecialist && dispatch(updateGoogleTokens(headOrgSpecialist.googleTokens));
      }
    };

    fetchAccount();
  }, []);

  const handleClickEvent = async (id: string, event?: GoogleEventType | BreakType) => {
    const appointment = !event && (await appointmentsAPI.fetchAppointmentById(id));

    if (event || (appointment && size(appointment.data))) {
      ((!isAppointmentDetails && !event) || (event && moment(event?.start).isAfter(moment()))) &&
        setIsEditAppointmentSidebar(true);

      if (moment(event?.end).isAfter(moment()) || (appointment && size(appointment.data))) {
        if (event && !event.isGoogle) {
          setAddBreak(true);
          setSelectedBreakData(event as BreakType);
          setEditBreakSidebar(true);
        } else {
          (!event || moment(event?.start).isAfter(moment())) && setIsAppointmentSidebarVisible(true);
          setSelectedAppointmentData(appointment ? appointment.data : event);
        }
      }
    }
    appointment &&
      size(appointment.data) &&
      moment(appointment.data.end).isBefore(moment()) &&
      appointment.data.status !== EventStatus.UNCLOSED &&
      setAppointmentDetails(true);
  };

  const handleAppointmentSidebarClose = () => {
    setNewAppointmentData(null);
    setIsEditAppointmentSidebar(false);
    setIsAppointmentSidebarVisible(false);
    setAddFromMenu(false);
    setDateFromMenu(null);
    selectedClient && dispatch(setSelectedClient(null));
  };

  const handleBreakSidebarClose = () => {
    setAddBreak(false);
    setBreakSpecialist(null);
    setAddFromMenu(false);
    setDateFromMenu(null);
    setSelectedBreakData(null);
    setEditBreakSidebar(false);
    setIsEditAppointmentSidebar(false);
  };

  const appointmentSidebarProps = {
    isEditMode: isEditAppointmentSidebar,
    selectedWeekday,
    selectedAppointmentData: isEditAppointmentSidebar ? selectedAppointmentData : null,
    newAppointmentData,
    organisationSpecialists,
    organisationWorkTime,
    company,
    handleClose: handleAppointmentSidebarClose,
    isAddFromMenu,
    dateFromMenu,
    isAppointmentDetails,
    setAppointmentDetails,
    isVisible: isAppointmentSidebarVisible,
    setVisible: setIsAppointmentSidebarVisible,
  };

  // TODO: Temporary solution. Need to find better way to style day and week views
  const slotPropGetter = (date: Date) => {
    const isDayToday = new Date().toDateString() === date.toDateString();
    const isPastSlot = moment(date).isBefore(moment());
    const isWorkTime = isWorkingSlot(date, schedule);
    const isWeekView = selectedView === Views.WEEK;
    return getCellBackground(isDayToday, isWorkTime, isWeekView, isPastSlot);
  };

  const handleDayPropGetter = (date: Date) => {
    const isPastDay = moment(date).isBefore(moment());
    return isPastDay ? { className: 'past-date-cell' } : {};
  };

  const handleCloseDay = () => {
    setDateToClose(null);
  };

  const getScrollToTime = () => {
    switch (true) {
      case isToday(selectedWeekday):
        return moment();
      default: {
        return getStartWorkingTime(selectedWeekday, schedule);
      }
    }
  };

  const handleCancelAll = () => {
    setCancelAllSpecialist(null);
    setDateFromMenu(null);
    setCancelAll(false);
  };

  useEffect(() => {
    isMobile &&
      !selectedSpecialist &&
      selectedView !== Views.AGENDA &&
      selectedView !== Views.MONTH &&
      dispatch(setView(Views.WEEK));
    isMobile && selectedSpecialist && selectedView === Views.WEEK && dispatch(setView(Views.DAY));
    isDesktop && selectedView === Views.AGENDA && dispatch(setView(selectedSpecialist ? Views.DAY : Views.WEEK));
  }, [isMobile, selectedSpecialist]);

  useEffect(() => {
    (newAppointmentData || isAddFromMenu) && setIsAppointmentSidebarVisible(true);
  }, [newAppointmentData, isAddFromMenu]);

  useEffect(() => setEventMouseMove(false), [isAppointmentSidebarVisible, isAddBreak]);

  return (
    <AsyncComponent isLoading={isInitializing}>
      <StyledCalendar
        messages={{
          noEventsInRange: (
            <AsyncComponent isLoading={isLoading}>
              <NoData title={t('calendar.noAppointments')} info="" />
            </AsyncComponent>
          ),
        }}
        isSubscription={isSubscription}
        date={selectedWeekday}
        view={selectedView}
        showMultiDayTimes
        events={events}
        slotPropGetter={slotPropGetter}
        dayPropGetter={handleDayPropGetter}
        localizer={localizer}
        rtl={rtl}
        resizable
        onRangeChange={handleRangeChange}
        resourceIdAccessor="resourceId"
        withBanner={isSuperAdmin && !subscription?.renewed}
        resources={(selectedView === Views.DAY || selectedView === Views.AGENDA) && resources.length ? resources : null}
        resourceTitleAccessor="resourceTitle"
        timeslots={numberOfTimeslotsPerTimeStep}
        step={timeStepInDayWeekView}
        scrollToTime={getScrollToTime()}
        length={listViewDaysPeriod}
        // TODO: Uncomment to set calendar time range to align with organisation working hours
        /* min={selectedView === Views.DAY ? startWorkingHour : minStartWorkingHour}
        max={selectedView === Views.DAY ? endWorkingHour : maxEndWorkingHour} */
        onView={handleChangeView}
        onNavigate={handleNavigate}
        isTimeIndicator={selectedView === Views.DAY || (isMobile && selectedView === Views.WEEK)}
        selectable={selectedView !== Views.MONTH && !eventMouseMove}
        onSelectSlot={handleSlotClick}
        dayOfWeek={dayOfWeek}
        isLoading={isLoading}
        components={{
          toolbar: ({ date, view, label, onView, onNavigate }: CalendarHeaderProps) => {
            const headerProps = {
              view,
              onView,
              onNavigate,
              onAddBreak: () => setAddBreak(true),
              onCancelAll: () => {
                setCancelAll(true);
                setDateFromMenu(date.toString());
              },
            };

            return isMobile ? (
              <>
                <MobileCalendarHeader {...headerProps} />
                {(view === Views.AGENDA || view === Views.MONTH) && (
                  <WidgetHeader
                    {...headerProps}
                    date={selectedWeekday}
                    avatarUrl={agendaViewAvatarUrl}
                    specialistName={agendaViewSpecialistName}
                    title={agendaViewSpecialization}
                    text={agendaViewSpecialistName ?? ''}
                    specId={selectedSpecialist}
                    onAddSpecialistBreak={setBreakSpecialist}
                    onSelectDate={setDateFromMenu}
                    onCancelSpecialistAll={setCancelAllSpecialist}
                    isMobile
                    urls={
                      isUserAdmin && !selectedSpecialist
                        ? Object.values(specialistData).map(spec => spec.avatarUrl)
                        : undefined
                    }
                    forAgenda
                  />
                )}
              </>
            ) : (
              <CalendarHeader {...headerProps} date={date} label={label} />
            );
          },
          event: ({ event }) => (
            <DayEvent
              event={event}
              currentDate={currentDate}
              handleClick={handleClickEvent}
              setEventMouseMove={setEventMouseMove}
              isSingleAppointment={selectedView === Views.DAY && specialistCount === 1}
              isAgenda={selectedView === Views.AGENDA}
            />
          ),
          timeGutterWrapper: CustomTimeGutterWrapper,
          timeGutterHeader: DayHeader,
          header: ({ date }) =>
            selectedView === Views.WEEK ? (
              <WidgetHeader
                date={date}
                isToday={isMobile && specialistIds.length === 1 ? false : isToday(date)}
                avatarUrl={weekViewHeaderAvatarUrl}
                title={weekViewHeaderTitle(date)}
                text={weekViewHeaderText(date)}
                view={selectedView}
                onAddBreak={() => setAddBreak(true)}
                onAddSpecialistBreak={setBreakSpecialist}
                onSelectDate={setDateFromMenu}
                onCancelAll={() => setCancelAll(true)}
                onCancelSpecialistAll={setCancelAllSpecialist}
                isMobile={isMobile}
                urls={weekViewHeaderUrls}
              />
            ) : null,
          month: {
            header: ({ date }) => <MonthHeader title={moment(date).format('ddd')} />,
            dateHeader: ({ date, label, isOffRange }) => {
              const filteredEvents = useMemo(
                () => events.filter(event => moment(event.start).isSame(moment(date), 'day')),
                [events, date],
              );
              return (
                <MonthDate
                  date={date}
                  label={label}
                  isOffRange={isOffRange}
                  view={selectedView}
                  onView={handleChangeView}
                  onNavigate={handleNavigate}
                  events={filteredEvents}
                  organisationSpecialists={filteredSpecialists}
                  setAddFromMenu={setAddFromMenu}
                  setDateFromMenu={setDateFromMenu}
                  onCancelAll={() => setCancelAll(true)}
                  isAdmin={isUserAdmin}
                  isSubscription={isSubscription}
                  onCloseDay={() => setDateToClose(date)}
                  setEmptyDay={setEmptyDay}
                  setOptionsMenu={setOptionsMenu}
                />
              );
            },
          },
        }}
        formats={{
          timeGutterFormat: (time: Moment) =>
            moment(time).minutes() === 0 ? moment(time).format('HH') : moment(time).format('mm'),
          dayHeaderFormat: 'MMM D',
          dayRangeHeaderFormat: ({ start, end }) => `${moment(start).format('MMM D')} - ${moment(end).format('MMM D')}`,
          monthHeaderFormat: 'MMM YYYY',
          agendaHeaderFormat: ({ start, end }) => `${moment(start).format('MMM D')} - ${moment(end).format('MMM D')}`,
        }}
        tooltipAccessor={null}
      />
      <FloatingButton>
        {isUserAdmin && (
          <MenuButton
            count={unclosedEvents.length + inProgressEvents.filter(event => !event.resource.isGoogle).length}
            onClick={() => setActiveEvents(true)}
          />
        )}
        <PlusButton
          disabled={Boolean(!user.headOrgSpecialist?.active || subscription?.status !== SubscriptionStatusType.ACTIVE)}
          handleClick={() => {
            setIsAppointmentSidebarVisible(true);
            setIsEditAppointmentSidebar(false);
          }}
        />
      </FloatingButton>
      {isActiveEvents && (
        <ActiveEventsSidebar
          unclosedEvents={unclosedEvents}
          inProgressEvents={inProgressEvents}
          handleEventClick={handleClickEvent}
          handleClose={() => setActiveEvents(false)}
        />
      )}

      {isAppointmentSidebarVisible &&
        (isMobile ? (
          <AppointmentMobile {...appointmentSidebarProps} />
        ) : (
          <AppointmentSidebar
            {...appointmentSidebarProps}
            onAddButtonClick={() => setIsNewClientSidebarVisible(true)}
          />
        ))}
      {isNewClientSidebarVisible && <NewClientSidebar onClose={() => setIsNewClientSidebarVisible(false)} />}
      {isAddBreak && (
        <BreakSidebar
          isEditMode={isEditBreakSidebar}
          breakDate={dateFromMenu || ''}
          handleClose={handleBreakSidebarClose}
          selectedWeekday={selectedWeekday}
          organisationSpecialists={organisationSpecialists}
          organisationWorkTime={organisationWorkTime}
          specialist={
            breakSpecialist ??
            selectedSpecialist ??
            (user.headOrgSpecialist?.accessLevel === SpecialistsRoles.SPECIALIST ? specialistIds[0] : undefined)
          }
          currentSpecialist={currentSpecialist as SpecialistResponse}
          selectedBreakData={selectedBreakData}
        />
      )}

      {optionsMenu && isMobile && (
        <OptionsMenu
          onAddBreak={() => setAddBreak(true)}
          onCancelAll={() => setCancelAll(true)}
          onCreateAppointment={() => setIsAppointmentSidebarVisible(true)}
          isOpen={optionsMenu}
          onClose={() => setOptionsMenu(false)}
          onSelectDate={date => setDateFromMenu(date)}
          dateFromMenu={dateFromMenu || undefined}
          onNavigate={handleNavigate}
          onChangeView={handleChangeView}
          setDateToClose={setDateToClose}
        />
      )}

      {dateToClose && (
        <CloseDayPopup
          date={dateToClose as Date}
          onSubmit={handleCloseDay}
          onClose={() => setDateToClose(null)}
          orgId={organisationId}
          isEmptyDay={isEmptyDay}
        />
      )}

      {isCancelAll && (
        <CancelAllPopup
          date={dateFromMenu as string}
          onSubmit={handleCancelAll}
          onClose={handleCancelAll}
          orgSpecId={cancelAllSpecialist || selectedSpecialist}
          orgId={organisationId}
        />
      )}
    </AsyncComponent>
  );
};
