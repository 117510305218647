import { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FormikProps } from 'formik';
import { Flex } from '@beauty/beauty-market-ui';
import { SidebarFooter } from 'components/SidebarFooter/SidebarFooter';
import { useRequest } from '../../../hooks';
import { ThunkService } from '../../../store/redux-slices/serviceSlice';
import { ServiceAction } from '../../../types';
import { SidebarSheet } from '../../SidebarSheet';
import { MainServiceFormType } from '../MainService/MainService.definitions.';
import { MainServiceForm } from '../MainService/MainServiceForm';
import { PhotoServiceForm } from '../PhotoService/PhotoServiceForm';
import { TariffServiceFormType } from '../TariffService/TariffService.definitions.';
import { TariffServiceForm } from '../TariffService/TariffServiceForm';

export const EditService = ({ onClose }) => {
  const { t } = useTranslation();
  const updateService = useRequest(ThunkService.editService, ServiceAction.Update);

  const [isMainDirty, setIsMainDirty] = useState(false);
  const [isPhotoDirty, setIsPhotoDirty] = useState(false);
  const [isTariffDirty, setIsTariffDirty] = useState(false);
  const [isMainValid, setIsMainValid] = useState(true);
  const [isPhotoValid, setIsPhotoValid] = useState(true);
  const [isTariffValid, setIsTariffValid] = useState(true);
  const [isSubmitting, setSubmitting] = useState(false);

  const mainFormRef = useRef<FormikProps<MainServiceFormType>>();
  const tariffFormRef = useRef<FormikProps<TariffServiceFormType>>();

  const handleSubmit = async () => {
    setSubmitting(true);
    try {
      await mainFormRef.current?.submitForm();
      await tariffFormRef.current?.submitForm();
      await updateService();
      onClose();
    } finally {
      setSubmitting(false);
    }
  };

  const isValid = isMainValid && isTariffValid && isPhotoValid;
  const isDirty = isMainDirty || isTariffDirty || isPhotoDirty;
  const isDisabled = !isDirty || !isValid;

  const footer = (
    <SidebarFooter
      applyChanges
      cancel
      disabled={isDisabled}
      onSubmit={handleSubmit}
      onBack={onClose}
      isLoading={isSubmitting}
    />
  );
  return (
    <SidebarSheet
      FooterBody={footer}
      onClose={onClose}
      label={t('settings.services.sidebar.editService')}
      descriptor={t('settings.services.sidebar.chooseCategories')}
    >
      <Flex flexDirection="column" gap="24px" mb="80px">
        <MainServiceForm
          ref={mainFormRef}
          title={t('settings.services.sidebar.mainInformation')}
          setIsValid={setIsMainValid}
          setIsDirty={setIsMainDirty}
        />
        <PhotoServiceForm
          title={t('settings.services.sidebar.photoGallery')}
          setIsDirty={setIsPhotoDirty}
          setIsValid={setIsPhotoValid}
        />
        <TariffServiceForm
          ref={tariffFormRef}
          title={t('settings.services.sidebar.tariff')}
          setIsValid={setIsTariffValid}
          setIsDirty={setIsTariffDirty}
        />
      </Flex>
    </SidebarSheet>
  );
};
