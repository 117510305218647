import styled from 'styled-components';
import { DownIcon, Flex, mediaQueries } from '@beauty/beauty-market-ui';
import { Paper } from '../Paper';

export const getBorderRadius = (position: 'first' | 'middle' | 'last' | 'default') => {
  switch (position) {
    case 'first':
      return '10px 10px 0 0';
    case 'last':
      return '0 0 10px 10px';
    case 'middle':
      return '0';
    default:
      return '10px';
  }
};

export const Wrapper = styled(Paper)<{ position: 'first' | 'middle' | 'last' | 'default' }>`
  width: 100%;
  flex-direction: column;
  align-items: start;
  border-radius: 10px;
  padding: ${({ position }) => (position === 'last' ? '16px 8px 80px' : '16px 8px')};
  gap: 16px;

  ${mediaQueries.md} {
    border-radius: ${({ position }) => getBorderRadius(position)};
    padding: 24px;
  }
`;

export const Heading = styled(Flex)<{ isEmpty: boolean }>`
  width: 100%;
  justify-content: space-between;
  align-items: ${({ isEmpty }) => (isEmpty ? 'start' : 'center')};

  ${mediaQueries.md} {
    align-items: ${({ isEmpty }) => (isEmpty ? 'start' : 'center')};
  }
`;

export const StyledDownIcon = styled(DownIcon)<{ $isUp: boolean }>`
  transition: transform 0.2s linear;
  cursor: pointer;

  ${({ $isUp }) => $isUp && `transform: rotate(180deg);`}
`;
