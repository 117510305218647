import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Nullable } from 'tsdef';
import { Flex, Section, TagButton } from '@beauty/beauty-market-ui';
import { Loader } from 'components';
import { useGetSubscription } from 'hooks';
import { SubscriptionPeriodType, SubscriptionPlanType } from 'types';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import { ThunkOrganisation } from '../../../store/redux-slices/organisationSlice';
import { selectUserHead } from '../../../store/redux-slices/userSlice';
import { TariffActions, TariffPlans, TariffTable } from './components';

export const ChangePlanPage = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const { isLoading, subscriptionPlans } = useGetSubscription();

  const [period, setPeriod] = useState<SubscriptionPeriodType>(SubscriptionPeriodType.Monthly);
  const [plan, setPlan] = useState<Nullable<SubscriptionPlanType>>(null);

  const userHead = useAppSelector(selectUserHead);

  useEffect(() => {
    if (userHead) {
      dispatch(ThunkOrganisation.fetchHead(userHead.headOrgId));
    }
  }, []);

  return isLoading ? (
    <Loader />
  ) : (
    <Flex flexDirection="column" gap="16px" width="100%">
      <Section title={t('subscriptions.changeYourPlan')} button={null} asLabel>
        <Flex width="100%" gap="16px" mt="8px">
          <TagButton
            text={t('subscriptions.payMonthly')}
            design={period === SubscriptionPeriodType.Monthly ? 'active' : 'default'}
            onClick={() => setPeriod(SubscriptionPeriodType.Monthly)}
          />
          <TagButton
            text={t('subscriptions.payAnnually')}
            design={period === SubscriptionPeriodType.Annually ? 'active' : 'default'}
            onClick={() => setPeriod(SubscriptionPeriodType.Annually)}
          />
        </Flex>
      </Section>
      {subscriptionPlans.length > 0 && (
        <>
          <TariffPlans period={period} subscriptionPlans={subscriptionPlans} onChangePlan={setPlan} />
          <TariffTable />
          <TariffActions
            period={period}
            plan={plan}
            subscriptionPlans={subscriptionPlans}
            onClose={() => setPlan(null)}
          />
        </>
      )}
    </Flex>
  );
};
