import { apiRequest } from '../api/api';
import { AuthEndpoints } from '../api/endpoints/endpoints';
import { AccType } from '../constants';

type LoginByEmailParams = {
  email: string;
  password: string;
};

export const loginByEmail = async (data: LoginByEmailParams) => {
  const [error, response] = await apiRequest.post({
    endpoint: AuthEndpoints.LoginByEmail,
    data: { ...data, accType: AccType.BUSINESS },
  });
  return response ? response.data : error?.data;
};
