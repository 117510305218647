import { BodySmall } from '@beauty/beauty-market-ui';
import { StyledH6 } from './style';

interface ContentItemProps {
  label: string;
  title: string;
  pt?: string;
}

const ContentItem = ({ label, title, pt }: ContentItemProps) => (
  <>
    <BodySmall mt="20px" mb="4px" pt={pt}>
      {label}
    </BodySmall>
    <StyledH6 title={title}>{title}</StyledH6>
  </>
);

export default ContentItem;
