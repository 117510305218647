import styled from 'styled-components';
import { Flex, Display, Button, mediaQueries, H6 } from '@beauty/beauty-market-ui';
import { zIndex } from '../../constants';
import { bubbles, bubbleWidth, titleShadow } from '../../style';

export const CrmWrapper = styled(Flex)`
  max-width: 1060px;
  width: 100%;
  align-items: center;
  justify-content: center;
  margin: auto;

  @media (max-width: 1060px) {
    width: 100%;
    padding-right: 24px;
    padding-left: 24px;
    box-sizing: border-box;
  }
`;

export const BubbleWrapper = styled(Flex)`
  flex-direction: column;
  align-items: center;
  position: relative;
  &:before {
    content: '';
    z-index: ${zIndex.zero};
    top: -222px;
    left: calc((100% - ${bubbleWidth}px) / 2);
    ${bubbles};
    background-size: auto;

    ${mediaQueries.md} {
      background-size: 120%;
    }
  }
`;

export const StyledDisplay = styled(Display)`
  position: relative;
  padding-top: 20px;
  padding-bottom: 40px;
  width: 100%;
  max-width: 620px;
  text-align: center !important;
  ${titleShadow};
  letter-spacing: -2.4px;

  ${mediaQueries.md} {
    padding-top: 40px;
    width: 620px;
  }
`;

export const StyledH6 = styled(H6)`
  position: relative;
  width: 100%;
  ${titleShadow};
  text-align: center !important;
  padding-bottom: 40px;
`;

export const StyledButton = styled(Button)`
  width: 100%;
  max-width: 450px;
`;
