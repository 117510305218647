import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Caption, colors, Flex, H6, UploadMainPhoto, UploadPhotos } from '@beauty/beauty-market-ui';
import { checkPhotosValid, hasArrayChanged } from 'helpers';
import { useMediaScreen } from 'hooks';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { MAX_PHOTO_ADDRESS_COUNT } from '../../../constants';
import {
  deleteAddressPhoto,
  selectAddressPhotos,
  updateAddressPhotos,
  updateMainPhoto,
} from '../../../store/redux-slices/addressSlice';
import { selectSubscriptionPlan } from '../../../store/redux-slices/organisationSlice';
import { FormPropsType } from '../../../types';
import { defaultAddressFields } from '../constants';
import { EditAddressFieldsType } from '../types';

type EditableFields = {
  fields?: EditAddressFieldsType['photos'];
};

type PhotosFormProps = FormPropsType & EditableFields;

export const PhotosForm = ({
  editBar,
  setIsDirty,
  setIsValid,
  fields = defaultAddressFields.photos,
}: PhotosFormProps) => {
  const { t } = useTranslation();
  const { isDesktop } = useMediaScreen('md');
  const dispatch = useAppDispatch();
  const subscriptionLimit = useAppSelector(selectSubscriptionPlan);
  const addressPhotos = useAppSelector(selectAddressPhotos) || { mainPhoto: '', photos: [] };
  const { mainPhoto, photos } = addressPhotos;
  const photosRef = useRef({ mainPhoto, photos });
  const [error, setError] = useState<string | null>('');

  const handleUploadMain = async (image: File) => {
    const errorMessage = checkPhotosValid([image], t);
    setError(errorMessage);
    setIsDirty && setIsDirty(true);
    dispatch(updateMainPhoto(image));
  };

  const handleUploadPhotos = (images: (File | string)[]) => {
    const updatedPhotos = [...photos, ...images];
    const errorMessage = checkPhotosValid(updatedPhotos, t, subscriptionLimit?.photoLimit);
    setError(errorMessage);
    setIsDirty && setIsDirty(true);
    dispatch(updateAddressPhotos(updatedPhotos));
  };

  const handleDeletePhoto = (photoToDelete: File | string) => {
    const filteredPhotos = photos.filter(photo => photo !== photoToDelete);
    const errorMessage = checkPhotosValid(filteredPhotos, t, subscriptionLimit?.photoLimit);
    const dirty = hasArrayChanged(filteredPhotos, photosRef.current.photos);
    const isMainChanged = photosRef.current.mainPhoto !== mainPhoto;
    setError(errorMessage);
    setIsDirty && setIsDirty(dirty || isMainChanged);
    dispatch(deleteAddressPhoto(photoToDelete));
  };

  useEffect(() => {
    setIsValid && setIsValid(!error);
  }, [error]);

  const isPreventExtension = photos.length && (error === null || !!error);

  return (
    <Flex flexDirection="column" gap="16px">
      {editBar && <H6>{t('settings.addresses.sidebar.organisationPhotos')}</H6>}
      {fields?.mainPhoto && (
        <UploadMainPhoto
          image={mainPhoto}
          onUpload={handleUploadMain}
          badgeText={t('settings.addresses.sidebar.mainPhoto')}
        />
      )}
      {fields?.photos && (
        <UploadPhotos
          max={subscriptionLimit?.photoLimit || MAX_PHOTO_ADDRESS_COUNT}
          images={photos}
          onUpload={handleUploadPhotos}
          onDelete={handleDeletePhoto}
          preventExtension={isPreventExtension}
          mode={isDesktop ? 'hover' : 'double-tap'}
        />
      )}
      {error && <Caption color={colors.red.standard}>{error}</Caption>}
    </Flex>
  );
};
