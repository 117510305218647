import { Views } from 'react-big-calendar';
import { AppointmentData, AppointmentDataType, AppointmentType, BreakType } from '../types/appointment';

export enum SocketEvents {
  Appointment = 'Appointment',
}

export type SocketData = {
  start: string;
  end: string;
  orgSpecId?: string | null;
  orgId?: string | null;
};

export enum SocketEventStatus {
  DELETE = 'DELETE',
  UPDATE = 'UPDATE',
  CREATE = 'CREATE',
  INIT = 'INIT',
}

export enum EventType {
  GOOGLE = 'GOOGLE',
  BM = 'BM',
  BREAK = 'BREAK',
}

export type FullData<T> = {
  calendar: T;
  inprogress: AppointmentType[];
  unclosed: AppointmentType[];
};

export type EventResponse<T> = {
  data: FullData<T> | AppointmentType | BreakType;
  status: SocketEventStatus;
  type?: EventType;
  message?: string;
  success?: boolean | null;
};

export type HandleEventType = (
  payload: EventResponse<AppointmentDataType[] | AppointmentData | AppointmentType>,
) => void;

export type SocketPropsType = {
  data: SocketData | null;
  event: SocketEvents;
  handleEvent: HandleEventType;
  view: Views;
};

export type SocketConnection =
  | {
      orgId: string | undefined;
      start: string;
      end: string;
      timeZone: string;
      orgSpecId?: undefined;
    }
  | {
      orgSpecId: string | undefined;
      start: string;
      end: string;
      timeZone: string;
      orgId?: undefined;
    };
