import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNotify, NotifyStatus } from '@beauty/beauty-market-ui';
import { WhatsAppBar, WhatsAppSection } from 'components';
import { getNotifyContent } from 'helpers';
import { useRequest } from 'hooks';
import { OrganizationAction, SubscriptionPlanType } from 'types';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import {
  selectActiveSubscription,
  selectHeadOrgWhatsApp,
  ThunkOrganisation,
} from '../../../store/redux-slices/organisationSlice';

export const WhatsApp = () => {
  const { t } = useTranslation();
  const isHeadOrgWhatsAppConnect = useAppSelector(selectHeadOrgWhatsApp);
  const subscription = useAppSelector(selectActiveSubscription);
  const notify = useNotify();
  const dispatch = useAppDispatch();

  const [isSidebarOpen, setSidebarOpen] = useState(false);

  const handleClose = useCallback(() => {
    setSidebarOpen(false);
  }, []);

  const deactivateWhatsApp = useRequest(ThunkOrganisation.deactivateWhatsApp, OrganizationAction.DeactivateWhatsApp);

  const activateWhatsApp = () => {
    dispatch(ThunkOrganisation.payForWhatsApp({}))
      .unwrap()
      .then(res => {
        if (res?.status === 0) {
          throw new Error(t('validation.unknown'));
        } else if (res.whatsApp) {
          notify(getNotifyContent(NotifyStatus.SUCCESS, OrganizationAction.ActivateWhatsApp, t));
        } else if (res.payment_url) {
          window.location.href = res.payment_url;
        } else {
          throw new Error(t('validation.unknown'));
        }
      })
      .catch(() => notify(getNotifyContent(NotifyStatus.ERROR, OrganizationAction.ActivateWhatsApp, t)))
      .then(() => handleClose());
  };

  const handleClick = () => {
    if (subscription?.whatsApp || subscription?.plan.planType === SubscriptionPlanType.FREE) {
      isHeadOrgWhatsAppConnect ? deactivateWhatsApp() : activateWhatsApp();
    } else {
      setSidebarOpen(true);
    }
  };

  return (
    <>
      <WhatsAppSection onClick={handleClick} />
      {isSidebarOpen && <WhatsAppBar onClose={handleClose} />}
    </>
  );
};
