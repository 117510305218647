import { TFunction } from 'react-i18next';
import { isValidPhoneNumber } from 'libphonenumber-js/max';
import * as Yup from 'yup';
import { AddressType } from 'types/organisation';
import { emailReg, generalInstagramReg } from '../../../constants';
import { getShortCountryByCode } from '../../../helpers';

export enum MainInformationFormFields {
  Name = 'name',
  Number = 'number',
  Code = 'code',
  Email = 'email',
  Instagram = 'instagram',
  Telegram = 'telegram',
  WhatsAppCode = 'whatsAppCode',
  WhatsAppNumber = 'whatsAppNumber',
}

export type MainInformationFormType = {
  [MainInformationFormFields.Name]: string;
  [MainInformationFormFields.Number]: string;
  [MainInformationFormFields.Code]: string;
  [MainInformationFormFields.Email]: string;
  [MainInformationFormFields.Instagram]: string | null;
  [MainInformationFormFields.Telegram]: string;
  [MainInformationFormFields.WhatsAppCode]: string;
  [MainInformationFormFields.WhatsAppNumber]: string;
};

export const getInitialValues = (address: AddressType) => {
  const whatsAppPhone = address.social?.whatsapp?.split(' ') ?? [];
  return {
    [MainInformationFormFields.Name]: address.name,
    [MainInformationFormFields.Email]: address.email,
    [MainInformationFormFields.Number]: address.phone.number,
    [MainInformationFormFields.Code]: address.phone.code,
    [MainInformationFormFields.Instagram]: `@${address.social?.instagram ?? ''}`,
    [MainInformationFormFields.Telegram]: address.social?.telegram ?? '',
    [MainInformationFormFields.WhatsAppCode]: whatsAppPhone.length === 2 ? `+${whatsAppPhone[0]}` : '',
    [MainInformationFormFields.WhatsAppNumber]: whatsAppPhone.length === 2 ? whatsAppPhone[1] : '',
  };
};

export const mainInformationValidationSchema = (t: TFunction<'translation', undefined>) =>
  Yup.object({
    [MainInformationFormFields.Name]: Yup.string()
      .trim()
      .required(t('validation.notEmpty'))
      .min(2, t('validation.minLength2'))
      .max(50, t('validation.maxLength50')),
    [MainInformationFormFields.Code]: Yup.string().trim(),
    [MainInformationFormFields.Number]: Yup.string()
      .trim()
      .required(t('validation.phoneRequired'))
      .test('validate-code', t('validation.codeRequired'), function validateCode() {
        return !!this.parent.code;
      })
      .test('validate-phone', t('validation.enterValidPhone'), function validatePhone(phone) {
        const { code } = this.parent;
        if (code) {
          return isValidPhoneNumber(`${code} ${phone}`, getShortCountryByCode(code));
        }
        return false;
      }),
    [MainInformationFormFields.Email]: Yup.string()
      .trim()
      .email(t('validation.emailNotValid'))
      .matches(emailReg, t('validation.emailNotValid'))
      .required(t('validation.notEmpty')),
    [MainInformationFormFields.Instagram]: Yup.string().matches(generalInstagramReg, t('validation.instagramNotValid')),
    [MainInformationFormFields.Telegram]: Yup.string(),
    [MainInformationFormFields.WhatsAppNumber]: Yup.string()
      .trim()
      .test('validate-whatsapp-code', t('validation.codeRequired'), function validateWhatsappCode() {
        const whatsappNumber = this.parent[MainInformationFormFields.WhatsAppNumber];
        const whatsappCode = this.parent[MainInformationFormFields.WhatsAppCode];
        return whatsappNumber ? !!whatsappCode : true;
      })
      .test('validate-whatsapp-phone', t('validation.enterValidPhone'), function validateWhatsappPhone(whatsappPhone) {
        const whatsappCode = this.parent[MainInformationFormFields.WhatsAppCode];
        if (whatsappCode && whatsappPhone) {
          return isValidPhoneNumber(`${whatsappCode} ${whatsappPhone}`, getShortCountryByCode(whatsappCode));
        }
        return true;
      }),
  });
