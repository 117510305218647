import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Nullable } from 'tsdef';
import { colors } from '@beauty/beauty-market-ui';
import { useMediaScreen } from '../../../../hooks';
import { Wrapper } from '../../style';
import { AppointmentsSection } from './AppointmentsSection';

export type AppointmentsType = 'past' | 'future';

export const Appointments = () => {
  const { t } = useTranslation();
  const { isDesktop } = useMediaScreen('md');
  const [open, setOpen] = useState<Nullable<AppointmentsType>>('past');

  return (
    <Wrapper backgroundColor={colors.grey.light} p="0" delta={isDesktop ? '300px' : '72px'}>
      <AppointmentsSection
        type="future"
        open={open === 'future'}
        onChange={setOpen}
        title={t('clients.profile.appointments.future')}
        placeholder={t('clients.profile.appointments.noFuture')}
      />
      <AppointmentsSection
        type="past"
        open={open === 'past'}
        onChange={setOpen}
        title={t('clients.profile.appointments.past')}
        placeholder={t('clients.profile.appointments.noPast')}
      />
    </Wrapper>
  );
};
