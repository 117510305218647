import styled, { css } from 'styled-components';
import { Flex, H5, Caption, mediaQueries, getMaxWidthMediaQuery, colors } from '@beauty/beauty-market-ui';
import { ltrStyle } from 'style';

export const CardWrapper = styled(Flex)<{ forAgenda: boolean }>`
  position: relative;
  width: 100%;
  padding: 10px 8px;
  box-sizing: border-box;
  justify-content: space-between;
  align-items: center;
  border-radius: 8px;

  button svg {
    display: flex;
    ${mediaQueries.md} {
      display: none;
    }
  }

  :hover {
    svg {
      display: flex;
      cursor: pointer;
    }
  }

  ${({ forAgenda }) =>
    forAgenda &&
    css`
      margin-top: 1px;
      margin-bottom: 1px;
      background-color: ${colors.white.standard};
      height: 72px;
    `}
`;

export const InfoWrapper = styled(Flex)`
  width: 95%;
  flex-direction: column;
  justify-content: space-between;

  ${getMaxWidthMediaQuery('md')} {
    [dir='ltr'] & {
      text-align: left;
    }
    [dir='rtl'] & {
      text-align: right;
    }
  }
`;

export const StyledCaption = styled(Caption)<{ isHebrew: boolean }>`
  max-width: calc(100vw - 16px);
  ${({ isHebrew }) => ltrStyle(isHebrew)};
`;

export const StyledH5 = styled(H5)<{ isHebrew: boolean }>`
  max-width: calc(100vw - 16px);
  ${({ isHebrew }) => ltrStyle(isHebrew)};
`;
