import { TFunction } from 'react-i18next';
import { NotifyPropsType, NotifyStatus } from '@beauty/beauty-market-ui';
import { AppointmentsAction } from '../../types';

const successTitles = {
  [AppointmentsAction.Create]: 'calendar.alerts.title.createAppointmentSuccess',
  [AppointmentsAction.Update]: 'calendar.alerts.title.updateAppointmentSuccess',
  [AppointmentsAction.Cancel]: 'calendar.alerts.title.cancelAppointmentSuccess',
  [AppointmentsAction.Convert]: 'calendar.alerts.title.convertGoogleSuccess',
  [AppointmentsAction.Close]: 'calendar.alerts.title.closeAppointmentSuccess',
  [AppointmentsAction.CancelAll]: 'calendar.alerts.title.cancelAllAppointmentSuccess',
};

const errorTitles = {
  [AppointmentsAction.Create]: 'alerts.error',
  [AppointmentsAction.Update]: 'alerts.error',
  [AppointmentsAction.Cancel]: 'alerts.error',
  [AppointmentsAction.Convert]: 'alerts.error',
  [AppointmentsAction.Close]: 'alerts.error',
  [AppointmentsAction.CancelAll]: 'alerts.error',
};

const successSubtitles = {
  [AppointmentsAction.Create]: 'calendar.alerts.subtitle.createAppointmentSuccess',
  [AppointmentsAction.Update]: 'calendar.alerts.subtitle.updateAppointmentSuccess',
  [AppointmentsAction.Cancel]: 'calendar.alerts.subtitle.cancelAppointmentSuccess',
  [AppointmentsAction.Convert]: 'calendar.alerts.subtitle.convertGoogleSuccess',
  [AppointmentsAction.Close]: 'calendar.alerts.subtitle.closeAppointmentSuccess',
  [AppointmentsAction.CancelAll]: 'calendar.alerts.subtitle.cancelAllAppointmentSuccess',
};

const errorSubtitles = {
  [AppointmentsAction.Create]: 'calendar.alerts.subtitle.createAppointmentError',
  [AppointmentsAction.Update]: 'calendar.alerts.subtitle.updateAppointmentError',
  [AppointmentsAction.Cancel]: 'calendar.alerts.subtitle.cancelAppointmentError',
  [AppointmentsAction.Convert]: 'calendar.alerts.subtitle.convertGoogleError',
  [AppointmentsAction.Close]: 'calendar.alerts.subtitle.closeAppointmentError',
  [AppointmentsAction.CancelAll]: 'calendar.alerts.subtitle.cancelAllAppointmentError',
};

export const getAppointmentsNotifyContent = (
  status: NotifyStatus,
  action: AppointmentsAction,
  t: TFunction<'translation', undefined>,
  message?: string,
): NotifyPropsType => {
  const title = status === NotifyStatus.SUCCESS ? successTitles[action] : errorTitles[action];
  const subtitle = status === NotifyStatus.SUCCESS ? successSubtitles[action] : errorSubtitles[action];

  return {
    id: action,
    status,
    title: t(title),
    subtitle: message || t(subtitle),
  };
};
