import { Avatar, BodyLarge, Caption, colors, Flex, Link, Separator, TrashIcon } from '@beauty/beauty-market-ui';
import { Box, IconBox } from '../../style';

export const WorkPlace = ({ url, name, address, onDelete, separator, isDesktop }) => (
  <>
    <Box available justifyContent="space-between">
      <Flex gap="8px" justifyContent="start">
        <Avatar url={url} rating="" size="xs" />
        <Flex flexDirection="column" gap="2px">
          <BodyLarge large title={name}>
            {name}
          </BodyLarge>
          <Caption color={colors.grey.dark} title={address}>
            {address}
          </Caption>
        </Flex>
      </Flex>
      <IconBox>
        <Link design="gray" size="md" icon={<TrashIcon />} onClick={onDelete} />
      </IconBox>
    </Box>
    {separator && <Separator m={isDesktop ? '16px 0' : '8px 0'} />}
  </>
);
