import styled from 'styled-components';
import { Flex, BodySmall, H4 } from '@beauty/beauty-market-ui';

export const PageContainer = styled(Flex)`
  flex-direction: column;
  width: 100%;
  max-width: 1280px;
  gap: 16px;
`;

export const LinkWrapper = styled(Flex)`
  align-items: flex-start;
  cursor: pointer;
`;

export const IconWrapper = styled(Flex)`
  box-sizing: border-box;
  align-items: center;
  justify-content: center;
  padding: 18px;
  width: 56px;
  height: 56px;
`;

export const OptionItem = styled(BodySmall)`
  text-align: left;
  cursor: pointer;
  display: block;
  padding: 0;
  border: none;
  background: transparent;
`;

export const Paper = styled(Flex)<{ border?: boolean }>`
  width: 100%;
  box-sizing: border-box;
  background-color: ${({ theme }) => theme.colors.white.standard};

  border: ${({ border, theme }) => (border ? `1px solid ${theme.colors.grey.standard}` : 'none')};
`;

export const BlueHeading = styled(H4)`
  span {
    color: ${({ theme }) => theme.colors.blue.standard};
    [dir='ltr'] & {
      margin-left: 8px;
    }
    [dir='rtl'] & {
      margin-right: 8px;
    }
  }
`;

export const MenuWrapper = styled(Flex)`
  ul > p {
    [dir='rtl'] & {
      text-align: right;
    }
  }
`;
