import { ChangeEvent } from 'react';
import { useTranslation } from 'react-i18next';
import { isEmpty } from 'lodash';
import { BodySmall, colors, Flex } from '@beauty/beauty-market-ui';
import { DropTime } from '../../index';
import { ApplyForAllDays } from './ApplyForAllDays';
import {
  changeEndBreak,
  changeEndTime,
  changeStartBreak,
  changeStartTime,
  createBreak,
  deleteBreak,
  deleteOrganisation,
  setWeekOfOrganisation,
} from './scheduleReducer/actions';

export const TimeSection = ({
  id,
  orgId,
  orgName,
  startIndex,
  endIndex,
  breaks,
  dispatch,
  children,
  errors,
  applyTitle,
  isApplyForAllDays,
}) => {
  const { t } = useTranslation();

  const handleChangeStart = (index: number) => dispatch(changeStartTime(orgId, index));

  const handleChangeEnd = (index: number) => dispatch(changeEndTime(orgId, index));

  const handleDeleteOrganisation = () => dispatch(deleteOrganisation(orgId));

  const handleCreateBreak = () => {
    const newBreak = { id: crypto.randomUUID(), startIndex, endIndex };
    dispatch(createBreak(orgId, newBreak));
  };

  const handleSetWeekOfOrganisation = (e: ChangeEvent<HTMLInputElement>) => {
    dispatch(setWeekOfOrganisation(orgId, e.currentTarget.checked));
  };

  const breaksErrors = breaks.filter(breakOne => !!errors[breakOne.id]);
  const applyDisabled = startIndex === -1 || endIndex === -1 || !!errors[id] || !isEmpty(breaksErrors);
  const applyColorText = applyDisabled ? colors.grey.standard : colors.black.standard;

  return (
    <>
      <DropTime
        title={orgName}
        start={startIndex}
        end={endIndex}
        onChangeStart={handleChangeStart}
        onChangeEnd={handleChangeEnd}
        onDelete={handleDeleteOrganisation}
        error={t(errors[id])}
      >
        {children}
      </DropTime>
      {breaks.map((breakOne, breakIndex) => {
        const handleDeleteBreak = () => dispatch(deleteBreak(orgId, breakOne.id));

        const handleChangeStartBreak = (index: number) => dispatch(changeStartBreak(orgId, breakOne.id, index));

        const handleChangeEndBreak = (index: number) => dispatch(changeEndBreak(orgId, breakOne.id, index));

        return (
          <DropTime
            key={breakOne.id}
            title={`${t('time.break')} ${breakIndex + 1}`}
            start={breakOne.startIndex}
            end={breakOne.endIndex}
            onChangeStart={handleChangeStartBreak}
            onChangeEnd={handleChangeEndBreak}
            onDelete={handleDeleteBreak}
            error={t(errors[breakOne.id])}
          />
        );
      })}
      <Flex gap="5px" flexDirection="column">
        <BodySmall color={colors.blue.standard} onClick={handleCreateBreak} m="0 auto" cursor="pointer">
          {t('time.addBreak')}
        </BodySmall>
      </Flex>
      <ApplyForAllDays
        title={applyTitle}
        subtitle={t('common.applyForAllSubtitle')}
        checked={isApplyForAllDays}
        onChange={handleSetWeekOfOrganisation}
        disabled={applyDisabled}
        color={applyColorText}
      />
    </>
  );
};
