import { ChangeEvent, MouseEvent, useCallback, useEffect, useMemo, useState, useTransition } from 'react';
import { useTranslation } from 'react-i18next';
import { generatePath } from 'react-router';
import { useNavigate, useSearchParams } from 'react-router-dom';
import cloneDeep from 'lodash/cloneDeep';
import lowerCase from 'lodash/lowerCase';
import {
  Button,
  colors,
  ExtendedSearchInput,
  Flex,
  H3,
  H4,
  NotifyStatus,
  PlusIcon,
  Scroll,
  Separator,
  useNotify,
} from '@beauty/beauty-market-ui';
import { EmployeeCard } from 'components';
import { getNotifyContent } from 'helpers';
import { Adaptive } from 'hoc';
import { useMediaScreen } from 'hooks';
import { RouterUrl } from 'routes';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { selectActiveSubscription, selectEmployeeNum } from 'store/redux-slices/organisationSlice';
import { selectAllSpecialists, setSpecialistActive, ThunkSpecialist } from 'store/redux-slices/specialistSlice';
import { selectUserHead } from 'store/redux-slices/userSlice';
import { OrganizationAction, SubscriptionStatusType } from 'types';
import { Wrapper } from '../../style';
import { sortSpecialist } from '../helpers';
import { InputWrapper } from '../style';
import { ActivateBar } from './ActivateBar';
import { PaymentBar } from './PaymentBar';

export const EmployeeListSection = ({ onAddClick }) => {
  const { t } = useTranslation();
  const { isDesktop } = useMediaScreen('md');

  const userHead = useAppSelector(selectUserHead);
  const employeeNum = useAppSelector(selectEmployeeNum);
  const specialists = useAppSelector(selectAllSpecialists);
  const subscription = useAppSelector(selectActiveSubscription);

  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const notify = useNotify();

  const [, startTransition] = useTransition();
  const [toSearch, setSearch] = useState('');
  const [toActivateId, setActivateId] = useState('');
  const [isActivateBar, setActivateBar] = useState(false);
  const [isPaymentBar, setPaymentBar] = useState(false);
  const [params] = useSearchParams();

  const isPast = subscription?.status === SubscriptionStatusType.PAST;

  const handleClose = useCallback(() => {
    setPaymentBar(false);
    setActivateBar(false);
    setActivateId('');
  }, []);

  const handleChangeSearch = (e: ChangeEvent<HTMLInputElement>) => {
    startTransition(() => {
      setSearch(e.currentTarget.value);
    });
  };

  const sortedSpecialist = useMemo(() => cloneDeep(specialists).sort(sortSpecialist), [specialists]);
  const filteredSpecialists = useMemo(() => {
    const toSearchWithoutSpaces = lowerCase(toSearch).trim().split(' ').join('');
    return sortedSpecialist.filter(({ account }) => {
      const { name, surname, email, code, number } = account;
      const combinedProperties = lowerCase(`${name}${surname}${email}${code}${number}`);
      return combinedProperties.includes(toSearchWithoutSpaces);
    });
  }, [sortedSpecialist, toSearch]);

  useEffect(() => {
    if (userHead) {
      const orgId = userHead.headOrgId;
      dispatch(ThunkSpecialist.fetchAllSpecialists(orgId)).then(() => {
        const success = params.get('success');
        const specId = params.get('specId');
        if (success) {
          if (success === 'true') {
            notify(getNotifyContent(NotifyStatus.SUCCESS, OrganizationAction.PayForSpecialist, t));
            specId && dispatch(setSpecialistActive(specId));
          } else {
            notify(getNotifyContent(NotifyStatus.ERROR, OrganizationAction.PayForSpecialist, t));
          }
        }
      });
    }
  }, [userHead]);

  return (
    <Wrapper>
      <Flex width="100%" justifyContent="space-between" pb="16px" alignItems="center" gap="23px">
        <Adaptive desktop={H3} mobile={H4}>
          {t('specialists.employeeList')}
        </Adaptive>
        {/* <Button size="small" design="tertiaryBlack" prefix={<FilterIcon />} /> */}
        <Button prefix={<PlusIcon width="20px" height="20px" />} onClick={onAddClick} design="primary" size="small">
          {isDesktop ? t('specialists.addNew') : null}
        </Button>
      </Flex>
      <Separator mb="24px" />
      <Flex flexDirection="column">
        <InputWrapper>
          <ExtendedSearchInput
            onlyService
            placeholder={t('clients.placeholder')}
            maxWidth="100%"
            onChange={handleChangeSearch}
            onCrossClick={() => setSearch('')}
            zIndex={1}
          />
        </InputWrapper>
      </Flex>
      <Scroll scrollColor={colors.grey.light} mt="100px">
        {filteredSpecialists.map((spec, index, arr) => {
          const handleSpecialistClick = () => navigate(generatePath(RouterUrl.EmployeeGeneralData, { id: spec.id }));

          const handleActivateSpecialistClick = (e: MouseEvent<HTMLDivElement>) => {
            e.stopPropagation();
            if (isPast) return;
            setActivateId(spec.id);
            if (subscription) {
              subscription.employeeCount <= employeeNum ? setPaymentBar(true) : setActivateBar(true);
            }
          };

          return (
            <EmployeeCard
              key={spec.id}
              disabled={isPast}
              {...spec}
              onClick={handleSpecialistClick}
              onActivate={handleActivateSpecialistClick}
              separator={index !== arr.length - 1}
            />
          );
        })}
      </Scroll>
      {isPaymentBar && <PaymentBar specId={toActivateId} onClose={handleClose} />}
      {isActivateBar && <ActivateBar specId={toActivateId} onClose={handleClose} />}
    </Wrapper>
  );
};
