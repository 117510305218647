import { forwardRef, memo, useEffect, useMemo, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { Form, Formik, FormikProps } from 'formik';
import { Select, H6, colors, Icon, Flex } from '@beauty/beauty-market-ui';
import { getCurrencyIcon } from 'helpers';
import { useTimeList } from 'hooks';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import { selectHead } from '../../../store/redux-slices/organisationSlice';
import { selectService, updateDuration, updatePrice } from '../../../store/redux-slices/serviceSlice';
import { FormPropsType, OptionType } from '../../../types';
import { FormikInput } from '../../functional';
import {
  TariffServiceFormFields,
  TariffServiceFormType,
  tariffServiceValidationSchema,
} from './TariffService.definitions.';

export const TariffServiceForm = memo(
  forwardRef(({ setIsValid, title, setIsDirty }: FormPropsType, ref) => {
    const minDuration = '0';
    const maxDuration = '600';
    const { t } = useTranslation();
    const timeList = useTimeList();
    const dispatch = useAppDispatch();
    const { currency } = useAppSelector(selectHead);
    const { newService } = useAppSelector(selectService);
    const { price, duration } = newService;
    const tariffRef = useRef({ price, duration });

    const handleChangeDuration = (option: OptionType) => {
      dispatch(updateDuration(option.id));
    };

    const durationOptions = useMemo(
      () =>
        timeList
          .filter(item => Number(item.id) <= Number(maxDuration) && Number(item.id) > Number(minDuration))
          .map(item => ({ ...item, value: `${item.id} ${t('time.min')}` })),
      [timeList, t],
    );

    const durationItem = durationOptions.find(item => item.id === duration);

    const onFormSubmit = (data: TariffServiceFormType) => {
      dispatch(updatePrice(data.price?.trim()));
    };

    const { Price } = TariffServiceFormFields;
    const formikContextValue = {
      initialValues: {
        [Price]: price && price !== 'null' ? price : '',
      },
      onSubmit: onFormSubmit,
      validateOnMount: false,
      validationSchema: tariffServiceValidationSchema(t),
    };

    return (
      <Formik innerRef={ref as (instance: FormikProps<TariffServiceFormType> | null) => void} {...formikContextValue}>
        {({ isValid, values, handleChange, handleBlur, errors, touched }) => {
          useEffect(() => {
            const valid = isValid && duration;
            setIsValid && setIsValid(!!valid);
          }, [isValid, duration]);

          useEffect(() => {
            const dirty =
              formikContextValue.initialValues[Price] !== values[Price] || tariffRef.current.duration !== duration;
            setIsDirty && setIsDirty(dirty);
          }, [values[Price], duration]);

          const isPriceNumeric = !isNaN(Number(values[Price]));
          const currencySymbol = isPriceNumeric ? getCurrencyIcon(currency) : '';
          const styledSymbol = <Icon color={colors.grey.standard}>{currencySymbol}</Icon>;

          return (
            <Form>
              <Flex flexDirection="column" gap="16px">
                {title && <H6>{title}</H6>}
                <FormikInput
                  id={Price}
                  name={Price}
                  width="100%"
                  design="white"
                  placeholder={t('settings.services.sidebar.price')}
                  value={values[Price]}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  caption={
                    touched[Price] && errors[Price] ? errors[Price] : t('settings.services.sidebar.priceCaption')
                  }
                  iconRight={styledSymbol}
                />
                <Select
                  isSearch
                  placeholder={t('settings.services.sidebar.averageTime')}
                  selected={durationItem}
                  options={durationOptions}
                  onSelect={handleChangeDuration}
                  required
                />
              </Flex>
            </Form>
          );
        }}
      </Formik>
    );
  }),
);
