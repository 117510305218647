import { TFunction } from 'react-i18next';
import { NotifyPropsType, NotifyStatus } from '@beauty/beauty-market-ui';
import { ServiceAction } from '../../types';

const successTitles = {
  [ServiceAction.Create]: 'service.alerts.title.createServiceSuccess',
  [ServiceAction.Update]: 'service.alerts.title.updateServiceSuccess',
  [ServiceAction.Delete]: 'service.alerts.title.deleteServiceSuccess',
};

const errorTitles = {
  [ServiceAction.Create]: 'alerts.error',
  [ServiceAction.Update]: 'alerts.error',
  [ServiceAction.Delete]: 'alerts.error',
};

const successSubtitles = {
  [ServiceAction.Create]: 'service.alerts.subtitle.createServiceSuccess',
  [ServiceAction.Update]: 'service.alerts.subtitle.updateServiceSuccess',
  [ServiceAction.Delete]: 'service.alerts.subtitle.deleteServiceSuccess',
};

const errorSubtitles = {
  [ServiceAction.Create]: 'service.alerts.subtitle.createServiceError',
  [ServiceAction.Update]: 'service.alerts.subtitle.updateServiceError',
  [ServiceAction.Delete]: 'service.alerts.subtitle.deleteServiceError',
};

export const getServiceNotifyContent = (
  status: NotifyStatus,
  action: ServiceAction,
  t: TFunction<'translation', undefined>,
  message?: string,
): NotifyPropsType => {
  const title = status === NotifyStatus.SUCCESS ? successTitles[action] : errorTitles[action];
  const subtitle = status === NotifyStatus.SUCCESS ? successSubtitles[action] : errorSubtitles[action];

  return {
    id: action,
    status,
    title: t(title),
    subtitle: message || t(subtitle),
  };
};
