import styled from 'styled-components';
import { Flex, colors, zIndex, mediaQueries } from '@beauty/beauty-market-ui';

export const Wrapper = styled(Flex)`
  position: relative;
  box-sizing: border-box;
  justify-content: space-between;
  align-items: flex-start;
  gap: 43px;
  height: 100%;
  background-color: ${colors.grey.light};
  z-index: ${zIndex.accountSettingBodyWrapper};

  padding: 0;
  margin: 0;

  ${mediaQueries.md} {
    padding: 16px 40px;
    margin: ${({ theme }) => (theme.rtl ? '0 64px 0 0' : '0 0 0 64px')};
  }
`;
