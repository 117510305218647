import { useNavigate } from 'react-router-dom';
import { RouterUrl } from '../routes';
import { logout } from '../store/actions/common';
import { useAppDispatch, useAppSelector } from '../store/hooks';
import { selectAuth, setShowLogout } from '../store/redux-slices/authSlice';

export const useLogout = () => {
  const { isShowLogout: isOpen } = useAppSelector(selectAuth);

  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const onOpen = () => {
    dispatch(setShowLogout(true));
  };

  const onConfirm = () => {
    // setAccessToken();
    navigate(RouterUrl.Login);
    dispatch(logout());
  };

  const onCancel = () => {
    dispatch(setShowLogout(false));
  };

  const onLoginAnotherUser = () => {
    navigate(RouterUrl.Login);
    dispatch(logout());
  };

  return {
    isOpen,
    onConfirm,
    onOpen,
    onCancel,
    onLoginAnotherUser,
  };
};
