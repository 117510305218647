import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { isArray, uniq } from 'lodash';
import { ArrowLeftIcon, Caption, Flex, getMinWidthMediaQuery, Title, useMediaQuery } from '@beauty/beauty-market-ui';
import { getSelectedLanguage } from '../../../constants';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import { selectNewAddress, ThunkAddress } from '../../../store/redux-slices/addressSlice';
import { SidebarFooter } from '../../SidebarFooter';
import { SidebarSheet } from '../../SidebarSheet';
import { getTopCategoryData } from '../helpers';
import { SelectServicesProps } from '../types';
import { TopCategoryCard } from './TopCategoryCard';

export const SelectServices = ({
  orgId,
  orgName,
  services,
  headOrgServices,
  onSubmit,
  onClose,
  onBack,
}: SelectServicesProps) => {
  const { t } = useTranslation();
  const { orgSpecialistService } = useAppSelector(selectNewAddress);
  const language = getSelectedLanguage();
  const dispatch = useAppDispatch();

  const [selectedServices, setSelectedServices] = useState(services);

  const mediaQuery = getMinWidthMediaQuery('md');
  const isDesktop = useMediaQuery(mediaQuery);

  const topCategories = useMemo(
    () => getTopCategoryData(orgSpecialistService, headOrgServices, language),
    [headOrgServices, orgSpecialistService, language],
  );

  const handleSelectAllClick = useCallback(() => {
    if (isArray(orgSpecialistService)) {
      const orgServiceIds = orgSpecialistService.map(item => item.id);
      if (selectedServices.length !== orgServiceIds.length) {
        setSelectedServices(orgServiceIds);
      } else {
        setSelectedServices([]);
      }
    }
  }, [orgSpecialistService, selectedServices]);

  const handleSubmit = () => onSubmit(orgId, selectedServices);
  const handleChange = (serviceIds: string[]) => {
    const hasAllSelected = serviceIds.every(id => selectedServices.includes(id));

    if (hasAllSelected) {
      const filteredServices = selectedServices.filter(id => !serviceIds.includes(id));
      setSelectedServices(filteredServices);
    } else {
      setSelectedServices(uniq(selectedServices.concat(serviceIds)));
    }
  };

  const descriptor = isDesktop ? (
    <Flex gap="4px" cursor="pointer" alignItems="center" onClick={onBack}>
      <ArrowLeftIcon width="16px" height="16px" />
      <Caption large>{t('button.back')}</Caption>
    </Flex>
  ) : null;

  const FooterBody = (
    <SidebarFooter
      disabled={false}
      onBack={onClose}
      onSubmit={handleSubmit}
      handleExtraAction={handleSelectAllClick}
      extraActionLabel={t('common.selectAll')}
      save
      cancel
    />
  );

  useEffect(() => {
    dispatch(ThunkAddress.fetchServicesForEmployee(orgId));
  }, []);

  return (
    <SidebarSheet onClose={onClose} onBack={onBack} label={orgName} descriptor={descriptor} FooterBody={FooterBody}>
      <Flex gap="24px" flexDirection="column" mb="80px" p="4px">
        <Flex gap="16px" flexDirection="column">
          <Title text={t('specialists.selectServices')} />
        </Flex>
        {Object.values(topCategories).map(topCategory => (
          <TopCategoryCard
            id={topCategory.id}
            key={topCategory.id}
            icon={topCategory.icon}
            title={topCategory.title}
            selectedServices={selectedServices}
            subCategories={topCategory.subCategories}
            serviceIds={topCategory.serviceIds}
            onChange={handleChange}
          />
        ))}
      </Flex>
    </SidebarSheet>
  );
};
