import { useTranslation } from 'react-i18next';
import { H4, BodyLarge } from '@beauty/beauty-market-ui';
import ErrorIcon from '../../assets/fixing-bugs.png';
import SuccessIcon from '../../assets/trendy-people.png';
import { ColumnFlex } from './style';

export const ResultInfo = props => {
  const { responseStatus } = props;
  const isOk = responseStatus === 'success';
  const { t } = useTranslation();

  return (
    <ColumnFlex mt="80px" alignItems="center">
      <img src={isOk ? SuccessIcon : ErrorIcon} width="240px" height="240px" alt="result" />
      <H4 mb="16px" mt="40px">
        {t(isOk ? 'review.youAreAmazing' : 'review.oops')}
      </H4>
      <BodyLarge px="37px" textAlign="center">
        {t(isOk ? 'review.successSubmit' : 'review.errorSubmit')}
      </BodyLarge>
    </ColumnFlex>
  );
};
