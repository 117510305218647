import { ReactNode, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  BodySmall,
  Button,
  Caption,
  colors,
  EditIcon,
  Flex,
  getMinWidthMediaQuery,
  H5,
  H6,
  useMediaQuery,
} from '@beauty/beauty-market-ui';
import { Adaptive } from 'hoc';
import { Heading, StyledDownIcon, Wrapper } from './style';

type Props = {
  title: string;
  description: string;
  buttonLabel: string;
  placeholder: string;
  onClick: () => void;
  position?: 'first' | 'middle' | 'last';
  count?: number;
  visibleCount?: number;
  badge?: ReactNode;
  separator?: ReactNode | ReactNode[];
  children?: ReactNode | ReactNode[];
};

export const EmployeeSection = ({
  children,
  title,
  description,
  onClick,
  buttonLabel,
  placeholder,
  separator = null,
  badge = null,
  count = 0,
  visibleCount = 7,
  position = 'middle',
}: Props) => {
  const { t } = useTranslation();

  const [expanded, setExpanded] = useState(count <= visibleCount);
  const isEmptyBody = count < 1;
  const isArrow = count > visibleCount;

  const mediaQuery = getMinWidthMediaQuery('md');
  const isDesktop = useMediaQuery(mediaQuery);

  const descriptionItem = isDesktop ? <BodySmall>{description}</BodySmall> : <Caption>{description}</Caption>;

  const buttonText = isEmptyBody ? buttonLabel : t('button.edit');
  const button = (
    <Button
      size="extraSmall"
      design={isEmptyBody ? 'primary' : 'secondary'}
      prefix={isEmptyBody ? null : <EditIcon height="16px" width="16px" />}
      onClick={onClick}
    >
      {isDesktop ? buttonText : null}
    </Button>
  );

  useEffect(() => setExpanded(count <= visibleCount), [count, visibleCount]);

  return (
    <Wrapper position={position}>
      <Heading>
        <Flex flexDirection="column" gap={isDesktop ? '7px' : '14px'} width="100%">
          <Flex alignItems="center" gap="8px" onClick={isArrow ? () => setExpanded(prev => !prev) : null} width="100%">
            {isArrow && <StyledDownIcon $isUp={expanded} />}
            <Adaptive desktop={H5} mobile={H6}>
              {title}
            </Adaptive>
            {badge}
          </Flex>
          {isEmptyBody ? descriptionItem : null}
        </Flex>
        <Flex minWidth="max-content" flexShrink={0}>
          {(isDesktop || !isEmptyBody) && button}
        </Flex>
      </Heading>
      {expanded && (
        <Flex flexDirection="column">
          {separator}
          {isEmptyBody && (
            <Caption color={colors.grey.dark} mb="16px">
              {placeholder}
            </Caption>
          )}
          {isEmptyBody && !isDesktop && (
            <Button size="small" onClick={onClick} mb="16px">
              {buttonLabel}
            </Button>
          )}
          {children}
        </Flex>
      )}
    </Wrapper>
  );
};
