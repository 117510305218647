import { useCallback, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import isArray from 'lodash/isArray';
import isEmpty from 'lodash/isEmpty';
import { Badge, Button, PlusIcon, Separator } from '@beauty/beauty-market-ui';
import { EditEmployee, InfoBlock } from 'components';
import { formatPhoneNumber, getFullName } from 'helpers';
import { useMediaScreen } from 'hooks';
import { RouterUrl } from 'routes/routes';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { selectAllAddresses } from 'store/redux-slices/addressSlice';
import { selectHeadState } from 'store/redux-slices/organisationSlice';
import {
  selectActiveSpecialists,
  selectType,
  ThunkSpecialist,
  updateSpecialist,
  updateType,
} from 'store/redux-slices/specialistSlice';
import { SpecialistAction } from 'types';
import { SpecialistItem } from './SpecialistItem';

export const SpecialistsSection = () => {
  const { t } = useTranslation();
  const { isDesktop, isMobile } = useMediaScreen('md');
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { initialized } = useAppSelector(selectHeadState);
  const specialists = useAppSelector(selectActiveSpecialists) || [];
  const allAddress = useAppSelector(selectAllAddresses) || [];
  const type = useAppSelector(selectType);

  const handleSubmit = useCallback(() => navigate(RouterUrl.EmployeeList), []);

  const specialistItems = useMemo(() => {
    if (!isArray(specialists)) return [];
    return specialists.flatMap((item, index, arr) => {
      if (item.role && item.role === 'SPECIALIST') {
        const phone = formatPhoneNumber(item.account.code, item.account.number);

        const handleClick = async () => {
          await dispatch(ThunkSpecialist.fetchSpecialist(item.id));
          dispatch(updateType(SpecialistAction.Edit));
        };

        const fullName = getFullName(item.account);
        const contact = [phone, item.account.email].filter(Boolean).join(' | ');

        return (
          <SpecialistItem
            key={item.id}
            avatarUrl={item.account.avatarUrl}
            specialization={item.specialization}
            fullName={fullName}
            contact={contact}
            onClick={handleClick}
            isDesktop={isDesktop}
            separator={index !== arr.length - 1}
          />
        );
      }
      return [];
    });
  }, [specialists, isDesktop]);

  const handleAddClick = () => {
    const owner = specialists.find(item => item.accessLevel === 'OWNER');
    if (owner) {
      if (owner.role) {
        dispatch(updateType(SpecialistAction.Add));
      } else {
        dispatch(updateType(SpecialistAction.Edit));
        dispatch(updateSpecialist(owner));
      }
    }
  };

  const empty = isEmpty(specialistItems);
  const button = (
    <Button
      size={empty && isMobile ? 'small' : 'extraSmall'}
      onClick={handleAddClick}
      prefix={<PlusIcon width="16px" height="16px" />}
      my={empty && isMobile ? '16px' : '0'}
      disabled={initialized ? false : allAddress.length === 0}
    >
      {(empty || isDesktop) && t('button.add')}
    </Button>
  );

  useEffect(() => {
    if (type !== SpecialistAction.None) {
      dispatch(updateType(SpecialistAction.None));
    }
  }, []);

  return (
    <>
      <InfoBlock
        position="default"
        title={t('settings.specialists.title')}
        description={t('settings.specialists.description')}
        placeholder={t('settings.specialists.empty')}
        count={specialistItems.length}
        badge={!empty && <Badge text={specialistItems.length} design="lightGrey" />}
        separator={isDesktop ? <Separator mb={empty ? '16px' : '0'} /> : null}
        visibleCount={1}
        button={button}
        emptyButton={button}
      >
        {specialistItems}
      </InfoBlock>
      {type !== SpecialistAction.None && (
        <EditEmployee onSubmit={type === SpecialistAction.Add ? handleSubmit : undefined} />
      )}
    </>
  );
};
