import { CreateClientParamsType } from 'types';
import { apiRequest } from '../api/api';
import { CalendarEndpoints } from '../api/endpoints/endpoints';
import { getQueryParams } from './utils';

type ErrorType =
  | {
      statusCode: number;
      message?: string;
      error?: string;
    }
  | undefined;

const createClient = async (id: string, params: CreateClientParamsType) => {
  const [error, response] = await apiRequest.post({
    endpoint: CalendarEndpoints.ClientCreate,
    endpointParams: id,
    data: params,
  });
  return { data: response?.data, error: error?.data as ErrorType };
};

const createAppointment = async ({ params, thunkAPI }) => {
  const { rejectWithValue } = thunkAPI;
  const [error, response] = await apiRequest.post({
    endpoint: CalendarEndpoints.AppointmentCreate,
    data: params,
  });
  return response ? response.data : rejectWithValue(error?.data);
};

const fetchAppointments = async ({ params, rejectWithValue }) => {
  const queryParams = getQueryParams(params);
  const [error, response] = await apiRequest.get({
    endpoint: CalendarEndpoints.Appointments,
    endpointParams: queryParams,
  });
  return response ? response.data : rejectWithValue(error?.data);
};

const fetchAppointmentById = async (id: string) => {
  const [error, response] = await apiRequest.get({
    endpoint: CalendarEndpoints.AppointmentById,
    endpointParams: id,
  });
  return { data: response?.data, error: error?.data };
};

const updateAppointment = async ({ id, params, thunkAPI }) => {
  const { rejectWithValue } = thunkAPI;
  const [error, response] = await apiRequest.patch({
    endpoint: CalendarEndpoints.AppointmentById,
    endpointParams: id,
    data: params,
  });
  return response ? response.data : rejectWithValue(error?.data);
};

const convertGoogle = async ({ params, thunkAPI }) => {
  const { rejectWithValue } = thunkAPI;
  const [error, response] = await apiRequest.post({
    endpoint: CalendarEndpoints.GoogleConvert,
    data: params,
  });
  return response ? response.data : rejectWithValue(error?.data);
};

const closeAppointment = async ({ params, thunkAPI }) => {
  const { rejectWithValue } = thunkAPI;
  const [error, response] = await apiRequest.post({
    endpoint: CalendarEndpoints.CloseAppointment,
    data: params,
  });
  return response ? response.data : rejectWithValue(error?.data);
};

const cancelAllAppointment = async ({ params, thunkAPI }) => {
  const { rejectWithValue } = thunkAPI;
  const [error, response] = await apiRequest.patch({
    endpoint: CalendarEndpoints.CancelAll,
    data: params,
  });
  return response ? response.data : rejectWithValue(error?.data);
};

export const appointmentsAPI = {
  createClient,
  fetchAppointments,
  fetchAppointmentById,
  createAppointment,
  updateAppointment,
  convertGoogle,
  closeAppointment,
  cancelAllAppointment,
};
