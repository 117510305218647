import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Flex } from '@beauty/beauty-market-ui';
import { SidebarFooter } from 'components/SidebarFooter/SidebarFooter';
import { SidebarSheet } from '../../SidebarSheet';
import { PhotoServiceForm } from './PhotoServiceForm';

export const PhotoService = ({ handleClose, handleBackStep, handleNextStep, stepper }) => {
  const { t } = useTranslation();
  const [isValid, setIsValid] = useState(true);

  const footer = <SidebarFooter disabled={!isValid} onSubmit={handleNextStep} onBack={handleBackStep} />;
  const content = <PhotoServiceForm showCaption setIsValid={setIsValid} />;

  return (
    <SidebarSheet
      onClose={handleClose}
      FooterBody={footer}
      label={t('settings.services.sidebar.addNewService')}
      descriptor={t('settings.services.sidebar.chooseCategories')}
    >
      <Flex flexDirection="column" gap="16px" mb="80px">
        {stepper}
        {content}
      </Flex>
    </SidebarSheet>
  );
};
