import { useTranslation } from 'react-i18next';
import { PaymentContainer } from '@beauty/beauty-market-ui';
import { ServiceOption } from 'types/appointment';

type PaymentInformationProps = {
  service: string;
  services: ServiceOption[];
  price: string | null;
};

export const PaymentInformation = ({ service, services, price }: PaymentInformationProps) => {
  const { t } = useTranslation();
  const selectedService = services.find(item => item.id === service);

  return selectedService ? (
    <PaymentContainer
      title={t('payment.paymentInformation')}
      items={[{ label: selectedService.title, value: price || selectedService.price }]}
      total={t('payment.total')}
      totalValue={price || selectedService.price}
    />
  ) : null;
};
