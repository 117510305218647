import styled from 'styled-components';
import { Flex } from '@beauty/beauty-market-ui';

export const PhoneWrapper = styled(Flex)`
  width: 100%;
  justify-content: space-between;
  gap: 8px;
  flex-direction: row;
  [dir='rtl'] & {
    flex-direction: row-reverse;
    input:last-of-type {
      text-align: end;
    }
  }
`;
