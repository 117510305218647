import { LeftIcon, RightIcon, Button } from '@beauty/beauty-market-ui';
import { Wrapper } from './style';

type NavigationArrowsProps = {
  leftDisabled?: boolean;
  rightDisabled?: boolean;
  onLeftClick?: () => void;
  onRightClick?: () => void;
};

export const NavigationArrows = ({
  onLeftClick,
  onRightClick,
  leftDisabled = false,
  rightDisabled = false,
}: NavigationArrowsProps) => (
  <Wrapper>
    <Button design="tertiaryBlack" disabled={leftDisabled} size="small" onClick={onLeftClick}>
      <LeftIcon />
    </Button>
    <Button design="tertiaryBlack" disabled={rightDisabled} size="small" onClick={onRightClick}>
      <RightIcon />
    </Button>
  </Wrapper>
);
