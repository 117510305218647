import { Currency } from '../constants';

export enum SubscriptionPlanType {
  FREE = 'FREE',
  BASIC = 'BASIC',
  PRO = 'PRO',
  BUSINESS = 'BUSINESS',
  ENTERPRISE = 'ENTERPRISE',
  UPGRADE = 'UPGRADE',
}

export enum SubscriptionPricePlanType {
  MAIN = 'MAIN',
  EMPLOYEE = 'EMPLOYEE',
  WHATSAPP = 'WHATSAPP',
  TELEGRAM = 'TELEGRAM',
}

export enum SubscriptionStatusType {
  PAST = 'PAST',
  ACTIVE = 'ACTIVE',
  FUTURE = 'FUTURE',
}

export enum SubscribeActions {
  Unsubscribe = 'UNSUBSCRIBE',
  UpdateCard = 'UPDATE_PAYMENT_CARD',
  CancelSubscription = 'CANCEL_SUBSCRIPTION',
  NavigateToChangePlanPage = 'NAVIGATE_TO_CHANGE_PLAN_PAGE',
}

export enum PaymentSystemType {
  VISA = 'VISA',
  MASTERCARD = 'MASTERCARD',
}

export type SubscriptionPriceType = {
  annual: number;
  currency: Currency;
  id: string;
  monthly: number;
  planType: SubscriptionPricePlanType;
  subscriptionPlanId: string;
};

export type SubscriptionDataType = {
  id: string;
  limit: number;
  planType: SubscriptionPlanType;
  price: Record<string, Record<SubscriptionPricePlanType, SubscriptionPriceType>>;
  public: boolean;
};

export enum SubscriptionPeriodType {
  Monthly = 'monthly',
  Annually = 'annual',
}
