import { Views } from 'react-big-calendar';
import { Flex } from '@beauty/beauty-market-ui';
import { CardIcon, Dots, Filters, ListIcon, StyledButton } from './style';

type MobileCalendarMenuProps = {
  view: string;
  onClickFilters?: () => void;
  onClickList: () => void;
  onClickMenu?: () => void;
};

export const MobileCalendarMenu = ({ view, onClickFilters, onClickList, onClickMenu }: MobileCalendarMenuProps) => (
    <Flex
      width={view === Views.MONTH ? '36px' : '92px'}
      height="20px"
      justifyContent="space-between"
      onClick={e => e.stopPropagation()}
    >
      <StyledButton design="tertiary" type="button" onClick={onClickFilters}>
        <Filters />
      </StyledButton>
      {view !== Views.MONTH && (
        <>
          <StyledButton design="tertiary" type="button" onClick={onClickList}>
            {view === Views.AGENDA ? <CardIcon /> : <ListIcon />}
          </StyledButton>
          <StyledButton design="tertiary" type="button" onClick={onClickMenu}>
            <Dots />
          </StyledButton>
        </>
      )}
    </Flex>
  );
