import { BodyLarge, BodySmall } from '@beauty/beauty-market-ui';
import { Widget } from 'components';

type Props = {
  title?: string;
  subtitle?: string;
  description?: string;
  p?: string;
  separator?: boolean;
};

export const InfoItem = ({ title, subtitle, description, p = '20px 0', separator = true }: Props) => (
  <Widget
    title={title && <BodySmall>{title}</BodySmall>}
    subtitle={
      subtitle && (
        <BodyLarge large lowline>
          {subtitle}
        </BodyLarge>
      )
    }
    description={description && <BodyLarge large>{description}</BodyLarge>}
    p={p}
    separator={separator}
  />
);
