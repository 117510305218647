import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { BodySmall, colors, Flex, H5, Icon, LeftIcon, RightIcon } from '@beauty/beauty-market-ui';
import { Paper } from 'components';
import { isRtl } from 'helpers';
import { useMediaScreen } from 'hooks';
import { useAppSelector } from 'store/hooks';
import { selectUser } from 'store/redux-slices/userSlice';
import { Language } from 'types';
import { getLanguageOptions } from '../../../../constants';
import { EditLanguage } from './EditLanguage';

export const NotificationLanguage = () => {
  const { t } = useTranslation();
  const { isDesktop } = useMediaScreen('md');
  const { profile } = useAppSelector(selectUser);

  const [isOpen, setOpen] = useState(false);

  const rtl = isRtl();
  const language = profile.language || Language.English;
  const languageOptions = getLanguageOptions(t);
  const languageItem = languageOptions?.data[language] || languageOptions.data.EN;

  const handleClose = useCallback(() => setOpen(false), []);

  return (
    <>
      <Paper
        alignItems="center"
        justifyContent="space-between"
        cursor="pointer"
        onClick={() => setOpen(true)}
        borderRadius={isDesktop ? '0' : '10px'}
        p={isDesktop ? '24px' : '16px 8px'}
      >
        <Flex flexDirection="column">
          <H5>{t('profile.notificationLanguage')}</H5>
          <BodySmall>{t('profile.changeNotificationLanguage')}</BodySmall>
        </Flex>
        <Flex alignItems="center" gap="16px">
          <Flex alignItems="center" gap="4px">
            <Icon minWidth="20px" height="20px" ml="auto">
              {languageItem.icon}
            </Icon>
            <BodySmall large lowline color={colors.grey.standard}>
              {languageItem.value}
            </BodySmall>
          </Flex>
          <Icon width="20px" height="20px">
            {rtl ? <LeftIcon /> : <RightIcon />}
          </Icon>
        </Flex>
      </Paper>
      {isOpen && <EditLanguage onClose={handleClose} />}
    </>
  );
};
