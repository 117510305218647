import { useTranslation } from 'react-i18next';
import { Button, Flex } from '@beauty/beauty-market-ui';
import { NoData } from 'components/NoData';
import { SidebarSheet } from 'components/SidebarSheet';
import { EventType } from 'types';
import { EventsSection } from './EventsSection';

type ActiveEventsSidebarProps = {
  unclosedEvents: EventType[];
  inProgressEvents: EventType[];
  handleEventClick: (id: string) => void;
  handleClose: () => void;
};

export const ActiveEventsSidebar = ({
  unclosedEvents,
  inProgressEvents,
  handleEventClick,
  handleClose,
}: ActiveEventsSidebarProps) => {
  const { t } = useTranslation();

  const footer = (
    <Button design="secondary" mt="16px" width="100%" size="large" onClick={handleClose}>
      {t('button.close')}
    </Button>
  );

  const sidebarProps = {
    isOpen: true,
    onClose: handleClose,
    FooterBody: footer,
    label: t('calendar.daily'),
  };

  const body =
    unclosedEvents.length + inProgressEvents.length ? (
      <Flex flexDirection="column" gap="16px">
        {!!inProgressEvents.length && (
          <EventsSection status="inprogress" events={inProgressEvents} handleEventClick={handleEventClick} />
        )}
        {!!unclosedEvents.length && (
          <EventsSection status="unclosed" events={unclosedEvents} handleEventClick={handleEventClick} />
        )}
      </Flex>
    ) : (
      <NoData title={t('calendar.noActiveTitle')} info={t('calendar.noActiveSubtitle')} mt="0" />
    );

  return <SidebarSheet {...sidebarProps}>{body}</SidebarSheet>;
};
