import { createRoot } from 'react-dom/client';
import { ThemeProvider } from 'styled-components';
import { NavigationPointerIcon, Button, Div, BMTheme, theme, PlusIcon, MinusIcon } from '@beauty/beauty-market-ui';
import { GoogleMapType } from '../../../types';
import { ZoomButtonsWrapper } from '../style';

const getStyledComponentWrapper = (children: JSX.Element) => (
  <BMTheme>
    <ThemeProvider {...{ theme }}>{children}</ThemeProvider>
  </BMTheme>
);

const createMapElement = (children: JSX.Element) => {
  const divWrapper = document.createElement('div');
  const root = createRoot(divWrapper);
  root.render(getStyledComponentWrapper(children));
  return divWrapper;
};

export const getZoomControlButtons = (map: GoogleMapType, defaultZoom: number) => {
  const handleZoomIn = () => {
    map.setZoom((map.getZoom() || defaultZoom) + 1);
  };

  const handleZoomOut = () => {
    map.setZoom((map.getZoom() || defaultZoom) - 1);
  };

  return createMapElement(
    <ZoomButtonsWrapper>
      <Button
        size="small"
        design="withShadow"
        p="10px !important"
        borderBottomRightRadius="0"
        borderBottomLeftRadius="0"
        onClick={handleZoomIn}
      >
        <PlusIcon />
      </Button>
      <Button
        size="small"
        design="withShadow"
        p="10px !important"
        borderTopRightRadius="0"
        borderTopLeftRadius="0"
        onClick={handleZoomOut}
      >
        <MinusIcon />
      </Button>
    </ZoomButtonsWrapper>,
  );
};

export const getRightBottomButtons = (map: GoogleMapType, onClick: () => void) =>
  createMapElement(
    <Div mr="24px" mt="24px" mb="24px">
      <Button size="small" design="withShadow" p="10px !important" onClick={onClick}>
        <NavigationPointerIcon />
      </Button>
    </Div>,
  );
