import { BodySmall, Flex, H6, Separator } from '@beauty/beauty-market-ui';
import { ForAllDaysWrapper, StyledToggle } from './style';

export const ApplyForAllDays = ({ title, subtitle, checked, onChange, disabled, color }) => (
  <>
    <Separator />
    <ForAllDaysWrapper>
      <Flex flexDirection="column" gap="4px" alignItems="start">
        <H6 color={color}>{title}</H6>
        <BodySmall color={color}>{subtitle}</BodySmall>
      </Flex>
      <StyledToggle size="small" checked={checked} onChange={onChange} disabled={disabled} />
    </ForAllDaysWrapper>
  </>
);
