import React, { forwardRef, memo, useEffect, useMemo, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { Form, Formik, FormikProps } from 'formik';
import { Select, H6, Flex } from '@beauty/beauty-market-ui';
import { getSelectedLanguage, ServiceIcons } from '../../../constants';
import { getTranslation } from '../../../helpers';
import { AvailableOptionType, useAvailableFor } from '../../../hooks/useAvailableOptions';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import {
  selectService,
  updateAvailableFor,
  updateCategory,
  updateMainServiceInfo,
  updateService,
} from '../../../store/redux-slices/serviceSlice';
import { FormPropsType } from '../../../types';
import { CategoryOptionType } from '../../../types/service';
import { FormikInput, FormikTextfield } from '../../functional';
import { MainServiceFormFields, MainServiceFormType, mainServiceValidationSchema } from './MainService.definitions.';

export const MainServiceForm = memo(
  forwardRef(({ title, setIsValid, setIsDirty, showCaption = false }: FormPropsType, ref) => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const language = getSelectedLanguage();
    const availableForOptions = useAvailableFor();
    const { categories, newService, topIds } = useAppSelector(selectService);
    const { availableFor, category, service, description, name } = newService;
    const mainRef = useRef({ availableFor, category, service, description, name });

    const handleChangeCategory = (option: CategoryOptionType | null) => {
      if (option) {
        dispatch(updateCategory(option.id));
        dispatch(updateService(null));
      }
    };

    const handleChangeService = (option: CategoryOptionType | null) => {
      if (option) {
        dispatch(updateService(option.id));
      }
    };

    const handleChangeAvailableFor = (option: AvailableOptionType | null) => {
      if (option) {
        dispatch(updateAvailableFor(option.id));
      }
    };

    const categoryOptions = useMemo(
      () =>
        topIds.map(id => ({
          ...categories[id],
          value: getTranslation(categories[id].title, language),
          icon: ServiceIcons[categories[id].title.text],
        })),
      [categories, language],
    );

    const serviceOptions = useMemo(() => {
      if (!category) return [];

      return categories[category].childs.map(item => ({ ...item, value: getTranslation(item.title, language) }));
    }, [category, categories, language]);

    const availableForItem = availableForOptions[availableFor];
    const serviceItem = service ? serviceOptions.find(option => option.id === service) : null;
    const categoryItem = category ? categoryOptions.find(option => option.id === category) : null;

    // TODO: Description optional arg so 'without description' should be deleted, but now it's required field on back-end
    const onFormSubmit = (data: MainServiceFormType) => {
      const obj = {
        language,
        name: data.name,
        description: data.description || 'without description',
        categoryId: service || '',
      };
      dispatch(updateMainServiceInfo(obj));
    };

    const { Name, Description } = MainServiceFormFields;
    const formikContextValue = {
      initialValues: {
        [Name]: name,
        [Description]: description,
      },
      onSubmit: onFormSubmit,
      validateOnMount: true,
      validationSchema: mainServiceValidationSchema(t),
    };

    return (
      <Formik innerRef={ref as (instance: FormikProps<MainServiceFormType> | null) => void} {...formikContextValue}>
        {({ isValid, errors, values, handleChange }) => {
          useEffect(() => {
            const valid = category && service && availableFor && isValid;
            setIsValid && setIsValid(!!valid);
          }, [category, service, availableFor, isValid]);

          useEffect(() => {
            const dirty =
              mainRef.current.name !== values[Name] ||
              mainRef.current.description !== values[Description] ||
              mainRef.current.category !== category ||
              mainRef.current.service !== service ||
              mainRef.current.availableFor !== availableFor;
            setIsDirty && setIsDirty(dirty);
          }, [category, service, availableFor, values[Description], values[Name]]);

          return (
            <Form>
              <Flex flexDirection="column" gap="16px">
                {title && <H6>{title}</H6>}
                <FormikInput
                  id={Name}
                  name={Name}
                  width="100%"
                  design="white"
                  value={values[Name]}
                  onChange={handleChange}
                  placeholder={t('settings.services.sidebar.name')}
                  required
                />
                <Select
                  isSearch
                  selected={categoryItem}
                  options={categoryOptions}
                  onSelect={handleChangeCategory}
                  placeholder={t('settings.services.sidebar.category')}
                  required
                />
                <Select
                  isSearch
                  selected={serviceItem}
                  disabled={!category}
                  options={serviceOptions}
                  onSelect={handleChangeService}
                  placeholder={t('settings.services.sidebar.typeOfService')}
                  caption={showCaption && t('settings.services.sidebar.chooseProcedure')}
                  required
                />
                <Select
                  selected={availableForItem}
                  options={Object.values(availableForOptions)}
                  onSelect={handleChangeAvailableFor}
                  placeholder={t('settings.services.sidebar.availableFor')}
                  required
                />
                <FormikTextfield
                  rows={4}
                  design="white"
                  id={Description}
                  name={Description}
                  onChange={handleChange}
                  value={values[Description]}
                  defaultValue={values[Description]}
                  placeholder={t('settings.services.sidebar.description')}
                  caption={
                    errors[Description]
                      ? errors[Description]
                      : showCaption && t('settings.services.sidebar.optionalField')
                  }
                />
              </Flex>
            </Form>
          );
        }}
      </Formik>
    );
  }),
);
