import { useTranslation } from 'react-i18next';
import { Button, PlusIcon } from '@beauty/beauty-market-ui';
import LogoutImage from 'assets/exit-image.png';
import { Stub } from 'components';
import { useMediaScreen } from 'hooks';

export const EmployeeStub = ({ onAddClick }) => {
  const { t } = useTranslation();
  const { isDesktop } = useMediaScreen('md');
  return (
    <Stub
      title={t('specialists.noEmployees')}
      subtitle={t('specialists.addSpecialistToWork')}
      src={LogoutImage}
      alt={t('logout.altImg')}
      margin={isDesktop ? '171px auto 0' : '73px 8px auto'}
    >
      <Button
        prefix={isDesktop ? <PlusIcon width="20px" height="20px" /> : null}
        onClick={onAddClick}
        design="primary"
        size="small"
      >
        {t('specialists.addNew')}
      </Button>
    </Stub>
  );
};
