import { apiRequest } from 'api/api';
import { GoogleAuthorisationEndpoints, UserEndpoints } from 'api/endpoints/endpoints';

export const getCalendarAccessUrl = async (id: string) => {
  const [error, response] = await apiRequest.get({
    endpoint: GoogleAuthorisationEndpoints.Calendar,
    endpointParams: id,
  });
  return response ? response.data : error?.data;
};

export const getAccount = async (id: string) => {
  const [error, response] = await apiRequest.get({
    endpoint: UserEndpoints.Root,
    endpointParams: {
      id,
      queryParams: 'include=accountAppRole,client,headOrgSpecialist,address,contact,accountNotification,document',
    },
  });
  return response ? response.data : error?.data;
};
