import { range } from 'lodash';
import { Flex } from '@beauty/beauty-market-ui';
import { Dot, DotsTab, DotsWrapper } from './style';

type DotsProps = {
  number: number;
  active: number;
  onClick?: (active) => void;
};

const paddings = 8;
const dotSizeWithMargins = 9;

export const PaginationProgress = ({ number, active, onClick }: DotsProps) => (
  <Flex>
    <DotsTab width={`${paddings + dotSizeWithMargins * number}px`} />
    <DotsWrapper>
      {range(number).map(dot => (
        <Dot key={dot} isActive={dot === active} onClick={() => onClick && onClick(dot)} />
      ))}
    </DotsWrapper>
  </Flex>
);
