import styled from 'styled-components';
import { Flex, Div, CheckedIcon, BodySmall, RadioButton } from '@beauty/beauty-market-ui';
import { Paper } from '../style';

export const Container = styled(Flex)`
  flex-direction: column;
  border-radius: 16px;
  padding: 24px;
  width: 100%;
  background-color: ${({ theme }) => theme.colors.white.standard};
`;

export const Row = styled(Div)`
  display: grid;
  grid-template-columns: 2.85fr 1fr 1fr 1fr 1fr;
  grid-template-rows: repeat(1, 52px);
  align-items: center;
  justify-content: start;
`;

export const StyledCheckedIcon = styled(CheckedIcon)<{ color: string }>`
  flex-shrink: 0;
  width: 24px;
  height: 24px;

  circle {
    fill: ${({ color }) => color};
  }

  path {
    stroke: ${({ theme }) => theme.colors.white.standard};
  }
`;

export const ColoredPaper = styled(Paper)`
  flex-direction: column;
  min-height: 224px;
  padding: 24px;
  border-radius: 16px;
  background: linear-gradient(147deg, #f54b31 10.8%, #7070ff 61%);
`;

export const BorderedPaper = styled(Paper)`
  flex-direction: column;
  gap: 16px;
  padding: 16px;
  border-radius: 10px;
  border: ${({ theme }) => `1px solid ${theme.colors.grey.standard}`};
`;

export const StyledText = styled(BodySmall)`
  color: ${({ theme }) => theme.colors.grey.dark} !important;
  cursor: pointer;
  &:hover {
    color: ${({ theme }) => theme.colors.blue.standard} !important;
  }
`;

export const StyledRadio = styled(RadioButton)`
  width: 24px !important;
  height: 24px !important;

  &::after {
    width: 16px !important;
    height: 16px !important;
  }
`;
