import { memo, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import GoogleMap from 'google-maps-react-markers';
import { BodySmall, colors } from '@beauty/beauty-market-ui';
import { mapCenter } from '../../../constants';
import { useAppSelector } from '../../../store/hooks';
import { selectGeolocation } from '../../../store/redux-slices/addressSlice';
import { GoogleMapType, LatLngLiteral, MapOptions } from '../../../types';
import { CustomMapWrapper, GoogleMapWrapper } from '../style';
import { getRightBottomButtons, getZoomControlButtons } from './helpers';
import MapPointLocation from './MapPointLocation';

const options: MapOptions = {
  disableDefaultUI: true,
  disableDoubleClickZoom: true,
  mapId: '4a89a47c8aa391b1',
  clickableIcons: false,
  gestureHandling: 'greedy',
};

const CustomMap = () => {
  const { t } = useTranslation();
  const { lng, lat } = useAppSelector(selectGeolocation);
  const [mapBounds, setMapBounds] = useState({});
  const defaultCenter = { lat: lat || mapCenter.lat, lng: lng || mapCenter.lng };
  const centerRef = useRef<LatLngLiteral>(defaultCenter);
  const mapsControlsRef = useRef<any>(null);
  const mapRef = useRef<GoogleMapType | null>(null);
  const mapZoom = 12;

  const onMapChange = ({ bounds, zoom }: { bounds: google.maps.LatLngBounds; zoom: number }) => {
    const ne = bounds.getNorthEast();
    const sw = bounds.getSouthWest();
    setMapBounds({ ...mapBounds, bounds: [sw.lng(), sw.lat(), ne.lng(), ne.lat()], zoom });
  };

  const onBackCenter = () => {
    if (mapRef.current) {
      mapRef.current?.panTo(centerRef.current);
    }
  };

  const onGoogleApiLoaded = ({ map, maps }) => {
    mapRef.current = map;
    mapsControlsRef.current = maps;

    const rightBottomButtons = getRightBottomButtons(map, onBackCenter);
    const zoomButtons = getZoomControlButtons(map, mapZoom);

    mapRef?.current?.controls[mapsControlsRef.current?.ControlPosition.RIGHT_BOTTOM].push(rightBottomButtons);
    mapRef?.current?.controls[mapsControlsRef.current?.ControlPosition.RIGHT_BOTTOM].push(zoomButtons);
  };

  useEffect(() => {
    if (lng != null && lat != null) {
      centerRef.current = { lat, lng };
      onBackCenter();
    }
  }, [lng, lat]);

  return (
    <CustomMapWrapper>
      <GoogleMapWrapper>
        <GoogleMap
          apiKey={process.env.REACT_APP_GOOGLE_MAP_KEY}
          defaultCenter={centerRef.current}
          defaultZoom={mapZoom}
          options={options}
          mapMinHeight="260px"
          onChange={onMapChange}
          onGoogleApiLoaded={onGoogleApiLoaded}
          yesIWantToUseGoogleMapApiInternals
          bootstrapURLKeys={{
            key: process.env.REACT_APP_GOOGLE_MAP_KEY,
            libraries: ['geometry'],
          }}
        >
          <MapPointLocation lat={centerRef.current.lat} lng={centerRef.current.lng} />
        </GoogleMap>
      </GoogleMapWrapper>
      <BodySmall color={colors.grey.dark}>{t('settings.addresses.sidebar.dragMap')}</BodySmall>
    </CustomMapWrapper>
  );
};

export default memo(CustomMap);
