import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import isEmpty from 'lodash/isEmpty';
import {
  Caption,
  colors,
  Flex,
  getMinWidthMediaQuery,
  UploadMainPhoto,
  UploadPhotos,
  useMediaQuery,
  useNotify,
  NotifyStatus,
} from '@beauty/beauty-market-ui';
import { SidebarFooter, SidebarSheet } from 'components';
import { checkPhotosValid, getAddressNotifyContent, hasArrayChanged, updatePhotosByChunk } from 'helpers';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { selectNewAddress } from 'store/redux-slices/addressSlice';
import { selectSubscriptionPlan } from 'store/redux-slices/organisationSlice';
import { AddressAction, ErrorDataType, ImageType, PhotoStoreType } from 'types';
import { MAX_PHOTO_ADDRESS_COUNT } from '../../../../../constants';
import { getDeletedPhotos } from '../../helpers';

export const EditPhotoGallery = ({ onClose }) => {
  const { t } = useTranslation();
  const { photos } = useAppSelector(selectNewAddress);
  const subscriptionLimit = useAppSelector(selectSubscriptionPlan);
  const dispatch = useAppDispatch();
  const notify = useNotify();

  const [store, setStore] = useState<PhotoStoreType>(photos);
  const [error, setError] = useState<string | null>('');
  const [isSubmitting, setSubmitting] = useState(false);
  const [isDirty, setDirty] = useState(false);

  const mediaQuery = getMinWidthMediaQuery('md');
  const isDesktop = useMediaQuery(mediaQuery);

  const handleUploadMain = (image: File) => {
    const errorMessage = checkPhotosValid([image], t);
    setError(errorMessage);
    setDirty(true);
    setStore(prevStore => ({
      ...prevStore,
      mainPhoto: image,
      deletePhotos: getDeletedPhotos(prevStore, prevStore.mainPhoto),
    }));
  };

  const handleUploadPhotos = (images: ImageType[]) => {
    const updatedPhotos = [...store.photos, ...images];
    const errorMessage = checkPhotosValid(updatedPhotos, t, subscriptionLimit?.photoLimit);
    setError(errorMessage);
    setDirty(true);
    setStore(prevStore => ({ ...prevStore, photos: updatedPhotos }));
  };

  const handleDeletePhoto = (photoToDelete: ImageType) => {
    const filteredPhotos = store.photos.filter(photo => photo !== photoToDelete);
    const errorMessage = checkPhotosValid(filteredPhotos, t, subscriptionLimit?.photoLimit);
    const dirty = hasArrayChanged(filteredPhotos, photos.photos);
    const isMainChanged = photos.mainPhoto !== store.mainPhoto;
    setError(errorMessage);
    setDirty(dirty || isMainChanged);
    setStore(prevStore => ({
      ...prevStore,
      photos: filteredPhotos,
      deletePhotos: getDeletedPhotos(prevStore, photoToDelete),
    }));
  };

  const handleSubmitForm = () => {
    setSubmitting(true);
    updatePhotosByChunk(store, dispatch)
      .then(() => notify(getAddressNotifyContent(NotifyStatus.SUCCESS, AddressAction.EditPhotos, t)))
      .catch((err: ErrorDataType) =>
        notify(getAddressNotifyContent(NotifyStatus.ERROR, AddressAction.EditPhotos, t, err.message)),
      )
      .finally(() => {
        setSubmitting(false);
        onClose();
      });
  };

  const sidebarFooter = (
    <SidebarFooter
      save
      disabled={!!error || !isDirty}
      isLoading={isSubmitting}
      onSubmit={handleSubmitForm}
      onBack={onClose}
    />
  );

  const isPreventExtension = !isEmpty(store.photos) && (error === null || !!error);

  return (
    <SidebarSheet
      label={t('address.photoGallery')}
      descriptor={t('address.editInformationAboutAddress')}
      FooterBody={sidebarFooter}
      onClose={onClose}
      onBack={onClose}
    >
      <Flex gap="16px" flexDirection="column" mb="100px">
        <UploadMainPhoto image={store.mainPhoto} onUpload={handleUploadMain} badgeText={t('address.mainPhoto')} />
        <UploadPhotos
          images={store.photos}
          max={subscriptionLimit?.photoLimit || MAX_PHOTO_ADDRESS_COUNT}
          onUpload={handleUploadPhotos}
          onDelete={handleDeletePhoto}
          preventExtension={isPreventExtension}
          mode={isDesktop ? 'hover' : 'double-tap'}
        />
        {error && <Caption color={colors.red.standard}>{error}</Caption>}
      </Flex>
    </SidebarSheet>
  );
};
