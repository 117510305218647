import { TFunction } from 'react-i18next';
import * as Yup from 'yup';

import { VALID_IMAGES } from '../constants';
import { getPluralForm } from './utils';

export const validateMinMax = (t: TFunction<'translation', undefined>, min: number, max: number) => {
  const minSuffix = getPluralForm(min);
  const maxSuffix = getPluralForm(max);
  return Yup.string()
    .trim()
    .min(min, t(`validation.minCharacters.${minSuffix}`, { count: min }))
    .max(max, t(`validation.maxCharacters.${maxSuffix}`, { count: max }));
};

export const priceValidation: (template: RegExp, t: TFunction<'translation', undefined>) => Yup.AnySchema = (
  template,
  t,
) =>
  Yup.string()
    .required(t('validation.notEmpty'))
    .matches(template, t('validation.enterValidPrice'))
    .max(10, t('validation.maxPriceValue'));

export const phoneNumberValidation: (t: TFunction<'translation', undefined>) => Yup.AnySchema = t =>
  Yup.string()
    .typeError(t('validation.wrongPhone'))
    .max(15, t('validation.maxLength15'))
    .matches(/^\d{7,}$/, t('validation.enterValidPhone'));

export const photoValidation = (t: TFunction<'transition', undefined>, maxSize: number) =>
  Yup.mixed()
    .test({
      message: t('validation.incorrectImageFormat'),
      test: (file, context) => {
        const isValid = file === null || typeof file === 'string' || VALID_IMAGES.includes(file?.type);
        if (!isValid) context?.createError();
        return isValid;
      },
    })
    .test({
      message: t('validation.maxPhotoSize', { count: maxSize }),
      test: file => {
        const size = file?.size ? file.size / (1024 * 1024) : 0;
        return size < maxSize;
      },
    });
