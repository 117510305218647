import { calendarIcons } from '@beauty/beauty-market-ui';
import { EventStatus } from '../../constants';

export const MIN_DURATION = 15;

const { Completed, Pending, Confirmed, Waiting, InProgress, NoShow } = calendarIcons;

export const statusData = {
  [EventStatus.PENDING]: {
    color: 'transparent',
    icon: <Pending />,
  },
  [EventStatus.CONFIRMED]: {
    color: 'darkGrey',
    icon: <Confirmed />,
  },
  [EventStatus.WAITING]: {
    color: 'orange',
    icon: <Waiting />,
  },
  [EventStatus.INPROGRESS]: {
    color: 'green',
    icon: <InProgress />,
  },
  [EventStatus.COMPLETED]: {
    color: 'blue',
    icon: <Completed />,
  },
  [EventStatus.PAST]: {
    color: 'blue',
    icon: <Completed />,
  },
  [EventStatus.NOSHOW]: {
    color: 'red',
    icon: <NoShow />,
  },
  [EventStatus.UNCLOSED]: {
    color: 'blue',
    icon: <Completed />,
  },
};

export const MINUTES_PER_SLOT = 15;

export const todayColorAll = 'rgba(112,112,255,0.08)';
export const todayColorWorking = `#F4F4FF`;
export const lineColor = 'rgba(0, 0, 0, 0.08)';
