import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Nullable } from 'tsdef';
import { BodyLarge, BodySmall, colors, Flex, NotifyStatus, useNotify } from '@beauty/beauty-market-ui';
import { PaymentMethods, SidebarFooter, SidebarSheet } from 'components';
import { getFormattedCurrency, getNotifyContent } from 'helpers';
import { ThunkCommon } from 'store';
import { OrganizationAction, PaymentCardType, SubscriptionPricePlanType } from 'types';
import { getSelectedLanguage } from '../../../../constants';
import { useAppDispatch, useAppSelector } from '../../../../store/hooks';
import { selectActiveSubscription, selectPaymentCards } from '../../../../store/redux-slices/organisationSlice';
import { BorderedPaper } from '../../../ProfileSettings/ChangePlanPage/style';

export const PaymentBar = ({ specId, onClose }) => {
  const { t } = useTranslation();
  const paymentCards = useAppSelector(selectPaymentCards);
  const subscription = useAppSelector(selectActiveSubscription);
  const dispatch = useAppDispatch();
  const notify = useNotify();
  const language = getSelectedLanguage();

  const planPriceList = subscription?.plan?.price.find(item => item.planType === SubscriptionPricePlanType.EMPLOYEE);

  const [card, setCard] = useState<Nullable<PaymentCardType>>(paymentCards.find(item => item.default) || null);
  const [isSubmitting, setSubmitting] = useState(false);

  const employeeTodayAmount = subscription?.todaysPrice.employee || 0;
  const employeeMonthAmount = planPriceList?.monthly || 0;
  const employeeCurrency = planPriceList?.currency || '';

  const employeeTodayPrice = getFormattedCurrency(employeeTodayAmount, employeeCurrency, language);
  const employeeMonthPrice = getFormattedCurrency(employeeMonthAmount, employeeCurrency, language);

  const handleSubmit = () => {
    setSubmitting(true);
    dispatch(ThunkCommon.activateSpecialist({ specId, cardId: card?.id }))
      .unwrap()
      .then(res => {
        if (res?.status === 0) {
          throw new Error(t('validation.unknown'));
        } else if (card?.id) {
          notify(getNotifyContent(NotifyStatus.SUCCESS, OrganizationAction.ActivateSpecialist, t));
        } else if (res.payment_url) {
          window.location.href = res.payment_url;
        } else {
          throw new Error(t('validation.unknown'));
        }
      })
      .catch(() => notify(getNotifyContent(NotifyStatus.ERROR, OrganizationAction.ActivateSpecialist, t)))
      .then(() => onClose())
      .finally(() => setSubmitting(false));
  };

  return (
    <SidebarSheet
      onClose={onClose}
      label={t('subscriptions.subscriptionPayment')}
      descriptor={t('subscriptions.additionalServices')}
      FooterBody={
        <SidebarFooter
          onSubmit={handleSubmit}
          onBack={onClose}
          confirmationLabel={t('subscriptions.pay')}
          disabled={false}
          isLoading={isSubmitting}
          cancel
        />
      }
    >
      <Flex flexDirection="column" gap="40px" mb="80px">
        <Flex flexDirection="column" gap="8px" alignItems="center">
          <BodyLarge>{t('subscriptions.youWillBeCharged', { price: employeeTodayPrice })}</BodyLarge>
          <BodyLarge>{t('subscriptions.thenForEachEmployee', { price: employeeMonthPrice })}</BodyLarge>
        </Flex>
        <BorderedPaper>
          <BodyLarge>{t('subscriptions.paymentInformation')}</BodyLarge>
          <Flex flexDirection="column" gap="8px">
            <BodySmall color={colors.grey.dark}>{t('subscriptions.employees.one', { count: 1 })}</BodySmall>
            <BodySmall color={colors.grey.dark}>{employeeTodayPrice}</BodySmall>
          </Flex>
          <Flex justifyContent="space-between">
            <BodySmall>{t('subscriptions.total')}</BodySmall>
            <BodySmall>{employeeTodayPrice}</BodySmall>
          </Flex>
        </BorderedPaper>
        <PaymentMethods value={card} onChange={setCard} stub />
      </Flex>
    </SidebarSheet>
  );
};
