import { Dispatch, SetStateAction } from 'react';
import { useTranslation } from 'react-i18next';
import { BodySmall, colors, RatingStars } from '@beauty/beauty-market-ui';
import { Customer } from 'types/calendar';
import { RatingCard } from './RatingCard';
import { ColumnFlex } from './style';

type SidebarBodyProps = {
  title: string;
  client: Customer;
  stars: number;
  setStars: Dispatch<SetStateAction<number>>;
};

export const SidebarBody = ({ title, client, stars, setStars }: SidebarBodyProps) => {
  const { t } = useTranslation();

  const { avatarUrl, clientName } = client;

  return (
    <ColumnFlex mt="40px" alignItems="center">
      <RatingCard avatarUrl={avatarUrl} title={clientName} info={t('review.client')} />
      <ColumnFlex>
        <RatingStars rating={stars} onClick={setStars} />

        <BodySmall lowline textAlign="center" color={colors.grey.dark} mb="81px">
          {stars ? t('review.yourRating') : title}
        </BodySmall>
      </ColumnFlex>
    </ColumnFlex>
  );
};
