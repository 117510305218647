import styled from 'styled-components';
import { Flex, colors } from '@beauty/beauty-market-ui';

const FullRound = 283;

export const CircleSvg = styled.svg`
  width: 100%;
  height: 100%;
`;

export const BackgroundCircle = styled.circle<{ design: string }>`
  cx: 50;
  cy: 50;
  r: 45;
  fill: none;
  stroke: ${({ design }) => (design === 'white' ? colors.white.standard : colors.grey.light)};
  stroke-width: 10px;
`;

export const ProgressCircle = styled.circle<{ progress: number }>`
  cx: 50;
  cy: 50;
  r: 45;
  fill: none;
  stroke: ${colors.blue.standard};
  stroke-dasharray: ${({ progress }) => `${(progress * FullRound).toFixed(0)} ${FullRound}`};
  stroke-dashoffset: 0;
  stroke-width: 10px;
  stroke-linecap: round;
  transform: rotate(-90deg);
  transform-origin: center;
`;

export const CircleContent = styled(Flex)`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  justify-content: center;
  align-items: center;
`;

export const Circle = styled(Flex)`
  position: relative;
  width: 48px;
  height: 48px;
`;
