import { TFunction } from 'react-i18next';
import { isValidPhoneNumber } from 'libphonenumber-js/max';
import moment from 'moment';
import * as Yup from 'yup';
import { getShortCountryByCode } from 'helpers';
import { CreateSpecialistParams, SpecialistResponse } from 'types';
import { dateReg, emailReg } from '../../../constants';

export enum EditEmployeeFormFields {
  Name = 'name',
  Surname = 'surname',
  Position = 'role',
  Specialisation = 'specialization',
  Region = 'code',
  Number = 'number',
  Email = 'email',
  Start = 'accessStart',
  Finish = 'accessEnd',
  Access = 'accessLevel',
  Photo = 'photo',
}

export type EditEmployeeForm = {
  [EditEmployeeFormFields.Name]: string;
  [EditEmployeeFormFields.Surname]: string;
  [EditEmployeeFormFields.Position]: number;
  [EditEmployeeFormFields.Specialisation]: string;
  [EditEmployeeFormFields.Region]: string;
  [EditEmployeeFormFields.Number]: string;
  [EditEmployeeFormFields.Email]: string;
  [EditEmployeeFormFields.Start]: string;
  [EditEmployeeFormFields.Finish]: string | undefined;
  [EditEmployeeFormFields.Access]: number;
  [EditEmployeeFormFields.Photo]: string | File | null;
};

export const initialValues = (
  specialist: CreateSpecialistParams | SpecialistResponse,
  positionOptions,
  accessOptions,
): EditEmployeeForm => {
  const accessStart = specialist.accessStart
    ? moment(specialist.accessStart).format('DD.MM.YYYY')
    : moment().format('DD.MM.YYYY');

  const accessEnd = specialist.accessEnd ? moment(specialist.accessEnd).format('DD.MM.YYYY') : undefined;
  const positionIndex = positionOptions.findIndex(item => item.id === specialist.role);
  const accessIndex = accessOptions.findIndex(item => item.id === specialist.accessLevel);

  return {
    [EditEmployeeFormFields.Name]: specialist.account.name,
    [EditEmployeeFormFields.Surname]: specialist.account.surname,
    [EditEmployeeFormFields.Position]: positionIndex !== -1 ? positionIndex : 0,
    [EditEmployeeFormFields.Specialisation]: specialist.specialization || '',
    [EditEmployeeFormFields.Region]: specialist.account.code,
    [EditEmployeeFormFields.Number]: specialist.account.number,
    [EditEmployeeFormFields.Email]: specialist.account.email,
    [EditEmployeeFormFields.Start]: accessStart,
    [EditEmployeeFormFields.Finish]: accessEnd,
    [EditEmployeeFormFields.Access]: accessIndex !== -1 ? accessIndex : 0,
    [EditEmployeeFormFields.Photo]: specialist.account.avatarUrl,
  };
};

export const validationSchema = (t: TFunction<'translation', undefined>) =>
  Yup.object({
    [EditEmployeeFormFields.Name]: Yup.string()
      .trim()
      .min(2, t('validation.minName'))
      .max(50, t('validation.maxName'))
      .required(t('validation.notEmpty')),
    [EditEmployeeFormFields.Surname]: Yup.string()
      .trim()
      .min(1, t('validation.minName1'))
      .max(50, t('validation.maxName'))
      .required(t('validation.notEmpty')),
    [EditEmployeeFormFields.Specialisation]: Yup.string()
      .trim()
      .min(3, t('validation.minLength3'))
      .max(50, t('validation.maxName'))
      .required(t('validation.notEmpty')),
    [EditEmployeeFormFields.Region]: Yup.string(),
    [EditEmployeeFormFields.Number]: Yup.string()
      .trim()
      .required(t('validation.phoneRequired'))
      .test('validate-code', t('validation.codeRequired'), function validateCode() {
        return !!this.parent[EditEmployeeFormFields.Region];
      })
      .test('validate-phone', t('validation.enterValidPhone'), function validatePhone(phone) {
        const code = this.parent[EditEmployeeFormFields.Region];
        if (code) {
          return isValidPhoneNumber(`${code} ${phone}`, getShortCountryByCode(code));
        }
        return false;
      }),
    [EditEmployeeFormFields.Email]: Yup.string()
      .trim()
      .matches(emailReg, t('validation.emailNotValid'))
      .required(t('validation.notEmpty')),
    [EditEmployeeFormFields.Position]: Yup.number().required(t('validation.notEmpty')),
    [EditEmployeeFormFields.Access]: Yup.number().required(t('validation.notEmpty')),
    [EditEmployeeFormFields.Start]: Yup.string()
      .matches(dateReg, t('validation.correctDate'))
      .required(t('validation.notEmpty'))
      .test('is-less-than-finish', t('validation.startEarlierFinish'), function checkStart(start) {
        const finish = this.parent[EditEmployeeFormFields.Finish];
        if (start && finish) {
          const startDate = moment(start, 'DD.MM.YYYY');
          const finishDate = moment(finish, 'DD.MM.YYYY');
          return startDate.isBefore(finishDate);
        }
        return true;
      }),
    [EditEmployeeFormFields.Finish]: Yup.string()
      .matches(dateReg, t('validation.correctDate'))
      .test('is-more-than-start', t('validation.finishLaterStart'), function checkEnd(finish) {
        const start = this.parent[EditEmployeeFormFields.Start];
        if (start && finish) {
          const startDate = moment(start, 'DD.MM.YYYY');
          const finishDate = moment(finish, 'DD.MM.YYYY');
          return finishDate.isAfter(startDate);
        }
        return true;
      }),
  });
