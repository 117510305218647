import { useEffect, useState } from 'react';
import { apiRequest } from '../api/api';
import { HeadOrganisationEndpoints } from '../api/endpoints/endpoints';
import { groupPricesByCurrency } from '../helpers';
import { SubscriptionDataType, SubscriptionPlanType, SubscriptionPriceType } from '../types';

type ResponseDataType = {
  id: string;
  orgLimit: number;
  photoLimit: boolean;
  planType: SubscriptionPlanType;
  price: SubscriptionPriceType[];
  public: boolean;
  whatsApp: boolean;
  telegram: boolean;
};

export const useGetSubscription = () => {
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [subscriptionPlans, setSubscriptionPlans] = useState<SubscriptionDataType[]>([]);

  useEffect(() => {
    const fetchSubscriptions = async () => {
      const [error, response] = await apiRequest.get({
        endpoint: HeadOrganisationEndpoints.SubscriptionPlans,
      });

      if (error?.data) alert('Error with loading subscription plans§');
      if (response) {
        const updatedData = response.data
          .filter(
            (subscription: ResponseDataType) =>
              subscription.public && subscription.planType !== SubscriptionPlanType.FREE,
          )
          .map((subscription: ResponseDataType) => ({
            id: subscription.id,
            limit: subscription.orgLimit,
            planType: subscription.planType,
            price: groupPricesByCurrency(subscription.price),
            public: subscription.public,
          }));

        setSubscriptionPlans(updatedData);
      }
      setIsLoading(false);
    };

    fetchSubscriptions();

    return setIsLoading(false);
  }, []);

  return {
    isLoading,
    subscriptionPlans,
  };
};
