import { useTranslation } from 'react-i18next';
import { Select, Flex } from '@beauty/beauty-market-ui';

type SelectOption = { id: string; value: string };

type Props = {
  language: SelectOption | null;
  level: SelectOption | null;
  languages: SelectOption[];
  levels: SelectOption[];
  onSelectLevel?: (item: SelectOption) => void;
  onSelectLanguage?: (item: SelectOption) => void;
};

export const LanguageField = ({ languages, level, levels, language, onSelectLanguage, onSelectLevel }: Props) => {
  const { t } = useTranslation();
  return (
    <Flex flexDirection="column" gap="16px">
      <Select
        options={languages}
        selected={language}
        onSelect={onSelectLanguage}
        placeholder={t('specialists.personalInfo.language')}
      />
      <Select
        options={levels}
        selected={level}
        onSelect={onSelectLevel}
        placeholder={t('specialists.personalInfo.level')}
      />
    </Flex>
  );
};
