import { TFunction } from 'react-i18next';
import { isArray, upperCase } from 'lodash';
import { access, invalidHints, InvalidVariants, positions, ServiceIcons } from '../../constants';
import { getTranslation } from '../../helpers/utils';
import { ErrorDataType } from '../../types';
import { EditEmployeeFormFields } from './EditEmployee/EditEmployee.definitions';
import { CategoryInfoType, DropdownOptionType, TopCategoryState } from './types';

export const extractCategoryInfo = (item, orgServices): CategoryInfoType => ({
  topCategoryId: item.headOrgService.category?.parentId,
  topCategoryTitle: item.headOrgService.category?.parent.title,
  subCategoryId: item.headOrgService.category?.id,
  subCategoryTitle: item.headOrgService.category?.title,
  serviceId: item.id,
  serviceTitle: orgServices[item.headOrgServId]?.title ?? '',
  serviceDescription: orgServices[item.headOrgServId]?.availableFor ?? '',
});

export const updateCategory = (acc, categoryInfo, language) => {
  const { topCategoryId, subCategoryId, subCategoryTitle, serviceId, serviceTitle, serviceDescription } = categoryInfo;
  if (topCategoryId && subCategoryId && subCategoryTitle) {
    acc[topCategoryId].serviceIds.push(serviceId);
    if (acc[topCategoryId].subCategories[subCategoryId]) {
      acc[topCategoryId].subCategories[subCategoryId].serviceIds.push(serviceId);
      acc[topCategoryId].subCategories[subCategoryId].services.push({
        id: serviceId,
        title: getTranslation(serviceTitle, language),
        description: serviceDescription,
      });
    } else {
      acc[topCategoryId].subCategories[subCategoryId] = {
        id: subCategoryId,
        title: getTranslation(subCategoryTitle, language),
        serviceIds: [serviceId],
        services: [
          {
            id: serviceId,
            title: getTranslation(serviceTitle, language),
            description: serviceDescription,
          },
        ],
      };
    }
  }
};

export const createCategory = (acc, categoryInfo, language) => {
  const {
    topCategoryId,
    topCategoryTitle,
    subCategoryId,
    subCategoryTitle,
    serviceId,
    serviceTitle,
    serviceDescription,
  } = categoryInfo;
  if (topCategoryId && topCategoryTitle && subCategoryId && subCategoryTitle) {
    acc[topCategoryId] = {
      id: topCategoryId,
      icon: topCategoryTitle.text ? ServiceIcons[topCategoryTitle.text] : null,
      title: getTranslation(topCategoryTitle, language),
      serviceIds: [serviceId],
      subCategories: {
        [subCategoryId]: {
          id: subCategoryId,
          title: getTranslation(subCategoryTitle, language),
          serviceIds: [serviceId],
          services: [
            {
              id: serviceId,
              title: getTranslation(serviceTitle, language),
              description: serviceDescription,
            },
          ],
        },
      },
    };
  }
};

export const getTopCategoryData = (orgServices, headOrgServices, language) => {
  if (!isArray(orgServices)) return {};
  const topCategories: TopCategoryState = orgServices.reduce((acc, item) => {
    const categoryInfo = extractCategoryInfo(item, headOrgServices);
    const { topCategoryId } = categoryInfo;

    if (topCategoryId && acc[topCategoryId]) {
      updateCategory(acc, categoryInfo, language);
    } else if (topCategoryId) {
      createCategory(acc, categoryInfo, language);
    }
    return acc;
  }, {});

  return topCategories;
};

export const getAccessOptions = (isOwner, t) => {
  const filteredAccess = isOwner ? ['owner'] : access;
  return filteredAccess.reduce((acc: DropdownOptionType[], level) => {
    acc.push({
      id: upperCase(level),
      item: t(`settings.specialists.level.${level}`),
      disabled: false,
    });
    return acc;
  }, []);
};

export const getPositionOptions = (isOnlyOwner, t) => {
  const filteredPositions = isOnlyOwner ? ['specialist'] : positions;
  return filteredPositions.reduce((acc: DropdownOptionType[], position) => {
    acc.push({
      id: upperCase(position),
      item: t(`settings.specialists.positions.${position}`),
      disabled: false,
    });
    return acc;
  }, []);
};

export const getErrorMessage = (err: ErrorDataType, t: TFunction<'translation', undefined>) => {
  const { statusCode, message } = err;
  if (statusCode === 409) {
    if (message.includes('email')) {
      const errorMessage = t(invalidHints[InvalidVariants.UsedEmail]);
      return {
        field: EditEmployeeFormFields.Email,
        error: errorMessage,
      };
    }
    if (message.includes('number')) {
      const errorMessage = t(invalidHints[InvalidVariants.UsedNumber]);
      return {
        field: EditEmployeeFormFields.Number,
        error: errorMessage,
      };
    }
    return false;
  }
  return false;
};
