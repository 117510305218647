import { useEffect, useState } from 'react';
import { apiRequest } from '../api/api';
import { CalendarEndpoints } from '../api/endpoints/endpoints';
import { AppointmentByIdResponse } from '../types';

export const useGetAppointmentById = (
  id?: string | null,
): [AppointmentByIdResponse | undefined, boolean, unknown, (data: AppointmentByIdResponse) => void] => {
  const [isLoading, setLoading] = useState(true);
  const [errorMessage, setErrorMessage] = useState<unknown>(null);
  const [data, setData] = useState<AppointmentByIdResponse>();

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      const [error, response] = await apiRequest.get({
        endpoint: CalendarEndpoints.AppointmentById,
        endpointParams: id,
      });
      if (error?.data) setErrorMessage(error.data);
      if (response?.data) setData(response.data);
      setLoading(false);
    };

    id && fetchData();
  }, [id]);

  return [data, isLoading, errorMessage, setData];
};
