import { ReactNode } from 'react';
import { Separator } from '@beauty/beauty-market-ui';
import { Box, Content, IconBox } from './style';

type Props = {
  title?: ReactNode;
  subtitle?: ReactNode;
  description?: ReactNode;
  caption?: ReactNode;
  label?: ReactNode;
  avatar?: ReactNode;
  icons?: ReactNode;
  separator?: boolean;
  onClick?: () => void;
  children?: ReactNode;
  m?: string;
  p?: string;
};

export const Widget = ({
  title,
  subtitle,
  caption,
  separator,
  label,
  description,
  avatar,
  icons,
  onClick,
  children,
  m = '0',
  p = '0',
}: Props) => (
  <>
    <Box m={m} p={p} cursor={onClick ? 'pointer' : 'auto'} onClick={onClick} available={!!icons}>
      {avatar}
      <Content placement="left">
        {title}
        {subtitle}
        {caption}
      </Content>
      {children}
      <Content placement="right">
        {label}
        {description}
      </Content>
      <IconBox>{icons}</IconBox>
    </Box>
    {separator && <Separator />}
  </>
);
