import { TFunction } from 'react-i18next';
import * as Yup from 'yup';

export enum FeedbackFormFields {
  Feedback = 'feedback',
}

export type FeedbackForm = {
  [FeedbackFormFields.Feedback]: string;
};

export const initialValues = {
  [FeedbackFormFields.Feedback]: '',
};

export const FeedbackFormValidationSchema = (t: TFunction<'translation', undefined>, score: number) =>
  Yup.object({
    [FeedbackFormFields.Feedback]:
      score < 3
        ? Yup.string()
            .min(5, t('validation.minLength5'))
            .max(1000, t('validation.maxLength1000'))
            .required(t('validation.notEmpty'))
        : Yup.string().min(5, t('validation.minLength5')).max(1000, t('validation.maxLength1000')),
  });
