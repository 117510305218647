import qs from 'query-string';
import { EndpointName } from './api.types';
import { ACCESS_TOKEN_KEY, SELECTED_LOCALE_KEY, COOKIES_ACCEPTED_KEY } from './constants';
import { endpointsWhitelist } from './endpoints/endpoints';

export const getAccessToken = () => localStorage.getItem(ACCESS_TOKEN_KEY);
export const setAccessToken = (token?: string) =>
  token ? localStorage.setItem(ACCESS_TOKEN_KEY, token) : localStorage.removeItem(ACCESS_TOKEN_KEY);

export const getSelectedLocale = () => localStorage.getItem(SELECTED_LOCALE_KEY) || 'en-US';
export const setSelectedLocale = (locale: string) => localStorage.setItem(SELECTED_LOCALE_KEY, locale);

export const getCookiesAccepted = () => localStorage.getItem(COOKIES_ACCEPTED_KEY)?.toLowerCase() === 'true';
export const setCookiesAccepted = (isAccepted: string) => localStorage.setItem(COOKIES_ACCEPTED_KEY, isAccepted);

export const isWhitelistAPI = (endpoint: EndpointName) =>  endpointsWhitelist.some((permittedEndpoints: any) => Object.values(permittedEndpoints).includes(endpoint));

export const expandEndpointByParams = <T>(params: T) => ({
  params,
  paramsSerializer: (serializedParams: T) =>
    // @ts-ignore
    qs.stringify(serializedParams, { arrayFormat: 'comma' }),
});
