import { ServiceSearch } from '@beauty/beauty-market-ui';
import { Banner } from '../Banner';
import { CRMHeaderContainer, CRMHeaderWrapper } from '../style';
import { HeaderTools } from './ServiceTools/HeaderTools';

export const CRMHeader = () => (
  <CRMHeaderContainer>
    <Banner />
    <CRMHeaderWrapper>
      <ServiceSearch disabled onlyService />
      <HeaderTools />
    </CRMHeaderWrapper>
  </CRMHeaderContainer>
);
