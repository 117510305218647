import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import isArray from 'lodash/isArray';
import isEmpty from 'lodash/isEmpty';
import { Button, Flex, H4, NotifyStatus, PlusIcon, TagButton, useNotify } from '@beauty/beauty-market-ui';
import { useMediaScreen } from 'hooks';
import { useGetUrl } from 'hooks/useGetUrl';
import { useStepper } from 'hooks/useStepper';
import LogoutImage from '../../../assets/exit-image.png';
import { DetailedInformation, MainInformation, Paper, Photos, Schedule, Stub, Tooltip } from '../../../components';
import { AccessRange } from '../../../constants';
import { checkAccess, getAddressNotifyContent } from '../../../helpers';
import { clearCurrentData } from '../../../store/actions/common';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import { selectAllAddresses, ThunkAddress } from '../../../store/redux-slices/addressSlice';
import { selectSubscriptionPlan } from '../../../store/redux-slices/organisationSlice';
import { selectSpecialist, ThunkSpecialist } from '../../../store/redux-slices/specialistSlice';
import { selectUserHead } from '../../../store/redux-slices/userSlice';
import { AddressAction, AddressFilterBy, PublishPayloadType } from '../../../types';
import { Wrapper } from '../style';
import { PublishAddress } from './components';
import { getAddressItems, showWarningMessages } from './helpers';

export const AddressList = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const admin = useAppSelector(selectSpecialist);
  const userHead = useAppSelector(selectUserHead);
  const allAddresses = useAppSelector(selectAllAddresses);
  const planSubscription = useAppSelector(selectSubscriptionPlan);
  const isSuperAdmin = checkAccess(AccessRange.SUPERADMIN);
  const notify = useNotify();

  const [filter, setFilter] = useState<AddressFilterBy>(AddressFilterBy.All);
  const [organisation, setOrganisation] = useState<{ id?: string; orgId?: string; name?: string } | null>(null);
  const [isSubmitting, setSubmitting] = useState(false);

  const { client } = useGetUrl(`/organisation/${organisation ? organisation?.orgId || organisation?.id : ''}`);

  const isLimitExceeded = isArray(allAddresses) && allAddresses.length >= (planSubscription?.orgLimit ?? 0);

  const { isDesktop, isMobile } = useMediaScreen('md');

  const title = [
    '',
    t('settings.addresses.stepper.main'),
    t('settings.addresses.stepper.detailed'),
    t('settings.addresses.stepper.schedule'),
    t('settings.addresses.stepper.about'),
  ];

  const { currentStep, StepperComponent, handleBackStep, handleNextStep, handleReset, handleFirstStep } = useStepper({
    title,
  });

  const handleAddAddressClick = () => {
    dispatch(clearCurrentData());
    handleFirstStep();
  };

  const handleCloseSidebar = () => {
    dispatch(clearCurrentData());
    handleReset();
  };

  const handleClosePopup = () => setOrganisation(null);

  const handlePublishAddress = () => {
    if (organisation) {
      setSubmitting(true);

      const params = {
        id: organisation?.orgId || organisation?.id || '',
        published: true,
      };

      dispatch(ThunkAddress.updatePublish(params))
        .unwrap()
        .then((res: PublishPayloadType) => {
          res.success
            ? notify(getAddressNotifyContent(NotifyStatus.SUCCESS, AddressAction.Publish, t))
            : showWarningMessages(res.publishedLog, notify, t);
        })
        .finally(() => {
          setSubmitting(false);
          setOrganisation(null);
        });
    }
  };
  const handleShowPreview = () => {
    if (organisation) window.open(client);
  };

  const addresses = isSuperAdmin ? allAddresses : admin.organization;
  const addressItems = getAddressItems(filter, addresses, setOrganisation, isDesktop);
  const emptyAddresses = isEmpty(addresses);
  const emptyAddressesItems = isEmpty(addressItems);
  const isAnyEmpty = emptyAddresses || emptyAddressesItems;

  const addButton = isSuperAdmin ? (
    <Tooltip id="add-new-address-button" content={t('validation.haveReachedOrgLimit')} show={isLimitExceeded}>
      <Button
        data-tooltip-id="add-new-address-button"
        size="small"
        onClick={handleAddAddressClick}
        disabled={isLimitExceeded}
        prefix={<PlusIcon width="16px" height="16px" />}
      >
        {isDesktop ? t('address.addAddress') : null}
      </Button>
    </Tooltip>
  ) : null;

  useEffect(() => {
    if (userHead) {
      isSuperAdmin
        ? dispatch(ThunkAddress.fetchAllAddresses(userHead.headOrgId))
        : dispatch(ThunkSpecialist.fetchSpecialist(userHead.id));
    }
  }, []);

  return (
    <>
      <Flex flexDirection="column" gap="1px" width="100%" maxWidth="1280px" mb="80px">
        <Paper
          alignItems={emptyAddresses && isMobile ? 'center' : 'start'}
          justifyContent="space-between"
          borderRadius={isDesktop ? '10px 10px 0 0' : '10px'}
          padding={isDesktop ? '24px' : '16px 8px'}
          width="100%"
          flexDirection="column"
          gap="16px"
        >
          <Flex alignItems="center" justifyContent="space-between" width="100%">
            <H4>{t('settings.addresses.title')}</H4>
            {addButton}
          </Flex>
          {!emptyAddresses && (
            <Flex width="100%" gap="8px">
              <TagButton
                text={t('address.all')}
                design={filter === AddressFilterBy.All ? 'active' : 'default'}
                onClick={() => setFilter(AddressFilterBy.All)}
              />
              <TagButton
                text={t('address.online')}
                design={filter === AddressFilterBy.Online ? 'active' : 'default'}
                onClick={() => setFilter(AddressFilterBy.Online)}
              />
              <TagButton
                text={t('address.offline')}
                design={filter === AddressFilterBy.Offline ? 'active' : 'default'}
                onClick={() => setFilter(AddressFilterBy.Offline)}
              />
            </Flex>
          )}
        </Paper>
        {isAnyEmpty && (
          <Wrapper delta={isDesktop ? 280 : 250} borderRadius={isAnyEmpty && isDesktop ? '0 0 10px 10px' : '10px'}>
            <Stub
              title={t('address.noAddresses')}
              subtitle={emptyAddresses ? t('address.addYourAddresses') : ''}
              src={LogoutImage}
              alt={t('logout.altImg')}
              margin="auto"
            >
              {emptyAddresses && (
                <Button
                  size="small"
                  onClick={handleAddAddressClick}
                  disabled={isLimitExceeded}
                  prefix={isDesktop ? <PlusIcon width="16px" height="16px" /> : null}
                >
                  {t('address.addAddress')}
                </Button>
              )}
            </Stub>
          </Wrapper>
        )}
        {addressItems}
      </Flex>
      {currentStep === 1 && (
        <MainInformation
          stepper={StepperComponent}
          handleClose={handleCloseSidebar}
          handleBackStep={handleCloseSidebar}
          handleNextStep={handleNextStep}
        />
      )}
      {currentStep === 2 && (
        <DetailedInformation
          stepper={StepperComponent}
          handleClose={handleCloseSidebar}
          handleBackStep={handleBackStep}
          handleNextStep={handleNextStep}
        />
      )}
      {currentStep === 3 && (
        <Schedule
          stepper={StepperComponent}
          handleBackStep={handleBackStep}
          handleNextStep={handleNextStep}
          handleClose={handleCloseSidebar}
        />
      )}
      {currentStep === 4 && (
        <Photos stepper={StepperComponent} handleClose={handleReset} handleBackStep={handleBackStep} />
      )}
      {organisation && (
        <PublishAddress
          title={t('address.publishOrganisation', { name: organisation.name })}
          onSubmit={handlePublishAddress}
          onClose={handleClosePopup}
          onShow={handleShowPreview}
          isLoading={isSubmitting}
        />
      )}
    </>
  );
};
