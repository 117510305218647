import { TFunction } from 'react-i18next';
import * as Yup from 'yup';
import { passwordReg } from '../../../constants';

export enum ChangePasswordFormFields {
  Old = 'oldPassword',
  New = 'newPassword',
  Repeat = 'repeatPassword',
}

export type ChangePasswordFormType = {
  [ChangePasswordFormFields.Old]: string;
  [ChangePasswordFormFields.New]: string;
  [ChangePasswordFormFields.Repeat]: string;
};

export const initialValues = {
  [ChangePasswordFormFields.Old]: '',
  [ChangePasswordFormFields.New]: '',
  [ChangePasswordFormFields.Repeat]: '',
};

export const validationSchema = (t: TFunction<'translation', undefined>) =>
  Yup.object({
    [ChangePasswordFormFields.Old]: Yup.string().required(t('validation.notEmpty')).min(8, t('validation.minPassword')),
    [ChangePasswordFormFields.New]: Yup.string()
      .required(t('validation.notEmpty'))
      .min(8, t('validation.minPassword'))
      .matches(passwordReg, t('validation.passwordHint'))
      .test('is-different', t('validation.passwordDifferent'), function compareOldAndNew(newPassword) {
        const oldPassword = this.parent[ChangePasswordFormFields.Old];
        if (oldPassword && newPassword) {
          return newPassword !== oldPassword;
        }
        return true;
      }),
    [ChangePasswordFormFields.Repeat]: Yup.string().test(
      'is-same',
      t('validation.passwordsMatch'),
      function compareRepeatAndNew(repeatPassword) {
        const newPassword = this.parent[ChangePasswordFormFields.New];
        if (repeatPassword && newPassword) {
          return newPassword === repeatPassword;
        }
        return false;
      },
    ),
  });
