import { useState } from 'react';
import { Nullable } from 'tsdef';
import { Loader } from 'components';
import { useGetInvoices } from 'hooks';
import { SubscribeActions, SubscriptionPlanType, SubscriptionStatusType } from 'types';
import { useAppSelector } from '../../../store/hooks';
import { selectActiveSubscription } from '../../../store/redux-slices/organisationSlice';
import { selectUserHead } from '../../../store/redux-slices/userSlice';
import { PageContainer } from '../style';
import { CurrentPlanPageActions, FinishTrialPage, Invoices, SectionHeader } from './components';
import { SubscriptionFilterBy } from './helpers';
import { useGetPayments } from './useGetPayments';

export const CurrentPlanPage = () => {
  const subscription = useAppSelector(selectActiveSubscription);
  const userHead = useAppSelector(selectUserHead);

  const [action, setAction] = useState<Nullable<SubscribeActions>>(null);
  const [filter, setFilter] = useState<SubscriptionFilterBy>(SubscriptionFilterBy.Plan);

  const isTrial = subscription?.plan.planType === SubscriptionPlanType.FREE;
  const { isLoading, invoices } = useGetInvoices(isTrial, userHead?.headOrgId);
  const { isLoading: isPolling } = useGetPayments(isTrial, subscription?.renewed, userHead?.headOrgId);

  if (isTrial && subscription.status === SubscriptionStatusType.PAST) {
    return <FinishTrialPage />;
  }

  return isLoading || isPolling ? (
    <Loader />
  ) : (
    <PageContainer>
      <SectionHeader filter={filter} setFilter={setFilter} setAction={setAction} />
      <Invoices items={invoices} />
      <CurrentPlanPageActions action={action} setAction={setAction} />
    </PageContainer>
  );
};
