import { ToolsIcon, BanknoteIcon, BellIcon } from '@beauty/beauty-market-ui';
import { RouterUrl } from '../../../routes/routes';

export const getSettings = t => [
  {
    id: 0,
    icon: <ToolsIcon width="20px" height="20px" />,
    // link: RouterUrl.OrganisationSettings,
    label: t('profile.companySettings'),
    subtitle: t('profile.dataAboutCompany'),
  },
  {
    id: 2,
    icon: <BanknoteIcon width="20px" height="20px" />,
    link: RouterUrl.CurrentPlanPage,
    label: t('subscriptions.paymentsAndPayouts'),
    subtitle: t('subscriptions.personalInformationAboutAccounts'),
  },
  {
    id: 3,
    icon: <BellIcon width="20px" height="20px" />,
    link: RouterUrl.NotificationSettings,
    label: t('profile.notificationSettings'),
    subtitle: t('profile.receiveNotifications'),
  },
];
