import { TFunction } from 'react-i18next';
import { formatPhoneNumber } from '../../helpers';
import { ProfileType } from '../../types/user';

export const getPersonalInfo = (profile: ProfileType, t: TFunction<'translation', undefined>) =>
  [
    {
      id: 'phone',
      label: t('profile.phone'),
      value: profile?.code && profile?.number ? formatPhoneNumber(profile.code, profile.number) : '',
      dir: 'ltr',
    },
    {
      id: 'email',
      label: t('profile.email'),
      value: profile?.email,
    },
  ].filter(item => item.value);
