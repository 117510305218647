import { Trans, useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';
import { H4, BodySmall, Button, Flex, colors } from '@beauty/beauty-market-ui';
import ReminderImage from '../../../assets/reminder.png';
import { transComponents } from '../../../constants';
import { RouterUrl } from '../../../routes/routes';
import { useAppSelector } from '../../../store/hooks';
import { selectActiveSubscription } from '../../../store/redux-slices/organisationSlice';
import { Paper } from '../style';

export const UnsubscribePage = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const subscription = useAppSelector(selectActiveSubscription);
  const date = subscription?.daysLeft ? moment().add(subscription?.daysLeft, 'days') : moment();

  return (
    <Paper flexDirection="column" borderRadius="10px" p="104px 24px" gap="24px" alignItems="center">
      <img src={ReminderImage} alt="reminder" width="240px" height="240px" />
      <Flex flexDirection="column" gap="16px" alignItems="center">
        <H4>{t('subscriptions.haveNotSubscription')}</H4>
        <BodySmall textAlign="center" color={colors.grey.standard}>
          <Trans components={transComponents}>
            {t('subscriptions.yourSubscriptionActiveUntil', { value: date.format('DD.MM.YYYY') })}
          </Trans>
        </BodySmall>
      </Flex>
      <Flex gap="16px">
        <Button design="secondary" size="medium" onClick={() => navigate(RouterUrl.AccountSetting)}>
          {t('subscriptions.goHome')}
        </Button>
        <Button size="medium" onClick={() => navigate(RouterUrl.ChangePlanPage)}>
          {t('subscriptions.upgradePlan')}
        </Button>
      </Flex>
    </Paper>
  );
};
