import { useLocation } from 'react-router-dom';
import {
  CRM_PRED_URL,
  CRM_TEST_URL,
  CRM_URL,
  MARKET_PRED_URL,
  MARKET_TEST_URL,
  MARKET_URL,
  urlWithLang,
} from '../constants';
import { getHost } from '../helpers/utils';

const getDomain = (prod, test, preprod) => {
  const host = getHost();
  if (host.includes('nolink') || host.includes('localhost')) {
    return test;
  }
  if (host.includes('dev')) {
    return preprod;
  }

  return prod;
};

const getDefaultUrlWithLang = domain => {
  const { state } = useLocation();
  const suffix = state && state.type !== 'login' ? '/signup' : '';
  const urlWithSuffix = `${domain}/login${suffix}`;
  return urlWithLang(urlWithSuffix);
};

export const useGetUrl = (path = '') => {
  const clientDomain = getDomain(MARKET_URL, MARKET_TEST_URL, MARKET_PRED_URL);
  const businessDomain = getDomain(CRM_URL, CRM_TEST_URL, CRM_PRED_URL);

  const clientUrl = path ? urlWithLang(`${clientDomain}${path}`) : getDefaultUrlWithLang(clientDomain);
  const businessUrl = path ? urlWithLang(`${businessDomain}${path}`) : getDefaultUrlWithLang(businessDomain);

  return {
    client: clientUrl,
    business: businessUrl,
  };
};
