import { useTranslation } from 'react-i18next';
import { Button, EditIcon, PlusIcon } from '@beauty/beauty-market-ui';

type EditButtonPropsType = {
  edit: boolean;
  onClick: () => void;
  disabled?: boolean;
};

const EditButton = ({ edit, onClick, disabled = false }: EditButtonPropsType) => {
  const { t } = useTranslation();
  return edit ? (
    <Button
      prefix={<EditIcon height="16px" width="16px" />}
      size="extraSmall"
      design="secondary"
      onClick={onClick}
      disabled={disabled}
    >
      {t('button.edit')}
    </Button>
  ) : (
    <Button prefix={<PlusIcon height="16px" width="16px" />} size="extraSmall" onClick={onClick} disabled={disabled}>
      {t('button.add')}
    </Button>
  );
};

export default EditButton;
