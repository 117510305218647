import { useTranslation } from 'react-i18next';
import isEmpty from 'lodash/isEmpty';
import { BodySmall, Caption, Flex, H3, H4, Link, getMinWidthMediaQuery, useMediaQuery } from '@beauty/beauty-market-ui';
import { Paper } from 'components';
import { Adaptive } from 'hoc';
import { useAppSelector } from 'store/hooks';
import { selectAllAddresses } from 'store/redux-slices/addressSlice';
import { selectAllServices } from 'store/redux-slices/serviceSlice';
import { selectAllSpecialists } from 'store/redux-slices/specialistSlice';
import { selectUser } from 'store/redux-slices/userSlice';
import { stringForSendSupportEmail, SUPPORT_EMAIL } from '../../../constants';

export const WelcomeSection = () => {
  const { t } = useTranslation();
  const { user } = useAppSelector(selectUser);

  const allAddress = useAppSelector(selectAllAddresses) || [];
  const allServices = useAppSelector(selectAllServices) || [];
  const allSpecialists = useAppSelector(selectAllSpecialists) || [];

  const mediaQuery = getMinWidthMediaQuery('md');
  const isDesktop = useMediaQuery(mediaQuery);

  const isSettingComplete = !isEmpty(allAddress) && !isEmpty(allServices) && !isEmpty(allSpecialists);

  return (
    <Paper flexDirection="column" gap="8px" p={isDesktop ? '41px 24px 33px' : '24px 8px 16px'} borderRadius="10px">
      <Adaptive desktop={H3} mobile={H4}>{`${t('settings.hello.title')}, ${user.name}!`}</Adaptive>
      {isSettingComplete ? (
        <>
          <BodySmall>{t('settings.hello.forUsageCRM')}</BodySmall>
          <Flex alignItems="center" gap="0 8px" flexWrap="wrap">
            <BodySmall>{t('settings.hello.ifYouHaveQuestions')}</BodySmall>
            <Link href={stringForSendSupportEmail} size="s">
              {SUPPORT_EMAIL}
            </Link>
          </Flex>
        </>
      ) : (
        <Adaptive desktop={BodySmall} mobile={Caption}>
          {t('settings.hello.shortDescription')}
        </Adaptive>
      )}
    </Paper>
  );
};
