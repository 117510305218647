import { AxiosError, AxiosResponse } from 'axios';

type TestProps = {
  payload: any;
  success: boolean;
} & AxiosResponse;
export class Unavailable {
  public resolve = (response: TestProps) => response;
  
  public reject = async (error: AxiosError) => {
    if (error.message === 'Network Error') {
      error.response = {
        // @ts-ignore
        headers: 'NetworkError',
        // @ts-ignore
        config: {},
        status: 418,
        statusText: '',
        data: 'Network error. Cannot connect to the server.',
      };
    }

    return Promise.reject(error);
  };
}
