import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import { Button } from '@beauty/beauty-market-ui';
import { setAccessToken } from 'api/api.helpers';
import { loginByPhone } from 'helpers/loginByPhone';
import { loginPhoneVerification } from 'helpers/loginPhoneVerification';
import { getInvalidType } from 'helpers/utils';
import { useGetSecondsLeft } from 'hooks/useGetSecondsLeft';
import useRedirectAfterLogin from 'hooks/useRedirectAfterLogin';
import { clearAccountState } from 'store/actions/common';
import { useAppDispatch } from 'store/hooks';
import { setLogin } from 'store/redux-slices/authSlice';
import { updateProfile, updateSmsCodeTime, updateUser } from 'store/redux-slices/userSlice';
import { InvalidVariants } from '../../../constants';
import { initialSmsCodeValues, LoginSmsCodeForm, loginSMSCodeFormValidationSchema } from '../Login.definitions';
import { SmsForm } from './SmsForm';

const SMSCodeForm = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { state } = useLocation();
  const dispatch = useAppDispatch();

  const { number, code } = state;
  const [invalidType, setInvalidType] = useState<InvalidVariants | null>(null);
  const [isDefaultValue, setDefaultValue] = useState(true);

  const secondsLeft = useGetSecondsLeft();

  const onFormSubmit = useCallback(
    async (data: LoginSmsCodeForm) => {
      dispatch(clearAccountState());
      const values = { code, number, otp: data.smmcode };
      const response = await loginByPhone(values);

      if (response.success) {
        setInvalidType(null);
        const { email, name, id, avatarUrl, headOrgSpecialist, ...rest } = response.data;
        setAccessToken(response.token);
        dispatch(updateUser({ name, email, userId: id, avatarUrl, headOrgSpecialist }));
        dispatch(updateProfile({ id, name, email, avatarUrl, ...rest }));
        dispatch(setLogin(true));
        useRedirectAfterLogin(navigate, !!headOrgSpecialist);
      } else setInvalidType(getInvalidType(response.statusCode || response.status, 'codeVerification'));
    },
    [number, code, invalidType],
  );

  const handleResendClick = async () => {
    setInvalidType(null);
    const phoneVer = { code, number };
    const response = await loginPhoneVerification(phoneVer);
    response && dispatch(updateSmsCodeTime(new Date().getTime()));
  };

  const formikContextValue = {
    initialValues: initialSmsCodeValues,
    validationSchema: loginSMSCodeFormValidationSchema(t),
    onSubmit: onFormSubmit,
    validateOnMount: true,
  };

  return (
    <>
      <SmsForm
        formikContextValue={formikContextValue}
        invalidType={invalidType}
        setInvalidType={setInvalidType}
        isDefaultValue={isDefaultValue}
      />
      <Button
        disabled={secondsLeft}
        design="quaternary"
        mt="16px"
        width="100%"
        size="large"
        onClick={() => {
          handleResendClick();
          setDefaultValue(true);
        }}
      >
        {`${t(`registration.resendCode`)}${secondsLeft ? `${` 00:`}${`0${secondsLeft}`.slice(-2)}` : ``}`}
      </Button>
    </>
  );
};

export default SMSCodeForm;
