import { useEffect, useMemo, useState } from 'react';
import { Views } from 'react-big-calendar';
import isEmpty from 'lodash/isEmpty';
import { Nullable } from 'tsdef';
import { Avatar, colors, Div, Flex, MembersList } from '@beauty/beauty-market-ui';
import { checkAccess } from 'helpers';
import { isHebrew } from 'helpers/utils';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { selectAllAddresses } from 'store/redux-slices/addressSlice';
import { selectAppointments, setSelectedAddress, setView } from 'store/redux-slices/appointmentsSlice';
import { selectSpecialist } from 'store/redux-slices/specialistSlice';
import { AccessRange } from '../../../constants';
import { getAllAddressOptions, getSpecialistAddressOptions } from '../CalendarHeader/helpers';
import { MobileFilterBySpecialist } from '../CalendarHeader/MobileFilters/MobileFilterBySpecialist';
import { MobileFilters } from '../CalendarHeader/MobileFilters/MobileFilters';
import { OptionsMenu } from '../CalendarHeader/OptionsMenu';
import { DayKebabMenu } from '../KebabMenu/DayKebabMenu';
import { WeekKebabMenu } from '../KebabMenu/WeekKebabMenu';
import { MobileCalendarMenu } from '../MobileCalendarMenu/MobileCalendarMenu';
import { CardWrapper, InfoWrapper, StyledCaption, StyledH5 } from './style';

type WidgetHeaderProps = {
  date: string;
  title: string;
  text: string;
  onAddBreak: () => void;
  onAddSpecialistBreak: (specId: string) => void;
  onSelectDate: (date: string) => void;
  onCancelSpecialistAll: (orgSpecId: string) => void;
  onCancelAll: () => void;
  view?: Views;
  avatarUrl?: string | null;
  specialistName?: string;
  isToday?: boolean;
  specId?: Nullable<string>;
  urls?: Array<Nullable<string>>;
  isMobile?: boolean;
  forAgenda?: boolean;
};

export const WidgetHeader = ({
  date,
  title,
  text,
  onAddBreak,
  onAddSpecialistBreak,
  onSelectDate,
  onCancelSpecialistAll,
  onCancelAll,
  avatarUrl,
  specialistName,
  view = Views.DAY,
  isToday,
  specId,
  urls,
  isMobile = false,
  forAgenda,
}: WidgetHeaderProps) => {
  const dispatch = useAppDispatch();

  const isAvatar =
    view === Views.DAY || view === Views.AGENDA || (isMobile && (view === Views.WEEK || view === Views.MONTH));
  const textColor = isToday ? colors.blue.standard : colors.black.standard;

  const [isMenu, setMenu] = useState(false);
  const [isFilter, setFilter] = useState(false);
  const [isSpecialistFilter, setSpecialistFilter] = useState(false);
  const { selectedSpecialist, specialistIds } = useAppSelector(selectAppointments);

  const isSuperAdmin = checkAccess(AccessRange.SUPERADMIN);
  const specialist = useAppSelector(selectSpecialist);
  const allAddress = useAppSelector(selectAllAddresses);
  const { selectedAddress } = useAppSelector(selectAppointments);

  const addressOptions = useMemo(
    () => (isSuperAdmin ? getAllAddressOptions(allAddress) : getSpecialistAddressOptions(specialist.organization)),
    [isSuperAdmin, allAddress, specialist.organization],
  );

  const kebabProps = {
    date,
    onAddBreak,
    onSelectDate,
    onCancelAll,
  };

  const defaultView = selectedSpecialist ? Views.DAY : Views.WEEK;

  const handleListClick = () => {
    dispatch(setView(view === Views.AGENDA ? defaultView : Views.AGENDA));
  };

  useEffect(() => {
    if (!isEmpty(addressOptions) && !selectedAddress) {
      dispatch(setSelectedAddress(addressOptions[0].id));
    }
  }, [addressOptions, selectedAddress]);

  return (
    <CardWrapper
      forAgenda={forAgenda}
      onClick={e => {
        if (isMobile) {
          e.preventDefault();
          e.stopPropagation();
        }
      }}
    >
      {urls ? (
        <MembersList
          urls={urls}
          onClick={e => {
            e.stopPropagation();
            e.preventDefault();
            setSpecialistFilter(true);
          }}
        />
      ) : (
        <Flex
          width="85%"
          gap={isAvatar ? '10px' : 'auto'}
          onClick={() => (isMobile && specialistIds.length > 1 ? setSpecialistFilter(true) : null)}
        >
          <Div>{isAvatar ? <Avatar size="xs" url={avatarUrl} name={specialistName} /> : null}</Div>
          <InfoWrapper>
            <StyledCaption width={isAvatar ? '93%' : undefined} color={textColor} isHebrew={isHebrew(title)} truncated>
              {title}
            </StyledCaption>
            <StyledH5 width={isAvatar ? '93%' : undefined} color={textColor} isHebrew={isHebrew(text)} truncated>
              {text}
            </StyledH5>
          </InfoWrapper>
        </Flex>
      )}
      {!!specId && !isMobile ? (
        <DayKebabMenu
          {...kebabProps}
          specId={specId}
          onAddSpecialistBreak={onAddSpecialistBreak}
          onCancelSpecialistAll={onCancelSpecialistAll}
        />
      ) : (
        (!specId && !isMobile && <WeekKebabMenu {...kebabProps} />) || (
          <MobileCalendarMenu
            view={view}
            onClickFilters={() => setFilter(true)}
            onClickList={handleListClick}
            onClickMenu={() => setMenu(prev => !prev)}
          />
        )
      )}
      {isMenu && isMobile && (
        <OptionsMenu
          onAddBreak={onAddBreak}
          onCancelAll={onCancelAll}
          onSelectDate={onSelectDate}
          isOpen={isMenu}
          onClose={() => setMenu(false)}
        />
      )}
      {isSpecialistFilter && isMobile && (
        <MobileFilterBySpecialist isReset={false} onClose={() => setSpecialistFilter(false)} />
      )}
      {isFilter && isMobile && (
        <MobileFilters isReset={false} onClose={() => setFilter(false)} addressOptions={addressOptions} />
      )}
    </CardWrapper>
  );
};
