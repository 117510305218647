import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { generatePath, useParams } from 'react-router';
import { Outlet, useNavigate } from 'react-router-dom';
import { Nullable } from 'tsdef';
import { Button, Flex, H4, Slider } from '@beauty/beauty-market-ui';
import { Loader } from 'components';
import { isRtl } from 'helpers/utils';
import { useMediaScreen } from 'hooks';
import { RouterUrl } from 'routes';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { ActionClient, ThunkClient } from 'store/redux-slices/clientSlice';
import { selectAllAddresses } from '../../store/redux-slices/addressSlice';
import { setSelectedAddress, setSelectedClient } from '../../store/redux-slices/appointmentsSlice';
import { ClientCoverStack, ClientPopup } from './components';
import { clientsTabs } from './helper';
import { PageHeader, TabItem, TabSection } from './style';

export const ClientPage = () => {
  const { t } = useTranslation();
  const { isDesktop, isMobile } = useMediaScreen('md');
  const { id } = useParams();
  const rtl = isRtl();

  const allAddresses = useAppSelector(selectAllAddresses);

  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const [isLoading, setLoading] = useState(true);
  const [isOpen, setOpen] = useState(false);
  const [address, setAddress] = useState<Nullable<{ id: string }>>(null);

  const handleSubmit = () => {
    id && dispatch(setSelectedClient(id));
    address && dispatch(setSelectedAddress(address.id));
    navigate(RouterUrl.Calendar);
  };

  const handleOpen = () => (allAddresses.length === 1 ? handleSubmit() : setOpen(true));
  const handleClose = () => setOpen(false);

  const slides = clientsTabs(t).map(tab => (
    <TabItem key={tab.title} text={tab.title} to={generatePath(tab.route, { id: id || null })} />
  ));

  useEffect(() => {
    setLoading(true);
    dispatch(ThunkClient.fetchClientInfo(id))
      .unwrap()
      .finally(() => setLoading(false));
    return () => {
      dispatch(ActionClient.resetClient());
    };
  }, [id, dispatch]);

  return isLoading ? (
    <Loader />
  ) : (
    <>
      <Flex width="100%" flexDirection="column">
        {isMobile && <ClientCoverStack />}
        <PageHeader>
          <H4>{t('clients.profile.clientPage')}</H4>
          <Button size="small" design="secondary" onClick={handleOpen}>
            {t('clients.profile.bookForClient')}
          </Button>
        </PageHeader>
        <TabSection>
          <Slider inlineSlider slides={slides} marginBottom="0" rtl={rtl} />
        </TabSection>
        <Outlet />
        {isOpen && (
          <ClientPopup
            value={address}
            onSubmit={handleSubmit}
            onChange={setAddress}
            onClose={handleClose}
            info=""
            isLoading={false}
          />
        )}
      </Flex>
      {isDesktop && <ClientCoverStack />}
    </>
  );
};
