import { useCallback, useState } from 'react';
import { useMediaScreen } from '../../hooks';
import { LogoutSidebar, Menu, MobileMenu } from './components';
import { CutWrapper, FullWrapper, NavbarWrapper } from './style';

export const Navbar = () => {
  const { isDesktop } = useMediaScreen('md');

  const [isOpen, setOpen] = useState(false);

  const handleOpen = useCallback(() => setOpen(true), []);
  const handleClose = useCallback(() => setOpen(false), []);

  return (
    <>
      <NavbarWrapper
        isOpen={isOpen}
        onMouseOver={isDesktop ? handleOpen : undefined}
        onMouseOut={isDesktop ? handleClose : undefined}
      >
        <FullWrapper isOpen={isOpen}>
          <Menu isFull onClose={isDesktop ? undefined : handleClose} />
        </FullWrapper>
        <CutWrapper isOpen={isOpen}>
          <Menu />
        </CutWrapper>
      </NavbarWrapper>
      <MobileMenu isOpen={isOpen} onOpen={handleOpen} />
      <LogoutSidebar />
    </>
  );
};
