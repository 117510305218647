import { BodySmall, Checkbox, Flex } from '@beauty/beauty-market-ui';
import { ServiceCardProps } from '../types';
import { ServiceCardWrapper } from './style';

export const ServiceCard = ({ id, title, description, selectedServices, onChange }: ServiceCardProps) => {
  const serviceChecked = selectedServices.includes(id);

  const handleServiceClick = () => onChange([id]);

  return (
    <ServiceCardWrapper>
      <Flex flexDirection="column" gap="4px" alingItems="start">
        <BodySmall>{title}</BodySmall>
        {/* <BodySmall color={colors.grey.dark}>{serviceAvailableFor}</BodySmall> */}
      </Flex>
      <Checkbox checked={serviceChecked} shape="round" onCheckboxClick={handleServiceClick} />
    </ServiceCardWrapper>
  );
};
