import { ReactNode } from 'react';
import {
  Avatar,
  AvatarSizesKey,
  BodyLarge,
  BodySmall,
  Button,
  Caption,
  colors,
  Flex,
  H6,
  Separator,
} from '@beauty/beauty-market-ui';
import { Adaptive } from 'hoc';
import { ContactInfo, Container } from '../style';

export type Props = {
  icon: ReactNode;
  heading: string;
  subtitle: string;
  onClick?: () => void;
  avatar?: string | null | File;
  avatarSize?: AvatarSizesKey;
  isMobile?: boolean;
};

export const ClientCard = ({ heading, subtitle, onClick, icon, avatar, isMobile, avatarSize = 'xs' }: Props) => (
  <>
    <Container onClick={onClick} width="100%" justifyContent="space-between">
      <Flex alignItems="start" gap="8px">
        <Avatar size={avatarSize} url={avatar || undefined} anonymos="default" />
        <Flex flexDirection="column" aligItems="start">
          <Adaptive desktop={H6} mobile={BodyLarge} large={isMobile}>
            {heading}
          </Adaptive>
          <ContactInfo desktop={BodySmall} mobile={Caption} color={colors.grey.dark} dir="ltr">
            {subtitle}
          </ContactInfo>
        </Flex>
      </Flex>
      <Button design="tertiaryBlack" prefix={icon} justyfySelf="end" p="10px" />
    </Container>
    <Separator ml="56px" />
  </>
);
