import { TFunction } from 'react-i18next';
import * as Yup from 'yup';
import {
  compareBreakEndAndNow,
  compareBreakEndAndStart,
  compareBreakStartAndEnd,
  compareBreakStartAndNow,
} from '../helpers';

interface InitialValuesProps {
  date: string;
  specialistId: string;
  start?: string;
  end?: string;
}

export enum BreakFormFields {
  BreakDate = 'date',
  Specialist = 'orgSpecialistId',
  Start = 'start',
  End = 'end',
}

export type BreakForm = {
  [BreakFormFields.BreakDate]: string;
  [BreakFormFields.Specialist]: string;
  [BreakFormFields.Start]: string;
  [BreakFormFields.End]: string;
};

export const getInitialValues = ({ date, specialistId, start, end }: InitialValuesProps) => ({
  [BreakFormFields.BreakDate]: date,
  [BreakFormFields.Specialist]: specialistId || '',
  [BreakFormFields.Start]: start || '',
  [BreakFormFields.End]: end || '',
});

export const AddBreakValidationSchema = (isDayOff: boolean, t: TFunction<'translation', undefined>) =>
  Yup.object({
    [BreakFormFields.Specialist]: Yup.string().trim().required(t('validation.notEmpty')),
    [BreakFormFields.Start]: isDayOff
      ? Yup.string()
      : Yup.string()
          .required(t('validation.notEmpty'))
          .test('is-start-more-than-current', t('validation.irrelevantTime'), compareBreakStartAndNow)
          .test('is-start-more-than-end', t('validation.irrelevantTime'), compareBreakStartAndEnd),
    [BreakFormFields.End]: isDayOff
      ? Yup.string()
      : Yup.string()
          .required(t('validation.notEmpty'))
          .test('is-end-more-than-current', t('validation.irrelevantTime'), compareBreakEndAndNow)
          .test('is-start-more-than-end', t('validation.irrelevantTime'), compareBreakEndAndStart),
  });
