import { TFunction } from 'react-i18next';
import { usePluralForm } from 'hooks';
import { SubscriptionDataType, SubscriptionPlanType } from 'types';

export const getTariffPlans = (subscriptionPlans: SubscriptionDataType[], t: TFunction<'translation', undefined>) => {
  const enterprise = {
    id: SubscriptionPlanType.ENTERPRISE,
    planType: SubscriptionPlanType.ENTERPRISE,
    limit: null,
    price: {},
    features: [t('subscriptions.unlimitedEmployees'), t('subscriptions.unlimitedPlaces')],
    additional: [
      t('subscriptions.commissionForBookings'),
      t('subscriptions.commissionForPayments'),
      t('subscriptions.commissionForRepeat'),
    ],
  };

  const basePlans = subscriptionPlans.map(plan => ({
    ...plan,
    features: [usePluralForm('subscriptions.addresses', plan.limit), t('subscriptions.freeEmployee')],
    additional: [
      t('subscriptions.commissionForBookings'),
      t('subscriptions.commissionForPayments'),
      t('subscriptions.commissionForRepeat'),
    ],
  }));

  return [...basePlans, enterprise];
};

export const getTableData = (t: TFunction<'translation', undefined>) => ({
  tableHead: [
    t('subscriptions.features'),
    SubscriptionPlanType.BASIC,
    SubscriptionPlanType.PRO,
    SubscriptionPlanType.BUSINESS,
    SubscriptionPlanType.ENTERPRISE,
  ],
  tableRows: [
    { label: t('subscriptions.numberOfAddresses'), values: ['1', '3', '5', t('subscriptions.unlimited')] },
    { label: t('subscriptions.photosNumber'), values: ['5', '10', '15', t('subscriptions.unlimited')] },
    { label: t('subscriptions.onlineBooking'), values: [true, true, true, true] },
    { label: t('subscriptions.emailNotifications'), values: [true, true, true, true] },
    { label: t('subscriptions.clientFeedback'), values: [true, true, true, true] },
    { label: t('subscriptions.telegramNotifications'), values: [false, true, true, true] },
    {
      label: t('subscriptions.whatsApp'),
      values: [false, false, true, true],
    },
    { label: t('subscriptions.videoOfSalon'), values: [false, false, false, true] },
    { label: t('subscriptions.advancedReports'), values: [false, false, false, true] },
    { label: t('subscriptions.personalManager'), values: [false, false, false, true] },
  ],
});
