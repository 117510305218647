import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { BodySmall, colors, Icon, MoreIcon } from '@beauty/beauty-market-ui';
import { isRtl } from 'helpers';
import { languageOptions } from '../../Footer/const';
import { BlockSeparator, FooterWrapper, LanguageItem, LanguageList } from '../style';
import { MenuProps } from '../types';

export const MenuFooter = ({ isFull }: MenuProps) => {
  const { t, i18n } = useTranslation();
  const rtl = isRtl();

  const [isOpen, setIsOpen] = useState(false);
  const selectedLang = languageOptions(t).find(item => item.sortLan === i18n.resolvedLanguage);

  const handleClick = () => setIsOpen(!isOpen);
  const handleChange = async (language: string) => {
    await i18n.changeLanguage(language);
    setIsOpen(false);
  };

  return (
    <FooterWrapper isMenuOpen={isFull} isOpen={isOpen} onMouseLeave={() => setIsOpen(false)}>
      <LanguageList isOpen={isOpen}>
        <BlockSeparator show={isOpen} />
        {languageOptions(t).map(item => (
          <LanguageItem key={item.language} onClick={() => handleChange(item.sortLan)} event={isOpen}>
            <Icon width="24px" height="24px">
              {item.flag}
            </Icon>
            {isFull && <BodySmall color={colors.grey.standard}>{item.language}</BodySmall>}
          </LanguageItem>
        ))}
      </LanguageList>
      <BlockSeparator show={!isOpen} />
      <LanguageItem onClick={handleClick} event>
        <Icon width="24px" height="24px">
          {selectedLang?.flag}
        </Icon>
        {isFull && (
          <>
            <BodySmall color={colors.grey.standard} m={rtl ? '0 0 0 auto' : '0 auto 0 0'}>
              {selectedLang?.language}
            </BodySmall>
            <Icon stroke={colors.grey.standard}>
              <MoreIcon />
            </Icon>
          </>
        )}
      </LanguageItem>
    </FooterWrapper>
  );
};
