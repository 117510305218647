import styled from 'styled-components';
import { Flex } from '@beauty/beauty-market-ui';

export const BorderedPaper = styled(Flex)`
  flex-direction: column;
  width: 100%;
  gap: 16px;
  padding: 16px;
  border-radius: 10px;

  box-sizing: border-box;
  background-color: ${({ theme }) => theme.colors.white.standard};
  border: ${({ theme }) => `1px solid ${theme.colors.grey.standard}`};
`;
