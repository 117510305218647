import { useTranslation } from 'react-i18next';
import { H6 } from '@beauty/beauty-market-ui';
import { FormikInput } from 'components/functional/formik/formik-input/FormikInput';
import { AppointmentFormFields } from '../AppointmentSidebar.definitions';

type Props = {
  sign: string;
};

export const NegotiatedPriceInput = ({ sign }: Props) => {
  const { t } = useTranslation();
  const { Price } = AppointmentFormFields;

  return (
    <>
      <H6 mt="8px">{t('calendar.newAppointmentSidebar.details.negotiatedPrice')}</H6>
      <FormikInput
        id={Price}
        name={Price}
        placeholder={t('calendar.newAppointmentSidebar.details.price')}
        design="white"
        focused
        fixedPart={sign}
      />
    </>
  );
};
