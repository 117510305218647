import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FormikProps } from 'formik';
import { Flex } from '@beauty/beauty-market-ui';
import { SidebarFooter } from 'components/SidebarFooter/SidebarFooter';
import { AsyncComponent } from 'hoc';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { selectGeolocation, selectNewAddress, setSave, ThunkAddress } from 'store/redux-slices/addressSlice';
import { getSelectedLanguage } from '../../../constants';
import { SidebarSheet } from '../../SidebarSheet';
import { Overlay } from '../style';
import { DetailedInformationFormType } from './DetailedInformation.definitions';
import { DetailedInformationForm } from './DetailedInformationForm';
import { useGeolocation } from './useGeolocation';

export const DetailedInformation = ({ handleClose, handleBackStep, handleNextStep, stepper }) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const language = getSelectedLanguage();
  const { id } = useAppSelector(selectNewAddress);
  const { lng, lat } = useAppSelector(selectGeolocation);
  const { askedLocation } = useGeolocation(!!id);
  const [isValid, setIsValid] = useState(false);
  const [isDirty, setIsDirty] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const formRef = useRef<FormikProps<DetailedInformationFormType>>();

  const handleSubmit = async () => {
    await formRef.current?.submitForm();
    if (id && isDirty) dispatch(setSave(true));
    handleNextStep();
  };

  const footer = <SidebarFooter disabled={!isValid} onBack={handleBackStep} onSubmit={handleSubmit} />;
  const content = <DetailedInformationForm ref={formRef} setIsValid={setIsValid} setIsDirty={setIsDirty} />;

  useEffect(() => {
    if (!id && lng && lat) {
      setLoading(true);
      dispatch(ThunkAddress.fetchAddress({ lat, lng, language })).finally(() => setLoading(false));
    }
  }, [askedLocation]);

  if (!askedLocation) {
    return <Overlay />;
  }

  return (
    <SidebarSheet
      onBack={handleBackStep}
      onClose={handleClose}
      FooterBody={footer}
      label={t('settings.addresses.sidebar.workplace')}
      descriptor={t('settings.addresses.sidebar.descriptor')}
    >
      <AsyncComponent isLoading={isLoading}>
        <Flex flexDirection="column" gap="16px">
          {stepper}
          {content}
        </Flex>
      </AsyncComponent>
    </SidebarSheet>
  );
};
