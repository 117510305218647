import { useEffect, useState } from 'react';
import { useAppDispatch } from '../../../store/hooks';
import { setGeolocation } from '../../../store/redux-slices/addressSlice';

export const useGeolocation = (ask: boolean) => {
  const [askedLocation, setAskedLocation] = useState(ask);
  const dispatch = useAppDispatch();

  const handleError = () => {
    dispatch(setGeolocation({ lat: null, lng: null }));
    setAskedLocation(true);
  };

  const askGeolocation = () =>
    navigator.geolocation.getCurrentPosition(
      position => {
        const { latitude, longitude } = position.coords;
        dispatch(setGeolocation({ lat: latitude, lng: longitude }));
        setAskedLocation(true);
      },
      () => handleError(),
    );

  useEffect(() => {
    if (!askedLocation) {
      navigator.geolocation ? askGeolocation() : handleError();
    }
  }, []);

  return {
    askedLocation,
  };
};
