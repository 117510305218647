import { useState } from 'react';
import { Nullable } from 'tsdef';
import { useRequest } from 'hooks';
import { useAppSelector } from 'store/hooks';
import { selectSpecialist, selectSpecialistInfo, ThunkSpecialist } from 'store/redux-slices/specialistSlice';
import { SpecialistAction, SpecialistLanguage, SpokenLanguage } from 'types';

export const useLanguages = () => {
  const { id } = useAppSelector(selectSpecialist);
  const { spokenLanguage } = useAppSelector(selectSpecialistInfo);

  const [action, setAction] = useState(SpecialistAction.None);
  const [selected, setSelected] = useState<Nullable<SpecialistLanguage>>(null);

  const addLanguage = useRequest(ThunkSpecialist.editSpokenLanguage, SpecialistAction.AddLanguage);
  const editLanguage = useRequest(ThunkSpecialist.editSpokenLanguage, SpecialistAction.EditLanguage);
  const deleteLanguage = useRequest(ThunkSpecialist.editSpokenLanguage, SpecialistAction.DeleteLanguage);

  const onAdd = () => {
    setAction(SpecialistAction.AddLanguage);
    setSelected(null);
  };

  const onEdit = (item: SpecialistLanguage) => {
    setAction(SpecialistAction.EditLanguage);
    setSelected(item);
  };

  const onDelete = (item: SpecialistLanguage) => {
    setAction(SpecialistAction.DeleteLanguage);
    setSelected(item);
  };

  const onClose = () => {
    setAction(SpecialistAction.None);
    setSelected(null);
  };

  const onSubmit = async (data?: SpokenLanguage) => {
    let languages = spokenLanguage.map(item => ({ language: item.language, level: item.level }));
    if (action === SpecialistAction.AddLanguage && data) {
      languages = [...languages, data];
      await addLanguage({ id, languages });
    }

    if (action === SpecialistAction.EditLanguage && selected && data) {
      languages = languages.map(item =>
        item.language === selected.language ? { language: data.language, level: data.level } : item,
      );
      await editLanguage({ id, languages });
    }

    if (action === SpecialistAction.DeleteLanguage && selected) {
      languages = languages.filter(item => item.language !== selected.language);
      await deleteLanguage({ id, languages });
    }

    onClose();
  };

  return {
    onAdd,
    onEdit,
    onDelete,
    onSubmit,
    onClose,
    action,
    selected,
    current: spokenLanguage,
  };
};
