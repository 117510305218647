import { MouseEvent, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import isEmpty from 'lodash/isEmpty';
import { Button, H3, H4, PlusIcon, Scroll } from '@beauty/beauty-market-ui';
import LogoutImage from 'assets/exit-image.png';
import { DeleteService, EditService, ServiceItem, ServiceStep, Stub } from 'components';
import { getFormattedCurrency, getTranslation } from 'helpers';
import { Adaptive } from 'hoc';
import { useMediaScreen, useStepper } from 'hooks';
import { getSelectedLanguage } from '../../../constants';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import { selectHead } from '../../../store/redux-slices/organisationSlice';
import { clearNewService, selectAllServices, ThunkService } from '../../../store/redux-slices/serviceSlice';
import { Head, ServiceListContent, Wrapper } from './style';

export const ServiceList = () => {
  const { t } = useTranslation();
  const { isDesktop } = useMediaScreen('md');
  const { id, currency } = useAppSelector(selectHead);
  const allServices = useAppSelector(selectAllServices);

  const language = getSelectedLanguage();
  const dispatch = useAppDispatch();

  const [isOpenEditBar, setOpenEditBar] = useState(false);
  const [serviceId, setServiceId] = useState<string | null>(null);

  const serviceItems = useMemo(() => {
    if (!Array.isArray(allServices)) return [];
    return allServices.map((item, index, arr) => {
      const handleServiceClick = async (e: MouseEvent<HTMLDivElement>) => {
        e.stopPropagation();
        await dispatch(ThunkService.fetchServiceById(item.id));
        setOpenEditBar(true);
      };

      const handleDeleteClick = (e: MouseEvent<HTMLDivElement>) => {
        e.stopPropagation();
        setServiceId(item.id);
      };

      const price =
        item.listPrice !== null
          ? getFormattedCurrency(item.listPrice, currency, language)
          : t('settings.services.nullPrice');

      return (
        <ServiceItem
          key={item.id}
          price={price}
          name={item.title.text}
          onDelete={handleDeleteClick}
          onClick={handleServiceClick}
          category={item.category ? getTranslation(item.category.title, language) : ''}
          separator={index !== arr.length - 1}
          isDesktop={isDesktop}
        />
      );
    });
  }, [allServices, language, t]);

  const title = [
    '',
    t('settings.services.stepper.main'),
    t('settings.services.stepper.photoUpload'),
    t('settings.services.stepper.tariff'),
  ];

  const { currentStep, handleFirstStep, handleBackStep, handleReset, StepperComponent, handleNextStep } = useStepper({
    title,
  });

  const handleClosePopup = () => {
    setServiceId(null);
  };

  const handleClose = () => {
    if (isOpenEditBar) setOpenEditBar(false);
    dispatch(clearNewService());
    handleReset();
  };

  const handleAddClick = () => {
    dispatch(clearNewService());
    handleFirstStep();
  };

  useEffect(() => {
    dispatch(ThunkService.fetchAllServices(id));
  }, [dispatch]);

  const empty = isEmpty(serviceItems);

  return (
    <ServiceListContent>
      <Head>
        <Adaptive desktop={H3} mobile={H4}>
          {t('settings.services.title')}
        </Adaptive>
        {/* <Button size="small" design="tertiaryBlack" prefix={<FilterIcon />} /> */}
        <Button prefix={<PlusIcon width="20px" height="20px" />} onClick={handleAddClick} design="primary" size="small">
          {isDesktop ? t('button.addNew') : null}
        </Button>
      </Head>
      <Wrapper>
        <Scroll>
          {empty ? (
            <Stub
              margin={isDesktop ? 'auto' : '0 auto'}
              title={t('settings.services.emptyTitle')}
              subtitle={t('settings.services.emptySubtitle')}
              src={LogoutImage}
            >
              <Button
                prefix={isDesktop && <PlusIcon width="20px" height="20px" />}
                onClick={handleAddClick}
                design="primary"
                size="small"
              >
                {t('button.addNew')}
              </Button>
            </Stub>
          ) : (
            serviceItems
          )}
        </Scroll>
      </Wrapper>
      <ServiceStep
        stepper={StepperComponent}
        currentStep={currentStep}
        handleNextStep={handleNextStep}
        handleBackStep={handleBackStep}
        handleClose={handleClose}
      />
      {isOpenEditBar && <EditService onClose={handleClose} />}
      {serviceId && <DeleteService id={serviceId} onClose={handleClosePopup} />}
    </ServiceListContent>
  );
};
