import { useEffect, useRef, useState } from 'react';
import { Outlet } from 'react-router-dom';
import { Flex } from '@beauty/beauty-market-ui';
import { hasScroll } from 'helpers/utils';
import { ImageBlock, InfoWrapper, LoginWrapper } from './style';

const Login = () => {
  const [marginTop, setMarginTop] = useState('0');
  const infoRef = useRef<HTMLElement>(null);

  useEffect(() => {
    const topDistance = infoRef.current?.getBoundingClientRect().top;
    const handleWindowResize = () => setMarginTop(hasScroll() && topDistance && topDistance < 104 ? '24px' : '0');
    window.addEventListener('resize', handleWindowResize);
    return () => window.removeEventListener('resize', handleWindowResize);
  }, []);

  return (
    <LoginWrapper mt={['0', '0', '0', marginTop]}>
      <InfoWrapper ref={infoRef}>
        <Flex width={['100%', '100%', '100%', '400px']} flexDirection="column">
          <Outlet />
        </Flex>
      </InfoWrapper>
      <ImageBlock />
    </LoginWrapper>
  );
};

export default Login;
