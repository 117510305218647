import { useMemo } from 'react';

export type TimeItemType = {
  id: string;
  item: string;
  hour: number;
  minute: number;
  disabled: boolean;
};

const getTimeList = (): TimeItemType[] => {
  const timeList: TimeItemType[] = [];
  const minutesPerHour = 60;
  const minutesStep = 15;
  const hourLimit = 24;

  for (let hour = 0; hour <= hourLimit; hour += 1) {
    const formattedHour = hour.toString().padStart(2, '0');

    for (let minute = 0; minute < minutesPerHour; minute += minutesStep) {
      const formattedMinute = minute.toString().padStart(2, '0');
      const time24Hour = `${formattedHour}:${formattedMinute}`;

      timeList.push({
        id: `${hour * minutesPerHour + minute}`,
        item: time24Hour,
        hour,
        minute,
        disabled: false,
      });

      if (hour === hourLimit) break;
    }
  }
  return timeList;
};

export const useTimeList = (): TimeItemType[] => useMemo(() => getTimeList(), []);

// type DateItemType = {
//   item: string;
//   disabled: boolean;
//   time24Hour: string;
// };

// TODO: get dateList in AM/PM format
// const getDateList = (): DateItemType[] => {
//   const periods = ['AM', 'PM'];
//   const DateList: DateItemType[] = [];
//
//   for (let hour = 0; hour < 24; hour += 1) {
//     const periodIndex = hour < 12 ? 0 : 1;
//     const formattedHour = hour.toString().padStart(2, '0');
//
//     for (let minute = 0; minute < 60; minute += 15) {
//       const formattedMinute = minute.toString().padStart(2, '0');
//       const time12Hour = `${formattedHour}:${formattedMinute} ${periods[periodIndex]}`;
//       const time24Hour = `${formattedHour}:${formattedMinute}`;
//
//       DateList.push({
//         item: time12Hour,
//         disabled: false,
//         time24Hour,
//       });
//     }
//   }
//   return DateList;
// };
