import { useTranslation } from 'react-i18next';
import { Tooltip } from 'react-tooltip';
import {
  Flex,
  Caption,
  BodySmall,
  Badge,
  Avatar,
  PhoneFillIcon,
  MailFillIcon,
  Separator,
  calendarIcons,
} from '@beauty/beauty-market-ui';
import { GoogleIcon } from 'assets';
import { getDir } from 'helpers/utils';
import { ClientType, TimeslotType } from 'types';
import { EventStatus } from '../../../constants';
import { GappedFlex } from '../../../style';
import { statusData } from '../constant';
import { StyledCaption, Wrapper } from './style';

type CardTooltipProps = {
  id: string;
  timeslot: TimeslotType;
  service: string;
  isSingleAppointment: boolean;
  specialist?: {
    specialistName: string;
    photo?: string;
  };
  client?: ClientType;
  duration?: number | string;
  status?: EventStatus;
  isGoogle?: boolean;
  isBreak?: boolean;
  leftOffset?: number;
};

const { VIP } = calendarIcons;

export const CardTooltip = ({
  id,
  timeslot,
  duration,
  service,
  specialist,
  client,
  status = EventStatus.PENDING,
  isGoogle = false,
  isBreak = false,
  leftOffset,
  isSingleAppointment,
}: CardTooltipProps) => {
  const { t } = useTranslation();
  const { start, end } = timeslot;

  return (
    <Wrapper leftOffset={leftOffset} isSingleAppointment={isSingleAppointment}>
      <Tooltip id={id} place={end && Number(end.slice(0, 2)) < 23 ? 'top-start' : 'right'} isOpen>
        <Flex justifyContent="space-between" alignItems="center">
          <Flex>
            <BodySmall large>{start}</BodySmall>
            <BodySmall large>-</BodySmall>
            <BodySmall large>{end}</BodySmall>
            <BodySmall large>{duration ? ` • ${duration}` : ''}</BodySmall>
          </Flex>
          {!isBreak && (
            <Badge
              text={<StyledCaption large>{t(`status.${status.toLowerCase()}`)}</StyledCaption>}
              design={statusData[status].color}
              icon={statusData[status].icon}
            />
          )}
        </Flex>

        <Caption truncated dir={getDir(service)} large display="inline-block" maxWidth="288px">
          {service}
        </Caption>

        {specialist ? (
          <Flex justifyContent="space-between" alignItems="center">
            <Flex mt="12px" mb="16px" gap="6px" alignItems="center">
              <Avatar size="xxs" url={specialist?.photo} />
              <Caption lowline>{specialist?.specialistName}</Caption>
            </Flex>

            {isGoogle && !client && <GoogleIcon />}
          </Flex>
        ) : null}

        {client ? (
          <>
            <Separator />
            <GappedFlex gap="4px" mt="16px" mb="4px" justifyContent="space-between">
              <BodySmall large>{client.name}</BodySmall>
              <GappedFlex gap="4px" alignItems="center">
                {isGoogle && <GoogleIcon />}
                {/* <VIP width="20" height="20" /> */}
              </GappedFlex>
            </GappedFlex>
            <GappedFlex gap="4px" alignItems="center">
              {client.number ? <PhoneFillIcon fill="#FFFFFF" /> : <MailFillIcon fill="#FFFFFF" />}
              <Caption dir="ltr">{client.number ? client.number : client.email}</Caption>
            </GappedFlex>
          </>
        ) : null}
      </Tooltip>
    </Wrapper>
  );
};
