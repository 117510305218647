import { useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Button, Flex, getMinWidthMediaQuery, H5, useMediaQuery } from '@beauty/beauty-market-ui';
import { Paper, Tooltip } from '../../../components';
import { transComponents } from '../../../constants';
import { getCalendarAccessUrl } from '../../../helpers/generalAPI';
import { useRequest } from '../../../hooks';
import { useAppSelector } from '../../../store/hooks';
import { selectUser, ThunkUser } from '../../../store/redux-slices/userSlice';
import { ProfileActions } from '../../../types';
import { BlueSpanInclude } from '../style';

export const GoogleCalendarSync = () => {
  const { t } = useTranslation();
  const { user } = useAppSelector(selectUser);
  const revokeGoogleCalendar = useRequest(ThunkUser.revokeGoogleCalendar, ProfileActions.RevokeGoogleCalendar);

  const [isLoading, setLoading] = useState(false);

  const { id = '', googleTokens = 'false', googleCalendarEmail = null, active = false } = user.headOrgSpecialist ?? {};
  const isGoggleCalendar = googleTokens === 'true';

  const mediaQuery = getMinWidthMediaQuery('md');
  const isDesktop = useMediaQuery(mediaQuery);

  const handleToggleGoogleCalendar = async () => {
    if (isGoggleCalendar) {
      setLoading(true);
      revokeGoogleCalendar(id).finally(() => {
        setLoading(false);
      });
    } else {
      const response = id ? await getCalendarAccessUrl(id) : null;
      response && window.open(response, '_blank');
    }
  };

  const userMessage = googleCalendarEmail
    ? t('profile.yourPlatformSyncWithUserName', { value: 'Google Calendar', name: googleCalendarEmail })
    : t('profile.yourPlatformSyncWithOutUserName', { value: 'Google Calendar' });

  return (
    <Paper
      gap="8px"
      justifyContent="space-between"
      alignItems="start"
      borderRadius={isDesktop ? '0' : '10px'}
      p={isDesktop ? '24px' : '16px 8px'}
    >
      <Flex flexDirection="column" gap="4px" width="100%">
        <H5>{t('profile.connectGoogleCalendarTitle')}</H5>
        <BlueSpanInclude maxWidth="90%">
          <Trans components={transComponents}>
            {isGoggleCalendar ? userMessage : t('profile.connectGoogleCalendarInfo')}
          </Trans>
        </BlueSpanInclude>
      </Flex>
      <Tooltip
        id="connect-goggle-calendar"
        content={t('profile.forSyncWithGoogle')}
        show={!isGoggleCalendar && !active}
      >
        <Button
          data-tooltip-id="connect-goggle-calendar"
          onClick={handleToggleGoogleCalendar}
          disabled={isLoading || !id || (!isGoggleCalendar && !active)}
          design={isGoggleCalendar ? 'secondary' : 'primary'}
          size="extraSmall"
        >
          {isGoggleCalendar ? t('profile.disconnect') : t('profile.connect')}
        </Button>
      </Tooltip>
    </Paper>
  );
};
