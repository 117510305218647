import { getMinWidthMediaQuery, useMediaQuery } from '@beauty/beauty-market-ui';

export const Adaptive = ({ mobile, desktop, children, ...rest }) => {
  const mediaQuery = getMinWidthMediaQuery('md');
  const isDesktop = useMediaQuery(mediaQuery);

  const Component = isDesktop ? desktop : mobile;

  return <Component {...rest}>{children}</Component>;
};
