import styled from 'styled-components';
import { Flex, mediaQueries } from '@beauty/beauty-market-ui';
import { Paper } from '../../../components';

export const Wrapper = styled(Flex)`
  flex-direction: column;
  padding: 0 8px;
  box-sizing: border-box;
  background-color: ${({ theme }) => theme.colors.white.standard};
  min-height: calc(100vh - 230px);
  max-height: calc(100vh - 230px);
  border-radius: 0 0 10px 10px;

  ${mediaQueries.md} {
    min-height: calc(100vh - 270px);
    max-height: calc(100vh - 270px);
    border-radius: 10px;
    padding: 24px;
  }

  & > div:nth-of-type(3) {
    max-width: 100%;
  }
`;

export const ServiceListContent = styled(Flex)`
  flex-direction: column;
  gap: 0;
  box-sizing: border-box;
  max-width: 1280px;
  width: 100%;

  ${mediaQueries.md} {
    gap: 16px;
  }
`;

export const Head = styled(Paper)`
  justify-content: space-between;
  align-items: center;
  gap: 23px;
  border-radius: 10px 10px 0 0;

  padding: 16px 8px;

  ${mediaQueries.md} {
    border-radius: 10px;
    padding: 24px;
  }
`;
