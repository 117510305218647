import styled from 'styled-components';
import { PlayIcon, colors, Flex, mediaQueries } from '@beauty/beauty-market-ui';
import { CenteredFlex, ColumnFlex } from '../../style';
import { zIndex } from '../../constants';

export const StyledPlayIcon = styled(PlayIcon)`
  path {
    stroke: ${colors.grey.dark};
  }
  :hover {
    cursor: pointer;
  }
`;

export const HomeWrapper = styled(Flex)`
  width: 1060px;
  ${mediaQueries.md} {
    margin: auto;
  }

  @media (max-width: 1060px) {
    width: 100%;
    padding-right: 24px;
    padding-left: 24px;
    box-sizing: border-box;
  }

  transition: all 0.1s ease-in;
`;

export const BodyWrapper = styled(ColumnFlex)`
  padding: 120px 40px 0px;
  width: 100%;
  /* z-index: ${zIndex.homeBodyWrapper}; */
`;
