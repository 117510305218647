import { Views } from 'react-big-calendar';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';
import moment, { Moment } from 'moment';
import { Flex, H3, TabStack } from '@beauty/beauty-market-ui';
import { AccessRange, getSelectedLanguage } from '../../../constants';
import { checkAccess } from '../../../helpers/utils';
import { Today } from '../Today/Today';
import { FilterByAddress } from './FilterByAddress';
import { FilterByService } from './FilterByService';
import { FilterBySpecialist } from './FilterBySpecialist';
import { OptionsMenu } from './OptionsMenu';
import { Wrapper } from './style';

export type CalendarHeaderProps = {
  date: Moment;
  label: string;
  view: string;
  onView: (value: string) => void;
  onNavigate: (value: string) => void;
  onAddBreak: (isBreak?: boolean) => void;
  onCancelAll: () => void;
};

const viewStates = [Views.DAY, Views.WEEK, Views.MONTH];

// Props are passed from components -> toolbar in a CustomCalendar component
export const CalendarHeader = ({
  date,
  view,
  label,
  onView,
  onNavigate,
  onAddBreak,
  onCancelAll,
}: CalendarHeaderProps) => {
  const { t } = useTranslation();
  const isAdmin = checkAccess(AccessRange.ADMIN);
  const locale = getSelectedLanguage();
  moment.locale(locale);

  const viewTypes = [t('calendar.day'), t('calendar.week'), t('calendar.month')];

  const goToDayView = () => {
    onView(Views.DAY);
  };
  const goToWeekView = () => {
    onView(Views.WEEK);
  };
  const goToMonthView = () => {
    onView(Views.MONTH);
  };

  const goToListView = () => {
    onView(Views.AGENDA);
  };

  const viewRedirections = {
    [t('calendar.day')]: goToDayView,
    [t('calendar.week')]: goToWeekView,
    [t('calendar.month')]: goToMonthView,
    [t('calendar.list')]: goToListView,
  };

  const title =
    view === Views.DAY
      ? `${label}, ${locale === 'HE' ? moment(date).format('ddd').split(' ')[1] : moment(date).format('ddd')}`
      : label;

  return (
    <Wrapper>
      <Flex justifyContent="space-between" alignItems="center" width="100%">
        <H3>{title}</H3>
        <Flex gap="16px" alignItems="center">
          <Flex gap="8px">
            <FilterByAddress />
            {isAdmin && <FilterBySpecialist />}
            <FilterByService />
          </Flex>
          <Flex>
            <TabStack
              items={viewTypes}
              active={_.indexOf(viewStates, view)}
              onStackClick={(index: number) =>
                index !== _.indexOf(viewStates, view) && viewRedirections[viewTypes[index]]()
              }
              isBlack
            />
          </Flex>
          <Today onNavigate={onNavigate} view={view} />
          {view === Views.DAY && isAdmin && (
            <OptionsMenu onAddBreak={onAddBreak} onCancelAll={onCancelAll} onClose={() => null} />
          )}
        </Flex>
      </Flex>
    </Wrapper>
  );
};
