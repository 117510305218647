import { useEffect, useRef } from 'react';
import { Socket } from 'socket.io-client';
import { SocketApi } from '../api/socket-api';
import { SocketData, SocketPropsType } from '../api/socket-api.types';

export const useSocketConnection = ({ data, event, handleEvent, view }: SocketPropsType) => {
  const socket = useRef<Socket | null>(null);

  const createConnection = (initial: SocketData) => {
    socket.current = SocketApi.connect(initial);
  };

  const destroyConnection = () => {
    if (socket.current) {
      socket.current = SocketApi.disconnect();
    }
  };

  const listen = () => {
    if (socket.current) {
      socket.current?.on(event, handleEvent);
    }
  };

  const changeData = (newData: SocketData) => {
    destroyConnection();
    createConnection(newData);
    listen();
  };

  useEffect(() => {
    data && changeData(data);
    return destroyConnection;
  }, [data, view]);
};
