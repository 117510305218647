import { createSlice, isFulfilled, isPending, isRejected, PayloadAction } from '@reduxjs/toolkit';
import { specialistAPI } from 'helpers/specialistApi';
import { createAppAsyncThunk } from 'store/create-app-async-thunk';
import { RootState } from 'store/store';
import { Language } from 'types';
import {
  AddBreakParams,
  CreateSpecialistParams,
  SpecialistAction,
  SpecialistInformation,
  SpecialistResponse,
  SpecialistStateType,
} from 'types/specialist';
import { clearAccountState, clearCurrentData, logout } from '../actions/common';
import {
  addEducation,
  deleteAvatar,
  deleteEducation,
  editCurrentSchedule,
  editEducation,
  editGeneralSchedule,
  editPhotos,
  editServices,
  editSpecialist,
  editSpecialistAbout,
  editSpokenLanguage,
  editWorkplaces,
  fetchSpecialistInfo,
} from '../asyncActions/specialist';
import { ThunkCommon } from '../index';
import { extractBaseSpecialistInfo } from '../utils';

export const emptySpecialist: CreateSpecialistParams = {
  id: '',
  role: 'SPECIALIST',
  accessLevel: 'SPECIALIST',
  accessStart: '',
  accessEnd: '',
  specialization: '',
  language: Language.English,
  active: false,
  account: {
    name: '',
    surname: '',
    code: '',
    number: '',
    email: '',
    avatarUrl: null,
  },
};

const initialState: SpecialistStateType = {
  allSpecialists: [],
  currentSpecialist: emptySpecialist,
  information: {
    about: '',
    education: [],
    photo: [],
    spokenLanguage: [],
  },
  isLoading: true,
  type: SpecialistAction.None,
};

const createSpecialist = createAppAsyncThunk<SpecialistResponse, CreateSpecialistParams | SpecialistResponse>(
  'specialist/createSpecialist',
  async (data, thunkAPI) => specialistAPI.createSpecialist({ data, thunkAPI }),
);

const fetchAllSpecialists = createAppAsyncThunk<SpecialistResponse[], string>(
  'specialist/fetchAllSpecialists',
  async (id, thunkAPI) => specialistAPI.getAllSpecialists({ id, thunkAPI }),
);

const fetchSpecialist = createAppAsyncThunk<SpecialistResponse, string>(
  'specialist/fetchSpecialist',
  async (id, thunkAPI) => specialistAPI.getSpecialist({ id, thunkAPI }),
);

const addBreak = createAppAsyncThunk<SpecialistResponse, AddBreakParams>(
  'specialist/addBreak',
  async (data, thunkAPI) => specialistAPI.addBreak({ data, thunkAPI }),
);
const addBreakAll = createAppAsyncThunk<SpecialistResponse, AddBreakParams>(
  'specialist/addBreakAll',
  async (data, thunkAPI) => specialistAPI.addBreakAll({ data, thunkAPI }),
);
const editBreak = createAppAsyncThunk<SpecialistResponse, AddBreakParams>(
  'specialist/editBreak',
  async (data, thunkAPI) => specialistAPI.editBreak({ data, thunkAPI }),
);

const deleteBreak = createAppAsyncThunk<void, string>('specialist/deleteBreak', async (id, thunkAPI) =>
  specialistAPI.deleteBreak({ id, thunkAPI }),
);

export const specialistSlice = createSlice({
  name: 'specialist',
  initialState,
  reducers: {
    updateSpecialist: (state, action: PayloadAction<CreateSpecialistParams | SpecialistResponse>) => {
      state.currentSpecialist = action.payload;
    },
    updateSpecialistInfo: (state, action: PayloadAction<Partial<SpecialistInformation>>) => {
      Object.keys(action.payload).forEach(key => {
        state.information[key] = action.payload[key];
      });
    },
    updateType: (state, action: PayloadAction<SpecialistAction>) => {
      state.type = action.payload;
      if (action.payload === SpecialistAction.Add) {
        state.currentSpecialist = emptySpecialist;
      }
    },
    setSpecialistActive: (state, action: PayloadAction<string>) => {
      const currentSpec = state.allSpecialists.find(spec => spec.id === action.payload);
      if (currentSpec) currentSpec.active = true;
    },
    deleteSpecialistAvatar: (state, action: PayloadAction<string>) => {
      const currentSpec = state.allSpecialists.find(specialist => specialist.id === action.payload);
      if (currentSpec) currentSpec.account.avatarUrl = '';
    },
  },
  extraReducers: builder => {
    builder
      .addCase(createSpecialist.fulfilled, (state, action) => {
        state.allSpecialists.push(action.payload);
        state.currentSpecialist = emptySpecialist;
        state.type = SpecialistAction.None;
      })
      .addCase(fetchAllSpecialists.fulfilled, (state, action) => {
        state.allSpecialists = action.payload;
      })
      .addCase(fetchAllSpecialists.rejected, state => {
        state.allSpecialists = [];
      })
      .addCase(fetchSpecialist.fulfilled, (state, action) => {
        extractBaseSpecialistInfo(state, action.payload);
        if (action.payload.organization) {
          state.currentSpecialist.organization = action.payload.organization.sort((a, b) => {
            const firstOrgName = a?.name || '';
            const secondOrgName = b?.name || '';
            return firstOrgName.localeCompare(secondOrgName);
          });
        }
      })
      .addCase(fetchSpecialist.rejected, state => {
        state.currentSpecialist = emptySpecialist;
      })
      .addCase(editSpecialist.fulfilled, (state, action) => {
        const index = state.allSpecialists.findIndex(item => item.id === action.payload.id);
        if (index !== -1) {
          state.allSpecialists[index] = action.payload;
        }
        extractBaseSpecialistInfo(state, action.payload);
      })
      .addCase(editWorkplaces.fulfilled, (state, action) => {
        state.currentSpecialist.organization = action.payload.map(orgItem => {
          const item = state.currentSpecialist.organization?.find(org => org.orgId === orgItem.orgId);
          if (item) return item;
          return {
            orgId: orgItem.orgId,
            orgSpecId: orgItem.id,
            name: orgItem.name,
            orgService: [],
            orgSpecSchedule: [],
            orgSchedule: [],
          };
        });
      })
      .addCase(editServices.fulfilled, (state, action) => {
        const { orgId, orgService } = action.payload;
        if (state.currentSpecialist.organization) {
          const index = state.currentSpecialist.organization.findIndex(org => org.orgId === orgId);
          if (index !== -1) {
            state.currentSpecialist.organization[index].orgService = orgService;
          }
        }
      })
      .addCase(editCurrentSchedule.fulfilled, (state, action) => {
        const { orgId, orgSpecSchedule } = action.payload;
        if (state.currentSpecialist.organization) {
          const index = state.currentSpecialist.organization.findIndex(org => org.orgId === orgId);
          if (index !== -1) {
            state.currentSpecialist.organization[index].orgSpecSchedule = orgSpecSchedule;
          }
        }
      })
      .addCase(editGeneralSchedule.fulfilled, (state, action) => {
        const organisation = state.currentSpecialist.organization;
        action.payload.forEach(updatedOrg => {
          const { orgId, orgSpecSchedule, scheduleStart } = updatedOrg;
          const index = organisation ? organisation.findIndex(org => org.orgId === orgId) : -1;
          if (index !== -1 && state.currentSpecialist.organization) {
            state.currentSpecialist.organization[index].orgSpecSchedule = orgSpecSchedule;
            state.currentSpecialist.organization[index].scheduleStart = scheduleStart;
          }
        });
      })
      .addCase(fetchSpecialistInfo.fulfilled, (state, action) => {
        state.information = action.payload;
      })
      .addCase(editSpecialistAbout.fulfilled, (state, action) => {
        state.information.about = action.meta.arg.about;
      })
      .addCase(addEducation.fulfilled, (state, action) => {
        state.information.education.push(action.payload);
      })
      .addCase(editEducation.fulfilled, (state, action) => {
        const index = state.information.education.findIndex(item => item.id === action.payload.id);
        if (index !== -1) {
          state.information.education.splice(index, 1, action.payload);
        }
      })
      .addCase(deleteEducation.fulfilled, (state, action) => {
        const index = state.information.education.findIndex(item => item.id === action.meta.arg);
        if (index !== -1) {
          state.information.education.splice(index, 1);
        }
      })
      .addCase(editPhotos.fulfilled, (state, action) => {
        const { deletePhotos } = action.meta.arg.data;
        deletePhotos.forEach(id => {
          const index = state.information.photo.findIndex(item => item.id === id);
          if (index !== -1) state.information.photo.splice(index, 1);
        });
        action.payload.forEach(photo => {
          const index = state.information.photo.findIndex(item => item.id === photo.id);
          if (index === -1) state.information.photo.push(photo);
        });
      })
      .addCase(editSpokenLanguage.fulfilled, (state, action) => {
        state.information.spokenLanguage = action.payload.spokenLanguage;
      })
      .addCase(deleteAvatar.fulfilled, state => {
        state.currentSpecialist.account.avatarUrl = null;
      })
      .addCase(ThunkCommon.activateSpecialist.fulfilled, (state, action) => {
        const index = state.allSpecialists.findIndex(spec => spec.id === action.payload.id);
        if (index !== -1) state.allSpecialists[index].active = action.payload.active;
      })
      .addCase(clearAccountState, () => initialState)
      .addCase(clearCurrentData, state => {
        state.currentSpecialist = initialState.currentSpecialist;
      })
      .addCase(logout, () => initialState)
      .addMatcher(isPending, state => {
        state.isLoading = true;
      })
      .addMatcher(isFulfilled, state => {
        state.isLoading = false;
      })
      .addMatcher(isRejected, state => {
        state.isLoading = false;
      });
  },
});

export const { updateSpecialist, updateType, setSpecialistActive } = specialistSlice.actions;

export const selectSpecialistState = (state: RootState) => state.specialist;

export const selectSpecialist = (state: RootState) => state.specialist.currentSpecialist;
export const selectSpecialistInfo = (state: RootState) => state.specialist.information;
export const selectAllSpecialists = (state: RootState) => state.specialist.allSpecialists;
export const selectType = (state: RootState) => state.specialist.type;
export const selectActiveSpecialists = (state: RootState) =>
  state.specialist.allSpecialists.filter(spec => spec.active);

export const ActionSpecialist = specialistSlice.actions;

export const ThunkSpecialist = {
  createSpecialist,
  fetchSpecialist,
  fetchSpecialistInfo,
  fetchAllSpecialists,
  editWorkplaces,
  editServices,
  editCurrentSchedule,
  editGeneralSchedule,
  deleteAvatar,
  editSpecialist,
  addBreak,
  addBreakAll,
  editBreak,
  deleteBreak,
  editSpecialistAbout,
  addEducation,
  deleteEducation,
  editEducation,
  editPhotos,
  editSpokenLanguage,
};

export default specialistSlice.reducer;
