import { Trans, useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { BodySmall, Button, colors, Flex, H4 } from '@beauty/beauty-market-ui';
import ReminderImage from '../../../../assets/reminder.png';
import { transComponents } from '../../../../constants';
import { RouterUrl } from '../../../../routes/routes';
import { Paper } from '../../style';

export const FinishTrialPage = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  return (
    <Paper flexDirection="column" borderRadius="10px" p="104px 24px" gap="24px" alignItems="center">
      <img src={ReminderImage} alt="reminder" width="240px" height="240px" />
      <Flex flexDirection="column" gap="16px" alignItems="center" justifyContent="center">
        <H4>{t('subscriptions.trialIsFinished')}</H4>
        <BodySmall color={colors.grey.standard} textAlign="center">
          <Trans components={transComponents}>{t('subscriptions.toContinueUsingBM')}</Trans>
        </BodySmall>
      </Flex>
      <Button size="medium" onClick={() => navigate(RouterUrl.ChangePlanPage)}>
        {t('subscriptions.upgradePlan')}
      </Button>
    </Paper>
  );
};
