import { TFunction } from 'react-i18next';
import { NotifyPropsType, NotifyStatus } from '@beauty/beauty-market-ui';
import { ProfileActions } from 'types';

const successTitles = {
  [ProfileActions.ChangePassword]: 'profile.alerts.title.changePasswordSuccess',
  [ProfileActions.EditProfile]: 'profile.alerts.title.editProfileSuccess',
  [ProfileActions.DisconnectTelegram]: 'profile.alerts.title.disconnectTelegramSuccess',
  [ProfileActions.RevokeGoogleCalendar]: 'profile.alerts.title.revokeCalendarSuccess',
  [ProfileActions.ChangeNotificationLanguage]: 'alerts.success',
  [ProfileActions.DeleteAccount]: 'alerts.success',
};

const errorTitles = {
  [ProfileActions.ChangePassword]: 'profile.alerts.title.changePasswordError',
  [ProfileActions.EditProfile]: 'profile.alerts.title.editProfileError',
  [ProfileActions.DisconnectTelegram]: 'profile.alerts.title.disconnectTelegramError',
  [ProfileActions.RevokeGoogleCalendar]: 'profile.alerts.title.revokeCalendarError',
  [ProfileActions.ChangeNotificationLanguage]: 'alerts.error',
  [ProfileActions.DeleteAccount]: 'alerts.error',
};

const successSubtitles = {
  [ProfileActions.ChangePassword]: 'profile.alerts.subtitle.changePasswordSuccess',
  [ProfileActions.EditProfile]: 'profile.alerts.subtitle.editProfileSuccess',
  [ProfileActions.DisconnectTelegram]: 'profile.alerts.subtitle.disconnectTelegramSuccess',
  [ProfileActions.RevokeGoogleCalendar]: 'profile.alerts.subtitle.revokeCalendarSuccess',
  [ProfileActions.ChangeNotificationLanguage]: 'alerts.errors.subtitle.informationUpdated',
  [ProfileActions.DeleteAccount]: 'profile.alerts.subtitle.deleteAccountSuccess',
};

const errorSubtitles = {
  [ProfileActions.ChangePassword]: 'profile.alerts.subtitle.changePasswordError',
  [ProfileActions.EditProfile]: 'profile.alerts.subtitle.editProfileError',
  [ProfileActions.DisconnectTelegram]: 'profile.alerts.subtitle.disconnectTelegramError',
  [ProfileActions.RevokeGoogleCalendar]: 'profile.alerts.subtitle.revokeCalendarError',
  [ProfileActions.ChangeNotificationLanguage]: 'alerts.errors.subtitle.informationNotUpdated',
  [ProfileActions.DeleteAccount]: 'profile.alerts.subtitle.deleteAccountError',
};

export const getUserNotifyContent = (
  status: NotifyStatus,
  action: ProfileActions,
  t: TFunction<'translation', undefined>,
  message?: string,
): NotifyPropsType => {
  const title = status === NotifyStatus.SUCCESS ? successTitles[action] : errorTitles[action];
  const subtitle = status === NotifyStatus.SUCCESS ? successSubtitles[action] : errorSubtitles[action];

  return {
    id: action,
    status,
    title: t(title),
    subtitle: message || t(subtitle),
  };
};
