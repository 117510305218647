import { useTranslation } from 'react-i18next';
import { BodyLarge, BottomSheet, Button, Flex, Sidebar } from '@beauty/beauty-market-ui';
import { useLogout, useMediaScreen } from 'hooks';
import LogoutImage from '../../../assets/exit-image.png';

export const LogoutSidebar = () => {
  const { t } = useTranslation();
  const { isDesktop } = useMediaScreen('md');
  const { isOpen, onConfirm, onCancel, onLoginAnotherUser } = useLogout();

  const FooterBody = (
    <Flex flexDirection="column" justifyContent="space-between" alignItems="center" width="100%" minHeight={200}>
      <Button size="large" width="100%" onClick={onConfirm}>
        {t('logout.logoutButton')}
      </Button>
      <Button size="large" width="100%" design="secondary" onClick={onCancel}>
        {t('logout.cancelButton')}
      </Button>
      <Button design="tertiary" onClick={onLoginAnotherUser} size="medium">
        {t('logout.logIn')}
      </Button>
    </Flex>
  );

  const content = <BodyLarge large>{t('logout.description')}</BodyLarge>;

  return isDesktop ? (
    <Sidebar
      label={t('logout.title')}
      descriptor={t('logout.description')}
      isOpen={isOpen}
      onClose={onCancel}
      FooterBody={FooterBody}
    >
      <Flex flexDirection="column" justifyContent="center" alignItems="center" height="100%">
        <img src={LogoutImage} alt={t('logout.altImg')} />
      </Flex>
    </Sidebar>
  ) : (
    <BottomSheet
      isOpen={isOpen}
      onClose={onCancel}
      label={t('logout.title')}
      content={content}
      handleClose={onCancel}
      FooterBody={FooterBody}
      detent="content-height"
    />
  );
};
