import { apiRequest } from '../api/api';
import { AuthEndpoints } from '../api/endpoints/endpoints';
import { AccType } from '../constants';
import { getHost } from './utils';

export const sendEmailLink = async ({ email, language, referral }) => {
  const [error, response] = await apiRequest.post({
    endpoint: AuthEndpoints.Root,
    data: {
      email,
      language,
      host: getHost(),
      accType: AccType.BUSINESS,
      referral,
    },
  });
  return response ? response.data : error?.data;
};
