import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { upperCase } from 'lodash';
import { Nullable } from 'tsdef';
import { NotifyStatus, useNotify } from '@beauty/beauty-market-ui';
import { Currency, getSelectedLanguage } from '../constants';
import { getErrorNotifyContent, getFormattedCurrency, getNotifyContent } from '../helpers';
import { RouterUrl } from '../routes/routes';
import { useAppDispatch, useAppSelector } from '../store/hooks';
import {
  selectCurrency,
  selectEmployeeNum,
  selectPaymentCards,
  ThunkOrganisation,
} from '../store/redux-slices/organisationSlice';
import {
  OrganizationAction,
  PaymentCardType,
  SubscriptionDataType,
  SubscriptionPeriodType,
  SubscriptionPricePlanType,
} from '../types';

type Props = {
  period: string;
  planData: SubscriptionDataType;
  onClose?: () => void;
};

export const usePaymentForSubscription = ({ period, planData, onClose }: Props) => {
  const { t } = useTranslation();
  const headOrgCurrency = useAppSelector(selectCurrency);
  const paymentCards = useAppSelector(selectPaymentCards);
  const employeeNum = useAppSelector(selectEmployeeNum);

  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const notify = useNotify();

  const language = getSelectedLanguage();
  const currency = planData.price[headOrgCurrency] ? headOrgCurrency : Currency.ILS;

  const mainAmount = planData.price[currency][SubscriptionPricePlanType.MAIN][period];
  const whatsAppAmount = planData.price[currency][SubscriptionPricePlanType.WHATSAPP][period];
  const telegramAmount = planData.price[currency][SubscriptionPricePlanType.TELEGRAM][period];
  const oneEmployeeAmount = planData.price[currency][SubscriptionPricePlanType.EMPLOYEE][period];

  const isAnnually = period === SubscriptionPeriodType.Annually;

  const [card, setCard] = useState<Nullable<PaymentCardType>>(paymentCards.find(item => item.default) || null);
  const [isWhatsApp, setWhatsApp] = useState(whatsAppAmount === 0);
  const [isTelegram, setTelegram] = useState(telegramAmount === 0);
  const [isSubmitting, setSubmitting] = useState(false);

  const employeeExceptOwner = employeeNum - 1;
  const employeesAmount = oneEmployeeAmount * employeeExceptOwner;

  let totalAmount = employeesAmount + mainAmount;

  if (isWhatsApp) totalAmount += whatsAppAmount;
  if (isTelegram) totalAmount += telegramAmount;

  const mainPrice = getFormattedCurrency(mainAmount, currency, language);
  const whatsAppPrice = getFormattedCurrency(whatsAppAmount, currency, language);
  const telegramPrice = getFormattedCurrency(telegramAmount, currency, language);
  const employeePrice = getFormattedCurrency(employeesAmount, currency, language);

  const totalPrice = getFormattedCurrency(totalAmount, currency, language);

  const onSubmit = useCallback(async () => {
    if (planData) {
      setSubmitting(true);
      dispatch(
        ThunkOrganisation.subscribe({
          planId: planData.id,
          period: upperCase(period),
          currency,
          amount: totalAmount,
          whatsApp: isWhatsApp,
          telegram: isTelegram,
          cardId: card?.id,
        }),
      )
        .unwrap()
        .then(res => {
          if (res?.status === 1) {
            navigate(`${RouterUrl.CurrentPlanPage}?success=true`);
          } else if (res?.payment_url) {
            window.location.href = res.payment_url;
          } else if (res.statusCode === 400 && res.message) {
            notify(getErrorNotifyContent(t, res.message));
          } else {
            throw new Error('Some went wrong');
          }
        })
        .catch(() => {
          notify(getNotifyContent(NotifyStatus.ERROR, OrganizationAction.PaySubscription, t));
        })
        .then(() => onClose && onClose())
        .finally(() => setSubmitting(false));
    }
  }, [planData.id, isWhatsApp, isTelegram, card?.id, totalAmount, period, notify, navigate]);

  return {
    onSubmit,
    isSubmitting,
    card,
    setCard,
    isAnnually,
    whatsApp: {
      checked: isWhatsApp,
      isIncluded: whatsAppAmount === 0,
      amount: whatsAppAmount,
      price: whatsAppPrice,
      setWhatsApp,
    },
    telegram: {
      checked: isTelegram,
      isIncluded: telegramAmount === 0,
      amount: telegramAmount,
      price: telegramPrice,
      setTelegram,
    },
    employee: {
      num: employeeExceptOwner,
      price: employeePrice,
    },
    mainPrice,
    totalPrice,
  };
};
