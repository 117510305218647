import { useEffect, useState } from 'react';
import { apiRequest } from 'api/api';
import { HeadOrganisationEndpoints } from 'api/endpoints/endpoints';
import { useAppDispatch } from 'store/hooks';
import { updateClient } from 'store/redux-slices/clientSlice';
import { Client } from 'types/organisation';

export const useGetHeadOrgClient = (clientId: string) => {
  const [isLoading, setIsLoading] = useState(true);
  const [client, setClient] = useState<Client | undefined>(undefined);
  const dispatch = useAppDispatch();

  useEffect(() => {
    let isClientLoading = true;

    const fetchClient = async (id: string) => {
      const [, response] = await apiRequest.get({
        endpoint: HeadOrganisationEndpoints.Client,
        endpointParams: id,
      });

      if (isClientLoading && response) {
        setClient(response.data);
        dispatch(updateClient(response.data));
        setIsLoading(false);
      }
    };

    clientId ? fetchClient(clientId) : setClient(undefined);

    return () => {
      isClientLoading = false;
    };
  }, [clientId]);

  return {
    isLoading,
    client,
  };
};
