import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { Flex, Caption, Avatar, colors } from '@beauty/beauty-market-ui';
import { getDir } from 'helpers/utils';
import { EventType } from 'types/calendar';
import { statusIcons, EventStatus } from '../../../constants';
import { EventWrapper, ServiceWrapper } from './style';

type DayEventProps = {
  event: EventType;
  handleClick: (id: string) => void;
};

export const ActiveEvent = ({ event, handleClick }: DayEventProps) => {
  const { t } = useTranslation();
  const { start, end, title, resource } = event;
  const { photo, specialistName } = resource.specialist!;

  const currentDate = moment().toDate();
  const duration = moment(end).diff(moment(start), 'minutes');
  const now = moment();
  const startTime = moment(start).format('HH:mm');
  const endTime = moment(end).format('HH:mm') !== '23:59' ? moment(end).format('HH:mm') : '24:00';

  const status = now.isBetween(moment(start), moment(end)) ? EventStatus.INPROGRESS : resource.status;
  const isPastEvent = moment(end).isBefore(currentDate);
  const EventIcon = statusIcons[status || EventStatus.PENDING];

  const colorType = isPastEvent ? 'light' : 'standard';

  return (
    <EventWrapper
      backgroundColor={resource?.backgroundColor[colorType]}
      onClick={() => resource?.appointmentId && handleClick(resource.appointmentId)}
      isGoogle={resource.isGoogle}
      isActive
    >
      <Flex flexDirection="column" width="95%">
        <Flex>
          <Caption color={colors.white.standard}>{startTime}</Caption>
          <Caption color={colors.white.standard}>-</Caption>
          <Caption color={colors.white.standard}>{endTime}</Caption>
          <Caption color={colors.white.standard}>{` • ${duration} ${t('time.min')}`}</Caption>
        </Flex>
        <ServiceWrapper truncated directed dir={getDir(title)}>
          {title}
        </ServiceWrapper>
        <Flex justifyContent="space-between" alignItems="center">
          <Flex mt="6px" gap="6px" alignItems="center">
            <Avatar size="xxs" url={photo} />
            <Caption lowline>{specialistName}</Caption>
          </Flex>

          {/* {isGoogle && !client && <GoogleIcon />} */}
        </Flex>
      </Flex>
      <Caption>
        <EventIcon width="12px" height="12px" fill="#FFFFFF99" />
      </Caption>
    </EventWrapper>
  );
};
