import { getFormattedCurrency } from 'helpers';
import { SubscriptionPricePlanType, SubscriptionStatusType } from 'types';
import { getSelectedLanguage } from '../constants';
import { useAppSelector } from '../store/hooks';
import {
  selectActiveSubscription,
  selectHeadOrgTelegram,
  selectHeadOrgWhatsApp,
} from '../store/redux-slices/organisationSlice';

export const usePrice = (planType: SubscriptionPricePlanType) => {
  const subscription = useAppSelector(selectActiveSubscription);
  const planPriceList = subscription?.plan?.price.find(item => item.planType === planType);
  const language = getSelectedLanguage();
  const currency = planPriceList?.currency || '';
  const price = getFormattedCurrency(planPriceList?.monthly || 0, currency, language);
  const general = {
    price,
    amount: planPriceList?.monthly || 0,
    isPast: subscription?.status === SubscriptionStatusType.PAST,
  };

  switch (planType) {
    case SubscriptionPricePlanType.TELEGRAM: {
      const todayAmount = subscription?.todaysPrice.telegram || 0;
      return {
        ...general,
        todayAmount,
        todayPrice: getFormattedCurrency(todayAmount, currency, language),
        isConnected: useAppSelector(selectHeadOrgTelegram),
        isAvailable: subscription?.telegram,
      };
    }

    case SubscriptionPricePlanType.WHATSAPP:
    default: {
      const todayAmount = subscription?.todaysPrice.whatsApp || 0;
      return {
        ...general,
        todayAmount,
        todayPrice: getFormattedCurrency(todayAmount, currency, language),
        isConnected: useAppSelector(selectHeadOrgWhatsApp),
        isAvailable: subscription?.whatsApp,
      };
    }
  }
};
