import { MouseEvent } from 'react';
import { useTranslation } from 'react-i18next';
import { generatePath, useNavigate } from 'react-router-dom';
import {
  Avatar,
  Badge,
  BodySmall,
  colors,
  EyeOpenedIcon,
  Flex,
  H6,
  Icon,
  LeftIcon,
  RightIcon,
  UploadIcon,
} from '@beauty/beauty-market-ui';
import { isRtl } from 'helpers';
import { useGetUrl } from 'hooks';
import { RouterUrl } from 'routes/routes';
import { extractAddress } from '../../helpers';
import { AddressCardContent, AddressCardWrapper, StyledCaption } from './style';
import { AddressCardPropsType } from './types';

export const AddressCard = ({
  id,
  name,
  published,
  address,
  onPublishClick,
  mainPhoto,
  isDesktop,
}: AddressCardPropsType) => {
  const { t } = useTranslation();
  const { client } = useGetUrl(`/organisation/${id}`);
  const navigate = useNavigate();
  const fullAddress = extractAddress(address);

  const rtl = isRtl();

  const handleAddressClick = () => {
    navigate(generatePath(RouterUrl.AddressGeneralData, { id }));
  };

  const handleItemClick = (e: MouseEvent<HTMLDivElement>) => {
    e.stopPropagation();
    published ? window.open(client) : onPublishClick(e);
  };

  const itemIcon = published ? <EyeOpenedIcon /> : <UploadIcon />;
  const itemText = published ? t('address.visitOnSite') : t('address.publish');
  const badgeText = published ? t('address.online') : t('address.offline');
  const badgeDesign = published ? 'green' : 'darkGrey';
  const textColor = colors.grey.dark;

  return (
    <AddressCardWrapper onClick={handleAddressClick} gap="16px">
      <Avatar url={mainPhoto} defaultAvatar size="m" />
      <AddressCardContent>
        <Badge text={badgeText} design={badgeDesign} />
        <H6 mt="6px" title={name}>
          {name}
        </H6>
        <BodySmall color={textColor}>{fullAddress}</BodySmall>
      </AddressCardContent>
      <StyledCaption>
        <Flex gap="8px" height="100%" onClick={handleItemClick} alignItems="center">
          <Icon stroke={textColor} m="auto 0">
            {itemIcon}
          </Icon>
          {isDesktop && <BodySmall color={textColor}>{itemText}</BodySmall>}
        </Flex>
        <Icon width="24px" height="24px" flexShrink={0}>
          {rtl ? <LeftIcon /> : <RightIcon />}
        </Icon>
      </StyledCaption>
    </AddressCardWrapper>
  );
};
