import { TFunction } from 'react-i18next';
import isEmpty from 'lodash/isEmpty';
import { formatPhoneNumber } from '../../../helpers';
import { OrganisationHeadType } from '../../../types';

export const getHeadOrgContent = (t: TFunction<'transaltion', undefined>, head: OrganisationHeadType) =>
  [
    {
      label: t('settings.head.phone'),
      value: isEmpty(head.contact.phone)
        ? undefined
        : formatPhoneNumber(head.contact.phone[0]?.code, head.contact.phone[0]?.number),
      direction: 'ltr',
    },
    {
      label: t('settings.head.email'),
      value: head.contact?.email?.email,
    },
    {
      label: t('settings.head.employees'),
      value: Number(head.employeesNum) > 7 ? '7+' : head.employeesNum,
    },
  ].filter(item => Boolean(item.value));
