import { ReactNode } from 'react';
import { Nullable } from 'tsdef';
import { DownIcon, Flex, H5, H6, Scroll, UpIcon } from '@beauty/beauty-market-ui';
import { Adaptive } from 'hoc';
import { useEndScroll } from 'hooks';
import { AccordionWrapper } from '../../style';
import { AppointmentsType } from './Appointments';

type Props = {
  title: string;
  open: boolean;
  onChange: (value: Nullable<AppointmentsType>) => void;
  children?: ReactNode;
  onEnd?: () => void;
  type: AppointmentsType;
};

export const Accordion = ({ title, children, onEnd, type, open, onChange }: Props) => {
  const scrollRef = useEndScroll(onEnd);
  return (
    <AccordionWrapper last={type === 'past'} isOpen={open}>
      <Flex
        gap="8px"
        alignItems="center"
        cursor="pointer"
        onClick={() => onChange(open ? null : type)}
        pb={open ? '16px' : '0px'}
      >
        <Adaptive mobile={H5} desktop={H6}>
          {title}
        </Adaptive>
        {open ? <UpIcon /> : <DownIcon />}
      </Flex>
      <Scroll ref={scrollRef}>{open && children}</Scroll>
    </AccordionWrapper>
  );
};
