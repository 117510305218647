import { Dispatch, SetStateAction } from 'react';
import { NavigationArrows } from './NavigationArrows';
import { PaginationProgress } from './PaginationProgress';

type PaginatorProps = {
  activePage: number;
  setActivePage: Dispatch<SetStateAction<number>>;
  totalPages: number;
};
export const Paginator = ({ activePage, setActivePage, totalPages }: PaginatorProps) => (
  <>
    <NavigationArrows
      onLeftClick={() => activePage > 0 && setActivePage(active => active - 1)}
      onRightClick={() => activePage < totalPages - 1 && setActivePage(active => active + 1)}
      leftDisabled={!activePage}
      rightDisabled={activePage === totalPages - 1}
    />
    <PaginationProgress number={totalPages} active={activePage} onClick={setActivePage} />
  </>
);
