import { MenuIcon } from '@beauty/beauty-market-ui';
import { StyledQuaternaryButton } from './style';

type MenuButtonProps = {
  onClick: () => void;
  count?: number;
};

export const MenuButton = ({ onClick, count }: MenuButtonProps) => (
  <StyledQuaternaryButton design="quaternary" count={count} onClick={onClick}>
    <MenuIcon />
  </StyledQuaternaryButton>
);
