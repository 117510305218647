import styled from 'styled-components';
import { BodySmall, Flex, mediaQueries } from '@beauty/beauty-market-ui';

export const FullName = styled(Flex)`
  gap: 8px;
  flex-wrap: wrap;

  ${mediaQueries.sm} {
    flex-wrap: nowrap;
  }
`;

export const StyledIcon = styled(Flex)`
  background-color: ${({ theme }) => theme.colors.grey.light};
  border-radius: 50%;
  width: 56px;
  height: 56px;
  justify-content: center;
  align-items: center;

  & svg,
  & path {
    width: 24px;
    height: 24px;

    stroke: ${({ theme }) => theme.colors.blue.standard};
  }
`;

export const PhoneWrapper = styled(Flex)`
  flex-direction: ${({ theme }) => (theme.rtl ? 'row-reverse' : 'row')};
  gap: 8px;
`;

export const EmployeeInfo = styled(Flex)`
  flex-direction: column;
  gap: 2px;
  width: 57vw;

  ${mediaQueries.md} {
    width: auto;
  }
`;

export const EmployeeFullName = styled(BodySmall)`
  overflow: hidden;
  text-overflow: ellipsis;
`;
