import { configureStore } from '@reduxjs/toolkit';
import { combineReducers } from 'redux';
import { persistStore, persistReducer, FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import storageSession from 'redux-persist/lib/storage/session';
import addressReducer from './redux-slices/addressSlice';
import appointmentsReducer from './redux-slices/appointmentsSlice';
import authReducer from './redux-slices/authSlice';
import clientReducer from './redux-slices/clientSlice';
import headerReducer from './redux-slices/headerSlice';
import organisationReducer from './redux-slices/organisationSlice';
import serviceReducer from './redux-slices/serviceSlice';
import specialistReducer from './redux-slices/specialistSlice';
import userReducer from './redux-slices/userSlice';

const localStoragePersistConfig = {
  key: 'root',
  version: 1,
  storage,
  whitelist: ['auth', 'user', 'header', 'service', 'address', 'organisation', 'specialist', 'client'],
};

const sessionStoragePersistConfig = {
  key: 'appointments',
  version: 1,
  storage: storageSession,
};

const rootReducer = combineReducers({
  auth: authReducer,
  user: userReducer,
  header: headerReducer,
  service: serviceReducer,
  address: addressReducer,
  organisation: organisationReducer,
  specialist: specialistReducer,
  client: clientReducer,
  appointments: persistReducer(sessionStoragePersistConfig, appointmentsReducer),
});

const persistedReducer = persistReducer(localStoragePersistConfig, rootReducer);

export const store = configureStore({
  reducer: persistedReducer,
  middleware: getDefaultMiddleware =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }),
});

export const persistor = persistStore(store);

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
