import { ReactNode } from 'react';
import { BodySmall, colors, Flex, H4 } from '@beauty/beauty-market-ui';

type Props = {
  title: string;
  src: string;
  subtitle?: string;
  alt?: string;
  children?: ReactNode | ReactNode[];
  margin?: string;
  imageWidth?: string;
  infoWidth?: string;
};

export const Stub = ({
  title,
  src,
  alt,
  children,
  subtitle = '',
  margin = '171px auto 0',
  imageWidth = '240px',
  infoWidth = '300px',
}: Props) => (
  <Flex flexDirection="column" alignItems="center" maxWidth="930px" m={margin} gap="16px">
    <Flex width="300px" justifyContent="center">
      <img src={src} alt={alt || title} width={imageWidth} />
    </Flex>
    <Flex width={infoWidth} gap="8px" mt="8px" flexDirection="column" alignItems="center">
      <H4>{title}</H4>
      <BodySmall textAlign="center" color={colors.grey.dark}>
        {subtitle}
      </BodySmall>
    </Flex>
    {children}
  </Flex>
);
