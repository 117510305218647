import { forwardRef, memo, useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Form, Formik, FormikProps } from 'formik';
import { Flex, H6, InfoIcon } from '@beauty/beauty-market-ui';
import { FormikTextfield } from 'components/functional/formik/formik-textfield/FormikTextfield';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import { selectNewAddress, ThunkAddress, updateDetailedInformation } from '../../../store/redux-slices/addressSlice';
import { FormPropsType } from '../../../types';
import { FormikInput } from '../../functional/formik/formik-input/FormikInput';
import { defaultAddressFields } from '../constants';
import { EditAddressFieldsType } from '../types';
import CustomMap from './CustomMap';
import {
  DetailedInformationFormFields,
  DetailedInformationFormType,
  detailedInformationValidationSchema,
} from './DetailedInformation.definitions';

type EditableFields = {
  fields?: EditAddressFieldsType['detailedInformation'];
};

type DetailedInformationFormProps = FormPropsType & EditableFields;

export const DetailedInformationForm = memo(
  forwardRef(
    (
      {
        setIsValid,
        setIsDirty,
        editBar,
        fields = defaultAddressFields.detailedInformation,
      }: DetailedInformationFormProps,
      ref,
    ) => {
      const { t } = useTranslation();
      const dispatch = useAppDispatch();
      const { address } = useAppSelector(selectNewAddress);
      const { postal, street, country, city, building, office, description } = address;
      const { Country, City, Street, Building, Office, Post, Description } = DetailedInformationFormFields;

      const onFormSubmit = useCallback((data: DetailedInformationFormType) => {
        dispatch(
          updateDetailedInformation({
            ...data,
            office: data.office || null,
            description: data.description || null,
            postal: data.postal || null,
          }),
        );
      }, []);

      const formikContextValue = {
        initialValues: {
          [Country]: country,
          [City]: city,
          [Street]: street,
          [Building]: building,
          [Office]: office || '',
          [Post]: postal || '',
          [Description]: description || '',
        },
        onSubmit: onFormSubmit,
        validateOnMount: false,
        validateOnChange: true,
        validationSchema: detailedInformationValidationSchema(t),
      };

      return (
        <Formik
          innerRef={ref as (instance: FormikProps<DetailedInformationFormType> | null) => void}
          {...formikContextValue}
        >
          {({ isValid, errors, values, touched, handleChange, handleBlur, dirty, setFieldTouched, initialValues }) => {
            const handleInputBlur = (e: HTMLInputElement) => {
              handleBlur(e);
              dispatch(updateDetailedInformation(values));
              dispatch(ThunkAddress.fetchCoordinates());
            };

            useEffect(() => {
              if (!address.id) {
                Object.entries(initialValues).forEach(([key, value]) => {
                  setFieldTouched(key, !!value);
                });
              }
            }, [address.id, initialValues]);

            useEffect(() => {
              setIsValid && setIsValid(isValid);
            }, [isValid]);

            useEffect(() => {
              setIsDirty && setIsDirty(dirty);
            }, [dirty]);

            return (
              <Form>
                <Flex flexDirection="column" gap="16px">
                  {editBar && <H6>{t('settings.addresses.sidebar.addressInformation')}</H6>}
                  {fields?.country && (
                    <FormikInput
                      id={Country}
                      name={Country}
                      width="100%"
                      design="white"
                      value={values[Country]}
                      onBlur={handleInputBlur}
                      onChange={handleChange}
                      placeholder={t('settings.addresses.placeholder.country')}
                      captionIcon={touched[Country] && !!errors[Country] && <InfoIcon />}
                      caption={touched[Country] && !!errors[Country] ? errors[Country] : ''}
                      required
                    />
                  )}
                  {fields?.city && (
                    <FormikInput
                      id={City}
                      name={City}
                      width="100%"
                      design="white"
                      value={values[City]}
                      onBlur={handleInputBlur}
                      onChange={handleChange}
                      placeholder={t('settings.addresses.placeholder.city')}
                      captionIcon={touched[City] && !!errors[City] && <InfoIcon />}
                      caption={touched[City] && !!errors[City] ? errors[City] : ''}
                      required
                    />
                  )}
                  {fields?.street && (
                    <FormikInput
                      id={Street}
                      name={Street}
                      width="100%"
                      design="white"
                      onBlur={handleInputBlur}
                      value={values[Street]}
                      onChange={handleChange}
                      placeholder={t('settings.addresses.placeholder.street')}
                      captionIcon={touched[Street] && !!errors[Street] && <InfoIcon />}
                      caption={touched[Street] && !!errors[Street] ? errors[Street] : ''}
                      required
                    />
                  )}
                  {fields?.building && (
                    <FormikInput
                      id={Building}
                      name={Building}
                      width="100%"
                      design="white"
                      onBlur={handleInputBlur}
                      value={values[Building]}
                      onChange={handleChange}
                      placeholder={t('settings.addresses.placeholder.building')}
                      captionIcon={touched[Building] && !!errors[Building] && <InfoIcon />}
                      caption={touched[Building] && !!errors[Building] ? errors[Building] : ''}
                      required
                    />
                  )}
                  {fields?.office && (
                    <FormikInput
                      id={Office}
                      name={Office}
                      width="100%"
                      design="white"
                      onBlur={handleBlur}
                      value={values[Office]}
                      onChange={handleChange}
                      placeholder={t('settings.addresses.placeholder.officeOrApt')}
                      captionIcon={touched[Office] && !!errors[Office] && <InfoIcon />}
                      caption={touched[Office] && !!errors[Office] ? errors[Office] : ''}
                    />
                  )}
                  {fields?.postal && (
                    <FormikInput
                      id={Post}
                      name={Post}
                      width="100%"
                      design="white"
                      onBlur={handleBlur}
                      value={values[Post]}
                      onChange={handleChange}
                      placeholder={t('settings.addresses.placeholder.postIndex')}
                      captionIcon={touched[Post] && !!errors[Post] && <InfoIcon />}
                      caption={touched[Post] && !!errors[Post] ? errors[Post] : ''}
                    />
                  )}
                  {fields?.description && (
                    <FormikTextfield
                      id={Description}
                      name={Description}
                      design="white"
                      rows={3}
                      value={values[Description]}
                      defaultValue={values[Description]}
                      placeholder={t('settings.addresses.placeholder.description')}
                      onChange={handleChange}
                    />
                  )}
                  <CustomMap />
                </Flex>
              </Form>
            );
          }}
        </Formik>
      );
    },
  ),
);
