import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ArrowLeftIcon, Button, Caption, Flex, getMinWidthMediaQuery, useMediaQuery } from '@beauty/beauty-market-ui';
import { hasScheduleChanges, isScheduleValid } from '../../../helpers';
import { useRequest } from '../../../hooks';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import { selectAddressState, setAllDay, setSchedule, ThunkAddress } from '../../../store/redux-slices/addressSlice';
import { AddressAction, FormPropsType, SidebarStepType } from '../../../types';
import { SidebarSheet } from '../../SidebarSheet';
import { BackWrapper } from '../style';
import { ScheduleForm } from './ScheduleForm';

type ScheduleType = SidebarStepType & FormPropsType;

export const Schedule = ({ handleNextStep, handleBackStep, handleClose, stepper, editBar = false }: ScheduleType) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { newAddress, isLoading } = useAppSelector(selectAddressState);
  const { id, isSave, schedule, prevSchedule, allDayTemplate } = newAddress;

  const currentSchedule = allDayTemplate || schedule;

  const mediaQuery = getMinWidthMediaQuery('md');
  const isDesktop = useMediaQuery(mediaQuery);

  const updateOrganization = useRequest(ThunkAddress.editOrganization, AddressAction.EditAddress);
  const createNewAddress = useRequest(ThunkAddress.createNewAddress, AddressAction.AddAddress);

  const [isDirty, setIsDirty] = useState(false);
  const [isValid, setIsValid] = useState(true);

  const onSubmit = async () => {
    try {
      if (!editBar && id && isSave) {
        await updateOrganization();
      } else if (!editBar) {
        await createNewAddress();
      }
    } finally {
      handleNextStep();
    }
  };

  const handleBackClick = () => {
    if (prevSchedule) {
      dispatch(setSchedule(prevSchedule));
      dispatch(setAllDay(null));
    }
    handleBackStep();
  };

  const sidebarTitle = editBar
    ? t('settings.addresses.sidebar.workingTime')
    : t('settings.addresses.sidebar.workplace');

  const sidebarDescriptor = editBar
    ? isDesktop && (
        <BackWrapper onClick={handleBackClick}>
          <ArrowLeftIcon width="16px" height="16px" />
          <Caption large>{t('button.back')}</Caption>
        </BackWrapper>
      )
    : t('settings.addresses.sidebar.creatingSchedule');

  const footer = (
    <Flex flexDirection="column" width="100%" gap="4px">
      <Button type="submit" width="100%" disabled={!isSave || !isValid || isLoading} onClick={onSubmit}>
        {t('button.save')}
      </Button>
      <Button type="button" width="100%" disabled={isLoading} design="secondary" onClick={handleBackClick}>
        {editBar ? t('button.cancel') : t('button.back')}
      </Button>
    </Flex>
  );

  useEffect(() => {
    if (prevSchedule) {
      const dirty = hasScheduleChanges(prevSchedule, currentSchedule);
      dirty !== isDirty && setIsDirty(dirty);
    }
  }, [prevSchedule, currentSchedule]);

  useEffect(() => {
    const valid = isScheduleValid(currentSchedule);
    valid !== isValid && setIsValid(valid);
  }, [prevSchedule, currentSchedule]);

  return (
    <SidebarSheet
      onBack={handleBackClick}
      onClose={handleClose}
      label={sidebarTitle}
      descriptor={sidebarDescriptor}
      FooterBody={footer}
    >
      <Flex flexDirection="column" gap="16px" mb="80px">
        {stepper}
        <ScheduleForm />
      </Flex>
    </SidebarSheet>
  );
};
