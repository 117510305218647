import { useTranslation } from 'react-i18next';
import { isEmpty } from 'lodash';
import { BodyLarge, BottomSheet, Button, Flex, Separator } from '@beauty/beauty-market-ui';
import { PopUp } from 'components';
import { useMediaScreen } from 'hooks';
import { useAppSelector } from 'store/hooks';
import { selectSpecialistInfo } from 'store/redux-slices/specialistSlice';
import { SpecialistAction } from 'types';
import { EmployeeSection } from '../EmployeeSection/EmployeeSection';
import { EditEducation } from './EditEducation';
import { EducationItem } from './EducationItem';
import { useEducation } from './useEducation';

export const Education = () => {
  const { t } = useTranslation();
  const { isDesktop } = useMediaScreen('md');
  const { education } = useAppSelector(selectSpecialistInfo);
  const { onDelete, onEdit, onSubmit, onAdd, onApproveDelete, onClose, action, isSubmitting, values } = useEducation();

  const educationItems = education.map((item, index, arr) => (
    <EducationItem
      key={item.id}
      avatar={item?.fileName?.endsWith('.pdf') ? null : item.documentUrl}
      range={`${item.startYear} - ${item.endYear}`}
      document={item.documentUrl}
      school={item.school}
      degree={item.degree}
      onDelete={() => onDelete(item)}
      onEdit={() => onEdit(item)}
      separator={index !== arr.length - 1}
    />
  ));

  const content = (
    <BodyLarge mb="80px">{t('specialists.personalInfo.deleteEducation', { value: values?.degree ?? '' })}</BodyLarge>
  );

  return (
    <>
      <EmployeeSection
        position="middle"
        visibleCount={3}
        count={educationItems.length}
        title={t('specialists.personalInfo.education')}
        description={t('specialists.personalInfo.certificates')}
        placeholder={t('specialists.personalInfo.noProfessional')}
        buttonLabel={t('specialists.personalInfo.fillInformation')}
        onClick={onAdd}
        separator={isEmpty(educationItems) && isDesktop ? <Separator mb="16px" /> : null}
      >
        {educationItems}
      </EmployeeSection>
      {action === SpecialistAction.AddEducation && <EditEducation onSubmit={onSubmit} onClose={onClose} />}
      {action === SpecialistAction.EditEducation && (
        <EditEducation onSubmit={onSubmit} onClose={onClose} defaultValues={values} />
      )}
      {action === SpecialistAction.DeleteEducation &&
        (isDesktop ? (
          <PopUp
            title={t('specialists.personalInfo.deletingEducation')}
            description=""
            onSubmit={onApproveDelete}
            onClose={onClose}
            confirm={t('button.delete')}
            cancel={t('button.cancel')}
            isLoading={isSubmitting}
          >
            {content}
          </PopUp>
        ) : (
          <BottomSheet
            isOpen={action === SpecialistAction.DeleteEducation}
            onClose={onClose}
            handleClose={onClose}
            label={t('specialists.personalInfo.deletingEducation')}
            content={content}
            detent="content-height"
            FooterBody={
              <Flex flexDirection="column" width="100%" gap="8px">
                <Button onClick={onApproveDelete}>{t('button.delete')}</Button>
                <Button onClick={onClose} design="secondary">
                  {t('button.cancel')}
                </Button>
              </Flex>
            }
          />
        ))}
    </>
  );
};
