import { io, Socket } from 'socket.io-client';
import { getQueryParams } from '../helpers/utils';
import { SocketData } from './socket-api.types';

const url = process.env.REACT_APP_WSS_URL_TEST || process.env.REACT_APP_WSS_URL_DEV;
const config = { transports: ['websocket'] };

export class SocketApi {
  static socket: null | Socket = null;

  static connect(data: SocketData) {
    const queryParams = getQueryParams(data);
    const queryString = `${url}${queryParams}`;
    this.socket = io(queryString, config);
    return this.socket;
  }

  static disconnect() {
    this.socket?.disconnect();
    return null;
  }
}
