import styled, { css } from 'styled-components';
import { Flex } from '@beauty/beauty-market-ui';
import { ImageProps } from './types';

export const Wrapper = styled(Flex)<ImageProps>`
  position: relative;
  border-radius: 10px;
  box-sizing: border-box;

  ${({ src, theme }) =>
    src
      ? css`
          background:
            linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.29) 48.82%, rgba(0, 0, 0, 0.7) 100%),
            url(${src}) no-repeat center/cover;
        `
      : css`
          background:
            linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.29) 48.82%, rgba(0, 0, 0, 0.7) 100%),
            url('/no-image.png') no-repeat top/auto,
            ${theme.colors.grey.light};
          background-position-y: 37%;
        `}
`;
