import { createSlice, isFulfilled, isPending, isRejected, PayloadAction } from '@reduxjs/toolkit';
import { ClientFormFields } from 'page/Clients/components/ClientSidebar/ClientForm.definitions';
import { RootState } from 'store/store';
import { ClientActions, ClientInfo, ClientStateType } from 'types/client';
import { Client } from 'types/organisation';
import { clearAccountState, clearCurrentData, logout } from '../actions/common';
import {
  createClient,
  editClient,
  fetchClient,
  fetchClientInfo,
  fetchClients,
  getClient,
  sendReview,
} from '../asyncActions/client';

export const emptyClient: Client = {
  [ClientFormFields.Image]: null,
  [ClientFormFields.Name]: '',
  [ClientFormFields.Surname]: '',
  [ClientFormFields.DateOfBirth]: '',
  [ClientFormFields.Gender]: undefined,
  [ClientFormFields.IDNumber]: '',
  [ClientFormFields.Code]: '+972',
  [ClientFormFields.Number]: '',
  [ClientFormFields.AdditionalCode]: '',
  [ClientFormFields.AdditionalNumber]: '',
  [ClientFormFields.Email]: '',
  [ClientFormFields.AdditionalInfo]: '',
  [ClientFormFields.ShowAdditionalInfo]: false,
  [ClientFormFields.AppointmentNotifications]: false,
  [ClientFormFields.DiscountNotifications]: false,
  [ClientFormFields.MarketingNotifications]: false,
  id: '',
  headOrgClientId: '',
  createdBy: '',
  clientId: '',
  accountId: '',
  verified: false,
  orgClientIds: undefined,
};

const initialState: ClientStateType = {
  clients: [],
  currentClient: emptyClient,
  information: {
    totalSales: 0,
    totalBookings: 0,
    completedApp: 0,
    cancelledApp: 0,
    noShowApp: 0,
    reviewCount: 0,
    rating: 0,
  },
  isLoading: true,
  type: null,
};

export const clientSlice = createSlice({
  name: 'client',
  initialState,
  reducers: {
    updateClient: (state, action: PayloadAction<Client>) => {
      state.currentClient = action.payload;
    },
    updateClientInfo: (state, action: PayloadAction<Partial<ClientInfo>>) => {
      Object.keys(action.payload).forEach(key => {
        state.information[key] = action.payload[key];
      });
    },
    updateType: (state, action: PayloadAction<ClientActions | null>) => {
      state.type = action.payload;
    },
    loadClients: (state, action: PayloadAction<Client[]>) => {
      state.clients = action.payload;
    },
    resetClient: state => {
      state.currentClient = initialState.currentClient;
      state.information = initialState.information;
    },
  },
  extraReducers: builder => {
    builder
      .addCase(createClient.fulfilled, (state, action) => {
        state.clients.push(action.payload);
        state.currentClient = emptyClient;
        state.type = null;
      })
      .addCase(editClient.fulfilled, (state, action: PayloadAction<Client>) => {
        if (state.currentClient.verified) {
          action.payload.dateOfBirth = state.currentClient.dateOfBirth;
          action.payload.gender = state.currentClient.gender;
        }
        state.currentClient = action.payload;
      })
      .addCase(fetchClients.fulfilled, (state, action: PayloadAction<Client[]>) => {
        state.clients = action.payload;
      })
      .addCase(fetchClient.fulfilled, (state, action: PayloadAction<Client>) => {
        state.currentClient = action.payload;
      })
      .addCase(fetchClientInfo.fulfilled, (state, action: PayloadAction<Client & ClientInfo>) => {
        state.currentClient = action.payload;
        state.information = {
          totalSales: action.payload.totalSales,
          totalBookings: action.payload.totalBookings,
          completedApp: action.payload.completedApp,
          cancelledApp: action.payload.cancelledApp,
          noShowApp: action.payload.noShowApp,
          reviewCount: action.payload.reviewCount,
          rating: action.payload.rating,
        };
      })
      .addCase(sendReview.fulfilled, (state, action) => {
        state.information.reviewCount = action.payload.totalRating.reviewCount;
        state.information.rating = action.payload.totalRating.rating;
      })
      .addCase(clearAccountState, () => initialState)
      .addCase(clearCurrentData, state => {
        state.currentClient = initialState.currentClient;
      })
      .addCase(logout, () => initialState)
      .addMatcher(isPending, state => {
        state.isLoading = true;
      })
      .addMatcher(isFulfilled, state => {
        state.isLoading = false;
      })
      .addMatcher(isRejected, state => {
        state.isLoading = false;
      });
  },
});

export const { updateClient } = clientSlice.actions;
export const selectClient = (state: RootState) => state.client.currentClient;
export const selectClientInfo = (state: RootState) => state.client.information;
export const selectClients = (state: RootState) => state.client.clients;

export const ActionClient = clientSlice.actions;
export const ThunkClient = {
  createClient,
  editClient,
  getClient,
  fetchClients,
  fetchClient,
  sendReview,
  fetchClientInfo,
};
export default clientSlice.reducer;
