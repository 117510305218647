import { forwardRef } from 'react';
import { createPortal } from 'react-dom';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';
import { H6 } from '@beauty/beauty-market-ui';
/* import { getNotificationCard, markAllAsRead } from '../../helpers/notifications';
import { useGetNotifications } from '../../hooks/useGetNotifications'; */
/* import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { selectUser, setNotifications } from '../../store/redux-slices/userSlice';
import { Notification } from '../../types/notifications'; */
import { NoData } from 'components/NoData';
import { CardsWrapper, TitleWrapper, Wrapper } from './style';

type NotificationsPopupProps = {
  title: string;
  items: Notification[];
  onClose: () => void;
  isOpen: boolean;
  setRead?: (index: number) => void;
};

export const NotificationsPopup = forwardRef((props: NotificationsPopupProps, ref) => {
  const { title = 'Notifications', items, setRead, onClose, isOpen } = props;
  const { t } = useTranslation();
  const navigate = useNavigate();
  /* const { user } = useAppSelector(selectUser);
  const { nots } = useGetNotifications(user.userId);
  const dispatch = useAppDispatch();

  const handleMarkAll = async () => {
    const response = await markAllAsRead(user.userId);
    response && dispatch(setNotifications(nots));
  }; */

  return isOpen ? (
    /* createPortal( */
    <Wrapper ref={ref}>
      <TitleWrapper>
        <H6>{title}</H6>
        {/*  <Link design="gray" href={RouterUrl.PersonalProfile}>
          <GearIcon width="20px" height="20px" />
        </Link> */}
      </TitleWrapper>
      <CardsWrapper>
        {
          /* items.length ? (
        <Flex flexDirection="column" gap="4px" px="4px">
          {items.map((item, index) =>
            getNotificationCard(t, navigate, user.userId, item, index, setRead, setNotificationsPopup),
          )}
        </Flex>
      ) :  */ <NoData title={t('notifications.noDataTitle')} info={t('notifications.noDataInfo')} />
        }
      </CardsWrapper>
      {/* {!!items.length && (
        <FooterWrapper>
          <StyledLink
            design="gray"
            size="xs"
            icon={<CheckCircleIcon width="16px" height="16px" />}
            onClick={handleMarkAll}
          >
            {t('notifications.markAll')}
          </StyledLink>
          <Button
            size="extraSmall"
            onClick={() => {
              onClose();
              navigate(RouterUrl.Notifications);
            }}
          >
            {t('notifications.viewAll')}
          </Button>
        </FooterWrapper>
      )} */}
    </Wrapper>
  ) : /*   document.getElementById('root') as HTMLElement,
      ) */
  null;
});

NotificationsPopup.displayName = 'NotificationsPopup';
