import styled from 'styled-components';
import { Flex, colors, Button, mediaQueries, BodyLarge, Div } from '@beauty/beauty-market-ui';
import { NavTag } from 'components';
import { Adaptive } from 'hoc';

export const Wrapper = styled(Flex)<{ delta?: string }>`
  flex-direction: column;
  flex-grow: 1;
  width: 100%;
  min-height: ${({ delta }) => `calc(100vh - ${delta || '300px'})`};
  max-height: ${({ delta }) => `calc(100vh - ${delta || '72px'})`};
  padding: 16px 8px 80px;
  box-sizing: border-box;
  background-color: ${colors.white.standard};
  border-radius: 10px;

  & > div:nth-of-type(3) {
    max-width: 100%;
  }

  ${mediaQueries.md} {
    padding: 24px 24px 8px;
    border-radius: 0 0 16px 16px;
    min-height: ${({ delta }) => `calc(100vh - ${delta || '300px'})`};
    max-height: ${({ delta }) => `calc(100vh - ${delta || '300px'})`};
  }
`;

export const StyledButton = styled(Button)`
  svg path {
    stroke-width: 2 !important;
  }
`;

export const Container = styled(Flex)`
  width: 100%;
  padding: 8px 0 8px;
  align-items: center;
  background-color: ${colors.white.standard};
  gap: 16px;
  box-sizing: border-box;
  cursor: pointer;
  * {
    cursor: pointer !important;
  }

  ${mediaQueries.md} {
    padding: 16px 0 16px;
  }
`;

export const FormWrapper = styled(Flex)`
  flex-direction: column;
  padding-top: 16px;
  gap: 16px;
`;

export const InputWrapper = styled(Flex)`
  width: 100%;
  margin-bottom: 8px;
  & > div {
    width: 100%;
    max-width: 100%;
  }
  svg {
    width: 16px;
    height: 16px;
  }
  input {
    margin: 0 0 0 4px;
  }
`;

export const PopupWrapper = styled(Flex)`
  * {
    overflow-y: visible !important;
  }
`;

export const PageHeader = styled(Flex)`
  justify-content: space-between;
  align-items: center;
  border-radius: 16px;
  padding: 16px 8px;
  margin-top: 1px;
  background-color: ${({ theme }) => theme.colors.white.standard};

  ${mediaQueries.md} {
    border-radius: 16px 16px 0 0;
    padding: 24px 24px 8px 24px;
    margin-top: 0;
  }
`;

export const TabSection = styled(Flex)`
  border-radius: 16px;
  padding: 16px 8px;
  margin-top: 1px;
  margin-bottom: 1px;
  background-color: ${({ theme }) => theme.colors.white.standard};
  justify-content: ${({ theme }) => (theme.rtl ? 'end' : 'start')};

  ${mediaQueries.md} {
    gap: 16px;
    border-radius: 0;
    padding: 8px 24px 24px 24px;
    margin-top: 0;
  }
`;

export const TabItem = styled(NavTag)`
  flex: 0 0 100%;
  max-width: 100%;
  counter-increment: slide;
  position: relative;
`;

export const AccordionWrapper = styled(Flex)<{ last: boolean; isOpen: boolean }>`
  flex-direction: column;
  background-color: ${({ theme }) => theme.colors.white.standard};

  margin-bottom: 1px;
  border-radius: 16px;
  padding: 16px 8px;

  max-height: ${({ isOpen }) => (isOpen ? '100%' : 'auto')};
  min-height: ${({ isOpen }) => (isOpen ? '100%' : 'auto')};

  ${mediaQueries.md} {
    padding: 24px;
    border-radius: ${({ last }) => (last ? ' 0 0 16px 16px' : '0')};
  }
`;

export const StyledBodyLarge = styled(BodyLarge)`
  width: 55vw;

  ${mediaQueries.md} {
    width: 40vw;
  }
`;

export const ContactInfo = styled(Adaptive)`
  justify-self: start;
  text-align: start;

  [dir='rtl'] & {
    text-align: end;
  }
`;

export const PageContainer = styled(Div)`
  min-height: calc(100vh - 72px);

  ${mediaQueries.md} {
    min-height: auto;
  }
`;
