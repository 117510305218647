import { useTranslation } from 'react-i18next';
import { capitalize } from 'lodash';
import { BodySmall, Flex, TagButton } from '@beauty/beauty-market-ui';
import { SubscriptionPlanType, SubscriptionStatusType } from 'types';
import { useAppSelector } from '../../../../store/hooks';
import { selectActiveSubscription } from '../../../../store/redux-slices/organisationSlice';
import { BlueHeading, Paper } from '../../style';
import { SubscriptionFilterBy } from '../helpers';
import { SectionMenu } from './SectionMenu';

export const SectionHeader = ({ filter, setFilter, setAction }) => {
  const { t } = useTranslation();
  const subscription = useAppSelector(selectActiveSubscription);

  const isActive = subscription && subscription.status !== SubscriptionStatusType.PAST;
  const isCancelled = subscription && subscription.cancelled;
  const isTrial = subscription && subscription.plan.planType === SubscriptionPlanType.FREE;
  const { daysLeft = 0 } = subscription ?? {};

  const planName =
    subscription?.plan.planType === SubscriptionPlanType.FREE ? 'Trial' : capitalize(subscription?.plan.planType);
  const planText = isCancelled || isTrial ? `${planName} (${daysLeft} ${t('subscriptions.daysLeft')})` : planName;

  return (
    <Paper borderRadius="10px" p="24px" flexDirection="column" gap="21px">
      <Flex alignItems="center" justifyContent="space-between">
        <Flex flexDirection="column" gap="9px">
          <BlueHeading>
            {t('subscriptions.currentPlan')}
            <span>{isActive ? planText : t('subscriptions.noSubscription')}</span>
          </BlueHeading>
          {isActive && isCancelled && (
            <BodySmall>{t('subscriptions.cancelledSubscription', { value: daysLeft })}</BodySmall>
          )}
        </Flex>
        <SectionMenu setAction={setAction} />
      </Flex>
      {/* <Flex> */}
      {/*  <TagButton */}
      {/*    text={t('subscriptions.subscriptionPlan')} */}
      {/*    design={filter === SubscriptionFilterBy.Plan ? 'active' : 'default'} */}
      {/*    onClick={() => setFilter(SubscriptionFilterBy.Plan)} */}
      {/*  /> */}
      {/* </Flex> */}
    </Paper>
  );
};
