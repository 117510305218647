import styled from 'styled-components';
import { Div, Flex, ArrowRightIcon } from '@beauty/beauty-market-ui';

export const SidebarContentWrapper = styled(Flex)`
  flex-direction: column;
`;

export const SidebarContentSection = styled(Flex)`
  flex-direction: column;
  margin-bottom: 24px;
  gap: 16px;
`;

export const PhoneInputsWrapper = styled(Div)`
  display: grid;
  grid-template-columns: 105px 1fr;
`;

export const CategoriesWrapper = styled(Flex)`
  flex-wrap: wrap;
  gap: 6.5px;

  svg {
    width: 100%;
    height: 100%;
  }
  span {
    text-align: center !important;
  }
`;

export const StyledArrowRightIcon = styled(ArrowRightIcon)`
  [dir='rtl'] & {
    transform: scaleX(-1);
  }
`;
