import React from 'react';
import { Flex, H6, Caption, colors, BodySmall } from '@beauty/beauty-market-ui';

type DetailsSectionProps = {
  title: string;
  body: Array<{ label: string; value: string }>;
  isRow?: boolean;
  isPayment?: boolean;
};

export const DetailsSection = ({ title, body, isRow = false, isPayment = false }: DetailsSectionProps) => (
  <Flex flexDirection="column" gap="16px" mb="24px">
    <H6>{title}</H6>
    {body.map((item, index) => (
      <Flex
        key={item.label}
        flexDirection={isRow ? 'row' : 'column'}
        alignItems={isRow ? 'center' : 'start'}
        justifyContent={isRow ? 'space-between' : 'start'}
      >
        {isPayment && index === body.length - 1 && (
          <>
            <H6>{item.label}</H6>
            <H6>{item.value}</H6>
          </>
        )}
        {isRow && (!isPayment || index < body.length - 1) && (
          <BodySmall color={colors.black.standard}>{item.label}</BodySmall>
        )}
        {!isRow && (!isPayment || index < body.length - 1) && (
          <Caption lowline color={colors.grey.dark}>
            {item.label}
          </Caption>
        )}

        {(!isPayment || index < body.length - 1) && <BodySmall>{item.value}</BodySmall>}
      </Flex>
    ))}
  </Flex>
);
