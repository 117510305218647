import { TFunction } from 'react-i18next';
import { NotifyPropsType, NotifyStatus } from '@beauty/beauty-market-ui';
import { OrganizationAction } from 'types';

const successTitles = {
  [OrganizationAction.CancelSubscription]: 'organisation.alerts.title.cancelSubscriptionSuccess',
  [OrganizationAction.PaySubscription]: 'organisation.alerts.title.paySubscriptionSuccess',
  [OrganizationAction.UpdateCard]: 'organisation.alerts.title.updateCardSuccess',
  [OrganizationAction.ActivateSpecialist]: 'organisation.alerts.title.activateSpecialistSuccess',
  [OrganizationAction.PayForTelegram]: 'organisation.alerts.title.payForTelegramSuccess',
  [OrganizationAction.ActivateTelegram]: 'organisation.alerts.title.activateTelegramSuccess',
  [OrganizationAction.DeactivateTelegram]: 'organisation.alerts.title.deactivateTelegramSuccess',
  [OrganizationAction.PayForWhatsApp]: 'organisation.alerts.title.payForWhatsAppSuccess',
  [OrganizationAction.ActivateWhatsApp]: 'organisation.alerts.title.activateWhatsAppSuccess',
  [OrganizationAction.DeactivateWhatsApp]: 'organisation.alerts.title.deactivateWhatsAppSuccess',
  [OrganizationAction.PayForSpecialist]: 'alerts.success',
  [OrganizationAction.EditOrganisation]: 'organisation.alerts.title.headUpdateSuccess',
  [OrganizationAction.CreateOrganisation]: 'alerts.success',
  [OrganizationAction.CloseDay]: 'organisation.alerts.title.closeDaySuccess',
};

const errorTitles = {
  [OrganizationAction.CancelSubscription]: 'organisation.alerts.title.cancelSubscriptionError',
  [OrganizationAction.PaySubscription]: 'organisation.alerts.title.paySubscriptionError',
  [OrganizationAction.UpdateCard]: 'organisation.alerts.title.updateCardError',
  [OrganizationAction.ActivateSpecialist]: 'organisation.alerts.title.activateSpecialistError',
  [OrganizationAction.PayForTelegram]: 'organisation.alerts.title.payForTelegramError',
  [OrganizationAction.ActivateTelegram]: 'organisation.alerts.title.activateTelegramError',
  [OrganizationAction.DeactivateTelegram]: 'organisation.alerts.title.deactivateTelegramError',
  [OrganizationAction.PayForSpecialist]: 'alerts.error',
  [OrganizationAction.EditOrganisation]: 'alerts.error',
  [OrganizationAction.CreateOrganisation]: 'alerts.error',
  [OrganizationAction.CloseDay]: 'organisation.alerts.title.closeDayError',
};

const successSubtitles = {
  [OrganizationAction.CancelSubscription]: 'organisation.alerts.subtitle.cancelSubscriptionSuccess',
  [OrganizationAction.PaySubscription]: 'organisation.alerts.subtitle.paySubscriptionSuccess',
  [OrganizationAction.UpdateCard]: 'organisation.alerts.subtitle.updateCardSuccess',
  [OrganizationAction.ActivateSpecialist]: 'organisation.alerts.subtitle.activateSpecialistSuccess',
  [OrganizationAction.PayForTelegram]: 'organisation.alerts.subtitle.payForTelegramSuccess',
  [OrganizationAction.ActivateTelegram]: 'organisation.alerts.subtitle.activateTelegramSuccess',
  [OrganizationAction.DeactivateTelegram]: 'organisation.alerts.subtitle.deactivateTelegramSuccess',
  [OrganizationAction.PayForWhatsApp]: 'organisation.alerts.subtitle.payForWhatsAppSuccess',
  [OrganizationAction.ActivateWhatsApp]: 'organisation.alerts.subtitle.activateWhatsAppSuccess',
  [OrganizationAction.DeactivateWhatsApp]: 'organisation.alerts.subtitle.deactivateWhatsAppSuccess',
  [OrganizationAction.PayForSpecialist]: 'alerts.errors.subtitle.paymentSuccess',
  [OrganizationAction.EditOrganisation]: 'organisation.alerts.subtitle.headUpdateSuccess',
  [OrganizationAction.CreateOrganisation]: 'organisation.alerts.subtitle.headCreateSuccess',
  [OrganizationAction.CloseDay]: 'organisation.alerts.subtitle.closeDaySuccess',
};

const errorSubtitles = {
  [OrganizationAction.CancelSubscription]: 'organisation.alerts.subtitle.cancelSubscriptionError',
  [OrganizationAction.PaySubscription]: 'organisation.alerts.subtitle.paySubscriptionError',
  [OrganizationAction.UpdateCard]: 'organisation.alerts.subtitle.updateCardError',
  [OrganizationAction.ActivateSpecialist]: 'organisation.alerts.subtitle.activateSpecialistError',
  [OrganizationAction.PayForTelegram]: 'organisation.alerts.subtitle.payForTelegramError',
  [OrganizationAction.ActivateTelegram]: 'organisation.alerts.subtitle.activateTelegramError',
  [OrganizationAction.DeactivateTelegram]: 'organisation.alerts.subtitle.deactivateTelegramError',
  [OrganizationAction.PayForWhatsApp]: 'organisation.alerts.subtitle.payForWhatsAppError',
  [OrganizationAction.ActivateWhatsApp]: 'organisation.alerts.subtitle.activateWhatsAppError',
  [OrganizationAction.DeactivateWhatsApp]: 'organisation.alerts.subtitle.deactivateWhatsAppError',
  [OrganizationAction.PayForSpecialist]: 'alerts.errors.subtitle.paymentFailed',
  [OrganizationAction.EditOrganisation]: 'organisation.alerts.subtitle.headUpdateError',
  [OrganizationAction.CreateOrganisation]: 'organisation.alerts.subtitle.headCreateError',
  [OrganizationAction.CloseDay]: 'organisation.alerts.subtitle.closeDayError',
};

export const getOrganizationNotifyContent = (
  status: NotifyStatus,
  action: OrganizationAction,
  t: TFunction<'translation', undefined>,
  message?: string,
): NotifyPropsType => {
  const title = status === NotifyStatus.SUCCESS ? successTitles[action] : errorTitles[action];
  const subtitle = status === NotifyStatus.SUCCESS ? successSubtitles[action] : errorSubtitles[action];

  return {
    id: action,
    status,
    title: t(title),
    subtitle: message || t(subtitle),
  };
};
