import styled from 'styled-components';
import { Flex, LinkBasicStyles, mediaQueries, Toggle } from '@beauty/beauty-market-ui';

export const TabName = styled(Flex)<{ active: boolean }>`
  ${LinkBasicStyles()}
  box-sizing: border-box;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  border-bottom: 2px solid transparent;
  width: 100%;

  ${({ theme }) => `color: ${theme.colors.grey.dark};`}
  ${({ active, theme }) =>
    active &&
    `
    border-bottom: 2px solid ${theme.colors.blue.standard};
    color: ${theme.colors.blue.standard};
  `}
  &:hover {
    ${({ active, theme }) => `
      border-bottom: 2px solid ${theme.colors.blue.standard};
      color: ${active ? theme.colors.blue.standard : theme.colors.black.standard};
    `}
  }
`;

export const StartScheduleZone = styled(Flex)`
  width: 100%;
  flex-direction: column-reverse;
  gap: 8px;

  ${mediaQueries.md} {
    flex-direction: ${({ theme }) => (theme.rtl ? 'row-reverse' : 'row')};
    gap: 16px;
  }
`;

export const ForAllDaysWrapper = styled(Flex)`
  align-items: start;
  gap: 16px;
  justify-content: space-between;
`;

export const StyledToggle = styled(Toggle)`
  transform: rotate(0deg) !important;
`;
