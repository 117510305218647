import styled from 'styled-components';
import { Flex } from '@beauty/beauty-market-ui';

const getMargin = (placement: 'left' | 'right', rtl: boolean) => {
  switch (placement) {
    case 'left':
      return rtl ? '0 0 0 auto' : '0 auto 0 0';
    case 'right':
    default:
      return rtl ? '0 auto 0 0' : '0 0 0 auto';
  }
};

export const IconBox = styled(Flex)`
  justify-content: end;
  align-self: center;
  gap: 16px;
  opacity: 0;
  transition: opacity 0.1s ease-in-out;
  width: 0;
  margin-left: 0;
`;

export const Box = styled(Flex)<{ available: boolean }>`
  width: 100%;
  flex-direction: row;
  align-items: center;
  gap: 16px;
  box-sizing: border-box;

  &:hover ${IconBox} {
    opacity: ${({ available }) => (available ? 1 : 0)};
    width: ${({ available }) => (available ? 'max-content' : 0)};
  }
`;

export const Content = styled(Flex)<{ placement: 'left' | 'right' }>`
  flex-direction: column;
  gap: 2px;

  margin: ${({ placement, theme }) => getMargin(placement, theme.rtl)};
`;
