import styled, { css } from 'styled-components';
import { Div, Flex, H4, mediaQueries, Button } from '@beauty/beauty-market-ui';
import Bubble from './assets/Bubble.png';
import { zIndex } from './constants';

export const AppWrapper = styled(Div)`
  margin: auto;
  min-height: 100%;
  display: grid;
  grid-template-columns: 100%;
  grid-template-rows: [row1] minmax(72px, max-content) [row2] 1fr [row3];
  grid-template-areas:
    'header'
    'main';
`;

export const GappedFlex = styled(Flex)<{ gap: string }>`
  ${({ gap }) => `gap:${gap}`}
`;

export const bubbleWidth = '950';
export const bubbleHeight = '950';

export const bubbles = css`
  position: absolute;
  background-image: url(${Bubble});
  background-repeat: no-repeat;
  background-position: center center;
  width: ${`${bubbleWidth}px`};
  height: ${`${bubbleHeight}px`};
  @keyframes anim {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }
  animation: anim 30s linear infinite;
`;

export const titleShadow = css`
  text-shadow: 1px 2px 0px rgba(0, 0, 0, 0.24);
`;

export const StyledUl = styled.ul<{ type?: string }>`
  margin: 0;
  padding: 0;
  padding-left: 24px;
  list-style-position: outside;
  ${({ type }) => type && `list-style-type:${type}`}
`;

export const StyledOl = styled.ol`
  margin: 0;
  padding: 0;
  padding-left: 24px;
  list-style-position: outside;
  list-style-type: none;
  counter-reset: item;

  li:before {
    content: counter(item) ') ';
    counter-increment: item;
  }
`;

export const StyledLi = styled.li`
  white-space: pre-wrap;
`;

export const PrivacyWrapper = styled(Flex)`
  margin-top: 26px;
  margin-bottom: 62px;
  flex-direction: column;

  ${mediaQueries.md} {
    flex-direction: row;
    gap: 38px;
    margin-top: 34px;
    margin-bottom: 0px;
  }
`;

export const PrivacyBodyWrapper = styled(Flex)`
  flex-direction: column;
  max-width: 620px;
  margin-top: -100px;

  div:last-child {
    z-index: ${zIndex.privacyBodyWrapper};
  }
`;

export const PrivacyItemWrapper = styled(Flex)`
  flex-direction: column;
  padding-top: 80px;
  margin-bottom: -64px;
`;

export const PrivacyItemTitleWrapper = styled(H4)`
  margin-bottom: 24px !important;
`;

export const PrivacyMenu = styled(Flex)`
  width: 181px;
  flex-direction: column;
`;

export const ColumnFlex = styled(Flex)`
  flex-direction: column;
`;

export const CenteredFlex = styled(Flex)`
  align-items: center;
  justify-content: center;
`;

export const StyledButton = styled(Button)`
  padding: 0;
  letter-spacing: 0;
`;

export const croppedMultiLineText = css`
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
`;

export const cropped1LineText = css`
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
`;

export const cropped5LineText = css`
  -webkit-line-clamp: 5;
  -webkit-box-orient: vertical;
  overflow: hidden;
`;

export const croppedSingleLineText = css`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const trimEventServiceText = stringsToTrim =>
  stringsToTrim === 1 ? cropped1LineText : (stringsToTrim === 3 && croppedMultiLineText) || cropped5LineText;

export const textAlignRtl = css`
  [dir='ltr'] & {
    text-align: start;
  }
  [dir='rtl'] & {
    text-align: end;
  }
`;

export const ltrStyle = (isHebrew: boolean) => css`
  text-align: start;
  ${!isHebrew &&
  css`
    [dir='rtl'] & {
      direction: ltr;
      text-align: end;
    }
  `};

  ${isHebrew &&
  css`
    [dir='ltr'] & {
      direction: rtl;
      text-align: end;
    }
  `};
`;
