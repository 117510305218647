import { TFunction } from 'react-i18next';
import * as Yup from 'yup';

export enum TariffServiceFormFields {
  Price = 'price',
}

export type TariffServiceFormType = {
  [TariffServiceFormFields.Price]: string | null;
};

export const tariffServiceValidationSchema = (t: TFunction<'translation', undefined>) =>
  Yup.object({
    [TariffServiceFormFields.Price]: Yup.string()
      .trim()
      .test('is-number', t('validation.enterValidPrice'), value => {
        if (value) {
          const numericValue = Number(value);
          return !isNaN(numericValue);
        }
        return true;
      })
      .test('is-positive', t('validation.positivePrice'), value => {
        if (value) {
          const numericValue = parseFloat(value);
          return numericValue >= 0;
        }
        return true;
      })
      .test('is-integer', t('validation.integerPrice'), value => {
        if (value) {
          const number = Number(value);
          return number >= 0 ? Number.isInteger(number) : false;
        }
        return true;
      }),
  });
