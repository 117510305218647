import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import { Form, Formik } from 'formik';
import { parsePhoneNumber } from 'libphonenumber-js/max';
import { Button } from '@beauty/beauty-market-ui';
import { FormikPhone } from 'components';
import { getInvalidType, getShortCountryByCode, loginPhoneVerification } from 'helpers';
import { RouterUrl } from 'routes';
import { useAppDispatch } from 'store/hooks';
import { updateSmsCodeTime } from 'store/redux-slices/userSlice';
import { invalidHints } from '../../../constants';
import {
  initialByPhoneValues,
  LoginByPhoneFields,
  LoginByPhoneForm,
  loginPhoneFormValidationSchema,
} from '../Login.definitions';

const LoginByPhone = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { state } = useLocation();
  const { t } = useTranslation();
  const { Number, Region } = LoginByPhoneFields;

  const onFormSubmit = useCallback(
    async (data: LoginByPhoneForm, { setFieldError }) => {
      const { code, number } = data;
      if (code && number) {
        const phone = parsePhoneNumber(`${code}${number}`, getShortCountryByCode(code));
        const response = await loginPhoneVerification({ code, number: phone.nationalNumber });

        if (response.status === 'pending') {
          dispatch(updateSmsCodeTime(new Date().getTime()));
          navigate(RouterUrl.LoginSMS, { state: { code, number: phone.nationalNumber, ...state } });
        } else {
          const errorCode = getInvalidType(response.statusCode, 'login');
          setFieldError(Number, t(invalidHints[errorCode]));
        }
      }
    },
    [navigate, dispatch],
  );

  const formikContextValue = {
    initialValues: initialByPhoneValues,
    validationSchema: loginPhoneFormValidationSchema(t),
    onSubmit: onFormSubmit,
    validateOnMount: true,
  };

  return (
    <Formik {...formikContextValue}>
      {({ isValid }) => (
        <Form>
          <FormikPhone codeId={Region} phoneId={Number} placeholder={t('registration.phone')} design="grey" />
          <Button disabled={!isValid} design="primary" mt="24px" width="100%" size="large" type="submit">
            {t(`login.continue`)}
          </Button>
        </Form>
      )}
    </Formik>
  );
};

export default LoginByPhone;
