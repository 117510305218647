import styled from 'styled-components';
import { Flex } from '@beauty/beauty-market-ui';
import { Paper } from 'components';
import { IconBox } from '../../style';

export const Container = styled(Flex)`
  align-items: start;

  &:hover ${IconBox} {
    width: max-content;
    opacity: 1;
  }
`;

export const StyledPaper = styled(Paper)<{ active?: boolean; error?: boolean }>`
  justify-content: center;
  align-items: center;
  height: 112px;
  width: auto;
  padding: 24px;

  transition: all 0.3s ease;

  border: ${({ theme, active, error }) => {
    switch (true) {
      case error:
        return `1px solid ${theme.colors.red.standard}`;
      case active:
        return `1px solid ${theme.colors.blue.standard}`;
      default:
        return `1px solid ${theme.colors.grey.standard}`;
    }
  }};

  & p {
    color: ${({ theme, active }) => (active ? theme.colors.blue.standard : theme.colors.grey.dark)};
  }

  & path {
    stroke: ${({ theme, active }) => (active ? theme.colors.blue.standard : theme.colors.grey.dark)};
  }

  &:hover {
    & p {
      color: ${({ theme }) => theme.colors.black.standard};
    }

    & path {
      stroke: ${({ theme }) => theme.colors.black.standard};
    }

    cursor: pointer;
  }
`;

export const StyledLabel = styled.label`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  gap: 8px;

  &:hover {
    cursor: pointer;
  }
`;
