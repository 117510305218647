import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { H1, BodySmall } from '@beauty/beauty-market-ui';
import { StyledBodySmall } from '../style';
import SMSCodeForm from './SMSCodeForm';

const LoginSMS = () => {
  const { t } = useTranslation();
  const { code, number } = useLocation().state;

  return (
    <>
      <H1 mb="16px">{t('registration.smsCode')}</H1>
      <BodySmall>{t('login.enterSMSCode')}</BodySmall>
      <StyledBodySmall mb="32px" dir="ltr">
        {`${code} ${number}`}
      </StyledBodySmall>
      <SMSCodeForm />
    </>
  );
};

export default LoginSMS;
