import { apiRequest } from '../api/api';
import { HeadOrganisationEndpoints, SpecialistEndpoints, UserEndpoints } from '../api/endpoints/endpoints';
import { toFormData } from './utils';

const createSpecialist = async ({ data, thunkAPI }) => {
  const { rejectWithValue } = thunkAPI;
  const formData = toFormData(data);
  const [error, response] = await apiRequest.post({
    endpoint: SpecialistEndpoints.Root,
    endpointParams: {},
    data: formData,
  });
  return response ? response.data : rejectWithValue(error?.data);
};

const updateSpecialist = async ({ data, thunkAPI }) => {
  const { getState, rejectWithValue } = thunkAPI;
  const { id } = getState().specialist.currentSpecialist;
  const formData = toFormData(data);
  const [error, response] = await apiRequest.patch({
    endpoint: SpecialistEndpoints.Root,
    endpointParams: { id },
    data: formData,
  });
  return response ? response.data.data : rejectWithValue(error?.data);
};

const getSpecialist = async ({ id, thunkAPI }) => {
  const { rejectWithValue } = thunkAPI;
  const [error, response] = await apiRequest.get({
    endpoint: SpecialistEndpoints.Root,
    endpointParams: {
      id,
      queryParams: '?include=headOrganization,account,organization',
    },
  });
  return response ? response.data : rejectWithValue(error?.data);
};

const getAllSpecialists = async ({ id, thunkAPI }) => {
  const { rejectWithValue } = thunkAPI;
  const [error, response] = await apiRequest.get({
    endpoint: HeadOrganisationEndpoints.Specialists,
    endpointParams: id,
  });
  return response ? response.data : rejectWithValue(error?.data);
};

const editWorkplaces = async ({ data, thunkAPI }) => {
  const { getState, rejectWithValue } = thunkAPI;
  const [error, response] = await apiRequest.patch({
    endpoint: SpecialistEndpoints.Organization,
    data,
  });
  if (response) {
    return response.data.data.map(item => {
      const address = getState().address.allAddress.find(org => org.id === item.orgId);
      return { ...item, name: address?.name || '' };
    });
  }
  return rejectWithValue(error?.data);
};

const editServices = async ({ data, thunkAPI }) => {
  const { rejectWithValue } = thunkAPI;
  const [error, response] = await apiRequest.patch({
    endpoint: SpecialistEndpoints.Services,
    data: {
      orgSpecId: data.orgSpecId,
      orgServiceIds: data.orgServiceIds,
    },
  });

  return response ? { orgId: data.orgId, orgService: response.data.data } : rejectWithValue(error?.data);
};

const editCurrentSchedule = async ({ data, thunkAPI }) => {
  const { rejectWithValue } = thunkAPI;
  const [error, response] = await apiRequest.patch({
    endpoint: SpecialistEndpoints.CurrentSchedule,
    data: data.orgSpecData,
  });

  return response ? { orgId: data.orgId, orgSpecSchedule: response.data } : rejectWithValue(error?.data);
};

const editGeneralSchedule = async ({ data, thunkAPI }) => {
  const { rejectWithValue, getState } = thunkAPI;
  const [error, response] = await apiRequest.patch({
    endpoint: SpecialistEndpoints.GeneralSchedule,
    data: {
      headOrgSpecId: getState().specialist.currentSpecialist.id,
      orgSchedule: data,
    },
  });
  return response ? response.data.data : rejectWithValue(error?.data);
};

export const deleteAvatar = async ({ id, thunkAPI }) => {
  const { rejectWithValue } = thunkAPI;
  const [error, response] = await apiRequest.delete({
    endpoint: UserEndpoints.AvatarUpload,
    endpointParams: id,
  });
  return response ? response.data : rejectWithValue(error?.data);
};

const addBreak = async ({ data, thunkAPI }) => {
  const { rejectWithValue } = thunkAPI;
  const [error, response] = await apiRequest.post({
    endpoint: SpecialistEndpoints.Break,
    data,
  });
  return response ? response.data.data : rejectWithValue(error?.data);
};

const addBreakAll = async ({ data, thunkAPI }) => {
  const { rejectWithValue } = thunkAPI;
  const [error, response] = await apiRequest.post({
    endpoint: SpecialistEndpoints.BreakAll,
    data,
  });
  return response ? response.data.data : rejectWithValue(error?.data);
};

const editBreak = async ({ data, thunkAPI }) => {
  const { rejectWithValue } = thunkAPI;
  const [error, response] = await apiRequest.put({
    endpoint: SpecialistEndpoints.Break,
    data,
  });
  return response ? response.data.data : rejectWithValue(error?.data);
};

const deleteBreak = async ({ id, thunkAPI }) => {
  const { rejectWithValue } = thunkAPI;
  const [error, response] = await apiRequest.delete({
    endpoint: SpecialistEndpoints.Break,
    endpointParams: id,
  });
  return response ? response.data.data : rejectWithValue(error?.data);
};

const getSpecialistInfo = async ({ id, thunkAPI }) => {
  const { rejectWithValue } = thunkAPI;
  const [error, response] = await apiRequest.get({
    endpoint: SpecialistEndpoints.PersonalInformation,
    endpointParams: id,
  });
  return response ? response.data : rejectWithValue(error?.data);
};

const editSpecialistAbout = async ({ data, thunkAPI }) => {
  const { rejectWithValue } = thunkAPI;
  const [error, response] = await apiRequest.put({
    endpoint: SpecialistEndpoints.EditAbout,
    data,
  });
  return response ? response.data : rejectWithValue(error?.data);
};

const addEducation = async ({ data, thunkAPI }) => {
  const { rejectWithValue } = thunkAPI;
  const [error, response] = await apiRequest.post({
    endpoint: SpecialistEndpoints.Education,
    data,
  });
  return response ? response.data : rejectWithValue(error?.data);
};

const editEducation = async ({ data, thunkAPI }) => {
  const { rejectWithValue } = thunkAPI;
  const [error, response] = await apiRequest.put({
    endpoint: SpecialistEndpoints.Education,
    data,
  });
  return response ? response.data : rejectWithValue(error?.data);
};

const deleteEducation = async ({ id, thunkAPI }) => {
  const { rejectWithValue } = thunkAPI;
  const [error, response] = await apiRequest.delete({
    endpoint: SpecialistEndpoints.Education,
    endpointParams: id,
  });
  return response ? response.data : rejectWithValue(error?.data);
};

const editPhotos = async ({ id, data, thunkAPI }) => {
  const { rejectWithValue } = thunkAPI;
  const [error, response] = await apiRequest.patch({
    endpoint: SpecialistEndpoints.Photo,
    endpointParams: id,
    data,
  });
  return response ? response.data : rejectWithValue(error?.data);
};

const editSpokenLanguage = async ({ data, thunkAPI }) => {
  const { rejectWithValue } = thunkAPI;
  const [error, response] = await apiRequest.put({
    endpoint: SpecialistEndpoints.Language,
    data,
  });
  return response ? response.data : rejectWithValue(error?.data);
};

export const specialistAPI = {
  createSpecialist,
  updateSpecialist,
  getSpecialist,
  getAllSpecialists,
  editWorkplaces,
  editServices,
  editCurrentSchedule,
  editGeneralSchedule,
  deleteAvatar,
  addBreak,
  addBreakAll,
  editBreak,
  deleteBreak,
  getSpecialistInfo,
  editSpecialistAbout,
  addEducation,
  deleteEducation,
  editEducation,
  editPhotos,
  editSpokenLanguage,
};
