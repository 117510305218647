import { apiRequest } from '../api/api';
import {
  GoogleAuthorisationEndpoints,
  NotificationEndpoints,
  SpecialistEndpoints,
  UserEndpoints,
} from '../api/endpoints/endpoints';
import { ProfileType } from '../types/user';

const getProfile = async ({ id, rejectWithValue }) => {
  const [error, response] = await apiRequest.get<ProfileType>({
    endpoint: UserEndpoints.Root,
    endpointParams: { id, queryParams: 'include=telegram,headOrgSpecialist' },
  });
  return response ? response.data : rejectWithValue(error?.data);
};

const updateProfile = async ({ id, data, rejectWithValue }) => {
  const [error, response] = await apiRequest.patch({
    endpoint: UserEndpoints.Root,
    endpointParams: { id },
    data,
  });
  return response ? response.data : rejectWithValue(error?.data);
};

const changePassword = async ({ id, data, rejectWithValue }) => {
  const [error, response] = await apiRequest.patch({
    endpoint: UserEndpoints.Password,
    endpointParams: id,
    data,
  });
  return response ? response.data : rejectWithValue(error?.data);
};

const editProfile = async ({ id, data, rejectWithValue }) => {
  const [error, response] = await apiRequest.patch({
    endpoint: SpecialistEndpoints.Root,
    endpointParams: { id },
    data,
  });
  return response ? response.data.data : rejectWithValue(error?.data);
};

export const deleteAvatar = async ({ id, rejectWithValue }) => {
  const [error, response] = await apiRequest.delete({
    endpoint: UserEndpoints.AvatarUpload,
    endpointParams: id,
  });

  return response ? response.data : rejectWithValue(error?.data);
};

const deleteAccount = async ({ id, rejectWithValue }) => {
  const [error, response] = await apiRequest.delete({
    endpoint: UserEndpoints.Root,
    endpointParams: { id },
  });
  return response ? response.data : rejectWithValue(error?.data);
};

export const disconnectTelegram = async ({ id, rejectWithValue }) => {
  const [error, response] = await apiRequest.delete({
    endpoint: NotificationEndpoints.Telegram,
    endpointParams: id,
  });
  return response ? response.data : rejectWithValue(error?.data);
};

export const revokeGoogleCalendar = async ({ id, rejectWithValue }) => {
  const [error, response] = await apiRequest.patch({
    endpoint: GoogleAuthorisationEndpoints.Revoke,
    endpointParams: id,
    data: {},
  });
  return response?.data?.success ? response.data : rejectWithValue(error?.data);
};

export const userAPI = {
  getProfile,
  updateProfile,
  changePassword,
  editProfile,
  deleteAccount,
  disconnectTelegram,
  deleteAvatar,
  revokeGoogleCalendar,
};
