import styled from 'styled-components';
import { Flex } from '@beauty/beauty-market-ui';

export const PaperWrapper = styled(Flex)`
  width: 100%;
  padding: 24px;
  border-radius: 16px;
  box-sizing: border-box;
  background-color: ${({ theme }) => theme.colors.white.standard};
`;
