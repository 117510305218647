import { ChangeEvent, useEffect, useReducer, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { capitalize, isArray, isEmpty, isEqual } from 'lodash';
import {
  BodyLarge,
  BottomSheet,
  Button,
  DataList,
  Dropdown,
  Flex,
  H6,
  Input,
  NotifyStatus,
  TabStack,
  useNotify,
} from '@beauty/beauty-market-ui';
import { isRtl } from 'helpers';
import { useMediaScreen, useRequest, useTimeList } from 'hooks';
import { getSelectedLanguage, localesCalendarData } from '../../../constants';
import { useAppSelector } from '../../../store/hooks';
import { selectSpecialist, ThunkSpecialist } from '../../../store/redux-slices/specialistSlice';
import { ErrorDataType, SpecialistAction } from '../../../types';
import { PopUp, SidebarSheet } from '../../index';
import { SidebarFooter } from '../../SidebarFooter';
import {
  addOrganisation,
  changeDate,
  changeDay,
  changeIsWorkDay,
  changeOrganisation,
  changeRepeat,
  changeWeek,
  setDirty,
  setLoading,
  setOpen,
} from './scheduleReducer/actions';
import {
  checkOutOfSchedule,
  extractEditWorkingTimeParams,
  getEditScheduleError,
  hasScheduleChanged,
} from './scheduleReducer/helpers';
import { initialState } from './scheduleReducer/initialState';
import { scheduleReducer } from './scheduleReducer/scheduleReducer';
import { StrokedStack } from './StrokedStack';
import { StartScheduleZone } from './style';
import { TimeSection } from './TimeSection';

const EditSchedule = ({ selectedWeek, onClose }) => {
  const { t } = useTranslation();
  const { organization } = useAppSelector(selectSpecialist);
  const timeList = useTimeList();
  const notify = useNotify();
  const locale = getSelectedLanguage().toLowerCase();
  const rtl = isRtl();

  const editSchedule = useRequest(ThunkSpecialist.editGeneralSchedule, SpecialistAction.EditSchedule);

  const { isDesktop, isMobile } = useMediaScreen('md');

  const initialize = useRef(initialState(timeList, rtl, organization, selectedWeek));
  const [state, dispatch] = useReducer(scheduleReducer, null, () => initialize.current);

  const { date, repeatIndex, weekIndex, dayIndex, weeks, days, isLoading, isDirty, isOpen, errors } = state;

  const dayTabs = localesCalendarData[locale].weekdaysShort;

  const currentDayId = weeks[weekIndex][dayIndex];
  const currentDay = days[currentDayId];
  const dayTitle = t(`daysOfWeek.${capitalize(currentDay.dayOfWeek)}`);
  const daySubtitle = !currentDay.isWorkDay && t('daysOfWeek.nonWorkingDay');

  const weekNames = [
    { id: 0, applyForAll: t('week.applyForAll.one'), short: t('week.short.one'), full: t('week.full.one') },
    { id: 1, applyForAll: t('week.applyForAll.two'), short: t('week.short.two'), full: t('week.full.two') },
    { id: 2, applyForAll: t('week.applyForAll.three'), short: t('week.short.three'), full: t('week.full.three') },
    { id: 3, applyForAll: t('week.applyForAll.four'), short: t('week.short.four'), full: t('week.full.four') },
  ];
  const repeatOptions = [
    { id: 0, item: t('week.repeat.one'), disabled: false },
    { id: 1, item: t('week.repeat.two'), disabled: false },
    { id: 2, item: t('week.repeat.three'), disabled: false },
    { id: 3, item: t('week.repeat.four'), disabled: false },
  ];
  const organizationOptions = isArray(organization)
    ? organization.map(org => ({
        id: org.orgId,
        item: org.name,
        disabled: currentDay.dayOrgIds.includes(org.orgId),
      }))
    : [];

  const isWorkDayEmpty = currentDay.isWorkDay && isEmpty(currentDay.dayOrgIds);
  const isWorkDayNotEmpty = currentDay.isWorkDay && !isEmpty(currentDay.dayOrgIds);
  const hasUnselectedOrganizations =
    isWorkDayNotEmpty && !isEqual(currentDay.dayOrgIds.length, organizationOptions.length);

  const hasErrors = Object.values(errors).some(Boolean);
  const hasWorkDays = Object.values(days).some(day => day.isWorkDay && day.dayOrgIds.length > 0);

  const applyTitle = repeatIndex === 0 ? t('common.applyForAllTitle') : weekNames[weekIndex].applyForAll;

  const handleChangeDate = (e: ChangeEvent<HTMLInputElement>) => dispatch(changeDate(e.currentTarget.value));

  const handleChangeRepeat = (index: number) => dispatch(changeRepeat(index));

  const handleChangeWeek = (index: number) => dispatch(changeWeek(index));

  const handleChangeDay = (index: number) => dispatch(changeDay(index));

  const handleAddOrganisation = (index: number) => {
    const orgId = organizationOptions[index].id;
    const orgName = organizationOptions[index].item || '';
    dispatch(addOrganisation(orgId, orgName));
  };

  const handleAddOtherOrganisation = () => {
    const index = organizationOptions.findIndex(option => !currentDay.dayOrgIds.includes(option.id));
    if (index !== -1) handleAddOrganisation(index);
  };

  const handleChangeIsWorkDay = (checked: boolean) => {
    dispatch(changeIsWorkDay(checked));
    checked && organizationOptions.length === 1 && handleAddOrganisation(0);
  };

  const handleError = (err: ErrorDataType) => {
    const message = getEditScheduleError(err, t);
    if (message) {
      notify({
        id: message,
        status: NotifyStatus.ERROR,
        title: t('alerts.error'),
        subtitle: message,
      });
    }
    throw err;
  };

  const handleConfirm = () => {
    dispatch(setOpen(false));
    if (organization) {
      dispatch(setLoading(true));
      const params = organization.map(org => extractEditWorkingTimeParams(state, org.orgId, org.orgSpecId, timeList));
      editSchedule(params)
        .catch(handleError)
        .then(onClose)
        .finally(() => {
          dispatch(setLoading(false));
        });
    }
  };

  const handleSubmit = () => {
    const isOutOfSchedule = checkOutOfSchedule(state);
    if (isOutOfSchedule) {
      dispatch(setOpen(true));
    } else {
      handleConfirm();
    }
  };

  const handleBack = () => dispatch(setOpen(false));

  useEffect(() => {
    const isDaysDirty = hasScheduleChanged(days, initialize.current.days);
    const isDateDirty = !isEqual(date, initialize.current.date);
    const isRepeatDirty = !isEqual(repeatIndex, initialize.current.repeatIndex);
    const dirty = isDaysDirty || isDateDirty || isRepeatDirty;
    isDirty !== dirty && dispatch(setDirty(dirty));
  }, [days, repeatIndex, date]);

  return (
    <SidebarSheet
      onClose={onClose}
      onBack={onClose}
      label={t('specialists.fillWorkingTime')}
      descriptor={t('specialists.fillWorkingSchedule')}
      FooterBody={
        <SidebarFooter
          save
          cancel
          disabled={!isDirty || hasErrors || !hasWorkDays}
          isLoading={isLoading}
          onSubmit={handleSubmit}
          onBack={onClose}
        />
      }
    >
      <Flex flexDirection="column" gap="24px" ml="1px" mb="80px">
        <StartScheduleZone>
          <Dropdown
            options={repeatOptions}
            currentOption={repeatIndex}
            onChange={handleChangeRepeat}
            placeholder={t('specialists.repeat')}
          />
          <Input
            id="ScheduleStartDate"
            name="ScheduleStartDate"
            value={date}
            design="white"
            onChange={handleChangeDate}
            nonValid={errors.date}
            caption={errors.date && t('validation.correctDate')}
            placeholder={t('specialists.startScheduleFrom')}
          />
        </StartScheduleZone>
        <StrokedStack
          items={weekNames}
          active={weekIndex}
          onChange={handleChangeWeek}
          size={repeatIndex}
          isDesktop={isDesktop}
        />
        {repeatIndex > 0 && <H6>{weekNames[weekIndex].full}</H6>}
        <TabStack items={dayTabs} active={dayIndex} onStackClick={handleChangeDay} />
        <DataList
          label={dayTitle}
          description={daySubtitle}
          defaultChecked={currentDay.isWorkDay}
          onCheckboxClick={handleChangeIsWorkDay}
          hovered={false}
        />
        {isWorkDayNotEmpty &&
          currentDay.dayOrgIds.map(dayOrgId => {
            const currentDayOrg = currentDay.dayOrgMap[dayOrgId];
            const orgIndex = organizationOptions.findIndex(option => option.id === currentDayOrg.orgId);

            const handleChangeOrganisation = (index: number) => {
              const newOrgId = organizationOptions[index].id;
              const newOrgName = organizationOptions[index].item || '';
              dispatch(changeOrganisation(currentDayOrg.orgId, newOrgId, newOrgName));
            };

            return (
              <TimeSection
                key={currentDayOrg.id}
                errors={errors}
                dispatch={dispatch}
                applyTitle={applyTitle}
                {...currentDayOrg}
              >
                {organizationOptions.length > 1 && (
                  <Dropdown
                    currentOption={orgIndex}
                    options={organizationOptions}
                    onChange={handleChangeOrganisation}
                    placeholder={t('specialists.organizationAddress')}
                  />
                )}
              </TimeSection>
            );
          })}
        {isWorkDayEmpty && (
          <Dropdown
            currentOption={-1}
            options={organizationOptions}
            onChange={handleAddOrganisation}
            placeholder={t('specialists.organizationAddress')}
          />
        )}
        {hasUnselectedOrganizations && (
          <Button design="quaternary" onClick={handleAddOtherOrganisation}>
            {t('specialists.addTimeForOtherAddress')}
          </Button>
        )}
      </Flex>
      {isOpen && isDesktop && (
        <PopUp
          title={t('specialists.changingInTimeSchedule')}
          description={t('specialists.youTriedToChangeWorkingSchedule')}
          onSubmit={handleConfirm}
          onClose={handleBack}
          confirm={t('button.changeIt')}
          cancel={t('button.back')}
        />
      )}
      {isOpen && isMobile && (
        <BottomSheet
          isOpen
          onClose={handleBack}
          label={t('specialists.changingInTimeSchedule')}
          content={<BodyLarge>{t('specialists.youTriedToChangeWorkingSchedule')}</BodyLarge>}
          detent="content-height"
          FooterBody={
            <Flex flexDirection="column" width="100%" gap="8px">
              <Button onClick={handleConfirm}>{t('button.changeIt')}</Button>
              <Button onClick={handleBack} design="secondary">
                {t('button.back')}
              </Button>
            </Flex>
          }
        />
      )}
    </SidebarSheet>
  );
};

export default EditSchedule;
