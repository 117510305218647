import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import capitalize from 'lodash/capitalize';
import isEmpty from 'lodash/isEmpty';
import { BodyLarge, Button, EditIcon, Separator } from '@beauty/beauty-market-ui';
import { PopUp, ScheduleForm, SidebarFooter, SidebarSheet, InfoBlock } from 'components';
import { checkAccess, hasScheduleChanges, isRtl, isScheduleValid } from 'helpers';
import { useMediaScreen, useRequest } from 'hooks';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { selectNewAddress, setSchedule, ThunkAddress } from 'store/redux-slices/addressSlice';
import { AddressAction } from 'types';
import { AccessRange } from '../../../../../constants';
import { AddressWorkDay } from './AddressWorkDay';

export const WorkingTime = () => {
  const { t } = useTranslation();
  const rtl = isRtl();
  const { orgSpecialist, schedule, prevSchedule, allDayTemplate } = useAppSelector(selectNewAddress);
  const dispatch = useAppDispatch();
  const isOwner = checkAccess(AccessRange.OWNER);

  const editWorkingTime = useRequest(ThunkAddress.editOrganization, AddressAction.EditWorkingTime);

  const currentSchedule = allDayTemplate || schedule;

  const { isDesktop } = useMediaScreen('md');

  const [isValid, setIsValid] = useState(true);
  const [isDirty, setIsDirty] = useState(false);
  const [isSubmitting, setSubmitting] = useState(false);
  const [isPopupOpen, setPopupOpen] = useState(false);
  const [isEditbarOpen, setEditbarOpen] = useState(false);

  const rtlSchedule = schedule.length ? [schedule[schedule.length - 1], ...schedule.slice(0, -1)] : [];
  const scheduleItems = (rtl ? rtlSchedule : schedule).map((props, index, arr) => {
    const { dayOfWeek, start, end, breakStart, breakEnd, isWorkDay } = props;
    const time = rtl ? `${end}-${start}` : `${start}-${end}`;
    const breakTime = rtl ? `${breakEnd}-${breakStart}` : `${breakStart}-${breakEnd}`;
    const label = isWorkDay ? time : t('daysOfWeek.dayOff');
    const description = isWorkDay && breakStart && breakEnd ? `${t('time.breakTime')} ${breakTime}` : '';
    return (
      <AddressWorkDay
        key={dayOfWeek}
        title={t(`daysOfWeek.${capitalize(dayOfWeek)}`)}
        subtitle=""
        label={label}
        description={description}
        enableSeparator={index !== arr.length - 1}
      />
    );
  });

  const handleEditWorkingTimeClick = () => setEditbarOpen(true);

  const handleOpenPopup = () => {
    setEditbarOpen(false);
    setPopupOpen(true);
  };

  const handleCloseForm = () => {
    dispatch(setSchedule(prevSchedule));
    setEditbarOpen(false);
    setPopupOpen(false);
  };

  const handleSubmitForm = () => {
    setSubmitting(true);
    editWorkingTime().finally(() => {
      setPopupOpen(false);
      setEditbarOpen(false);
      setSubmitting(false);
    });
  };

  useEffect(() => {
    const dirty = hasScheduleChanges(prevSchedule, currentSchedule);
    dirty !== isDirty && setIsDirty(dirty);
  }, [currentSchedule, prevSchedule, isDirty]);

  useEffect(() => {
    const valid = isScheduleValid(currentSchedule);
    valid !== isValid && setIsValid(valid);
  }, [currentSchedule, isValid]);

  return (
    <>
      <InfoBlock
        title={t('address.workingTime')}
        placeholder={t('address.haveNotFilledWorkingTime')}
        description={t('address.workingSchedule')}
        visibleCount={8}
        count={scheduleItems.length}
        badge={null}
        position={isOwner ? 'middle' : 'last'}
        separator={isEmpty(scheduleItems) && isDesktop ? <Separator mb="16px" /> : null}
        button={
          <Button
            size="extraSmall"
            design={scheduleItems.length ? 'secondary' : 'primary'}
            prefix={scheduleItems.length ? <EditIcon height="16px" width="16px" /> : null}
            onClick={handleEditWorkingTimeClick}
          >
            {isDesktop && (scheduleItems.length ? t('button.edit') : t('address.fillSchedule'))}
          </Button>
        }
        emptyButton={
          <Button size="small" onClick={handleEditWorkingTimeClick} my="16px">
            {t('address.chooseEmployees')}
          </Button>
        }
      >
        {scheduleItems}
      </InfoBlock>
      {isEditbarOpen && (
        <SidebarSheet
          onClose={handleCloseForm}
          onBack={handleCloseForm}
          detent="full-height"
          label={t('address.editWorkingTime')}
          descriptor={t('address.fillActualSchedule')}
          FooterBody={
            <SidebarFooter
              save
              disabled={!isDirty || !isValid}
              isLoading={isSubmitting}
              onSubmit={isEmpty(orgSpecialist) ? handleSubmitForm : handleOpenPopup}
              onBack={handleCloseForm}
            />
          }
        >
          <ScheduleForm title="" />
        </SidebarSheet>
      )}
      {isPopupOpen && (
        <PopUp
          isOpen={isPopupOpen}
          title={t('address.changingTimeSchedule')}
          description=""
          onSubmit={handleSubmitForm}
          onClose={handleCloseForm}
          confirm={t('button.changeIt')}
          cancel={t('button.cancel')}
          isLoading={isSubmitting}
        >
          <BodyLarge mb="24px">{t('address.youTriedToChangeSchedule')}</BodyLarge>
        </PopUp>
      )}
    </>
  );
};
