import moment from 'moment';
import { ActionType, ScheduleActionsType } from './actions';
import { clearErrors, clearOrganisationErrors, clearWeekOrganisationAllDays, getDayId, setErrors } from './helpers';
import { ScheduleStateType } from './types';

export const scheduleReducer = (state: ScheduleStateType, action: ScheduleActionsType) => {
  switch (action.type) {
    case ActionType.SetDirty:
      return { ...state, isDirty: action.payload };
    case ActionType.SetOpen:
      return { ...state, isOpen: action.payload };
    case ActionType.SetLoading:
      return { ...state, isLoading: action.payload };
    case ActionType.ChangeDate: {
      const dateFormats = ['DD.MM.YYYY', 'DD/MM/YYYY', 'DD-MM-YYYY'];
      const isValid = dateFormats.some(format => moment(action.payload, format, true).isValid());
      return { ...state, date: action.payload, errors: { ...state.errors, date: !isValid } };
    }
    case ActionType.ChangeWeek:
      return { ...state, weekIndex: action.payload };
    case ActionType.ChangeDay: {
      return { ...state, dayIndex: action.payload };
    }
    case ActionType.ChangeRepeat: {
      const { payload } = action;
      const { weekIndex, dayIndex } = state;

      return {
        ...state,
        repeatIndex: payload,
        weekIndex: payload >= weekIndex ? weekIndex : payload,
        dayIndex: payload >= weekIndex ? dayIndex : 0,
      };
    }
    case ActionType.ChangeIsWorkDay: {
      const { days, errors } = state;
      const dayId = getDayId(state);

      const updatedDay = { ...days[dayId] };
      const updatedErrors = { ...errors };

      clearErrors(updatedDay, updatedErrors);

      updatedDay.dayOrgIds = [];
      updatedDay.dayOrgMap = {};
      updatedDay.isWorkDay = action.payload;

      return { ...state, days: { ...days, [dayId]: updatedDay }, errors: updatedErrors };
    }
    case ActionType.SetOrgWeek: {
      const { orgId, checked } = action.payload;
      const { weeks, days, weekIndex } = state;
      const dayId = getDayId(state);
      const week = weeks[weekIndex];
      const updatedDays = { ...days };

      if (checked) {
        // remove is ApplyForAllDays for current organization for all days of current week
        clearWeekOrganisationAllDays(week, updatedDays, orgId);
        // set isApplyForAllDays true for current organisation of current week
        updatedDays[dayId].dayOrgMap[orgId] = { ...days[dayId].dayOrgMap[orgId], isApplyForAllDays: true };
      } else {
        updatedDays[dayId].dayOrgMap[orgId] = { ...days[dayId].dayOrgMap[orgId], isApplyForAllDays: false };
      }

      return { ...state, days: updatedDays };
    }
    case ActionType.ChangeStartTime: {
      const { days, errors } = state;
      const { orgId, startIndex } = action.payload;
      const dayId = getDayId(state);
      const updatedErrors = { ...errors };

      const updatedDay = {
        ...days[dayId],
        dayOrgMap: {
          ...days[dayId].dayOrgMap,
          [orgId]: {
            ...days[dayId].dayOrgMap[orgId],
            isApplyForAllDays: false,
            startIndex,
          },
        },
      };

      setErrors(orgId, updatedDay, updatedErrors);

      return { ...state, days: { ...days, [dayId]: updatedDay }, errors: updatedErrors };
    }
    case ActionType.ChangeEndTime: {
      const { days, errors } = state;
      const { orgId, endIndex } = action.payload;
      const dayId = getDayId(state);
      const updatedErrors = { ...errors };

      const updatedDay = {
        ...days[dayId],
        dayOrgMap: {
          ...days[dayId].dayOrgMap,
          [orgId]: {
            ...days[dayId].dayOrgMap[orgId],
            isApplyForAllDays: false,
            endIndex,
          },
        },
      };

      setErrors(orgId, updatedDay, updatedErrors);

      return { ...state, days: { ...days, [dayId]: updatedDay }, errors: updatedErrors };
    }
    case ActionType.DeleteOrganisation: {
      const { days, errors } = state;
      const dayId = getDayId(state);
      const updatedDay = { ...days[dayId] };
      const updatedErrors = { ...errors };
      const orgId = action.payload;

      clearOrganisationErrors(updatedDay.dayOrgMap[orgId], errors);

      updatedDay.dayOrgIds = updatedDay.dayOrgIds.filter(id => id !== orgId);
      updatedDay.isWorkDay = !!updatedDay.dayOrgIds.length;

      return { ...state, days: { ...days, [dayId]: updatedDay }, errors: updatedErrors };
    }
    case ActionType.AddOrganisation: {
      const { days, errors, orgMap } = state;
      const { orgId, orgName } = action.payload;
      const dayId = getDayId(state);
      const dayName = days[dayId].dayOfWeek;
      const updatedDay = { ...days[dayId] };
      const updatedErrors = { ...errors };

      const id = crypto.randomUUID();
      updatedDay.dayOrgIds = [...updatedDay.dayOrgIds, orgId];
      updatedDay.dayOrgMap[orgId] = {
        id,
        orgId,
        orgName,
        startIndex: orgMap[orgId][dayName] ? orgMap[orgId][dayName].startIndex : -1,
        endIndex: orgMap[orgId][dayName] ? orgMap[orgId][dayName].endIndex : -1,
        breaks: [],
        isApplyForAllDays: false,
      };

      updatedErrors[id] = '';

      setErrors(orgId, updatedDay, updatedErrors);

      return { ...state, days: { ...days, [dayId]: updatedDay }, errors: updatedErrors };
    }
    case ActionType.ChangeOrganisation: {
      const { days } = state;
      const { orgId, newOrgId, newOrgName } = action.payload;
      const dayId = getDayId(state);
      const updatedDay = { ...days[dayId] };

      updatedDay.dayOrgMap[newOrgId] = {
        ...updatedDay.dayOrgMap[orgId],
        orgId: newOrgId,
        orgName: newOrgName,
        isApplyForAllDays: false,
      };
      updatedDay.dayOrgIds = updatedDay.dayOrgIds.map(dayOrgId => (dayOrgId === orgId ? newOrgId : dayOrgId));
      // if (updatedDay.dayOrgMap[orgId]) delete updatedDay.dayOrgMap[orgId];

      return { ...state, days: { ...days, [dayId]: updatedDay } };
    }
    case ActionType.CreateBreak: {
      const { days, errors } = state;
      const { orgId, value } = action.payload;
      const dayId = getDayId(state);

      const updatedErrors = { ...errors };
      const updatedDay = {
        ...days[dayId],
        dayOrgMap: {
          ...days[dayId].dayOrgMap,
          [orgId]: {
            ...days[dayId].dayOrgMap[orgId],
            isApplyForAllDays: false,
            breaks: [...days[dayId].dayOrgMap[orgId].breaks, value],
          },
        },
      };

      setErrors(orgId, updatedDay, updatedErrors);

      return { ...state, days: { ...days, [dayId]: updatedDay }, errors: updatedErrors };
    }
    case ActionType.DeleteBreak: {
      const { days, errors } = state;
      const { orgId, breakId } = action.payload;
      const dayId = getDayId(state);

      const updatedErrors = { ...errors };
      const updatedDay = {
        ...days[dayId],
        dayOrgMap: {
          ...days[dayId].dayOrgMap,
          [orgId]: {
            ...days[dayId].dayOrgMap[orgId],
            isApplyForAllDays: false,
            breaks: days[dayId].dayOrgMap[orgId].breaks.filter(breakOne => breakId !== breakOne.id),
          },
        },
      };

      if (updatedErrors[breakId]) delete updatedErrors[breakId];

      return { ...state, days: { ...days, [dayId]: updatedDay }, errors: updatedErrors };
    }
    case ActionType.ChangeStartBreak: {
      const { days, errors } = state;
      const { orgId, breakId, startIndex } = action.payload;
      const dayId = getDayId(state);
      const updatedErrors = { ...errors };

      const updatedDay = {
        ...days[dayId],
        dayOrgMap: {
          ...days[dayId].dayOrgMap,
          [orgId]: {
            ...days[dayId].dayOrgMap[orgId],
            isApplyForAllDays: false,
            breaks: days[dayId].dayOrgMap[orgId].breaks.map(breakOne => {
              if (breakId !== breakOne.id) return breakOne;
              return { ...breakOne, startIndex };
            }),
          },
        },
      };

      setErrors(orgId, updatedDay, updatedErrors);

      return { ...state, days: { ...days, [dayId]: updatedDay }, errors: updatedErrors };
    }

    case ActionType.ChangeEndBreak: {
      const { days, errors } = state;
      const { orgId, breakId, endIndex } = action.payload;
      const dayId = getDayId(state);
      const updatedErrors = { ...errors };

      const updatedDay = {
        ...days[dayId],
        dayOrgMap: {
          ...days[dayId].dayOrgMap,
          [orgId]: {
            ...days[dayId].dayOrgMap[orgId],
            isApplyForAllDays: false,
            breaks: days[dayId].dayOrgMap[orgId].breaks.map(breakOne => {
              if (breakId !== breakOne.id) return breakOne;
              return { ...breakOne, endIndex };
            }),
          },
        },
      };

      setErrors(orgId, updatedDay, updatedErrors);

      return { ...state, days: { ...days, [dayId]: updatedDay }, errors: updatedErrors };
    }
    default:
      return state;
  }
};
