import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import isEmpty from 'lodash/isEmpty';
import uniq from 'lodash/uniq';
import { Nullable } from 'tsdef';
import { Badge, Caption, colors, Separator, Slider, TagButton } from '@beauty/beauty-market-ui';
import { EditServices, PopUp } from 'components';
import { getFormattedCurrency, getTranslation, isRtl } from 'helpers';
import { useMediaScreen, useRequest } from 'hooks';
import { useAppSelector } from 'store/hooks';
import { selectAllServices } from 'store/redux-slices/serviceSlice';
import { selectSpecialist, ThunkSpecialist } from 'store/redux-slices/specialistSlice';
import { SpecialistAction } from 'types';
import { SpecOrgStateType } from '../../../../../components/Employees/types';
import { getSelectedLanguage } from '../../../../../constants';
import { selectCurrency } from '../../../../../store/redux-slices/organisationSlice';
import { EmployeeSection } from '../EmployeeSection/EmployeeSection';
import { ServiceItem } from './ServiceItem';

export const EmployeeServices = () => {
  const { t } = useTranslation();
  const { isDesktop } = useMediaScreen('md');
  const { organization } = useAppSelector(selectSpecialist);
  const currency = useAppSelector(selectCurrency);
  const allServices = useAppSelector(selectAllServices);
  const language = getSelectedLanguage();
  const rtl = isRtl();

  const deleteService = useRequest(ThunkSpecialist.editServices, SpecialistAction.EditServices);

  const [selectedOrgId, setSelectedOrgId] = useState('');
  const [selectedService, setSelectedService] = useState('');
  const [action, setAction] = useState<SpecialistAction>(SpecialistAction.None);
  const [isSubmitting, setSubmitting] = useState(false);
  const [orgData, setOrgData] = useState(organization ?? []);
  const [headOrgServiceIds, setHeadOrgServiceIds] = useState<string[]>([]);

  const { orgIds, orgMap } = orgData.reduce(
    (acc, org) => {
      org.orgId && acc.orgIds.push(org.orgId);
      const activeServices = org?.orgService.filter(service => !service.deleted);
      acc.orgMap[org.orgId] = {
        isDirty: false,
        orgName: org.name || '',
        orgSpecId: org?.orgSpecId || '',
        orgService: activeServices || [],
        services: activeServices.map(service => service.id),
      };
      return acc;
    },
    { orgIds: [], orgMap: {} } as SpecOrgStateType,
  );

  const handleClose = () => {
    setAction(SpecialistAction.None);
  };
  const handleEditServices = () => {
    setAction(SpecialistAction.EditServices);
  };

  const handleDeleteClick = (id: string) => {
    setSelectedService(id);
    setAction(SpecialistAction.DeleteService);
  };

  const handleDeleteService = () => {
    setSubmitting(true);
    const filteredServices = orgMap[selectedOrgId].services.filter(service => service !== selectedService);
    const params = {
      orgId: selectedOrgId,
      orgSpecId: orgMap[selectedOrgId].orgSpecId,
      orgServiceIds: filteredServices.join(','),
    };
    deleteService(params).finally(() => {
      handleClose();
      setSubmitting(false);
    });
  };

  const getPrice = (price: Nullable<number>) =>
    price === null ? t('settings.services.nullPrice') : getFormattedCurrency(price, currency, language);

  const getOrgServiceList = (orgId: string) =>
    orgMap[orgId].orgService.map((item, index, arr) => (
      <ServiceItem
        key={item.id}
        name={item.title.text}
        price={getPrice(item.price)}
        category={item.category ? getTranslation(item.category.title, language) : ''}
        onDelete={() => handleDeleteClick(item.id)}
        separator={index !== arr.length - 1}
        isDesktop={isDesktop}
      />
    ));

  const getAllOrgServiceList = () => {
    const uniqueIds = uniq(headOrgServiceIds);
    return allServices
      .filter(service => uniqueIds.includes(service.id))
      .map((service, index, arr) => (
        <ServiceItem
          key={service.id}
          name={service.title.text}
          price={getPrice(service.listPrice)}
          category={service.category ? getTranslation(service.category.title, language) : ''}
          separator={index !== arr.length - 1}
          isDesktop={isDesktop}
        />
      ));
  };

  const specialistServiceItems = selectedOrgId ? getOrgServiceList(selectedOrgId) : getAllOrgServiceList();

  const slides = !isEmpty(orgIds)
    ? orgIds.map(orgId => (
        <TagButton
          key={orgId}
          text={orgMap[orgId].orgName}
          number={orgMap[orgId].services.length || null}
          design={orgId === selectedOrgId ? 'active' : 'default'}
          onClick={() => setSelectedOrgId(orgId)}
          isDesktop={isDesktop}
        />
      ))
    : [];

  useEffect(() => {
    const ids = orgData.flatMap(
      org =>
        org?.orgService?.flatMap(item => {
          if (item.deleted) return [];
          return item.headOrgServiceId ? [item.headOrgServiceId] : [];
        }) || [],
    );
    setOrgData(organization ?? []);
    setHeadOrgServiceIds(ids);
  }, [organization]);

  return (
    <>
      <EmployeeSection
        onClick={handleEditServices}
        count={headOrgServiceIds.length}
        title={t('specialists.services')}
        description={t('specialists.whatKindOfServices')}
        placeholder={t('specialists.haveNotServices')}
        buttonLabel={t('specialists.chooseServices')}
        separator={
          isEmpty(slides) && isDesktop ? (
            <Separator mb="16px" />
          ) : (
            <Slider inlineSlider slides={slides} mb="16px" rtl={rtl} />
          )
        }
        badge={headOrgServiceIds.length ? <Badge text={headOrgServiceIds.length} design="lightGrey" /> : null}
        visibleCount={1}
      >
        {isEmpty(specialistServiceItems) && !isEmpty(headOrgServiceIds) ? (
          <Caption color={colors.grey.dark} mt="16px">
            {t('specialists.haveNotServices')}
          </Caption>
        ) : (
          specialistServiceItems
        )}
      </EmployeeSection>
      {action === SpecialistAction.DeleteService && (
        <PopUp
          title={t('specialists.deleteService')}
          description={t('specialists.areYouSureDeleteService')}
          onClose={handleClose}
          onSubmit={handleDeleteService}
          confirm={t('button.delete')}
          isLoading={isSubmitting}
        />
      )}
      {action === SpecialistAction.EditServices && (
        <EditServices orgIds={orgIds} initial={orgMap} onClose={handleClose} />
      )}
    </>
  );
};
