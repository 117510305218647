import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { Flex, H7 } from '@beauty/beauty-market-ui';
import { SidebarFooter, SidebarSheet } from 'components';
import { getSelectedLanguage } from '../../../../constants';
import { getFormattedCurrency, getTranslation } from '../../../../helpers';
import { useAppSelector } from '../../../../store/hooks';
import { selectCurrency } from '../../../../store/redux-slices/organisationSlice';
import { ClientPayments, PaymentType } from '../../../../types';
import { InfoItem } from './InfoItem';

type Props = {
  payment: ClientPayments;
  onClose: () => void;
};

export const PaymentInfo = ({ payment, onClose }: Props) => {
  const { t } = useTranslation();

  const language = getSelectedLanguage();
  const currency = useAppSelector(selectCurrency);

  const receiptInfo = [
    {
      title: t('clients.profile.dateAndTime'),
      subtitle: moment(payment.date).format('DD.MM.YYYY HH:mm'),
    },
    {
      title: t('clients.profile.clientName'),
      subtitle: payment.clientName,
    },
    {
      title: t('clients.profile.idClient'),
      subtitle: '',
    },
    {
      title: t('clients.profile.payments.specialist'),
      subtitle:
        payment?.specialist
          ?.split(' ')
          .filter(part => part !== 'undefined')
          .join(' ') || '',
    },
  ].filter(item => !!item.subtitle);

  const paymentInfo = [
    {
      title: t('clients.profile.typeOfSale'),
      subtitle: payment.paymentMethod === PaymentType.CASH ? t('clients.profile.byCash') : t('clients.profile.byCard'),
    },
    {
      title: t('clients.profile.cardNumber'),
      subtitle: '',
    },
  ].filter(item => !!item.subtitle);

  const orderInfo = [
    {
      subtitle: getTranslation(payment.service, language),
      description: getFormattedCurrency(payment.price, currency, language),
    },
    {
      subtitle: t('clients.profile.total'),
      description: getFormattedCurrency(payment.price, currency, language),
      padding: '48px 0 32px',
    },
  ];

  const sidebarProps = {
    isOpen: true,
    label: t('clients.profile.payments.appointmentReceipt'),
    descriptor: t('clients.profile.payments.receiptAboutPayment'),
    onClose,
    FooterBody: <SidebarFooter onBack={onClose} cancelLabel={t('button.close')} info disabled={false} />,
  };

  return (
    <SidebarSheet {...sidebarProps}>
      <Flex flexDirection="column">
        {/* <H7>{t('clients.profile.payments.receiptNo')}</H7> */}
        {receiptInfo.map((item, index, arr) => (
          <InfoItem key={item.title} title={item.title} subtitle={item.subtitle} separator={index !== arr.length - 1} />
        ))}
      </Flex>
      <Flex flexDirection="column" mt="24px">
        <H7 mb="16px">{t('clients.profile.payments.paymentInformation')}</H7>
        {paymentInfo.map((item, index, arr) => (
          <InfoItem key={item.title} title={item.title} subtitle={item.subtitle} separator={index !== arr.length - 1} />
        ))}
      </Flex>
      <Flex flexDirection="column" mt="24px">
        <H7 mb="16px">{t('clients.profile.payments.orderInformation')}</H7>
        {orderInfo.map((item, index, arr) => (
          <InfoItem
            key={item.subtitle}
            subtitle={item.subtitle}
            description={item?.description}
            p={item?.padding}
            separator={index !== arr.length - 1}
          />
        ))}
      </Flex>
    </SidebarSheet>
  );
};
