import { userAPI } from '../../helpers/userAPI';
import { SpecialistResponse } from '../../types';
import { ProfileType } from '../../types/user';
import { createAppAsyncThunk } from '../create-app-async-thunk';

export const getProfile = createAppAsyncThunk<ProfileType, string>('user/getProfile', (id, { rejectWithValue }) =>
  userAPI.getProfile({ id, rejectWithValue }),
);

export const editProfile = createAppAsyncThunk<SpecialistResponse, { id: string; data: FormData }>(
  'user/editProfile',
  async ({ id, data }, { rejectWithValue }) => userAPI.editProfile({ id, data, rejectWithValue }),
);

export const changePassword = createAppAsyncThunk<void, { oldPassword: string; newPassword: string }>(
  'user/changePassword',
  async (data, { getState, rejectWithValue }) => {
    const { id } = getState().user.profile;
    return userAPI.changePassword({ id, data, rejectWithValue });
  },
);

export const deleteAccount = createAppAsyncThunk<void, void>(
  'user/deleteAccount',
  (_, { getState, rejectWithValue }) => {
    const { id } = getState().user.profile;
    return userAPI.deleteAccount({ id, rejectWithValue });
  },
);

export const deleteUserAvatar = createAppAsyncThunk<void, void>(
  'user/deleteUserAvatar',
  (_, { getState, rejectWithValue }) => {
    const { id } = getState().user.profile;
    return userAPI.deleteAvatar({ id, rejectWithValue });
  },
);

export const disconnectTelegram = createAppAsyncThunk<void, void>(
  'user/deleteAccount',
  (_, { getState, rejectWithValue }) => {
    const { id } = getState().user.profile;
    return userAPI.disconnectTelegram({ id, rejectWithValue });
  },
);

export const revokeGoogleCalendar = createAppAsyncThunk<
  {
    success: boolean;
  },
  void
>('user/revokeGoogleCalendar', (_, { getState, rejectWithValue }) => {
  const { id } = getState().user.profile;
  return userAPI.revokeGoogleCalendar({ id, rejectWithValue });
});

export const updateNotificationLanguage = createAppAsyncThunk<void, { accountData: { language: string } }>(
  'user/updateNotificationLanguage',
  async (data, { getState, rejectWithValue }) => {
    const { id } = getState().user.profile;
    return userAPI.updateProfile({ id, data, rejectWithValue });
  },
);
