import { useEffect } from 'react';
import { Views } from 'react-big-calendar';
import { isArray } from 'lodash';
import moment, { Moment } from 'moment';
import { getMinWidthMediaQuery, useMediaQuery } from '@beauty/beauty-market-ui';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import {
  clearAppointments,
  selectAppointments,
  setDate,
  setRange,
  setView,
} from 'store/redux-slices/appointmentsSlice';
import { selectUser, selectUserHeadOrgId, selectUserOrgSpecialist } from 'store/redux-slices/userSlice';
import { AccessRange } from '../../constants';
import { checkAccess } from '../../helpers/utils';
import { useCalendarData } from '../../hooks/useCalendarData';
import { useSubscribeToAppointment } from '../../hooks/useSubscribeToAppointment';
import { ThunkSpecialist } from '../../store/redux-slices/specialistSlice';
import { DateRangeType } from '../../types';
import { getISOEndDay, getISOStartDay } from './helpers';
import { useDayHeader } from './useDayHeader';

type UseCustomCalendarProps = {
  onAddBreak: () => void;
  setBreakSpecialist: (id: string) => void;
  setCancelAllSpecialist: (orgSpecId: string) => void;
  onCancelAll: () => void;
  setDateFromMenu: (date: string) => void;
};

export const useCustomCalendar = ({
  onAddBreak,
  setBreakSpecialist,
  setCancelAllSpecialist,
  onCancelAll,
  setDateFromMenu,
}: UseCustomCalendarProps) => {
  const {
    range,
    view: selectedView,
    date: selectedWeekday,
    selectedAddress: organisationId,
    specialistIds,
  } = useAppSelector(selectAppointments);

  const dispatch = useAppDispatch();
  const isUserAdmin = checkAccess(AccessRange.ADMIN);
  const isSuperAdmin = checkAccess(AccessRange.SUPERADMIN);

  const orgSpecialist = useAppSelector(selectUserOrgSpecialist);
  const userHeadOrgId = useAppSelector(selectUserHeadOrgId);
  const { user } = useAppSelector(selectUser);

  const mediaQuery = getMinWidthMediaQuery('md');
  const isMobile = !useMediaQuery(mediaQuery);

  const { events, unclosedEvents, inProgressEvents, resourceMap, specialistCount } = useCalendarData({
    onAddBreak,
    setBreakSpecialist,
    setCancelAllSpecialist,
    onCancelAll,
    setDateFromMenu,
    selectedWeekday,
    isShowPhone: isUserAdmin,
    isMobile,
  });

  const { resources, DayHeader } = useDayHeader(selectedView, resourceMap);

  useSubscribeToAppointment(
    isUserAdmin,
    organisationId as string,
    isUserAdmin ? orgSpecialist?.id : specialistIds[0],
    range?.start,
    range?.end,
    selectedView,
  );

  const handleChangeView = (currentView: Views) => {
    dispatch(setView(currentView));
  };

  const handleNavigate = (date: Moment) => {
    dispatch(setDate(moment(date).format('YYYY-MM-DD')));
  };

  const handleRangeChange = (newRange: DateRangeType) => {
    dispatch(clearAppointments());
    const start = isArray(newRange) ? newRange[0] : newRange.start;
    let end = isArray(newRange) ? newRange[newRange.length - 1] : newRange.end;
    if (moment(end).get('hours') === 0 && moment(end).get('minutes') === 0)
      end = moment(end).add(1, 'day').toISOString();
    dispatch(setRange({ start: getISOStartDay(start), end: getISOEndDay(end) }));
  };

  useEffect(() => {
    if (!isSuperAdmin && user.userId) {
      // need for getting specialist organisations for filter addresses in header
      dispatch(ThunkSpecialist.fetchSpecialist(user.userId));
    }
  }, [user.userId, isSuperAdmin]);

  return {
    selectedView,
    selectedWeekday,
    events,
    unclosedEvents,
    inProgressEvents,
    resources,
    user,
    userHeadOrgId,
    organisationId,
    handleChangeView,
    handleNavigate,
    handleRangeChange,
    isUserAdmin,
    isSuperAdmin,
    orgSpecialist,
    DayHeader,
    specialistCount,
  };
};
