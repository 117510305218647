import { BodyLarge, Caption, colors, Flex, Separator } from '@beauty/beauty-market-ui';

type Props = {
  label: string;
  title?: string;
  large?: boolean;
  separator?: boolean;
  placeholder?: string;
  dir?: 'ltr' | 'rtl';
};

export const ProfileItem = ({ label, title, large, separator, placeholder, dir }: Props) => (
  <>
    <Flex p="16px 0" flexDirection="column" alignItems="start">
      <Caption color={colors.grey.dark}>{label}</Caption>
      {large || !title ? (
        <BodyLarge large dir={dir}>
          {title || placeholder}
        </BodyLarge>
      ) : (
        <Caption dir={dir}>{title}</Caption>
      )}
    </Flex>
    {separator && <Separator />}
  </>
);
