import { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Alert,
  AlertTypes,
  UploadPhotos,
  Caption,
  colors,
  H6,
  Flex,
  getMinWidthMediaQuery,
  useMediaQuery,
} from '@beauty/beauty-market-ui';
import { checkPhotosValid, hasArrayChanged } from 'helpers/utils';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { deletePhoto, selectPhotos, updatePhotos } from 'store/redux-slices/serviceSlice';
import { FormPropsType } from 'types';
import { MAX_PHOTO_SERVICE_COUNT } from '../../../constants';

export const PhotoServiceForm = ({ title, setIsDirty, setIsValid, showCaption = false }: FormPropsType) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const photos = useAppSelector(selectPhotos);
  const photosRef = useRef(photos);
  const [error, setError] = useState<null | string>('');

  const mediaQuery = getMinWidthMediaQuery('md');
  const isDesktop = useMediaQuery(mediaQuery);

  const handleUploadPhotos = (newPhotos: File[]) => {
    const updatedPhotos = [...photos, ...newPhotos];
    const errorMessage = checkPhotosValid(updatedPhotos, t, MAX_PHOTO_SERVICE_COUNT);
    setError(errorMessage);
    setIsDirty && setIsDirty(true);
    setIsValid && setIsValid(!errorMessage);
    !errorMessage && dispatch(updatePhotos(updatedPhotos));
  };

  const handleDeletePhoto = (photoToDelete: File | string) => {
    const filteredPhotos = photos.filter(photo => photo !== photoToDelete);
    const errorMessage = checkPhotosValid(filteredPhotos, t, MAX_PHOTO_SERVICE_COUNT);
    const dirty = hasArrayChanged(filteredPhotos, photosRef.current);
    setError(errorMessage);
    setIsDirty && setIsDirty(dirty);
    setIsValid && setIsValid(!errorMessage);
    dispatch(deletePhoto(photoToDelete));
  };

  const isPreventExtension = photos.length && error === null;

  return (
    <Flex flexDirection="column" gap="16px">
      {title && <H6>{title}</H6>}
      <UploadPhotos
        max={MAX_PHOTO_SERVICE_COUNT}
        images={photos}
        onUpload={handleUploadPhotos}
        onDelete={handleDeletePhoto}
        preventExtension={isPreventExtension}
        mode={isDesktop ? 'hover' : 'double-tap'}
      />
      {error ? (
        <>
          <Caption color={colors.red.standard}>{error}</Caption>
          <Alert isAlertVisible={!!error} type={AlertTypes.ERROR} title={t('alerts.error')} subtitle={error} />
        </>
      ) : (
        showCaption && <Caption color={colors.grey.dark}>{t('settings.services.sidebar.youCanSkip')}</Caption>
      )}
    </Flex>
  );
};
