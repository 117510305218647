import { MainService, PhotoService, TariffService } from '../../index';

export const ServiceStep = ({ currentStep, stepper, handleNextStep, handleBackStep, handleClose }) => {
  switch (currentStep) {
    case 1:
      return (
        <MainService
          stepper={stepper}
          handleNextStep={handleNextStep}
          handleBackStep={handleBackStep}
          handleClose={handleClose}
        />
      );
    case 2:
      return (
        <PhotoService
          stepper={stepper}
          handleNextStep={handleNextStep}
          handleBackStep={handleBackStep}
          handleClose={handleClose}
        />
      );
    case 3:
      return (
        <TariffService
          stepper={stepper}
          handleNextStep={handleNextStep}
          handleBackStep={handleBackStep}
          handleClose={handleClose}
        />
      );
    default:
      return null;
  }
};
