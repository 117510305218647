import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';
import { generatePath, Outlet } from 'react-router-dom';
import { Flex, H4 } from '@beauty/beauty-market-ui';
import { NavTag } from 'components';
import { useMediaScreen } from 'hooks';
import { RouterUrl } from 'routes/routes';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { selectNewAddress, ThunkAddress } from 'store/redux-slices/addressSlice';
import { AddressCoverStack } from './components';
import { AddressContentWrapper, Wrapper } from './style';

export const AddressPage = () => {
  const { t } = useTranslation();
  const { id } = useParams();
  const { name } = useAppSelector(selectNewAddress);
  const dispatch = useAppDispatch();

  const { isDesktop, isMobile } = useMediaScreen('md');

  useEffect(() => {
    if (id) {
      dispatch(ThunkAddress.fetchAddressById(id));
    }
  }, [id]);

  return (
    <>
      <AddressContentWrapper>
        {isMobile && <AddressCoverStack />}
        <Wrapper position="first">
          <H4 truncated>{name}</H4>
          <Flex width="100%" gap="8px">
            <NavTag
              text={t('address.generalData')}
              to={generatePath(RouterUrl.AddressGeneralData, { id: id || null })}
            />
            <NavTag
              text={t('address.aboutCompany')}
              to={generatePath(RouterUrl.AddressAboutCompany, { id: id || null })}
            />
          </Flex>
        </Wrapper>
        <Outlet />
      </AddressContentWrapper>
      {isDesktop && <AddressCoverStack />}
    </>
  );
};
