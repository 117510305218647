import range from 'lodash/range';
import moment from 'moment';
import { startYear } from '../../../constants';
import { NamedAddressType, SpecialistOrganizationType } from '../../../types';

export const getSpecialistAddressOptions = (addresses?: SpecialistOrganizationType[]) => {
  if (!Array.isArray(addresses)) return [];
  return addresses.map(address => ({
    id: address?.orgId,
    title: address.name,
    icon: address.mainPhoto,
    description: address.address?.fullAddress || '',
  }));
};

export const getAllAddressOptions = (addresses: NamedAddressType[]) => {
  if (!Array.isArray(addresses)) return [];
  return addresses.map(address => ({
    id: address?.id,
    title: address.name,
    icon: address.mainPhoto?.url,
    description: address.address.fullAddress,
  }));
};

export const sliderMonthDates = years =>
  Array.from({ length: 12 * years }, (_, i) => i).map(offset => ({
    timestamp: moment().year(startYear).month(0).add(offset, 'months').startOf('month').format('YYYY-MM-DD'),
    disabled: false,
  }));

export const sliderDates = daysForOneSide =>
  range(-daysForOneSide, daysForOneSide).map(day => {
    const currentDay = moment().add(day, 'days');
    const timestamp = currentDay.format('YYYY-MM-DD');
    return {
      timestamp,
      disabled: false,
    };
  });
