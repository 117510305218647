import { Outlet } from 'react-router-dom';
import { Loader } from '../Loader';
import { Navbar } from '../Navbar';
import { Wrapper } from './style';
import { useInitializeApp } from './useInitializeApp';

export const Layout = () => {
  const { isInitializing } = useInitializeApp();
  return (
    <>
      <Navbar />
      <Wrapper>{isInitializing ? <Loader /> : <Outlet />}</Wrapper>
    </>
  );
};
