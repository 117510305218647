import { TFunction } from 'react-i18next';
import { getSeparator } from 'helpers';
import { Client, ClientInfo } from 'types';
import { RouterUrl } from '../../routes/routes';
import { ClientForm } from './components';

export const getParams = (data: ClientForm, initialValues: Client, isEditAction: boolean): Partial<ClientForm> => {
  const params: Partial<ClientForm> = {};

  for (const key of Object.keys(data)) {
    if (isEditAction && data[key] !== initialValues[key]) {
      params[key] = data[key];
    }
    if (!isEditAction && data[key]) {
      params[key] = data[key];
    }
  }

  if (data.addCode !== initialValues.addCode || data.addNumber !== initialValues.addNumber) {
    params.addCode = data.addNumber ? data.addCode : '';
    params.addNumber = data.addNumber;
  }

  if (params.dateOfBirth) {
    params.dateOfBirth = params.dateOfBirth.split(getSeparator(params.dateOfBirth)).reverse().join('-');
  }

  // Not verified client
  if (isEditAction) {
    delete params.code;
    delete params.number;
  }

  // Verified client
  if (isEditAction && initialValues?.verified) {
    delete params.name;
    delete params.surname;
    delete params.dateOfBirth;
    delete params.gender;
  }

  return params;
};

export const clientsTabs = (t: TFunction<'translation', undefined>) => [
  {
    title: t('clients.profile.clientData'),
    route: RouterUrl.ClientPageData,
  },
  {
    title: t('clients.profile.appointments.appointments'),
    route: RouterUrl.ClientAppointments,
  },
  {
    title: t('clients.profile.payments.payments'),
    route: RouterUrl.ClientPayments,
  },
  {
    title: t('clients.profile.reviews'),
    route: RouterUrl.ClientReviews,
  },
];

export const parseClientInfo = (
  person: ClientInfo,
  t: TFunction<'translation', undefined>,
): {
  title: string;
  value: number;
}[] => [
  {
    title: t('clients.profile.totalBookings'),
    value: person.totalBookings,
  },
  {
    title: t('clients.profile.completed'),
    value: person.completedApp,
  },
  {
    title: t('clients.profile.canceled'),
    value: person.cancelledApp,
  },
  {
    title: t('clients.profile.noShow'),
    value: person.noShowApp,
  },
];
