import { useTranslation } from 'react-i18next';
import { Field, Form, Formik } from 'formik';
import { Flex, H6 } from '@beauty/beauty-market-ui';
import { Properties } from 'components/Addresses/MainInformation/Properties';
import { FormikTextfield } from 'components/functional/formik/formik-textfield/FormikTextfield';
import { SidebarFooter, SidebarSheet } from 'components/index';
import { useGetProperties } from 'hooks';
import { AboutCompanyFormFields, AboutCompanyFormType, schema } from './EditAboutCompany.defenitions';

type Props = {
  onClose: () => void;
  onSubmit: (data: AboutCompanyFormType) => Promise<void>;
  onBack?: () => void;
  defaultValues?: {
    description?: string | null;
    property?: string[];
  };
};

export const EditAboutCompany = ({ defaultValues, onClose, onSubmit, onBack }: Props) => {
  const { t } = useTranslation();
  const { Description, Property } = AboutCompanyFormFields;
  const { properties } = useGetProperties();

  const formikContextValue = {
    initialValues: { description: defaultValues?.description ?? '', property: defaultValues?.property ?? [] },
    validationSchema: schema(t),
    validateOnMount: false,
    onSubmit,
  };

  return (
    <Formik {...formikContextValue}>
      {({
        isValid,
        errors,
        values,
        handleChange,
        setFieldValue,
        dirty,
        touched,
        isSubmitting,
        handleSubmit: handleSubmitForm,
      }) => (
        <Form>
          <SidebarSheet
            label={t('address.editOrganisationInfo')}
            descriptor={t('address.changeInformation')}
            onClose={onClose}
            onBack={onBack}
            FooterBody={
              <SidebarFooter
                onSubmit={handleSubmitForm}
                onBack={onClose}
                isLoading={isSubmitting}
                disabled={!isValid || !dirty}
                save
              />
            }
          >
            <Flex flexDirection="column" mb="100px" gap="24px">
              <Flex flexDirection="column" gap="16px">
                <H6>{t('address.companyDescription')}</H6>
                <FormikTextfield
                  id={Description}
                  name={Description}
                  rows={8}
                  width="100%"
                  design="white"
                  onChange={handleChange}
                  value={values[Description]}
                  defaultValue={values[Description]}
                  placeholder={t('common.description')}
                  caption={touched[Description] && !!errors[Description] ? errors[Description] : ''}
                />
              </Flex>
              <Field
                id={Property}
                name={Property}
                properties={properties}
                selected={values[Property]}
                onSelect={(items: string[]) => setFieldValue(Property, items)}
                component={Properties}
                limit={3}
              />
            </Flex>
          </SidebarSheet>
        </Form>
      )}
    </Formik>
  );
};
