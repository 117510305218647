import { useTranslation } from 'react-i18next';
import { useFormikContext } from 'formik';
import { Button, H6, MessageIcon } from '@beauty/beauty-market-ui';
import { FormikTextfield } from 'components/functional/formik/formik-textfield/FormikTextfield';
import { AppointmentFormFields } from './EditAppointment.definitions';

export const EditNote = ({ editNote, onClick, onBlur }) => {
  const { t } = useTranslation();
  const { Notes } = AppointmentFormFields;
  const { values } = useFormikContext<AppointmentFormFields>();

  return editNote ? (
    <>
      <H6 marginTop="8px">{t('calendar.newAppointmentSidebar.notes')}</H6>
      <FormikTextfield
        id={Notes}
        name={Notes}
        design="white"
        placeholder={t('calendar.newAppointmentSidebar.typeNotes')}
        rows={5}
        infoCaption={`${values[Notes].length}/1000`}
        isCaptionRight
        onBlur={values[Notes] ? undefined : onBlur}
      />
    </>
  ) : (
    <Button width="100%" design="secondary" type="button" suffix={<MessageIcon />} onClick={onClick}>
      {t('button.addNotes')}
    </Button>
  );
};
