import { Navigate } from 'react-router-dom';
import { RouterUrl } from 'routes/routes';
import { useAppSelector } from '../../store/hooks';
import { selectUser } from '../../store/redux-slices/userSlice';

interface ProtectedRouteProps {
  isAllowed: boolean;
  component: JSX.Element;
  redirectPath?: string;
}

export const ProtectedRoute = ({ component, redirectPath, isAllowed = false }: ProtectedRouteProps) => {
  const user = useAppSelector(selectUser);
  const {userId} = user.user;
  const defaultRedirectPath = userId ? RouterUrl.AccountSetting : RouterUrl.Login;
  return isAllowed ? component : <Navigate to={redirectPath || defaultRedirectPath} replace />;
};
