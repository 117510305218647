import { Badge, BodyLarge, BodySmall, Caption, colors, Flex, Separator } from '@beauty/beauty-market-ui';

export const AppointmentItem = ({ time, service, price, isDesktop, cancelled, separator, onClick, ...rest }) => (
  <>
    <Flex
      onClick={onClick}
      cursor={onClick ? 'pointer' : 'auto'}
      alignItems="center"
      justifyContent="space-between"
      {...rest}
    >
      <Flex flexDirection="column">
        <Caption color={colors.grey.dark}>{time}</Caption>
        <BodyLarge large title={service} textWrap="balance" maxWidth={isDesktop ? '100%' : '60vw'}>
          {service}
        </BodyLarge>
      </Flex>
      <Flex gap="10px" alignItems="center">
        {cancelled && <Badge text={cancelled} mr={isDesktop ? '8px' : '4px'} ml="auto" design="lightOrange" />}
        <BodySmall large minWidth="50px" textAlign="end">
          {price}
        </BodySmall>
      </Flex>
    </Flex>
    {separator && <Separator />}
  </>
);
