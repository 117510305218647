import { useLocation, useNavigate } from 'react-router-dom';
import { t } from 'i18next';
import { H2, Flex } from '@beauty/beauty-market-ui';
import { urlWithLang } from '../../constants';
import { useGetUrl } from '../../hooks/useGetUrl';
import { RouterUrl } from '../../routes/routes';
import { GappedFlex } from '../../style';
import { LoginButton } from './componets/LoginButton';
import { ImageBlock, LoginInfoWrapper, LoginWrapper } from './style';

export const LoginIntro = () => {
  const navigate = useNavigate();
  const { type } = useLocation().state;
  const { client } = useGetUrl();

  const crmUrl = type === 'login' ? urlWithLang(RouterUrl.Login) : urlWithLang(RouterUrl.SignUp);

  return (
    <LoginWrapper>
      <LoginInfoWrapper width="100%" maxWidth="400px">
        <Flex flexDirection="column">
          <H2 mb="32px">{t(`login.intro.${type || ''}Title`)}</H2>
          <GappedFlex flexDirection="column" gap="24px">
            <LoginButton
              title={t('login.intro.titleClients')}
              info={t('login.intro.infoClients')}
              onClick={() => window.open(client, '_self')}
            />
            <LoginButton
              title={t('login.intro.titleBusiness')}
              info={t('login.intro.infoBusiness')}
              onClick={() => navigate(crmUrl, { state: { type } })}
              isActive
            />
          </GappedFlex>
        </Flex>
      </LoginInfoWrapper>
      <ImageBlock />
    </LoginWrapper>
  );
};
