import { useEffect, useState } from 'react';
import { uniqBy } from 'lodash';
import { ClientAppointment } from 'types';
import { apiRequest } from '../../api/api';
import { HeadOrganisationEndpoints } from '../../api/endpoints/endpoints';

type Response = {
  totalPages: number;
  currentPage: number;
  appointments: ClientAppointment[];
};

type IntervalType = 'past' | 'future';

export const useGetClientAppointments = (
  id = '',
  interval: IntervalType = 'future',
  page = 1,
  size = 5,
): [Response, boolean, unknown] => {
  const [isLoading, setLoading] = useState(true);
  const [errorMessage, setErrorMessage] = useState<unknown>(null);
  const [data, setData] = useState<Response>({
    currentPage: page,
    totalPages: 2,
    appointments: [],
  });

  useEffect(() => {
    const fetchAppointments = async () => {
      setLoading(true);
      const [error, response] = await apiRequest.get<Response>({
        endpoint: HeadOrganisationEndpoints.ClientAppointments,
        endpointParams: { id, interval, page, size },
      });
      if (error?.data) setErrorMessage(error?.data);
      if (response?.data) {
        if (page === 1) {
          setData(response?.data);
        } else {
          setData(prev => ({
            ...prev,
            currentPage: response.data.currentPage,
            totalPages: response.data.totalPages,
            appointments: uniqBy(prev.appointments.concat(response.data.appointments), 'id'),
          }));
        }
      }
      setLoading(false);
    };

    id && fetchAppointments();
  }, [id, interval, page, size]);

  return [data, isLoading, errorMessage];
};
