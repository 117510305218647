import styled from 'styled-components';
import { Div } from '@beauty/beauty-market-ui';

export const InputWrapper = styled(Div)`
  width: 100%;
  box-sizing: border-box;

  input {
    width: 100%;
  }
`;
