import { useEffect, useState } from 'react';
import { apiRequest } from '../api/api';
import { HeadOrganisationEndpoints } from '../api/endpoints/endpoints';
import { SubscriptionPlanType, SubscriptionPricePlanType } from '../types';

type ResponseDataType = {
  createdAt: string;
  invoice: {
    url: string | null;
    receiptUrl: string | null;
  } | null;
  subscription: {
    plan: {
      planType: SubscriptionPlanType;
    };
  } | null;
  subscriptionOptions: [Record<'type', SubscriptionPricePlanType>];
};

type InvoicesDataType = {
  id: string;
  url: string | null;
  receiptUrl: string | null;
  createdAt: string;
  planType: SubscriptionPlanType | SubscriptionPricePlanType | null;
};

export const useGetInvoices = (isTrial: boolean, id?: string) => {
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [invoices, setInvoices] = useState<InvoicesDataType[]>([]);

  useEffect(() => {
    const fetchInvoices = async () => {
      if (!isTrial && id) {
        const [error, response] = await apiRequest.get({
          endpoint: HeadOrganisationEndpoints.SubscriptionInvoices,
          endpointParams: id,
        });
        if (error?.data) alert('Error with loading invoices');
        if (response) {
          const updatedData: InvoicesDataType[] = response.data
            .filter((item: ResponseDataType) => Boolean(item.invoice))
            .map(item => ({
              id: item.invoice.url,
              url: item.invoice.url,
              receiptUrl: item.invoice.receiptUrl,
              createdAt: item.createdAt,
              planType: item.subscription ? item.subscription.plan.planType : item.subscriptionOptions[0].type,
            }));
          setInvoices(updatedData);
        }
      }
      setIsLoading(false);
    };

    fetchInvoices();

    return setIsLoading(false);
  }, [isTrial]);

  return {
    isLoading,
    invoices,
  };
};
