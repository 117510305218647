import styled from 'styled-components';
import { Div } from '@beauty/beauty-market-ui';

export const Spinner = styled(Div)`
  width: 24px;
  height: 24px;
  border: 2px solid #fff;
  border-bottom-color: transparent;
  border-radius: 50%;
  display: inline-block;
  box-sizing: border-box;
  animation: rotate 1s linear infinite;
`;
