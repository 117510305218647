import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { capitalize } from 'lodash';
import { BodySmall, Caption, Flex, WalletIcon } from '@beauty/beauty-market-ui';
import { SubscriptionPlanType, SubscriptionStatusType } from 'types';
import { RouterUrl } from '../../../routes/routes';
import { useAppSelector } from '../../../store/hooks';
import { selectActiveSubscription } from '../../../store/redux-slices/organisationSlice';
import { ChangePlanButton } from '../style';

export const ChangePlan = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const subscription = useAppSelector(selectActiveSubscription);

  const isTrial = subscription?.plan.planType === SubscriptionPlanType.FREE;
  const isPast = subscription?.status === SubscriptionStatusType.PAST;

  const buttonText = isPast ? t('subscriptions.noSubscription') : capitalize(subscription?.plan.planType);
  const activeSubscription = subscription && !isTrial && !isPast;
  const { daysLeft = 0 } = subscription ?? {};

  const handleChangePlanClick = () => {
    if (location.pathname !== RouterUrl.ChangePlanPage) {
      navigate(RouterUrl.ChangePlanPage);
    }
  };

  return (
    <ChangePlanButton
      design="quaternary"
      prefix={<WalletIcon />}
      onClick={handleChangePlanClick}
      active={activeSubscription}
    >
      <Flex flexDirection="column" alignItems="start">
        <BodySmall large>{isTrial ? t('header.changePlan') : buttonText}</BodySmall>
        <Caption lowline>{`${isPast ? 0 : daysLeft} ${t('header.daysLeft')}`}</Caption>
      </Flex>
    </ChangePlanButton>
  );
};
