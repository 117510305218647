import { colors, BodySmall, BodyLarge, Flex, Checkbox } from '@beauty/beauty-market-ui';
import { Wrapper } from './style';

type InfoCheckboxProps = {
  checked: boolean;
  onChange: () => void;
  title?: string;
  info?: string;
};

export const InfoCheckbox = ({ title, info, checked, onChange }: InfoCheckboxProps) => (
    <Wrapper>
      <Flex flexDirection="column" justifyItems="space-between" gap="2px">
        {title && <BodyLarge>{title}</BodyLarge>}
        {info && <BodySmall color={colors.grey.standard}>{info}</BodySmall>}
      </Flex>
      <Checkbox shape="round" onCheckboxClick={onChange} checked={checked} />
    </Wrapper>
  );
