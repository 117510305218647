import { useRef, useState } from 'react';
import { Views } from 'react-big-calendar';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { DateSliderCalendar, useClickOutside, DropdownCard, MonthSliderCalendar } from '@beauty/beauty-market-ui';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import {
  clearAppointments,
  selectAppointments,
  setDate,
  setLoading,
  setRange,
} from 'store/redux-slices/appointmentsSlice';
import { getSelectedLanguage, maxCalendarDays, yearsToShow } from '../../../constants';
import { KebabBody } from '../KebabMenu/KebabBody';
import { sliderDates, sliderMonthDates } from './helpers';
import { KebabWrapper, MobileHeaderWrapper } from './style';

export type MobileCalendarHeaderProps = {
  view: string;
  onView: (value: string) => void;
};

export const MobileCalendarHeader = ({ view, onView }: MobileCalendarHeaderProps) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const menuRef = useRef(null);
  const userLocale = getSelectedLanguage().toLowerCase();

  const { date: calendarDate, selectedSpecialist } = useAppSelector(selectAppointments);

  const datesForCalendar = sliderDates(maxCalendarDays);
  const selectedDate =
    view === Views.MONTH
      ? moment(calendarDate).startOf('month').format('YYYY-MM-DD')
      : moment(calendarDate).format('YYYY-MM-DD');

  const [isMenu, setIsMenu] = useState(false);

  const options = [
    {
      body: (
        <DropdownCard
          title={t('calendar.day')}
          description={t('calendar.dailyDisplay')}
          selected={view !== Views.MONTH}
        />
      ),
      onClick: () => {
        onView(selectedSpecialist ? Views.DAY : Views.WEEK);
        if (moment(selectedDate).isBefore(moment(datesForCalendar[0].timestamp)))
          dispatch(setDate(moment(datesForCalendar[0].timestamp).format('YYYY-MM-DD')));
        else if (moment(selectedDate).isAfter(moment(datesForCalendar[datesForCalendar.length - 1].timestamp)))
          dispatch(setDate(moment(datesForCalendar[datesForCalendar.length - 1].timestamp).format('YYYY-MM-DD')));
      },
    },
    {
      body: (
        <DropdownCard
          title={t('calendar.month')}
          description={t('calendar.monthlyDisplay')}
          selected={view === Views.MONTH}
        />
      ),
      onClick: () => onView(Views.MONTH),
    },
  ];

  const handleDateSelect = (date: string) => {
    dispatch(setLoading(true));
    dispatch(clearAppointments());
    if (view === Views.MONTH)
      dispatch(
        setRange({
          start: moment(date).startOf('month').toISOString(),
          end: moment(date).endOf('month').toISOString(),
        }),
      );
    else dispatch(setRange({ start: moment(date).toISOString(), end: moment(date).add(1, 'day').toISOString() }));
    dispatch(setDate(date));
  };

  useClickOutside(menuRef, () => setIsMenu(false));

  return (
    <MobileHeaderWrapper>
      {view === Views.MONTH ? (
        <MonthSliderCalendar
          dates={sliderMonthDates(yearsToShow)}
          userLocale={userLocale}
          selectedDate={selectedDate}
          setSelectedDate={handleDateSelect}
        />
      ) : (
        <DateSliderCalendar
          dates={datesForCalendar}
          userLocale={userLocale}
          selectedDate={selectedDate}
          setSelectedDate={handleDateSelect}
          buttonLabel={t('calendar.selectDate')}
        />
      )}
      <KebabWrapper>
        <KebabBody
          isMenu={isMenu}
          onClick={e => {
            e.stopPropagation();
            e.preventDefault();
            setIsMenu(prev => !prev);
          }}
          menuRef={menuRef}
          options={options}
          isMobile
        />
      </KebabWrapper>
    </MobileHeaderWrapper>
  );
};
