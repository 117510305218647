import { useTranslation } from 'react-i18next';
import { intersection, isEmpty } from 'lodash';
import { FilterDropdown } from '@beauty/beauty-market-ui';
import { Anonymous } from '../../../assets';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import { selectAppointments, setSelectedSpecialist } from '../../../store/redux-slices/appointmentsSlice';

export const FilterBySpecialist = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { selectedCategory, selectedSpecialist, specialistIds, categoryData, specialistData } =
    useAppSelector(selectAppointments);

  const handleSpecialistClick = (id: string | null) => {
    dispatch(setSelectedSpecialist(id));
  };

  const handleResetFilter = () => {
    dispatch(setSelectedSpecialist(null));
  };

  const filterBySelectedService = (specialistId: string | null) => {
    if (selectedCategory && specialistId && specialistData[specialistId]?.serviceIds) {
      const specServices = specialistData[specialistId].serviceIds;
      const categoryServices = categoryData[selectedCategory].serviceIds;
      return !isEmpty(intersection(specServices, categoryServices));
    }
    return true;
  };

  const specialistOptions = [null, ...specialistIds].filter(filterBySelectedService).map(specId => ({
    id: specId,
    title: specId ? specialistData[specId].name : t('specialists.allSpecialists'),
    description: specId ? specialistData[specId].surname : '',
    icon: specId && specialistData[specId]?.avatarUrl ? specialistData[specId].avatarUrl : <Anonymous />,
  }));

  return (
    <FilterDropdown
      options={specialistOptions}
      selectedOptionId={selectedSpecialist}
      onSelect={handleSpecialistClick}
      onCross={selectedSpecialist ? handleResetFilter : undefined}
      placeholder="Search"
    />
  );
};
