import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Select, Flex } from '@beauty/beauty-market-ui';
import { SidebarFooter, SidebarSheet } from 'components';
import { useRequest } from 'hooks';
import { useAppSelector } from 'store/hooks';
import { selectUser, ThunkUser } from 'store/redux-slices/userSlice';
import { Language, ProfileActions } from 'types';
import { getLanguageOptions } from '../../../../constants';

export const EditLanguage = ({ onClose }) => {
  const { t } = useTranslation();
  const { profile } = useAppSelector(selectUser);

  const language = profile.language || Language.English;
  const languageOptions = getLanguageOptions(t);

  const [isSubmitting, setSubmitting] = useState(false);
  const [selected, setSelected] = useState<{ id: string }>(languageOptions.data[language]);

  const changeLanguage = useRequest(ThunkUser.updateNotificationLanguage, ProfileActions.ChangeNotificationLanguage);

  const handleSubmit = async () => {
    setSubmitting(true);
    changeLanguage({ accountData: { language: selected.id } })
      .then(onClose)
      .finally(() => {
        setSubmitting(false);
      });
  };

  return (
    <SidebarSheet
      detent="full-height"
      label={t('profile.updateLanguage')}
      descriptor={t('profile.chooseLanguage')}
      onClose={isSubmitting ? undefined : onClose}
      FooterBody={
        <SidebarFooter
          onSubmit={handleSubmit}
          onBack={onClose}
          isLoading={isSubmitting}
          disabled={selected.id === language}
          confirmationLabel={t('button.apply')}
          cancelLabel={t('button.cancel')}
        />
      }
    >
      <Flex mb="80px">
        <Select
          selected={selected}
          onSelect={setSelected}
          options={Object.values(languageOptions.data)}
          placeholder={t('language.language')}
          isSeparator
        />
      </Flex>
    </SidebarSheet>
  );
};
