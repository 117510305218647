import { useEffect, useState } from 'react';
import { uniqBy } from 'lodash';
import { ClientPayments } from 'types';
import { apiRequest } from '../../api/api';
import { HeadOrganisationEndpoints } from '../../api/endpoints/endpoints';
import { getQueryParams } from '../../helpers/utils';

type Response = {
  currentPage: number;
  totalPages: number;
  payments: ClientPayments[];
};

const defaultValues = {
  currentPage: 1,
  totalPages: 1,
  payments: [],
};

// export const getSearchParams = (obj: Record<string, string | number>) => {
//   return Object.entries(obj)
//     .filter(([, value]) => !!value)
//     .map(([key, value]) => `${encodeURIComponent(key)}=${encodeURIComponent(value)}`)
//     .join('&');
// }

export const useGetClientPayments = (id = '', page = 1, size = 5, query = ''): [Response, boolean, unknown] => {
  const [isLoading, setLoading] = useState(true);
  const [errorMessage, setErrorMessage] = useState<unknown>(null);
  const [data, setData] = useState<Response>(defaultValues);

  useEffect(() => {
    const fetchPayments = async () => {
      setLoading(true);
      const search = query.trim();
      const queryParams = getQueryParams(search ? { page, size, search } : { page, size });
      const [error, response] = await apiRequest.get<Response>({
        endpoint: HeadOrganisationEndpoints.ClientPayments,
        endpointParams: { id, queryParams },
      });

      if (error?.data) {
        if (page === 1) {
          setData(defaultValues);
        }
        setErrorMessage(error?.data);
      }
      if (response?.data) {
        if (page === 1) {
          setData(response.data);
        } else {
          setData(prev => ({
            ...prev,
            currentPage: response.data.currentPage,
            totalPages: response.data.totalPages,
            payments: uniqBy(prev.payments.concat(response.data.payments), 'id'),
          }));
        }
      }
      setLoading(false);
    };

    id && fetchPayments();
  }, [id, page, size, query]);

  return [data, isLoading, errorMessage];
};
