import { NavLink } from 'react-router-dom';
import styled from 'styled-components';
import { Button, colors, Div, Flex, mediaQueries, BottomSheet, alignedText } from '@beauty/beauty-market-ui';
import { zIndex } from '../../constants';

export const HeaderWrapper = styled.header<{ isOnTop: boolean }>`
  ${({ isOnTop }) => `z-index: ${isOnTop ? zIndex.headerWrapper : zIndex.zero};`}
  background-color: ${colors.grey.light};
  box-sizing: border-box;
  grid-area: header;
  grid-row: row1;
  display: flex;
  width: 100%;
  height: 72px;
  position: sticky;
  top: 0;

  ${mediaQueries.md} {
    background-color: ${colors.white.standard};
  }
`;

export const HeaderGrid = styled(Div)`
  display: grid;
  grid-template-columns: 1fr 9fr;
  grid-template-areas: 'logo navigationMenu';
  justify-content: space-between;
  align-items: center;

  height: 100%;
  width: 100%;
  max-width: 1060px;

  border-radius: 10px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.08);
  background-color: ${colors.white.standard};

  padding: 0 8px;
  margin: auto;

  ${mediaQueries.md} {
    grid-template-columns: 20% 55% 25%;
    grid-template-areas: 'logo search navigationMenu';
    box-shadow: none;
  }
`;

export const LogoutButton = styled(Div)`
  ${alignedText}
  cursor: pointer;
  display: block;
  padding: 0;
  color: ${colors.black.standard};
  border: none;
  background: transparent;
  font-size: 14px;
`;

export const NavButton = styled(NavLink)`
  cursor: pointer;
  display: block;
  color: ${colors.black.standard};
  border: none;
  background: transparent;
  font-size: 14px;
  text-decoration: none;
  padding-bottom: 20px;

  &.active p {
    color: ${colors.blue.standard};
  }
`;

export const MenuWrapper = styled(Flex)`
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 84px;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, #ffffff 87.62%);
`;

export const CRMHeaderContainer = styled(Flex)`
  flex-direction: column;
  width: -webkit-fill-available;
  grid-area: header;
  position: sticky;
  top: 0;
  z-index: 2;
  background-color: ${colors.grey.light};
  min-height: 76px;
  margin: 0;

  ${mediaQueries.md} {
    margin: ${({ theme }) => (theme.rtl ? '0 64px 0 0' : '0 0 0 64px')};
  }
`;

export const CRMHeaderWrapper = styled(Flex)`
  padding: 14px 40px;
  box-sizing: border-box;
  justify-content: center;
  gap: 40px;
  background-color: ${colors.grey.light};

  & > div:first-child {
    max-width: 100%;
    z-index: 1;

    & > div:first-child {
      max-width: 100%;
    }
  }

  & > div:nth-child(2) > div:last-of-type {
    height: 48px;
  }

  /* TODO: remove after Menu component is refactored in UI-lib */

  & > div:last-of-type > div:last-of-type button {
    padding: 0;
  }
`;

export const ChangePlanButton = styled(Button)<{ active: boolean }>`
  justify-content: start !important;
  width: 182px;
  height: 48px;

  * {
    color: ${({ active }) => (active ? colors.black.standard : colors.white.standard)} !important;
  }

  svg path {
    stroke: ${({ active }) => (active ? colors.blue.standard : colors.white.standard)} !important;
  }

  background-color: ${({ active }) => (active ? 'transparent' : colors.orange.standard)} !important;
  border: ${({ active }) => (active ? `1px solid ${colors.grey.standard}` : 'none')} !important;
`;

export const GoogleButton = styled(Button)`
  justify-content: start !important;
  width: 182px;
  height: 40px;
  border-radius: 4px !important;
  border: 1px solid #747775 !important;
  background-color: ${colors.white.standard} !important;

  & {
    gap: 10px !important;
    padding: 10px 12px !important;
  }

  svg {
    path {
      stroke: none !important;
    }
  }

  * {
    color: ${colors.black.standard} !important;
  }

  :hover {
    background-color: #303030;
    opacity: 12%;
  }
`;

export const BellWrapper = styled(Flex)<{ counter: number }>`
  position: relative;

  button:hover {
    cursor: pointer;
  }
`;

export const StyledBanner = styled(Flex)`
  box-sizing: border-box;
  height: 48px;
  justify-content: flex-start;
  align-items: center;
  background-color: rgba(255, 174, 66, 0.1);
  padding: 14px 40px;
`;

export const StyledButton = styled(Flex)`
  gap: 4px;
  align-items: center;
  margin-left: 8px;
  cursor: pointer;

  & > svg {
    stroke: ${({ theme }) => theme.colors.orange.standard};
  }

  & > p {
    color: ${({ theme }) => theme.colors.orange.standard};
  }

  &:hover {
    & > svg {
      stroke: ${({ theme }) => theme.colors.blue.standard};
    }

    & > p {
      color: ${({ theme }) => theme.colors.blue.standard};
    }
  }
`;

export const CaptionWrapper = styled(Flex)`
  border-radius: 16px;
  position: absolute;
  width: fit-content;
  left: 8px;
  top: -8px;
  padding: 1px 5px;
  background-color: ${colors.blue.standard};

  span {
    color: ${colors.white.standard};
  }
`;

export const StyledFlex = styled(Flex)`
  [dir='rtl'] & {
    .gsi-material-button-icon {
      margin-right: 0;
      margin-left: 12px;
    }
  }
`;

export const StyledBottomSheet = styled(BottomSheet)`
  .react-modal-sheet-container {
    padding: 0 !important;

    & > div:nth-child(2) > div {
      justify-content: space-between;
      padding-left: 8px;
      padding-right: 8px;
    }

    .react-modal-sheet-content {
      & > div > div > div {
        padding-left: 8px;
        padding-right: 8px;
      }
    }
  }
`;
