import { useTranslation } from 'react-i18next';
import {
  BodyLarge,
  BottomSheet,
  Button,
  Counter,
  Flex,
  getMinWidthMediaQuery,
  Popup,
  Separator,
  useMediaQuery,
} from '@beauty/beauty-market-ui';
import { getPublishInfo } from '../helpers';

export const PublishAddress = ({ title, onSubmit, onClose, onShow, isLoading }) => {
  const { t } = useTranslation();

  const mediaQuery = getMinWidthMediaQuery('md');
  const isDesktop = useMediaQuery(mediaQuery);

  const content = (
    <Flex gap="8px" flexDirection="column" m={isDesktop ? '0 0 0 40px' : '0 auto'}>
      {getPublishInfo(t).map(item => (
        <Flex key={item} gap="8px" alignItems="center">
          <Counter />
          <BodyLarge>{item}</BodyLarge>
        </Flex>
      ))}
    </Flex>
  );

  const FooterBody = (
    <Flex flexDirection="column" gap="16px" width="100%">
      <Button design="quaternary" size="large" width="100%" onClick={onShow} disabled={isLoading}>
        {t('address.showPreview')}
      </Button>
      <Button design="quaternary" size="large" width="100%" onClick={onSubmit} disabled={isLoading}>
        {t('address.publishAddress')}
      </Button>
      <Button design="quaternary" size="large" width="100%" onClick={onClose} disabled={isLoading}>
        {t('button.cancel')}
      </Button>
    </Flex>
  );

  return isDesktop ? (
    <Popup open title={title} info={t('address.publishDescription')} handleClose={onClose} FooterBody={FooterBody}>
      {content}
      <Separator mt="40px" />
    </Popup>
  ) : (
    <BottomSheet
      isOpen
      content={content}
      label={title}
      descriptor={t('address.publishDescription')}
      onClose={onClose}
      handleClose={onClose}
      detent="content-height"
      FooterBody={FooterBody}
    />
  );
};
