import { useTranslation } from 'react-i18next';
import { Formik } from 'formik';
import { Button, Caption, colors, useNotify, NotifyStatus } from '@beauty/beauty-market-ui';
import { getNotifyContent } from 'helpers';
import { useAppDispatch } from '../../../store/hooks';
import { ThunkUser } from '../../../store/redux-slices/userSlice';
import { ProfileActions } from '../../../types';
import { FormikPasswordInput } from '../../functional/formik/formik-password-input/FormikPasswordInput';
import { Container, SidebarSheet } from '../../index';
import {
  ChangePasswordFormFields,
  ChangePasswordFormType,
  initialValues,
  validationSchema,
} from './ChangePassword.definitions';
import { StyledCaption } from './style';

export const ChangePasswordSidebar = ({ onClose }) => {
  const { t } = useTranslation();
  const notify = useNotify();
  const dispatch = useAppDispatch();

  const content = [t('profile.letters'), t('profile.numbers')];
  const caption = content.map(item => <StyledCaption key={item}>{item}</StyledCaption>);

  const handleSubmitForm = (data: ChangePasswordFormType, { setFieldError, setSubmitting }) => {
    const { oldPassword, newPassword } = data;
    dispatch(ThunkUser.changePassword({ oldPassword, newPassword }))
      .unwrap()
      .then(() => {
        notify(getNotifyContent(NotifyStatus.SUCCESS, ProfileActions.ChangePassword, t));
        onClose();
      })
      .catch(error => {
        if (error.statusCode === 401) {
          setFieldError(ChangePasswordFormFields.Old, t('validation.wrongPassword'));
          notify(getNotifyContent(NotifyStatus.ERROR, ProfileActions.ChangePassword, t, t('validation.wrongPassword')));
        } else {
          notify(getNotifyContent(NotifyStatus.ERROR, ProfileActions.ChangePassword, t));
        }
        setSubmitting(false);
      });
  };

  const formikContextValue = {
    initialValues,
    validateOnMount: false,
    onSubmit: handleSubmitForm,
    validationSchema: validationSchema(t),
  };

  return (
    <Formik {...formikContextValue}>
      {({ isValid, dirty, handleSubmit, isSubmitting }) => (
        <SidebarSheet
          detent="full-height"
          onClose={onClose}
          label={t('profile.changePassword')}
          descriptor={t('profile.writeOldAndNewPassword')}
          FooterBody={
            <Button
              size="large"
              type="submit"
              disabled={!isValid || !dirty || isSubmitting}
              onClick={handleSubmit}
              width="100%"
            >
              {t('profile.updatePassword')}
            </Button>
          }
        >
          <Container gap="16px" flexDirection="column" padding="0" mb="80px">
            <FormikPasswordInput
              id={ChangePasswordFormFields.Old}
              name={ChangePasswordFormFields.Old}
              autoComplete="off"
              type="password"
              placeholder={t('profile.oldPassword')}
              required
            />
            <Container gap="4px" flexDirection="column" padding="0">
              <FormikPasswordInput
                id={ChangePasswordFormFields.New}
                name={ChangePasswordFormFields.New}
                autoComplete={ChangePasswordFormFields.New}
                type="password"
                placeholder={t('profile.newPassword')}
                required
              />
              <Container gap="4px" flexDirection="column" padding="0 16px">
                <Caption lowline color={colors.grey.dark}>
                  {t('profile.passwordShouldContain')}
                </Caption>
                {caption}
              </Container>
            </Container>
            <FormikPasswordInput
              id={ChangePasswordFormFields.Repeat}
              name={ChangePasswordFormFields.Repeat}
              autoComplete={ChangePasswordFormFields.Repeat}
              type="password"
              placeholder={t('profile.repeatPassword')}
              required
            />
          </Container>
        </SidebarSheet>
      )}
    </Formik>
  );
};
