import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useLocation } from 'react-router-dom';
import {
  Flex,
  LoginIcon,
  colors,
  Logo,
  Link,
  getMinWidthMediaQuery,
  useMediaQuery,
  Button,
  BellIcon,
  Caption,
  BottomSheet,
  useClickOutside,
} from '@beauty/beauty-market-ui';
import { locationsWithStandardHeaderFooter } from '../../constants';
import { RouterUrl } from '../../routes/routes';
import { useAppSelector } from '../../store/hooks';
import { selectAuth } from '../../store/redux-slices/authSlice';
import { CRMHeader } from './components/CRMHeader';
import { NotificationsPopup } from './components/NotificationsPopup/NotificationsPopup';
import { AvatarMenu } from './components/ServiceTools/AvatarMenu';
import { HeaderWrapper, HeaderGrid, BellWrapper, CaptionWrapper, StyledBottomSheet } from './style';

const Header = () => {
  const { t } = useTranslation();

  const { isLogin } = useAppSelector(selectAuth);

  const navigate = useNavigate();
  const location = useLocation();

  const bellRef = useRef<Element | null>(null);

  const [isLoginPage, setLoginPage] = useState<boolean>(false);
  const [isHomePage, setIsHomePage] = useState<boolean>(true);
  const [isSettingPage, setIsSettingPage] = useState<boolean>(false);
  const [isForBusinessPage, setIsForBusinessPage] = useState<boolean>(false);
  const [isNotificationsPopup, setNotificationsPopup] = useState(false);

  const mediaQuery = getMinWidthMediaQuery('md');
  const isMobile = !useMediaQuery(mediaQuery);

  const fromMarketplace = location.search.includes('isLogin=false');
  const counter = 0; // temporary

  const showAvatar = !fromMarketplace && isLogin;
  const showButtons = !fromMarketplace && !isLogin && !isLoginPage;

  const signUpPress = () => {
    isLogin ? navigate(RouterUrl.LogOut) : navigate(RouterUrl.LoginIntro, { state: { type: 'signup' } });
  };

  const loginPress = () => {
    isLogin ? navigate(RouterUrl.LogOut) : navigate(RouterUrl.LoginIntro, { state: { type: 'login' } });
  };

  const goHome = () => {
    navigate(RouterUrl.WelcomeCrm);
  };

  const goCalendar = () => {
    // dispatch(setDate(moment().format('YYYY-MM-DD')));
    navigate(RouterUrl.Calendar);
  };

  const onClose = () => setNotificationsPopup(false);

  const notificationsPopupProps = {
    isOpen: isNotificationsPopup,
    title: t('notifications.title'),
    items: /* notifications?.new ?? */ [],
    /* setRead: async (index: number) => {
      if (!notifications.new[index].isRead) {
        const response = await changeNotificationStatus(notifications.new[index].id);
        response?.status === NotificationStatus.VIEWED && dispatch(updateNotificationStatus({ isNew: true, index }));
      }
    }, */
    onClose,
  };

  useClickOutside(bellRef, () => {
    setNotificationsPopup(false);
  });

  useEffect(() => {
    const tempLocation = location.pathname.match('login');
    setLoginPage(!!tempLocation);
    setIsHomePage(location.pathname === RouterUrl.Homepage || location.pathname === RouterUrl.WelcomeCrm);
    setIsSettingPage(location.pathname === RouterUrl.AccountSetting);
    setIsForBusinessPage(location.pathname === RouterUrl.ForBusiness);
  }, [location]);

  return !isLogin || locationsWithStandardHeaderFooter.includes(location.pathname) || isMobile ? (
    <HeaderWrapper isOnTop={!isHomePage}>
      <HeaderGrid>
        <Logo
          gridArea="logo"
          justifyItems="start"
          labelColor={colors.black.standard}
          logoColor={colors.blue.standard}
          isHideLabel={isMobile}
          monochrome
          onClick={isMobile ? goCalendar : goHome}
          mt="4px"
        />
        <Flex
          gridArea="navigationMenu"
          justifyItems="end"
          alignItems="center"
          justifyContent="flex-end"
          width="100%"
          height="48px"
          gap="24px"
        >
          {!isForBusinessPage && !isMobile ? (
            <Link size="s" design="black" mr="10px" href="https://beautymarket.co.il/for-business" target="_blank">
              {t('header.forBusiness')}
            </Link>
          ) : (
            isLogin && (
              <BellWrapper>
                <BellIcon width="20" height="20" onClick={() => setNotificationsPopup(pr => !pr)} />
                {!!counter && (
                  <CaptionWrapper onClick={() => setNotificationsPopup(pr => !pr)}>
                    <Caption large lowline>
                      {counter}
                    </Caption>
                  </CaptionWrapper>
                )}
                <StyledBottomSheet
                  ref={bellRef}
                  isOpen={isNotificationsPopup}
                  content={<NotificationsPopup {...notificationsPopupProps} />}
                  onClose={onClose}
                  handleClose={onClose}
                  detent="content-height"
                />
              </BellWrapper>
            )
          )}
          {showButtons && (
            <>
              <Link
                mr="16px"
                design="blue"
                icon={<LoginIcon fill="none" height="15" width="15" />}
                size="s"
                onClick={loginPress}
              >
                {t('header.logIn')}
              </Link>
              <Button size="extraSmall" onClick={signUpPress}>
                {t('header.signUp')}
              </Button>
            </>
          )}
          {showAvatar && <AvatarMenu />}
        </Flex>
      </HeaderGrid>
    </HeaderWrapper>
  ) : (
    <CRMHeader />
  );
};

export default Header;
