import styled from 'styled-components';
import { Flex, Div, Icon, mediaQueries } from '@beauty/beauty-market-ui';

export const BackWrapper = styled(Flex)`
  align-items: center;
  gap: 4px;
  cursor: pointer;
`;

export const BlueIcon = styled(Flex)`
  align-items: center;
  justify-content: center;
  & path {
    stroke: ${({ theme }) => theme.colors.blue.standard};
  }
`;

export const LinkWrapper = styled(Flex)`
  align-items: center;
  gap: 4px;
`;

export const MapPointLocationWrapper = styled(Flex)`
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  position: absolute;
  transform: translateZ(0) translate(-50%, -50%);

  & path {
    fill: ${({ theme }) => theme.colors.blue.standard};
  }
`;

export const ZoomButtonsWrapper = styled(Flex)`
  margin-right: 24px;
  flex-direction: column;
  justify-content: center;
  box-shadow:
    0 0 2px rgba(0, 0, 0, 0.08),
    0 2px 24px rgba(0, 0, 0, 0.08);
`;

export const GoogleMapWrapper = styled(Div)`
  border-radius: 16px;
  overflow: hidden;
`;

export const CustomMapWrapper = styled(Flex)`
  flex-direction: column;
  gap: 8px;
`;

export const Overlay = styled(Div)`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  z-index: 99999;
`;

export const StyledIcon = styled(Icon)`
  width: 16px !important;
  height: 16px !important;

  path {
    stroke: none !important;
    fill: ${({ theme }) => theme.colors.grey.standard};
  }
`;

export const PhoneWrapper = styled(Flex)`
  flex-direction: ${({ theme }) => (theme.rtl ? 'row-reverse' : 'row')};
  gap: 8px;
`;

export const FormWrapper = styled(Flex)`
  flex-direction: column;
  gap: 16px;
  margin-bottom: 200px;

  ${mediaQueries.md} {
    margin-bottom: 0;
  }
`;
