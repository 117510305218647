import styled, { css } from 'styled-components';
import { colors, Button, BodySmall } from '@beauty/beauty-market-ui';

export const StyledButton = styled(Button)<{ isRed: boolean }>`
  ${({ isRed }) =>
    isRed &&
    css`
      background-color: ${colors.red.standard}!important;
      border: none !important;
    `}
`;

export const BlueSpanInclude = styled(BodySmall)`
  span {
    color: ${({ theme }) => theme.colors.blue.standard};
  }
`;
