import { forwardRef, memo } from 'react';
import { useField } from 'formik';
import { Div, Dropdown } from '@beauty/beauty-market-ui';
import { useStatus } from '../hooks/use-status';

export type Option = {
  item: string;
  disabled: boolean;
};

type DropdownProps = {
  options: Option[];
  onChange?: (index: number) => void;
  setIndex?: (index: number) => void;
  currentOption?: number;
  placeholder?: string;
  isSeparator?: boolean;
  disabled?: boolean;
  caption?: string;
  captionIcon?: JSX.Element;
  onClick?: () => void;
  scrollTo?: number;
  autoScroll?: boolean;
  partialVisibility?: number;
  required?: boolean;
};

type FormikDropdownProps = DropdownProps & {
  id: string;
  name: string;
};

export const FormikDropdown = memo(
  forwardRef((props: FormikDropdownProps, ref) => {
    const { placeholder, name, options, setIndex, caption, captionIcon, required, ...otherExplicitProps } = props;
    const [formikProps, meta, helpers] = useField(name);
    const { onChange, ...otherFormikProps } = formikProps;

    const { value } = meta;
    const { setValue } = helpers;
    const status = useStatus(meta);

    const handleChange = (index: number) => {
      setValue(options[index].item);
      setIndex && setIndex(index);
    };

    return (
      <Div width="100%">
        <Dropdown
          autoComplete="nope"
          ref={ref}
          aria-label={placeholder}
          placeholder={placeholder}
          nonValid={status === 'invalid'}
          caption={status === 'invalid' ? meta.error : caption || ''}
          captionIcon={captionIcon}
          options={options}
          currentOption={value}
          onChange={handleChange}
          required={required}
          {...otherExplicitProps}
          {...otherFormikProps}
        />
      </Div>
    );
  }),
);
