import styled from 'styled-components';
import { Flex, BodySmall, RadioButton } from '@beauty/beauty-market-ui';

export const Paper = styled(Flex)<{ border?: boolean }>`
  width: 100%;
  box-sizing: border-box;
  background-color: ${({ theme }) => theme.colors.white.standard};
  padding: 24px;
  border-radius: 10px;

  border: ${({ border, theme }) => (border ? `1px solid ${theme.colors.grey.standard}` : 'none')};
`;

export const BorderedPaper = styled(Paper)`
  flex-direction: column;
  gap: 16px;
  padding: 16px;
  border-radius: 10px;
  border: ${({ theme }) => `1px solid ${theme.colors.grey.standard}`};
`;

export const StyledRadio = styled(RadioButton)`
  width: 24px !important;
  height: 24px !important;

  &::after {
    width: 16px !important;
    height: 16px !important;
  }
`;

export const StyledText = styled(BodySmall)`
  color: ${({ theme }) => theme.colors.grey.dark} !important;
  cursor: pointer;
  &:hover {
    color: ${({ theme }) => theme.colors.blue.standard} !important;
  }
`;

export const ColoredPaper = styled(Paper)`
  flex-direction: column;
  min-height: 224px;
  padding: 24px;
  border-radius: 16px;
  background: linear-gradient(147deg, #f54b31 10.8%, #7070ff 61%);
`;
