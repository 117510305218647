import { useTranslation } from 'react-i18next';
import { Nullable } from 'tsdef';
import { BodyLarge, Button, Flex, Link, Popup, Separator } from '@beauty/beauty-market-ui';
import { PaymentForSubscription } from 'components';
import { SubscriptionDataType, SubscriptionPeriodType, SubscriptionPlanType } from 'types';
import { stringForSendSupportEmail } from '../../../../constants';

type TariffActionsPropsType = {
  period: SubscriptionPeriodType;
  plan: Nullable<SubscriptionPlanType>;
  subscriptionPlans: SubscriptionDataType[];
  onClose: () => void;
};

export const TariffActions = ({ period, plan, subscriptionPlans, onClose }: TariffActionsPropsType) => {
  const { t } = useTranslation();

  const handleSendEmail = () => {
    window.location.href = stringForSendSupportEmail;
    onClose();
  };

  switch (plan) {
    case SubscriptionPlanType.BASIC:
    case SubscriptionPlanType.BUSINESS:
    case SubscriptionPlanType.PRO: {
      const planData = subscriptionPlans.find(subscription => subscription.planType === plan) as SubscriptionDataType;
      return planData ? <PaymentForSubscription period={period} planData={planData} onClose={onClose} /> : null;
    }

    case SubscriptionPlanType.ENTERPRISE:
      return (
        <Popup
          title={t('subscriptions.individualPlanSubscription')}
          info=""
          open
          handleClose={onClose}
          FooterBody={
            <>
              <Button design="secondary" size="large" width="100%" onClick={onClose}>
                {t('button.cancel')}
              </Button>
              <Button size="large" width="100%" onClick={handleSendEmail}>
                {t('button.goToMail')}
              </Button>
            </>
          }
        >
          <Flex flexDirection="column" gap="16px">
            <BodyLarge>
              {t('subscriptions.ifYouWantToSubscribe')}
              <Link href={stringForSendSupportEmail} display="inline" ml="8px" onClick={onClose}>
                support@beautymarket.co.il
              </Link>
            </BodyLarge>
          </Flex>
          <Separator mt="40px" />
        </Popup>
      );
    case SubscriptionPlanType.UPGRADE:
      return (
        <Popup
          title={t('subscriptions.youHavePaidSubscription')}
          info=""
          open
          handleClose={onClose}
          FooterBody={
            <>
              <Button design="secondary" size="large" width="100%" onClick={onClose}>
                {t('button.cancel')}
              </Button>
              <Button size="large" width="100%" onClick={handleSendEmail}>
                {t('button.goToMail')}
              </Button>
            </>
          }
        >
          <Flex flexDirection="column" gap="16px">
            <BodyLarge>
              {t('subscriptions.ifYouWantToChangePlan')}
              <Link href={stringForSendSupportEmail} display="inline" ml="8px" onClick={onClose}>
                support@beautymarket.co.il
              </Link>
            </BodyLarge>
          </Flex>
          <Separator mt="40px" />
        </Popup>
      );
    default:
      return null;
  }
};
