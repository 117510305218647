import { TFunction, useTranslation } from 'react-i18next';
import {
  BelarusFlag,
  calendarColors,
  calendarIcons,
  GeorgiaFlag,
  IsraelFlag,
  MastercardIcon,
  PolandFlag,
  PortugalFlag,
  RussiaFlag,
  serviceIcons,
  UKFlag,
  UkraineFlag,
  USAFlag,
  VisaIcon,
} from '@beauty/beauty-market-ui';
import { RouterUrl } from './routes/routes';
import { AccessLevel, Language, LatLngLiteral, Level, OptionType, PaymentSystemType } from './types';
import { ProfileType } from './types/user';

export const breadcrumbs = [
  { name: 'Home', onItemClick: () => console.log('onItemClick') },
  { name: 'Barbershops', onItemClick: () => console.log('onItemClick') },
  { name: 'Blacksmith Barbershop', onItemClick: () => console.log('onItemClick') },
];

export const mapCenter: LatLngLiteral = { lat: 31.768806421811647, lng: 34.6281344937872 };
export const userLocation: LatLngLiteral = { lat: 31.74, lng: 34.64 };

export const excludeMenuRouteList = [RouterUrl.Login, RouterUrl.LogOut, RouterUrl.ForBusiness];

export const excludeFooterRouteList = [RouterUrl.Login, RouterUrl.LogOut];

export const SUPPORT_EMAIL = 'support@beautymarket.co.il';
export const PARTNER_EMAIL = 'partner@beautymarket.co.il';
export const SMS_CODE_EXPIRATION_TIME = 60;

export const MAX_MARK = 5;
export const CLOSE_AFTER = 10;

export const userLocale = Intl.DateTimeFormat().resolvedOptions().locale;

export const selectedLocale = localStorage.getItem('i18nextLng')?.toUpperCase() || 'EN';

export const getSelectedLanguage = () => {
  const { i18n } = useTranslation();
  return i18n.resolvedLanguage.toUpperCase();
};

export const urlWithLang = (url: string) => {
  const { i18n } = useTranslation();
  const lng = i18n.resolvedLanguage.toLowerCase();
  return `${url}?lng=${lng}`;
};

// Test URL
export const MARKET_TEST_URL = 'https://bmarket-test.nolink.ink';
export const CRM_TEST_URL = 'https://crm0.nolink.ink';
const SERVER_TEST_URL = 'https://api-test.nolink.ink';

// Prod URL
export const MARKET_URL = 'https://beautymarket.co.il';
export const CRM_URL = 'https://crm.beautymarket.co.il';
const SERVER_URL = 'https://api.beautymarket.co.il';

// Pred prod
export const MARKET_PRED_URL = 'https://beautymarket.dev';
export const CRM_PRED_URL = 'https://crm.beautymarket.dev';
const SERVER_PRED_URL = 'https://api.beautymarket.dev';

export const PARTNER_TERMS_URL = 'https://storage.googleapis.com/beautymarketapi/Beauty%20Market%20Partner%20Terms.pdf';
export const stringForSendSupportEmail = 'mailto: support@beautymarket.co.il';
export const stringToOpenSite = 'http://beautymarket.co.il';

export const transComponents = {
  br: <br />,
  span: <span />,
};

export const EmptyProfile: ProfileType = {
  id: '',
  name: '',
  surname: '',
  dateOfBirth: null,
  currency: null,
  language: '',
  avatarUrl: null,
  password: null,
  contactId: null,
  addressId: null,
  jwtRefreshToken: null,
  marketingNotes: false,
  createdAt: '',
  updateAt: '',
  code: '',
  number: '',
  email: '',
  gender: null,
  accType: '',
  verified: false,
  telegram: null,
};

export const emailReg =
  /^(([^<>()\\[\]\\.,;:\s@"]+(\.[^<>()\\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
export const passwordReg = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)/;
export const dateReg =
  /^((((0?[1-9]([-./])(0?[1-9]|1[0-2]))|([12][0-9]([-./])((0?[13-9])|1[0-2]))|(((1[0-9])|(2[0-8]))([-./])0?2)|(30([-./])((0?[13-9])|1[0-2]))|(31([-./])((0?[13578])|10|12)))(\5|\8|\15|\17|\21)(19|20)\d{2})|(29([-./])0?2(\27)(([0-9]{2}((0[48])|([2468][048])|([13579][26])))|((([02468][048])|([13579][26]))00))))$/;

// export const instagramReg = /^(?!.*\.\.)(?!.*\.$)([^\W][\w.]{0,29})?$/;
export const instagramDomain = /https:\/\/www.instagram.com\//;
export const generalInstagramReg =
  /^@{0,1}(?!.*\.\.)(?!.*\.$)([^\W][\w.]{0,29})?$|^https:\/\/www.instagram.com\/@{0,1}(?!.*\.\.)(?!.*\.$)([^\W][\w.]{0,29})?(\/){0,1}$|^@$/;

export const generalTelegramReg = /(https?:\/\/)?(www[.])?(telegram|t)\.me\/([a-zA-Z0-9_-]*)\/?$/;

export const DIGITS_ONLY_REGEXP = /^\d+$/;

export const UK_POSTAL_CODE_REGEXP = /^(GIR 0AA|[A-Z]{1,2}[0-9][0-9]?[A-Z]?\s?[0-9][A-Z]{2})$/;
export const OTHER_POSTAL_CODE_REGEXP = /^\d{5}$/;
export const ISRAEL_POSTAL_CODE_REGEXP = /^\d{5}-\d{2}$/;
export const POLAND_POSTAL_CODE_REGEXP = /^\d{2}-\d{3}$/;
export const USA_POSTAL_CODE_REGEXP = /^\d{5}(-\d{4})?$/;
export const RUSSIA_POSTAL_CODE_REGEXP = /^\d{6}$/;
export const UKRAINE_POSTAL_CODE_REGEXP = /^\d{5}$/;
export const BELARUS_POSTAL_CODE_REGEXP = /^\d{6}$/;

export enum InvalidVariants {
  Exists = 'exists',
  Email = 'email',
  InvalidPhone = 'invalidPhone',
  InvalidOTP = 'invalidOTP',
  ExpiredOTP = 'expiredOTP',
  NotFound = 'notFound',
  MaxAttempts = 'maxAttempts',
  Unknown = 'unknown',
  TooMany = 'tooMany',
  UsedEmail = 'usedEmail',
  UsedNumber = 'usedNumber',
  FileLarge = 'File too large',
}

export const invalidHints = {
  [InvalidVariants.Exists]: 'registration.accountExists',
  [InvalidVariants.Email]: 'validation.emailNotValid',
  [InvalidVariants.InvalidPhone]: 'registration.invalidPhone',
  [InvalidVariants.InvalidOTP]: 'registration.invalidOTP',
  [InvalidVariants.ExpiredOTP]: 'registration.expiredOTP',
  [InvalidVariants.NotFound]: 'login.isNotFound',
  [InvalidVariants.MaxAttempts]: 'validation.maxAttempts',
  [InvalidVariants.Unknown]: 'validation.unknown',
  [InvalidVariants.TooMany]: 'validation.tooMany',
  [InvalidVariants.UsedEmail]: 'validation.usedEmail',
  [InvalidVariants.UsedNumber]: 'validation.usedNumber',
};

export enum AccType {
  USER = 'USER',
  BUSINESS = 'BUSINESS',
}

export const zIndex = {
  minusOne: -1,
  zero: 0,
  one: 1,
  mainMenuColumn: 10,
  privacyBodyWrapper: 2,
  homeBodyWrapper: 5,
  footerWrapper: 2,
  headerWrapper: 6,
  accountSettingBodyWrapper: 1,
  loginInfoWrapper: 2,
  dropdown: 10,
  menuClient: 1,
};

export enum EventStatus {
  PENDING = 'PENDING',
  CONFIRMED = 'CONFIRMED',
  WAITING = 'WAITING',
  INPROGRESS = 'INPROGRESS',
  CANCELLED = 'CANCELLED',
  NOSHOW = 'NO_SHOW',
  COMPLETED = 'COMPLETED',
  UNCLOSED = 'UNCLOSED',
  PAST = 'PAST',
}

const { Pending, Confirmed, Waiting, InProgress, Completed, NoShow } = calendarIcons;

export const statusIcons = {
  [EventStatus.PENDING]: Pending,
  [EventStatus.CONFIRMED]: Confirmed,
  [EventStatus.WAITING]: Waiting,
  [EventStatus.INPROGRESS]: InProgress,
  [EventStatus.NOSHOW]: NoShow,
  [EventStatus.CANCELLED]: NoShow,
  [EventStatus.COMPLETED]: Completed,
  [EventStatus.PAST]: Completed,
  [EventStatus.UNCLOSED]: Completed,
};

// TODO: Temporary const. Should be removed after we able to set color for service from FE
export const calendarColorsMock = [
  calendarColors.red,
  calendarColors.orange,
  calendarColors.yellow,
  calendarColors.green,
  calendarColors.lightGreen,
  calendarColors.blue,
  calendarColors.lightBlue,
  calendarColors.oceanBlue,
  calendarColors.lilac,
  calendarColors.violet,
];

const {
  AllServicesIcon,
  RelaxIcon,
  NailsIcon,
  MakeupIcon,
  EyebrowsIcon,
  DepilationIcon,
  BarberIcon,
  SkincareIcon,
  MassageIcon,
  HardwareIcon,
  HairIcon,
  ForMenIcon,
  MedicalAestheticIcon,
  ForEventIcon,
  FitnessIcon,
  NutritionIcon,
  GroomingIcon,
  NaturopathyIcon,
  MobileIcon,
  EducationIcon,
  StyleIcon,
} = serviceIcons;

export const ServiceIcons = {
  All: <AllServicesIcon />,
  Skincare: <SkincareIcon />,
  Massage: <MassageIcon />,
  Hardware: <HardwareIcon />,
  Hair: <HairIcon />,
  SPA: <RelaxIcon />,
  'Brow&Lash': <EyebrowsIcon />,
  'For Men': <ForMenIcon />,
  Cosmetology: <MedicalAestheticIcon />,
  Events: <ForEventIcon />,
  Fitness: <FitnessIcon />,
  Nails: <NailsIcon />,
  Makeup: <MakeupIcon />,
  Nutrition: <NutritionIcon />,
  Grooming: <GroomingIcon />,
  Naturopathy: <NaturopathyIcon />,
  Mobile: <MobileIcon />,
  Education: <EducationIcon />,
  Style: <StyleIcon />,
  Depilation: <DepilationIcon />,
  Barbershop: <BarberIcon />,
};

export const orderedTopCategories = [
  'Nails',
  'Hair',
  'Cosmetology',
  'Brow&Lash',
  'Massage',
  'Skincare',
  'Depilation',
  'SPA',
  'Barbershop',
  'For Men',
  'Makeup',
  'Fitness',
  'Hardware',
  'Nutrition',
  'Education',
  'Style',
  'Grooming',
  'Naturopathy',
  'Events',
  'Mobile',
];

export const positions = ['specialist', 'manager'];
export const access = ['specialist', 'admin'];

export enum SpecialistsRoles {
  MANAGER = 'MANAGER',
  SPECIALIST = 'SPECIALIST',
}

export const regionListCode = [
  {
    code: '+972',
    country: 'Israel',
    flag: <IsraelFlag />,
    short: 'IL',
  },
  {
    code: '+44',
    country: 'United Kingdom',
    flag: <UKFlag />,
    short: 'UK',
  },
  {
    code: '+1',
    country: 'United States',
    flag: <USAFlag />,
    short: 'US',
  },
  {
    code: '+48',
    country: 'Poland',
    flag: <PolandFlag />,
    short: 'PL',
  },
  {
    code: '+375',
    country: 'Belarus',
    flag: <BelarusFlag />,
    short: 'BY',
  },
  {
    code: '+7',
    country: 'Russia',
    flag: <RussiaFlag />,
    short: 'RU',
  },
  {
    code: '+380',
    country: 'Ukraine',
    flag: <UkraineFlag />,
    short: 'UA',
  },
  {
    code: '+995',
    country: 'Georgia',
    flag: <GeorgiaFlag />,
    short: 'GE',
  },
  {
    code: '+351',
    country: 'Portugal',
    flag: <PortugalFlag />,
    short: 'PT',
  },
] as const;

export enum GoogleStatusCode {
  OK = 'OK',
  ZeroResults = 'ZERO_RESULTS',
  OverLimit = 'OVER_QUERY_LIMIT',
  Denied = 'REQUEST_DENIED',
  Invalid = 'INVALID_REQUEST',
  Unknown = 'UNKNOWN_ERROR',
  ERROR = 'ERROR',
}

export const emptyAddress = { country: '', city: '', street: '', building: '', office: '', postal: '' };

// TODO It's awful solution, but we need it fast. Refactor is needed
export const locationsWithStandardHeaderFooter = [
  RouterUrl.Login,
  RouterUrl.LoginIntro,
  RouterUrl.LoginSMS,
  RouterUrl.Business,
  RouterUrl.EveryOne,
  RouterUrl.SignUp,
  RouterUrl.EmailVerification,
  RouterUrl.Registration,
  RouterUrl.PhoneVerification,
  RouterUrl.UpdatePassword,
  RouterUrl.ResetPassword,
  RouterUrl.SentEmailNotification,
  RouterUrl.WelcomeCrm,
] as string[];

export const MAX_PHOTO_SERVICE_COUNT = 1;
export const MAX_PHOTO_ADDRESS_COUNT = 5;
export const MAX_PHOTO_EMPLOYEE_COUNT = 8;

export type AccessRangeType = {
  [key in AccessLevel]: AccessLevel[];
};

export const AccessRange: AccessRangeType = {
  OWNER: ['OWNER'],
  SUPERADMIN: ['OWNER', 'SUPERADMIN'],
  ADMIN: ['OWNER', 'SUPERADMIN', 'ADMIN'],
  SPECIALIST: ['OWNER', 'SUPERADMIN', 'ADMIN', 'SPECIALIST'],
};

export enum Currency {
  ILS = 'ILS',
  USD = 'USD',
  EUR = 'EUR',
  BYN = 'BYN',
  GEL = 'GEL',
  PLN = 'PLN',
  RUB = 'RUB',
  UAH = 'UAH',
  GBP = 'GBP',
}

export const currencySymbol = {
  [Currency.ILS]: '₪ ',
  [Currency.USD]: '$ ',
  [Currency.EUR]: '€ ',
  [Currency.BYN]: 'BYN ',
  [Currency.GEL]: '₾ ',
  [Currency.PLN]: 'zł ',
  [Currency.RUB]: '₽ ',
  [Currency.UAH]: '₴ ',
  [Currency.GBP]: '£ ',
};

export const getCurrencyOptions = t => [
  {
    id: Currency.ILS,
    value: t(`currency.${Currency.ILS}`),
  },
  {
    id: Currency.USD,
    value: t(`currency.${Currency.USD}`),
  },
  {
    id: Currency.EUR,
    value: t(`currency.${Currency.EUR}`),
  },
  {
    id: Currency.BYN,
    value: t(`currency.${Currency.BYN}`),
  },
  {
    id: Currency.GEL,
    value: t(`currency.${Currency.GEL}`),
  },
  {
    id: Currency.PLN,
    value: t(`currency.${Currency.PLN}`),
  },
  {
    id: Currency.RUB,
    value: t(`currency.${Currency.RUB}`),
  },
  {
    id: Currency.UAH,
    value: t(`currency.${Currency.UAH}`),
  },
  {
    id: Currency.GBP,
    value: t(`currency.${Currency.GBP}`),
  },
];

export const genders = ['male', 'female'];

export const cardTooltipWidth = 320;

export const CardsIcons = {
  [PaymentSystemType.VISA]: <VisaIcon />,
  [PaymentSystemType.MASTERCARD]: <MastercardIcon />,
};

export const getLanguageOptions = t => ({
  ids: [Language.Hebrew, Language.Russian, Language.English],
  data: {
    [Language.English]: {
      id: Language.English,
      icon: <UKFlag />,
      value: t('language.english'),
    },
    [Language.Russian]: {
      id: Language.Russian,
      icon: <RussiaFlag />,
      value: t('language.russian'),
    },
    [Language.Hebrew]: {
      id: Language.Hebrew,
      icon: <IsraelFlag />,
      value: t('language.hebrew'),
    },
  },
});

export const getSpokenLanguages = (
  t: TFunction<'translation', undefined>,
  selected: string[] = [],
): Record<string, OptionType> => ({
  [Language.English]: {
    id: Language.English,
    value: t('language.english'),
    disabled: selected.includes(Language.English),
  },
  [Language.Russian]: {
    id: Language.Russian,
    value: t('language.russian'),
    disabled: selected.includes(Language.Russian),
  },
  [Language.Hebrew]: { id: Language.Hebrew, value: t('language.hebrew'), disabled: selected.includes(Language.Hebrew) },
  [Language.Amharic]: {
    id: Language.Amharic,
    value: t('language.amharic'),
    disabled: selected.includes(Language.Amharic),
  },
  [Language.Arabic]: { id: Language.Arabic, value: t('language.arabic'), disabled: selected.includes(Language.Arabic) },
  [Language.French]: { id: Language.French, value: t('language.french'), disabled: selected.includes(Language.French) },
  [Language.German]: { id: Language.German, value: t('language.german'), disabled: selected.includes(Language.German) },
  [Language.Spanish]: {
    id: Language.Spanish,
    value: t('language.spanish'),
    disabled: selected.includes(Language.Spanish),
  },
  [Language.Ukrainian]: {
    id: Language.Ukrainian,
    value: t('language.ukrainian'),
    disabled: selected.includes(Language.Ukrainian),
  },
});

export const getSpokenLevels = (
  t: TFunction<'translation', undefined>,
): Record<string, { id: string; value: string }> => ({
  [Level.A1]: { id: Level.A1, value: 'A1' },
  [Level.A2]: { id: Level.A2, value: 'A2' },
  [Level.B1]: { id: Level.B1, value: 'B1' },
  [Level.B2]: { id: Level.B2, value: 'B2' },
  [Level.C1]: { id: Level.C1, value: 'C1' },
  [Level.C2]: { id: Level.C2, value: 'C2' },
  [Level.Native]: { id: Level.Native, value: t('language.native') },
});

export const VALID_IMAGES = ['image/jpeg', 'image/png', 'image/jpg', 'image/png'];

export const ValidEducation = ['image/jpeg', 'image/png', 'image/jpg', 'application/pdf'];

export const MAX_PHOTO_SIZE = 6;

// constants for Calendar
export const listViewDaysPeriod = 7;
export const timeStepInDayWeekView = 15;
export const numberOfTimeslotsPerTimeStep = 1;
export const maxCalendarDays = 94;
export const startYear = 2024;
export const yearsToShow = 3;

export const localesCalendarData = {
  en: {
    weekdaysShort: 'Mon_Tue_Wed_Thu_Fri_Sat_Sun'.split('_'),
  },
  ru: {
    months: 'Январь_Февраль_Март_Апрель_Май_Июнь_Июль_Август_Сентябрь_Октябрь_Ноябрь_Декабрь'.split('_'),
    monthsShort: 'Янв_Фев_Март_Апр_Май_Июнь_Июль_Авг_Сен_Окт_Ноя_Дек'.split('_'),
    weekdaysShort: 'Пн_Вт_Ср_Чт_Пт_Сб_Вс'.split('_'),
  },
  he: {
    months: 'דצמבר_נובמבר_אוקטובר_ספטמבר_אוגוסט_יולי_יוני_מאי_אפריל_מרץ_פברואר_ינואר'.split('_').reverse(),
    monthsShort: 'דצמבר_נובמבר_אוקטובר_ספטמבר_אוגוסט_יולי_יוני_מאי_אפריל_מרץ_פברואר_ינואר'.split('_').reverse(),
    weekdaysShort: 'שבת_ו׳_ה׳_ד׳_ג׳_ב׳_א׳'.split('_').reverse(),
  },
};

export const HHmm = 'HH:mm';
export const DAYS_PER_WEEK = 7;
export const HOURS_PER_DAY = 24;
export const MINUTES_PER_HOUR = 60;
