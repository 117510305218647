import { TFunction } from 'react-i18next';
import * as Yup from 'yup';
import { validateMinMax } from 'helpers';

export enum MainServiceFormFields {
  Name = 'name',
  Description = 'description',
}

export type MainServiceFormType = {
  [MainServiceFormFields.Name]: string;
  [MainServiceFormFields.Description]: string;
};

export const mainServiceValidationSchema = (t: TFunction<'translation', undefined>) =>
  Yup.object({
    [MainServiceFormFields.Name]: validateMinMax(t, 2, 150).required(t('validation.notEmpty')),
    [MainServiceFormFields.Description]: validateMinMax(t, 2, 1000),
  });
