import { Navigate, Views } from 'react-big-calendar';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { LeftIcon, RightIcon, Button } from '@beauty/beauty-market-ui';
import { useAppSelector } from 'store/hooks';
import { selectAppointments } from 'store/redux-slices/appointmentsSlice';
import { Wrapper } from './style';

type TodayProps = {
  view: Views;
  onNavigate: (value: string) => void;
};

export const Today = ({ view, onNavigate }: TodayProps) => {
  const { t } = useTranslation();
  const { date } = useAppSelector(selectAppointments);

  const handlePrev = () => {
    onNavigate(Navigate.PREVIOUS);
  };

  const handleNext = () => {
    onNavigate(Navigate.NEXT);
  };

  const handleToday = () => {
    ((view === Views.DAY && !moment(date).isSame(moment(), 'day')) ||
      (view === Views.MONTH && !moment(date).isSame(moment(), 'month')) ||
      (view === Views.WEEK && !moment(date).isSame(moment(), 'week'))) &&
      onNavigate(Navigate.TODAY);
  };

  return (
    <Wrapper>
      <Button design="tertiaryBlack" size="small" onClick={handlePrev}>
        <LeftIcon width="16px" height="16px" />
      </Button>
      <Button design="tertiaryBlack" size="small" onClick={handleToday}>
        {t('calendar.today')}
      </Button>
      <Button design="tertiaryBlack" size="small" onClick={handleNext}>
        <RightIcon width="16px" height="16px" />
      </Button>
    </Wrapper>
  );
};
