import { useTranslation } from 'react-i18next';
import { Navigate } from 'react-router-dom';
import { PopUp } from 'components';
import { SubscribeActions } from 'types';
import { RouterUrl } from '../../../../routes/routes';
import { UnsubscribeSidebar } from './UnsubscribeSidebar';
import { WalletSidebar } from './WalletSidebar';

export const CurrentPlanPageActions = ({ action, setAction }) => {
  const { t } = useTranslation();
  switch (action) {
    case SubscribeActions.NavigateToChangePlanPage:
      return <Navigate to={RouterUrl.ChangePlanPage} />;
    case SubscribeActions.Unsubscribe:
      return <UnsubscribeSidebar onClose={() => setAction(null)} />;
    case SubscribeActions.CancelSubscription:
      return (
        <PopUp
          title={t('subscriptions.doYouReallyWantToCancelSubscription')}
          description={t('subscriptions.afterCancellationYouWillLose')}
          onSubmit={() => setAction(SubscribeActions.Unsubscribe)}
          onClose={() => setAction(null)}
          confirm={t('subscriptions.unsubscribe')}
        />
      );
    case SubscribeActions.UpdateCard:
      return <WalletSidebar onClose={() => setAction(null)} />;
    default:
      return null;
  }
};
