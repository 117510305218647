import { memo } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router';
import { isArray } from 'lodash';
import {
  BodyLarge,
  BodySmall,
  Button,
  colors,
  CoverStack,
  CoverStackSpecialist,
  Flex,
  getMinWidthMediaQuery,
  H4,
  H6,
  InfoIcon,
  Separator,
  useMediaQuery,
} from '@beauty/beauty-market-ui';
import { getTranslation } from 'helpers/utils';
import { Image, Paper, Widget } from '../../../components';
import { getSelectedLanguage } from '../../../constants';
import { Adaptive } from '../../../hoc';
import { RouterUrl } from '../../../routes/routes';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import { selectHead, ThunkOrganisation } from '../../../store/redux-slices/organisationSlice';
import { ContentWrapper } from '../style';
import { getHeadOrgContent } from './helpers';

const OrganisationCard = () => {
  const { t } = useTranslation();
  const { pathname } = useLocation();
  const head = useAppSelector(selectHead);
  const { name, headOrgCategory, photo } = head;

  const dispatch = useAppDispatch();
  const language = getSelectedLanguage();

  const mediaQuery = getMinWidthMediaQuery('md');
  const isDesktop = useMediaQuery(mediaQuery);

  const isProfileSettings = pathname === RouterUrl.ProfileSettings;

  const orgName = name?.text || '';
  const topCategory = isArray(headOrgCategory)
    ? headOrgCategory.map(headOrgCat => getTranslation(headOrgCat.serviceCategory.title, language))
    : [];

  const handleEditButtonClick = (image: File) => {
    dispatch(ThunkOrganisation.editHead({ photo: image }));
  };

  const header = (
    <CoverStackSpecialist
      width="310px"
      specialistType={topCategory.join(', ')}
      specialistLabel={orgName}
      imgUrl={photo}
      onEditButtonClick={pathname === RouterUrl.ProfileSettings && handleEditButtonClick}
    />
  );

  const content = (
    <ContentWrapper>
      {getHeadOrgContent(t, head).map((item, index, arr) => (
        <Widget
          p="12px 0"
          key={item.label}
          title={<BodySmall>{item.label}</BodySmall>}
          separator={isDesktop ? false : index !== arr.length - 1}
          subtitle={
            <Adaptive
              mobile={BodyLarge}
              desktop={H6}
              truncated
              large={!isDesktop}
              title={item.value}
              dir={item?.direction}
            >
              {item.value}
            </Adaptive>
          }
        />
      ))}
      {isProfileSettings && (
        <>
          <Separator mt="14px" mb="28px" />
          <Button prefix={<InfoIcon />} design="quaternary" size="small" mb="4px" disabled width="100%">
            {t('settings.head.deleteAccount')}
          </Button>
        </>
      )}
    </ContentWrapper>
  );

  return isDesktop ? (
    <CoverStack width="310px" header={header} content={content} />
  ) : (
    <Paper borderRadius="10px" flexDirection="column" p="0 8px" mt="8px">
      <Image
        src={photo}
        width="100%"
        height="224px"
        flexDirection="column"
        alignItems="start"
        justifyContent="end"
        p="24px"
      >
        <Flex flexDirection="column">
          <BodySmall large mb="8px" color={colors.white.standard}>
            {topCategory.join(', ')}
          </BodySmall>
          <H4 color={colors.white.standard}>{orgName}</H4>
        </Flex>
      </Image>
      {content}
    </Paper>
  );
};

export default memo(OrganisationCard);
