import { isArray, lowerCase } from 'lodash';
import {
  AddressBaseType,
  AddressFormType,
  AddressPayloadType,
  AddressStateType,
  DayOrgType,
  DayType,
  EditAddressDataType,
  GeocodingResponseType,
  HeadOrganisationFormType,
  PhotoStoreType,
  PhotoType,
  SpecialistResponse,
  SpecialistStateType,
  WeekDay,
  WorkDayType,
} from 'types';
import { AddressType } from 'types/organisation';
import { ServiceStateType, TopCategoryStateType } from '../types/service';
import { UserState } from './redux-slices/userSlice';

export const checkRequiredFields = (day: WorkDayType) => day.isWorkDay && !!day.start && !!day.end;

export const removeIsWorkDay = (day: WorkDayType): DayType => ({
  dayOfWeek: day.dayOfWeek,
  start: day.start,
  end: day.end,
  breakStart: day.breakStart,
  breakEnd: day.breakEnd,
});

export const extractOrgBusinessHours = (organisation: AddressType) =>
  organisation?.allDayTemplate
    ? organisation.allDayTemplate.filter(checkRequiredFields).map(removeIsWorkDay)
    : organisation.schedule.filter(checkRequiredFields).map(removeIsWorkDay);

enum GoogleComponent {
  Country = 'country',
  Post = 'postal_code',
  City = 'locality',
  Street = 'route',
  Building = 'street_number',
}

export const parseAddress = (response: GeocodingResponseType, initialAddress: AddressFormType) => {
  const address = { ...initialAddress };
  for (const component of response.address_components) {
    const types = component.types as string[];

    for (const type of types) {
      switch (type) {
        case GoogleComponent.Country:
          address.country = component.long_name;
          break;
        case GoogleComponent.Post:
          address.postal = component.short_name;
          break;
        case GoogleComponent.City:
          address.city = component.short_name;
          break;
        case GoogleComponent.Street:
          address.street = component.short_name;
          break;
        case GoogleComponent.Building:
          address.building = component.short_name;
          break;
        default:
          break;
      }
    }
  }
  if (response.formatted_address) {
    address.fullAddress = response.formatted_address;
  }
  return address;
};

export const createPhotoFormData = (photos: File[]): FormData => {
  const photoFormData = new FormData();
  for (const file of photos) {
    photoFormData.append('photo', file);
  }
  return photoFormData;
};

type ExtractServicePropsType = {
  id?: string;
  headOrgId?: string;
  newService: ServiceStateType['newService'];
};

export const packServiceToFormData = ({ id, headOrgId, newService }: ExtractServicePropsType): FormData => {
  const { name, price, photos, service, language, duration, photoStore, description, availableFor } = newService;

  const formData = new FormData();
  if (id) formData.append('id', id);
  if (name) formData.append('name', name);
  if (duration) formData.append('duration', duration);
  if (language) formData.append('language', language);
  if (service) formData.append('category', service);
  if (headOrgId) formData.append('headOrgId', headOrgId);
  if (description) formData.append('description', description);
  if (availableFor) formData.append('availableFor', availableFor);

  formData.append('listPrice', price || '');

  if (photos && photos.length) {
    for (const photo of photos) {
      photo instanceof File && formData.append('photo', photo);
    }
  }

  if (photoStore && photoStore.del.length) {
    const delPhoto = JSON.stringify(photoStore.del.map(item => item.id));
    formData.append('delPhoto', delPhoto);
  }

  return formData;
};

export const extractTopCategoryId = (topIds: string[], categories: TopCategoryStateType, serviceId: string) => {
  for (const id of topIds) {
    const isParentId = categories[id].childs.some(service => service.id === serviceId);
    if (isParentId) {
      return id;
    }
  }
  return null;
};

export const extractWeek = (orgSchedule: DayOrgType[]): WorkDayType[] =>
  WeekDay.map(day => {
    const workDay = orgSchedule.find(item => item.dayOfWeek === day);

    return {
      dayOfWeek: day,
      start: workDay?.start ?? '07:00',
      end: workDay?.end ?? '07:15',
      breakStart: workDay?.breakStart ?? null,
      breakEnd: workDay?.breakEnd ?? null,
      isWorkDay: Boolean(workDay),
    };
  });

export const packPhotoStoreToFormData = (data: Partial<PhotoStoreType>): FormData => {
  const formData = new FormData();
  if (data.mainPhoto) {
    if (data.mainPhoto instanceof File) formData.append('mainPhoto', data.mainPhoto);
  }

  if (data.photos) {
    for (const file of data.photos) {
      if (file instanceof File) formData.append('photos', file);
    }
  }

  if (data.deletePhotos) {
    for (const file of data.deletePhotos) {
      formData.append('deletePhotos', file.id);
    }
  }

  return formData;
};

export const packOrgHeadToFormData = (data: HeadOrganisationFormType): FormData => {
  const formData = new FormData();
  for (const [key, value] of Object.entries(data)) {
    if (value) {
      if (typeof value === 'number') {
        formData.append(key, String(value));
      } else {
        formData.append(key, value);
      }
    }
  }

  return formData;
};

export const updateUserProfile = (state: UserState, payload: SpecialistResponse) => {
  state.profile = { ...state.profile, ...payload.account };
  state.user.avatarUrl = payload.account.avatarUrl;
  state.user.name = payload.account.name;
  state.user.email = payload.account.email;
  if (state.user.headOrgSpecialist) {
    state.user.headOrgSpecialist.role = payload.role;
  }
};

export const extractMainAddressInformation = (state: AddressStateType, payload: AddressPayloadType) => {
  state.newAddress.id = payload.id || '';
  state.newAddress.name = payload.name || '';
  state.newAddress.description = payload.description || null;
  state.newAddress.email = payload.contact.email.email || '';
  state.newAddress.phone = payload.contact.phone[0];
  state.newAddress.social = {};
  state.newAddress.property = payload?.property ?? [];

  if (payload.contact.socialNetwork) {
    for (const socialNetwork of payload.contact.socialNetwork) {
      state.newAddress.social[lowerCase(socialNetwork.label)] = socialNetwork.value;
      state.initialValues[lowerCase(socialNetwork.label)] = socialNetwork.value;
    }
  }

  state.initialValues.description = payload.description;
};

export const extractAddressDetailedInformation = (state: AddressStateType, payload: AddressPayloadType) => {
  const address = isArray(payload.address.address) ? payload.address.address[0] : payload.address.address;
  const fullAddressName =
    address.fullAddress || `${address.street} ${address.building}, ${address.city}, ${address.country}`;
  const newAddress = {
    id: address.id,
    country: address.country,
    city: address.city,
    street: address.street,
    building: address.building,
    office: address.office,
    postal: address.postal,
    description: address.description,
    fullAddress: fullAddressName,
  };
  state.newAddress.address = { ...newAddress };
  state.initialValues.address = { ...newAddress };
  state.geolocation = { lat: address.lat, lng: address.lng };
};

export const getUpdatedAddressDetailedInformation = (address, geolocation, initialValues): Partial<AddressBaseType> => {
  const fullAddressName = `${address.street} ${address.building}, ${address.city}, ${address.country}`;
  const updatedAddress = Object.keys(initialValues).reduce((acc: Partial<AddressFormType>, key) => {
    if (initialValues[key] !== address[key]) {
      acc[key] = address[key];
    }
    return acc;
  }, {});

  return {
    id: address.id,
    ...updatedAddress,
    fullAddress: fullAddressName,
    lat: geolocation?.lat || 0,
    lng: geolocation?.lng || 0,
  };
};

export const getUpdatedDataForEditOrganization = (state: AddressStateType): EditAddressDataType => {
  const { newAddress, geolocation, initialValues } = state;
  const { name, email, phone, address, description, social, property } = newAddress;

  const orgBusinessHours = extractOrgBusinessHours(newAddress);
  const phoneData = {
    id: phone.id,
    code: phone.code,
    number: phone.number,
  };

  const updatedAddress = getUpdatedAddressDetailedInformation(address, geolocation, initialValues.address);

  let result: EditAddressDataType = {
    timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
    name,
    email,
    phone: phoneData,
    address: updatedAddress,
    orgBusinessHours,
    property: property.map(item => item.id).join(','),
  };

  if (description && description !== initialValues.description) {
    result = { ...result, description };
  }

  if (social.instagram !== initialValues.social?.instagram) {
    result = { ...result, instagram: social?.instagram ?? '' };
  }

  if (social.telegram !== initialValues.social?.telegram) {
    result = { ...result, telegram: social?.telegram ?? '' };
  }

  if (social.whatsapp !== initialValues.social?.whatsapp) {
    const splitNumber = social?.whatsapp?.split(' ');
    const whatsapp =
      splitNumber?.length === 2
        ? {
            code: splitNumber[0],
            number: splitNumber[1],
          }
        : null;
    result = { ...result, whatsapp };
  }

  return result;
};
export const extractBaseSpecialistInfo = (state: SpecialistStateType, params: SpecialistResponse) => {
  state.currentSpecialist.id = params.id;
  state.currentSpecialist.role = params.role;
  state.currentSpecialist.accessStart = params.accessStart;
  state.currentSpecialist.accessEnd = params.accessEnd;
  state.currentSpecialist.accessLevel = params.accessLevel;
  state.currentSpecialist.account = params.account;
  state.currentSpecialist.published = params.published;
  state.currentSpecialist.specialization = params.specialization;
  state.currentSpecialist.active = params.active;
};

export const extractOrganizationPhotos = (state: AddressStateType, mainPhoto?: PhotoType, photo?: PhotoType[]) => {
  state.newAddress.photos.deletePhotos = [];
  state.newAddress.photos.photoStore = [];
  state.newAddress.photos.mainPhoto = '';
  state.newAddress.photos.photos = [];
  state.initialValues.photos = {
    photos: [],
    photoStore: [],
    deletePhotos: [],
    mainPhoto: '',
  };

  if (mainPhoto) {
    state.newAddress.photos.mainPhoto = mainPhoto.url;
    state.initialValues.photos.mainPhoto = mainPhoto.url;
  }

  if (photo) {
    const filteredPhotos = photo.filter(photoItem => (mainPhoto ? photoItem.id !== mainPhoto.id : true));
    const photoUrls = filteredPhotos.map(photoItem => photoItem.url);
    state.newAddress.photos.photoStore = photo;
    state.newAddress.photos.photos = photoUrls;
    state.initialValues.photos.photoStore = photo;
    state.initialValues.photos.photos = photoUrls;
  }
};
