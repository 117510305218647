import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import isEmpty from 'lodash/isEmpty';
import { BodySmall, Separator } from '@beauty/beauty-market-ui';
import { EditDescription, EditDescriptionFormType } from 'components';
import { useMediaScreen, useRequest } from 'hooks';
import { useAppSelector } from 'store/hooks';
import { selectSpecialist, selectSpecialistInfo, ThunkSpecialist } from 'store/redux-slices/specialistSlice';
import { SpecialistAction } from 'types';
import { EmployeeSection } from './EmployeeSection/EmployeeSection';

export const About = () => {
  const { t } = useTranslation();
  const { isDesktop } = useMediaScreen('md');
  const { id } = useAppSelector(selectSpecialist);
  const specialistInfo = useAppSelector(selectSpecialistInfo);

  const [action, setAction] = useState(SpecialistAction.None);

  const editAbout = useRequest(ThunkSpecialist.editSpecialistAbout, SpecialistAction.EditAbout);

  const handleClose = () => setAction(SpecialistAction.None);

  const handleOpen = () => setAction(SpecialistAction.EditAbout);

  const handleSubmit = async (data: EditDescriptionFormType) =>
    editAbout({ id, about: data?.description || '' }).finally(handleClose);

  return (
    <>
      <EmployeeSection
        position="middle"
        title={t('specialists.personalInfo.aboutSpecialist')}
        description={t('specialists.personalInfo.descriptionAbout')}
        placeholder={t('specialists.personalInfo.noInfoAboutSpecialist')}
        count={specialistInfo?.about?.length ?? 0}
        visibleCount={50}
        onClick={handleOpen}
        buttonLabel={t('specialists.personalInfo.editInfo')}
        separator={isEmpty(specialistInfo?.about) && isDesktop ? <Separator mb="16px" /> : null}
      >
        {specialistInfo.about && (
          <BodySmall key={specialistInfo.about} style={{ wordBreak: 'break-all' }}>
            {specialistInfo.about}
          </BodySmall>
        )}
      </EmployeeSection>
      {action === SpecialistAction.EditAbout && (
        <EditDescription
          defaultValue={specialistInfo?.about}
          title={t('specialists.personalInfo.aboutSpecialist')}
          subtitle={t('specialists.personalInfo.writeTheDescription')}
          onSubmit={handleSubmit}
          onClose={handleClose}
          onBack={handleClose}
          mb="135px"
        />
      )}
    </>
  );
};
