import { MutableRefObject } from 'react';
import { ContextMenu, Flex } from '@beauty/beauty-market-ui';
import { DropdownMenu } from '../CalendarHeader/DropdownMenu';
import { DropdownMenuWrapper } from '../CalendarHeader/style';
import { StyledButton, StyledMenuDotsIcon, ContextMenuWrapper } from './style';

type KebabBodyProps = {
  isMenu: boolean;
  onClick: (e: MouseEvent) => void;
  menuRef: MutableRefObject<null>;
  options: Array<{
    body: JSX.Element;
    onClick: (e?: any) => void;
  }>;
  isMobile?: boolean;
};

export const KebabBody = ({ isMenu, onClick, menuRef, options, isMobile = false }: KebabBodyProps) => {
  const menu = isMobile ? (
    <DropdownMenuWrapper ref={menuRef}>
      <DropdownMenu items={options} />
    </DropdownMenuWrapper>
  ) : (
    <ContextMenuWrapper ref={menuRef}>
      <ContextMenu items={options} />
    </ContextMenuWrapper>
  );

  return (
    <Flex>
      <StyledButton design="tertiary" type="button" onClick={onClick} isShowDots={isMobile}>
        <StyledMenuDotsIcon />
      </StyledButton>
      {isMenu && menu}
    </Flex>
  );
};
