import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import isEmpty from 'lodash/isEmpty';
import { BodySmall, colors, H6, NotifyStatus, Separator, Slider, useNotify } from '@beauty/beauty-market-ui';
import { getNotifyContent, isRtl } from 'helpers';
import { useMediaScreen } from 'hooks';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { selectSpecialist, selectSpecialistInfo, ThunkSpecialist } from 'store/redux-slices/specialistSlice';
import { PhotosUpdate, SpecialistAction } from 'types';
import { GalleryImage, GalleryStub } from '../../../style';
import { EmployeeSection } from '../EmployeeSection/EmployeeSection';
import { EditPhotoGallery } from './EditPhotoGallery';

export const PhotoGallery = () => {
  const { t } = useTranslation();
  const { isDesktop } = useMediaScreen('md');
  const { id } = useAppSelector(selectSpecialist);
  const { photo } = useAppSelector(selectSpecialistInfo);
  const notify = useNotify();
  const dispatch = useAppDispatch();
  const rtl = isRtl();

  const [action, setAction] = useState<SpecialistAction>(SpecialistAction.None);

  const photoUrls = useMemo(() => photo.map(item => item.url), [photo]);

  const handleEditPhotosClick = () => setAction(SpecialistAction.EditPhotos);

  const handleClose = () => setAction(SpecialistAction.None);

  const handleSubmit = async (data: PhotosUpdate) => {
    const deletePhotos = photo.filter(item => data?.deletePhotos.includes(item.url)).map(item => item.id);
    const updatePhotos = data.photos.filter(item => item instanceof File);

    await Promise.all([
      dispatch(
        ThunkSpecialist.editPhotos({
          id,
          data: { photos: updatePhotos.slice(0, 4), deletePhotos },
        }),
      ).unwrap(),
      updatePhotos.length > 4 &&
        dispatch(
          ThunkSpecialist.editPhotos({
            id,
            data: { photos: updatePhotos.slice(4, 6), deletePhotos: [] },
          }),
        ).unwrap(),
      updatePhotos.length > 6 &&
        dispatch(
          ThunkSpecialist.editPhotos({
            id,
            data: { photos: data.photos.slice(6), deletePhotos: [] },
          }),
        ).unwrap(),
    ])
      .then(() => notify(getNotifyContent(NotifyStatus.SUCCESS, SpecialistAction.EditPhotos, t)))
      .catch(() => notify(getNotifyContent(NotifyStatus.ERROR, SpecialistAction.EditPhotos, t)));
  };

  const slides = photo.map((slide, index) => (
    <GalleryImage key={slide.id} src={slide.url} alt={`slide number ${index + 1}`} />
  ));
  const stub = (
    <GalleryStub key="stub" onClick={handleEditPhotosClick} cursor="pointer">
      <BodySmall lowline color={colors.grey.dark}>
        {t('specialists.personalInfo.addPhoto')}
      </BodySmall>
    </GalleryStub>
  );

  return (
    <>
      <EmployeeSection
        title={t('specialists.personalInfo.photoGallery')}
        count={slides.length + 1}
        visibleCount={8}
        description=""
        placeholder=""
        buttonLabel={t('specialists.personalInfo.addPhoto')}
        onClick={handleEditPhotosClick}
        position="last"
        separator={isDesktop ? <Separator mb="16px" /> : null}
      >
        <Slider
          title={<H6 />}
          titleBottomMargin={isEmpty(slides) ? '0' : '16px'}
          slides={[...slides, stub]}
          isPaginationEnabled
          rtl={rtl}
        />
      </EmployeeSection>
      {action === SpecialistAction.EditPhotos && (
        <EditPhotoGallery onSubmit={handleSubmit} onClose={handleClose} defaultValues={photoUrls} />
      )}
    </>
  );
};
