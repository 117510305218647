import { TFunction } from 'react-i18next';
import moment from 'moment/moment';
import * as Yup from 'yup';
import { getCurrencyTemplate, priceValidation } from 'helpers';
import { AppointmentByIdResponse, PaymentType } from 'types';
import { EventStatus } from '../../../../constants';
import {
  compareAppointmentEndAndNow,
  compareAppointmentEndAndStart,
  compareAppointmentStartAndEnd,
  compareAppointmentStartAndNow,
} from '../../../Calendar/Sidebars/helpers';

export enum AppointmentFormFields {
  Address = 'address',
  Status = 'status',
  Payment = 'paymentMethod',
  Notes = 'notes',
  Client = 'clientId',
  Service = 'orgServId',
  Specialist = 'orgSpecId',
  Start = 'start',
  End = 'end',
  AppointmentDate = 'date',
  Price = 'price',
}

export type AppointmentForm = {
  [field in AppointmentFormFields]: string;
};

export const getInitialValues = (appointment: AppointmentByIdResponse | null) => {
  const status =
    appointment?.status === EventStatus.PAST || appointment?.status === EventStatus.UNCLOSED
      ? EventStatus.COMPLETED
      : appointment?.status;

  return {
    [AppointmentFormFields.Address]: '',
    [AppointmentFormFields.Status]: status ?? '',
    [AppointmentFormFields.Payment]: PaymentType.CASH,
    [AppointmentFormFields.Notes]: appointment?.notes || '',
    [AppointmentFormFields.Client]: appointment?.clientId ?? '',
    [AppointmentFormFields.Service]: appointment?.orgService.id ?? '',
    [AppointmentFormFields.Specialist]: appointment?.orgSpecialist.id ?? '',
    [AppointmentFormFields.Start]: appointment?.start ? moment(appointment.start).format('HH:mm') : '',
    [AppointmentFormFields.End]: appointment?.end ? moment(appointment.end).format('HH:mm') : '',
    [AppointmentFormFields.AppointmentDate]: moment(appointment?.start).format('DD.MM.YYYY'),
    [AppointmentFormFields.Price]: appointment?.price ?? '',
  };
};

export const schema = (t: TFunction<'translation', undefined>, currencySign: string, skipCheckCurrentTime?: boolean) =>
  Yup.object({
    [AppointmentFormFields.Client]: Yup.string().required(t('validation.notEmpty')),
    [AppointmentFormFields.Status]: Yup.string().required(t('validation.notEmpty')),
    [AppointmentFormFields.Service]: Yup.string().required(t('validation.notEmpty')),
    [AppointmentFormFields.Specialist]: Yup.string().required(t('validation.notEmpty')),
    [AppointmentFormFields.AppointmentDate]: Yup.string().required(t('validation.notEmpty')),
    [AppointmentFormFields.Start]: skipCheckCurrentTime
      ? Yup.string()
          .required(t('validation.notEmpty'))
          .test('is-start-more-than-end', t('validation.irrelevantTime'), compareAppointmentStartAndEnd)
      : Yup.string()
          .required(t('validation.notEmpty'))
          .test('is-start-more-than-current', t('validation.irrelevantTime'), compareAppointmentStartAndNow)
          .test('is-start-more-than-end', t('validation.irrelevantTime'), compareAppointmentStartAndEnd),
    [AppointmentFormFields.End]: skipCheckCurrentTime
      ? Yup.string()
          .required(t('validation.notEmpty'))
          .test('is-start-more-than-end', t('validation.irrelevantTime'), compareAppointmentEndAndStart)
      : Yup.string()
          .required(t('validation.notEmpty'))
          .test('is-end-more-than-current', t('validation.irrelevantTime'), compareAppointmentEndAndNow)
          .test('is-start-more-than-end', t('validation.irrelevantTime'), compareAppointmentEndAndStart),
    [AppointmentFormFields.Notes]: Yup.string()
      .notRequired()
      .min(2, t('validation.minLength2'))
      .max(1000, t('validation.maxLength1000')),
    [AppointmentFormFields.Price]: priceValidation(getCurrencyTemplate(currencySign), t),
  });
