import { TFunction } from 'react-i18next';
import { NotifyStatus } from '@beauty/beauty-market-ui';

export const getErrorNotifyContent = (t: TFunction<'translation', undefined>, message?: string) => {
  const errorKey = message ? `alerts.errors.subtitle.${message}` : 'alerts.errors.subtitle.unknown';
  return {
    id: errorKey,
    status: NotifyStatus.ERROR,
    title: t(`alerts.error`),
    subtitle: t(errorKey),
  };
};
