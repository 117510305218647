import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { colors } from '@beauty/beauty-market-ui';
import { RouterUrl } from '../../routes/routes';
import { BubbleWrapper, CrmWrapper, StyledButton, StyledDisplay, StyledH6 } from './style';

export const WelcomeCrm = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <CrmWrapper>
      <BubbleWrapper>
        <StyledDisplay color={colors.white.standard}>{t('welcome.title')}</StyledDisplay>
        <StyledH6 color={colors.white.standard}>{t('welcome.toBegin')}</StyledH6>
        <StyledButton onClick={() => navigate(RouterUrl.AccountSetting)}>{t('welcome.button')}</StyledButton>
      </BubbleWrapper>
    </CrmWrapper>
  );
};
