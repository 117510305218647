import { useTranslation } from 'react-i18next';
import join from 'lodash/join';
import lowerCase from 'lodash/lowerCase';
import { Avatar, Badge, Caption, colors, Flex, Separator } from '@beauty/beauty-market-ui';
import { formatPhoneNumber, getFullName } from 'helpers';
import { getDir } from 'helpers/utils';
import { useMediaScreen } from 'hooks';
import { Tooltip } from '../../Tooltip';
import { EmployeeFullName, EmployeeInfo } from '../style';

const EmployeeCard = ({ id, specialization, role, account, separator, onClick, onActivate, active, disabled }) => {
  const { t } = useTranslation();
  const { isDesktop } = useMediaScreen('md');
  const fullName = getFullName(account);
  const phone = formatPhoneNumber(account.code, account.number);
  const contact = join([phone, isDesktop ? account.email : undefined].filter(Boolean), ' • ');

  return (
    <>
      <Flex
        onClick={onClick}
        cursor="pointer"
        alignItems={isDesktop ? 'stretch' : 'center'}
        p={isDesktop ? '16px 8px' : '8px'}
        justifyContent="space-between"
      >
        <Flex justifyContent="start" gap="8px">
          <Avatar size="xs" url={account.avatarUrl} rating="" anonymos={!account.avatarUrl ? 'default' : undefined} />
          <EmployeeInfo>
            {isDesktop && <Caption color={colors.grey.dark}>{specialization}</Caption>}
            <EmployeeFullName large truncated directed dir={getDir(fullName)}>
              {fullName}
            </EmployeeFullName>
            <Caption color={colors.grey.dark} dir="ltr" width="fit-content">
              {contact}
            </Caption>
          </EmployeeInfo>
        </Flex>
        <Flex alignItems="center" flexWrap={isDesktop ? 'nowrap' : 'wrap'} gap="16px" justifyContent="end">
          {!active && (
            <Tooltip id={id} content={t('specialists.forActivateSpec')} show={disabled}>
              <Badge data-tooltip-id={id} text={t('specialists.activate')} design="red" onClick={onActivate} />
            </Tooltip>
          )}
          <Badge text={t(`settings.specialists.positions.${lowerCase(role)}`)} design="dark" />
        </Flex>
      </Flex>
      {separator && <Separator />}
    </>
  );
};

export default EmployeeCard;
