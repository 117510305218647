import { useCallback, useEffect, useState } from 'react';
import { isEmpty } from 'lodash';
import { Loader } from 'components';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { emptyClient, selectClients, ThunkClient } from 'store/redux-slices/clientSlice';
import { selectHead } from 'store/redux-slices/organisationSlice';
import { ClientActions } from 'types';
import { ClientSection, ClientStub, ClientForm, ClientSidebar } from './components';
import { getParams } from './helper';

export const Clients = () => {
  const { id: headOrgId } = useAppSelector(selectHead);
  const clients = useAppSelector(selectClients);
  const dispatch = useAppDispatch();

  const [isLoading, setLoading] = useState(true);
  const [action, setAction] = useState<ClientActions>(ClientActions.None);

  const onSubmit = useCallback(
    async (data: ClientForm, orgId?: string) => {
      const params = getParams(data, emptyClient, false);
      return orgId ? dispatch(ThunkClient.createClient({ ...(params as ClientForm), orgId })).unwrap() : null;
    },
    [dispatch, getParams],
  );

  const handleAddEmployee = useCallback(() => setAction(ClientActions.Add), [setAction]);
  const handleClose = useCallback(() => setAction(ClientActions.None), [setAction]);

  useEffect(() => {
    setLoading(true);
    dispatch(ThunkClient.fetchClients(headOrgId)).finally(() => setLoading(false));
  }, []);

  return isLoading ? (
    <Loader />
  ) : (
    <>
      {isEmpty(clients) ? (
        <ClientStub onAddClick={handleAddEmployee} />
      ) : (
        <ClientSection onAddClick={handleAddEmployee} />
      )}
      {action !== ClientActions.None && <ClientSidebar onSubmit={onSubmit} onClose={handleClose} action={action} />}
    </>
  );
};
