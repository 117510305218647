import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Nullable } from 'tsdef';
import { Sidebar } from '@beauty/beauty-market-ui';
import { PaymentMethods, SidebarFooter } from 'components';
import { useRequest } from 'hooks';
import { OrganizationAction, PaymentCardType } from 'types';
import { useAppSelector } from '../../../../store/hooks';
import { selectPaymentCards, ThunkOrganisation } from '../../../../store/redux-slices/organisationSlice';

export const WalletSidebar = ({ onClose }) => {
  const { t } = useTranslation();
  const paymentCard = useAppSelector(selectPaymentCards);
  const setPaymentCard = useRequest(ThunkOrganisation.setPaymentCard, OrganizationAction.UpdateCard);

  const [card, setCard] = useState<Nullable<PaymentCardType>>(paymentCard.find(item => item.default) || null);

  const handleSubmit = () => {
    if (card) setPaymentCard(card.id).finally(onClose);
  };

  return (
    <Sidebar
      isOpen
      onClose={onClose}
      label={t('subscriptions.updatePaymentMethod')}
      descriptor={t('subscriptions.chooseNewWay')}
      FooterBody={
        <SidebarFooter
          onSubmit={handleSubmit}
          onBack={onClose}
          disabled={!card || (card && card.default)}
          confirmationLabel={t('subscriptions.savePaymentMethod')}
          cancel
        />
      }
    >
      <PaymentMethods value={card} onChange={setCard} />
    </Sidebar>
  );
};
