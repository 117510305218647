import { AccType } from '../constants';
import { TextWithTranslations } from './general';
import { OrganisationSubscriptionType } from './organisation';
import { AccessLevel, Role } from './specialist';

type TitleTranslation = {
  id: string;
  tid: string;
  langId: string;
  text: string;
};

type ServiceCategory = {
  title: {
    id: string;
    langId: string;
    text: string;
    translation: TitleTranslation[];
  };
};

type HeadOrgCategory = {
  serviceCategoryId: string;
  serviceCategory: ServiceCategory;
};

export type OrgSpecialist = {
  id: string;
  orgId: string;
  headOrgSpecId: string;
};

export type PaymentCardType = {
  id: string;
  default: boolean;
  number: string;
  system: string;
};

export type HeadOrganizationType = {
  id: string;
  currency: string;
  minTimeSlot: number;
  photo: string | null;
  employeesNum: string | null;
  headOrgCategory: HeadOrgCategory[];
  whatsApp: boolean;
  telegram: boolean;
  subscription: OrganisationSubscriptionType[];
  paymentCard: PaymentCardType[];
};

export type HeadOrganizationSpecialistType = {
  id: string;
  googleTokens: string;
  headOrgId: string;
  accessLevel: AccessLevel;
  googleCalendarEmail: string | null;
  headOrganization?: HeadOrganizationType;
  role?: Role;
  active?: boolean;
  orgSpecialist?: OrgSpecialist[];
};

export type UserType = {
  name: string;
  email: string;
  userId: string;
  headOrgSpecialist: HeadOrganizationSpecialistType | null;
  avatarUrl?: string;
  lang?: string;
};

export type SocialNetworkType = {
  label: string;
  id?: string;
  contactId?: string;
  link?: string;
};

export type NotificationType = {
  notificationId: string;
  title: TextWithTranslations;
  description: TextWithTranslations;
};

type AccountDataType = {
  name?: string;
  surname?: string;
  dateOfBirth?: Date;
  gender?: string;
};
type AddressDataType = {
  id: string;
  country?: string;
  city?: string;
  fullAddress?: string;
  accType?: string;
};
type EmailDataType = {
  id: string;
  email: string;
};
type PhoneDataType = {
  id: string;
  number: string;
  code: string;
};

export type PatchProfileType = {
  accountData?: AccountDataType;
  addressData?: AddressDataType[];
  emailData?: EmailDataType[];
  phoneData?: PhoneDataType[];
};

export type CreateAccountType = {
  name: string;
  surname: string;
  code: string;
  number: string;
  email: string;
  password: string;
  marketingNotes: boolean;
  otp: string;
  accType: AccType;
};

export type TelegramBotType = {
  id: string;
  accountId: string;
  chatId: number;
  userName: string;
};

export type ProfileType = {
  id: string;
  name: string;
  surname: string;
  dateOfBirth: string | null;
  currency: string | null;
  language: string;
  avatarUrl: string | null;
  password: string | null;
  contactId: string | null;
  addressId: string | null;
  jwtRefreshToken: string | null;
  marketingNotes: boolean;
  createdAt: string;
  updateAt: string;
  code: string;
  number: string;
  email: string;
  gender: string | null;
  accType: string;
  verified: boolean;
  telegram: TelegramBotType | null;
  headOrgSpecialist?: HeadOrganizationSpecialistType;
};

export type ActionType =
  | 'addCard'
  | 'deleteAccount'
  | 'editProfile'
  | 'logout'
  | 'changeEmail'
  | 'changePhone'
  | 'smsCode'
  | null;

export type PatchEmailType = {
  email: string;
  newEmail: string;
  host: string;
  language: string;
};

export type PostPhoneType = {
  code: string;
  number: string;
};

export type PatchPhoneType = {
  newCode: string;
  newNumber: string;
  otp: string;
} & PostPhoneType;

export enum ProfileActions {
  None = 'NONE',
  ChangePassword = 'CHANGE_PASSWORD',
  EditProfile = 'EDIT_PROFILE',
  DeleteAccount = 'DELETE_ACCOUNT',
  DisconnectTelegram = 'DISCONNECT_TELEGRAM',
  RevokeGoogleCalendar = 'REVOKE_GOOGLE_CALENDAR',
  ChangeNotificationLanguage = 'CHANGE_NOTIFICATION_LANGUAGE',
}
