import { useTranslation } from 'react-i18next';
import { Form } from 'formik';
import { H6, SearchIcon } from '@beauty/beauty-market-ui';
import { FormikInput } from 'components/functional/formik/formik-input/FormikInput';
import { ClientOption } from 'types/client';
import { FormWrapper } from '../../style';
import { AppointmentFormFields } from '../AppointmentSidebar.definitions';

type ClientFormProps = {
  clients: ClientOption[];
  selectedClient: number;
  onSelect: (index: number) => void;
  onDelete: () => void;
  onAddNewClient: () => void;
  disabled?: boolean;
};

export const ClientForm = ({
  clients,
  selectedClient,
  onSelect,
  onDelete,
  onAddNewClient,
  disabled = false,
}: ClientFormProps) => {
  const { Client } = AppointmentFormFields;
  const { t } = useTranslation();

  return (
    <Form>
      <FormWrapper>
        <H6 marginTop="8px">{t('calendar.newAppointmentSidebar.client')}</H6>
        <FormikInput
          id={Client}
          name={Client}
          placeholder={
            clients.length === 0
              ? t('calendar.newAppointmentSidebar.addNewClient')
              : t('calendar.newAppointmentSidebar.clientSearch')
          }
          iconLeft={<SearchIcon />}
          design="white"
          //  onButtonClick={isUserAdmin ? onAddButtonClick : undefined}
          searchInput
          currentOption={selectedClient}
          options={clients}
          onSelect={onSelect}
          onDeleteIconClick={onDelete}
          type="client"
          disabled={disabled}
        />
      </FormWrapper>
    </Form>
  );
};
