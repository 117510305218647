import { useTranslation } from 'react-i18next';
import isArray from 'lodash/isArray';
import {
  Badge,
  Flex,
  getMinWidthMediaQuery,
  Icon,
  Separator,
  TabCategory,
  useMediaQuery,
} from '@beauty/beauty-market-ui';
import { InfoBlock } from 'components';
import { getTranslation } from 'helpers';
import { useAppSelector } from 'store/hooks';
import { selectNewAddress } from 'store/redux-slices/addressSlice';
import { getSelectedLanguage, ServiceIcons } from '../../../../../constants';

export const AddressActivities = () => {
  const { t } = useTranslation();
  const { categories } = useAppSelector(selectNewAddress);
  const language = getSelectedLanguage();

  const mediaQuery = getMinWidthMediaQuery('md');
  const isDesktop = useMediaQuery(mediaQuery);

  const activities = isArray(categories)
    ? categories.flatMap((current, idx, arr) => {
        const index = arr.findIndex(item => item.parentId === current.parentId);
        if (idx !== index) return [];
        return {
          id: current.parentId,
          icon: (
            <Icon width="40px" height="40px">
              {ServiceIcons[current.parent.title.text]}
            </Icon>
          ),
          title: getTranslation(current.parent.title, language),
          size: 'medium',
        };
      })
    : [];

  return (
    <InfoBlock
      title={t('address.mainActivities')}
      description={t('address.categoriesOfServices')}
      placeholder={t('address.haveNotCategories')}
      separator={!activities.length && isDesktop ? <Separator mb="16px" /> : null}
      badge={activities.length ? <Badge text={activities.length} design="lightGrey" /> : null}
      count={activities.length}
      visibleCount={10}
    >
      {!!activities.length && (
        <Flex flexWrap="wrap" gap="8px" mt="8px">
          {activities.map(current => (
            <TabCategory key={current.id} {...current} hovered={false} />
          ))}
        </Flex>
      )}
    </InfoBlock>
  );
};
