import styled from 'styled-components';
import { Flex, mediaQueries } from '@beauty/beauty-market-ui';
import { Adaptive } from 'hoc';

export const OrganisationInfoContainer = styled(Flex)`
  flex-direction: column;
  box-sizing: border-box;
  gap: 1px;
  width: 100%;
  margin-bottom: 80px;

  ${mediaQueries.md} {
    gap: 16px;
    margin-bottom: 0;
  }
`;

export const ContactInfo = styled(Adaptive)`
  text-align: start;

  [dir='rtl'] & {
    text-align: end;
  }
`;
