import { Client, ClientInfo, ClientReviewPayload, CreateClientParams, Rate, UpdateClientParams } from 'types';
import { clientAPI } from '../../helpers/clientAPI';
import { createAppAsyncThunk } from '../create-app-async-thunk';

export const createClient = createAppAsyncThunk<Client, CreateClientParams>(
  'client/createClient',
  async (data, thunkAPI) => clientAPI.createClient({ data, thunkAPI }),
);

export const editClient = createAppAsyncThunk<Client, UpdateClientParams>('client/editClient', async (data, thunkAPI) =>
  clientAPI.updateClient({ data, thunkAPI }),
);

export const getClient = createAppAsyncThunk<CreateClientParams, string | undefined>(
  'client/getClient',
  async (id, thunkAPI) => (id ? clientAPI.getClient({ id, thunkAPI }) : thunkAPI.rejectWithValue(null)),
);

export const fetchClients = createAppAsyncThunk<Client[], string>('client/fetchClients', async (id, thunkAPI) =>
  clientAPI.fetchClients({ id, thunkAPI }),
);

export const fetchClient = createAppAsyncThunk<Client, string | undefined>(
  'client/fetchClient',
  async (id, thunkAPI) => (id ? clientAPI.fetchClient({ id, thunkAPI }) : thunkAPI.rejectWithValue(null)),
);

export const fetchClientInfo = createAppAsyncThunk<Client & ClientInfo, string | undefined>(
  'client/fetchClientInfo',
  async (id, thunkAPI) => (id ? clientAPI.fetchClientInfo({ id, thunkAPI }) : thunkAPI.rejectWithValue(null)),
);

export const sendReview = createAppAsyncThunk<{ totalRating: Rate }, { id: string; data: ClientReviewPayload }>(
  'client/sendReview',
  async (params, thunkAPI) => {
    const response = await clientAPI.sendReview(params.id, params.data);
    return response?.statusCode ? thunkAPI.rejectWithValue(null) : response;
  },
);
