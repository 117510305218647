import { TFunction } from 'react-i18next';
import { isValidPhoneNumber } from 'libphonenumber-js/max';
import * as Yup from 'yup';
import { getShortCountryByCode } from '../../../../helpers';

export enum NewClientFormFields {
  Name = 'name',
  Surname = 'surname',
  Number = 'number',
  Region = 'code',
}

export type NewClientForm = {
  [NewClientFormFields.Name]: string;
  [NewClientFormFields.Surname]: string;
  [NewClientFormFields.Region]: string;
  [NewClientFormFields.Number]: string;
};

export const initialValues: NewClientForm = {
  [NewClientFormFields.Name]: '',
  [NewClientFormFields.Surname]: '',
  [NewClientFormFields.Region]: '+972',
  [NewClientFormFields.Number]: '',
};

export const newClientValidationSchema = (t: TFunction<'translation', undefined>) =>
  Yup.object({
    [NewClientFormFields.Name]: Yup.string()
      .trim()
      .required(t('validation.notEmpty'))
      .min(2, t('validation.minLength2'))
      .max(50, t('validation.maxLength50')),
    [NewClientFormFields.Surname]: Yup.string()
      .trim()
      .required(t('validation.notEmpty'))
      .min(2, t('validation.minLength2'))
      .max(50, t('validation.maxLength50')),
    [NewClientFormFields.Region]: Yup.string().trim(),
    [NewClientFormFields.Number]: Yup.string()
      .trim()
      .required(t('validation.phoneRequired'))
      .test('validate-code', t('validation.codeRequired'), function validateCode() {
        return !!this.parent[NewClientFormFields.Region];
      })
      .test('validate-phone', t('validation.enterValidPhone'), function validatePhone(phone) {
        const code = this.parent[NewClientFormFields.Region];
        if (code) {
          return isValidPhoneNumber(`${code} ${phone}`, getShortCountryByCode(code));
        }
        return false;
      }),
  });
