import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';
import { Flex, getMinWidthMediaQuery, Title, useMediaQuery } from '@beauty/beauty-market-ui';
import { SidebarFooter, SidebarSheet } from 'components';
import { getFullName, hasArrayChanged } from 'helpers';
import { useRequest } from 'hooks';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { selectNewAddress, ThunkAddress } from 'store/redux-slices/addressSlice';
import { selectAllSpecialists } from 'store/redux-slices/specialistSlice';
import { AddressAction } from 'types';
import { ServiceIcons } from '../../../../../constants';
import { StyledIcon } from '../../style';
import { EmployeeItem } from './EmployeeItem';

export const EditEmployees = ({ onClose }) => {
  const { t } = useTranslation();
  const { id } = useParams();
  const { orgSpecialist } = useAppSelector(selectNewAddress);
  const allSpecialist = useAppSelector(selectAllSpecialists);
  const dispatch = useAppDispatch();

  const initial = useRef(orgSpecialist.map(item => item.headOrgSpecId));

  const [isDirty, setDirty] = useState(false);
  const [isSubmitting, setSubmitting] = useState(false);
  const [selected, setSelected] = useState<string[]>(initial.current);

  const mediaQuery = getMinWidthMediaQuery('md');
  const isDesktop = useMediaQuery(mediaQuery);

  const editEmployees = useRequest(ThunkAddress.editEmployees, AddressAction.EditEmployees);

  const handleSpecialistClick = (specId: string) => {
    if (selected.includes(specId)) {
      const filtered = selected.filter(org => org !== specId);
      setSelected(filtered);
    } else {
      setSelected([...selected, specId]);
    }
  };

  const handleSelectAllClick = () => {
    selected.length !== allSpecialist.length ? setSelected(allSpecialist.map(item => item.id)) : setSelected([]);
  };

  const handleSubmitForm = () => {
    setSubmitting(true);
    const params = {
      headOrgSpecIds: selected.join(','),
    };

    editEmployees(params).finally(() => {
      setSubmitting(false);
      onClose();
      id && dispatch(ThunkAddress.fetchAddressById(id));
    });
  };

  useEffect(() => {
    const dirty = hasArrayChanged(initial.current, selected);
    if (dirty !== isDirty) setDirty(dirty);
  }, [selected, allSpecialist, isDirty]);

  return (
    <SidebarSheet
      onClose={onClose}
      onBack={onClose}
      label={t('address.editEmployees')}
      descriptor={t('address.chooseSpecialistsWhichWork')}
      FooterBody={
        <SidebarFooter
          save
          cancel
          disabled={!isDirty}
          isLoading={isSubmitting}
          onSubmit={handleSubmitForm}
          onBack={onClose}
        />
      }
    >
      <Flex flexDirection="column">
        {isDesktop ? (
          <Title
            text={t('address.selectEmployees')}
            label={t('common.selectAll')}
            checked={selected.length === allSpecialist.length}
            onChange={handleSelectAllClick}
          />
        ) : (
          <EmployeeItem
            title={t('common.selectAll')}
            subtitle="Choose all specialist"
            image={<StyledIcon>{ServiceIcons.All}</StyledIcon>}
            checked={selected.length === allSpecialist.length}
            onChange={handleSelectAllClick}
            separator
          />
        )}
        {allSpecialist.map((item, index, arr) => (
          <EmployeeItem
            key={item.id}
            title={getFullName(item.account)}
            subtitle={item.specialization}
            image={item.account?.avatarUrl ?? ''}
            separator={index !== arr.length - 1}
            checked={selected.includes(item.id)}
            onChange={() => handleSpecialistClick(item.id)}
            mt={isDesktop && index === 0 ? '16px' : '0'}
          />
        ))}
      </Flex>
    </SidebarSheet>
  );
};
