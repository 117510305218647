import { Flex, RadioButton } from '@beauty/beauty-market-ui';

type FilterMobileItemProps = {
  id: number | null;
  body: JSX.Element;
  onClick: () => void;
  isChecked?: boolean;
};

export const FilterMobileItem = ({ body, onClick, isChecked }: FilterMobileItemProps) => (
  <Flex width="100%" height="40px" justifyContent="space-between" alignItems="center">
    {body}
    <RadioButton
      checked={isChecked}
      onClick={e => {
        e.stopPropagation();
        onClick();
      }}
    />
  </Flex>
);
