import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';
import { Nullable } from 'tsdef';
import { useNotify, NotifyStatus } from '@beauty/beauty-market-ui/';
import { getNotifyContent } from 'helpers';
import { OrganizationAction } from 'types';
import { useAppDispatch } from '../../../store/hooks';
import { ThunkOrganisation } from '../../../store/redux-slices/organisationSlice';

export const useGetPayments = (isTrial: boolean, isUnpaid?: boolean, id?: string) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const notify = useNotify();

  const timerRef = useRef<Nullable<NodeJS.Timeout | number>>(null);

  const [params, setParams] = useSearchParams();
  const [counter, setCounter] = useState(0);
  const [isLoading, setLoading] = useState(false);

  const clearTimer = () => {
    if (timerRef.current) clearTimeout(timerRef.current);
  };

  const handleResult = (status: NotifyStatus) => {
    notify(getNotifyContent(status, OrganizationAction.PaySubscription, t));
    clearTimer();
    setLoading(false);
    setParams({});
  };

  const tryGetSubscription = () => {
    setTimeout(() => {
      if (id) {
        dispatch(ThunkOrganisation.fetchHead(id));
      }
      setCounter(prev => prev + 1);
    }, 1000);
  };

  useEffect(() => {
    const success = params.get('success');
    if (success) {
      setLoading(true);
      if (success === 'true' && counter < 5) {
        isTrial || !isUnpaid ? tryGetSubscription() : handleResult(NotifyStatus.SUCCESS);
      } else if (success === 'false') {
        handleResult(NotifyStatus.ERROR);
      } else {
        window.location.reload();
      }
    }
    return () => clearTimer();
  }, [counter, isTrial]);

  return { isLoading };
};
