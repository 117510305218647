import { Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import { isEmpty } from 'lodash';
import moment from 'moment';
import { BodyLarge, Caption, Flex, H4, Link, Separator } from '@beauty/beauty-market-ui';
import LogoutImage from '../../../../assets/exit-image.png';
import { Paper } from '../../style';
import { getTextForInvoice } from '../helpers';

export const Invoices = ({ items }) => {
  const { t } = useTranslation();
  return (
    <Paper borderRadius="10px" p="24px" flexDirection="column">
      <H4>{t('subscriptions.invoices')}</H4>
      {isEmpty(items) ? (
        <Flex flexDirection="column" p="104px 24px" gap="24px" alignItems="center">
          <Flex width="240px">
            <img src={LogoutImage} alt={t('logout.altImg')} />
          </Flex>
          <H4>{t('subscriptions.noInvoices')}</H4>
        </Flex>
      ) : (
        <>
          <Separator mt="24px" />
          {items.map((invoice, index, arr) => (
            <Fragment key={invoice.id}>
              <Flex alignitems="center" justifyContent="space-between" padding="16px 0 0">
                <Flex flexDirection="column">
                  <Caption>{moment(invoice.createdAt).format('DD.MM.YYYY')}</Caption>
                  <BodyLarge large>{getTextForInvoice(invoice.planType, t)}</BodyLarge>
                </Flex>
                <Flex gap="16px">
                  {invoice.receiptUrl && (
                    <Link href={invoice.receiptUrl} target="_blank" m="10px 0">
                      {t('subscriptions.downloadInvoice')}
                    </Link>
                  )}
                  {invoice.url && (
                    <Link href={invoice.url} target="_blank" m="10px 0">
                      {t('subscriptions.downloadReceipt')}
                    </Link>
                  )}
                </Flex>
              </Flex>
              {index !== arr.length - 1 && <Separator mt="16px" />}
            </Fragment>
          ))}
        </>
      )}
    </Paper>
  );
};
