import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Nullable } from 'tsdef';
import { BodyLarge, BodySmall, colors, Flex, NotifyStatus, useNotify } from '@beauty/beauty-market-ui';
import { getNotifyContent } from 'helpers';
import { usePrice } from 'hooks';
import { OrganizationAction, PaymentCardType, SubscriptionPricePlanType } from 'types';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import { selectPaymentCards, ThunkOrganisation } from '../../../store/redux-slices/organisationSlice';
import { PaymentBar } from '../Payment';
import { BorderedPaper } from '../style';

export const TelegramBar = ({ onClose }) => {
  const { t } = useTranslation();
  const paymentCards = useAppSelector(selectPaymentCards);
  const dispatch = useAppDispatch();
  const notify = useNotify();

  const { price, todayPrice } = usePrice(SubscriptionPricePlanType.TELEGRAM);

  const [card, setCard] = useState<Nullable<PaymentCardType>>(paymentCards.find(item => item.default) || null);
  const [isSubmitting, setSubmitting] = useState(false);

  const handleSubmit = () => {
    setSubmitting(true);
    dispatch(ThunkOrganisation.payForTelegram({ cardId: card?.id }))
      .unwrap()
      .then(res => {
        if (res?.status === 0) {
          throw new Error(t('validation.unknown'));
        } else if (res.telegram) {
          notify(getNotifyContent(NotifyStatus.SUCCESS, OrganizationAction.PayForTelegram, t));
        } else if (res.payment_url) {
          window.location.href = res.payment_url;
        } else {
          throw new Error(t('validation.unknown'));
        }
      })
      .catch(() => notify(getNotifyContent(NotifyStatus.ERROR, OrganizationAction.PayForTelegram, t)))
      .then(() => onClose())
      .finally(() => setSubmitting(false));
  };

  return (
    <PaymentBar
      label={t('subscriptions.telegramConnection')}
      descriptor={t('subscriptions.toConnectTelegram')}
      card={card}
      setCard={setCard}
      onSubmit={handleSubmit}
      onClose={onClose}
      confirmationLabel={t('subscriptions.pay')}
      cancelLabel={t('button.cancel')}
      isLoading={isSubmitting}
    >
      <Flex flexDirection="column" gap="24px">
        <Flex flexDirection="column" gap="8px" alignItems="center">
          <BodyLarge>{t('subscriptions.youWillBeCharged', { price: todayPrice })}</BodyLarge>
          <BodyLarge>{t('subscriptions.thenForEachMonth', { price })}</BodyLarge>
        </Flex>
        <BorderedPaper>
          <BodyLarge>{t('subscriptions.paymentInformation')}</BodyLarge>
          <Flex justifyContent="space-between">
            <BodySmall color={colors.grey.dark}>{t('subscriptions.telegramPayment')}</BodySmall>
            <BodySmall color={colors.grey.dark}>{todayPrice}</BodySmall>
          </Flex>
          <Flex justifyContent="space-between">
            <BodySmall>{t('subscriptions.total')}</BodySmall>
            <BodySmall>{todayPrice}</BodySmall>
          </Flex>
        </BorderedPaper>
      </Flex>
    </PaymentBar>
  );
};
