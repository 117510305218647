import { NavigateFunction } from 'react-router-dom';
import { RouterUrl } from '../routes/routes';

const useRedirectAfterLogin = (navigate: NavigateFunction, hasUserHead: boolean) => {
  const url = hasUserHead ? RouterUrl.AccountSetting : RouterUrl.WelcomeCrm;

  navigate(url, { state: {} });
};

export default useRedirectAfterLogin;
