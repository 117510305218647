import { useTranslation } from 'react-i18next';
import {
  BodyLarge,
  BottomSheet,
  Button,
  Flex,
  getMinWidthMediaQuery,
  Popup,
  useMediaQuery,
} from '@beauty/beauty-market-ui';
import { PopupWrapper, StyledButton, TelegramIcon } from '../style';

type TelegramPopupProps = {
  isTelegram: boolean;
  onClose: () => void;
  connect: () => void;
  disconnect: () => void;
};

export const TelegramPopup = ({ isTelegram, onClose, connect, disconnect }: TelegramPopupProps) => {
  const { t } = useTranslation();
  const prefix = `profile.telegramPopup.${isTelegram ? 'disconnect' : 'connect'}`;

  const mediaQuery = getMinWidthMediaQuery('md');
  const isDesktop = useMediaQuery(mediaQuery);

  const title = t(`${prefix}.title`);

  const content = (
    <Flex gap="24px" flexDirection="column" mb={isDesktop ? '0' : '80px'}>
      <BodyLarge>{t(`${prefix}.info1`)}</BodyLarge>
      <BodyLarge mb="24px">{t(`${prefix}.info2`)}</BodyLarge>
    </Flex>
  );

  const FooterBody = (
    <>
      {isTelegram && (
        <Button size="large" width="100%" design="secondary" onClick={onClose}>
          {t('profile.telegramPopup.cancelButton')}
        </Button>
      )}
      <StyledButton
        size="large"
        width="100%"
        onClick={isTelegram ? disconnect : connect}
        prefix={!isTelegram ? <TelegramIcon /> : null}
        isRed={isTelegram}
      >
        {t(`profile.telegramPopup.${isTelegram ? 'disconnectButton' : 'openButton'}`)}
      </StyledButton>
    </>
  );

  return isDesktop ? (
    <PopupWrapper>
      <Popup open title={title} handleClose={onClose} FooterBody={FooterBody}>
        {content}
      </Popup>
    </PopupWrapper>
  ) : (
    <BottomSheet
      isOpen
      label={title}
      onClose={onClose}
      handleClose={onClose}
      FooterBody={FooterBody}
      content={content}
      detent="content-height"
    />
  );
};
