import styled, { css } from 'styled-components';
import { colors, Button, zIndex } from '@beauty/beauty-market-ui';

const generalStyles = css`
  width: 56px;
  height: 56px;
  border-radius: 50% !important;
  svg {
    width: 24px !important;
    height: 24px !important;
  }
  padding: 0 !important;
  box-shadow:
    0px 2px 24px 0px rgba(0, 0, 0, 0.08),
    0px 0px 2px 0px rgba(0, 0, 0, 0.08);
`;

export const StyledPrimaryButton = styled(Button)`
  ${generalStyles};
`;

export const StyledQuaternaryButton = styled(Button)<{ count: number }>`
  ${generalStyles};
  background-color: ${colors.white.standard}!important;

  ${({ count }) =>
    count &&
    css`
      &:after {
        background-color: ${colors.blue.standard}!important;
        min-width: 20px !important;
        padding: 0px 6px !important;
        height: 20px;
        border-radius: 12px !important;
        z-index: ${zIndex.styledButton};
        bottom: -2px !important;
        right: 0px !important;
      }
    `}
`;
