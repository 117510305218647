import { useTranslation } from 'react-i18next';
import { Form, Formik } from 'formik';
import { AnyObject, OptionalObjectSchema, TypeOfShape } from 'yup/lib/object';
import { RequiredStringSchema } from 'yup/lib/string';
import { Div, Button } from '@beauty/beauty-market-ui';
import { FormikInput } from '../../../components/functional/formik/formik-input/FormikInput';
import { InvalidVariants } from '../../../constants';
import { LoginFormSmsCodeFields, LoginSmsCodeForm } from '../Login.definitions';

type SmsFormProps = {
  formikContextValue: {
    initialValues: LoginSmsCodeForm;
    validationSchema: OptionalObjectSchema<
      {
        smmcode: RequiredStringSchema<string | undefined, AnyObject>;
      },
      AnyObject,
      TypeOfShape<{
        smmcode: RequiredStringSchema<string | undefined, AnyObject>;
      }>
    >;
    onSubmit: (data: LoginSmsCodeForm) => Promise<void>;
    validateOnMount: boolean;
  };
  invalidType: InvalidVariants | null;
  setInvalidType: (type: InvalidVariants | null) => void;
  isDefaultValue: boolean;
};

export const SmsForm = ({ formikContextValue, invalidType, setInvalidType, isDefaultValue }: SmsFormProps) => {
  const { t } = useTranslation();
  return (
    <Div width="100%">
      <Formik {...formikContextValue}>
        {({ isValid }) => (
          <Form>
            <FormikInput
              width="160px"
              id={LoginFormSmsCodeFields.SMSCode}
              name={LoginFormSmsCodeFields.SMSCode}
              placeholder={t('registration.smsCode')}
              invalidType={invalidType}
              onInput={() => setInvalidType(null)}
            />

            <Button
              disabled={!isValid || invalidType}
              design="primary"
              mt="16px"
              width="100%"
              size="large"
              type="submit"
            >
              {t(`login.continue`)}
            </Button>
          </Form>
        )}
      </Formik>
    </Div>
  );
};
