import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Nullable } from 'tsdef';
import { Sidebar } from '@beauty/beauty-market-ui';
import { SidebarFooter } from 'components/index';
import { PaymentCardType } from 'types';
import { useAppSelector } from '../../../store/hooks';
import { selectPaymentCards } from '../../../store/redux-slices/organisationSlice';
import { CardItem } from './CardItem';

export const CardList = ({ defaultValue, onSubmit, onClose }) => {
  const { t } = useTranslation();
  const paymentCards = useAppSelector(selectPaymentCards);

  const [value, setValue] = useState<Nullable<PaymentCardType>>(defaultValue);

  const handleChange = (card: PaymentCardType) => {
    setValue(card);
  };

  const handleSubmit = () => {
    onSubmit(value);
    onClose();
  };

  return (
    <Sidebar
      isOpen
      onBack={onClose}
      onClose={onClose}
      FooterBody={
        <SidebarFooter
          disabled={!value}
          onSubmit={handleSubmit}
          onBack={onClose}
          confirmationLabel={t('button.apply')}
          cancel
        />
      }
      label={t('subscriptions.allCards')}
      descriptor={t('subscriptions.selectCardForPayment')}
    >
      {paymentCards.map(card => (
        <CardItem key={card.id} card={card} checked={value?.id === card.id} onChange={handleChange} />
      ))}
    </Sidebar>
  );
};
