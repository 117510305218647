import styled from 'styled-components';
import { Flex, colors } from '@beauty/beauty-market-ui';

export const Wrapper = styled(Flex)`
  position: absolute;
  top: 16px;
  left: 16px;
  width: 56px;
  height: 32px;
  padding: 2px;
  border-radius: 100px;
  box-sizing: border-box;
  background-color: ${colors.grey.light};

  button {
    padding: 0;
  }

  svg {
    width: 24px !important;
    height: 24px !important;
    [dir='rtl'] & {
      transform: scaleX(-1);
    }
  }
`;

export const DotsWrapper = styled(Flex)`
  position: fixed;
  bottom: 11px;
  left: 50%;
  gap: 4px;
  padding: 6px;
  box-sizing: border-box;
`;

export const DotsTab = styled(Flex)`
  position: fixed;
  bottom: 11px;
  left: 50%;
  height: 17px;
  border-radius: 20px;
  background-color: ${colors.black.standard};
  opacity: 0.4;
  backdrop-filter: blur(20px);
`;

export const Dot = styled(Flex)<{ isActive?: boolean }>`
  width: 5px;
  height: 5px;
  border-radius: 50%;
  ${({ isActive }) =>
    isActive
      ? `background-color: ${colors.blue.standard};`
      : `background-color: ${colors.white.standard};
        opacity: 0.8;`}
  :hover {
    cursor: pointer;
  }
`;
