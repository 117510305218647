import { ChangeEvent, Fragment, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { BodySmall, Caption, Checkbox, colors, Flex, Separator, Sidebar, Textfield } from '@beauty/beauty-market-ui';
import { SidebarFooter } from 'components';
import { useRequest } from 'hooks';
import { OrganizationAction } from 'types';
import { RouterUrl } from '../../../../routes/routes';
import { ThunkOrganisation } from '../../../../store/redux-slices/organisationSlice';
import { getReasons } from '../helpers';

export const UnsubscribeSidebar = ({ onClose }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [text, setText] = useState('');
  const [index, setIndex] = useState(-1);
  const [isValid, setValid] = useState(true);

  const cancelSubscription = useRequest(ThunkOrganisation.cancelSubscription, OrganizationAction.CancelSubscription);

  const reasons = getReasons(t);

  const handleSubmit = () => {
    if (reasons[index].required && text.length <= 1) {
      setValid(false);
      return;
    }
    cancelSubscription({
      note: reasons[index].required ? text : reasons[index].id,
    }).then(() => navigate(RouterUrl.UnsubscribePage));
  };

  const handleChangeText = (e: ChangeEvent<HTMLTextAreaElement>) => {
    const { value } = e.currentTarget;
    if (value.length > 1000) {
      setValid(false);
    } else if (reasons[index].required) {
      setValid(!!value);
    } else {
      !isValid && setValid(true);
    }
    setText(value);
  };

  return (
    <Sidebar
      isOpen
      onClose={onClose}
      label={t('subscriptions.chooseTheReason')}
      descriptor={t('subscriptions.whyYouDecidedToCancelSubscription')}
      FooterBody={
        <SidebarFooter
          onSubmit={handleSubmit}
          onBack={onClose}
          confirmationLabel={t('subscriptions.unsubscribe')}
          disabled={!isValid || index === -1}
          cancel
        />
      }
    >
      {reasons.map((item, reasonIndex, arr) => (
        <Fragment key={item.value}>
          <Flex minHeight="52px" p="16px 0" justifyContent="space-between" alignItems="center">
            <BodySmall>{item.value}</BodySmall>
            <Checkbox
              shape="round"
              checked={reasonIndex === index}
              onChange={e => {
                e.stopPropagation();
                if (reasonIndex !== index) {
                  setIndex(reasonIndex);
                  if (item.required) {
                    setValid(!!text);
                  } else {
                    setValid(text.length < 1000);
                  }
                }
              }}
            />
          </Flex>
          {reasonIndex !== arr.length - 1 && <Separator />}
        </Fragment>
      ))}
      <Flex flexDirection="column">
        <Textfield
          value={text}
          onChange={handleChangeText}
          placeholder={t('subscriptions.writeTheReason')}
          rows={6}
          nonValid={!isValid}
          disabled={index === -1}
        />
        <Caption lowline color={text.length > 1000 ? colors.red.standard : colors.grey.dark} ml="auto">
          {`${text.length}/1000`}
        </Caption>
      </Flex>
    </Sidebar>
  );
};
