import { useMemo } from 'react';
import { Views } from 'react-big-calendar';
import { setLoading } from 'components/Employees/EditSchedule/scheduleReducer/actions';
import { EventResponse, SocketEventStatus, EventType, FullData, SocketEvents } from '../api/socket-api.types';
import { EventStatus } from '../constants';
import { useAppDispatch } from '../store/hooks';
import {
  addAppointment,
  addBreak,
  changeAppointment,
  changeBreak,
  deleteAppointment,
  deleteBreak,
  deleteUnclosedAppointment,
  setAppointments,
  setInProgressAppointments,
  setSpecialistAppointments,
  setUnclosedAppointments,
  updateInProgressAppointments,
  updateUnclosedAppointments,
} from '../store/redux-slices/appointmentsSlice';
import { AppointmentData, AppointmentDataType, AppointmentType, BreakType } from '../types/appointment';
import { useSocketConnection } from './useSocketConnection';

type DataForSubscribeType = {
  start: string;
  end: string;
  timeZone: string;
  orgId?: string;
  orgSpecId?: string;
};

export const useSubscribeToAppointment = (
  isUserAdmin: boolean,
  orgId: string | null,
  orgSpecId: string | undefined,
  start: string,
  end: string,
  view: Views,
) => {
  const dispatch = useAppDispatch();

  // orgId subscribe to all events
  // orgSpecId subscribe to spec events
  const data: DataForSubscribeType | null = useMemo(() => {
    const commonData = {
      start,
      end,
      timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
    };

    if (isUserAdmin && orgId) {
      return { ...commonData, orgId };
    }
    if (!isUserAdmin && orgSpecId) {
      return { ...commonData, orgSpecId };
    }
    return null;
  }, [isUserAdmin, start, end, orgId, orgSpecId]);

  const handleEvent = (
    payload: EventResponse<AppointmentDataType[] | AppointmentData | AppointmentType | BreakType>,
  ) => {
    const payloadData = payload.data as FullData<AppointmentDataType[] | AppointmentData | AppointmentType | BreakType>;
    switch (payload.status) {
      case SocketEventStatus.INIT:
        if (data?.orgId) {
          dispatch(setAppointments(payloadData.calendar as AppointmentDataType[]));
        }
        if (data?.orgSpecId) {
          dispatch(setSpecialistAppointments({ id: data.orgSpecId, ...payloadData.calendar } as AppointmentData));
        }
        dispatch(setUnclosedAppointments(payloadData.unclosed));
        dispatch(setInProgressAppointments(payloadData.inprogress));
        dispatch(setLoading(false));
        break;
      case SocketEventStatus.CREATE:
        payload.type === EventType.BREAK
          ? dispatch(addBreak(payload.data as BreakType))
          : dispatch(addAppointment(payload.data as AppointmentType));
        break;
      case SocketEventStatus.DELETE:
        if (payload.type === EventType.BREAK) {
          dispatch(deleteBreak(payload.data as BreakType));
        } else if (((payload.data as AppointmentType).status as unknown as EventStatus) !== EventStatus.NOSHOW) {
          dispatch(deleteAppointment(payload.data as AppointmentType));
          dispatch(updateInProgressAppointments(payload.data as AppointmentType));
        } else dispatch(changeAppointment(payload.data as AppointmentType));
        break;
      case SocketEventStatus.UPDATE:
        if (payload.type === EventType.BM) {
          dispatch(deleteUnclosedAppointment((payload.data as AppointmentType).id));
          if (((payload.data as AppointmentType).status as unknown as EventStatus) === EventStatus.INPROGRESS) {
            dispatch(updateInProgressAppointments(payload.data as AppointmentType));
            dispatch(changeAppointment(payload.data as AppointmentType));
          } else if (((payload.data as AppointmentType).status as unknown as EventStatus) === EventStatus.UNCLOSED) {
            dispatch(updateUnclosedAppointments(payload.data as AppointmentType));
          }
        }
        payload.type === EventType.BREAK
          ? dispatch(changeBreak(payload.data as BreakType))
          : dispatch(changeAppointment(payload.data as AppointmentType));
        break;
      default:
        break;
    }
  };

  useSocketConnection({ data, event: SocketEvents.Appointment, handleEvent, view });
};
