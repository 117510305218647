import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { BodySmall } from '@beauty/beauty-market-ui';
import { useRequest } from '../../../hooks';
import { ThunkService } from '../../../store/redux-slices/serviceSlice';
import { ServiceAction } from '../../../types';
import Popup from '../../PopUp';

export const DeleteService = ({ id, onClose }) => {
  const { t } = useTranslation();

  const deleteService = useRequest(ThunkService.deleteService, ServiceAction.Delete);

  const [isSubmitting, setSubmitting] = useState(false);

  const handleDeleteService = () => {
    setSubmitting(true);
    deleteService(id).finally(() => {
      setSubmitting(false);
      onClose();
    });
  };

  return (
    <Popup
      isOpen
      title={t('organizationPopup.title')}
      onSubmit={handleDeleteService}
      onClose={onClose}
      onBack={onClose}
      isLoading={isSubmitting}
      cancel={t('button.cancel')}
      confirm={t('button.delete')}
    >
      <BodySmall>{t('organizationPopup.info')}</BodySmall>
    </Popup>
  );
};
