import { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FormikProps } from 'formik';
import { Button } from '@beauty/beauty-market-ui';
import { SidebarSheet, Spinner } from 'components';
import { useRequest } from '../../../hooks';
import { ThunkOrganisation } from '../../../store/redux-slices/organisationSlice';
import { HeadOrganisationFormType, OrganizationAction } from '../../../types';
import OrganisationData from './HeadOrganisationData/HeadOrganisationData';
import { OrganisationDataFormTypes } from './HeadOrganisationData/HeadOrganisationData.definitions';
import { StyledArrowRightIcon } from './style';

interface OrganisationSettingsSidebarProps {
  onClose: () => void;
  editMode?: boolean;
}

const HeadOrganisationSettingsSidebar = ({ onClose, editMode = false }: OrganisationSettingsSidebarProps) => {
  const { t } = useTranslation();

  const [isValid, setIsValid] = useState(true);
  const [isDirty, setIsDirty] = useState(false);
  const [isSubmitting, setSubmitting] = useState(false);

  const editHeadOrganisation = useRequest(ThunkOrganisation.editHead, OrganizationAction.EditOrganisation);
  const createHeadOrganisation = useRequest(ThunkOrganisation.createHead, OrganizationAction.CreateOrganisation);

  const formRef = useRef<FormikProps<OrganisationDataFormTypes>>();
  const handleSubmit = async (params: HeadOrganisationFormType) => {
    setSubmitting(true);
    const request = editMode ? editHeadOrganisation(params) : createHeadOrganisation(params);
    return request.then(() => onClose()).finally(() => setSubmitting(false));
  };

  const buttonText = editMode
    ? t('settings.sidebarOrganisationSettings.saveChanges')
    : t('settings.sidebarOrganisationSettings.proceed');
  const footer = (
    <Button
      disabled={!isDirty || !isValid || isSubmitting}
      design="primary"
      type="submit"
      width="100%"
      size="large"
      suffix={!editMode && <StyledArrowRightIcon width="20" height="20" />}
      onClick={formRef.current?.submitForm}
    >
      {isSubmitting ? <Spinner /> : buttonText}
    </Button>
  );

  return (
    <SidebarSheet
      FooterBody={footer}
      disableDrag
      onClose={editMode ? onClose : undefined}
      label={
        editMode
          ? t('settings.sidebarOrganisationSettings.editCompany')
          : t('settings.sidebarOrganisationSettings.organisationSettings')
      }
      descriptor={
        editMode
          ? t('settings.sidebarOrganisationSettings.updateCommon')
          : t('settings.sidebarOrganisationSettings.titleDescriptor')
      }
    >
      <OrganisationData
        ref={formRef}
        setIsValid={setIsValid}
        setIsDirty={setIsDirty}
        editMode={editMode}
        onSubmit={handleSubmit}
      />
    </SidebarSheet>
  );
};

export default HeadOrganisationSettingsSidebar;
