import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import {
  BodyLarge,
  BodySmall,
  BottomSheet,
  Button,
  Flex,
  getMinWidthMediaQuery,
  H5,
  useMediaQuery,
} from '@beauty/beauty-market-ui';
import { Paper, PopUp } from 'components';
import { checkAccess } from 'helpers';
import { useRequest } from 'hooks';
import { ProfileActions } from 'types';
import { AccessRange } from '../../../constants';
import { RouterUrl } from '../../../routes/routes';
import { logout } from '../../../store/actions/common';
import { useAppDispatch } from '../../../store/hooks';
import { ThunkUser } from '../../../store/redux-slices/userSlice';

export const DeleteAccount = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const [isOpen, setOpen] = useState(false);

  const isOwner = checkAccess(AccessRange.OWNER);
  const deleteAccount = useRequest(ThunkUser.deleteAccount, ProfileActions.DeleteAccount);

  const mediaQuery = getMinWidthMediaQuery('md');
  const isDesktop = useMediaQuery(mediaQuery);

  const handleOpen = () => setOpen(true);

  const handleClose = () => setOpen(false);

  const handleDelete = () => {
    if (isOwner) return;
    deleteAccount().then(() => {
      dispatch(logout());
      navigate(RouterUrl.LoginIntro, { state: { type: 'login' } });
    });
  };

  return (
    <>
      <Paper
        flexDirection="column"
        borderRadius={isDesktop ? '0 0 10px 10px' : '10px'}
        p={isDesktop ? '24px' : '16px 8px'}
      >
        <H5>{t('profile.deleteAccount')}</H5>
        <BodySmall mt="4px">{t('profile.areYouSureDeleteAccount')}</BodySmall>
        <Button size="small" onClick={handleOpen} design="quaternary" mt="24px" disabled={isOwner}>
          {t('profile.deleteAccount')}
        </Button>
      </Paper>
      {isOpen &&
        (isDesktop ? (
          <PopUp
            title={t('profile.deletingAccount')}
            description={t('profile.doYouReallyWantToDelete')}
            onSubmit={handleDelete}
            onClose={handleClose}
          />
        ) : (
          <BottomSheet
            detent="content-height"
            isOpen={isOpen}
            handleClose={handleClose}
            onClose={handleClose}
            label={t('profile.deletingAccount')}
            content={<BodyLarge mb="80px">{t('profile.doYouReallyWantToDelete')}</BodyLarge>}
            FooterBody={
              <Flex flexDirection="column" width="100%" gap="8px">
                <Button width="100%" onClick={handleDelete}>
                  {t('button.delete')}
                </Button>
                <Button design="secondary" width="100%" onClick={handleClose}>
                  {t('button.cancel')}
                </Button>
              </Flex>
            }
          />
        ))}
    </>
  );
};
