import { useTranslation } from 'react-i18next';
import { useFormikContext } from 'formik';
import { H6 } from '@beauty/beauty-market-ui';
import { CardStub } from 'components/features/Payment/CardStub';
import { PaymentType } from 'types';
import { AppointmentForm, AppointmentFormFields } from './EditAppointment.definitions';

export const PaymentSection = () => {
  const { t } = useTranslation();
  const { Payment } = AppointmentFormFields;
  const { values, setFieldValue } = useFormikContext<AppointmentForm>();

  return (
    <>
      <H6 marginTop="8px">{t('calendar.newAppointmentSidebar.details.paymentMethod')}</H6>
      <CardStub
        mb="0"
        checked={values[Payment] === PaymentType.CASH}
        onChange={() => setFieldValue(Payment, PaymentType.CASH)}
        title={t('calendar.newAppointmentSidebar.details.byCash')}
        info={t('calendar.newAppointmentSidebar.details.cashInfo')}
        notIcons
      />
      <CardStub
        mb="0"
        checked={values[Payment] !== PaymentType.CASH}
        onChange={() => setFieldValue(Payment, PaymentType.CARD)}
        title={t('calendar.newAppointmentSidebar.details.byCard')}
        info={t('calendar.newAppointmentSidebar.details.cardInfo')}
      />
    </>
  );
};
