import { MouseEvent } from 'react';
import { useTranslation } from 'react-i18next';
import { isEmpty } from 'lodash';
import { BodyLarge, BottomSheet, Button, Flex, Separator } from '@beauty/beauty-market-ui';
import { PopUp } from 'components';
import { useMediaScreen } from 'hooks';
import { SpecialistAction } from 'types';
import { getSpokenLanguages } from '../../../../../constants';
import { EmployeeSection } from '../EmployeeSection/EmployeeSection';
import { EditLanguages } from './EditLanguages';
import { LanguageItem } from './LanguageItem';
import { useLanguages } from './useLanguages';

export const Languages = () => {
  const { t } = useTranslation();
  const { isDesktop } = useMediaScreen('md');
  const { current, selected, action, onEdit, onAdd, onDelete, onSubmit, onClose } = useLanguages();
  const languageOptions = getSpokenLanguages(t);

  const languages = current.map((item, index, arr) => (
    <LanguageItem
      key={item.id}
      subtitle={item.level}
      title={languageOptions[item.language].value}
      separator={index !== arr.length - 1}
      onEdit={(e: MouseEvent<HTMLOrSVGElement>) => {
        e.stopPropagation();
        onEdit(item);
      }}
      onDelete={(e: MouseEvent<HTMLOrSVGElement>) => {
        e.stopPropagation();
        onDelete(item);
      }}
    />
  ));

  const content = (
    <BodyLarge mb="80px">
      {t('specialists.personalInfo.deleteLanguage', {
        value: selected?.language ? languageOptions[selected.language].value : '',
      })}
    </BodyLarge>
  );

  return (
    <>
      <EmployeeSection
        position="middle"
        visibleCount={7}
        count={languages.length}
        title={t('specialists.personalInfo.languages')}
        description={t('specialists.personalInfo.whatSpecialist')}
        placeholder={t('specialists.personalInfo.noInfoAboutLanguages')}
        buttonLabel={t('specialists.personalInfo.addLanguage')}
        onClick={onAdd}
        separator={isEmpty(languages) && isDesktop ? <Separator mb="16px" /> : null}
      >
        {languages}
      </EmployeeSection>
      {action === SpecialistAction.AddLanguage && <EditLanguages onSubmit={onSubmit} onClose={onClose} />}
      {action === SpecialistAction.EditLanguage && (
        <EditLanguages onSubmit={onSubmit} onClose={onClose} defaultValue={selected} />
      )}
      {action === SpecialistAction.DeleteLanguage &&
        selected &&
        (isDesktop ? (
          <PopUp
            title={t('specialists.personalInfo.deletingLanguage')}
            description=""
            onSubmit={onSubmit}
            onClose={onClose}
            confirm={t('button.delete')}
            cancel={t('button.cancel')}
            isLoading={false}
          >
            {content}
          </PopUp>
        ) : (
          <BottomSheet
            isOpen={action === SpecialistAction.DeleteLanguage && selected}
            onClose={onClose}
            handleClose={onClose}
            label={t('specialists.personalInfo.deletingLanguage')}
            content={content}
            detent="content-height"
            FooterBody={
              <Flex flexDirection="column" width="100%" gap="8px">
                <Button onClick={onSubmit}>{t('button.delete')}</Button>
                <Button onClick={onClose} design="secondary">
                  {t('button.cancel')}
                </Button>
              </Flex>
            }
          />
        ))}
    </>
  );
};
