import { Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import isArray from 'lodash/isArray';
import isEmpty from 'lodash/isEmpty';
import {
  Badge,
  BodyLarge,
  Caption,
  Flex,
  Separator,
  colors,
  getMinWidthMediaQuery,
  useMediaQuery,
} from '@beauty/beauty-market-ui';
import { InfoBlock } from 'components';
import { getTranslation } from 'helpers';
import { useAppSelector } from 'store/hooks';
import { selectNewAddress } from 'store/redux-slices/addressSlice';
import { getSelectedLanguage } from '../../../../../constants';

export const AddressServices = () => {
  const { t } = useTranslation();
  const { orgService } = useAppSelector(selectNewAddress);
  const language = getSelectedLanguage();

  const mediaQuery = getMinWidthMediaQuery('md');
  const isDesktop = useMediaQuery(mediaQuery);

  const services = isArray(orgService)
    ? orgService.map((service, index, arr) => (
        <Fragment key={service.id}>
          <Flex flexDirection="column">
            <Caption color={colors.grey.standard}>{service.title.text}</Caption>
            <BodyLarge large>{service.category ? getTranslation(service.category.title, language) : ''}</BodyLarge>
          </Flex>
          {index !== arr.length - 1 && <Separator my="16px" />}
        </Fragment>
      ))
    : [];

  return (
    <InfoBlock
      title={t('address.services')}
      description={t('address.whatKindOfServices')}
      placeholder={t('address.haveNotServices')}
      badge={services.length ? <Badge text={services.length} design="lightGrey" /> : null}
      count={services.length}
      visibleCount={1}
      separator={isEmpty(services) && isDesktop ? <Separator mb="16px" /> : null}
    >
      {services}
    </InfoBlock>
  );
};
