import { Flex, Slider } from '@beauty/beauty-market-ui';
import { isRtl } from 'helpers/utils';
import { TabName } from './style';

export const StrokedStack = ({ items, size, active, onChange, isDesktop }) => {
  if (size <= 0) {
    return null;
  }

  const slides = items.flatMap((item, index) =>
    size >= index ? (
      <TabName key={item.id} active={active === index} onClick={() => onChange(index)} width="max-content">
        {item.short}
      </TabName>
    ) : (
      []
    ),
  );
  const rtl = isRtl();

  return isDesktop ? (
    <Flex alignItems="center" gap="16px" justifyContent="space-between">
      {slides}
    </Flex>
  ) : (
    <Slider inlineSlider slides={slides} rtl={rtl} />
  );
};
