import styled from 'styled-components';
import { Flex } from '@beauty/beauty-market-ui';

export const BackWrapper = styled(Flex)`
  align-items: center;
  gap: 4px;
  cursor: pointer;

  [dir='ltr'] & {
    direction: ltr;
    flex-direction: row;
  }

  [dir='rtl'] & {
    flex-direction: row-reverse;
    svg {
      transform: scaleX(-1);
    }
  }
`;
