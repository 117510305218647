import { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Select,
  Separator,
  Flex,
  Button,
  Popup,
  getMinWidthMediaQuery,
  useMediaQuery,
  BottomSheet,
} from '@beauty/beauty-market-ui';
import { OptionType } from 'types';
import { useAppSelector } from '../../../store/hooks';
import { selectAllAddresses } from '../../../store/redux-slices/addressSlice';
import { PopupWrapper } from '../style';

export const ClientPopup = ({ value, onSubmit, onChange, onClose, isLoading, info }) => {
  const { t } = useTranslation();
  const allAddress = useAppSelector(selectAllAddresses);

  const mediaQuery = getMinWidthMediaQuery('md');
  const isDesktop = useMediaQuery(mediaQuery);

  const selectOptions: OptionType[] | null = useMemo(
    () =>
      allAddress?.map(address => ({
        id: address.id,
        value: address.name,
        caption: address.address.fullAddress ?? undefined,
      })) || null,
    [allAddress],
  );

  const content = (
    <Select
      options={selectOptions}
      placeholder={t('clients.address')}
      selected={value}
      onSelect={onChange}
      disabled={selectOptions?.length === 1}
    />
  );

  const FooterBody = (
    <Flex width="100%" gap="16px" px="8px">
      <Button design="secondary" size="large" width="100%" onClick={onClose} disabled={isLoading}>
        {t('button.back')}
      </Button>
      <Button size="large" width="100%" onClick={onSubmit} disabled={isLoading || !value}>
        {t('button.apply')}
      </Button>
    </Flex>
  );

  useEffect(() => {
    if (selectOptions?.length === 1) {
      onChange(selectOptions[0]);
    }
  }, [selectOptions]);

  return (
    <PopupWrapper>
      {isDesktop ? (
        <Popup open title={t('clients.chooseAddress')} info={info} handleClose={onClose} FooterBody={FooterBody}>
          <>
            {content}
            <Separator mt="40px" />
          </>
        </Popup>
      ) : (
        <BottomSheet
          isOpen
          label={t('clients.chooseAddress')}
          descriptor={info}
          onClose={onClose}
          handleClose={onClose}
          FooterBody={FooterBody}
          content={content}
        />
      )}
    </PopupWrapper>
  );
};
