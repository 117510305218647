import { TFunction } from 'react-i18next';
import { capitalize } from 'lodash';
import { OrganisationSubscriptionType, SubscribeActions, SubscriptionPlanType, SubscriptionPricePlanType } from 'types';

export enum SubscriptionFilterBy {
  Plan = 'SUBSCRIPTION_PLAN',
}

export const getOptions = (
  subscription: OrganisationSubscriptionType | null,
  t: TFunction<'translation', undefined>,
) => [
  {
    disabled: subscription?.plan.planType === SubscriptionPlanType.FREE,
    item: t('subscriptions.options.updatePaymentMethod'),
    action: SubscribeActions.UpdateCard,
  },
  {
    disabled: false,
    item: t('subscriptions.options.changePlan'),
    action: SubscribeActions.NavigateToChangePlanPage,
  },
  {
    disabled: subscription?.cancelled || subscription?.plan.planType === SubscriptionPlanType.FREE,
    item: t('subscriptions.options.cancelSubscription'),
    action: SubscribeActions.CancelSubscription,
  },
];

export const getReasons = (t: TFunction<'translation', undefined>) => [
  {
    id: 'Very expensive',
    required: false,
    value: t('subscriptions.reasons.veryExpensive'),
  },
  {
    id: 'Choose another software',
    required: false,
    value: t('subscriptions.reasons.chooseAnotherSoftware'),
  },
  {
    id: 'Close the business',
    required: false,
    value: t('subscriptions.reasons.closeTheBusiness'),
  },
  {
    id: 'Other',
    required: true,
    value: t('subscriptions.reasons.other'),
  },
];

export const getTextForInvoice = (planType: SubscriptionPlanType | SubscriptionPricePlanType | null, t) => {
  switch (planType) {
    case SubscriptionPlanType.BASIC:
    case SubscriptionPlanType.PRO:
    case SubscriptionPlanType.BUSINESS:
    case SubscriptionPlanType.ENTERPRISE:
      return `${capitalize(planType)} ${t('subscriptions.planSubscription')}`;
    case SubscriptionPricePlanType.EMPLOYEE:
      return t('subscriptions.paymentForAdditionalSeat');
    case SubscriptionPricePlanType.TELEGRAM:
      return t('subscriptions.paymentForTelegram');
    case SubscriptionPricePlanType.WHATSAPP:
      return t('subscriptions.paymentForWhatsApp');
    default:
      return null;
  }
};
