import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import isArray from 'lodash/isArray';
import isEmpty from 'lodash/isEmpty';
import { Separator, Slider, TagButton } from '@beauty/beauty-market-ui';
import { EditSchedule } from 'components';
import { isRtl } from 'helpers/utils';
import { useMediaScreen, useTimeList } from 'hooks';
import { useAppSelector } from 'store/hooks';
import { selectSpecialist } from 'store/redux-slices/specialistSlice';
import { SpecialistAction } from 'types';
import { extractSchedule } from '../../helpers';
import { ScheduleType } from '../../types';
import { EmployeeSection } from '../EmployeeSection/EmployeeSection';
import { OrganisationDay } from '../OrganisationDay';

export const EmployeeSchedule = () => {
  const { t } = useTranslation();
  const { isDesktop } = useMediaScreen('md');
  const { organization } = useAppSelector(selectSpecialist);
  const timeList = useTimeList();
  const rtl = isRtl();

  const [selectedWeek, setSelectedWeek] = useState<number>(0);
  const [action, setAction] = useState<SpecialistAction>(SpecialistAction.None);

  const orgData = isArray(organization) ? organization : [];
  const schedule: ScheduleType = useMemo(() => extractSchedule(orgData, timeList, rtl), [organization, rtl]);

  const handleClose = () => setAction(SpecialistAction.None);
  const handleEditScheduleClick = () => setAction(SpecialistAction.EditSchedule);

  const scheduleItems = useMemo(() => {
    const currentSchedule = schedule[selectedWeek];
    return schedule.length && selectedWeek < schedule.length && isArray(currentSchedule)
      ? currentSchedule.map((day, index, arr) => (
          <OrganisationDay key={day.dayOfWeek} {...day} separator={index !== arr.length - 1} />
        ))
      : [];
  }, [organization, selectedWeek, rtl]);

  const weekLabels = [`1 ${t('week.label')}`, `2 ${t('week.label')}`, `3 ${t('week.label')}`, `4 ${t('week.label')}`];
  const slides = weekLabels
    .slice(0, schedule.length)
    .map((week, index) => (
      <TagButton
        key={week}
        text={week}
        design={index === selectedWeek ? 'active' : 'default'}
        onClick={() => setSelectedWeek(index)}
      />
    ));

  useEffect(() => {
    if (schedule.length && selectedWeek > schedule.length - 1) {
      setSelectedWeek(schedule.length - 1);
    }
  }, [schedule.length]);

  return (
    <>
      <EmployeeSection
        count={schedule.length}
        title={t('specialists.workingTime')}
        description={t('specialists.workingSchedule')}
        buttonLabel={t('specialists.fillSchedule')}
        placeholder={t('specialists.haveNotFilledWorkingTime')}
        onClick={handleEditScheduleClick}
        badge={null}
        separator={
          (isEmpty(schedule) && isDesktop) || schedule.length === 1 ? (
            <Separator mb="16px" />
          ) : (
            <Slider inlineSlider slides={slides} mb="16px" rtl={rtl} />
          )
        }
        position="last"
        visibleCount={8}
      >
        {scheduleItems}
      </EmployeeSection>
      {action === SpecialistAction.EditSchedule && <EditSchedule selectedWeek={selectedWeek} onClose={handleClose} />}
    </>
  );
};
