import { About, Education, Languages, PhotoGallery } from '../components';

export const PersonalInfo = () => (
  <>
    <Education />
    <Languages />
    <About />
    <PhotoGallery />
  </>
);
