import { useEffect, useState } from 'react';
import { Views } from 'react-big-calendar';
import { useTranslation } from 'react-i18next';
import { intersection, isEmpty } from 'lodash';
import { Nullable } from 'tsdef';
import { Button, Flex, BodySmall, BottomSheet, Caption, colors, Avatar } from '@beauty/beauty-market-ui';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { selectAppointments, setLoading, setSelectedSpecialist, setView } from 'store/redux-slices/appointmentsSlice';
import { emptySpecialist, updateSpecialist } from 'store/redux-slices/specialistSlice';
import { ColumnFlex } from 'style';
import { Anonymous } from '../../../../assets';
import { FilterMobileItem } from './FilterMobileitem';
import { CategoryOptionWrapper, TextWrapper } from './style';

type MobileFilterBySpecialistProps = {
  isReset: boolean;
  onClose: () => void;
};

export const MobileFilterBySpecialist = ({ isReset, onClose }: MobileFilterBySpecialistProps) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { selectedSpecialist, specialistIds, specialistData, selectedCategory, categoryData, view } =
    useAppSelector(selectAppointments);

  const filterBySelectedService = (specialistId: string | null) => {
    if (selectedCategory && specialistId && specialistData[specialistId]?.serviceIds) {
      const specServices = specialistData[specialistId].serviceIds;
      const categoryServices = categoryData[selectedCategory].serviceIds;
      return !isEmpty(intersection(specServices, categoryServices));
    }
    return true;
  };

  const specialistOptions = [null, ...specialistIds].filter(filterBySelectedService).map(specId => ({
    id: specId,
    title: specId
      ? `${specialistData[specId].name} ${specialistData[specId].surname}`
      : t('specialists.allSpecialists'),
    description: specId ? specialistData[specId].specialization : '',
    icon: specId && specialistData[specId]?.avatarUrl ? specialistData[specId].avatarUrl : <Anonymous />,
  }));

  const [specialist, setSpecialist] = useState<Nullable<number>>(
    specialistOptions.findIndex(spec => spec.id === selectedSpecialist),
  );

  const handleApply = () => {
    // !specialist && dispatch(updateSpecialist(emptySpecialist));
    dispatch(setSelectedSpecialist(specialist ? specialistOptions[specialist].id : null));
    specialist && view === Views.WEEK && dispatch(setView(Views.DAY));
    !specialist && view === Views.DAY && dispatch(setView(Views.WEEK));
    onClose();
  };

  const handleClose = () => {
    setSpecialist(specialistOptions.findIndex(spec => spec.id === selectedSpecialist));
    onClose();
  };

  const content = (
    <Flex flexDirection="column" gap="8px">
      {specialistOptions?.map((item, index) => (
        <FilterMobileItem
          key={item.id}
          id={index}
          body={
            <CategoryOptionWrapper>
              <Avatar size="xs" url={item.icon} bgColor={colors.grey.light} />
              <TextWrapper>
                <BodySmall large>{item.title}</BodySmall>
                <Caption lowline color={colors.grey.standard}>
                  {item.description}
                </Caption>
              </TextWrapper>
            </CategoryOptionWrapper>
          }
          onClick={() => setSpecialist(index)}
          isChecked={index === specialist}
        />
      ))}
    </Flex>
  );

  const FooterBody = (
    <ColumnFlex width="100%" gap="8px">
      <Button size="large" width="100%" onClick={handleApply}>
        {t('button.choose')}
      </Button>
      <Button size="large" width="100%" design="secondary" onClick={handleClose}>
        {t('button.cancel')}
      </Button>
    </ColumnFlex>
  );

  const someProps = {
    isOpen: true,
    label: t('calendar.selectSpecialist'),
    handleClose,
    onClose: handleClose,
    FooterBody,
  };

  useEffect(() => {
    if (isReset) {
      handleClose();
    }
  }, [isReset]);

  return <BottomSheet {...someProps} content={content} detent="content-height" />;
};
