import styled from 'styled-components';
import { H5 } from '@beauty/beauty-market-ui';

export const WidgetText = styled(H5)`
  width: 100%;
  align-content: center;
  text-align: left;
  [dir='rtl'] & {
    text-align: right;
  }

  padding-left: 8px;
  [dir='rtl'] & {
    padding-left: 0;
    padding-right: 8px;
  }
  box-sizing: border-box;
`;
