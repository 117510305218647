import styled from 'styled-components';
import { Flex, mediaQueries } from '@beauty/beauty-market-ui';
import { Paper } from '../../../../../components';

export const AddressCardWrapper = styled(Paper)`
  align-items: center;
  justify-content: space-between;
  padding: 16px 8px;
  gap: 8px;
  border-radius: 10px;

  ${mediaQueries.md} {
    padding: 24px;
    gap: 16px;
    border-radius: 0;

    &:last-child {
      border-radius: 0 0 10px 10px;
    }
  }

  &:hover {
    cursor: pointer;
  }
`;

export const AddressCardContent = styled(Flex)`
  flex-direction: column;
  gap: 2px;
  max-width: 100%;

  ${({ theme }) => (theme.rtl ? 'margin-left: auto' : 'margin-right: auto')};
`;

export const StyledCaption = styled(Flex)`
  align-items: center;
  justify-content: space-between;
  max-width: 80px;
  width: 100%;

  ${mediaQueries.md} {
    max-width: ${({ theme }) => (theme.rtl ? '150px' : '220px')};
  }
`;
