import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { lowerCase } from 'lodash';
import { BodySmall, UpdateIcon } from '@beauty/beauty-market-ui';
import { checkAccess, preparePlanData } from 'helpers';
import { AccessRange } from '../../constants';
import { useAppSelector } from '../../store/hooks';
import { selectActiveSubscription } from '../../store/redux-slices/organisationSlice';
import { PaymentForSubscription } from '../features';
import { StyledBanner, StyledButton } from './style';

export const Banner = () => {
  const { t } = useTranslation();
  const subscription = useAppSelector(selectActiveSubscription);
  const isSuperAdmin = checkAccess(AccessRange.SUPERADMIN);
  const planData = preparePlanData(subscription);

  const [isOpen, setOpen] = useState(false);

  const handleClose = useCallback(() => setOpen(false), []);

  const handleOpen = useCallback(() => setOpen(true), []);

  return isSuperAdmin && !subscription?.renewed && planData ? (
    <>
      <StyledBanner>
        <BodySmall>{t('header.paymentFailed')}</BodySmall>
        <StyledButton onClick={handleOpen}>
          <UpdateIcon />
          <BodySmall lowline>{t('header.tryAgain')}</BodySmall>
        </StyledButton>
      </StyledBanner>
      {isOpen && (
        <PaymentForSubscription period={lowerCase(subscription?.period)} onClose={handleClose} planData={planData} />
      )}
    </>
  ) : null;
};
