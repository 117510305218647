import { organisationAPI } from '../../helpers/organisationAPI';
import { PhotoStoreType, PublishPayloadType } from '../../types';
import { createAppAsyncThunk } from '../create-app-async-thunk';
import { packPhotoStoreToFormData } from '../utils';

export const editDescription = createAppAsyncThunk(
  'address/editDescription',
  (data: { description: string | null; property: string }, thunkAPI) => {
    const { id } = thunkAPI.getState().address.newAddress;
    if (id) {
      return organisationAPI.editOrganization({ id, data, thunkAPI });
    }
    throw new Error("id doesn't exist");
  },
);

export const editPhotos = createAppAsyncThunk(
  'address/editPhotos',
  async (params: Partial<PhotoStoreType>, thunkAPI) => {
    const { id } = thunkAPI.getState().address.newAddress;
    if (id) {
      const data = packPhotoStoreToFormData(params);
      return organisationAPI.updatePhotos({ id, data, thunkAPI });
    }
    return thunkAPI.rejectWithValue('id is not exist');
  },
);

export const editPhotosChunk = createAppAsyncThunk('address/editPhotosChunk', async (data: FormData, thunkAPI) => {
  const { id } = thunkAPI.getState().address.newAddress;
  if (id) {
    return organisationAPI.updatePhotos({ id, data, thunkAPI });
  }
  return thunkAPI.rejectWithValue('id is not exist');
});

export const editEmployees = createAppAsyncThunk<
  any,
  {
    headOrgSpecIds: string;
  }
>('address/editEmployees', async (data, thunkAPI) => {
  const currentOrgId = thunkAPI.getState().address.newAddress.id;
  if (currentOrgId) {
    return organisationAPI.editSpecialists({ id: currentOrgId, data, thunkAPI });
  }
  return thunkAPI.rejectWithValue('id is not exist');
});

export const updatePublish = createAppAsyncThunk<PublishPayloadType, { id: string; published: boolean }>(
  'address/publishAddress',
  ({ id, published }, thunkAPI) => organisationAPI.updatePublish({ id, data: { published }, thunkAPI }),
);
