import { useTranslation } from 'react-i18next';
import { intersection, isEmpty } from 'lodash';
import { FilterDropdown } from '@beauty/beauty-market-ui';
import { getTranslation } from 'helpers';
import { getSelectedLanguage, ServiceIcons } from '../../../constants';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import { selectAppointments, setSelectedCategory } from '../../../store/redux-slices/appointmentsSlice';

export const FilterByService = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { selectedSpecialist, selectedCategory, categoryIds, categoryData, specialistData } =
    useAppSelector(selectAppointments);

  const language = getSelectedLanguage();

  const handleServiceClick = (id: string | null) => {
    dispatch(setSelectedCategory(id));
  };

  const handleResetFilter = () => {
    dispatch(setSelectedCategory(null));
  };

  const filterBySelectedSpecialist = (categoryId: string | null) => {
    if (selectedSpecialist && categoryId && specialistData[selectedSpecialist]?.serviceIds) {
      const specServices = specialistData[selectedSpecialist].serviceIds;
      const categoryServices = categoryData[categoryId].serviceIds;

      return !isEmpty(intersection(specServices, categoryServices));
    }
    return true;
  };

  const serviceOptions = [null, ...categoryIds].filter(filterBySelectedSpecialist).map(categoryId => {
    const defaultIcon = categoryId === null ? ServiceIcons.All : null;
    return {
      id: categoryId,
      title: categoryId ? getTranslation(categoryData[categoryId].title, language) : t('calendar.allCategories'),
      description: '',
      icon: categoryId ? ServiceIcons[categoryData[categoryId].title.text] : defaultIcon,
    };
  });

  return (
    <FilterDropdown
      options={serviceOptions}
      selectedOptionId={selectedCategory}
      onSelect={handleServiceClick}
      onCross={selectedCategory ? handleResetFilter : undefined}
      placeholder="Search"
    />
  );
};
