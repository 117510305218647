import { useTranslation } from 'react-i18next';
import { capitalize } from 'lodash';
import { Flex, NotifyStatus, useNotify } from '@beauty/beauty-market-ui';
import { getFormattedCurrency } from 'helpers';
import { SubscriptionPeriodType, SubscriptionPlanType, SubscriptionPricePlanType } from 'types';
import { Currency, getSelectedLanguage } from '../../../../constants';
import { useAppSelector } from '../../../../store/hooks';
import { selectAllAddresses } from '../../../../store/redux-slices/addressSlice';
import { selectActiveSubscription, selectCurrency } from '../../../../store/redux-slices/organisationSlice';
import { getTariffPlans } from '../helpers';
import { TariffCard } from './TariffCard';

export const TariffPlans = ({ period, subscriptionPlans, onChangePlan }) => {
  const { t } = useTranslation();
  const allAddress = useAppSelector(selectAllAddresses);
  const headOrgCurrency = useAppSelector(selectCurrency);
  const subscription = useAppSelector(selectActiveSubscription);
  const notify = useNotify();

  const tariffs = getTariffPlans(subscriptionPlans, t);
  const language = getSelectedLanguage();

  return (
    <Flex gap="16px">
      {tariffs.map(item => {
        const isEnterprise = item.planType === SubscriptionPlanType.ENTERPRISE;

        const isAnnually = period === SubscriptionPeriodType.Annually;

        const currency = item.price[headOrgCurrency] ? headOrgCurrency : Currency.ILS;

        const mainAmount = item.price[currency] ? item.price[currency][SubscriptionPricePlanType.MAIN][period] : 0;
        const employeeAmount = item.price[currency]
          ? item.price[currency][SubscriptionPricePlanType.EMPLOYEE][period]
          : 0;

        const mainPrice = getFormattedCurrency(isAnnually ? mainAmount / 12 : mainAmount, currency, language);
        const employeePrice = getFormattedCurrency(
          isAnnually ? employeeAmount / 12 : employeeAmount,
          currency,
          language,
        );

        return (
          <TariffCard
            key={item.id}
            features={item.features}
            additional={item.additional}
            plan={capitalize(item.planType)}
            price={isEnterprise ? t('subscriptions.individual') : mainPrice}
            duration={isEnterprise ? '' : t('subscriptions.perMonth')}
            subtitle={t('subscriptions.perEmployee', { price: employeePrice })}
            buttonLabel={t('subscriptions.upgrade')}
            onClick={() => {
              if (item.limit !== null && allAddress.length > item.limit) {
                notify({
                  id: item.planType,
                  status: NotifyStatus.WARNING,
                  title: t('alerts.warning'),
                  subtitle: t('subscriptions.toManyAddresses'),
                });
              } else if (subscription?.plan.planType !== SubscriptionPlanType.FREE) {
                onChangePlan(SubscriptionPlanType.UPGRADE);
              } else {
                onChangePlan(item.planType);
              }
            }}
          />
        );
      })}
    </Flex>
  );
};
