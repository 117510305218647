import { organisationAPI } from '../../helpers/organisationAPI';
import {
  HeadOrganisationFormType,
  OrganisationHeadType,
  OrganisationSubscriptionType,
  SubscriptionPricePlanType,
} from '../../types';
import { HeadOrganizationType, PaymentCardType } from '../../types/user';
import { createAppAsyncThunk } from '../create-app-async-thunk';
import { packOrgHeadToFormData } from '../utils';

export const createHead = createAppAsyncThunk<HeadOrganizationType, HeadOrganisationFormType>(
  'organisation/createHead',
  async (data, thunkAPI) => {
    const id = thunkAPI.getState().user.user.userId;
    const formData = packOrgHeadToFormData({ ...data, account: id });
    return organisationAPI.createHead(formData);
  },
);

export const fetchHead = createAppAsyncThunk<OrganisationHeadType, string>('organisation/fetchHead', async id =>
  organisationAPI.fetchHead(id),
);

export const subscribe = createAppAsyncThunk<
  { payment_url?: string; status?: number; statusCode?: number; message?: string },
  {
    planId: string;
    period: string;
    currency: string;
    amount: number;
    whatsApp: boolean;
    telegram: boolean;
    cardId?: string;
  }
>('organisation/subscribe', async (data, thunkAPI) =>
  organisationAPI.subscribe({
    id: thunkAPI.getState().organisation.head.id,
    data,
    thunkAPI,
  }),
);

export const cancelSubscription = createAppAsyncThunk<
  Partial<OrganisationSubscriptionType>,
  {
    note: string;
  }
>('organisation/cancelSubscription', async (data, thunkAPI) =>
  organisationAPI.cancelSubscription({ id: thunkAPI.getState().organisation.head.subscription[0].id, data, thunkAPI }),
);

export const setPaymentCard = createAppAsyncThunk<PaymentCardType, string>(
  'organisation/setPaymentCard',
  async (cardId, thunkAPI) =>
    organisationAPI.setPaymentCard({ id: thunkAPI.getState().organisation.head.id, cardId, thunkAPI }),
);

export const editHead = createAppAsyncThunk<OrganisationHeadType, HeadOrganisationFormType>(
  'organisation/updateHead',
  (data, thunkAPI) => {
    const { id } = thunkAPI.getState().organisation.head;
    const formData = packOrgHeadToFormData(data);
    return organisationAPI.editHead({ id, data: formData, thunkAPI });
  });

export const payForTelegram = createAppAsyncThunk<
  { id: string; telegram: boolean; payment_url?: string; status?: number },
  { cardId?: string }
>('organisation/payForTelegram', (data, thunkAPI) => {
  const { id, currency, todaysPrice } = thunkAPI.getState().organisation.head.subscription[0];
  return organisationAPI.activateOption({
    id,
    data: {
      currency,
      headOrgId: thunkAPI.getState().organisation.head.id,
      amount: Number(todaysPrice.telegram),
      optionType: SubscriptionPricePlanType.TELEGRAM,
      cardId: data?.cardId,
    },
    thunkAPI,
  });
});

export const payForWhatsApp = createAppAsyncThunk<
  { id: string; whatsApp: boolean; payment_url?: string; status?: number },
  { cardId?: string }
>('organisation/payForWhatsApp', (data, thunkAPI) => {
  const { id, currency, todaysPrice } = thunkAPI.getState().organisation.head.subscription[0];
  return organisationAPI.activateOption({
    id,
    data: {
      currency,
      headOrgId: thunkAPI.getState().organisation.head.id,
      amount: Number(todaysPrice.whatsApp),
      optionType: SubscriptionPricePlanType.WHATSAPP,
      cardId: data?.cardId,
    },
    thunkAPI,
  });
});

export const deactivateTelegram = createAppAsyncThunk<{ id: string; message: string; telegram: boolean }, void>(
  'organisation/deactivateTelegram',
  (_, thunkAPI) =>
    organisationAPI.deactivateOption({
      id: thunkAPI.getState().organisation.head.id,
      data: { optionType: SubscriptionPricePlanType.TELEGRAM },
      thunkAPI,
    }),
);

export const deactivateWhatsApp = createAppAsyncThunk<{ id: string; message: string; whatsApp: boolean }, void>(
  'organisation/deactivateWhatsApp',
  (_, thunkAPI) =>
    organisationAPI.deactivateOption({
      id: thunkAPI.getState().organisation.head.id,
      data: { optionType: SubscriptionPricePlanType.WHATSAPP },
      thunkAPI,
    }));
