import { useEffect, useState } from 'react';
import { uniqBy } from 'lodash';
import { ClientReviews } from 'types';
import { apiRequest } from '../../api/api';
import { ClientEndpoints } from '../../api/endpoints/endpoints';

type Order = 'date_asc' | 'date_desc';

export const useGetClientReviews = (
  id = '',
  page = 1,
  size = 10,
  order: Order = 'date_asc',
): [ClientReviews[], boolean, unknown] => {
  const [isLoading, setLoading] = useState(true);
  const [errorMessage, setErrorMessage] = useState<unknown>(null);
  const [reviews, setReviews] = useState<ClientReviews[]>([]);

  useEffect(() => {
    const fetchReviews = async () => {
      setLoading(true);
      const [error, response] = await apiRequest.get({
        endpoint: ClientEndpoints.ClientReview,
        endpointParams: { id, page, size, order },
      });
      if (error?.data) setErrorMessage(error?.data);
      if (response?.data) setReviews(uniqBy(reviews.concat(response?.data), 'id'));
      setLoading(false);
    };

    id && fetchReviews();
  }, [id, page, size, order]);

  return [reviews, isLoading, errorMessage];
};
