import React from 'react';
import { useTranslation } from 'react-i18next';
import {
  AmexColorIcon,
  BodyLarge,
  BodySmall,
  colors,
  DiscoverColorIcon,
  Flex,
  MastercardColorIcon,
  VisaColorIcon,
} from '@beauty/beauty-market-ui';
import { StyledRadio, Paper } from '../style';

export const CardStub = ({
  checked,
  onChange,
  title = '',
  info = 'Visa, Mastercard, American Express',
  notIcons = false,
  mb = '16px',
}) => {
  const { t } = useTranslation();
  return (
    <Paper p="16px" gap="16px" alignItems="center" mb={mb} border borderRadius="10px">
      <Flex flexDirection="column" gap="4px" width="100%">
        <BodyLarge>{title || t('subscriptions.newCard')}</BodyLarge>
        <BodySmall color={colors.grey.dark}>{info}</BodySmall>
        {!notIcons && (
          <Flex gap="4px">
            <MastercardColorIcon />
            <AmexColorIcon />
            <VisaColorIcon />
            <DiscoverColorIcon />
          </Flex>
        )}
      </Flex>
      <StyledRadio checked={checked} onChange={onChange} ml="auto" />
    </Paper>
  );
};
