import { TFunction } from 'react-i18next';
import { NotifyStatus } from '@beauty/beauty-market-ui';
import { ClientActions } from '../../types/client';

const successTitles = {
  [ClientActions.Add]: 'clients.alerts.title.addClientSuccess',
  [ClientActions.Edit]: 'clients.alerts.title.editClientSuccess',
};

const errorTitles = {
  [ClientActions.Add]: 'clients.alerts.title.addClientError',
  [ClientActions.Edit]: 'clients.alerts.title.editClientError',
};

const successSubtitles = {
  [ClientActions.Add]: 'clients.alerts.subtitle.addClientSuccess',
  [ClientActions.Edit]: 'clients.alerts.subtitle.editClientSuccess',
};

const errorSubtitles = {
  [ClientActions.Add]: 'clients.alerts.title.addClientError',
  [ClientActions.Edit]: 'clients.alerts.subtitle.editClientError',
};

export const getClientNotifyContent = (
  status: NotifyStatus,
  action: ClientActions,
  t: TFunction<'translation', undefined>,
  message?: string,
) => {
  const title = status === NotifyStatus.SUCCESS ? successTitles[action] : errorTitles[action];
  const subtitle = status === NotifyStatus.SUCCESS ? successSubtitles[action] : errorSubtitles[action];

  return {
    id: action,
    status,
    title: t(title),
    subtitle: message || t(subtitle),
  };
};
