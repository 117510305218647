import React, { useMemo } from 'react';
import { useField } from 'formik';
import { t } from 'i18next';
import { Input, InputProps, Div, EyeOpenedIcon } from '@beauty/beauty-market-ui';
import { useStatus } from '../hooks/use-status';

type FormikInputProps = InputProps & {
  label?: React.ReactChild;
  name: Pick<InputProps, 'name'>;
  isWrong: boolean;
  hideErrorMessage?: boolean;
};

export const FormikPasswordInput = React.memo(
  React.forwardRef((props: FormikInputProps, ref) => {
    const { label, name, onBlur: explicitOnBlur, hideErrorMessage, isWrong, ...otherExplicitProps } = props;
    const [formikProps, meta] = useField(name as string);

    const { onBlur: formikDefaultOnBlur, value, ...otherFormikProps } = formikProps;

    const status = useStatus(meta);

    const caption = useMemo(() => {
      if (status === 'invalid') return meta.error;
      if (isWrong) return t('login.wrongPassword');
      return '';
    }, [status, meta.error, isWrong]);

    return (
      <Div>
        <Input
          width="100%"
          ref={ref}
          label={label}
          aria-label={label}
          status={status}
          nonValid={status === 'invalid' || isWrong}
          caption={caption}
          onBlur={event => {
            formikDefaultOnBlur(event);
            explicitOnBlur?.(event);
          }}
          value={value || props.defaultValue || ''}
          {...otherFormikProps}
          {...otherExplicitProps}
          type="password"
          onRightIconClick={() => console.log('onRightIconClick')}
          iconRight={<EyeOpenedIcon />}
        />
      </Div>
    );
  }),
);
