import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { H4, Button } from '@beauty/beauty-market-ui';
import LogoutImage from '../../assets/exit-image.png';
import { RouterUrl } from '../../routes/routes';
import { logout } from '../../store/actions/common';
import { useAppDispatch } from '../../store/hooks';
import { ServiceUnavailableWrapper } from './style';

const ServiceUnavailable = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  return (
    <ServiceUnavailableWrapper>
      <img src={LogoutImage} alt={t('logout.altImg')} />
      <H4>{t('error.serviceUnavailable')}</H4>
      <Button
        design="primary"
        size="large"
        mt="32px"
        onClick={() => {
          dispatch(logout());
          navigate(RouterUrl.Login);
          // location.reload is used cause of create-app scripts.
          // We need to refresh whole page to be able to display it, otherwise error-overlay won't let us do it
          window.location.reload();
        }}
      >
        {t('error.returnHome')}
      </Button>
    </ServiceUnavailableWrapper>
  );
};

export default ServiceUnavailable;
