import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import { Form, Formik } from 'formik';
import { parsePhoneNumber } from 'libphonenumber-js/max';
import { BodySmall, Button, Checkbox, Div, Flex, H2 } from '@beauty/beauty-market-ui';
import { FormikInput, FormikPasswordInput, FormikPhone } from 'components';
import { getInvalidType, getShortCountryByCode, signupPhoneVerification } from 'helpers';
import { useToggle } from 'hooks';
import { RouterUrl } from 'routes';
import { useAppDispatch } from 'store/hooks';
import { updateSmsCodeTime } from 'store/redux-slices/userSlice';
import { invalidHints } from '../../../constants';
import {
  initialvalues,
  RegistrationForm,
  RegistrationFormFields,
  registrationFormValidationSchema,
} from './Registration.definitions';

const Registration = () => {
  const { t } = useTranslation();
  const { state } = useLocation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const [isNotifications, setNotifications] = useToggle(true);

  const email = state?.email;

  const { Number, Name, Email, Region, Surname, Password } = RegistrationFormFields;

  const onFormSubmit = useCallback(
    async (data: RegistrationForm, { setFieldError }) => {
      const { code, number, ...rest } = data;
      if (code && number) {
        const phone = parsePhoneNumber(`${code}${number}`, getShortCountryByCode(code));
        const phoneVer = { code, number: phone.nationalNumber, email: data.email };
        const response = await signupPhoneVerification(phoneVer);

        if (response.status === 'pending') {
          dispatch(updateSmsCodeTime(new Date().getTime()));
          navigate(RouterUrl.PhoneVerification, {
            state: {
              ...rest,
              name: data.name.trim(),
              surname: data.surname.trim(),
              number: phone.nationalNumber,
              code,
              agreement: isNotifications,
            },
          });
        } else {
          const errorCode = getInvalidType(response.statusCode, 'registration');
          setFieldError(Number, t(invalidHints[errorCode]));
        }
      }
    },
    [navigate, isNotifications],
  );

  const formikContextValue = {
    initialValues: { ...initialvalues, [Email]: email },
    validationSchema: registrationFormValidationSchema(t),
    onSubmit: onFormSubmit,
    validateOnMount: false,
  };

  return (
    <Flex flexDirection={['column', 'column', 'column']}>
      <H2>{t('registration.title')}</H2>
      <Div width="100%" mt="16px">
        <Formik {...formikContextValue}>
          {({ isValid }) => (
            <Form autoComplete="on">
              <Flex gap="8px" flexDirection={['column', 'column', 'column', 'row']}>
                <FormikInput
                  autoFocus
                  id={Name}
                  name={Name}
                  autoComplete="given-name"
                  placeholder={t('registration.name')}
                  mb="8px"
                />
                <FormikInput
                  autoFocus
                  id={Surname}
                  name={Surname}
                  autoComplete="family-name"
                  placeholder={t('registration.surname')}
                  mb="16px"
                />
              </Flex>
              <FormikPhone codeId={Region} phoneId={Number} placeholder={t('registration.phone')} design="grey" />
              <FormikInput
                id={Email}
                name={Email}
                defaultValue={email}
                autoComplete="email"
                type="email"
                placeholder="E-mail"
                m="16px 0"
                disabled
              />
              <FormikPasswordInput
                id={Password}
                name={Password}
                autoComplete="new-password"
                type="password"
                placeholder={t('registration.password')}
                caption={t('registration.passwordHint')}
              />
              <Flex alignItems="center" my="30px">
                <Checkbox shape="round" onClick={setNotifications} checked={isNotifications} />
                <BodySmall low ml="8px">
                  {t('registration.iAgree')}
                </BodySmall>
              </Flex>
              <Button disabled={!isValid} design="primary" mt="24px" width="100%" size="large" type="submit">
                {t('button.continue')}
              </Button>
            </Form>
          )}
        </Formik>
      </Div>
    </Flex>
  );
};

export default Registration;
