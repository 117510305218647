import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";

export const useGetSearchParams = () => {
  const [searchParamsAsObject, setSearchParamsAsObject] = useState<{
    [k: string]: string;
  }>();
  const [searchParams] = useSearchParams();

  useEffect(() => {
    const searchParamsAsObjectTemp = Object.fromEntries(
      new URLSearchParams(searchParams)
    );
    setSearchParamsAsObject(searchParamsAsObjectTemp);
  }, [searchParams]);

  return [searchParamsAsObject];
};
