import { Fragment, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import {
  Avatar,
  BodyLarge,
  BodySmall,
  Button,
  Caption,
  colors,
  CoverStack,
  CoverStackCustomer,
  EditIcon,
  Flex,
  H4,
  Separator,
  TagButton,
} from '@beauty/beauty-market-ui';
import { EditEmployee, Paper } from 'components';
import { getFullName } from 'helpers';
import { Adaptive } from 'hoc';
import { useMediaScreen } from 'hooks';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { selectSpecialist, selectType, updateType } from 'store/redux-slices/specialistSlice';
import { SpecialistAction } from 'types';
import { getSelectedLanguage } from '../../../../constants';
import { getSpecialistInfo } from '../helpers';

export const EmployeeCoverStack = () => {
  const { t } = useTranslation();
  const { isDesktop } = useMediaScreen('md');
  const { account, accessLevel, specialization, accessStart, accessEnd } = useAppSelector(selectSpecialist);
  const type = useAppSelector(selectType);
  const dispatch = useAppDispatch();

  const locale = getSelectedLanguage().toLowerCase();
  moment.locale(locale);

  const tagText = accessLevel && t(`profile.access.${accessLevel.toLowerCase()}`);
  const fullName = getFullName(account);

  const specialistInfo = getSpecialistInfo(
    {
      specialization,
      accessStart,
      accessEnd,
      number: account.number,
      code: account.code,
      email: account.email,
    },
    isDesktop,
    t,
  );

  const content = (
    <Flex flexDirection="column" p={isDesktop ? '16px 0 0' : '0'} width="inherit" alignItems="start">
      {specialistInfo.map((item, idx, arr) => (
        <Fragment key={item.title}>
          <Flex p={isDesktop ? '12px 0' : '8px 0'} flexDirection="column" gap="2px">
            <Adaptive desktop={BodySmall} mobile={Caption}>
              {item.title}
            </Adaptive>
            <BodyLarge large color={item.subtitle ? colors.black.standard : colors.grey.standard} dir={item.dir}>
              {item.subtitle ?? t('profile.notFilledYet')}
            </BodyLarge>
          </Flex>
          {idx !== arr.length - 1 && <Separator />}
        </Fragment>
      ))}
    </Flex>
  );

  const handleEditEmployeeClick = () => dispatch(updateType(SpecialistAction.Edit));
  const handleClose = () => dispatch(updateType(SpecialistAction.None));

  const header = (
    <CoverStackCustomer
      firstName={account?.name}
      lastName={account?.surname}
      buttonSize="extraSmall"
      buttonDesign="tertiary"
      editButtonText={t('profile.editProfile')}
      imgUrl={account?.avatarUrl}
      onButtonClick={handleEditEmployeeClick}
      tagText={tagText}
      width="310px"
    />
  );

  useEffect(() => {
    if (type !== SpecialistAction.None) {
      dispatch(updateType(SpecialistAction.None));
    }
  }, []);

  return (
    <>
      {isDesktop ? (
        <CoverStack width="310px" header={header} content={content} />
      ) : (
        <Paper flexDirection="column" alignItems="center" p="16px 8px 8px" gap="16px">
          <Flex alignItems="center" justifyContent="space-between" width="100%">
            <TagButton text={tagText} backgroundColor="black" design="hover" />
            <Button design="secondary" prefix={<EditIcon />} size="small" onClick={handleEditEmployeeClick} />
          </Flex>
          <Flex flexDirection="column" alignItems="center">
            <Avatar url={account?.avatarUrl} name={fullName} rating="" size="m" />
            <H4 mt="8px">{fullName}</H4>
          </Flex>
          <Flex flexDirection="column" alignItems="center" width="100%">
            {content}
          </Flex>
        </Paper>
      )}
      {type === SpecialistAction.Edit && <EditEmployee onBack={handleClose} />}
    </>
  );
};
