import { useEffect, useState } from 'react';
import { apiRequest } from '../../api/api';
import { OrganisationEndpoints } from '../../api/endpoints/endpoints';
import { AddressPayloadType } from '../../types';

export const useGetAddressById = (id = '', query = ''): [AddressPayloadType | undefined, boolean, unknown] => {
  const [isLoading, setLoading] = useState(true);
  const [errorMessage, setErrorMessage] = useState<unknown>(null);
  const [data, setData] = useState<AddressPayloadType>();

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      const [error, response] = await apiRequest.get<AddressPayloadType>({
        endpoint: OrganisationEndpoints.OrganisationTest,
        endpointParams: { id, include: query },
      });
      if (error?.data) setErrorMessage(error.data);
      if (response?.data) setData(response.data);
      setLoading(false);
    };

    id && fetchData();
  }, [id]);

  return [data, isLoading, errorMessage];
};
