import { withTranslation } from 'react-i18next';
import { ThemeProvider } from 'styled-components';
import { BMTheme } from '@beauty/beauty-market-ui';
import { useTheme } from './hooks';
import Router from './routes/Router';
import { AppWrapper } from './style';

const App = () => {
  const theme = useTheme();

  return (
    <BMTheme theme={theme}>
      <ThemeProvider theme={theme}>
        <AppWrapper>
          <Router />
        </AppWrapper>
      </ThemeProvider>
    </BMTheme>
  );
};

export default withTranslation()(App);
