import { Endpoints } from '../api.types';

export enum Directories {
  User = 'account',
  Auth = 'auth',
  Services = 'services',
  Client = 'client',
  Organisation = 'organization',
  Appointment = 'appointment',
  Specialist = 'specialist',
  HeadOrganisationService = 'head-organization-service',
  HeadOrganisationClient = 'head-organization-client',
  HeadOrganisation = 'head-organization',
  GoogleAuth = 'google-auth',
  Password = 'password',
  Subscription = 'subscription',
  Notification = 'notification',
  Break = 'break',
  BreakAll = 'break-all',
  Property = 'property',
  Review = 'review',
  PersonalInformation = 'personal-information',
}

export enum UserEndpoints {
  Root = 'user',
  SendMessage = 'sendMessage',
  AvatarUpload = 'uploadProfilePhoto',
  NotificationUpdate = 'updateNotification',
  Appointments = 'userAppointments',
  CreateAppointment = 'createAppointment',
  Phone = 'phone',
  Password = 'password',
}

export enum OrganisationEndpoints {
  OrganisationCreate = 'organizationCreate',
  OrganisationUpdate = 'organizationUpdate',
  OrganisationTest = 'organisationTest',
  OrganisationSpecialists = 'organisationSpecialists',
  OrganisationReviews = 'organisationReviews',
  OrganisationServiceDetails = 'organisationServiceDetails',
  Timeslots = 'timeslots',
  Photo = 'photo',
  OrganisationServices = 'organisationServices',
  OrganisationEditSpecialists = 'organisationSpecialists',
  OrganisationDelete = 'organizationDelete',
}

export enum AuthEndpoints {
  Root = 'signup',
  LoginByPhone = 'loginByPhone',
  LoginPhoneVerification = 'loginPhoneVerification',
  LoginByEmail = 'loginByEmail',
  EmailVerification = 'emailVerification',
  UpdatePassword = 'updatePassword',
  ResetPassword = 'resetPassword',
  SignupPhoneVerification = 'signupPhoneVerification',
}

export enum ServicesEndpoints {
  TopCategories = 'topCategories',
  Categories = 'categories',
  HeadOrganizationService = 'head-organization-service',
}

export enum PropertyEndpoints {
  Organization = 'organizationProperty',
}

export enum HeadOrganisationEndpoints {
  Root = 'headOrganisation',
  Category = 'category',
  Service = 'service',
  Organization = 'organization',
  Specialists = 'specialists',
  SubscriptionPlans = 'subscriptionPlans',
  Subscribe = 'subscribe',
  SetPaymentCard = 'setPaymentCard',
  CancelSubscription = 'cancelSubscription',
  SubscriptionInvoices = 'subscriptionInvoices',
  ActivateOption = 'activateOption',
  DeactivateOption = 'deactivateOption',
  AllClients = 'allClients',
  Client = 'headOrganisationClient',
  ClientInfo = 'headOrganisationClientInfo',
  ClientPayments = 'headOrganisationClientPayments',
  ClientAppointments = 'headOrganisationClientAppointments',
}

export enum SpecialistEndpoints {
  Root = 'specialist',
  Organization = 'organizationSpecialist',
  Services = 'services',
  CurrentSchedule = 'currentSchedule',
  GeneralSchedule = 'generalSchedule',
  Break = 'break',
  BreakAll = 'breakAll',
  PersonalInformation = 'specialistPersonalInformation',
  EditAbout = 'specialistEditAbout',
  Education = 'specialistEducation',
  Photo = 'specialistEditPhotos',
  Language = 'specialistEditLanguages',
}

export enum ClientEndpoints {
  Root = 'client',
  Review = 'review',
  ClientReview = 'clientReview',
}

export enum CalendarEndpoints {
  Appointments = 'appointments',
  AppointmentById = 'appointmentById',
  AppointmentCreate = 'appointmentCreate',
  Clients = 'clients',
  ClientCreate = 'clientCreate',
  GoogleConvert = 'googleConvert',
  CloseAppointment = 'closeAppointment',
  CancelAll = 'cancelAll',
}

export enum GoogleAuthorisationEndpoints {
  Calendar = 'calendar',
  Revoke = 'revoke',
}

export enum NotificationEndpoints {
  Telegram = 'telegram',
}

export const endpointsWhitelist = [UserEndpoints]; // used to verify where should send access token

export const endpoints: Endpoints = {
  [UserEndpoints.Root]: ({ id, queryParams }) =>
    `/${Directories.User}${id ? `/${id}` : ''}${queryParams ? `?${queryParams}` : ''}`,
  [UserEndpoints.SendMessage]: () => `/${Directories.User}/send-message`,
  [UserEndpoints.AvatarUpload]: (id: string) => `/${Directories.User}/${id}/avatar`,
  [UserEndpoints.NotificationUpdate]: ({ profileId, notificationId }) =>
    `/${Directories.User}/${profileId}//${Directories.Notification}/${notificationId}`,
  [UserEndpoints.CreateAppointment]: () => `/${Directories.Appointment}`,
  [UserEndpoints.Appointments]: (id: string) => `/${Directories.Organisation}/${Directories.Client}/${id}/appointment`,
  [UserEndpoints.Phone]: () => `/${Directories.User}/phone`,
  [UserEndpoints.Password]: (id: string) => `/${Directories.User}/${Directories.Password}/${id}`,

  /** Autorization endpoints */
  [AuthEndpoints.Root]: (id?: string) => `/${Directories.Auth}/signup${id ? `/${id}` : ''}`,
  [AuthEndpoints.LoginByPhone]: () => `/${Directories.Auth}/login-phone`,
  [AuthEndpoints.LoginByEmail]: () => `/${Directories.Auth}/login-email`,
  [AuthEndpoints.LoginPhoneVerification]: () => `/${Directories.Auth}/login-phone-verification`,
  [AuthEndpoints.EmailVerification]: () => `/${Directories.Auth}/email-verification`,
  [AuthEndpoints.UpdatePassword]: () => `/${Directories.Auth}/update-password`,
  [AuthEndpoints.ResetPassword]: () => `/${Directories.Auth}/reset-password`,
  [AuthEndpoints.SignupPhoneVerification]: () => `/${Directories.Auth}/signup-phone-verification`,

  /** Service endpoints */
  [ServicesEndpoints.TopCategories]: () => `/service-category/top`,
  [ServicesEndpoints.Categories]: () => `/service-category`,
  [ServicesEndpoints.HeadOrganizationService]: (id?: string) =>
    `/${Directories.HeadOrganisationService}${id ? `/${id}` : ''}`,

  /** Organisation endpoints */
  [OrganisationEndpoints.OrganisationCreate]: (orgId?: string) =>
    `/${Directories.Organisation}${orgId ? `/${orgId}` : ''}`,
  [OrganisationEndpoints.OrganisationSpecialists]: (orgId: string) =>
    `/${Directories.Organisation}/${orgId}/specialists`,
  [OrganisationEndpoints.OrganisationReviews]: (orgId: string) => `/${Directories.Organisation}/${orgId}/reviews`,
  [OrganisationEndpoints.OrganisationServiceDetails]: ({ orgId, serviceId }) =>
    `/${Directories.Organisation}/${orgId}/services/${serviceId}`,
  [OrganisationEndpoints.Timeslots]: ({ serviceId, queryParams }) =>
    `/${Directories.Organisation}/service/${serviceId}/timeslots${queryParams}`,
  [OrganisationEndpoints.Photo]: (id: string) => `/${Directories.Organisation}/photo/${id}`,
  [OrganisationEndpoints.OrganisationTest]: ({ id, include }) =>
    `/${Directories.Organisation}/${id}/test?include=${include}`,
  [OrganisationEndpoints.OrganisationUpdate]: id => `/${Directories.Organisation}/${id}`,
  [OrganisationEndpoints.OrganisationServices]: id => `/${Directories.Organisation}/${id}/service`,
  [OrganisationEndpoints.OrganisationEditSpecialists]: id => `/${Directories.Organisation}/${id}/org-specialist`,
  [OrganisationEndpoints.OrganisationDelete]: orgId => `/${Directories.Organisation}/${orgId}/delete`,

  /** Specialist endpoints */
  [SpecialistEndpoints.Root]: ({ id, queryParams }) =>
    `/${Directories.Specialist}${id ? `/${id}` : ''}${queryParams ? `${queryParams}` : ``}`,
  [SpecialistEndpoints.Organization]: () => `${Directories.Specialist}/organization-specialist`,
  [SpecialistEndpoints.Services]: () => `${Directories.Specialist}/services`,
  [SpecialistEndpoints.CurrentSchedule]: () => `${Directories.Specialist}/organization-schedule`,
  [SpecialistEndpoints.GeneralSchedule]: () => `${Directories.Specialist}/head-organization-schedule`,
  [SpecialistEndpoints.Break]: (id?: string) => `${Directories.Specialist}/${Directories.Break}/${id ?? ''}`,
  [SpecialistEndpoints.BreakAll]: () => `${Directories.Specialist}/${Directories.BreakAll}`,
  [SpecialistEndpoints.PersonalInformation]: (id: string) =>
    `${Directories.Specialist}/${Directories.PersonalInformation}/${id}`,
  [SpecialistEndpoints.EditAbout]: () => `${Directories.Specialist}/${Directories.PersonalInformation}/about`,
  [SpecialistEndpoints.Education]: (id = '') =>
    `${Directories.Specialist}/${Directories.PersonalInformation}/education/${id}`,
  [SpecialistEndpoints.Photo]: (id = '') => `${Directories.Specialist}/photo/${id}`,
  [SpecialistEndpoints.Language]: () => `${Directories.Specialist}/${Directories.PersonalInformation}/language`,

  /** Client endpoints */
  [ClientEndpoints.Root]: ({ id, queryParams }) =>
    `/${Directories.Client}${id ? `/${id}` : ''}${queryParams ? `${queryParams}` : ``}`,
  [ClientEndpoints.Review]: (id: string) => `/${Directories.Review}/${Directories.Client}/${id}`,
  [ClientEndpoints.ClientReview]: ({ id, page, size, order }) =>
    `review/${Directories.Client}/${id}?size=${size}&page=${page}&order=${order}`,

  /** HeadOrganisation endpoints */
  [HeadOrganisationEndpoints.Root]: (orgId?: string) => `/${Directories.HeadOrganisation}${orgId ? `/${orgId}` : ''}`,
  [HeadOrganisationEndpoints.Category]: (orgId: string) => `/${Directories.HeadOrganisation}/${orgId}/category`,
  [HeadOrganisationEndpoints.Service]: (orgId: string) => `/${Directories.HeadOrganisation}/${orgId}/service`,
  [HeadOrganisationEndpoints.Organization]: (orgId: string) => `/${Directories.HeadOrganisation}/${orgId}/organization`,
  [HeadOrganisationEndpoints.Specialists]: (id: string) => `/${Directories.HeadOrganisation}/${id}/specialist`,
  [HeadOrganisationEndpoints.DeactivateOption]: (id: string) =>
    `/${Directories.HeadOrganisation}/${id}/option/deactivate`,

  /** HeadOrgSubscription */
  [HeadOrganisationEndpoints.SetPaymentCard]: ({ id, cardId }) =>
    `/${Directories.HeadOrganisation}/${id}/default/${cardId}`,
  [HeadOrganisationEndpoints.SubscriptionPlans]: () => `/${Directories.Subscription}/plans`,
  [HeadOrganisationEndpoints.Subscribe]: (id: string) => `/${Directories.Subscription}/subscribe/${id}`,
  [HeadOrganisationEndpoints.CancelSubscription]: (id: string) => `/${Directories.Subscription}/${id}/cancel`,
  [HeadOrganisationEndpoints.SubscriptionInvoices]: (id: string) => `/${Directories.Subscription}/${id}/payments`,
  [HeadOrganisationEndpoints.ActivateOption]: (id: string) => `/${Directories.Subscription}/${id}/activate-option`,

  /** HeadOrgClient */
  [HeadOrganisationEndpoints.Client]: (id: string) => `/${Directories.HeadOrganisationClient}/${id}`,
  [HeadOrganisationEndpoints.ClientInfo]: (id: string) => `/${Directories.HeadOrganisationClient}/${id}/info`,
  [HeadOrganisationEndpoints.ClientPayments]: ({ id, queryParams }) =>
    `/${Directories.HeadOrganisationClient}/${id}/payment${queryParams}`,
  [HeadOrganisationEndpoints.ClientAppointments]: ({ id, interval, page, size }) =>
    `/${Directories.HeadOrganisationClient}/${id}/appointment?size=${size}&page=${page}&interval=${interval}`,
  [HeadOrganisationEndpoints.AllClients]: (id: string) => `/${Directories.HeadOrganisationClient}/${id}/all`,

  /** Appointments endpoints */
  [CalendarEndpoints.Appointments]: (queryParams: string) => `/${Directories.Appointment}/crm${queryParams}`,
  [CalendarEndpoints.AppointmentById]: (id: string) => `/${Directories.Appointment}/${id}`,
  [CalendarEndpoints.AppointmentCreate]: () => `/${Directories.Appointment}/crm`,
  [CalendarEndpoints.Clients]: ({ id, queryParams }) => `/${Directories.HeadOrganisation}/${id}/client${queryParams}`,
  [CalendarEndpoints.ClientCreate]: (id: string) => `/${Directories.Organisation}/client/${id}`,
  [CalendarEndpoints.GoogleConvert]: () => `/${Directories.Appointment}/convert/`,
  [CalendarEndpoints.CloseAppointment]: () => `/${Directories.Appointment}/close/`,
  [CalendarEndpoints.CancelAll]: () => `/${Directories.Appointment}/cancel-all/`,

  [GoogleAuthorisationEndpoints.Calendar]: (id: string) => `/${Directories.GoogleAuth}/calendar/${id}`,
  [GoogleAuthorisationEndpoints.Revoke]: (id: string) => `/${Directories.GoogleAuth}/revoke/${id}`,

  [NotificationEndpoints.Telegram]: (profileId: string) => `/${Directories.Notification}/telegram/${profileId}`,

  /** Property endpoints */
  [PropertyEndpoints.Organization]: () => `/${Directories.Property}/organization`,
};
