import { ReactNode } from 'react';
import { BottomSheet, getMinWidthMediaQuery, Sidebar, useMediaQuery } from '@beauty/beauty-market-ui';

type Props = {
  label: string;
  FooterBody: ReactNode;
  children: ReactNode;
  onClose?: () => void;
  onBack?: () => void;
  descriptor?: ReactNode;
  detent?: 'full-height' | 'content-height';
  disableDrag?: boolean;
};

export const SidebarSheet = ({
  children,
  onClose,
  onBack,
  detent = 'content-height',
  disableDrag = false,
  ...rest
}: Props) => {
  const mediaQuery = getMinWidthMediaQuery('md');
  const isDesktop = useMediaQuery(mediaQuery);

  return isDesktop ? (
    <Sidebar isOpen onClose={onClose} {...rest}>
      {children}
    </Sidebar>
  ) : (
    <BottomSheet
      isOpen
      onClose={onClose}
      handleClose={onClose}
      onBack={onBack}
      content={children}
      detent={detent}
      {...rest}
    />
  );
};
