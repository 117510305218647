import { useTranslation } from 'react-i18next';
import { colors, Icon, Menu, MenuDotsIcon } from '@beauty/beauty-market-ui';
import { useAppSelector } from '../../../../store/hooks';
import { selectActiveSubscription } from '../../../../store/redux-slices/organisationSlice';
import { MenuWrapper, OptionItem } from '../../style';
import { getOptions } from '../helpers';

export const SectionMenu = ({ setAction }) => {
  const { t } = useTranslation();
  const subscription = useAppSelector(selectActiveSubscription);

  return (
    <MenuWrapper>
      <Menu
        currentOption={-1}
        placement="bottom-start"
        iconMenu={
          <Icon cursor="pointer" width="22px" height="22px" ml="auto">
            <MenuDotsIcon />
          </Icon>
        }
        options={[]}
      >
        {getOptions(subscription, t).map((item, index, arr) => (
          <OptionItem
            key={item.item}
            onClick={e => (item.disabled ? e.stopPropagation() : setAction(item.action))}
            mb={index !== arr.length - 1 ? '20px' : '0px'}
            color={item.disabled ? colors.grey.standard : colors.black.standard}
          >
            {item.item}
          </OptionItem>
        ))}
      </Menu>
    </MenuWrapper>
  );
};
