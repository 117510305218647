import { ChangeEvent, DragEvent, MouseEvent, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useFormikContext } from 'formik';
import { Avatar, BodySmall, Caption, CloseIcon, colors, Div, UploadIcon } from '@beauty/beauty-market-ui';
import { FormikInput } from 'components/functional/formik/formik-input/FormikInput';
import { getPhotoImage } from 'helpers';
import { EducationFormFields, EducationFormType } from './Education.definitions';
import { StyledLabel, StyledPaper } from './style';

export const Dropzone = () => {
  const { t } = useTranslation();
  const { File: EducationFile, Name } = EducationFormFields;
  const { values, setFieldValue, errors } = useFormikContext<EducationFormType>();

  const [isDragActive, setIsDragActive] = useState<boolean>(false);

  const onDragLeave = () => {
    setIsDragActive(false);
  };

  const onDragOver = (e: DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    setIsDragActive(true);
  };

  const onDrop = async (e: DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    setIsDragActive(false);
    const file = e.dataTransfer.files?.[0];
    if (file) {
      await setFieldValue(EducationFile, file);
      await setFieldValue(Name, file.name);
    }
  };

  const onReset = async (e: MouseEvent<HTMLOrSVGElement>) => {
    e.stopPropagation();
    e.preventDefault();
    await setFieldValue(EducationFile, '');
    await setFieldValue(Name, '');
  };

  const onChange = async (e: ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    const file = e.target.files?.[0];
    if (file) {
      await setFieldValue(EducationFile, file);
      await setFieldValue(Name, file.name);
    }
  };

  return (
    <>
      <StyledPaper
        active={isDragActive}
        error={!!errors[EducationFile]}
        onDragLeave={onDragLeave}
        onDragOver={onDragOver}
        onDrop={onDrop}
      >
        <StyledLabel htmlFor={EducationFile}>
          {values[Name] ? (
            <>
              <Avatar url={values[Name]?.endsWith('pdf') ? undefined : getPhotoImage(values[EducationFile])} size="s" />
              <BodySmall ml="16px" mr="auto" truncated>
                {values[Name]}
              </BodySmall>
              <Div flexShrink={0}>
                <CloseIcon onClick={onReset} />
              </Div>
            </>
          ) : (
            <>
              <UploadIcon />
              <BodySmall ml="4px">{t('specialists.personalInfo.uploadDocument')}</BodySmall>
            </>
          )}
        </StyledLabel>
      </StyledPaper>
      {errors[EducationFile] && (
        <Caption color={colors.red.standard} textAlign="center" display="block" m="4px 17px 0">
          {errors[EducationFile]}
        </Caption>
      )}
      <FormikInput
        id={EducationFile}
        name={EducationFile}
        type="file"
        onChange={onChange}
        style={{ display: 'none' }}
        value=""
      />
    </>
  );
};
