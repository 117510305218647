import { TFunction } from 'react-i18next';
import * as Yup from 'yup';

export enum AboutCompanyFormFields {
  Description = 'description',
  Property = 'property',
}

export type AboutCompanyFormType = {
  [AboutCompanyFormFields.Description]?: string;
  [AboutCompanyFormFields.Property]?: string[];
};

export const schema = (t: TFunction<'translation', undefined>) =>
  Yup.object({
    [AboutCompanyFormFields.Description]: Yup.string().max(1000, t('validation.maxLength1000')),
  });
