import styled from 'styled-components';
import { Flex } from '@beauty/beauty-market-ui';
import { zIndex } from '../../constants';

export const FooterWrapper = styled(Flex)`
  position: fixed;
  height: 72px;
  bottom: 0;
  left: 0;
  z-index: ${zIndex.footerWrapper};
  justify-content: center;
  width: 100%;
  box-sizing: border-box;
  background-color: rgba(255, 255, 255, 0.6);
`;

export const FooterContainer = styled(Flex)`
  align-items: center;
  width: 100%;
  max-width: 1060px;
  box-sizing: border-box;
  justify-content: space-between;
  backdrop-filter: blur(12px);

  @media screen and (max-width: 1060px) {
    padding-left: 25px;
    padding-right: 25px;
  }
`;

export const MixFlex = styled(Flex)`
  @media (max-width: 375px) {
    flex-direction: column-reverse;
  }
`;
