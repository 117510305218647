import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { logout } from '../actions/common';
import { RootState } from '../store';

interface AuthState {
  isLogin: boolean;
  isShowLogout: boolean;
}

const initialState: AuthState = {
  isLogin: false,
  isShowLogout: false,
};

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setShowLogout: (state, action: PayloadAction<boolean>) => {
      state.isShowLogout = action.payload;
    },
    setLogin: (state, action: PayloadAction<boolean>) => {
      state.isLogin = action.payload;
    },
  },
  extraReducers: builder => {
    builder.addCase(logout, () => initialState);
  },
});

export const { setShowLogout, setLogin } = authSlice.actions;

export const selectAuth = (state: RootState) => state.auth;

export default authSlice.reducer;
