import styled, { css } from 'styled-components';
import { Flex, TelegramMonoIcon, colors, Button, BodySmall } from '@beauty/beauty-market-ui';

export const PopupWrapper = styled(Flex)`
  position: absolute;
  & > div:first-child > div:first-child > div:first-child {
    padding-bottom: 0;
  }
`;

export const TelegramIcon = styled(TelegramMonoIcon)`
  path {
    stroke: none !important;
    fill: ${colors.white.standard};
  }
`;

export const StyledButton = styled(Button)<{ isRed: boolean }>`
  ${({ isRed }) =>
    isRed &&
    css`
      background-color: ${colors.red.standard}!important;
      border: none !important;
    `}
`;

export const BlueSpanInclude = styled(BodySmall)`
  span {
    color: ${({ theme }) => theme.colors.blue.standard};
  }
`;
