import { ChangeEvent, useRef, useState, MouseEvent, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import {
  useClickOutside,
  ContextMenu,
  Flex,
  BodySmall,
  PlusCircleIcon,
  MinusCircleIcon,
  CrossIcon,
} from '@beauty/beauty-market-ui';
import { StyledButton, StyledMenuDotsIcon, ContextMenuWrapper } from './style';

type MonthKebabMenuProps = {
  onCloseDay: (e: ChangeEvent<HTMLInputElement>) => void;
  onCreateAppointment: (e: MouseEvent<HTMLDivElement>) => void;
  onCancelAll: (e: MouseEvent<HTMLDivElement>) => void;
};

export const MonthKebabMenu = ({ onCloseDay, onCancelAll, onCreateAppointment }: MonthKebabMenuProps) => {
  const { t } = useTranslation();
  const [isMenu, setIsMenu] = useState(false);
  const [menuAtTop, setMenuAtTop] = useState(false);
  const menuRef = useRef<Element | null>(null);

  const options = [
    {
      body: (
        <Flex gap="8px" key="createAppointment">
          <PlusCircleIcon />
          <BodySmall lowline>{t('calendar.menu.createAppointment')}</BodySmall>
        </Flex>
      ),
      onClick: onCreateAppointment,
    },
    {
      body: (
        <Flex gap="8px" key="closeDay">
          <MinusCircleIcon />
          <BodySmall lowline>{t('calendar.menu.closeDay')}</BodySmall>
        </Flex>
      ),
      onClick: onCloseDay,
    },
    {
      body: (
        <Flex gap="8px" key="cancelAll">
          <CrossIcon />
          <BodySmall lowline>{t('calendar.menu.cancelAll')}</BodySmall>
        </Flex>
      ),
      onClick: onCancelAll,
    },
  ];

  useClickOutside(menuRef, () => setIsMenu(false));
  useEffect(() => {
    const elementRect = menuRef.current && menuRef.current.getBoundingClientRect();
    const elementHeight = elementRect?.height;
    const documentHeight = document.documentElement.scrollHeight;

    const distanceToBottom = elementRect?.top && elementHeight && documentHeight - (elementRect.top + elementHeight);
    isMenu && menuRef.current && distanceToBottom && distanceToBottom < 20 && setMenuAtTop(true);
  }, [isMenu, menuRef.current]);

  return (
    <Flex>
      <StyledButton
        design="tertiary"
        type="button"
        onClick={e => {
          e.stopPropagation();
          e.preventDefault();
          setIsMenu(prev => !prev);
        }}
      >
        <StyledMenuDotsIcon />
      </StyledButton>
      {isMenu && (
        <ContextMenuWrapper ref={menuRef} menuAtTop={menuAtTop}>
          <ContextMenu items={options} />
        </ContextMenuWrapper>
      )}
    </Flex>
  );
};
