import styled, { css } from 'styled-components';
import { Flex, colors, Caption } from '@beauty/beauty-market-ui';
import { trimEventServiceText } from 'style';

const agendaPaddings = css`
  [dir='ltr'] & {
    padding: 6px 6px 6px 24px;
  }
  [dir='rtl'] & {
    padding: 6px 24px 6px 6px;
  }
`;

export const EventWrapper = styled(Flex)<{
  lineColor: string;
  isGoogle: boolean;
  isBreak: boolean;
  stringsToTrim: number;
  isActive?: boolean;
  isAgenda?: boolean;
}>`
  position: relative;
  justify-content: space-between;

  ${({ isAgenda, lineColor, isBreak }) =>
    isAgenda &&
    css`
      ::before {
        content: '';
        position: absolute;
        top: -1px;
        [dir='ltr'] & {
          left: 0;
        }
        [dir='rtl'] & {
          right: 0;
        }
        height: 48px;
        width: 4px;
        background-color: ${isBreak ? '#54cc29' : lineColor};
        border-radius: 8px;
      }
      height: 48px;
      margin-bottom: 16px;
    `};

  z-index: 1;
  ${({ isActive, isAgenda }) =>
    isActive ? 'padding: 10px 16px!important' : (isAgenda && agendaPaddings) || 'padding: 4px 6px!important'};
  border-radius: ${({ isActive }) => (isActive ? '10px' : '6px')};
  border: 1px solid ${({ isBreak }) => (isBreak ? '#54cc29' : colors.white.standard)};
  ${({ isAgenda }) =>
    isAgenda &&
    css`
      border: none;
      & > div {
        max-width: 83vw;
      }
    `};
  cursor: pointer;

  div:first-child > span:nth-child(2) {
    ${({ stringsToTrim, isActive }) => !isActive && trimEventServiceText(stringsToTrim)}
  }

  * {
    white-space: pre;
  }
`;

export const ServiceWrapper = styled(Caption)`
  color: rgba(255, 255, 255, 0.6) !important;
`;
