import { capitalize } from 'lodash';
import { BodyLarge, BodySmall, colors, Flex, Icon } from '@beauty/beauty-market-ui';
import { CardsIcons } from '../../../constants';
import { StyledRadio, Paper } from '../style';

export const CardItem = ({ card, checked, onChange }) => (
  <Paper
    key={card.id}
    p="16px"
    justifyContent="space-between"
    alignItems="center"
    mb="16px"
    height="100px"
    border
    borderRadius="10px"
  >
    <Flex gap="16px" alignItems="center">
      <Icon width="40px" height="40px" alignItems="center">
        {CardsIcons[card.system] ? CardsIcons[card.system] : null}
      </Icon>
      <Flex flexDirection="column" gap="4px" width="100%">
        <BodyLarge>{capitalize(card.system)}</BodyLarge>
        <BodySmall color={colors.grey.dark}>
          ... {card.number.slice(card.number.length - 4, card.number.length)}
        </BodySmall>
      </Flex>
    </Flex>
    <StyledRadio checked={checked} onChange={() => onChange(card)} ml="auto" />
  </Paper>
);
