import { useEffect, useRef } from 'react';

export const useEndScroll = (callback?: () => void) => {
  const scrollRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleScroll = () => {
      if (scrollRef?.current && callback) {
        const scrollHeight = Number(scrollRef.current?.scrollHeight);
        const scrollTop = Number(scrollRef.current?.scrollTop);
        const clientHeight = Number(scrollRef.current?.clientHeight);
        if (scrollTop + clientHeight >= scrollHeight - 20) {
          callback();
        }
      }
    };

    scrollRef?.current?.addEventListener('scroll', handleScroll);
    return () => {
      scrollRef?.current?.removeEventListener('scroll', handleScroll);
    };
  }, [callback, scrollRef]);

  return scrollRef;
};
