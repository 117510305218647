import { useState } from 'react';
import { useRequest } from 'hooks';
import { useAppSelector } from 'store/hooks';
import { selectSpecialist, ThunkSpecialist } from 'store/redux-slices/specialistSlice';
import { SpecialistAction, SpecialistEducation } from 'types';
import { EducationFormType } from './Education.definitions';

export const useEducation = () => {
  const { id } = useAppSelector(selectSpecialist);

  const [action, setAction] = useState(SpecialistAction.None);
  const [values, setValues] = useState<SpecialistEducation | null>(null);
  const [isSubmitting, setSubmitting] = useState(false);

  const addEducation = useRequest(ThunkSpecialist.addEducation, SpecialistAction.AddEducation);
  const editEducation = useRequest(ThunkSpecialist.editEducation, SpecialistAction.EditEducation);
  const deleteEducation = useRequest(ThunkSpecialist.deleteEducation, SpecialistAction.DeleteEducation);

  const onClose = () => {
    setAction(SpecialistAction.None);
    setValues(null);
  };

  const onDelete = (item: SpecialistEducation) => {
    setAction(SpecialistAction.DeleteEducation);
    setValues(item);
  };

  const onEdit = (item: SpecialistEducation) => {
    setAction(SpecialistAction.EditEducation);
    setValues(item);
  };

  const onAdd = () => {
    setAction(SpecialistAction.AddEducation);
    setValues(null);
  };

  const onApproveDelete = async () => {
    try {
      setSubmitting(true);
      values && (await deleteEducation(values.id));
    } finally {
      setSubmitting(false);
      onClose();
    }
  };

  const onSubmit = async (data: EducationFormType) => {
    const isFileChanged = data.file instanceof File;
    const params = {
      startYear: Number(data.startYear),
      endYear: Number(data.endYear),
      school: data.school,
      field: data.field,
      degree: data?.degree,
      id: values?.id || id,
      headOrgSpecialistId: values?.headOrgSpecialistId,
      fileUrl: isFileChanged ? null : data.file,
      file: isFileChanged ? data.file : null,
      fileName: data?.file ? data?.fileName : null,
    };
    return values ? editEducation(params) : addEducation(params);
  };

  return {
    onDelete,
    onApproveDelete,
    onEdit,
    onSubmit,
    onAdd,
    onClose,
    isSubmitting,
    action,
    values,
  };
};
