import { useCallback, useState, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { Formik, Form } from 'formik';
import { Button, H2, BodySmall, Div } from '@beauty/beauty-market-ui';
import { useGetSearchParams } from 'routes/hooks/useGetSearchParams';
import { FormikInput } from '../../../components/functional/formik/formik-input/FormikInput';
import { getSelectedLanguage, InvalidVariants } from '../../../constants';
import { sendEmailLink } from '../../../helpers/sendEmailLink';
import { getInvalidType } from '../../../helpers/utils';
import { Rules } from '../componets/Rules';
import { EmailField, initialEmailValue, checkEmailSchema, EmailForm } from '../Login.definitions';

const SignUp = () => {
  const { t } = useTranslation();

  const language = getSelectedLanguage();
  const [isLinkSent, setIsLinkSent] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [email, setEmail] = useState('');
  const [invalidType, setInvalidType] = useState<InvalidVariants | null>(null);
  const [searchParamsAsObject] = useGetSearchParams();
  const { state } = useLocation();
  const isWrongLink = state?.isWrongLink;

  const referral = useMemo(() => searchParamsAsObject?.referral || '', [searchParamsAsObject]);

  const onFormSubmit = useCallback(
    async (data: EmailForm) => {
      setEmail(data.email);
      const response = await sendEmailLink({ email: data.email, language, referral });

      if (response.success) {
        setIsLinkSent(true);
        setInvalidType(null);
      } else {
        setInvalidType(getInvalidType(response.statusCode, 'signUp'));
        setErrorMessage(response.message);
      }
    },
    [referral],
  );

  const formikContextValue = useMemo(
    () => ({
      initialValues: initialEmailValue,
      validationSchema: checkEmailSchema(errorMessage, t),
      onSubmit: onFormSubmit,
      validateOnMount: false,
    }),
    [errorMessage, onFormSubmit, t],
  );

  return isLinkSent && !isWrongLink ? (
    <Div textAlign="center">
      <H2 mb="16px">{t('signUp.checkInbox')}</H2>
      <BodySmall large>{t('signUp.linkToVerify') + email}</BodySmall>
    </Div>
  ) : (
    <>
      <H2 mb="16px">{isWrongLink ? t('signUp.wrongLink') : t('signUp.title')}</H2>
      <BodySmall mb="32px">{t('signUp.enterEmail')}</BodySmall>
      <Formik {...formikContextValue}>
        {({ isValid }) => (
          <Form>
            <FormikInput
              autoFocus
              id={EmailField.Email}
              name={EmailField.Email}
              placeholder="E-mail"
              invalidType={invalidType}
              onInput={() => setInvalidType(null)}
            />
            <Button
              type="submit"
              disabled={!isValid || invalidType}
              design="primary"
              mt="14px"
              width="100%"
              size="large"
            >
              {t('button.continue')}
            </Button>
          </Form>
        )}
      </Formik>
      <Rules />
    </>
  );
};

export default SignUp;
