import { Flex, Sidebar } from '@beauty/beauty-market-ui';
import { SidebarFooter } from '../../SidebarFooter';
import { PaymentMethods } from './PaymentMethods';

export const PaymentBar = ({
  label,
  descriptor,
  isLoading,
  onSubmit,
  onClose,
  card,
  setCard,
  cancelLabel,
  confirmationLabel,
  children,
}) => (
  <Sidebar
    isOpen
    onClose={onClose}
    label={label}
    descriptor={descriptor}
    FooterBody={
      <SidebarFooter
        onSubmit={onSubmit}
        onBack={onClose}
        confirmationLabel={confirmationLabel}
        cancelLabel={cancelLabel}
        disabled={false}
        isLoading={isLoading}
      />
    }
  >
    <Flex flexDirection="column" gap="40px">
      {children}
      <PaymentMethods value={card} onChange={setCard} stub />
    </Flex>
  </Sidebar>
);
