import { BodyLarge, BodySmall, Button, Caption, colors, Flex, H2, H3 } from '@beauty/beauty-market-ui';
import { Container, StyledCheckedIcon } from '../style';

export const TariffCard = ({ plan, buttonLabel, duration, subtitle, features, additional, onClick, price = '' }) => (
  <Container>
    <BodyLarge large mb="40px">
      {plan}
    </BodyLarge>
    <Flex alignItems="flex-end">
      {duration ? (
        <H2 color={colors.grey.darker} mr="8px">
          {price}
        </H2>
      ) : (
        <H3 color={colors.grey.darker} mb="8px">
          {price}
        </H3>
      )}
      <BodyLarge color={colors.grey.darker}>{duration}</BodyLarge>
    </Flex>
    <Caption mb="39px" color={duration ? colors.grey.dark : colors.white.standard}>
      {subtitle}
    </Caption>
    <Flex flexDirection="column" height="110px">
      {features.map(item => (
        <Flex key={item} alignItems={duration ? 'center' : 'flex-start'} mb="12px">
          <StyledCheckedIcon color={colors.grey.standard} />
          <BodySmall color={colors.grey.dark} ml="8px">
            {item}
          </BodySmall>
        </Flex>
      ))}
    </Flex>
    <Button mt="27px" mb="23px" design="secondary" onClick={onClick}>
      {buttonLabel}
    </Button>
    {additional.map((item, index, arr) => (
      <Caption key={item} mb={index !== arr.length - 1 ? '8px' : '0px'} color={colors.grey.dark} lowline>
        {item}
      </Caption>
    ))}
  </Container>
);
