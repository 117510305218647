import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

enum AvailableForId {
  All = 'ALL',
  Men = 'MEN',
  Women = 'WOMEN',
  Children = 'CHILDREN',
}

export type AvailableOptionType = {
  id: AvailableForId;
  value: string;
  fullForm: string;
};

export const useAvailableOptions = () => {
  const { t } = useTranslation();
  const availableOptions: AvailableOptionType[] = [
    {
      id: AvailableForId.All,
      value: t('availableFor.all'),
      fullForm: t('availableFor.forAll'),
    },
    {
      id: AvailableForId.Men,
      value: t('availableFor.men'),
      fullForm: t('availableFor.forMen'),
    },
    {
      id: AvailableForId.Women,
      value: t('availableFor.women'),
      fullForm: t('availableFor.forWomen'),
    },
    {
      id: AvailableForId.Children,
      value: t('availableFor.children'),
      fullForm: t('availableFor.forChildren'),
    },
  ];
  return availableOptions;
};

type AvailableForType = Record<AvailableForId, AvailableOptionType>;

export const useAvailableFor = () => {
  const { t } = useTranslation();
  const availableFor: AvailableForType = {
    [AvailableForId.All]: {
      id: AvailableForId.All,
      value: t('availableFor.all'),
      fullForm: t('availableFor.forAll'),
    },
    [AvailableForId.Men]: {
      id: AvailableForId.Men,
      value: t('availableFor.men'),
      fullForm: t('availableFor.forMen'),
    },
    [AvailableForId.Women]: {
      id: AvailableForId.Women,
      value: t('availableFor.women'),
      fullForm: t('availableFor.forWomen'),
    },
    [AvailableForId.Children]: {
      id: AvailableForId.Children,
      value: t('availableFor.children'),
      fullForm: t('availableFor.forChildren'),
    },
  };
  return useMemo(() => availableFor, [t]);
};
