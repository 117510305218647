import { TFunction } from 'react-i18next';
import { isEmpty } from 'lodash';
import moment from 'moment/moment';
import { formatPhoneNumber } from 'helpers';
import { TimeItemType } from 'hooks';
import { RouterUrl } from 'routes/routes';
import { SpecialistOrganizationType } from 'types';
import { WeekDay, WeekDayHebrew } from 'types/organisation';
import { ScheduleType, WeekType } from './types';

const createWeek = (rtl: boolean): WeekType =>
  (rtl ? WeekDayHebrew : WeekDay).map(day => ({
    isWorkDay: false,
    dayOfWeek: day,
    orgIds: [],
    orgMap: {},
  }));
export const extractSchedule = (orgData, timeList: TimeItemType[], rtl: boolean): ScheduleType => {
  const getTimeIndex = (value: string) => timeList.findIndex(time => time.item === value);

  const schedule: ScheduleType = [];
  orgData.forEach(org => {
    const { orgId, name } = org;
    const orgSpecSchedule = org.orgSpecSchedule ? org.orgSpecSchedule : [];
    orgSpecSchedule.forEach(week => {
      const weekIndex = week.weekNumber - 1;
      const weekSchedule = week.orgSpecDaySchedule;
      if (!schedule[weekIndex]) schedule[weekIndex] = createWeek(rtl);

      weekSchedule.forEach(workDay => {
        const dayIndex = schedule[weekIndex].findIndex(day => day.dayOfWeek === workDay.dayOfWeek);
        if (dayIndex !== -1) {
          const day = schedule[weekIndex][dayIndex];
          day.isWorkDay = true;
          day.orgIds.push(orgId);
          day.orgMap[orgId] = {
            id: workDay.id,
            orgId,
            orgName: name,
            startIndex: getTimeIndex(workDay.start),
            endIndex: getTimeIndex(workDay.end),
            breaks: workDay.orgSpecDayBreak.map(breakOne => ({
              id: breakOne?.id || crypto.randomUUID(),
              startIndex: getTimeIndex(breakOne.start),
              endIndex: getTimeIndex(breakOne.end),
            })),
          };
        }
      });
    });
  });
  return schedule;
};

export const sortSpecialist = (a, b) => {
  // first always OWNER
  // second always SPECIALISTS by alphabet
  // third always MANAGERS by alphabet

  if (a.accessLevel === 'OWNER') {
    return -1;
  }
  if (b.accessLevel === 'OWNER') {
    return 1;
  }
  if (a.role === 'SPECIALIST' && b.role === 'MANAGER') {
    return -1;
  }
  if (a.role === 'MANAGER' && b.role === 'SPECIALIST') {
    return 1;
  }
  return `${a.account.name} ${a.account.surname}`.localeCompare(`${b.account.name} ${b.account.surname}`);
};

const checkIncludeServiceAndSchedule = (org: SpecialistOrganizationType) => {
  const workdaysOfAllWeeks = org.orgSpecSchedule.flatMap(item => item.orgSpecDaySchedule);
  return !isEmpty(workdaysOfAllWeeks) && !isEmpty(org.orgService);
};

export const getErrors = (
  t: TFunction<'translation', undefined>,
  published = false,
  active = false,
  organization: SpecialistOrganizationType[] = [],
) => {
  const messages: string[] = [];
  if (published) return [];
  if (!active) messages.push(t('specialists.shouldBeActivated'));
  if (isEmpty(organization)) messages.push(t('specialists.addAtLeastOneOrganization'));
  if (!organization.some(checkIncludeServiceAndSchedule)) {
    messages.push(t('specialists.atLeastOneOrganizationShouldHave'));
  }
  return Array.from(new Set(messages));
};

export const getSpecialistInfo = (
  specialist: {
    specialization?: string | null;
    code?: string | null;
    number?: string | null;
    email?: string | null;
    accessStart?: string | null;
    accessEnd?: string | null;
  },
  isDesktop: boolean,
  t: TFunction<'translation', undefined>,
) =>
  [
    {
      title: t('profile.specialisation'),
      subtitle: specialist?.specialization,
      show: !!specialist?.specialization && isDesktop,
    },
    {
      title: t('profile.phone'),
      subtitle: specialist?.code && specialist?.number ? formatPhoneNumber(specialist.code, specialist.number) : null,
      show: specialist?.code && specialist?.number,
      dir: 'ltr',
    },
    {
      title: t('profile.email'),
      subtitle: specialist?.email || null,
      show: !!specialist?.email,
    },
    {
      title: t('profile.startDate'),
      subtitle: specialist?.accessStart ? moment(specialist.accessStart).format('ll') : null,
      show: isDesktop && specialist?.accessStart,
    },
    {
      title: t('profile.finishDate'),
      subtitle: specialist?.accessEnd ? moment(specialist.accessEnd).format('ll') : null,
      show: isDesktop && specialist?.accessEnd,
    },
    {
      title: t('profile.workingPeriod'),
      subtitle:
        specialist?.accessEnd && specialist?.accessStart
          ? `${moment(specialist.accessStart).format('D MMMM YYYY')} - ${moment(specialist.accessEnd).format(
              'D MMMM YYYY',
            )}`
          : null,
      show: specialist?.accessStart && specialist?.accessEnd && !isDesktop,
    },
  ].filter(item => Boolean(item.show));

export const specialistTabs = (t: TFunction<'translation', undefined>) => [
  {
    title: t('specialists.generalData'),
    route: RouterUrl.EmployeeGeneralData,
  },
  {
    title: t('specialists.personalInfo.title'),
    route: RouterUrl.EmployeePersonalInfo,
  },
];
