import { appointmentsAPI } from '../../helpers/appointmentsAPI';
import {
  AppointmentDataType,
  CancelAllAppointmentParams,
  CloseAppointmentParams,
  ConvertGoogleParams,
  CreateAppointmentParams,
  FetchAppointmentProps,
} from '../../types/appointment';
import { createAppAsyncThunk } from '../create-app-async-thunk';

export const getAppointmentsData = createAppAsyncThunk<AppointmentDataType[], FetchAppointmentProps>(
  'appointments/getAppointmentsData',
  async (params, { rejectWithValue }) => appointmentsAPI.fetchAppointments({ params, rejectWithValue }),
);

export const createAppointment = createAppAsyncThunk<void, CreateAppointmentParams>(
  'appointments/createAppointment',
  async (params, thunkAPI) => appointmentsAPI.createAppointment({ params, thunkAPI }),
);

export const updateAppointment = createAppAsyncThunk<void, any>(
  'appointments/updateAppointment',
  async ({ id, params }, thunkAPI) => appointmentsAPI.updateAppointment({ id, params, thunkAPI }),
);

export const convertGoogle = createAppAsyncThunk<void, ConvertGoogleParams>(
  'appointments/convertGoogle',
  async (params, thunkAPI) => appointmentsAPI.convertGoogle({ params, thunkAPI }),
);

export const closeAppointment = createAppAsyncThunk<void, CloseAppointmentParams>(
  'appointments/convertGoogle',
  async (params, thunkAPI) => appointmentsAPI.closeAppointment({ params, thunkAPI }),
);

export const cancelAllAppointment = createAppAsyncThunk<void, CancelAllAppointmentParams>(
  'appointments/cancelAll',
  async (params, thunkAPI) => appointmentsAPI.cancelAllAppointment({ params, thunkAPI }),
);
