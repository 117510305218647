import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { generatePath, useParams } from 'react-router';
import { Outlet } from 'react-router-dom';
import { H4, Slider } from '@beauty/beauty-market-ui';
import { Loader, NavTag, Paper } from 'components';
import { isRtl } from 'helpers/utils';
import { useMediaScreen } from 'hooks';
import { useAppDispatch } from 'store/hooks';
import { ThunkSpecialist } from 'store/redux-slices/specialistSlice';
import { EmployeeCoverStack } from './components';
import { specialistTabs } from './helpers';
import { PageContainer } from './style';

export const EmployeePage = () => {
  const { t } = useTranslation();
  const { id } = useParams();
  const dispatch = useAppDispatch();

  const [isLoading, setIsLoading] = useState(true);

  const { isDesktop, isMobile } = useMediaScreen('md');
  const rtl = isRtl();

  const slides = specialistTabs(t).map(item => (
    <NavTag key={item.title} text={item.title} to={generatePath(item.route, { id: id || null })} />
  ));

  useEffect(() => {
    if (id) {
      Promise.all([
        dispatch(ThunkSpecialist.fetchSpecialist(id)),
        dispatch(ThunkSpecialist.fetchSpecialistInfo(id)),
      ]).finally(() => setIsLoading(false));
    }
  }, []);

  return isLoading ? (
    <Loader />
  ) : (
    <>
      <PageContainer>
        {isMobile && <EmployeeCoverStack />}
        <Paper
          p={isDesktop ? '24px' : '16px 8px'}
          borderRadius={isDesktop ? '10px 10px 0 0' : '10px'}
          flexDirection="column"
          gap="16px"
        >
          <H4>{t('specialists.personalCard')}</H4>
          <Slider inlineSlider slides={slides} marginBottom="0" rtl={rtl} />
        </Paper>
        <Outlet />
      </PageContainer>
      {isDesktop && <EmployeeCoverStack />}
    </>
  );
};
