import { useState } from 'react';
import { Stepper } from '@beauty/beauty-market-ui';

interface UseStepperProps {
  title: string | string[];
  count?: number;
  isReview?: boolean;
}

export const useStepper = ({ title, count, isReview = false }: UseStepperProps) => {
  const [currentStep, setCurrentStep] = useState(0);

  const handleReset = () => setCurrentStep(0);
  const handleFirstStep = () => setCurrentStep(1);
  const handleNextStep = () => setCurrentStep(currentStep + 1);
  const handleBackStep = () => setCurrentStep(currentStep - 1);

  const stepsTitle = Array.isArray(title) ? title[currentStep] : title;
  const stepsCount = count || (Array.isArray(title) ? title.length - 1 : 1);

  const StepperComponent = (
    <Stepper title={stepsTitle} stepsCount={stepsCount} currentStep={isReview ? currentStep + 1 : currentStep} />
  );
  return {
    currentStep,
    StepperComponent,
    handleNextStep,
    handleBackStep,
    handleFirstStep,
    handleReset,
  };
};
