import { useEffect, useState, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { Div } from '@beauty/beauty-market-ui';
import { emailVerification } from '../../helpers/emailVerification';
import { useGetSearchParams } from '../../routes/hooks/useGetSearchParams';
import { RouterUrl } from '../../routes/routes';

const EmailVerification = () => {
  const [isLinkWrong, setIsLinkWrong] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [email, setEmail] = useState<string>('');

  const navigate = useNavigate();
  const [searchParamsAsObject] = useGetSearchParams();

  useEffect(() => {
    let isVerificating = true;

    const verification = async () => {
      setIsLoading(true);
      const result = await emailVerification({
        token: searchParamsAsObject?.token,
      });

      if (isVerificating && result) {
        if (result.data.email) {
          setIsLoading(false);
          setEmail(result.data.email);
        } else {
          setIsLinkWrong(true);
        }
      }
    };

    if (searchParamsAsObject) verification();

    return () => {
      isVerificating = false;
    };
  }, [searchParamsAsObject]);

  const wrongLink = useCallback(() => {
    navigate(RouterUrl.SignUp, { state: { isWrongLink: true } });
  }, [navigate]);

  const emailVerified = useCallback(() => {
    navigate(RouterUrl.Registration, { state: { email } });
  }, [navigate, email]);

  return (
    <Div>
      {email && emailVerified()}
      {isLinkWrong && wrongLink()}
      {isLoading && <p>Please wait, email is verifing </p>}
    </Div>
  );
};

export default EmailVerification;
