import { useNavigate } from 'react-router-dom';
import { colors } from '@beauty/beauty-market-ui';
import { useMediaScreen } from 'hooks';
import { RouterUrl } from 'routes';
import { LogoWrapper, StyledCloseIcon, StyledLogo } from '../style';

export const MenuHeader = ({ isFull, onClose }) => {
  const navigate = useNavigate();
  const { isMobile } = useMediaScreen('md');

  const goCalendar = () => navigate(RouterUrl.Calendar);

  return (
    <LogoWrapper>
      <StyledLogo
        monochrome
        labelColor={colors.white.standard}
        logoColor={colors.blue.standard}
        isHideLabel={!isFull || isMobile}
        onClick={goCalendar}
      />
      {onClose && <StyledCloseIcon onClick={onClose} width={24} height={24} />}
    </LogoWrapper>
  );
};
