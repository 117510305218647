import { ChangeEvent, forwardRef, memo, useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Form, Formik, FormikProps } from 'formik';
import { parsePhoneNumber } from 'libphonenumber-js/max';
import { Flex, H6, InfoIcon, InstagramMonoIcon, TelegramMonoIcon, WhatsappMonoIcon } from '@beauty/beauty-market-ui';
import { getShortCountryByCode, prepareInstagramUserName, prepareTelegramUserName } from 'helpers';
import { useMediaScreen } from 'hooks';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { selectNewAddress, updateMainInformation } from 'store/redux-slices/addressSlice';
import { FormPropsType } from 'types';
import { FormikInput, FormikPhone } from '../../functional';
import { defaultAddressFields } from '../constants';
import { StyledIcon } from '../style';
import { EditAddressFieldsType } from '../types';
import {
  getInitialValues,
  MainInformationFormFields,
  MainInformationFormType,
  mainInformationValidationSchema,
} from './MainInformation.definitions';

type EditableFields = {
  fields?: EditAddressFieldsType['mainInformation'];
};

type MainInformationFormProps = FormPropsType & EditableFields;

export const MainInformationForm = memo(
  forwardRef(
    (
      { setIsValid, setIsDirty, editBar, fields = defaultAddressFields.mainInformation }: MainInformationFormProps,
      ref,
    ) => {
      const { t } = useTranslation();
      const { isDesktop } = useMediaScreen('md');
      const dispatch = useAppDispatch();
      const address = useAppSelector(selectNewAddress);
      const { Name, Email, Number, Instagram, Telegram, Code, WhatsAppNumber, WhatsAppCode } =
        MainInformationFormFields;

      const onFormSubmit = useCallback((data: MainInformationFormType) => {
        const { telegram, instagram, whatsAppCode, whatsAppNumber, number, code, ...rest } = data;
        const phone = code && number ? parsePhoneNumber(`${code}${number}`, getShortCountryByCode(code)) : null;
        const whatsappPhone =
          whatsAppCode && whatsAppNumber
            ? parsePhoneNumber(`${whatsAppCode}${whatsAppNumber}`, getShortCountryByCode(whatsAppCode))
            : null;
        dispatch(
          updateMainInformation({
            ...rest,
            code,
            number: phone?.nationalNumber ?? null,
            instagram: prepareInstagramUserName(instagram) || null,
            telegram: prepareTelegramUserName(telegram) || null,
            whatsapp: whatsappPhone ? `${whatsappPhone.countryCallingCode} ${whatsappPhone.nationalNumber}` : null,
          }),
        );
      }, []);

      const formikContextValue = {
        initialValues: getInitialValues(address),
        validationSchema: mainInformationValidationSchema(t),
        onSubmit: onFormSubmit,
        validateOnMount: false,
      };

      return (
        <Formik
          innerRef={ref as (instance: FormikProps<MainInformationFormType> | null) => void}
          {...formikContextValue}
        >
          {({ isValid, errors, values, handleChange, dirty, touched, setFieldValue }) => {
            let captionName = null;
            let captionEmail = null;
            let captionNumber = null;

            if (!editBar && isDesktop) {
              captionName = t('settings.addresses.sidebar.nameDisplayed');
              captionEmail = t('settings.addresses.sidebar.emailDisplayed');
              captionNumber = t('settings.addresses.sidebar.phoneDisplayed');
            }

            const onChangeInstagram = async (e: ChangeEvent<HTMLInputElement>) => {
              const { value } = e.target;
              if (value.charAt(0) === '@') {
                await setFieldValue(Instagram, value);
              } else if (value.charAt(0) === '') {
                await setFieldValue(Instagram, '@');
              }
            };

            useEffect(() => {
              setIsValid && setIsValid(isValid);
            }, [isValid]);

            useEffect(() => {
              setIsDirty && setIsDirty(dirty);
            }, [dirty]);

            return (
              <Form>
                <Flex flexDirection="column" gap="16px">
                  {editBar && <H6>{t('settings.addresses.sidebar.generalInformation')}</H6>}
                  {fields?.name && (
                    <FormikInput
                      id={Name}
                      name={Name}
                      width="100%"
                      design="white"
                      value={values[Name]}
                      onChange={handleChange}
                      captionIcon={<InfoIcon />}
                      placeholder={t('settings.addresses.sidebar.nameBranch')}
                      caption={touched[Name] && !!errors[Name] ? errors[Name] : captionName}
                      required
                    />
                  )}
                  {editBar && <H6>{t('settings.addresses.sidebar.contactInformation')}</H6>}
                  {fields?.number && (
                    <FormikPhone
                      phoneId={Number}
                      codeId={Code}
                      placeholder={t('settings.addresses.sidebar.number')}
                      caption={captionNumber}
                      captionIcon={<InfoIcon />}
                      required
                    />
                  )}
                  {fields?.email && (
                    <FormikInput
                      id={Email}
                      name={Email}
                      width="100%"
                      design="white"
                      value={values[Email]}
                      onChange={handleChange}
                      captionIcon={<InfoIcon />}
                      placeholder={t('settings.addresses.sidebar.email')}
                      caption={touched[Email] && !!errors[Email] ? errors[Email] : captionEmail}
                      required
                    />
                  )}
                  {fields?.instagram && (
                    <FormikInput
                      id={Instagram}
                      name={Instagram}
                      width="100%"
                      design="white"
                      value={values[Instagram]}
                      onChange={onChangeInstagram}
                      placeholder={t('settings.addresses.sidebar.instagram')}
                      caption={touched[Instagram] && errors[Instagram]}
                      iconRight={
                        <StyledIcon>
                          <InstagramMonoIcon />
                        </StyledIcon>
                      }
                    />
                  )}
                  {fields?.telegram && (
                    <FormikInput
                      id={Telegram}
                      name={Telegram}
                      width="100%"
                      design="white"
                      value={values[Telegram]}
                      onChange={e => setFieldValue(Telegram, e.target.value)}
                      placeholder={t('settings.addresses.sidebar.telegram')}
                      caption={touched[Telegram] && errors[Telegram]}
                      iconRight={
                        <StyledIcon>
                          <TelegramMonoIcon />
                        </StyledIcon>
                      }
                    />
                  )}
                  {fields?.whatsapp && (
                    <FormikPhone
                      phoneId={WhatsAppNumber}
                      codeId={WhatsAppCode}
                      placeholder={t('settings.addresses.sidebar.whatsapp')}
                      iconRight={
                        <StyledIcon>
                          <WhatsappMonoIcon />
                        </StyledIcon>
                      }
                    />
                  )}
                </Flex>
              </Form>
            );
          }}
        </Formik>
      );
    },
  ),
);
