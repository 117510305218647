import { useEffect, useState } from 'react';
import { getMinWidthMediaQuery, useMediaQuery } from '@beauty/beauty-market-ui';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { ThunkAddress } from 'store/redux-slices/addressSlice';
import { selectHead, ThunkOrganisation } from 'store/redux-slices/organisationSlice';
import { ThunkService } from 'store/redux-slices/serviceSlice';
import { ThunkSpecialist } from 'store/redux-slices/specialistSlice';
import { selectUserHead } from 'store/redux-slices/userSlice';
import HeadOrganisationSettingsSidebar from './HeadOrganisationSettings/HeadOrganisationSettingsSidebar';
import OrganisationCard from './OrganisationCard/OrganisationCard';
import { AddressSection, WelcomeSection, ServiceSection, SpecialistsSection } from './OrganisationInfo';
import { OrganisationInfoContainer } from './OrganisationInfo/style';

export const AccountSetting = () => {
  const userHead = useAppSelector(selectUserHead);
  const headOrg = useAppSelector(selectHead);

  const dispatch = useAppDispatch();

  const [isSidebarOpen, setIsSidebarOpen] = useState(false);

  const mediaQuery = getMinWidthMediaQuery('md');
  const isDesktop = useMediaQuery(mediaQuery);

  const isCompanyExist = headOrg && userHead;

  const handleClose = () => setIsSidebarOpen(false);

  useEffect(() => {
    if (userHead) {
      const orgId = userHead.headOrgId;
      dispatch(ThunkOrganisation.fetchHead(orgId));
      dispatch(ThunkSpecialist.fetchAllSpecialists(orgId));
      dispatch(ThunkAddress.fetchAllAddresses(orgId));
      dispatch(ThunkService.fetchAllServices(orgId));
      dispatch(ThunkService.fetchAllCategory(orgId));
    } else {
      setIsSidebarOpen(true);
    }
  }, [userHead]);

  return (
    <>
      <OrganisationInfoContainer>
        {isCompanyExist && !isDesktop && <OrganisationCard />}
        <WelcomeSection />
        <AddressSection />
        <SpecialistsSection />
        <ServiceSection />
      </OrganisationInfoContainer>
      {isCompanyExist && isDesktop && <OrganisationCard />}
      {isSidebarOpen && <HeadOrganisationSettingsSidebar onClose={handleClose} />}
    </>
  );
};
