import { TFunction } from 'react-i18next';
import * as Yup from 'yup';
import {
  BELARUS_POSTAL_CODE_REGEXP,
  DIGITS_ONLY_REGEXP,
  ISRAEL_POSTAL_CODE_REGEXP,
  OTHER_POSTAL_CODE_REGEXP,
  POLAND_POSTAL_CODE_REGEXP,
  RUSSIA_POSTAL_CODE_REGEXP,
  UK_POSTAL_CODE_REGEXP,
  UKRAINE_POSTAL_CODE_REGEXP,
  USA_POSTAL_CODE_REGEXP,
} from '../../../constants';

export enum DetailedInformationFormFields {
  Country = 'country',
  City = 'city',
  Street = 'street',
  Building = 'building',
  Office = 'office',
  Post = 'postal',
  Description = 'description',
}

export type DetailedInformationFormType = {
  [DetailedInformationFormFields.Country]: string;
  [DetailedInformationFormFields.City]: string;
  [DetailedInformationFormFields.Street]: string;
  [DetailedInformationFormFields.Building]: string;
  [DetailedInformationFormFields.Office]: string | null;
  [DetailedInformationFormFields.Post]: string | null;
  [DetailedInformationFormFields.Description]: string | null;
};

export const detailedInformationValidationSchema = (t: TFunction<'translation', undefined>) =>
  Yup.object({
    [DetailedInformationFormFields.Country]: Yup.string()
      .trim()
      .min(2, t(`validation.minCharacters.few`, { count: 2 }))
      .max(50, t(`validation.maxCharacters.many`, { count: 50 }))
      .required(t('validation.notEmpty')),
    [DetailedInformationFormFields.City]: Yup.string()
      .trim()
      .min(2, t(`validation.minCharacters.few`, { count: 2 }))
      .max(50, t(`validation.maxCharacters.many`, { count: 50 }))
      .required(t('validation.notEmpty')),
    [DetailedInformationFormFields.Street]: Yup.string()
      .trim()
      .min(2, t(`validation.minCharacters.few`, { count: 2 }))
      .max(50, t(`validation.maxCharacters.few`, { count: 50 }))
      .required(t('validation.notEmpty')),
    [DetailedInformationFormFields.Building]: Yup.string()
      .trim()
      .min(1, t(`validation.minCharacters.few`, { count: 1 }))
      .max(4, t(`validation.maxCharacters.many`, { count: 4 }))
      .matches(DIGITS_ONLY_REGEXP, t('validation.onlyDigits'))
      .required(t('validation.notEmpty')),
    [DetailedInformationFormFields.Post]: Yup.string()
      .trim()
      .min(2, t(`validation.minCharacters.few`, { count: 2 }))
      .max(20, t(`validation.maxCharacters.many`, { count: 20 }))
      .test('valid-postal-code', t('validation.incorrectPostalCode'), value => {
        if (!value) return true;
        return (
          RUSSIA_POSTAL_CODE_REGEXP.test(value) ||
          USA_POSTAL_CODE_REGEXP.test(value) ||
          POLAND_POSTAL_CODE_REGEXP.test(value) ||
          UK_POSTAL_CODE_REGEXP.test(value) ||
          OTHER_POSTAL_CODE_REGEXP.test(value) ||
          ISRAEL_POSTAL_CODE_REGEXP.test(value) ||
          UKRAINE_POSTAL_CODE_REGEXP.test(value) ||
          BELARUS_POSTAL_CODE_REGEXP.test(value) ||
          DIGITS_ONLY_REGEXP.test(value)
        );
      }),
    [DetailedInformationFormFields.Office]: Yup.string()
      .trim()
      .min(1, t(`validation.minCharacters.one`, { count: 1 }))
      .max(4, t(`validation.maxCharacters.few`, { count: 4 }))
      .matches(DIGITS_ONLY_REGEXP, t('validation.onlyDigits')),
    [DetailedInformationFormFields.Description]: Yup.string()
      .trim()
      .min(2, t(`validation.minCharacters.few`, { count: 2 }))
      .max(150, t(`validation.maxCharacters.many`, { count: 150 })),
  });
