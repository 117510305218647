import styled from 'styled-components';
import { Flex, mediaQueries } from '@beauty/beauty-market-ui';

export const Footer = styled(Flex)`
  flex-direction: column-reverse;
  width: 100%;
  gap: 8px;

  ${mediaQueries.md} {
    flex-direction: row;
  }
`;
