import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { isEmpty } from 'lodash';
import { Flex, H6 } from '@beauty/beauty-market-ui';
import { PaymentCardType } from 'types';
import { useAppSelector } from '../../../store/hooks';
import { selectPaymentCards } from '../../../store/redux-slices/organisationSlice';
import { StyledText } from '../style';
import { CardItem } from './CardItem';
import { CardList } from './CardList';
import { CardStub } from './CardStub';

export const PaymentMethods = ({ value, onChange, stub = false, visibleCount = 3 }) => {
  const { t } = useTranslation();
  const paymentCards = useAppSelector(selectPaymentCards);

  const [cards, setCards] = useState<PaymentCardType[]>(paymentCards.slice(0, visibleCount));
  const [isCardListOpen, setCardListOpen] = useState(false);

  const handleSelectCard = useCallback(
    (card: PaymentCardType | null) => {
      if (card) {
        const hasCard = cards.find(c => c.id === card.id);
        if (!hasCard) setCards([card, ...cards].slice(0, 3));
      }
      onChange(card);
    },
    [cards, onChange],
  );

  return (
    <Flex flexDirection="column" gap="8px">
      <Flex justifyContent="space-between" alignItems="center" mb="8px">
        <H6>{t('subscriptions.paymentMethods')}</H6>
        {!isEmpty(paymentCards) && (
          <StyledText onClick={() => setCardListOpen(true)}>{t('subscriptions.allCards')}</StyledText>
        )}
      </Flex>
      {cards.map(card => (
        <CardItem key={card.id} card={card} checked={value?.id === card.id} onChange={handleSelectCard} />
      ))}
      {stub && <CardStub checked={value === null} onChange={() => onChange(null)} />}
      {isCardListOpen && (
        <CardList defaultValue={value} onSubmit={handleSelectCard} onClose={() => setCardListOpen(false)} />
      )}
    </Flex>
  );
};
