import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { BodySmall, Flex, H6 } from '@beauty/beauty-market-ui';
import { useRequest } from 'hooks';
import { ThunkCommon } from 'store';
import { SidebarFooter, SidebarSheet } from '../../../../components';
import { useAppSelector } from '../../../../store/hooks';
import { selectActiveSubscription, selectEmployeeNum } from '../../../../store/redux-slices/organisationSlice';
import { OrganizationAction } from '../../../../types';

export const ActivateBar = ({ specId, onClose }) => {
  const { t } = useTranslation();
  const subscription = useAppSelector(selectActiveSubscription);
  const employeeNum = useAppSelector(selectEmployeeNum);

  const activateSpecialist = useRequest(ThunkCommon.activateSpecialist, OrganizationAction.ActivateSpecialist);

  const [isSubmitting, setSubmitting] = useState(false);

  const handleSubmit = () => {
    setSubmitting(true);
    activateSpecialist({ specId })
      .then(() => onClose())
      .finally(() => setSubmitting(false));
  };

  return (
    <SidebarSheet
      onClose={onClose}
      label={t('specialists.addNewSpecialist')}
      descriptor={t('specialists.updateInformationAbout')}
      FooterBody={
        <SidebarFooter
          disabled={!specId}
          onSubmit={handleSubmit}
          onBack={onClose}
          isLoading={isSubmitting}
          confirmationLabel={t('specialists.activateSpecialist')}
          cancelLabel={t('specialists.later')}
        />
      }
    >
      <Flex flexDirection="column" gap="16px" mb="80px">
        <H6>
          {t('specialists.youHavePaidPlaces', {
            value: subscription ? subscription.employeeCount - employeeNum : 0,
          })}
        </H6>
        <BodySmall>{t('specialists.doYouWantToActivate')}</BodySmall>
      </Flex>
    </SidebarSheet>
  );
};
