import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Flex, H4 } from '@beauty/beauty-market-ui';
import { Paper } from 'components';
import { useMediaScreen } from 'hooks';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { selectUser, ThunkUser } from 'store/redux-slices/userSlice';
import {
  ChangePassword,
  DeleteAccount,
  GoogleCalendarSync,
  NotificationLanguage,
  Telegram,
  ProfileCard,
} from './components';

export const PersonalProfile = () => {
  const { t } = useTranslation();
  const { isDesktop, isMobile } = useMediaScreen('md');
  const { user } = useAppSelector(selectUser);

  const dispatch = useAppDispatch();

  useEffect(() => {
    if (user.userId) dispatch(ThunkUser.getProfile(user.userId));
  }, []);

  return (
    <>
      <Flex width="100%" flexDirection="column" gap="1px" mb="80px">
        {isMobile && <ProfileCard />}
        <Paper borderRadius={isDesktop ? '10px 10px 0 0' : '10px'} p={isDesktop ? '24px' : '16px 8px'}>
          <H4>{t('profile.personalProfile')}</H4>
        </Paper>
        <ChangePassword />
        <GoogleCalendarSync />
        <Telegram />
        <NotificationLanguage />
        <DeleteAccount />
      </Flex>
      {isDesktop && <ProfileCard />}
    </>
  );
};
