import { useTranslation } from 'react-i18next';
import { Section } from '@beauty/beauty-market-ui';
import { Loader } from 'components';
import { useGetPaymentFor } from 'hooks';
import { OrganizationAction, SubscriptionPricePlanType } from 'types';
import { useAppSelector } from '../../../store/hooks';
import { selectActiveSubscription } from '../../../store/redux-slices/organisationSlice';
import { PageContainer } from '../style';
import { Telegram } from './Telegram';
import { WhatsApp } from './WhatsApp';

export const NotificationPage = () => {
  const { t } = useTranslation();
  const subscription = useAppSelector(selectActiveSubscription);

  const { isLoading: isWaitingForTelegram } = useGetPaymentFor(
    !!subscription?.telegram,
    OrganizationAction.PayForTelegram,
    SubscriptionPricePlanType.TELEGRAM,
  );
  const { isLoading: isWaitingForWhatsApp } = useGetPaymentFor(
    !!subscription?.whatsApp,
    OrganizationAction.PayForWhatsApp,
    SubscriptionPricePlanType.WHATSAPP,
  );

  return isWaitingForTelegram || isWaitingForWhatsApp ? (
    <Loader />
  ) : (
    <PageContainer>
      <Section title={`${t('profile.notificationSettings')}`} items={[]} button={null} />
      <WhatsApp />
      <Telegram />
    </PageContainer>
  );
};
