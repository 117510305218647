import { TFunction } from 'react-i18next';
import * as Yup from 'yup';
import { getCurrencyTemplate, priceValidation } from 'helpers';
import {
  compareAppointmentEndAndNow,
  compareAppointmentEndAndStart,
  compareAppointmentStartAndEnd,
  compareAppointmentStartAndNow,
} from '../helpers';

interface InitialValuesProps {
  date: string;
  statusIndex: number;
  notes: string;
  price: string;
  addressId?: string;
  clientId?: string;
  serviceId?: string;
  specialistId?: string;
  startTime?: string;
  endTime?: string;
}

export enum AppointmentFormFields {
  Status = 'status',
  Address = 'address',
  Client = 'client',
  Service = 'service',
  Specialist = 'specialist',
  Start = 'start',
  End = 'end',
  AppointmentDate = 'date',
  Notes = 'notes',
  Price = 'price',
}

export type AppointmentForm = {
  [AppointmentFormFields.Status]: number;
  [AppointmentFormFields.Address]: string;
  [AppointmentFormFields.Client]: string;
  [AppointmentFormFields.Service]: string;
  [AppointmentFormFields.Specialist]: string;
  [AppointmentFormFields.Start]: string;
  [AppointmentFormFields.End]: string;
  [AppointmentFormFields.AppointmentDate]: string;
  [AppointmentFormFields.Notes]: string;
  [AppointmentFormFields.Price]: string;
};

export const getInitialValues = ({
  date,
  addressId,
  serviceId,
  specialistId,
  startTime,
  endTime,
  clientId,
  statusIndex,
  notes,
  price,
}: InitialValuesProps) => ({
  [AppointmentFormFields.Address]: addressId || '',
  [AppointmentFormFields.Client]: clientId || '',
  [AppointmentFormFields.Service]: serviceId || '',
  [AppointmentFormFields.Specialist]: specialistId || '',
  [AppointmentFormFields.Start]: startTime || '',
  [AppointmentFormFields.End]: endTime || '',
  [AppointmentFormFields.AppointmentDate]: date,
  [AppointmentFormFields.Status]: statusIndex,
  [AppointmentFormFields.Notes]: notes,
  [AppointmentFormFields.Price]: price,
});

export const schema = (
  t: TFunction<'translation', undefined>,
  currencySign: string,
  skipCheckCurrentTime?: boolean,
) => {
  const template = getCurrencyTemplate(currencySign);

  return Yup.object({
    [AppointmentFormFields.Client]: Yup.string().trim().required(t('validation.notEmpty')),
    [AppointmentFormFields.Service]: Yup.string().trim().required(t('validation.notEmpty')),
    [AppointmentFormFields.Specialist]: Yup.string().trim().required(t('validation.notEmpty')),
    [AppointmentFormFields.Start]: skipCheckCurrentTime
      ? Yup.string()
          .required(t('validation.notEmpty'))
          .test('is-start-more-than-end', t('validation.irrelevantTime'), compareAppointmentStartAndEnd)
      : Yup.string()
          .required(t('validation.notEmpty'))
          .test('is-start-more-than-current', t('validation.irrelevantTime'), compareAppointmentStartAndNow)
          .test('is-start-more-than-end', t('validation.irrelevantTime'), compareAppointmentStartAndEnd),
    [AppointmentFormFields.End]: skipCheckCurrentTime
      ? Yup.string()
          .required(t('validation.notEmpty'))
          .test('is-start-more-than-end', t('validation.irrelevantTime'), compareAppointmentEndAndStart)
      : Yup.string()
          .required(t('validation.notEmpty'))
          .test('is-end-more-than-current', t('validation.irrelevantTime'), compareAppointmentEndAndNow)
          .test('is-start-more-than-end', t('validation.irrelevantTime'), compareAppointmentEndAndStart),
    [AppointmentFormFields.Notes]: Yup.string().max(1000, t('validation.maxLength1000')),
    [AppointmentFormFields.Price]: priceValidation(template, t),
  });
};
