import { useCallback, useEffect, useLayoutEffect, useState } from 'react';
import { Views } from 'react-big-calendar';
import { Paginator } from '../../components/Calendar/Pagination/Paginator';
import { ResourceMapType } from '../../types';

const defaultCalendarWidth = 1194;
const minColumnWidth = 300;

export const useDayHeader = (selectedView: Views, resourceMap: ResourceMapType[]) => {
  const [activePage, setActivePage] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [resources, setResources] = useState<ResourceMapType[]>([]);

  const calculateValues = useCallback(() => {
    const dayHeader = document.querySelector('.rbc-time-header');
    const timeHeader = document.querySelector('.rbc-label');

    const dayHeaderWidth =
      dayHeader && timeHeader ? dayHeader.clientWidth - timeHeader.clientWidth : defaultCalendarWidth;

    const specPerPage = Math.ceil(dayHeaderWidth / minColumnWidth);
    const pages = Math.ceil(resourceMap.length / specPerPage);
    const resource = resourceMap.slice(activePage * specPerPage, activePage * specPerPage + specPerPage);
    !resource.length && setActivePage(0);
    setResources(resource);
    setTotalPages(pages);
  }, [activePage, selectedView, resourceMap]);

  useLayoutEffect(calculateValues, [activePage, resourceMap, selectedView]);

  useEffect(() => {
    if (selectedView === Views.DAY || selectedView === Views.AGENDA) {
      window.addEventListener('resize', calculateValues);
    } else {
      window.removeEventListener('resize', calculateValues);
    }
    return () => {
      window.removeEventListener('resize', calculateValues);
    };
  }, [selectedView, calculateValues]);

  const DayHeader = () =>
    selectedView === Views.DAY && totalPages > 1 ? (
      <Paginator activePage={activePage} setActivePage={setActivePage} totalPages={totalPages} />
    ) : null;

  return {
    resources,
    DayHeader,
  };
};
