import { TFunction } from 'react-i18next';
import { isValidPhoneNumber } from 'libphonenumber-js/max';
import moment from 'moment';
import { Nullable } from 'tsdef';
import * as Yup from 'yup';
import { GenderType, ImageType } from 'types/general';
import { Client } from 'types/organisation';
import { dateReg, emailReg } from '../../../../constants';
import { getShortCountryByCode } from '../../../../helpers';

export enum ClientFormFields {
  Image = 'avatar',
  Name = 'name',
  Surname = 'surname',
  DateOfBirth = 'dateOfBirth',
  Gender = 'gender',
  IDNumber = 'idNumber',
  Code = 'code',
  Number = 'number',
  AdditionalCode = 'addCode',
  AdditionalNumber = 'addNumber',
  Email = 'email',
  AdditionalInfo = 'info',
  ShowAdditionalInfo = 'showAdditionalInfo',
  AppointmentNotifications = 'appointmentNotifications',
  DiscountNotifications = 'discountNotifications',
  MarketingNotifications = 'marketingNotifications',
}

export type ClientForm = {
  [ClientFormFields.Image]?: ImageType | null;
  [ClientFormFields.Name]: string;
  [ClientFormFields.Surname]: string;
  [ClientFormFields.DateOfBirth]?: string;
  [ClientFormFields.Gender]?: GenderType;
  [ClientFormFields.IDNumber]?: string | null;
  [ClientFormFields.Code]: string;
  [ClientFormFields.Number]: string;
  [ClientFormFields.AdditionalCode]?: Nullable<string>;
  [ClientFormFields.AdditionalNumber]?: Nullable<string>;
  [ClientFormFields.Email]?: string;
  [ClientFormFields.AdditionalInfo]?: string;
  [ClientFormFields.ShowAdditionalInfo]?: boolean;
  [ClientFormFields.AppointmentNotifications]?: boolean;
  [ClientFormFields.DiscountNotifications]?: boolean;
  [ClientFormFields.MarketingNotifications]?: boolean;
  deleteAvatar?: boolean;
};

export const getInitialValues = (client: Client): ClientForm => ({
  [ClientFormFields.Image]: client.avatar,
  [ClientFormFields.Name]: client.name || '',
  [ClientFormFields.Surname]: client.surname,
  [ClientFormFields.DateOfBirth]: client.dateOfBirth ? moment(client.dateOfBirth).utc().format('DD-MM-YYYY') : '',
  [ClientFormFields.Gender]: client.gender,
  [ClientFormFields.IDNumber]: client.idNumber || '',
  [ClientFormFields.Code]: client.code || '+972',
  [ClientFormFields.Number]: client.number,
  [ClientFormFields.AdditionalCode]: client.addCode,
  [ClientFormFields.AdditionalNumber]: client.addNumber,
  [ClientFormFields.Email]: client.email,
  [ClientFormFields.AdditionalInfo]: client.info,
  [ClientFormFields.ShowAdditionalInfo]: client.showAdditionalInfo,
  [ClientFormFields.AppointmentNotifications]: client.appointmentNotifications,
  [ClientFormFields.DiscountNotifications]: client.discountNotifications,
  [ClientFormFields.MarketingNotifications]: client.marketingNotifications,
});

export const clientsSectionValidationSchema = (t: TFunction<'translation', undefined>, isAddClient: boolean) =>
  Yup.object({
    [ClientFormFields.Name]: Yup.string()
      .trim()
      .min(2, t('validation.minName'))
      .max(50, t('validation.maxName'))
      .required(t('validation.notEmpty')),
    [ClientFormFields.Surname]: Yup.string()
      .trim()
      .min(2, t('validation.minName'))
      .max(50, t('validation.maxName'))
      .required(t('validation.notEmpty')),
    [ClientFormFields.DateOfBirth]: Yup.string().matches(dateReg, t('validation.correctDate')),
    [ClientFormFields.IDNumber]: Yup.string().trim().min(2, t('validation.minName')).max(50, t('validation.maxName')),
    /* [ClientFormFields.IDNumber]: Yup.string().test(
      'is-valid',
      t('validation.idNumber'),
      function isIdNumberValid(idNumber) {
        const code = this.parent[ClientFormFields.Code];
        switch (code) {
          case '+972': {
            return !!(idNumber?.match(/^[0-9]{9}$/) || !idNumber);
          }
          default:
            return true;
        }
      },
    ), */
    [ClientFormFields.Number]: Yup.string()
      .trim()
      .test('validate-code', t('validation.codeRequired'), function validateCode(phone) {
        if (phone) {
          return !!this.parent[ClientFormFields.Code];
        }
        return isAddClient;
      })
      .test('validate-phone', t('validation.enterValidPhone'), function validatePhone(phone) {
        const code = this.parent[ClientFormFields.Code];
        if (code) {
          return isValidPhoneNumber(`${code} ${phone}`, getShortCountryByCode(code));
        }
        return isAddClient;
      }),
    [ClientFormFields.AdditionalNumber]: Yup.string()
      .nullable()
      .trim()
      .test('validate-code', t('validation.codeRequired'), function validateCode(phone) {
        if (phone) {
          return !!this.parent[ClientFormFields.AdditionalCode];
        }
        return true;
      })
      .test('validate-phone', t('validation.enterValidPhone'), function validatePhone(phone) {
        const code = this.parent[ClientFormFields.AdditionalCode];
        if (code && phone) {
          return isValidPhoneNumber(`${code} ${phone}`, getShortCountryByCode(code));
        }
        return true;
      }),
    [ClientFormFields.Email]: Yup.string().nullable().trim().matches(emailReg, t('validation.emailNotValid')),
    [ClientFormFields.AdditionalInfo]: Yup.string()
      .nullable()
      .trim()
      .min(1, t('validation.minLength1'))
      .max(1000, t('validation.maxLength1000')),
  });
