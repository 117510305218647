import { Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import { capitalize } from 'lodash';
import { BodyLarge, BodySmall, colors, H7, Icon, Section, Separator } from '@beauty/beauty-market-ui';
import { getTableData } from '../helpers';
import { Row, StyledCheckedIcon } from '../style';

export const TariffTable = () => {
  const { t } = useTranslation();
  const tableData = getTableData(t);

  return (
    <Section title={t('subscriptions.compareFeatures')} button={null}>
      <Row mt="24px">
        {tableData.tableHead.map((head, headIndex) => (
          <H7 key={`${head}-${headIndex}`}>{capitalize(head)}</H7>
        ))}
      </Row>
      <Separator />
      {tableData.tableRows.map((row, rowIndex) => (
        <Fragment key={`${row.label}-${rowIndex}`}>
          <Row>
            <BodyLarge>{row.label}</BodyLarge>
            {row.values.map((value, columnIndex) => {
              const key = `${row.label}-${columnIndex}`;
              if (typeof value === 'string')
                return (
                  <BodySmall key={key} lowline>
                    {value}
                  </BodySmall>
                );
              return value ? <StyledCheckedIcon key={key} color={colors.blue.standard} /> : <Icon key={key} />;
            })}
          </Row>
          {rowIndex !== tableData.tableRows.length - 1 && <Separator />}
        </Fragment>
      ))}
    </Section>
  );
};
