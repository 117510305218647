import { TFunction } from 'react-i18next';
import { NotifyPropsType, NotifyStatus } from '@beauty/beauty-market-ui';
import { SpecialistAction } from '../../types';

const successTitles = {
  [SpecialistAction.Add]: 'specialists.alerts.title.addSpecialistSuccess',
  [SpecialistAction.Edit]: 'specialists.alerts.title.editSpecialistSuccess',
  [SpecialistAction.PublishOn]: 'specialists.alerts.title.publishOnSuccess',
  [SpecialistAction.PublishOff]: 'specialists.alerts.title.publishOffSuccess',
  [SpecialistAction.EditWorkplace]: 'specialists.alerts.title.editWorkplaceSuccess',
  [SpecialistAction.EditServices]: 'specialists.alerts.title.editServicesSuccess',
  [SpecialistAction.DeleteWorkplace]: 'specialists.alerts.title.deleteWorkplaceSuccess',
  [SpecialistAction.DeleteService]: 'specialists.alerts.title.deleteServiceSuccess',
  [SpecialistAction.EditSchedule]: 'specialists.alerts.title.editScheduleSuccess',
  [SpecialistAction.AddBreak]: 'specialists.alerts.title.addBreakSuccess',
  [SpecialistAction.EditBreak]: 'specialists.alerts.title.editBreakSuccess',
  [SpecialistAction.DeleteBreak]: 'specialists.alerts.title.deleteBreakSuccess',
  [SpecialistAction.EditAbout]: 'alerts.success',
  [SpecialistAction.AddEducation]: 'alerts.success',
  [SpecialistAction.EditEducation]: 'alerts.success',
  [SpecialistAction.DeleteEducation]: 'alerts.success',
  [SpecialistAction.AddLanguage]: 'alerts.success',
  [SpecialistAction.EditLanguage]: 'alerts.success',
  [SpecialistAction.DeleteLanguage]: 'alerts.success',
  [SpecialistAction.EditPhotos]: 'alerts.success',
};

const errorTitles = {
  [SpecialistAction.Add]: 'specialists.alerts.title.addSpecialistError',
  [SpecialistAction.Edit]: 'specialists.alerts.title.editSpecialistError',
  [SpecialistAction.PublishOn]: 'specialists.alerts.title.publishOnError',
  [SpecialistAction.PublishOff]: 'specialists.alerts.title.publishOffError',
  [SpecialistAction.EditWorkplace]: 'specialists.alerts.title.editWorkplaceError',
  [SpecialistAction.EditServices]: 'specialists.alerts.title.editServicesError',
  [SpecialistAction.DeleteWorkplace]: 'specialists.alerts.title.deleteWorkplaceError',
  [SpecialistAction.DeleteService]: 'specialists.alerts.title.deleteServiceError',
  [SpecialistAction.EditSchedule]: 'specialists.alerts.title.editScheduleError',
  [SpecialistAction.AddBreak]: 'specialists.alerts.title.addBreakError',
  [SpecialistAction.EditBreak]: 'specialists.alerts.title.editBreakError',
  [SpecialistAction.DeleteBreak]: 'specialists.alerts.title.deleteBreakError',
  [SpecialistAction.EditAbout]: 'alerts.error',
  [SpecialistAction.AddEducation]: 'alerts.error',
  [SpecialistAction.EditEducation]: 'alerts.error',
  [SpecialistAction.DeleteEducation]: 'alerts.error',
  [SpecialistAction.AddLanguage]: 'alerts.error',
  [SpecialistAction.EditLanguage]: 'alerts.error',
  [SpecialistAction.DeleteLanguage]: 'alerts.error',
  [SpecialistAction.EditPhotos]: 'alerts.error',
};

const successSubtitles = {
  [SpecialistAction.Add]: 'specialists.alerts.subtitle.addSpecialistSuccess',
  [SpecialistAction.Edit]: 'specialists.alerts.subtitle.editSpecialistSuccess',
  [SpecialistAction.PublishOn]: 'specialists.alerts.subtitle.publishOnSuccess',
  [SpecialistAction.PublishOff]: 'specialists.alerts.subtitle.publishOffSuccess',
  [SpecialistAction.EditWorkplace]: 'specialists.alerts.subtitle.editWorkplaceSuccess',
  [SpecialistAction.EditServices]: 'specialists.alerts.subtitle.editServicesSuccess',
  [SpecialistAction.DeleteWorkplace]: 'specialists.alerts.subtitle.deleteWorkplaceSuccess',
  [SpecialistAction.DeleteService]: 'specialists.alerts.subtitle.deleteServiceSuccess',
  [SpecialistAction.EditSchedule]: 'specialists.alerts.subtitle.editScheduleSuccess',
  [SpecialistAction.AddBreak]: 'specialists.alerts.subtitle.addBreakSuccess',
  [SpecialistAction.EditBreak]: 'specialists.alerts.subtitle.editBreakSuccess',
  [SpecialistAction.DeleteBreak]: 'specialists.alerts.subtitle.deleteBreakSuccess',
  [SpecialistAction.EditAbout]: 'specialists.alerts.subtitle.editAboutSuccess',
  [SpecialistAction.AddEducation]: 'specialists.alerts.subtitle.editEducationSuccess',
  [SpecialistAction.EditEducation]: 'specialists.alerts.subtitle.editEducationSuccess',
  [SpecialistAction.DeleteEducation]: 'specialists.alerts.subtitle.editEducationSuccess',
  [SpecialistAction.AddLanguage]: 'specialists.alerts.subtitle.editLanguageSuccess',
  [SpecialistAction.EditLanguage]: 'specialists.alerts.subtitle.editLanguageSuccess',
  [SpecialistAction.DeleteLanguage]: 'specialists.alerts.subtitle.editLanguageSuccess',
  [SpecialistAction.EditPhotos]: 'specialists.alerts.subtitle.editPhotosSuccess',
};

const errorSubtitles = {
  [SpecialistAction.Add]: 'specialists.alerts.subtitle.addSpecialistError',
  [SpecialistAction.Edit]: 'specialists.alerts.subtitle.editSpecialistError',
  [SpecialistAction.PublishOn]: 'specialists.alerts.subtitle.publishOnError',
  [SpecialistAction.PublishOff]: 'specialists.alerts.subtitle.publishOffError',
  [SpecialistAction.EditWorkplace]: 'specialists.alerts.subtitle.editWorkplaceError',
  [SpecialistAction.EditServices]: 'specialists.alerts.subtitle.editServicesError',
  [SpecialistAction.DeleteWorkplace]: 'specialists.alerts.subtitle.deleteWorkplaceError',
  [SpecialistAction.DeleteService]: 'specialists.alerts.subtitle.deleteServiceError',
  [SpecialistAction.EditSchedule]: 'specialists.alerts.subtitle.editScheduleError',
  [SpecialistAction.AddBreak]: 'specialists.alerts.subtitle.addBreakError',
  [SpecialistAction.EditBreak]: 'specialists.alerts.subtitle.editBreakError',
  [SpecialistAction.DeleteBreak]: 'specialists.alerts.subtitle.deleteBreakError',
  [SpecialistAction.EditAbout]: 'specialists.alerts.subtitle.editAboutError',
  [SpecialistAction.AddEducation]: 'specialists.alerts.subtitle.editEducationError',
  [SpecialistAction.EditEducation]: 'specialists.alerts.subtitle.editEducationError',
  [SpecialistAction.DeleteEducation]: 'specialists.alerts.subtitle.editEducationError',
  [SpecialistAction.AddLanguage]: 'specialists.alerts.subtitle.editLanguageError',
  [SpecialistAction.EditLanguage]: 'specialists.alerts.subtitle.editLanguageError',
  [SpecialistAction.DeleteLanguage]: 'specialists.alerts.subtitle.editLanguageError',
  [SpecialistAction.EditPhotos]: 'specialists.alerts.subtitle.editPhotosError',
};

export const getSpecialistNotifyContent = (
  status: NotifyStatus,
  action: SpecialistAction,
  t: TFunction<'translation', undefined>,
  message?: string,
): NotifyPropsType => {
  const title = status === NotifyStatus.SUCCESS ? successTitles[action] : errorTitles[action];
  const subtitle = status === NotifyStatus.SUCCESS ? successSubtitles[action] : errorSubtitles[action];

  return {
    id: action,
    status,
    title: t(title),
    subtitle: message || t(subtitle),
  };
};
