import { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useClickOutside, Flex, BodySmall, PlusCircleIcon, CrossIcon } from '@beauty/beauty-market-ui';
import { KebabBody } from './KebabBody';

type DayKebabMenuProps = {
  onAddBreak: (isBreak: boolean) => void;
  onAddSpecialistBreak: (id: string) => void;
  onSelectDate: (date: string) => void;
  onCancelSpecialistAll: (orgSpecId: string) => void;
  onCancelAll: () => void;
  specId?: string;
  date: string;
};

export const DayKebabMenu = ({
  onAddBreak,
  onAddSpecialistBreak,
  onSelectDate,
  onCancelSpecialistAll,
  onCancelAll,
  specId,
  date,
}: DayKebabMenuProps) => {
  const { t } = useTranslation();
  const [isMenu, setIsMenu] = useState(false);
  const menuRef = useRef(null);

  const options = [
    /* {
      body: (
        <Flex gap="8px" key="mailAll">
          <MailIcon />
          <BodySmall lowline>{t('calendar.menu.mailAll')}</BodySmall>
        </Flex>
      ),
      onClick: () => alert(1),
    }, */
    {
      body: (
        <Flex gap="8px" key="addBreak">
          <PlusCircleIcon />
          <BodySmall lowline>{t('calendar.menu.addBreak')}</BodySmall>
        </Flex>
      ),
      onClick: e => {
        e.stopPropagation();
        specId && onAddSpecialistBreak(specId);
        onAddBreak(true);
        setIsMenu(false);
      },
    },
    {
      body: (
        <Flex gap="8px" key="cancelAll">
          <CrossIcon />
          <BodySmall lowline>{t('calendar.menu.cancelAll')}</BodySmall>
        </Flex>
      ),
      onClick: () => {
        onSelectDate(date);
        specId && onCancelSpecialistAll(specId);
        onCancelAll();
        setIsMenu(false);
      },
    },
  ];

  useClickOutside(menuRef, () => setIsMenu(false));

  return (
    <KebabBody
      isMenu={isMenu}
      onClick={e => {
        e.stopPropagation();
        e.preventDefault();
        setIsMenu(prev => !prev);
      }}
      menuRef={menuRef}
      options={options}
    />
  );
};
