import styled from 'styled-components';
import { height, width, margin, flexbox } from 'styled-system';
import { Flex } from '@beauty/beauty-market-ui';
import { ContainerProps } from './types';

const getPadding = wide => (wide ? '80px' : '190px');

export const Wrapper = styled(Flex)<ContainerProps>`
  padding-left: ${({ wide }) => getPadding(wide)};
  padding-right: ${({ wide }) => getPadding(wide)};
  ${({ gap }) => gap && `gap: ${gap};`}
  ${width}
  ${margin}
  ${height}
  ${flexbox}
  @media (max-width: 850px) {
    padding-right: 25px;
    padding-left: 25px;
  }

  @media (max-width: 400px) {
    width: 100%;
  }
`;
