import styled, { css } from 'styled-components';
import { Flex, colors, MenuDotsIcon, FilterIcon, ListView, CardView, Button } from '@beauty/beauty-market-ui';

const iconStyle = css`
  width: 20px;
  height: 20px;
  :hover {
    cursor: pointer;
  }
`;

export const Dots = styled(MenuDotsIcon)`
  ${iconStyle}
`;

export const Filters = styled(FilterIcon)`
  ${iconStyle}
`;

export const ListIcon = styled(ListView)`
  ${iconStyle}
`;

export const CardIcon = styled(CardView)`
  ${iconStyle}
`;

export const StyledButton = styled(Button)`
  position: relative;
  padding: 6px 0px !important;
  path {
    stroke: ${colors.black.standard} !important;
  }
`;
