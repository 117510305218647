import { useTranslation } from 'react-i18next';
import isEmpty from 'lodash/isEmpty';
import moment from 'moment/moment';
import LogoutImage from 'assets/exit-image.png';
import { Stub } from 'components';
import { getFormattedCurrency, getTranslation, isRtl } from 'helpers';
import { useMediaScreen } from 'hooks';
import { useAppSelector } from 'store/hooks';
import { selectCurrency } from 'store/redux-slices/organisationSlice';
import { EventStatus, getSelectedLanguage } from '../../../../constants';
import { AppointmentItem } from './AppointmentItem';

require('moment/locale/ru');
require('moment/locale/he');

export const AppointmentsList = ({ appointments, onClick, placeholder }) => {
  const { t } = useTranslation();
  const { isDesktop } = useMediaScreen('md');
  const rtl = isRtl();
  const language = getSelectedLanguage();
  moment.locale(language.toLowerCase());
  const currency = useAppSelector(selectCurrency);

  return isEmpty(appointments) ? (
    <Stub
      title={placeholder}
      alt={placeholder}
      src={LogoutImage}
      margin="0 auto"
      imageWidth="200px"
      infoWidth="max-content"
    />
  ) : (
    <>
      {appointments.map(({ id, status, start, orgService }, index, arr) => {
        const last = index === arr.length - 1;
        const first = index === 0;
        const margin = first ? '0' : '16px 0';
        const isCancelled = status === EventStatus.CANCELLED;
        const time = moment(start).format('DD MMMM YYYY');
        const translatedService = getTranslation(orgService.headOrgService.title, language);
        const price =
          orgService.price === null
            ? t('settings.services.nullPrice')
            : getFormattedCurrency(orgService?.price, currency, language);

        const handleAppointmentClick = () => onClick(id);

        return (
          <AppointmentItem
            key={id}
            isDesktop={isDesktop}
            price={price}
            cancelled={isCancelled ? t('clients.profile.canceled') : ''}
            service={translatedService}
            time={time}
            separator={!last}
            m={last ? '16px 0 0' : margin}
            p={rtl ? '0 0 0 15px' : '0 15px 0 0'}
            onClick={isCancelled ? undefined : handleAppointmentClick}
          />
        );
      })}
    </>
  );
};
