import { isEmpty } from 'lodash';
import { toFormData } from '../../helpers';
import { specialistAPI } from '../../helpers/specialistApi';
import {
  CreateSpecialistParams,
  Document,
  EditWorkingTimeParamsType,
  OrganisationServiceResponse,
  PhotosUpdate,
  SpecialistEducation,
  SpecialistInformation,
  SpecialistOrganizationType,
  SpecialistResponse,
  SpecialistScheduleType,
  SpokenLanguage,
} from '../../types';
import { createAppAsyncThunk } from '../create-app-async-thunk';

export const editWorkplaces = createAppAsyncThunk<
  Array<{ id: string; orgId: string; name: string }>,
  { headOrgSpecId: string; organizationIds: string }
>('specialist/editWorkplaces', async (data, thunkAPI) => specialistAPI.editWorkplaces({ data, thunkAPI }));

export const editServices = createAppAsyncThunk<
  { orgId: string; orgService: OrganisationServiceResponse[] },
  {
    orgId: string;
    orgSpecId: string;
    orgServiceIds: string;
  }
>('specialist/editServices', async (data, thunkAPI) => specialistAPI.editServices({ data, thunkAPI }));

export const editCurrentSchedule = createAppAsyncThunk<
  {
    orgId: string;
    orgSpecSchedule: SpecialistScheduleType[];
  },
  EditWorkingTimeParamsType
>('specialist/editCurrentSchedule', async (data, thunkAPI) =>
  specialistAPI.editCurrentSchedule({
    data,
    thunkAPI,
  }),
);

export const editGeneralSchedule = createAppAsyncThunk<SpecialistOrganizationType[], EditWorkingTimeParamsType[]>(
  'specialist/editGeneralSchedule',
  async (data, thunkAPI) =>
    specialistAPI.editGeneralSchedule({
      data,
      thunkAPI,
    }),
);

export const deleteAvatar = createAppAsyncThunk<void, string>('specialist/deleteAvatar', async (id, thunkAPI) =>
  specialistAPI.deleteAvatar({
    id,
    thunkAPI,
  }),
);

export const editSpecialist = createAppAsyncThunk<
  SpecialistResponse,
  CreateSpecialistParams | SpecialistResponse | { published: boolean }
>('common/editSpecialist', async (data, thunkAPI) => specialistAPI.updateSpecialist({ data, thunkAPI }));

export const fetchSpecialistInfo = createAppAsyncThunk<SpecialistInformation, string>(
  'specialist/fetchSpecialistInfo',
  async (id, thunkAPI) => specialistAPI.getSpecialistInfo({ id, thunkAPI }),
);

export const editSpecialistAbout = createAppAsyncThunk<void, { id: string; about: string | null }>(
  'specialist/editSpecialistAbout',
  async (data, thunkAPI) => specialistAPI.editSpecialistAbout({ data, thunkAPI }),
);

export const addEducation = createAppAsyncThunk<SpecialistEducation, SpecialistEducation>(
  'specialist/addEducation',
  async (data, thunkAPI) => {
    const formData = toFormData(data);
    return specialistAPI.addEducation({ data: formData, thunkAPI });
  },
);

export const editEducation = createAppAsyncThunk<SpecialistEducation, SpecialistEducation>(
  'specialist/editEducation',
  async (data, thunkAPI) => {
    const formData = toFormData(data);
    return specialistAPI.editEducation({ data: formData, thunkAPI });
  },
);

export const deleteEducation = createAppAsyncThunk<void, string>('specialist/deleteEducation', async (id, thunkAPI) =>
  specialistAPI.deleteEducation({
    id,
    thunkAPI,
  }),
);

export const editPhotos = createAppAsyncThunk<Document[], { id: string; data: PhotosUpdate }>(
  'specialist/editPhotos',
  async ({ id, data }, thunkAPI) => {
    const formData = new FormData();

    for (const photo of data.photos) {
      formData.append('photos', photo);
    }

    if (!isEmpty(data.deletePhotos)) {
      formData.append('deletePhotos', data.deletePhotos.join(','));
    }

    return specialistAPI.editPhotos({ id, data: formData, thunkAPI });
  },
);

export const editSpokenLanguage = createAppAsyncThunk<
  Pick<SpecialistInformation, 'spokenLanguage'>,
  {
    id: string;
    languages: SpokenLanguage[];
  }
>('specialist/editSpokenLanguage', async (data, thunkAPI) =>
  specialistAPI.editSpokenLanguage({
    data,
    thunkAPI,
  }),
);
