import { useEffect, useState } from 'react';
import { apiRequest } from 'api/api';
import { HeadOrganisationEndpoints } from 'api/endpoints/endpoints';
import { OrganisationClientsType } from 'types/organisation';

export const useGetClients = (headOrgId = ''): [OrganisationClientsType[], boolean, unknown] => {
  const [isLoading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState<unknown>(null);
  const [clients, setClients] = useState<OrganisationClientsType[]>([]);

  useEffect(() => {
    const fetchClients = async (id: string) => {
      setLoading(true);
      const [error, response] = await apiRequest.get({
        endpoint: HeadOrganisationEndpoints.AllClients,
        endpointParams: id,
      });

      if (error?.data) setErrorMessage(error.data);
      if (response?.data) {
        setClients(response.data);
      }
      setLoading(false);
    };

    headOrgId && fetchClients(headOrgId);
  }, [headOrgId]);

  return [clients, isLoading, errorMessage];
};
