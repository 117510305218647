import { useEffect, useState } from 'react';
import { apiRequest } from '../api/api';
import { PropertyEndpoints } from '../api/endpoints/endpoints';
import { PropertyType } from '../types';

export const useGetProperties = () => {
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [properties, setProperties] = useState<PropertyType[]>([]);

  useEffect(() => {
    const fetchProperties = async () => {
      const [error, response] = await apiRequest.get({
        endpoint: PropertyEndpoints.Organization,
      });

      if (error?.data) alert('Error with loading properties');
      if (response?.data) {
        setProperties(response.data);
      }
      setIsLoading(false);
    };

    fetchProperties();

    return setIsLoading(false);
  }, []);

  return {
    isLoading,
    properties,
  };
};
