import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { RouterUrl } from '../../routes/routes';
import { HomeWrapper } from './style';

export const Home = () => {
  const navigate = useNavigate();

  useEffect(() => {
    navigate(RouterUrl.Login);
  }, []);

  return <HomeWrapper width="100%" />;
};
