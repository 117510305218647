import { useAppSelector } from 'store/hooks';
import { selectSpecialist } from 'store/redux-slices/specialistSlice';
import { EmployeePublish, EmployeeWorkplaces, EmployeeSchedule, EmployeeServices } from '../components';

export const GeneralData = () => {
  const { role } = useAppSelector(selectSpecialist);
  const isManager = role === 'MANAGER';

  return (
    <>
      {!isManager && <EmployeePublish />}
      <EmployeeWorkplaces />
      {!isManager && <EmployeeServices />}
      <EmployeeSchedule />
    </>
  );
};
