import { BodySmall, Toggle } from '@beauty/beauty-market-ui';
import { ToggleItemWrapper } from '../style';

type ToggleItemProps = {
  label: string;
  isChecked: boolean;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  disabled?: boolean;
};

const ToggleItem = ({ label, isChecked, onChange, disabled = false }: ToggleItemProps) => (
  <ToggleItemWrapper>
    <BodySmall large lowline>
      {label}
    </BodySmall>
    <Toggle size="small" checked={isChecked} onChange={onChange} disabled={disabled} />
  </ToggleItemWrapper>
);

export { ToggleItem };
