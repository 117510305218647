import { TFunction } from 'react-i18next';
import { UKFlag, IsraelFlag, RussiaFlag } from '@beauty/beauty-market-ui';

export const languageOptions = (t: TFunction<'translation', undefined>) => [
  {
    flag: <UKFlag width="21px" height="21px" />,
    language: t('language.english'),
    sortLan: 'en',
  },
  {
    flag: <IsraelFlag width="21px" height="21px" />,
    language: t('language.hebrew'),
    sortLan: 'he',
  },
  {
    flag: <RussiaFlag width="21px" height="21px" />,
    language: t('language.russian'),
    sortLan: 'ru',
  },
];
