import { useEffect, useRef, useState } from 'react';
import { Nullable } from 'tsdef';

export const useTimeout = (
  start: number,
  end: number,
  delay: number,
  callback?: () => void,
): [number, (value: number) => void] => {
  const [time, setTime] = useState(start);
  const timerRef = useRef<Nullable<NodeJS.Timeout | number>>(null);

  useEffect(() => {
    if (time > end) {
      timerRef.current = setTimeout(setTime, delay, time - 1);
    } else if (time === end) {
      callback && callback();
    }
    return () => {
      timerRef.current && clearTimeout(timerRef.current);
    };
  }, [time, start, end, callback]);

  return [time, setTime];
};
