import { useTranslation } from 'react-i18next';
import { useFormikContext } from 'formik';
import { H6, SearchIcon } from '@beauty/beauty-market-ui';
import { FormikInput } from 'components/functional/formik/formik-input/FormikInput';
import { SpecialistOption } from 'types/appointment';
import { SpecialistFormWrapper } from '../../style';
import { AppointmentFormFields } from '../AppointmentSidebar.definitions';

type SpecialistFormProps = {
  specialists: SpecialistOption[];
  onSelect: (index: number) => void;
  onDelete: () => void;
  disabled?: boolean;
};

export const SpecialistForm = ({ specialists, onSelect, onDelete, disabled = false }: SpecialistFormProps) => {
  const { t } = useTranslation();
  const { Specialist } = AppointmentFormFields;
  const { values } = useFormikContext<AppointmentFormFields>();

  return (
    <SpecialistFormWrapper>
      <H6 marginTop="8px">{t('calendar.newAppointmentSidebar.specialist')}</H6>
      <FormikInput
        id={Specialist}
        name={Specialist}
        placeholder={t('calendar.newAppointmentSidebar.specialistSearch')}
        iconLeft={<SearchIcon />}
        design="white"
        searchInput
        currentOption={specialists.findIndex(item => item.id === values[Specialist])}
        options={specialists}
        onSelect={onSelect}
        onDeleteIconClick={onDelete}
        type="client"
        disabled={disabled}
      />
    </SpecialistFormWrapper>
  );
};
